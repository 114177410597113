import {
    faArrowCircleUp,
    faFileDownload,
    faPlus,
    faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { Activity, ComponentIncludes } from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import { PipelineLink } from "~/components/common/resources/ResourceLinks";

export const formatPipelineMessage = (
    item: Activity,
    components: ComponentIncludes
) => {
    const pipelineLink = (
        <PipelineLink pipelineId={item.component?.id} components={components} />
    );

    switch (item.event) {
        case "pipeline.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Created new pipeline {pipelineLink}</span>
                ) : (
                    <span>Error creating new pipeline {pipelineLink}</span>
                ),
                icon: faPlus,
            };
        case "pipeline.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated pipeline {pipelineLink}</span>
                ) : (
                    <span>Error updating pipeline {pipelineLink}</span>
                ),
                icon: faArrowCircleUp,
            };
        case "pipeline.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested deletion of pipeline {pipelineLink}</span>
                ) : (
                    <span>
                        Error requesting deletion of pipeline {pipelineLink}
                    </span>
                ),
                icon: faTrash,
            };
        case "pipeline.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted pipeline {pipelineLink}</span>
                ) : (
                    <span>Error deleting pipeline {pipelineLink}</span>
                ),
                icon: faTrash,
            };
        case "pipeline.task.trigger":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested trigger of pipeline {pipelineLink}</span>
                ) : (
                    <span>
                        Error requesting trigger of pipeline {pipelineLink}
                    </span>
                ),
                icon: faFileDownload,
            };
        case "pipeline.trigger":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Triggered pipeline {pipelineLink}</span>
                ) : (
                    <span>Error triggering pipeline {pipelineLink}</span>
                ),
                icon: faFileDownload,
            };
        default:
            return {
                message: item.event,
            };
    }
};
