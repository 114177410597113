import { TierPlan } from "~/services/cycle";
import { useGetCurrentPlanTier } from "~/components/hubs/hooks";

export function useTierFeature(
    feature: keyof TierPlan["advanced_features"]
): boolean | undefined {
    const tier = useGetCurrentPlanTier();

    if (!tier) {
        return undefined;
    }

    return tier.advanced_features[feature];
}
