import classNames from "classnames";
import { Link, To } from "react-router-dom";
import { RhfIdCheckbox } from "../../../common/forms/IdSelect/RhfIdCheckbox";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { ResourceStateIcon } from "@cycleplatform/ui/components/resources/state";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import { Checkbox } from "@cycleplatform/ui/components/forms";
import { SkeletonCircle } from "@cycleplatform/ui/components/loaders/skeleton";
import { State } from "~/services/cycle";
import { AclResource, VerifyFn } from "@cycleplatform/core/modules/acls/util";
import { AccessControlOverlay } from "~/components/common/buttons";

type ResourceListItemProps<T extends AclResource> = {
    isLoading?: boolean;
    isActive: boolean;
    children: React.ReactNode;
    to: To;
    checkboxId?: string;
    state?: State & { current: string };
    accessControl?: {
        aclResource: T | undefined;
        verifyFn: VerifyFn<T>;
    };
};

/**
 * An individual resource item that goes in the 'ResourceList' component.
 */
export function ResourceListItem<T extends AclResource>({
    isActive,
    to,
    children,
    checkboxId,
    isLoading,
    state,
    accessControl,
}: ResourceListItemProps<T>) {
    return (
        <li
            className={classNames(
                "hover:bg-cycle-gray-light hover:dark:bg-cycle-black-accent/80 flex min-h-[4.5rem] w-full rounded-lg rounded-r-none dark:text-white",
                isActive && "bg-cycle-white-accent dark:bg-cycle-black-accent"
            )}
        >
            <div className="inherit flex flex-1 items-center">
                {checkboxId || isLoading ? (
                    <div
                        className={classNames(
                            "relative mr-4 w-8 px-4 text-center",
                            isLoading && skeletonStyles
                        )}
                    >
                        {checkboxId ? (
                            <>
                                {!!accessControl ? (
                                    <AccessControlOverlay
                                        aclResource={
                                            accessControl.aclResource as
                                                | AclResource
                                                | undefined
                                        }
                                        verifyFn={
                                            accessControl.verifyFn as VerifyFn<AclResource>
                                        }
                                        className="pl-2"
                                    >
                                        <RhfIdCheckbox id={checkboxId} />
                                    </AccessControlOverlay>
                                ) : (
                                    <RhfIdCheckbox id={checkboxId} />
                                )}
                            </>
                        ) : (
                            <Checkbox />
                        )}
                    </div>
                ) : null}

                <Link to={to} className="inherit flex-1 p-4">
                    <div
                        className={classNames(
                            "flex w-full flex-1 items-center"
                        )}
                    >
                        {state || isLoading ? (
                            <div className="mr-4 w-[12px] text-center">
                                <Tooltip
                                    message={
                                        state?.error?.message
                                            ? state.error.message
                                            : `${state?.current}`
                                    }
                                >
                                    {state ? (
                                        <ResourceStateIcon state={state} />
                                    ) : (
                                        <SkeletonCircle />
                                    )}
                                </Tooltip>
                            </div>
                        ) : null}
                        {children}
                    </div>
                </Link>
            </div>
        </li>
    );
}
