import {
    faTrash,
    faQuestionCircle,
    faPlus,
    faEdit,
} from "@fortawesome/pro-solid-svg-icons";
import { Activity, ComponentIncludes } from "~/services/cycle";
import {
    IntegrationLink,
    IntegrationVendorLink,
} from "~/components/common/resources/ResourceLinks";
import { isErroredActivity } from "./common/util";

export function formatIntegrationMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const integrationLink = (
        <IntegrationLink
            integrationId={item.component?.id}
            components={components}
        />
    );

    const integrationVendorLink = (
        <IntegrationVendorLink
            integrationId={item.component?.id}
            components={components}
        />
    );

    switch (item.event) {
        case "hub.integration.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Created {integrationVendorLink} integration{" "}
                        {integrationLink}{" "}
                    </span>
                ) : (
                    <span>
                        Error creating {integrationVendorLink} integration{" "}
                        {integrationLink}
                    </span>
                ),
                icon: faPlus,
            };
        case "hub.integration.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Updated {integrationVendorLink} integration{" "}
                        {integrationLink}{" "}
                    </span>
                ) : (
                    <span>
                        Error updating {integrationVendorLink} integration{" "}
                        {integrationLink}
                    </span>
                ),
                icon: faEdit,
            };

        case "hub.integration.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Deleted {integrationVendorLink} integration{" "}
                        {integrationLink}{" "}
                    </span>
                ) : (
                    <span>
                        Error deleting {integrationVendorLink} integration{" "}
                        {integrationLink}
                    </span>
                ),
                icon: faTrash,
            };

        case "hub.integration.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested deletion of {integrationVendorLink}{" "}
                        integration {integrationLink}
                    </span>
                ) : (
                    <span>
                        Error requesting deletion of {integrationVendorLink}{" "}
                        integration {integrationLink}
                    </span>
                ),
                icon: faTrash,
            };

        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}
