import { components } from "../api/__generated";

export function isServiceContainer(
    container?: components["schemas"]["Container"]
): boolean {
    if (!container) {
        return false;
    }
    return container.image.service !== null;
}

export function isHypervisorContainer(
    container?: components["schemas"]["Container"]
): boolean {
    if (!container) {
        return false;
    }
    return container.image.extension?.identifier === "virtual-machine-v1";
}

export function isLbContainer(
    container?: components["schemas"]["Container"]
): boolean {
    if (!container) {
        return false;
    }
    return container.image.service === "loadbalancer";
}

export function isDiscoveryContainer(
    container?: components["schemas"]["Container"]
): boolean {
    if (!container) {
        return false;
    }
    return container.image.service === "discovery";
}

export function isSchedulerContainer(
    container?: components["schemas"]["Container"]
): boolean {
    if (!container) {
        return false;
    }
    return container.image.service === "scheduler";
}

export function isVpnContainer(
    container?: components["schemas"]["Container"]
): boolean {
    if (!container) {
        return false;
    }
    return container.image.service === "vpn";
}

export function isGatewayContainer(
    container?: components["schemas"]["Container"]
): boolean {
    if (!container) {
        return false;
    }
    return container.image.service === "gateway";
}
