import {
    FilterContext,
    FilterGroup,
    FilterMenu,
    FilterValue,
} from "@cycleplatform/ui/components/forms/filters";
import { faCheck, faClock } from "@fortawesome/pro-solid-svg-icons";

export function InstanceTelemetryFilters({
    filtering,
    className,
}: {
    filtering: FilterContext;
    className?: string;
}) {
    return (
        <FilterMenu filtering={filtering} className={className}>
            <FilterGroup
                identifier="instanceLimit"
                name="Instance Limit"
                isExclusive
                isRequired
                icon={faCheck}
            >
                <FilterValue name="5" value="5" isDefault>
                    5
                </FilterValue>
                <FilterValue name="10" value="10">
                    10
                </FilterValue>
            </FilterGroup>

            <FilterGroup
                identifier="range"
                name="Time Range"
                isExclusive
                isRequired
                icon={faClock}
            >
                <FilterValue name="1d" value="24" isDefault>
                    1d
                </FilterValue>
                <FilterValue name="3d" value="72">
                    3d
                </FilterValue>
                <FilterValue name="1w" value="168">
                    1w
                </FilterValue>
            </FilterGroup>
        </FilterMenu>
    );
}
