import { useSearchParams } from "react-router-dom";

export function useNavigationExpandedState(): [
    boolean,
    (open: boolean) => void
] {
    const [searchParams, setSearchParams] = useSearchParams();

    const isExpanded = searchParams.get("nav") === "open";

    const setIsExpanded = (open: boolean) => {
        const newSearchParams = new URLSearchParams(searchParams);

        if (open) {
            newSearchParams.set("nav", "open");
        } else {
            newSearchParams.delete("nav");
        }

        setSearchParams(newSearchParams, { replace: true });
    };

    return [isExpanded, setIsExpanded];
}
