import { LetsEncryptPathInput } from "./LetsEncryptPathInput";

export function LetsEncryptIntegration() {
    return (
        <>
            <LetsEncryptPathInput
                field="config.integrations.lets_encrypt.certificate_path"
                label="Certificate path"
                placeholder={"/var/run/cycle/tls/current.cert"}
            />

            <LetsEncryptPathInput
                field="config.integrations.lets_encrypt.key_path"
                label="Key path"
                placeholder={"/var/run/cycle/tls/current.key"}
            />

            <LetsEncryptPathInput
                field="config.integrations.lets_encrypt.chain_path"
                label="Chain path"
                placeholder={"/var/run/cycle/tls/current.chain"}
            />
            <LetsEncryptPathInput
                field="config.integrations.lets_encrypt.bundle_path"
                label="Bundle path"
                placeholder={"/var/run/cycle/tls/current.bundle"}
            />

            <LetsEncryptPathInput
                field="config.integrations.lets_encrypt.additional_certs_path"
                label="Additional certs path"
                placeholder={""}
            />
        </>
    );
}
