import { createContext } from "react";
import { Image, ImageSource } from "~/services/cycle";

type ImageDialogContext = {
    image: Image | undefined;
    source: ImageSource | undefined;
};
export const ImageDialogContext = createContext<ImageDialogContext>({
    image: undefined,
    source: undefined,
});
