import { analyzeVirtualMachineNetworkStatus } from "@cycleplatform/core/modules/networks/analysis";
import { useMemo } from "react";
import {
    Container,
    Environment,
    LoadBalancerConfig,
    useGetContainerQuery,
    useGetLoadBalancerServiceQuery,
    VirtualMachine,
} from "~/services/cycle";
import { getLoadBalancerConfiguration } from "@cycleplatform/core/modules/environments/loadbalancer";
import { operations } from "@cycleplatform/core/modules/api/__generated";
import { VirtualMachinePublicNetworkChart } from "@cycleplatform/ui/components/resources/networks/VirtualMachinePublicNetworkChart";
import { isCycleApiError } from "~/services/helpers";
import classNames from "classnames";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import { Panel, PanelTitle } from "@cycleplatform/ui/components/panels";
import { PanelContentBoundary } from "~/components/layout/panels/PanelContentBoundary";

export function VmPublicNetworkChart({
    container,
    vm,
    environment,
    formatTooltip,
}: {
    container?: Container;
    environment?: Environment;
    vm?: VirtualMachine;
    formatTooltip?: (message: string) => string;
}) {
    const {
        data: lbInfo,
        isLoading: lbInfoLoading,
        error: lbInfoError,
    } = useGetLoadBalancerServiceQuery(
        {
            environmentId: vm?.environment?.id || "",
        },
        { skip: !vm }
    );

    const {
        data: loadbalancer,
        isLoading: lbLoading,
        error: lbContainerError,
    } = useGetContainerQuery(
        {
            containerId: lbInfo?.data.service?.container_id || "",
            meta: ["ips", "instances_count"],
            include: ["environments"],
        },
        { skip: !!lbInfoError || !lbInfo?.data }
    );

    const config = lbInfo?.data
        ? getLoadBalancerConfiguration(
              lbInfo.data as unknown as operations["getLoadBalancerService"]["responses"][200]["content"]["application/json"]["data"]
          )
        : undefined;

    const {
        data: gateway,
        isLoading: gatewayLoading,
        error: gatewayContainerError,
    } = useGetContainerQuery(
        {
            containerId: environment?.services.gateway?.container_id || "",
            meta: ["ips", "instances_count"],
            include: ["environments"],
        },
        { skip: !environment?.services.gateway?.container_id }
    );

    const analysis = useMemo(
        () =>
            vm && container
                ? analyzeVirtualMachineNetworkStatus(
                      container,
                      loadbalancer?.data,
                      // needed for type discrepancy until RTKQuery updates their codegen.
                      config as LoadBalancerConfig,
                      vm,
                      gateway?.data
                  )
                : null,
        [container, loadbalancer?.data, config]
    );

    if (lbInfoError) {
        if (isCycleApiError(lbInfoError) && lbInfoError.status === 503) {
            // ignore 503s, those are sent on new envs.
        } else {
            throw lbInfoError;
        }
    }
    if (lbContainerError) {
        if (
            isCycleApiError(lbContainerError) &&
            lbContainerError.status === 503
        ) {
            // ignore 503s, those are sent on new envs.
        } else {
            throw lbContainerError;
        }
    }

    if (gatewayContainerError) {
        if (
            isCycleApiError(gatewayContainerError) &&
            gatewayContainerError.status === 503
        ) {
            // ignore 503s, those are sent on new envs.
        } else {
            throw gatewayContainerError;
        }
    }

    const isLoading =
        !container || lbLoading || lbInfoLoading || gatewayLoading;

    return (
        <Panel>
            <PanelTitle title="Public Network" />
            <PanelContentBoundary>
                {!isLoading ? (
                    <VirtualMachinePublicNetworkChart
                        analysis={analysis}
                        formatTooltip={formatTooltip}
                    />
                ) : (
                    <div className={classNames(skeletonStyles, "h-24")} />
                )}
            </PanelContentBoundary>
        </Panel>
    );
}
