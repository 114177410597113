import { DateFormats, formatDateString } from "@cycleplatform/core/util";
import { Badge } from "@cycleplatform/ui/components/badges";
import { PanelDetail } from "@cycleplatform/ui/components/panels";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
    HubMembership,
    HubMembershipsIncludes,
    PublicAccount,
} from "~/services/cycle";

type UserInfoProps = {
    account: PublicAccount | undefined;
    memberIncludes: HubMembershipsIncludes | undefined;
    member: HubMembership | undefined;
};

export function UserInfo({ account, member, memberIncludes }: UserInfoProps) {
    const role = memberIncludes?.roles?.[member?.role_id || ""];

    return (
        <>
            <PanelDetail label="role" isLoading={!member}>
                <Link to={`/hub/roles/${member?.role_id}`}>{role?.name}</Link>
            </PanelDetail>
            <PanelDetail label="email" isLoading={!account}>
                {account?.email?.address}
            </PanelDetail>
            <PanelDetail label="joined" isLoading={!member}>
                {member?.events?.joined
                    ? formatDateString(
                          member?.events?.joined,
                          DateFormats["standardShort"]
                      )
                    : null}
            </PanelDetail>

            <PanelDetail label="Two-Factor Auth" isLoading={!account}>
                {account?.two_factor_auth?.verified ? (
                    <Badge className="flex gap-2">
                        <FontAwesomeIcon
                            icon={faCircle}
                            className="text-success"
                        />{" "}
                        enabled
                    </Badge>
                ) : (
                    <Badge className="flex gap-2">
                        <FontAwesomeIcon
                            icon={faCircle}
                            className="text-warning"
                        />{" "}
                        disabled
                    </Badge>
                )}
            </PanelDetail>
        </>
    );
}
