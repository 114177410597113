import { createContext } from "react";

type MultiStepDialogContext<T> = {
    onClose: () => void;
    currentIdx: number;
    handleNext: () => void;
    handleBack: () => void;
    navigateToStep: (idx: number) => void;
    stepNames: string[];
    setStepNames: (stepNames: string[]) => void;
    formKey: string;
};

export const MultiStepDialogContext = createContext<
    MultiStepDialogContext<unknown>
>({
    onClose: () => null,
    currentIdx: 0,
    handleNext: () => null,
    handleBack: () => null,
    navigateToStep: () => null,
    stepNames: [],
    setStepNames: () => null,
    formKey: "",
});
