import {
    GetLoadBalancerServiceApiResponse,
    LoadBalancerConfig,
    CreateLoadBalancerServiceJobApiArg,
} from "~/services/cycle";

export type LoadBalancerFormValues =
    CreateLoadBalancerServiceJobApiArg["body"]["contents"] & {
        // Custom is used only for haproxy config
        // Describes a custom vs defaul config
        custom?: boolean;
        isDirty: boolean;
    };

export const protectedPorts = ["1194", "8812"];

export function getDefaultLbFormVals(
    lbInfo: GetLoadBalancerServiceApiResponse["data"] | undefined
): LoadBalancerFormValues {
    // if there is no config set, then apply the default config

    if (!lbInfo?.service?.config) {
        return {
            config: lbInfo?.default_config,
            isDirty: false,
        };
    }

    const isGlobalDefault = lbInfo.service.config.details === null;
    // if config.details === null, apply the default config
    // else apply the config in lbInfo
    const config = isGlobalDefault
        ? lbInfo.default_config
        : lbInfo.service.config;

    // If type === default, apply the base config of the specified default type
    // and spread in the config from the previous type

    switch (lbInfo.service.config.type) {
        case "default":
            return {
                isDirty: false,
                config: {
                    ...config,
                    details: lbInfo.base_configs?.[lbInfo.default_type] || null,
                } as LoadBalancerConfig,
            };
        case "haproxy":
            return JSON.parse(
                JSON.stringify({
                    isDirty: false,
                    custom: !isGlobalDefault,
                    config: lbInfo.service.config.details
                        ? lbInfo.service.config
                        : {
                              ...lbInfo.service.config,
                              type: lbInfo.service.config.type as "haproxy",
                              details:
                                  lbInfo?.base_configs?.[
                                      lbInfo.service.config.type as "haproxy"
                                  ] || null,
                          },
                })
            );
        case "v1":
        default:
            const actualDetails =
                lbInfo?.base_configs?.[lbInfo.service.config.type as "v1"];

            const defaultControllers =
                lbInfo?.base_configs?.[
                    lbInfo.service.config.type as "v1"
                ]?.controllers?.map((c) => ({ ...c, transport: null })) || null;

            const defaultDetails = actualDetails && {
                ...actualDetails,
                controllers: defaultControllers,
            };

            return JSON.parse(
                JSON.stringify({
                    isDirty: false,
                    config: lbInfo.service.config.details
                        ? lbInfo.service.config
                        : {
                              ...lbInfo.service.config,
                              type: lbInfo.service.config.type as "v1",
                              details: isGlobalDefault
                                  ? defaultDetails
                                  : actualDetails,
                          },
                })
            );
    }
}
