import { components } from "../api/__generated";

export function isCreditExpired(credit: components["schemas"]["Credit"]) {
    const dateStr = credit.expires?.date;
    if (dateStr) {
        const date = new Date(dateStr);
        if (date < new Date()) return true;
    }
    return false;
}

export function getCreditsTotal(credits: components["schemas"]["Credit"][]) {
    return credits.reduce((total, c) => {
        if (isCreditExpired(c)) return total;
        return total + c.amount_remaining;
    }, 0);
}
