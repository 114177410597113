import { useMemo } from "react";
import {
    Environment,
    useGetEnvironmentMonitoringTiersQuery,
} from "~/services/cycle";

export function useGetEffectiveMonitoringTier(
    environment: Environment | undefined
) {
    const m = environment?.features.monitoring;

    const { data: monitoringTiers, error } =
        useGetEnvironmentMonitoringTiersQuery();

    const tier = useMemo(
        () => monitoringTiers?.data?.[m?.tier || "limited"],
        [m?.tier, monitoringTiers?.data]
    );
    if (error) {
        throw error;
    }

    return tier;
}
