import { getLoadBalancerConfiguration } from "@cycleplatform/core/modules/environments/loadbalancer";
import { operations } from "@cycleplatform/core/modules/api/__generated";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import classNames from "classnames";
import { useMemo } from "react";
import { analyzeContainerNetworkStatus } from "@cycleplatform/core/modules/networks/analysis";
import {
    Container,
    LoadBalancerConfig,
    useGetContainerQuery,
    useGetLoadBalancerServiceQuery,
} from "~/services/cycle";
import { isCycleApiError } from "~/services/helpers";
import { ContainerPublicNetworkChart } from "@cycleplatform/ui/components/resources/networks/ContainerPublicNetworkChart";

type PublicNetworkDiagramProps = {
    container?: Container;
    formatTooltip?: (message: string) => string;
};

export function PublicNetworkDiagram({
    container,
    formatTooltip = (m) => m,
}: PublicNetworkDiagramProps) {
    const {
        data: lbInfo,
        isLoading: lbInfoLoading,
        error: lbInfoError,
    } = useGetLoadBalancerServiceQuery(
        {
            environmentId: container?.environment?.id || "",
        },
        { skip: !container }
    );

    const {
        data: loadbalancer,
        isLoading: lbLoading,
        error: lbContainerError,
    } = useGetContainerQuery(
        {
            containerId: lbInfo?.data.service?.container_id || "",
            meta: ["ips", "instances_count"],
            include: ["environments"],
        },
        { skip: !!lbInfoError || !lbInfo?.data }
    );

    const config = lbInfo?.data
        ? getLoadBalancerConfiguration(
              lbInfo.data as unknown as operations["getLoadBalancerService"]["responses"][200]["content"]["application/json"]["data"]
          )
        : undefined;

    const analysis = useMemo(
        () =>
            container
                ? analyzeContainerNetworkStatus(
                      container,
                      loadbalancer?.data,
                      // needed for type discrepancy until RTKQuery updates their codegen.
                      config as LoadBalancerConfig
                  )
                : null,
        [container, loadbalancer?.data, config]
    );

    if (lbInfoError) {
        if (isCycleApiError(lbInfoError) && lbInfoError.status === 503) {
            // ignore 503s, those are sent on new envs.
        } else {
            throw lbInfoError;
        }
    }
    if (lbContainerError) {
        if (
            isCycleApiError(lbContainerError) &&
            lbContainerError.status === 503
        ) {
            // ignore 503s, those are sent on new envs.
        } else {
            throw lbContainerError;
        }
    }

    if (!container || lbLoading || lbInfoLoading) {
        return <div className={classNames(skeletonStyles, "h-12")} />;
    }

    return (
        <ContainerPublicNetworkChart
            analysis={analysis}
            formatTooltip={formatTooltip}
            container={container}
        />
    );
}
