import {
    InfoPanel,
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { useContext } from "react";
import { ImageDialogContext } from "../context";
import { useJobTracker } from "~/modules/jobs/hooks";
import { useCreateImageJobMutation } from "~/services/cycle";
import { useForm } from "react-hook-form";
import {
    Button,
    PushAndHoldButton,
} from "@cycleplatform/ui/components/buttons";
import { faFileArrowDown } from "@fortawesome/pro-solid-svg-icons";

import classNames from "classnames";
import { useAppDispatch } from "~/hooks";
import { pushNotification } from "~/modules/notifications/slice";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { AccessControlOverlay } from "~/components/common/buttons";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";

export function ImageImport() {
    const { image, source } = useContext(ImageDialogContext);

    const [trackJob, { isTrackingJob }] = useJobTracker();
    const [importImage] = useCreateImageJobMutation();

    const dispatch = useAppDispatch();

    const onSubmit = () => {
        return trackJob(
            importImage({
                imageId: image?.id || "",
                body: {
                    action: "import",
                },
            }).unwrap()
        ).catch((err) => {
            dispatch(
                pushNotification({
                    title: `Error importing image ${image?.name}`,
                    message: `${err?.data?.error?.title}`,
                    type: "error",
                    icon: "images",
                })
            );
        });
    };

    return (
        <div className={classNames("flex h-[32px] items-center text-base ")}>
            <AccessControlOverlay
                verifyFn={modifyAccessFn("images-manage")}
                aclResource={source}
            >
                {image?.state.current === "new" ? (
                    <PushAndHoldButton
                        className="flex h-[32px]"
                        isLoading={isTrackingJob}
                        type="submit"
                        flavor="primary"
                        icon={faFileArrowDown}
                        onClick={() => onSubmit()}
                        tooltip="Press and hold to import image"
                    />
                ) : (
                    <Tooltip message='Import image is only available when current state is "new"'>
                        <Button
                            disabled
                            type="submit"
                            flavor="primary"
                            icon={faFileArrowDown}
                            className={
                                "!text-cycle-white !bg-cycle-gray flex h-[32px]"
                            }
                        />
                    </Tooltip>
                )}
            </AccessControlOverlay>
        </div>
    );
}
