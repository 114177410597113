import { components } from "@cycleplatform/core/modules/api/__generated";
import { FeatureLineItem } from "./FeatureLineItem";
import { formatMoney } from "@cycleplatform/core/util/money";
import { FormattedImageBuildDetails } from "./FormattedImageBuildDetails";
import { getHumanizedNumber } from "@cycleplatform/core/util";
import { faArrowTrendUp } from "@fortawesome/pro-solid-svg-icons";
import {
    faUser,
    faServer,
    faCubes,
    faHammer,
    faFileBinary,
    faUsers,
    faMemory,
} from "@fortawesome/pro-duotone-svg-icons";
import classNames from "classnames";

type PlanFeaturesProps = {
    t: components["schemas"]["TierPlan"];
    className?: string;
};

export function PlanFeatures({ t, className }: PlanFeaturesProps) {
    return (
        <div className={classNames("flex w-full gap-4 ", className)}>
            {t.generation === 1 ? (
                // For Enterprise- dedicated cluster && existing legacy tiers
                <ul className="flex flex-col gap-1 ">
                    <FeatureLineItem
                        value={`${t.ram.included_gb}GB`}
                        label="RAM Pool"
                        icon={faMemory}
                    />
                    <FeatureLineItem
                        value={`${t.image_storage.included_gb}GB`}
                        label="Image Storage"
                        icon={faFileBinary}
                        className={className}
                    />

                    <FeatureLineItem
                        value={
                            t.builds.max_daily_builds !== null
                                ? `${t.builds.max_daily_builds}`
                                : `Unlimited`
                        }
                        label="Maximum Daily Image Builds"
                        tooltip={
                            <FormattedImageBuildDetails builds={t.builds} />
                        }
                        icon={faHammer}
                    />
                    <FeatureLineItem
                        value={t.max_nodes ? t.max_nodes : `Unlimited`}
                        label="Max Nodes"
                        icon={faServer}
                    />

                    <FeatureLineItem
                        value={t.max_members ? t.max_members : `Unlimited`}
                        label="Max Members"
                        icon={faUsers}
                    />
                </ul>
            ) : (
                <div className="flex h-full w-full flex-col justify-start gap-1">
                    <ul className="flex flex-col gap-1 ">
                        <FeatureLineItem
                            value={t.members.included}
                            label={
                                t.members.included > 1
                                    ? "Users Included"
                                    : "User"
                            }
                            tooltipIcon={faArrowTrendUp}
                            tooltipClassName="!text-success"
                            tooltip={
                                !t.members.hard_cap
                                    ? `Additional members ${formatMoney({
                                          value: t.members.additional.mills,
                                          mills: true,
                                      })} ${t.members.additional.term}`
                                    : undefined
                            }
                            icon={faUser}
                        />
                        <FeatureLineItem
                            value={t.servers.included}
                            label="Server Licences Included"
                            tooltipIcon={faArrowTrendUp}
                            tooltipClassName="!text-success"
                            tooltip={
                                !t.servers.hard_cap
                                    ? `Additional servers ${formatMoney({
                                          value: t.servers.additional.mills,
                                          mills: true,
                                      })} ${t.servers.additional.term}`
                                    : undefined
                            }
                            icon={faServer}
                        />

                        <FeatureLineItem
                            value={"Unlimited Containers"}
                            label=""
                            icon={faCubes}
                        />

                        <FeatureLineItem
                            value={
                                t.builds.cpu_cores < 2
                                    ? "Limited"
                                    : t.builds.cpu_cores < 4
                                    ? "Standard"
                                    : "Performance"
                            }
                            label={` Image Builds`}
                            tooltip={
                                <FormattedImageBuildDetails builds={t.builds} />
                            }
                            icon={faHammer}
                        />

                        <FeatureLineItem
                            value={`${getHumanizedNumber(
                                t.image_storage.included_gb
                            )} GB`}
                            label="Image Storage"
                            icon={faFileBinary}
                        />
                    </ul>

                    <div className="mt-4 border-t" />

                    <h4
                        className={classNames(
                            "py-2 font-semibold",
                            "@[18rem]:text-lg text-base"
                        )}
                    >
                        Included in {t.name.split(" ")?.[0]}
                    </h4>

                    <ul className="flex flex-col gap-1 ">
                        {Object.entries(t.advanced_features)
                            .sort(([_a, aVal], [_b, bVal]) => {
                                return aVal === bVal ? 0 : aVal ? -1 : 1;
                            })
                            .map(([key, val]) => {
                                const notSupported = !val;
                                return (
                                    <FeatureLineItem
                                        key={`${t.id}-${key}`}
                                        value={
                                            AdvancedFeatures[
                                                key as "deployments"
                                            ] || key.toUpperCase()
                                        }
                                        label=""
                                        notSupported={notSupported}
                                    />
                                );
                            })}
                    </ul>
                </div>
            )}
        </div>
    );
}
const AdvancedFeatures: Record<
    keyof components["schemas"]["TierPlan"]["advanced_features"],
    string
> = {
    deployments: "Deployments",
    ial: "Infrastructure Abstration Layer",
    gpu: "GPU Support",
    autoscale: "Auto-Scaling",
    geodns: "Geo DNS",
    provider_multi_account: "Multiple Integrations Per Provider",
};
