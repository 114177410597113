import { Avatar } from "@cycleplatform/ui/components/avatar";
import { Button } from "@cycleplatform/ui/components/buttons";
import {
    DialogColumn,
    DialogFooter,
} from "@cycleplatform/ui/components/dialog/components";
import {
    FormSectionHeader,
    FormSection,
} from "@cycleplatform/ui/components/forms";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { useContext } from "react";
import { MultiStepDialogContext } from "~/components/dialogs/MultiStepFormDialog/context";
import { useAppDispatch } from "~/hooks";
import { terminateForm } from "~/modules/forms/slice";
import { useGetHubInvitesQuery, useGetRolesQuery } from "~/services/cycle";
import { HubSetupInviteMemberForm } from "./form";
import { ChooseAnotherHubButton } from "../../buttons/ChooseAnotherHubButton";

export function HubSetupInvitesStep() {
    const { handleBack, formKey } = useContext(MultiStepDialogContext);
    const dispatch = useAppDispatch();

    const { data: invites } = useGetHubInvitesQuery({});
    const { data: roles } = useGetRolesQuery({
        sort: ["-rank"],
        page: {
            number: 1,
            size: 100,
        },
    });

    return (
        <div>
            <DialogColumn>
                <FormSectionHeader header="Invite Members" />
                <FormSection>
                    <HubSetupInviteMemberForm />
                </FormSection>

                {invites?.data?.length ? (
                    <div className="pt-4">
                        <FormSectionHeader header="Sent Invites" />
                        <FormSection className="flex flex-col gap-4">
                            {invites?.data?.map((i) => {
                                const role = roles?.data?.find(
                                    (r) => r.id === i.role_id
                                );
                                return (
                                    <div className="flex items-center gap-4">
                                        <Avatar
                                            email={i.invitation.recipient}
                                        />
                                        {i.invitation.recipient}{" "}
                                        {role ? `as ${role.name}` : ""}
                                    </div>
                                );
                            })}
                        </FormSection>
                    </div>
                ) : null}
            </DialogColumn>

            <DialogFooter className="flex justify-between">
                <div className="flex gap-4">
                    <Button icon={faArrowLeft} onClick={() => handleBack?.()}>
                        Back
                    </Button>
                    <ChooseAnotherHubButton />
                </div>

                <Button
                    flavor="primary"
                    onClick={() => dispatch(terminateForm({ key: formKey }))}
                    icon={faArrowRight}
                >
                    Proceed to Hub
                </Button>
            </DialogFooter>
        </div>
    );
}
