import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";

import {
    FormSection,
    FormSectionHeader,
    required,
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { useForm } from "react-hook-form";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import {
    UpdateVirtualProviderIsoApiArg,
    useUpdateVirtualProviderIsoMutation,
    VirtualProviderIso,
} from "~/services/cycle";
import {
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { useKeepFormCurrent } from "~/components/common/forms";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { IsoServerFormSection } from "../sections/IsoServerFormSection";
import { UpdateIsoFormType } from "../types";

function getDefaultValues(iso?: VirtualProviderIso) {
    return {
        name: iso?.name || "",
        server: iso?.config?.server || null,
    };
}
export function UpdateIsoPanel({
    iso,
}: {
    iso: VirtualProviderIso | undefined;
}) {
    const form = useForm<UpdateIsoFormType>({
        defaultValues: getDefaultValues(iso),
        ...rhfConfig,
    });

    const {
        register,
        formState: { isSubmitting },
    } = form;

    const [updateIso] = useUpdateVirtualProviderIsoMutation();

    const onUpdate = (data: UpdateIsoFormType) => {
        return updateIso({
            integrationId: iso?.integration_id || "",
            isoId: iso?.id || "",
            body: data,
        })
            .unwrap()
            .catch(handleSubmitError(form.setError));
    };

    useKeepFormCurrent(form, iso, (i) => getDefaultValues(i));

    return (
        <Panel>
            <PanelTitle title="Update ISO" />
            <PanelContent>
                <RhfFormProvider
                    {...form}
                    onSubmit={form.handleSubmit(onUpdate)}
                >
                    <FormSectionHeader header="General" />
                    <FormSection>
                        <RhfFormField label="Name" required name="name">
                            <TextInput
                                {...register("name", {
                                    ...required(),
                                })}
                            />
                        </RhfFormField>
                    </FormSection>

                    <IsoServerFormSection />

                    <PanelFooter className="items-center justify-between">
                        <div>
                            <RhfGlobalFormError />
                        </div>
                        <PushAndHoldButton
                            flavor="primary"
                            icon={faEdit}
                            onClick={form.handleSubmit(onUpdate)}
                            isLoading={isSubmitting}
                        >
                            Update
                        </PushAndHoldButton>
                    </PanelFooter>
                </RhfFormProvider>
            </PanelContent>
        </Panel>
    );
}
