import {
    RhfFormField,
    TextInput,
    required,
} from "@cycleplatform/ui/components/forms";
import { Controller, useFormContext } from "react-hook-form";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { PublicNetworkSelect } from "~/components/containers/PublicNetworkSelect";
import { PortSelect } from "~/components/containers/PortSelect";
import { VmConfigFormData } from "../types";

export function VmNetworkSection() {
    const { register, control } = useFormContext<VmConfigFormData>();

    return (
        <Panel>
            <PanelTitle title="Network" className="flex justify-start px-4" />
            <PanelContent>
                <RhfFormField
                    label="Hostname"
                    name="config.network.hostname"
                    required
                >
                    <TextInput
                        {...register("config.network.hostname", {
                            ...required("Hostname cannot be empty"),
                        })}
                    />
                </RhfFormField>
                <RhfFormField
                    label="Public Network"
                    name="config.network.public"
                >
                    <Controller
                        render={({ field: { ref: _ref, ...field } }) => (
                            <PublicNetworkSelect {...field} />
                        )}
                        control={control}
                        name="config.network.public"
                    />
                </RhfFormField>

                <RhfFormField label="Ports" name="config.network.ports">
                    <Controller
                        render={({ field: { ref: _ref, ...field } }) => {
                            return <PortSelect {...field} />;
                        }}
                        control={control}
                        name="config.network.ports"
                    />
                </RhfFormField>
            </PanelContent>
        </Panel>
    );
}
