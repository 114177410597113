import { BillingMethod } from "~/services/cycle";
import { CreateBillingMethodFormType } from "./CreateBillingMethodForm";

export function getDefaultValues(
    method?: BillingMethod
): CreateBillingMethodFormType {
    return {
        name: method?.name || "",
        method_type: "cc",
        primary: method?.primary || false,
        address: method?.address || {
            country: "",
            zip: "",
        },
    };
}
