import { Button } from "@cycleplatform/ui/components/buttons";
import { useContext } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { LoadBalancerDialogContext } from "~/components/dialogs/environments/services/loadbalancer/context";
import { LoadBalancerFormValues } from "../../../../../form";
import { V1LbConfig, V1LbController } from "~/services/cycle";
import { getActualTransportConfig } from "@cycleplatform/core/modules/environments/loadbalancer/v1";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";

export function V1CustomizeButton({
    activePortIdx,
    activePortParam,
    currentConfig,
}: {
    activePortIdx: number;
    activePortParam: string;
    currentConfig: V1LbConfig;
}) {
    const { service } = useContext(LoadBalancerDialogContext);

    const { control, setValue } = useFormContext<LoadBalancerFormValues>();

    const { append } = useFieldArray({
        control,
        name: "config.details.controllers",
    });

    return (
        <Button
            flavor="primary"
            onClick={() => {
                const baseConfig = service?.base_configs?.v1 as V1LbConfig;

                if (!baseConfig || !currentConfig) {
                    return;
                }

                const transport = getActualTransportConfig(
                    parseInt(activePortParam),
                    baseConfig,
                    currentConfig
                ) as V1LbController["transport"];

                if (activePortIdx > -1) {
                    // If "port" is registered to the form => register just the transport portion
                    setValue(
                        `config.details.controllers.${activePortIdx}.transport`,
                        transport,
                        {
                            shouldDirty: true,
                        }
                    );
                } else {
                    append({
                        port: parseInt(activePortParam),
                        identifier: `port-${activePortParam}`,
                        transport,
                    });
                }
            }}
            icon={faEdit}
        >
            Customize
        </Button>
    );
}
