import {
    Checkbox,
    FormField,
    RhfFormField,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { Controller, FieldPath, useFormContext } from "react-hook-form";
import { useState } from "react";
import {
    FormattedOption,
    Listbox,
} from "@cycleplatform/ui/components/forms/select";
import { GenerateIsoFormType } from "../types";

export function IpxeNetworkInterfaceInput({
    field,
    label,
}: {
    field: FieldPath<GenerateIsoFormType>;
    label: string;
}) {
    const [checked, setChecked] = useState(false);
    const { setValue, control } = useFormContext<GenerateIsoFormType>();

    const options = [
        { value: 0, label: "0" },
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
    ];

    return (
        <div className="flex gap-4">
            <FormField label="Customize" className="!w-80">
                <Checkbox
                    checked={checked}
                    onChange={(ev) => {
                        if (!ev.target.checked) {
                            setValue(field, null, { shouldDirty: true });
                        }
                        setChecked(ev.target.checked);
                    }}
                />
            </FormField>

            <RhfFormField label={label} name={field} required={checked}>
                {checked ? (
                    <Controller
                        render={({ field: { ref: _ref, ...field } }) => (
                            <Listbox
                                {...field}
                                options={options}
                                formatDisplayValue={(o) => {
                                    return o?.label || "";
                                }}
                                formatOption={(o) => {
                                    return <FormattedOption label={o?.label} />;
                                }}
                                onChange={(v) =>
                                    field.onChange(v?.value || null)
                                }
                                value={options.find(
                                    (o) => o.value === field.value
                                )}
                            />
                        )}
                        control={control}
                        name={field}
                    />
                ) : (
                    <TextInput disabled />
                )}
            </RhfFormField>
        </div>
    );
}
