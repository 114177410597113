import { useDeleteVirtualMachineMutation } from "~/services/cycle";
import {
    CopyInput,
    SectionDisabledControl,
} from "@cycleplatform/ui/components/forms";
import { PageAside, PageContent } from "@cycleplatform/ui/components/page";
import { VmBasicSettingsPanel } from "./panels/VmBasicSettingsPanel";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { SkeletonButton } from "@cycleplatform/ui/components/loaders/skeleton";
import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import classNames from "classnames";
import { DeleteResource } from "~/components/common/resources/DeleteResource";
import { useContext, useEffect } from "react";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { VirtualMachineDialogContex } from "../../context";
import { HypervisorContainerPanel } from "./panels/VmHypervisorContainerPanel";
import { VmUpdateRootPasswordPanel } from "./panels/VmUpdateRootPasswordPanel";
import { VmUpdateSshKeyPanel } from "./panels/VmUpdateSshKeyPanel";
import { VmMigratePanel } from "./panels/VmMigratePanel";
import { useSearchParams } from "react-router-dom";

type VmSettingsTabProps = {
    focus: string | undefined;
};

export function VmSettingsTab({ focus }: VmSettingsTabProps) {
    const [, setSearchParams] = useSearchParams();
    const [deleteVm] = useDeleteVirtualMachineMutation();
    const { vm, environment, container } = useContext(
        VirtualMachineDialogContex
    );

    useEffect(() => {
        return () => {
            // remove any highlighted panels on unmount
            const params = new URLSearchParams(window.location.search);
            params.delete("dialog-focus");
            setSearchParams(params);
        };
    }, []);

    return (
        <DialogPageBody className="relative">
            <SectionDisabledControl
                className={classNames("flex  flex-1 items-start rounded-lg")}
                heightFull
                disabled={vm?.state.current === "deleted"}
            >
                <PageContent>
                    <VmBasicSettingsPanel />
                    <VmUpdateRootPasswordPanel />
                    <VmUpdateSshKeyPanel />
                </PageContent>

                <PageAside>
                    <Panel>
                        <PanelTitle title="Virtual Machine ID" />
                        <PanelContent>
                            {vm ? (
                                <CopyInput value={vm.id} />
                            ) : (
                                <SkeletonButton />
                            )}
                        </PanelContent>
                    </Panel>

                    <HypervisorContainerPanel />

                    <VmMigratePanel shouldFocus={focus === "migrate"} />

                    <DeleteResource
                        accessControl={{
                            aclResource: environment,
                            verifyFn: modifyAccessFn("virtual-machines-manage"),
                        }}
                        disabled={!vm || vm.lock}
                        warningPanel={
                            vm?.lock
                                ? "This virtual machine is locked and cannot be deleted."
                                : undefined
                        }
                        autoFocus={focus === "delete"}
                        resourceType="virtual machine"
                        resourceName={vm?.name}
                        onSubmit={async () => {
                            if (!vm) {
                                return;
                            }
                            return deleteVm({
                                virtualMachineId: vm.id,
                            }).unwrap();
                        }}
                    />
                </PageAside>
            </SectionDisabledControl>
        </DialogPageBody>
    );
}
