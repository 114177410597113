import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useNavigationExpandedState } from "./hooks";

type ResourceNavHeaderProps = {
    children?: React.ReactNode;
    className?: string;
};

/**
 * Creates the proper padding for use inside a ResourceNavLayout component for the header.
 *
 * Should contain search, filter, and action button functionality.
 */
export function ResourceNavHeader({
    children,
    className,
}: ResourceNavHeaderProps) {
    const [isExpanded, setIsExpanded] = useNavigationExpandedState();

    return (
        <div
            className={classNames(
                "flex items-center gap-2",
                "p-4 py-2 2xl:py-4",
                "w-full bg-white dark:bg-black 2xl:border-b",
                isExpanded && "border-b",
                className
            )}
        >
            {children}
            <button
                onClick={() => setIsExpanded(!isExpanded)}
                className={classNames("text-cycle-blue p-2", "2xl:hidden")}
            >
                <FontAwesomeIcon
                    icon={faChevronDown}
                    className={classNames(isExpanded && "rotate-180")}
                />
            </button>
        </div>
    );
}
