import { paramsSerializer } from "@cycleplatform/core/util/format";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
    GetVirtualProviderIsoDownloadUrlApiArg,
    GetVirtualProviderIsoDownloadUrlApiResponse,
    useGetVirtualProviderIsoDownloadUrlQuery,
} from "../cycle";

export const PORTAL_API_DOMAIN_OVERRIDES = {
    localhost: "https://portal.dev.cycle.io",
    "portal-local.dev.cycle.io": "https://portal.dev.cycle.io",
};

export type generateVpnFilesApiResponse = {
    //
};

export type generateVpnFilesApiArg = {
    base64Url: string;
};

export type GenerateVpnFilesReturn = {
    data: {
        ready?: boolean;
        error?: boolean;
    };
};

export type DockerhubSearchResponse = {
    num_pages: number;
    num_results: number;
    results: DockerHubSearchResponseResult[];
    page_size: number;
    query: string;
    page: number;
};

export type DockerHubSearchResponseResult = {
    is_automated: boolean;
    name: string;
    is_trusted: boolean;
    is_official: boolean;
    star_count: number;
    description: string;
};

export type DockerhubSearchArg = {
    query?: string;
    count?: number;
};

export const portalApi = createApi({
    reducerPath: "portalApi",
    baseQuery: fetchBaseQuery({
        baseUrl:
            (typeof window !== "undefined" &&
                PORTAL_API_DOMAIN_OVERRIDES[
                    window.location.hostname as "localhost"
                ]) ||
            "/",
        paramsSerializer: paramsSerializer(),
    }),
    endpoints: (build) => ({
        generateVpnFiles: build.mutation<
            generateVpnFilesApiResponse,
            generateVpnFilesApiArg
        >({
            query: (queryArg) => ({
                url: `vpn/${queryArg.base64Url}`,
                credentials: "include",
                method: "HEAD",
            }),
            transformResponse: () => {
                return {
                    data: {
                        ready: true,
                    },
                } as GenerateVpnFilesReturn;
            },
        }),

        dockerhubSearch: build.query<
            DockerhubSearchResponse,
            DockerhubSearchArg
        >({
            query: (queryArg) => ({
                url: `dockerhub/search`,
                credentials: "include",
                method: "GET",
                params: {
                    q: queryArg.query,
                    n: queryArg.count,
                },
            }),
        }),
    }),
});

export const { useGenerateVpnFilesMutation, useDockerhubSearchQuery } =
    portalApi;
