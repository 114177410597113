import { formatRFC3339, roundToNearestHours, subDays } from "date-fns";

export function buildMemberUsageQuery() {
    return {
        filter: {
            "range-start": formatRFC3339(
                roundToNearestHours(subDays(new Date(), 7), {
                    nearestTo: 1,
                    roundingMethod: "floor",
                })
            ),
            "range-end": formatRFC3339(
                roundToNearestHours(new Date(), {
                    nearestTo: 1,
                    roundingMethod: "floor",
                })
            ),
        },
        body: {
            criteria: {
                "metadata.metric": {
                    $in: ["memberships.num"],
                },
                "labels.state": "accepted",
            },
            pipeline: [
                {
                    $sort: {
                        time: 1,
                    },
                },
                {
                    $project: {
                        metadata: "$metadata",
                        labels: "$labels",
                        time: {
                            $dateTrunc: {
                                date: "$time",
                                unit: "day",
                                binSize: 1,
                            },
                        },
                        point: {
                            $arrayElemAt: [
                                {
                                    $last: "$points",
                                },
                                1,
                            ],
                        },
                    },
                },

                {
                    $group: {
                        _id: {
                            day: {
                                $dayOfYear: "$time",
                            },
                            hour: {
                                $hour: "$time",
                            },
                            minute: {
                                $minute: "$time",
                            },
                            server_id: "$metadata.component._id",
                        },
                        time: {
                            $last: "$time",
                        },
                        active: {
                            $max: {
                                $cond: {
                                    if: {
                                        $eq: ["$labels.state", "accepted"],
                                    },
                                    then: "$point",
                                    else: 0,
                                },
                            },
                        },
                        pending: {
                            $max: {
                                $cond: {
                                    if: {
                                        $eq: ["$labels.state", "pending"],
                                    },
                                    then: "$point",
                                    else: 0,
                                },
                            },
                        },
                    },
                },
                {
                    $project: {
                        time: "$time",
                        active: "$active",
                        pending: "$pending",
                    },
                },

                {
                    $sort: {
                        time: 1,
                    },
                },
                {
                    $project: {
                        _id: 0,
                    },
                },
            ],
        },
    };
}
