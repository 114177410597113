import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { useContext, useMemo } from "react";
import { formatLoadBalancerInstanceName } from "@cycleplatform/core/modules/environments/loadbalancer/format";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import {
    DateTimeFormats,
    formatBytes,
    formatDateString,
} from "@cycleplatform/core/util";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { Link } from "react-router-dom";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { getAbbreviatedNumberString } from "@cycleplatform/core/util/number";
import {
    useGenerateAggregatedMetricsQuery,
    useGetInstanceQuery,
} from "~/services/cycle";
import { LbTrafficQueryTelem, getLbTrafficQuery } from "./query";
import {
    pieCenterTextProps,
    pieProps,
} from "@cycleplatform/ui/components/recharts/pie/props";
import { CyclePieTooltip } from "@cycleplatform/ui/components/recharts/pie/CyclePieTooltip";
import { LoadBalancerDialogContext } from "../../../../context";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { NavIcons } from "~/components/layout/NavIcons";
import { SkeletonTable } from "@cycleplatform/ui/components/loaders/skeleton";
import { ThemedColors, useGetThemedChartColors } from "~/util/charts/hooks";
import { AGGREGATE_POLLING_MS } from "~/util/charts/util";
import classNames from "classnames";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";

export function OverviewSection({ port }: { port: number }) {
    const { environment } = useContext(LoadBalancerDialogContext);
    const { data: metrics, isLoading } = useGenerateAggregatedMetricsQuery(
        getLbTrafficQuery(environment?.id, port),
        { skip: !environment?.id, pollingInterval: AGGREGATE_POLLING_MS }
    );

    const telemetry = metrics?.data as LbTrafficQueryTelem | undefined;
    const instanceMetrics = telemetry?.[0]?.instances;

    return (
        <Panel>
            <div className="relative w-full">
                <PanelTitle
                    title="Load Balancers"
                    className="flex items-center justify-between"
                >
                    <div className="text-sm">
                        Last Updated:{" "}
                        {formatDateString(
                            telemetry?.[0]?.time || "",
                            DateTimeFormats["standard"]
                        )}
                    </div>
                </PanelTitle>
            </div>
            <PanelContent stretch>
                {isLoading || !telemetry ? (
                    <SkeletonTable />
                ) : (
                    <StyledDataTable>
                        <StyledTableHead>
                            <StyledHeaderCell className="w-[40%]">
                                Instance
                            </StyledHeaderCell>

                            <StyledHeaderCell className="min-w-[10rem]">
                                Data Transfer
                            </StyledHeaderCell>

                            <StyledHeaderCell className="w-1/8 text-center">
                                Connections
                            </StyledHeaderCell>
                            <StyledHeaderCell className="w-1/8 text-center">
                                Requests
                            </StyledHeaderCell>
                            <StyledHeaderCell className="w-[10rem] text-center">
                                Disconnects
                            </StyledHeaderCell>
                        </StyledTableHead>
                        <tbody>
                            {instanceMetrics ? (
                                instanceMetrics.map((metric) => (
                                    <InstanceRow
                                        instanceMetric={metric}
                                        instanceId={metric.instance_id}
                                    />
                                ))
                            ) : (
                                <StyledTableRow>
                                    <StyledCell colSpan={5}>
                                        <EmptyResource
                                            icon={NavIcons["telemetry"]}
                                            title="No Metrics"
                                            className="w-full border-none"
                                        />
                                    </StyledCell>
                                </StyledTableRow>
                            )}
                        </tbody>
                    </StyledDataTable>
                )}
            </PanelContent>
        </Panel>
    );
}

function InstanceRow({
    instanceMetric,
    instanceId,
}: {
    instanceId: string;
    instanceMetric: LbTrafficQueryTelem[number]["instances"][number];
}) {
    const { lbContainer } = useContext(LoadBalancerDialogContext);

    const { data: instance, isLoading } = useGetInstanceQuery(
        {
            containerId: lbContainer?.id || "",
            instanceId,
            include: ["locations"],
        },
        { skip: !lbContainer?.id }
    );

    const metricsMap = useMemo(() => {
        return instanceMetric?.metrics?.reduce((acc, cur) => {
            if (!cur.metric || !cur.metric.includes("lb.controller")) {
                return { ...acc };
            }

            return {
                ...acc,
                [cur.metric.split("lb.controller.")[1] || ""]: cur?.value,
            };
        }, {} as Record<string, number>);
    }, [instanceMetric]);

    const location =
        instance?.includes?.locations?.[instance?.data?.location_id || ""];

    return (
        <StyledTableRow key={instanceMetric.instance_id}>
            <StyledCell>
                <div
                    className={classNames(
                        isLoading && `${skeletonStyles} h-[20px] w-[15rem]`
                    )}
                >
                    {instance ? (
                        <>
                            {instance?.data?.state?.current !== "deleted" ? (
                                <Link
                                    to={generateDialogLink("container", {
                                        "dialog-container-id":
                                            instance?.data?.container_id,
                                        "dialog-instance-id":
                                            instance?.data?.id,
                                        "dialog-tab": "instances",
                                    })}
                                >
                                    {formatLoadBalancerInstanceName(
                                        instance?.data?.id || "",
                                        instance?.data?.provider?.vendor || "",
                                        location?.name || ""
                                    )}
                                </Link>
                            ) : (
                                <>
                                    {formatLoadBalancerInstanceName(
                                        instance?.data?.id || "",
                                        instance?.data?.provider?.vendor || "",
                                        location?.name || ""
                                    )}
                                </>
                            )}
                        </>
                    ) : null}
                </div>
            </StyledCell>
            <StyledCell>
                <div className="text-sm">
                    {`${
                        formatBytes(metricsMap.received_kb || 0 * 1024).value
                    } ${
                        formatBytes(metricsMap.received_kb || 0 * 1024).suffix
                    } Received`}
                </div>
                <div className="text-sm">
                    {`${
                        formatBytes(metricsMap.transmitted_kb || 0 * 1024).value
                    } ${
                        formatBytes(metricsMap.transmitted_kb || 0 * 1024)
                            .suffix
                    }  Transmitted`}
                </div>
            </StyledCell>
            <StyledCell className="text-center">
                {getAbbreviatedNumberString(metricsMap.connections)}
            </StyledCell>
            <StyledCell className="text-center">
                {getAbbreviatedNumberString(metricsMap.requests)}
            </StyledCell>
            <StyledCell className="flex justify-center">
                <div className="m-2 flex h-[4rem] w-[4rem]">
                    <DisconnectsChart
                        instanceMetric={instanceMetric}
                        instanceId={instance?.data?.id}
                    />
                </div>
            </StyledCell>
        </StyledTableRow>
    );
}

function DisconnectsChart({
    instanceMetric,
    instanceId,
}: {
    instanceMetric: LbTrafficQueryTelem[number]["instances"][number];
    instanceId?: string;
}) {
    const { colors } = useGetThemedChartColors();

    const chartData = instanceMetric?.metrics.reduce((acc, cur) => {
        if (
            !cur.metric ||
            !cur.metric.includes("disconnects.") ||
            cur.metric.includes("total")
        ) {
            return [...acc];
        }

        const metricName = cur.metric.split("disconnects.")?.[1] || "?";

        return [
            ...acc,
            {
                name: metricName,
                value: cur.value,
                color: getColor(metricName, colors),
            },
        ] as {
            name: string;
            value: number;
            color: string;
        }[];
    }, [] as { name: string; value: number; color: string }[]);

    const total = instanceMetric.metrics.find((m) =>
        m.metric.includes("disconnects.total")
    )?.value;

    if (!chartData) {
        return null;
    }
    return (
        <div className="flex w-full">
            <ResponsiveContainer width="100%" height="100%">
                <PieChart margin={{ top: 1, bottom: 1 }}>
                    <Pie {...pieProps} data={chartData} dataKey="value">
                        {chartData.map((entry, idx) => {
                            return (
                                <>
                                    <Cell
                                        className="cursor-pointer"
                                        key={`cell-${instanceId}-${idx}`}
                                        fill={entry.color}
                                        stroke="rgba(0,0,0,0)"
                                    />
                                </>
                            );
                        })}
                    </Pie>

                    <text {...pieCenterTextProps}>
                        {total ? (
                            <>{getAbbreviatedNumberString(total)}</>
                        ) : null}
                    </text>
                    <Tooltip
                        reverseDirection={{ x: true, y: true }}
                        allowEscapeViewBox={{ x: true, y: true }}
                        offset={-5}
                        content={(props) => (
                            <CyclePieTooltip {...props} dataLabel="name" />
                        )}
                    />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
}

function getColor(entry: string, colors: ThemedColors) {
    switch (entry) {
        case "no_error":
            return colors["green"].DEFAULT;
        case "destination_unavailable":
            return colors["red"].DEFAULT;
        case "request_invalid":
            return colors["red"].light;
        case "connection_invalid":
            return colors["orange"].DEFAULT;
        case "router_nomatch":
            return colors["orange"].light;
        case "unknown_error":
            return colors["red"].dark;
        case "timeout_idle":
            return colors["gray"].DEFAULT;
        case "timeout_init":
            return colors["yellow"].DEFAULT;
        default:
            return colors["orange"].light;
    }
}
