import { useFormContext } from "react-hook-form";
import { ContainerConfigFormData } from "../../types";
import { RhfFormField, TextInput } from "@cycleplatform/ui/components/forms";

export function NetworkConnectionsThreshold({
    thresholdIdx,
}: {
    thresholdIdx: number;
}) {
    const { register } = useFormContext<ContainerConfigFormData>();

    return (
        <div>
            <RhfFormField label="connections total" name="connections_total">
                <TextInput
                    type="number"
                    {...register(
                        `config.scale.thresholds.${thresholdIdx}.details.connections`,
                        { valueAsNumber: true }
                    )}
                />
            </RhfFormField>
        </div>
    );
}
