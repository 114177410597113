import {
    Checkbox,
    FormField,
    RhfFormField,
    TextInput,
    isUrl,
    startsWithHttpHttps,
} from "@cycleplatform/ui/components/forms";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { ContainerConfigFormData } from "../types";
import {
    FormattedOption,
    SelectInput,
} from "@cycleplatform/ui/components/forms/select";

export function LogsIntegration() {
    return (
        <div className="flex flex-col gap-2">
            <DrainInput />
        </div>
    );
}

export function DrainInput() {
    const { register, control, setValue } =
        useFormContext<ContainerConfigFormData>();

    const isCustomized = useWatch({
        name: "config.integrations.logs.drain.url",
        control,
    });

    return (
        <div className="mt-4 flex gap-8">
            <FormField label="Enable Log Drain">
                <Checkbox
                    checked={typeof isCustomized === "string"}
                    onChange={(ev) =>
                        setValue(
                            "config.integrations.logs.drain",
                            ev.target.checked ? { url: "" } : null,
                            {
                                shouldDirty: true,
                            }
                        )
                    }
                />
            </FormField>

            {typeof isCustomized === "string" ? (
                <>
                    <RhfFormField
                        label="Drain URL"
                        name={"config.integrations.logs.drain.url"}
                        required
                    >
                        <TextInput
                            {...register("config.integrations.logs.drain.url", {
                                disabled: isCustomized === undefined,
                                validate: {
                                    ...isUrl(),
                                    ...startsWithHttpHttps(),
                                },
                            })}
                        />
                    </RhfFormField>
                    <RhfFormField
                        label="format"
                        name="config.integrations.logs.drain.format"
                    >
                        <Controller
                            render={({ field: { ref: _ref, ...field } }) => {
                                const options = [
                                    {
                                        label: "NDJSON - HTTP Headers",
                                        value: "ndjson-headers",
                                        detail: "Meta information is moved to http headers to reduce bandwidth usage.",
                                    },
                                    {
                                        label: "NDJSON - Raw ",
                                        value: "ndjson-raw",
                                        detail: "All instance metadata is embedded in every line of log output.",
                                    },
                                ];
                                return (
                                    <SelectInput
                                        {...field}
                                        options={options}
                                        isNullable
                                        placeholder="NDJSON - Headers"
                                        formatDisplayValue={(o) => {
                                            return o?.label || "";
                                        }}
                                        formatOption={(o) => {
                                            return (
                                                <FormattedOption
                                                    label={o?.label}
                                                    detail={o?.detail}
                                                />
                                            );
                                        }}
                                        value={options.find(
                                            (o) => o.value === field.value
                                        )}
                                        onChange={(v) =>
                                            field.onChange?.(v?.value || null)
                                        }
                                    />
                                );
                            }}
                            control={control}
                            name={"config.integrations.logs.drain.format"}
                        />
                    </RhfFormField>
                </>
            ) : (
                <>
                    <FormField label="Drain URL">
                        <TextInput disabled placeholder="No log drain" />
                    </FormField>
                    <FormField label="Format">
                        <SelectInput disabled />
                    </FormField>
                </>
            )}
        </div>
    );
}
