import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "../../../tooltip";
import classNames from "classnames";
import { faNetworkWired } from "@fortawesome/pro-duotone-svg-icons";
import { StatusErrorIcon } from "./StatusErrorIcon";
import { StatusWarningIcon } from "./StatusWarningIcon";
import { NetworkDiagramStatus } from "../util";

export function LoadBalancerComponent({
    status,
    formatTooltip,
}: {
    status: NetworkDiagramStatus;
    formatTooltip: (message: string) => string;
}) {
    return (
        <Tooltip
            disabled={!status.lb.message}
            message={
                <>
                    {formatTooltip(status.lb.message)
                        .split("\n")
                        .map((line, idx) => (
                            <div key={`${line}-${idx}`}>{line}</div>
                        ))}
                </>
            }
        >
            <span className="fa-layers fa-fw mr-4">
                <FontAwesomeIcon
                    className={classNames(
                        {
                            "text-cycle-blue": status.lb.status === "ok",
                            "text-error/50": status.lb.status === "error",
                            "text-warning/50": status.lb.status === "warning",
                            "text-cycle-gray/50 dark:text-cycle-gray-light/40":
                                status.lb.status === "disabled",
                        },
                        "text-3xl"
                    )}
                    icon={faNetworkWired}
                />
                {status.lb.status === "error" ? (
                    <StatusErrorIcon transform={{ x: 20, y: -15 }} />
                ) : null}
                {status.lb.status === "warning" ? (
                    <StatusWarningIcon transform={{ x: 20, y: -15 }} />
                ) : null}
            </span>
        </Tooltip>
    );
}
