import { isDialogOpen } from "~/components/dialogs/helpers";
import { MultiStepFormDialog } from "../../MultiStepFormDialog/MultiStepFormDialog";
import { NavIcons } from "~/components/layout/NavIcons";
import { MultiStepFormSteps } from "../../MultiStepFormDialog/components/MultStepFormSteps";
import { MultiStepFormStep } from "../../MultiStepFormDialog/components/MultiStepFormStep";
import { VmBasicsStep } from "./form/VmBasicsStep";
import { VmImageStep } from "./form/VmImageStep";
import { VmStorageStep } from "./form/VmStorageStep";
import { VmConfigStep } from "./form/VmConfigStep";
import { VmReviewStep } from "./form/VmReviewStep";
import { VmResourcesStep } from "./form/VmResourcesStep";
import { VmDeploymentStep } from "./form/VmDeploymentStep";

export function VmCreateDialog() {
    return (
        <MultiStepFormDialog
            title="New Virtual Machine"
            isOpen={isDialogOpen("virtual-machine-create")}
            icon={NavIcons["vm"]}
            formKey="create-virtual-machine"
            className="h-[85%]"
        >
            <MultiStepFormSteps>
                <MultiStepFormStep name="Basics">
                    <VmBasicsStep />
                </MultiStepFormStep>
                <MultiStepFormStep name="Image">
                    <VmImageStep />
                </MultiStepFormStep>
                <MultiStepFormStep name="Resources">
                    <VmResourcesStep />
                </MultiStepFormStep>
                <MultiStepFormStep name="Storage">
                    <VmStorageStep />
                </MultiStepFormStep>
                <MultiStepFormStep name="Network">
                    <VmConfigStep />
                </MultiStepFormStep>
                <MultiStepFormStep name="Deployment">
                    <VmDeploymentStep />
                </MultiStepFormStep>
                <MultiStepFormStep name="Review">
                    <VmReviewStep />
                </MultiStepFormStep>
            </MultiStepFormSteps>
        </MultiStepFormDialog>
    );
}
