import {
    InputRow,
    RhfFormField,
    TextInput,
    isDurationString,
} from "@cycleplatform/ui/components/forms";
import { useFormContext } from "react-hook-form";
import { VmConfigFormData } from "../../types";

export function DeployShutdownPolicy() {
    const { register, control, watch, unregister } =
        useFormContext<VmConfigFormData>();

    return (
        <InputRow>
            <RhfFormField
                label="graceful timeout"
                name="config.deploy.shutdown.graceful_timeout"
            >
                <TextInput
                    defaultValue={"5s"}
                    {...register("config.deploy.shutdown.graceful_timeout", {
                        validate: {
                            ...isDurationString(),
                        },
                    })}
                />
            </RhfFormField>
        </InputRow>
    );
}
