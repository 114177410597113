import { formatDateString } from "@cycleplatform/core/util";
import {
    Panel,
    PanelContent,
    PanelDetail,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { VirtualProviderIso } from "~/services/cycle";

export function IsoDetailsPanel({
    iso,
}: {
    iso: VirtualProviderIso | undefined;
}) {
    return (
        <Panel>
            <PanelTitle title="ISO Details" />
            <PanelContent>
                <PanelDetail label="token">{iso?.config?.token}</PanelDetail>

                {iso?.events?.created ? (
                    <PanelDetail label="created">
                        {formatDateString(
                            iso?.events?.created,
                            "MMM dd yyyy hh:mm aaa"
                        )}
                    </PanelDetail>
                ) : null}

                {iso?.config?.ipxe?.vlan_id ? (
                    <PanelDetail label="ipxe vlan id">
                        {iso?.config?.ipxe?.vlan_id}
                    </PanelDetail>
                ) : null}

                {iso?.config?.ipxe?.network_interface ? (
                    <PanelDetail label="ipxe network interface">
                        {iso?.config?.ipxe?.network_interface}
                    </PanelDetail>
                ) : null}

                {iso?.config?.ipxe?.static_ip ? (
                    <PanelDetail label="ipxe static ip">
                        {iso?.config?.ipxe?.static_ip}
                    </PanelDetail>
                ) : null}

                {iso?.config?.ipxe?.netmask ? (
                    <PanelDetail label="ipxe netmask">
                        {iso?.config?.ipxe?.netmask}
                    </PanelDetail>
                ) : null}

                {iso?.config?.ipxe?.gateway_ip ? (
                    <PanelDetail label="ipxe gateway ip">
                        {iso?.config?.ipxe?.gateway_ip}
                    </PanelDetail>
                ) : null}

                {iso?.config?.ipxe?.dns_ip ? (
                    <PanelDetail label="ipxe dns ip">
                        {iso?.config?.ipxe?.dns_ip}
                    </PanelDetail>
                ) : null}
            </PanelContent>
        </Panel>
    );
}
