import { Controller, useFormContext } from "react-hook-form";
import { ResourceComboBox } from "~/components/common/forms";
import {
    Container,
    useGetCompatibleImagesQuery,
    useGetImagesQuery,
} from "~/services/cycle";
import { FormatImageSourceMessage } from "@cycleplatform/ui/components/resources";
import { formatBytesString } from "@cycleplatform/core/util/bytes";
import { components } from "@cycleplatform/core/modules/api/__generated";
import { ReimageContainerSubmitType } from "./ReimageContainer";
import { required } from "@cycleplatform/ui/components/forms";
import { FormattedOption } from "@cycleplatform/ui/components/forms/select";
import { Badge } from "@cycleplatform/ui/components/badges";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

type ImageSelectProps = {
    container: Container;
    currentImageId?: string;
    allowIncompatible?: boolean;
};

export function ImageSelect({
    container,
    currentImageId,
    allowIncompatible,
}: ImageSelectProps) {
    const {
        data: images,
        isFetching,
        error,
    } = useGetCompatibleImagesQuery(
        {
            containerId: container?.id,
            sort: ["-id"],
            page: {
                number: 1,
                size: 100,
            },
        },
        { skip: allowIncompatible }
    );

    const { data: allImages } = useGetImagesQuery(
        {
            sort: ["-id"],
            page: {
                number: 1,
                size: 100,
            },
            filter: {
                state: "live",
            },
        },
        { skip: !allowIncompatible }
    );

    const { control } = useFormContext<ReimageContainerSubmitType>();
    if (error) {
        throw error;
    }
    return (
        <Controller
            render={({ field: { ref: _ref, ...field } }) => (
                <label>
                    <ResourceComboBox
                        {...field}
                        filterFields={["id", "name"]}
                        isLoading={isFetching}
                        resources={
                            allowIncompatible
                                ? allImages?.data || []
                                : images?.data || []
                        }
                        isOptionValid={(i) => currentImageId !== i.id}
                        formatDisplayValue={(i) => i?.name || ""}
                        formatOption={(i, disabled) => (
                            <FormattedOption
                                label={`${i.name} (${formatBytesString(
                                    i.size
                                )})`}
                                disabled={disabled}
                                detail={
                                    <>
                                        {i.about && (
                                            <div>{i.about.description}</div>
                                        )}
                                        <FormatImageSourceMessage
                                            image={
                                                i as components["schemas"]["Image"]
                                            }
                                        />
                                    </>
                                }
                            >
                                {currentImageId && i.id === currentImageId ? (
                                    <Badge className="bg-cycle-black flex gap-2 text-sm">
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className="text-success"
                                        />
                                        current
                                    </Badge>
                                ) : null}
                            </FormattedOption>
                        )}
                    />
                </label>
            )}
            rules={{ ...required() }}
            control={control}
            name="image_id"
        />
    );
}
