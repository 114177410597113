import { EventType, LookupComponentsApiArg } from "~/services/cycle";

export type ComponentInputArg =
    LookupComponentsApiArg["body"]["components"][number];

const componentTypes: ComponentInputArg["type"][] = [
    "image.source",
    "image",
    "container",
    "container.instance",
    "hub",
    "hub.api_key",
    "hub.membership",
    "hub.role",
    "sdn.network",
    "dns.zone",
    "dns.zone.record",
    "pipeline",
    "pipeline.run",
    "stack",
    "stack.build",
    "infrastructure.provider",
    "infrastructure.autoscale.group",
    "infrastructure.server",
    "infrastructure.cluster",
    "infrastructure.ips.pool",
    "billing.invoice",
    "billing.method",
    "environment",
    "environment.scoped-variable",
];

export type GenericEvent = {
    labels?: { [key: string]: string };
    event: EventType;
    time: string;
    text?: string;
    type:
        | "success"
        | "info"
        | "warning"
        | "error"
        | "alert"
        | "notice"
        | "audit";
    component: {
        id: string;
        type: ComponentInputArg["type"];
    };
    cluster?: string;
    [key: string]: unknown;
};
/**
 *
 * @param events is a general events output -> taken from generateAggregatedEventsQuery
 * @returns an array of components used as the input for the components section of useLookupComponentsQuery
 *
 * Takes output of generateAggregatedEventsQuery and generates input for useLookupComponentsQuery
 * This is used to get all the components neccessary to generate event links for event messages
 */
export function eventsToComponentInput(
    events?: GenericEvent[]
): ComponentInputArg[] {
    return (
        events?.reduce((acc, cur) => {
            const derivedLabelComponents = Object.entries(
                cur.labels || {}
            ).reduce((labelAcc, [labelKey, labelValue]) => {
                let type = labelKey.split("_id")[0];

                switch (type) {
                    case "instance":
                        type = "container.instance";
                        break;
                    case "neighbor:server":
                        type = "infrastructure.server";
                        break;
                    case "destination:server":
                        type = "infrastructure.server";
                        break;
                    default:
                }

                if (!componentTypes.some((t) => t === type)) {
                    return [...labelAcc];
                }

                return [
                    ...labelAcc,
                    { id: labelValue, type: type as ComponentInputArg["type"] },
                ];
            }, [] as ComponentInputArg[]);

            const acct =
                cur.user_id && cur.user_type === "account"
                    ? ([
                          {
                              id: cur.user_id,
                              type: cur.user_type as ComponentInputArg["type"],
                          },
                      ] as ComponentInputArg[])
                    : [];

            if (componentTypes.includes(cur.component.type)) {
                return [
                    ...acc,
                    ...derivedLabelComponents,
                    cur.component,
                    ...acct,
                ];
            } else {
                return [...acc, ...derivedLabelComponents, ...acct];
            }
        }, [] as ComponentInputArg[]) || []
    );
}
