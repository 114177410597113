import { FormattedOption } from "./FormattedOption";
import { Listbox } from "./Listbox";

export type Option = {
    value?: string | null;
    label: string;
    disabled?: boolean;
    detail?: string;
};

export type BasicSelectProps = {
    name?: string;
    value?: string | null;
    options: Option[];
    disabled?: boolean;
    placeholder?: string;
    onBlur?: () => void;
    onChange?: (value?: string | null) => void;
    groupOptions?: (options: Option[]) => Record<string, Option[]>;
    className?: string;
    isNullable?: boolean;
    defaultValue?: string | null;
    optionsClassName?: string;
};

/**
 * Basic select is used when you want to present a list of options of type Option
 * The label is the display string for the select
 * The value is the string that is submitted
 */
export function BasicSelect({
    options,
    placeholder,
    className,
    defaultValue,
    ...props
}: BasicSelectProps) {
    return (
        <Listbox
            {...props}
            className={className}
            placeholder={placeholder}
            options={options}
            formatDisplayValue={(o) => {
                return o?.label || "";
            }}
            formatOption={(o) => {
                return <FormattedOption label={o?.label} detail={o?.detail} />;
            }}
            value={options.find((o) => o.value === props.value)}
            isOptionValid={(o) => !o.disabled}
            onChange={(v) => props.onChange?.(v?.value || undefined)}
            defaultValue={options.find((o) => o.value === defaultValue)}
        />
    );
}
