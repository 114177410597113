import { Panel, PanelTitle } from "@cycleplatform/ui/components/panels";
import { PanelContentBoundary } from "~/components/layout/panels/PanelContentBoundary";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { SkeletonTable } from "@cycleplatform/ui/components/loaders/skeleton";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import {
    InfrastructureIpPool,
    Ip,
    Server,
    useCreateVirtualMachineJobMutation,
    useGetAvailableIpsQuery,
    useLookupComponentsQuery,
    VirtualMachine,
    VirtualMachineIpAllocateAction,
} from "~/services/cycle";
import { PropsWithChildren, useCallback, useContext, useRef } from "react";
import { VirtualMachineDialogContex } from "../../../context";
import { NavIcons } from "~/components/layout/NavIcons";
import {
    Button,
    LoaderButton,
    PushAndHoldButton,
} from "@cycleplatform/ui/components/buttons";
import { faBan, faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { PositionedMenu } from "@cycleplatform/ui/components/menus";
import { AccessControlOverlay } from "~/components/common/buttons";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
    CopyInput,
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import { useJobTracker } from "~/modules/jobs/hooks";
import { handleSubmitError } from "~/components/forms/util";
import { IpSelect } from "~/components/infrastructure/ips/IpSelect";
import { pushNotification } from "~/modules/notifications/slice";
import { useAppDispatch } from "~/hooks";
import { Link } from "react-router-dom";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledIconHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { RhfIdCheckboxSelectAll } from "~/components/common/forms/IdSelect/RhfIdCheckboxSelectAll";
import { RhfIdCheckbox } from "~/components/common/forms/IdSelect/RhfIdCheckbox";
import {
    IdSelectType,
    useIdSelectForm,
} from "~/components/common/forms/IdSelect/hooks";
import { $error } from "@cycleplatform/core/util/log";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";

export function VmStaticPublicIpsPanel() {
    const { vm } = useContext(VirtualMachineDialogContex);

    return (
        <Panel>
            <PanelTitle title="Static Public IPs" />
            <PanelContentBoundary>
                {vm?.container_id ? (
                    <>
                        <IpsPanel />
                    </>
                ) : vm ? (
                    <EmptyResource
                        className="flex  items-center justify-center border-none"
                        icon={faPlay}
                        title="Start virtual machine to initialize the network"
                    >
                        <p className="text-center">
                            This virtual machine has not yet been started.
                        </p>
                    </EmptyResource>
                ) : (
                    <SkeletonTable />
                )}
            </PanelContentBoundary>
        </Panel>
    );
}

export function IpsPanel() {
    const { vm } = useContext(VirtualMachineDialogContex);
    const form = useIdSelectForm();

    const selectedIds = useWatch({
        control: form.control,
        name: "ids",
    });
    const [createVmJob] = useCreateVirtualMachineJobMutation();
    const [trackJob, { isTrackingJob }] = useJobTracker();
    const dispatch = useAppDispatch();
    const unallocatingIp = useRef<string>();
    const gatewayServers = useGetGatewayServers(vm?.meta?.ips || []);

    const unallocate = useCallback(
        (data: IdSelectType) => {
            return Promise.all(
                data.ids.map((ipId) => {
                    unallocatingIp.current = ipId;
                    return trackJob(
                        createVmJob({
                            virtualMachineId: vm?.id || "",
                            virtualMachineTask: {
                                action: "ip.unallocate",
                                contents: {
                                    ip_id: ipId,
                                },
                            },
                        }).unwrap()
                    )
                        .then(() => form.reset())
                        .catch((err) => {
                            dispatch(
                                pushNotification({
                                    title: `Error unallocating virtual machine IP`,
                                    message: `${err?.data?.error?.title}`,
                                    type: "error",
                                    icon: "infrastructureIps",
                                })
                            );
                        })
                        .finally(() => (unallocatingIp.current = undefined));
                })
            );
        },
        [createVmJob, trackJob, vm]
    );

    if (vm && vm?.config.network.public !== "enable") {
        return (
            <EmptyResource
                className="border-none"
                icon={faBan}
                title="Public Network Disabled"
            >
                <p className="text-center">
                    The public network is disabled for this virtual machine.
                    Enable it to access this virtual machine over the public
                    internet.
                </p>
            </EmptyResource>
        );
    }

    return (
        <>
            {vm?.meta?.ips?.length ? (
                <div className="">
                    <RhfFormProvider {...form} id="id-select" className="">
                        <StyledDataTable>
                            <StyledTableHead>
                                <StyledIconHeaderCell>
                                    <RhfIdCheckboxSelectAll
                                        resources={vm.meta.ips}
                                    />
                                </StyledIconHeaderCell>
                                <StyledHeaderCell>IP Address</StyledHeaderCell>
                                <StyledHeaderCell>
                                    Gateway Server
                                </StyledHeaderCell>
                            </StyledTableHead>
                            <tbody>
                                {vm.meta.ips.map((ip) => (
                                    <StyledTableRow key={ip.id}>
                                        <StyledCell>
                                            <RhfIdCheckbox id={ip.id} />
                                        </StyledCell>
                                        <StyledCell className="w-1/2 pr-8">
                                            <CopyInput value={ip.address} />
                                        </StyledCell>
                                        <StyledCell className="max-w-[5rem] truncate">
                                            <Link
                                                to={`/infrastructure/servers/${
                                                    gatewayServers?.[ip.pool_id]
                                                        ?.id
                                                }`}
                                            >
                                                {
                                                    gatewayServers?.[ip.pool_id]
                                                        ?.hostname
                                                }
                                            </Link>
                                        </StyledCell>
                                    </StyledTableRow>
                                ))}
                            </tbody>
                        </StyledDataTable>
                    </RhfFormProvider>
                </div>
            ) : (
                <>
                    <EmptyResource
                        className="border-none"
                        icon={NavIcons["environmentGateway"]}
                        title="No Public IPs Allocated"
                    >
                        <p className="text-center">
                            Public IPs for a virtual machine are assigned to the
                            gateway service. Use the button below to assign an
                            IP address for this virtual machine.
                        </p>
                    </EmptyResource>
                </>
            )}
            <PanelFooter>
                <PushAndHoldButton
                    form="ip-select"
                    disabled={selectedIds.length === 0}
                    icon={faTrash}
                    flavor="discard"
                    isLoading={isTrackingJob}
                    onClick={form.handleSubmit(unallocate)}
                >
                    Delete Selected
                </PushAndHoldButton>
                <AddPublicIpPopup>
                    <Tooltip message="Manually assign an IP address to this virtual machine.">
                        <Button icon={faPlus}>Allocate IP Address</Button>
                    </Tooltip>
                </AddPublicIpPopup>{" "}
            </PanelFooter>
        </>
    );
}

function AddPublicIpPopup({ children }: PropsWithChildren) {
    const { vm, environment } = useContext(VirtualMachineDialogContex);

    return (
        <AccessControlOverlay
            aclResource={environment}
            verifyFn={modifyAccessFn("virtual-machines-manage")}
        >
            <PositionedMenu
                className={"w-[30rem]"}
                placement="top-start"
                render={(_, setIsOpen) => {
                    if (!vm) {
                        return null;
                    }
                    return (
                        <>
                            <div className="flex items-center gap-2 pb-4 text-lg">
                                <FontAwesomeIcon
                                    icon={NavIcons["infrastructureIps"]}
                                    className="text-cycle-blue"
                                />
                                Allocate IP
                            </div>
                            <AddPublicIpForm vm={vm} setIsOpen={setIsOpen} />
                        </>
                    );
                }}
            >
                {children}
            </PositionedMenu>
        </AccessControlOverlay>
    );
}

function AddPublicIpForm({
    vm,
    setIsOpen,
}: {
    vm: VirtualMachine;
    setIsOpen: (_: boolean) => void;
}) {
    const form = useForm<VirtualMachineIpAllocateAction["contents"]>({
        defaultValues: {},
    });

    const [trackJob] = useJobTracker();
    const [createVmJob] = useCreateVirtualMachineJobMutation();
    const { data: ips } = useGetAvailableIpsQuery({
        virtualMachineId: vm.id,
    });

    const { handleSubmit, setError, control, formState } = form;

    const onSubmit = (data: VirtualMachineIpAllocateAction["contents"]) => {
        return trackJob(
            createVmJob({
                virtualMachineId: vm.id || "",
                virtualMachineTask: {
                    action: "ip.allocate",
                    contents: data,
                },
            }).unwrap()
        )
            .then(() => {
                // small delay to give ips time to populate after the job completes
                // https://linear.app/cycleplatform/issue/ENG-3186/fix-ip-allocation-popup-menu-from-closing-too-early
                return new Promise((res) => setTimeout(res, 1000));
            })
            .then(() => {
                form.reset();
                setIsOpen(false);
            })
            .catch((err) => {
                handleSubmitError(setError)(err);
            });
    };

    return (
        <RhfFormProvider {...form} onSubmit={handleSubmit(onSubmit)}>
            <RhfFormField label="IP Address" name="ip_address">
                <Controller
                    render={({ field: { ref: _ref, ...field } }) => (
                        <IpSelect {...field} ips={ips?.data || []} />
                    )}
                    control={control}
                    name="ip_id"
                />
            </RhfFormField>
            <RhfGlobalFormError />
            <LoaderButton
                type="submit"
                icon={faPlus}
                isLoading={formState.isSubmitting}
            >
                Allocate IP
            </LoaderButton>
        </RhfFormProvider>
    );
}

function useGetGatewayServers(ips: Ip[]): Record<string, Server> | null {
    const poolIds = ips.map((ip) => ip.pool_id);

    const { data: ipPools, error: poolError } = useLookupComponentsQuery(
        {
            body: {
                components: poolIds.map((id) => ({
                    type: "infrastructure.ips.pool",
                    id,
                })),
            },
        },
        { skip: !ips.length }
    );

    const { data: servers, error: serverError } = useLookupComponentsQuery(
        {
            body: {
                components: ipPools
                    ? Object.values(ipPools.data).map((pool) => ({
                          type: "infrastructure.server",
                          id: (pool as InfrastructureIpPool).server_id,
                      }))
                    : [],
            },
        },
        {
            skip: !ipPools,
        }
    );

    if (poolError || serverError) {
        $error(`unable to get gateway servers: ${poolError || serverError}`);
        return null;
    }

    if (!ipPools?.data || !servers?.data) {
        return null;
    }

    return (Object.values(ipPools.data) as InfrastructureIpPool[]).reduce(
        (acc, cur) => {
            acc[cur.id] = servers.data[cur.server_id] as Server;
            return acc;
        },
        {} as Record<string, Server>
    );
}
