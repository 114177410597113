import { components } from "../api/__generated";

/**
 * Gets the instance that is active for a given list of virtual
 * machine instances. Necessary because in some circumstances,
 * a virtual machine may have more than one instance (such as migrating),
 * but only one of them will be legitimate at a time.
 * @param instances Instance(s) of the VM
 * @returns the active VM instance
 */
export function getActiveVmInstance(
    instances: components["schemas"]["Instance"][]
): components["schemas"]["Instance"] | undefined {
    return instances.find((i) => i.ready_state === "active");
}
