import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import {
    Container,
    GetContainersApiArg,
    useGetContainersQuery,
} from "~/services/cycle";
import { ResourceComboBox } from "../common/forms";
import { ContainerPublicNetworkBadge } from "@cycleplatform/ui/components/resources";
import { ContainerDeploymentBadge } from "./ContainerDeploymentBadge";
import { Badge } from "@cycleplatform/ui/components/badges";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/pro-solid-svg-icons";

export type ContainerSelectProps<MULTI extends boolean = false> = {
    value: MULTI extends true ? Array<string> : string | undefined;
    disabled?: boolean;
    isNullable?: boolean;
    onChange: MULTI extends true
        ? (ids: string[], containers: Container[]) => void
        : (
              id: string | undefined | null,
              container: Container | undefined
          ) => void;
    skip?: boolean;
    filter?: GetContainersApiArg["filter"];
    placeholder?: string;
    multiple?: MULTI;
    additionalContainers?: Container[];
    isCreatable?: boolean;
};

export function ContainerSelect<MULTI extends boolean = false>({
    value,
    disabled,
    onChange,
    isNullable,
    filter,
    skip,
    multiple,
    placeholder,
    additionalContainers = [],
    isCreatable,
}: ContainerSelectProps<MULTI>) {
    // TODO - there is a risk that the container set as 'value' is not on the list
    // returned by the query. May need an additional query for the specific container
    // if not initially returned and then merge them.
    const { currentData: containers, error } = useGetContainersQuery(
        {
            sort: ["-id"],
            filter,
            page: {
                number: 1,
                // TODO - infinite scroll
                size: 100,
            },
        },
        { skip }
    );

    if (error) {
        throw error;
    }

    return (
        <ResourceComboBox
            filterFields={["id", "name"]}
            value={value}
            multiple={multiple}
            onChange={onChange}
            placeholder={placeholder}
            isCreatable={isCreatable}
            isNullable={isNullable}
            disabled={disabled}
            resources={[...additionalContainers, ...(containers?.data || [])]}
            formatDisplayValue={(c) => {
                if (!c) return "";

                return c.name;
            }}
            formatOption={(c) => {
                return (
                    <div className="flex justify-between p-2">
                        <div>
                            <strong>{c.name}</strong>
                        </div>
                        <div className="flex gap-2">
                            {!!c.image.service ? (
                                <Badge>
                                    <FontAwesomeIcon
                                        icon={faGear}
                                        className="pr-2"
                                    />{" "}
                                    service
                                </Badge>
                            ) : null}
                            <ContainerDeploymentBadge container={c} />
                            <ContainerPublicNetworkBadge c={c} />
                            <ResourceStateBadge state={c.state} />
                        </div>
                    </div>
                );
            }}
        />
    );
}
