import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { TextInput } from "@cycleplatform/ui/components/forms";
import { useContext, useMemo, useState } from "react";
import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import { PageAside } from "@cycleplatform/ui/components/page";
import { DialogResourceList } from "~/components/layout/resources";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadBalancerDialogContext } from "../../context";
import { PortList } from "./PortList";
import { PortContent } from "./PortContent";
import { V1AddControllerMenu } from "./form/v1AddPort/V1AddControllerMenu";
import { useGenerateAggregatedMetricsQuery } from "~/services/cycle";
import { getLbControllersQuery } from "../../util/getLbControllersQuery";
import { AGGREGATE_POLLING_MS } from "~/util/charts/util";
import { getDefaultLbFormVals } from "../form";

export function V1Controllers() {
    const { service, environment } = useContext(LoadBalancerDialogContext);
    const [search, setSearch] = useState("");

    const details = getDefaultLbFormVals(service)?.config;

    const { data: telemetry } = useGenerateAggregatedMetricsQuery(
        getLbControllersQuery(environment?.id),
        { skip: !environment?.id, pollingInterval: AGGREGATE_POLLING_MS }
    );

    const telemControllers = (telemetry?.data?.[0]?.telemControllers ||
        []) as number[];

    const configControllers = useMemo(
        () =>
            details?.type === "v1"
                ? details?.details?.controllers?.map((c) => c.port)
                : [],
        [details?.type, details?.details]
    );

    const controllers = useMemo(
        () => [...new Set([...configControllers, ...telemControllers])],
        [telemControllers, configControllers]
    );

    if (!service) {
        return null;
    }

    return (
        <div className="h-full">
            <DialogPageBody className="!p-0">
                <PageAside className="relative h-full !w-auto border-r bg-white dark:bg-black">
                    <DialogResourceList>
                        <div className="flex items-center gap-2 pb-2 pl-1 pr-4">
                            <TextInput
                                placeholder="Search Ports"
                                onChange={(v) => setSearch(v.target.value)}
                                value={search}
                                prefix={<FontAwesomeIcon icon={faSearch} />}
                                className="!mb-0"
                            />
                            <V1AddControllerMenu />
                        </div>

                        <PortList
                            search={search}
                            controllers={controllers}
                            telemControllers={telemControllers}
                        />
                    </DialogResourceList>
                </PageAside>

                <PortContent />
            </DialogPageBody>
        </div>
    );
}
