import { isZeroTime, zeroTimeString } from "@cycleplatform/core/util";
import { format } from "date-fns";

type FormattedDateProps = {
    date: Date | string;
    dateFormat?: string;
    zeroTimeStringMessage?: string;
    className?: string;
};

export function FormattedDate({
    date,
    dateFormat = "PP HH:mm",
    zeroTimeStringMessage,
    className,
}: FormattedDateProps) {
    const d = new Date(date);

    if (isNaN(d.getTime()) || date === zeroTimeString) {
        return zeroTimeStringMessage ? (
            <time className={className} dateTime={zeroTimeString}>
                {zeroTimeStringMessage}
            </time>
        ) : (
            <time className={className} dateTime={zeroTimeString} />
        );
    }

    return (
        <time className={className} dateTime={d.toISOString()}>
            {format(d, dateFormat)}
        </time>
    );
}
