import { Button, LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    RhfFormProvider,
    TextInput,
    RhfGlobalFormError,
    required,
    FormSectionHeader,
    FormSection,
    RhfDataSizeInput,
    RhfSliderInput,
    RHF_GLOBAL_ERROR,
} from "@cycleplatform/ui/components/forms";
import {
    faArrowLeft,
    faArrowRight,
    faHardDrive,
    faPlus,
    faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import {
    Controller,
    useFieldArray,
    useForm,
    useFormContext,
} from "react-hook-form";
import { rhfConfig } from "~/components/forms/util";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { addStepData, getCurrentForm } from "~/modules/forms/slice";
import { useContext, useEffect } from "react";
import { MultiStepDialogContext } from "~/components/dialogs/MultiStepFormDialog/context";
import {
    DialogColumn,
    DialogFooter,
} from "@cycleplatform/ui/components/dialog/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VmStorageStepValues } from "./types";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { VolumeStoragePoolSelect } from "~/components/containers";
import { ToggleFormSection } from "@cycleplatform/react-hook-form-components/components";
import { LearnMore } from "./LearnMore";

export function VmStorageStep({ stepIdx = 0 }: { stepIdx?: number }) {
    const { handleNext, handleBack, formKey } = useContext(
        MultiStepDialogContext
    );
    const currentForm = useAppSelector((state) =>
        getCurrentForm(state, formKey)
    );

    const form = useForm<VmStorageStepValues>({
        defaultValues: currentForm?.steps?.[stepIdx] || {
            volumes: [],
            base_disk: 10,
        },
        ...rhfConfig,
    });

    const {
        handleSubmit,
        setError,
        formState: { isSubmitting },
    } = form;

    const dispatch = useAppDispatch();
    const onSubmit = async (data: VmStorageStepValues) => {
        dispatch(addStepData({ data, idx: stepIdx, key: formKey }));
        handleNext?.();
    };

    const error = currentForm?.error;

    useEffect(() => {
        if (error && error.step === stepIdx) {
            setError(RHF_GLOBAL_ERROR as never, { message: error.message });
        }
    }, [error]);

    return (
        <RhfFormProvider
            {...form}
            className="h-full"
            onSubmit={form.handleSubmit(onSubmit)}
        >
            <div className="flex">
                <DialogColumn className="w-2/3">
                    <StorageResources />
                    <Volumes />
                </DialogColumn>
                <LearnMore title="Virtual Machine Storage">
                    Set the base disk size (which stores the image used for this
                    virtual machine) and attach any other storage desired.
                </LearnMore>
            </div>

            <DialogFooter className="justify-between">
                <Button icon={faArrowLeft} onClick={() => handleBack?.()}>
                    Back
                </Button>{" "}
                <div>
                    <RhfGlobalFormError />
                </div>
                <LoaderButton
                    isLoading={isSubmitting}
                    onClick={handleSubmit(onSubmit)}
                    type="button"
                    flavor="primary"
                    icon={faArrowRight}
                >
                    Next
                </LoaderButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}

function StorageResources() {
    const form = useFormContext<VmStorageStepValues>();
    const { register } = form;
    return (
        <>
            <FormSectionHeader header="Base Disk (GB)" />
            <FormSection>
                <RhfSliderInput
                    min={10}
                    max={100}
                    numTicks={9}
                    {...register(`base_disk`)}
                />
            </FormSection>
        </>
    );
}

function Volumes() {
    const form = useFormContext<VmStorageStepValues>();
    const {
        register,
        setValue,

        control,
    } = form;

    const { fields, append, remove } = useFieldArray({
        name: "volumes",
        control,
    });

    return (
        <ToggleFormSection<VmStorageStepValues>
            header="Additional Storage"
            field={"volumes" as const}
            initialIsActive={(v) => Array.isArray(v) && v.length > 0}
            unregisterFn={() => setValue("volumes", [])}
        >
            {() => (
                <FormSection>
                    <StyledDataTable>
                        <StyledTableHead>
                            <StyledHeaderCell>Identifier</StyledHeaderCell>
                            <StyledHeaderCell>Pool Options</StyledHeaderCell>
                            <StyledHeaderCell>Size</StyledHeaderCell>
                            <StyledHeaderCell>{/** Delete */}</StyledHeaderCell>
                        </StyledTableHead>
                        <tbody>
                            {fields.map((v, idx) => (
                                <StyledTableRow key={v.id}>
                                    <StyledCell>
                                        <div className="flex items-center gap-2">
                                            <TextInput
                                                prefix={
                                                    <FontAwesomeIcon
                                                        icon={faHardDrive}
                                                        className="text-cycle-blue pt-1"
                                                    />
                                                }
                                                placeholder="my-volume"
                                                {...register(
                                                    `volumes.${idx}.identifier`,
                                                    {
                                                        ...required(),
                                                    }
                                                )}
                                            />
                                        </div>
                                    </StyledCell>
                                    <StyledCell>
                                        <div className="pr-8">
                                            <Controller
                                                render={({
                                                    field: {
                                                        ref: _ref,
                                                        ...field
                                                    },
                                                }) => (
                                                    <VolumeStoragePoolSelect
                                                        {...field}
                                                        value={
                                                            field.value ||
                                                            undefined
                                                        }
                                                    />
                                                )}
                                                control={control}
                                                name={`volumes.${idx}.local.storage_pool`}
                                            />
                                        </div>
                                    </StyledCell>
                                    <StyledCell>
                                        <div className="mr-8">
                                            <Controller
                                                render={({
                                                    field: {
                                                        ref: _ref,
                                                        ...field
                                                    },
                                                }) => (
                                                    <RhfDataSizeInput
                                                        {...field}
                                                    />
                                                )}
                                                control={control}
                                                name={`volumes.${idx}.local.max_size`}
                                            />
                                        </div>
                                    </StyledCell>
                                    <StyledCell>
                                        <Button
                                            flavor="discard"
                                            icon={faTrash}
                                            onClick={() => {
                                                remove(idx);
                                            }}
                                        ></Button>
                                    </StyledCell>
                                </StyledTableRow>
                            ))}
                            <StyledTableRow>
                                <StyledCell colSpan={5} className="!pl-0">
                                    <Button
                                        type="button"
                                        icon={faPlus}
                                        className="w-full"
                                        onClick={() =>
                                            append({
                                                identifier: "",
                                                local: {
                                                    max_size: "20G",
                                                },
                                                read_only: false,
                                            })
                                        }
                                    >
                                        Add
                                    </Button>
                                </StyledCell>
                            </StyledTableRow>
                        </tbody>
                    </StyledDataTable>
                </FormSection>
            )}
        </ToggleFormSection>
    );
}
