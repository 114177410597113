import { PageContent } from "@cycleplatform/ui/components/page";
import { StackBuild, useGetImagesQuery } from "~/services/cycle";
import { ImagesTable } from "~/components/images/tables/ImagesTable";
import { usePagination } from "@cycleplatform/ui/hooks";
import { Panel, PanelContent } from "@cycleplatform/ui/components/panels";
import { TableHeader } from "@cycleplatform/ui/components/tables";
import { PageControl } from "@cycleplatform/ui/components/pagination";
import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import { CycleErrorBoundary } from "~/components/common/errors";
import { tableFallbackWrapper } from "~/components/common/errors/FallbackWrappers";

type StackBuildImagesProps = {
    build?: StackBuild;
};
export function StackBuildImages({ build }: StackBuildImagesProps) {
    return (
        <DialogPageBody>
            <PageContent>
                <Panel>
                    <CycleErrorBoundary
                        fallbackWrapper={tableFallbackWrapper("images")}
                    >
                        <ImagesSection build={build} />
                    </CycleErrorBoundary>
                </Panel>
            </PageContent>
        </DialogPageBody>
    );
}

function ImagesSection({ build }: StackBuildImagesProps) {
    const pagination = usePagination(20, "stack-images");
    const { data: images, error } = useGetImagesQuery({
        filter: {
            source_type: "stack-build",
            source_id: build?.id || "",
        },
        include: ["stack_builds", "stacks", "integrations"],
        meta: ["containers_count"],
        page: pagination,
        sort: ["-id"],
    });
    if (error) {
        throw error;
    }
    return (
        <>
            <TableHeader title="Images">
                <PageControl
                    page={pagination}
                    itemCount={images?.data?.length}
                />
            </TableHeader>
            <PanelContent stretch>
                <ImagesTable
                    images={images?.data}
                    imageIncludes={images?.includes}
                />
            </PanelContent>
        </>
    );
}
