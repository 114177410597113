import { components } from "../../api/__generated";
import { analyzeContainerNetworkStatus } from "./container";
import { NetworkAnalysisError } from "./error";
import { getAnnotatedContainerPorts } from "./util";

export type VmNetworkAnalysis = {
    mode: "public" | "egress" | "disabled";
    hasStaticIps: boolean;
    exposedPorts: ReturnType<typeof getAnnotatedContainerPorts>;
    errors: NetworkAnalysisError[];
};

export function analyzeVirtualMachineNetworkStatus(
    container: components["schemas"]["Container"],
    lb: components["schemas"]["Container"] | undefined,
    lbconfig: components["schemas"]["LoadBalancerConfig"] | undefined,
    vm: components["schemas"]["VirtualMachine"],
    gateway: components["schemas"]["Container"] | undefined | null
): VmNetworkAnalysis {
    const containerAnalysis = analyzeContainerNetworkStatus(
        injectHypervisorContainerMeta(container, vm),
        lb,
        lbconfig
    );

    const { staticIps } = generateVmSummary(vm);

    return {
        ...containerAnalysis,
        hasStaticIps: staticIps.length > 0,
        errors: [
            ...analyzePortConfiguration(vm, container, lbconfig),
            ...analyzeLoadBalancerStatus(vm, lb),
            ...analyzeGatewayStatus(vm, gateway),
            ...analyzeVirtualMachineStatus(vm),
        ],
    };
}

export function injectHypervisorContainerMeta(
    container: components["schemas"]["Container"],
    vm: components["schemas"]["VirtualMachine"]
) {
    return {
        ...container,
        meta: {
            ...container.meta,
            domains: vm.meta?.domains || [],
        },
    } as components["schemas"]["Container"];
}

type GatewayStatus = "healthy" | "unavailable" | "offline" | "no-instances";

function generateServiceSummary(
    gateway: components["schemas"]["Container"] | undefined | null
): {
    status: GatewayStatus;
} {
    let status: GatewayStatus = "healthy";
    if (!gateway) {
        status = "unavailable";
    } else if (gateway.state.current !== "running") {
        status = "offline";
    } else if (gateway.instances === 0) {
        status = "no-instances";
    }

    return { status };
}

export function generateVmSummary(vm: components["schemas"]["VirtualMachine"]) {
    const linkedRecords = getVirtualMachineLinkedRecords(vm);

    return {
        state: vm.state.current,
        publicNetwork: vm.config.network.public,
        ports: vm.config.network.ports || [],
        staticIps: vm.meta?.ips || [],
        dmzRecords: linkedRecords.filter((r) => !!r.dmz),
        lbRecords: linkedRecords.filter((r) => !r.dmz),
    };
}

function analyzeLoadBalancerStatus(
    vm: components["schemas"]["VirtualMachine"],
    lb: components["schemas"]["Container"] | undefined
): NetworkAnalysisError[] {
    const errors: NetworkAnalysisError[] = [];

    const { staticIps, dmzRecords, lbRecords, publicNetwork } =
        generateVmSummary(vm);

    const { status: lbStatus } = generateServiceSummary(lb);

    switch (lbStatus) {
        case "healthy":
            break;
        // Should use gateway, but gateway unavailable (does not exist)
        case "unavailable":
            errors.push({
                type: lbRecords.length ? "error" : "disabled",
                code: "lb.instances.none",
                details:
                    "A load balancer has not been configured or is not enabled for this environment.",
                resolution:
                    "Start this environment or manually start the load balancer.",
            });
            break;
        // Should use gateway, but gateway offline
        case "offline":
            errors.push({
                type: lbRecords.length ? "error" : "disabled",
                code: "lb.state.offline",
                details: `Load balancer is ${lb?.state.current}.`,
                resolution:
                    "Either click 'start all' on the environment, or start the load balancer directly.",
            });
            break;
        // Should use gateway, but gateway has no instances
        case "no-instances":
            errors.push({
                type: lbRecords.length ? "error" : "disabled",
                code: "lb.instances.none",
                details: "No load balancer instances created.",
                resolution: "On the load balancer instances tab, click 'add'.",
            });
            break;
    }

    if (!lbRecords.length) {
        errors.push({
            type: "disabled",
            code: "lb-egress.none",
            details:
                "No non-DMZ records are configured directing traffic through this load balancer.",
            resolution:
                "Use DNS to point a domain to this virtual machine, with DMZ disabled.",
        });

        const shouldUseGateway = staticIps.length || dmzRecords.length;

        if (publicNetwork === "disable" || !!shouldUseGateway) {
            errors.push({
                type: "disabled",
                code: "lb-ingress.none",
                details:
                    "No non-DMZ records are configured directing traffic through this load balancer.",
                resolution:
                    "Use DNS to point a domain to this virtual machine, with DMZ disabled.",
            });
        } else {
            errors.push({
                type: "error",
                code: "lb-ingress.none",
                details:
                    "Public networking is enabled and no non-DMZ records are configured directing traffic through this load balancer.",
                resolution:
                    "Use DNS to point a domain to this virtual machine, with DMZ disabled.",
            });
        }
    }

    return errors;
}

function analyzePortConfiguration(
    vm: components["schemas"]["VirtualMachine"],
    container: components["schemas"]["Container"],
    lbConfig: components["schemas"]["LoadBalancerConfig"] | undefined
): NetworkAnalysisError[] {
    const hypervisor = injectHypervisorContainerMeta(container, vm);

    if (hypervisor.config.network.public !== "enable") {
        // port configuration doesn't matter in this situation
        return [];
    }

    const errors: NetworkAnalysisError[] = [];

    const { lbRecords } = generateVmSummary(vm);

    const ports = getAnnotatedContainerPorts(hypervisor, lbConfig);

    const hasTlsDomain = lbRecords.some((d) => d.tls);

    if (ports.length === 0 && !!lbRecords.length) {
        // There are non-DMZ records pointing at VM, but there are no ports assigned in config
        errors.push({
            type: "error",
            code: "vm.ports.none",
            details:
                "A non-DMZ LINKED record is pointed to the virtual machine, but no ports are exposed in the config.",
            resolution:
                "Specify ports in the network tab of the virtual machine config.",
        });
    } else if (ports.some((p) => p.mode === "http" && p.tls) && !hasTlsDomain) {
        errors.push({
            type: "warning",
            code: "vm.ports.no-tls-domain",
            details:
                "Virtual machine is listening for TLS-encrypted HTTP traffic, but no domains support TLS",
            resolution:
                "Configure at least one TLS-enabled LINKED record for this virtual machine.",
        });
    } else if (!ports.some((p) => p.mode === "http" && p.tls) && hasTlsDomain) {
        errors.push({
            type: "warning",
            code: "vm.ports.no-tls-port",
            details:
                "A TLS enabled domain is pointing to this virtual machine, but no TLS ports are defined in the config.",
            resolution:
                "Add a TLS port in the network tab of the virtual machine config.",
        });
    }

    // if we have matching ports:
    // - is there a non-tls record & non-tls port pair?
    // - is there a tls record & tls enabled port pair?
    // - is there a tcp port pair?

    // if ports exist, and no domains, and none of our matching ports are 'tcp' or 'udp' (since they don't need a record to work)
    // Only throw these errors if lbRecords are configured, indicating that we want to use the lb for routing

    // TODO investigate TCP traffic and surrounding implications
    // if (
    //     !lbRecords.length &&
    //     ports.length &&
    //     !ports.some((p) => p.mode === "tcp" || p.mode === "udp")
    // ) {
    //     errors.push({
    //         type: "disabled",
    //         code: "lb-ingress.dns.missing-record",
    //         details:
    //             "No non-DMZ records point to this virtual machine. No ingress traffic will be permitted through the load balancer.",
    //         resolution:
    //             "Configure at least one non-DMZ LINKED record pointed at this virtual machine.",
    //     });
    // }

    if (
        lbRecords.length &&
        hasTlsDomain &&
        ports.some(
            (p) => p.lbIngress === 443 && p.containerIngress === 443 && p.tls
        )
    ) {
        errors.push({
            type: "warning",
            code: "lb-egress.ports.tls-mismatch",
            details:
                "The virtual machine is listening on port 443:443, but the load balancer is configured to do TLS termination on this port.",
            resolution:
                "Update port configuration on the virtual machine to be 443:80.",
        });
    }

    return errors;
}

export function analyzeGatewayStatus(
    vm: components["schemas"]["VirtualMachine"],
    gateway: components["schemas"]["Container"] | undefined | null
) {
    const errors: NetworkAnalysisError[] = [];

    const { staticIps, dmzRecords, lbRecords, publicNetwork } =
        generateVmSummary(vm);

    const { status: gatewayStatus } = generateServiceSummary(gateway);

    const shouldUseGateway = staticIps.length || dmzRecords.length;

    switch (gatewayStatus) {
        case "healthy":
            break;
        // Should use gateway, but gateway unavailable (does not exist)
        case "unavailable":
            errors.push({
                type: shouldUseGateway ? "error" : "disabled",
                code: "gateway.service.unavailable",
                details:
                    "A gateway has not been configured or is not enabled on this environment.",
            });
            break;
        // Should use gateway, but gateway offline
        case "offline":
            errors.push({
                type: shouldUseGateway ? "error" : "disabled",
                code: "gateway.state.offline",
                details: `Gateway is ${gateway?.state.current}.`,
                resolution: "Start the gateway service container.",
            });
            break;
        // Should use gateway, but gateway has no instances
        case "no-instances":
            errors.push({
                type: shouldUseGateway ? "error" : "disabled",
                code: "gateway.instances.none",
                details: "No gateway instances created.",
                resolution: "On the gateway instances tab, click 'add'.",
            });
            break;
    }

    if (shouldUseGateway && !staticIps.length) {
        errors.push({
            type: "error",
            code: "gateway-ingress.static-ips.none",
            details:
                "There is a DMZ record pointed to the virtual machine, but no static IP assigned.",
        });
    }

    // Public networking is disabled and there are no static ips / DMZ records assigned AND
    // the public network is either disabled or there are lbRecords directing traffic through the LB

    if (!shouldUseGateway && publicNetwork !== "egress-only") {
        errors.push({
            type: "disabled",
            code: "gateway-egress.none",
            details: "No static IPs or DMZ LINKED records assigned to this VM.",
        });

        if (publicNetwork === "disable" || lbRecords.length) {
            errors.push({
                type: "disabled",
                code: "gateway-ingress.none",
                details:
                    "No static IPs or DMZ LINKED records assigned to this VM.",
            });
        } else {
            errors.push({
                type: "error",
                code: "gateway-ingress.none",
                details:
                    "Public networking is enabled and no static IPs or DMZ LINKED records assigned to this VM.",
            });
        }
    }

    return errors;
}

export function analyzeVirtualMachineStatus(
    vm: components["schemas"]["VirtualMachine"]
) {
    const errors: NetworkAnalysisError[] = [];

    const {
        state: vmState,
        staticIps,
        ports,
        dmzRecords,
        lbRecords,
        publicNetwork,
    } = generateVmSummary(vm);

    // Public network is enabled but the VM is not running
    if (vmState !== "running" && publicNetwork !== "disable") {
        errors.push({
            type: "error",
            code: "vm.state.offline",
            details: "The virtual machine is not online.",
            resolution: "Start the virtual machine",
        });
    }

    if (publicNetwork !== "enable" && lbRecords.length) {
        errors.push({
            type: "warning",
            code: "lb-ingress.network.disabled",
            details:
                "There is a non-DMZ record directing traffic to the virtual machine, but public networking is not enabled.",
        });
        errors.push({
            type: "disabled",
            code: "lb-egress.none",
            details: "Public networking is not enabled.",
        });
    }

    if (publicNetwork !== "enable" && dmzRecords.length) {
        errors.push({
            type: "warning",
            code: "gateway-ingress.network.disabled",
            details:
                "There is a DMZ record directing traffic to the virtual machine, but public networking is not enabled.",
        });

        errors.push({
            type: "disabled",
            code: "gateway-egress.none",
            details: "Public networking is not enabled.",
        });
    }

    if (publicNetwork !== "enable" && staticIps.length) {
        errors.push({
            type: "warning",
            code: "gateway-ingress.network.disabled",
            details:
                "A static IP is assigned to this virtual machine, but public networking is not enabled.",
        });
        errors.push({
            type: "disabled",
            code: "gateway-egress.none",
            details: "Public networking is not enabled.",
        });
    }

    if (publicNetwork === "egress-only" && dmzRecords.length) {
        errors.push({
            type: "warning",
            code: "vm.egress-only.has-domains",
            details:
                "A DMZ LINKED record is pointed to this virtual machine, but public networking is set to egress only.",
        });
    }

    if (publicNetwork === "egress-only" && lbRecords.length) {
        errors.push({
            type: "warning",
            code: "vm.egress-only.has-domains",
            details:
                "A non-DMZ LINKED record is pointed to this virtual machine, but public networking is set to egress only.",
        });
    }

    return errors;
}

function getVirtualMachineLinkedRecords(
    vm: components["schemas"]["VirtualMachine"]
) {
    const domains = vm.meta?.domains?.filter(
        (
            d
        ): d is typeof vm.meta.domains[number] & {
            record: components["schemas"]["DnsRecord"];
        } => !!d.record
    );

    if (!domains || domains.length === 0) {
        return [];
    }

    return domains.map((d) => ({
        fqdn: d.fqdn,
        tls: !!d.record.features?.certificate?.id,
        dmz:
            d.record.type.linked &&
            "virtual_machine" in d.record.type.linked &&
            !!d.record.type.linked.virtual_machine.dmz,
    }));
}

if (import.meta.vitest) {
    const { it, expect, describe } = import.meta.vitest;

    const buildVmAndContainerResource = ({
        state,
        network,
        ips,
        domains,
        ports,
    }: {
        state: components["schemas"]["ContainerState"]["current"];
        network: components["schemas"]["VirtualMachineConfig"]["network"]["public"];
        ips?: string[];
        domains?: { fqdn: string; dmz: boolean; tls: boolean }[];
        ports?: components["schemas"]["VirtualMachineConfig"]["network"]["ports"];
    }): {
        vm: components["schemas"]["VirtualMachine"];
        hypervisor: components["schemas"]["Container"];
    } => {
        const metaDomains = domains
            ? {
                  domains: domains.map((d) => ({
                      fqdn: d.fqdn,
                      record: {
                          features: d.tls
                              ? {
                                    certificate: {
                                        id: "record-id",
                                    },
                                }
                              : undefined,
                          type: {
                              linked: {
                                  virtual_machine: {
                                      dmz: d.dmz,
                                  },
                              },
                          },
                      },
                  })),
              }
            : {};

        const metaIps = ips
            ? {
                  ips: ips.map((cidr, idx) => ({
                      id: `id-${idx}`,
                      hub_id: `hub-id-${idx}`,
                      kind: "ipv4",
                      pool_id: `pool-id-${idx}`,
                      address: `address-${idx}`,
                      gateway: `gateway-${idx}`,
                      cidr,
                      state: {
                          current: "assigned",
                      },
                  })),
              }
            : {};

        return {
            vm: {
                state: {
                    current: state,
                },
                config: {
                    network: {
                        ports,
                        public: network,
                    },
                },

                meta: {
                    ...metaDomains,
                    ...metaIps,
                },
            } as components["schemas"]["VirtualMachine"],
            hypervisor: {
                state: {
                    current: state,
                },
                config: {
                    network: {
                        public: network,
                        ports,
                    },
                },
                image: {
                    service: null,
                },
                meta: { ...metaDomains },
            } as components["schemas"]["Container"],
        };
    };

    const buildServiceContainer = (
        state: components["schemas"]["ContainerState"]["current"],
        instances: number = 1
    ): components["schemas"]["Container"] => {
        return {
            state: {
                current: state,
            },
            instances,
        } as components["schemas"]["Container"];
    };

    const buildLbV1Config = (
        controllers: {
            port: number;
            tls: boolean;
            tcp?: boolean;
            redirect?: boolean;
        }[]
    ): components["schemas"]["LoadBalancerConfig"] => {
        return {
            type: "v1",
            ipv4: true,
            ipv6: true,
            details: {
                controllers: controllers.map((c) => ({
                    port: c.port,
                    identifier: `port-${c.port}`,
                    transport: {
                        mode: c.tcp ? "tcp" : "http",
                        routers: c.redirect
                            ? [
                                  {
                                      config: {
                                          extension: {
                                              details: {
                                                  redirect: {
                                                      auto_https_redirect: true,
                                                  },
                                              },
                                          },
                                      },
                                  },
                              ]
                            : [],
                        config: {
                            ingress: {
                                tls: {
                                    enable: c.tls,
                                },
                            },
                        },
                    },
                })),
                controller_template: {
                    identifier: "template",
                    port: 0,
                    transport: {
                        disable: false,
                        mode: "tcp",
                        config: {
                            performance: false,
                            verbosity: "low",
                            ingress: {},
                        },
                        routers: [
                            {
                                config: {
                                    tls: null,
                                },
                            },
                        ],
                    },
                },
            },
        } as unknown as components["schemas"]["LoadBalancerConfig"];
    };

    describe("tests public networking enabled errors", () => {
        it("checks gateway happy path with static IP", () => {
            const { vm, hypervisor } = buildVmAndContainerResource({
                state: "running",
                network: "enable",
                ips: ["thisisanIP"],
                domains: [],
            });

            const lb = buildServiceContainer("running", 1);
            const gateway = buildServiceContainer("running", 1);

            const lbV1Config = buildLbV1Config([
                { port: 443, tls: true },
                { port: 80, tls: false },
            ]);

            const gatewayErrors = analyzeGatewayStatus(vm, gateway);
            const vmErrors = analyzeVirtualMachineStatus(vm);
            const lbErrors = analyzeLoadBalancerStatus(vm, lb);

            const portErrors = analyzePortConfiguration(
                vm,
                hypervisor,
                lbV1Config
            );

            expect(gatewayErrors).toHaveLength(0);
            expect(vmErrors).toHaveLength(0);
            expect(portErrors).toHaveLength(0);

            expect(lbErrors).toContainEqual(
                expect.objectContaining({
                    type: "disabled",
                })
            );
        });

        it("checks gateway DMZ Record happy path", () => {
            const { vm, hypervisor } = buildVmAndContainerResource({
                state: "running",
                network: "enable",
                ips: ["thisisanIP"],
                domains: [{ fqdn: "HELLO", dmz: true, tls: false }],
            });

            const lb = buildServiceContainer("running", 1);
            const gateway = buildServiceContainer("running", 1);

            const lbV1Config = buildLbV1Config([
                { port: 443, tls: true },
                { port: 80, tls: false },
            ]);

            const gatewayErrors = analyzeGatewayStatus(vm, gateway);
            const vmErrors = analyzeVirtualMachineStatus(vm);
            const lbErrors = analyzeLoadBalancerStatus(vm, lb);

            const portErrors = analyzePortConfiguration(
                vm,
                hypervisor,
                lbV1Config
            );

            expect(vmErrors).toHaveLength(0);
            expect(portErrors).toHaveLength(0);
            expect(gatewayErrors).toHaveLength(0);

            expect(lbErrors).toContainEqual(
                expect.objectContaining({
                    type: "disabled",
                })
            );
        });

        it("checks gateway DMZ Record but no IP", () => {
            const { vm, hypervisor } = buildVmAndContainerResource({
                state: "running",
                network: "enable",

                domains: [{ fqdn: "HELLO", dmz: true, tls: false }],
            });

            const lb = buildServiceContainer("running", 1);
            const gateway = buildServiceContainer("running", 1);

            const lbV1Config = buildLbV1Config([
                { port: 443, tls: true },
                { port: 80, tls: false },
            ]);

            const gatewayErrors = analyzeGatewayStatus(vm, gateway);
            const vmErrors = analyzeVirtualMachineStatus(vm);
            const lbErrors = analyzeLoadBalancerStatus(vm, lb);

            const portErrors = analyzePortConfiguration(
                vm,
                hypervisor,
                lbV1Config
            );

            expect(vmErrors).toHaveLength(0);
            expect(portErrors).toHaveLength(0);

            expect(
                gatewayErrors.filter(
                    (e) =>
                        e.type === "error" &&
                        e.code === "gateway-ingress.static-ips.none"
                )
            ).toHaveLength(1);

            expect(lbErrors).toContainEqual(
                expect.objectContaining({
                    type: "disabled",
                })
            );
        });

        it("checks gateway happy path with non DMZ Record", () => {
            const { vm, hypervisor } = buildVmAndContainerResource({
                state: "running",
                network: "enable",
                ports: ["80:80", "443:80"],

                domains: [{ fqdn: "HELLO", dmz: false, tls: true }],
            });

            const lb = buildServiceContainer("running", 1);
            const gateway = buildServiceContainer("running", 1);

            const lbV1Config = buildLbV1Config([
                { port: 443, tls: true },
                { port: 80, tls: false },
            ]);

            const gatewayErrors = analyzeGatewayStatus(vm, gateway);
            const vmErrors = analyzeVirtualMachineStatus(vm);
            const lbErrors = analyzeLoadBalancerStatus(vm, lb);

            const portErrors = analyzePortConfiguration(
                vm,
                hypervisor,
                lbV1Config
            );

            expect(lbErrors).toHaveLength(0);
            expect(vmErrors).toHaveLength(0);
            expect(portErrors).toHaveLength(0);

            expect(gatewayErrors).toContainEqual(
                expect.objectContaining({
                    type: "disabled",
                })
            );
        });

        it("checks non DMZ record, missing ports", () => {
            const { vm, hypervisor } = buildVmAndContainerResource({
                state: "running",
                network: "enable",

                domains: [{ fqdn: "HELLO", dmz: false, tls: true }],
            });

            const lb = buildServiceContainer("running", 1);
            const gateway = buildServiceContainer("running", 1);

            const lbV1Config = buildLbV1Config([
                { port: 443, tls: true },
                { port: 80, tls: false },
            ]);

            const gatewayErrors = analyzeGatewayStatus(vm, gateway);
            const vmErrors = analyzeVirtualMachineStatus(vm);
            const lbErrors = analyzeLoadBalancerStatus(vm, lb);

            const portErrors = analyzePortConfiguration(
                vm,
                hypervisor,
                lbV1Config
            );

            expect(lbErrors).toHaveLength(0);
            expect(vmErrors).toHaveLength(0);
            expect(gatewayErrors).toContainEqual(
                expect.objectContaining({
                    type: "disabled",
                })
            );

            expect(
                portErrors.filter(
                    (e) => e.type === "error" && e.code === "vm.ports.none"
                )
            ).toHaveLength(1);
        });

        it("checks non DMZ record, TLS port mismatch", () => {
            const { vm, hypervisor } = buildVmAndContainerResource({
                state: "running",
                network: "enable",
                ports: ["80:80"],

                domains: [{ fqdn: "HELLO", dmz: false, tls: true }],
            });

            const lb = buildServiceContainer("running", 1);
            const gateway = buildServiceContainer("running", 1);

            const lbV1Config = buildLbV1Config([
                { port: 443, tls: true },
                { port: 80, tls: false },
            ]);

            const gatewayErrors = analyzeGatewayStatus(vm, gateway);
            const vmErrors = analyzeVirtualMachineStatus(vm);
            const lbErrors = analyzeLoadBalancerStatus(vm, lb);

            const portErrors = analyzePortConfiguration(
                vm,
                hypervisor,
                lbV1Config
            );

            expect(lbErrors).toHaveLength(0);
            expect(vmErrors).toHaveLength(0);
            expect(gatewayErrors).toContainEqual(
                expect.objectContaining({
                    type: "disabled",
                })
            );

            expect(
                portErrors.filter(
                    (e) =>
                        e.type === "warning" &&
                        e.code === "vm.ports.no-tls-port"
                )
            ).toHaveLength(1);
        });

        it("checks non DMZ record, no TLS domains", () => {
            const { vm, hypervisor } = buildVmAndContainerResource({
                state: "running",
                network: "enable",
                ports: ["80:80", "443:80"],

                domains: [{ fqdn: "HELLO", dmz: false, tls: false }],
            });

            const lb = buildServiceContainer("running", 1);
            const gateway = buildServiceContainer("running", 1);

            const lbV1Config = buildLbV1Config([
                { port: 443, tls: true },
                { port: 80, tls: false },
            ]);

            const gatewayErrors = analyzeGatewayStatus(vm, gateway);
            const vmErrors = analyzeVirtualMachineStatus(vm);
            const lbErrors = analyzeLoadBalancerStatus(vm, lb);

            const portErrors = analyzePortConfiguration(
                vm,
                hypervisor,
                lbV1Config
            );

            expect(lbErrors).toHaveLength(0);
            expect(vmErrors).toHaveLength(0);
            expect(gatewayErrors).toContainEqual(
                expect.objectContaining({
                    type: "disabled",
                })
            );

            expect(
                portErrors.filter(
                    (e) =>
                        e.type === "warning" &&
                        e.code === "vm.ports.no-tls-domain"
                )
            ).toHaveLength(1);
        });

        it("checks non DMZ record, custom TLS port, happy path", () => {
            const { vm, hypervisor } = buildVmAndContainerResource({
                state: "running",
                network: "enable",
                ports: ["80:80", "10:80"],
                domains: [{ fqdn: "HELLO", dmz: false, tls: true }],
            });

            const lb = buildServiceContainer("running", 1);
            const gateway = buildServiceContainer("running", 1);

            const lbV1Config = buildLbV1Config([
                { port: 443, tls: false },
                { port: 80, tls: false },
                { port: 10, tls: true },
            ]);

            const gatewayErrors = analyzeGatewayStatus(vm, gateway);
            const vmErrors = analyzeVirtualMachineStatus(vm);
            const lbErrors = analyzeLoadBalancerStatus(vm, lb);

            const portErrors = analyzePortConfiguration(
                vm,
                hypervisor,
                lbV1Config
            );

            expect(lbErrors).toHaveLength(0);
            expect(vmErrors).toHaveLength(0);
            expect(gatewayErrors).toContainEqual(
                expect.objectContaining({
                    type: "disabled",
                })
            );

            expect(portErrors).toHaveLength(0);
        });

        it("checks DMZ record path, with stopped LB, Gateway, and VM", () => {
            const { vm, hypervisor } = buildVmAndContainerResource({
                state: "stopped",
                network: "enable",
                domains: [{ fqdn: "HELLO", dmz: true, tls: false }],
            });

            const lb = buildServiceContainer("stopped", 1);
            const gateway = buildServiceContainer("stopped", 1);

            const lbV1Config = buildLbV1Config([
                { port: 443, tls: true },
                { port: 80, tls: false },
            ]);

            const gatewayErrors = analyzeGatewayStatus(vm, gateway);
            const vmErrors = analyzeVirtualMachineStatus(vm);
            const lbErrors = analyzeLoadBalancerStatus(vm, lb);

            const portErrors = analyzePortConfiguration(
                vm,
                hypervisor,
                lbV1Config
            );

            expect(portErrors).toHaveLength(0);

            expect(
                gatewayErrors.filter(
                    (e) =>
                        e.type === "error" && e.code === "gateway.state.offline"
                )
            ).toHaveLength(1);

            expect(
                vmErrors.filter(
                    (e) => e.type === "error" && e.code === "vm.state.offline"
                )
            ).toHaveLength(1);

            expect(lbErrors).toContainEqual(
                expect.objectContaining({
                    type: "disabled",
                })
            );
        });

        it("checks DMZ record path, with zero instances for LB and Gateway", () => {
            const { vm, hypervisor } = buildVmAndContainerResource({
                state: "running",
                network: "enable",
                domains: [{ fqdn: "HELLO", dmz: true, tls: false }],
            });

            const lb = buildServiceContainer("running", 0);
            const gateway = buildServiceContainer("running", 0);

            const lbV1Config = buildLbV1Config([
                { port: 443, tls: true },
                { port: 80, tls: false },
            ]);

            const gatewayErrors = analyzeGatewayStatus(vm, gateway);
            const vmErrors = analyzeVirtualMachineStatus(vm);
            const lbErrors = analyzeLoadBalancerStatus(vm, lb);

            const portErrors = analyzePortConfiguration(
                vm,
                hypervisor,
                lbV1Config
            );

            expect(portErrors).toHaveLength(0);
            expect(vmErrors).toHaveLength(0);

            expect(
                gatewayErrors.filter(
                    (e) =>
                        e.type === "error" &&
                        e.code === "gateway.instances.none"
                )
            ).toHaveLength(1);

            expect(lbErrors).toContainEqual(
                expect.objectContaining({
                    type: "disabled",
                })
            );
        });

        it("checks non-DMZ record path, with zero instances for LB and Gateway", () => {
            const { vm, hypervisor } = buildVmAndContainerResource({
                state: "running",
                network: "enable",
                ports: ["80:80", "443:80"],
                domains: [{ fqdn: "HELLO", dmz: false, tls: true }],
            });

            const lb = buildServiceContainer("running", 0);
            const gateway = buildServiceContainer("running", 0);

            const lbV1Config = buildLbV1Config([
                { port: 443, tls: true },
                { port: 80, tls: false },
            ]);

            const gatewayErrors = analyzeGatewayStatus(vm, gateway);
            const vmErrors = analyzeVirtualMachineStatus(vm);
            const lbErrors = analyzeLoadBalancerStatus(vm, lb);

            const portErrors = analyzePortConfiguration(
                vm,
                hypervisor,
                lbV1Config
            );

            expect(portErrors).toHaveLength(0);
            expect(vmErrors).toHaveLength(0);

            expect(
                lbErrors.filter(
                    (e) => e.type === "error" && e.code === "lb.instances.none"
                )
            ).toHaveLength(1);

            expect(gatewayErrors).toContainEqual(
                expect.objectContaining({
                    type: "disabled",
                })
            );
        });

        it("checks non-DMZ record path, with stopped LB and Gateway", () => {
            const { vm, hypervisor } = buildVmAndContainerResource({
                state: "stopped",
                network: "enable",
                ports: ["80:80", "443:80"],
                domains: [{ fqdn: "HELLO", dmz: false, tls: true }],
            });

            const lb = buildServiceContainer("stopped", 1);
            const gateway = buildServiceContainer("stopped", 1);

            const lbV1Config = buildLbV1Config([
                { port: 443, tls: true },
                { port: 80, tls: false },
            ]);

            const gatewayErrors = analyzeGatewayStatus(vm, gateway);
            const vmErrors = analyzeVirtualMachineStatus(vm);
            const lbErrors = analyzeLoadBalancerStatus(vm, lb);

            const portErrors = analyzePortConfiguration(
                vm,
                hypervisor,
                lbV1Config
            );

            expect(portErrors).toHaveLength(0);

            expect(
                lbErrors.filter(
                    (e) => e.type === "error" && e.code === "lb.state.offline"
                )
            ).toHaveLength(1);

            expect(
                vmErrors.filter(
                    (e) => e.type === "error" && e.code === "vm.state.offline"
                )
            ).toHaveLength(1);

            expect(gatewayErrors).toContainEqual(
                expect.objectContaining({
                    type: "disabled",
                })
            );
        });

        it("checks DMZ and non-DMZ record happy path", () => {
            const { vm, hypervisor } = buildVmAndContainerResource({
                state: "running",
                network: "enable",
                ports: ["80:80", "443:80"],
                ips: ["thisisanIP"],
                domains: [
                    { fqdn: "HELLO", dmz: false, tls: true },
                    { fqdn: "HELLO", dmz: true, tls: false },
                ],
            });

            const lb = buildServiceContainer("running", 1);
            const gateway = buildServiceContainer("running", 1);

            const lbV1Config = buildLbV1Config([
                { port: 443, tls: true },
                { port: 80, tls: false },
            ]);

            const gatewayErrors = analyzeGatewayStatus(vm, gateway);
            const vmErrors = analyzeVirtualMachineStatus(vm);
            const lbErrors = analyzeLoadBalancerStatus(vm, lb);

            const portErrors = analyzePortConfiguration(
                vm,
                hypervisor,
                lbV1Config
            );

            expect(portErrors).toHaveLength(0);
            expect(lbErrors).toHaveLength(0);
            expect(vmErrors).toHaveLength(0);
            expect(gatewayErrors).toHaveLength(0);
        });
    });

    describe("tests public networking disabled errors", () => {
        it("checks fully disabled happy path", () => {
            const { vm, hypervisor } = buildVmAndContainerResource({
                state: "running",
                network: "disable",

                domains: [],
            });

            const lb = buildServiceContainer("running", 1);
            const gateway = buildServiceContainer("running", 1);

            const lbV1Config = buildLbV1Config([
                { port: 443, tls: true },
                { port: 80, tls: false },
            ]);

            const gatewayErrors = analyzeGatewayStatus(vm, gateway);
            const vmErrors = analyzeVirtualMachineStatus(vm);
            const lbErrors = analyzeLoadBalancerStatus(vm, lb);

            const portErrors = analyzePortConfiguration(
                vm,
                hypervisor,
                lbV1Config
            );

            expect(portErrors).toHaveLength(0);
            expect(vmErrors).toHaveLength(0);

            expect(
                lbErrors.filter(
                    (e) => e.type === "disabled" && e.code === "lb-egress.none"
                )
            ).toHaveLength(1);

            expect(
                lbErrors.filter(
                    (e) => e.type === "disabled" && e.code === "lb-ingress.none"
                )
            ).toHaveLength(1);

            expect(
                gatewayErrors.filter(
                    (e) =>
                        e.type === "disabled" &&
                        e.code === "gateway-egress.none"
                )
            ).toHaveLength(1);

            expect(
                gatewayErrors.filter(
                    (e) =>
                        e.type === "disabled" &&
                        e.code === "gateway-ingress.none"
                )
            ).toHaveLength(1);
        });

        it("checks fully disabled but lb and gateway stopped", () => {
            const { vm, hypervisor } = buildVmAndContainerResource({
                state: "running",
                network: "disable",
                domains: [],
            });

            const lb = buildServiceContainer("stopped", 1);
            const gateway = buildServiceContainer("stopped", 1);

            const lbV1Config = buildLbV1Config([
                { port: 443, tls: true },
                { port: 80, tls: false },
            ]);

            const gatewayErrors = analyzeGatewayStatus(vm, gateway);
            const vmErrors = analyzeVirtualMachineStatus(vm);
            const lbErrors = analyzeLoadBalancerStatus(vm, lb);

            const portErrors = analyzePortConfiguration(
                vm,
                hypervisor,
                lbV1Config
            );

            expect(portErrors).toHaveLength(0);
            expect(vmErrors).toHaveLength(0);

            expect(
                lbErrors.filter(
                    (e) => e.type === "disabled" && e.code === "lb-egress.none"
                )
            ).toHaveLength(1);

            expect(
                lbErrors.filter(
                    (e) => e.type === "disabled" && e.code === "lb-ingress.none"
                )
            ).toHaveLength(1);

            expect(
                lbErrors.filter(
                    (e) =>
                        e.type === "disabled" && e.code === "lb.state.offline"
                )
            ).toHaveLength(1);

            expect(
                gatewayErrors.filter(
                    (e) =>
                        e.type === "disabled" &&
                        e.code === "gateway-egress.none"
                )
            ).toHaveLength(1);

            expect(
                gatewayErrors.filter(
                    (e) =>
                        e.type === "disabled" &&
                        e.code === "gateway-ingress.none"
                )
            ).toHaveLength(1);

            expect(
                gatewayErrors.filter(
                    (e) =>
                        e.type === "disabled" &&
                        e.code === "gateway.state.offline"
                )
            ).toHaveLength(1);
        });

        it("checks fully disabled but lb and gateway no instances", () => {
            const { vm, hypervisor } = buildVmAndContainerResource({
                state: "running",
                network: "disable",
                domains: [],
            });

            const lb = buildServiceContainer("running", 0);
            const gateway = buildServiceContainer("running", 0);

            const lbV1Config = buildLbV1Config([
                { port: 443, tls: true },
                { port: 80, tls: false },
            ]);

            const gatewayErrors = analyzeGatewayStatus(vm, gateway);
            const vmErrors = analyzeVirtualMachineStatus(vm);
            const lbErrors = analyzeLoadBalancerStatus(vm, lb);

            const portErrors = analyzePortConfiguration(
                vm,
                hypervisor,
                lbV1Config
            );

            expect(portErrors).toHaveLength(0);
            expect(vmErrors).toHaveLength(0);

            expect(
                lbErrors.filter(
                    (e) => e.type === "disabled" && e.code === "lb-egress.none"
                )
            ).toHaveLength(1);

            expect(
                lbErrors.filter(
                    (e) => e.type === "disabled" && e.code === "lb-ingress.none"
                )
            ).toHaveLength(1);

            expect(
                lbErrors.filter(
                    (e) =>
                        e.type === "disabled" && e.code === "lb.instances.none"
                )
            ).toHaveLength(1);

            expect(
                gatewayErrors.filter(
                    (e) =>
                        e.type === "disabled" &&
                        e.code === "gateway-egress.none"
                )
            ).toHaveLength(1);

            expect(
                gatewayErrors.filter(
                    (e) =>
                        e.type === "disabled" &&
                        e.code === "gateway-ingress.none"
                )
            ).toHaveLength(1);

            expect(
                gatewayErrors.filter(
                    (e) =>
                        e.type === "disabled" &&
                        e.code === "gateway.instances.none"
                )
            ).toHaveLength(1);
        });

        it("checks fully disabled but DMZ and non-DMZ records and static IP pointing to vm", () => {
            const { vm, hypervisor } = buildVmAndContainerResource({
                state: "running",
                network: "disable",

                ips: ["thisisanIP"],
                domains: [
                    { fqdn: "HELLO", dmz: true, tls: false },
                    { fqdn: "HELLO", dmz: false, tls: false },
                ],
            });

            const lb = buildServiceContainer("running", 1);
            const gateway = buildServiceContainer("running", 1);

            const lbV1Config = buildLbV1Config([
                { port: 443, tls: true },
                { port: 80, tls: false },
            ]);

            const gatewayErrors = analyzeGatewayStatus(vm, gateway);
            const vmErrors = analyzeVirtualMachineStatus(vm);
            const lbErrors = analyzeLoadBalancerStatus(vm, lb);

            const portErrors = analyzePortConfiguration(
                vm,
                hypervisor,
                lbV1Config
            );

            expect(portErrors).toHaveLength(0);
            expect(gatewayErrors).toHaveLength(0);
            expect(lbErrors).toHaveLength(0);

            expect(vmErrors).toContainEqual(
                expect.objectContaining({
                    type: "disabled",
                })
            );

            expect(
                vmErrors.filter(
                    (e) =>
                        e.type === "warning" &&
                        e.code === "lb-ingress.network.disabled"
                )
            ).toHaveLength(1);

            expect(
                vmErrors.filter(
                    (e) =>
                        e.type === "warning" &&
                        e.code === "gateway-ingress.network.disabled"
                )
            ).toHaveLength(2);
        });
    });

    describe("tests public networking egress only errors", () => {
        it("checks egress routing through server", () => {
            const { vm, hypervisor } = buildVmAndContainerResource({
                state: "running",
                network: "egress-only",
                domains: [],
            });

            const lb = buildServiceContainer("running", 1);
            const gateway = buildServiceContainer("running", 1);

            const lbV1Config = buildLbV1Config([
                { port: 443, tls: true },
                { port: 80, tls: false },
            ]);

            const vmErrors = analyzeVirtualMachineStatus(vm);

            const networkStatus = analyzeVirtualMachineNetworkStatus(
                hypervisor,
                lb,
                lbV1Config,
                vm,
                gateway
            );

            expect(networkStatus.hasStaticIps).toBe(false);

            expect(vmErrors).toHaveLength(0);
        });

        it("checks egress routing through gateway", () => {
            const { vm, hypervisor } = buildVmAndContainerResource({
                state: "running",
                network: "egress-only",
                ips: ["thisisanIP"],
                domains: [],
            });

            const lb = buildServiceContainer("running", 1);
            const gateway = buildServiceContainer("running", 1);

            const lbV1Config = buildLbV1Config([
                { port: 443, tls: true },
                { port: 80, tls: false },
            ]);

            const gatewayErrors = analyzeGatewayStatus(vm, gateway);

            const networkStatus = analyzeVirtualMachineNetworkStatus(
                hypervisor,
                lb,
                lbV1Config,
                vm,
                gateway
            );

            expect(networkStatus.hasStaticIps).toBe(true);
            expect(gatewayErrors).toHaveLength(0);
        });
        it("checks egress routing through gateway, and gateway stopped", () => {
            const { vm, hypervisor } = buildVmAndContainerResource({
                state: "running",
                network: "egress-only",
                ips: ["thisisanIP"],
                domains: [],
            });

            const lb = buildServiceContainer("running", 1);
            const gateway = buildServiceContainer("stopped", 1);

            const lbV1Config = buildLbV1Config([
                { port: 443, tls: true },
                { port: 80, tls: false },
            ]);

            const gatewayErrors = analyzeGatewayStatus(vm, gateway);

            const networkStatus = analyzeVirtualMachineNetworkStatus(
                hypervisor,
                lb,
                lbV1Config,
                vm,
                gateway
            );

            expect(networkStatus.hasStaticIps).toBe(true);
            expect(
                gatewayErrors.filter(
                    (e) =>
                        e.type === "error" && e.code === "gateway.state.offline"
                )
            ).toHaveLength(1);
        });

        it("checks egress routing through gateway, and gateway stopped, with domain pointing to vm", () => {
            const { vm, hypervisor } = buildVmAndContainerResource({
                state: "running",
                network: "egress-only",
                ips: ["thisisanIP"],
                domains: [
                    { fqdn: "HELLO", dmz: false, tls: true },
                    { fqdn: "HELLO", dmz: true, tls: false },
                ],
            });

            const lb = buildServiceContainer("running", 1);
            const gateway = buildServiceContainer("stopped", 1);

            const lbV1Config = buildLbV1Config([
                { port: 443, tls: true },
                { port: 80, tls: false },
            ]);

            const gatewayErrors = analyzeGatewayStatus(vm, gateway);
            const vmErrors = analyzeVirtualMachineStatus(vm);

            const networkStatus = analyzeVirtualMachineNetworkStatus(
                hypervisor,
                lb,
                lbV1Config,
                vm,
                gateway
            );

            expect(networkStatus.hasStaticIps).toBe(true);
            expect(
                gatewayErrors.filter(
                    (e) =>
                        e.type === "error" && e.code === "gateway.state.offline"
                )
            ).toHaveLength(1);

            expect(
                vmErrors.filter(
                    (e) =>
                        e.type === "warning" &&
                        e.code === "vm.egress-only.has-domains"
                )
            ).toHaveLength(2);
        });
    });
}
