import {
    faTrash,
    faArrowCircleUp,
    faQuestionCircle,
    faPlus,
    faFileDownload,
} from "@fortawesome/pro-solid-svg-icons";
import { Activity, ComponentIncludes, Image } from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import { ImageLink } from "~/components/common/resources/ResourceLinks";
export function formatImageMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const imageLink = (
        <ImageLink imageId={item.component?.id} components={components} />
    );
    switch (item.event) {
        case "image.import":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Imported image {imageLink}</span>
                ) : (
                    <span>Error importing image {imageLink} </span>
                ),
                icon: faFileDownload,
            };
        case "image.task.import":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested import of image {imageLink}</span>
                ) : (
                    <span>Error requesting import of image {imageLink}</span>
                ),
                icon: faFileDownload,
            };
        case "image.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated image {imageLink}</span>
                ) : (
                    <span>Error updating image {imageLink}</span>
                ),
                icon: faArrowCircleUp,
            };
        case "image.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Created image {imageLink}</span>
                ) : (
                    <span>Error creating image</span>
                ),
                icon: faPlus,
            };
        case "image.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted image {imageLink}</span>
                ) : (
                    <span>Error deleting image {imageLink}</span>
                ),
                icon: faTrash,
            };
        case "image.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested image {imageLink} be deleted</span>
                ) : (
                    <span>Error requesting image {imageLink} be deleted</span>
                ),
                icon: faTrash,
            };
        case "image.source.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Created new image source {imageLink}</span>
                ) : (
                    <span>Error creating new image source {imageLink}</span>
                ),
                icon: faPlus,
            };
        case "image.source.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated image source {imageLink}</span>
                ) : (
                    <span>Failed to update image source {imageLink}</span>
                ),
                icon: faArrowCircleUp,
            };
        case "image.source.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Request image source {imageLink} be deleted</span>
                ) : (
                    <span>
                        Error requesting image source {imageLink} be deleted
                    </span>
                ),
                icon: faTrash,
            };
        case "image.source.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted image source {imageLink}</span>
                ) : (
                    <span>Error deleting image source {imageLink}</span>
                ),
                icon: faTrash,
            };

        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}
