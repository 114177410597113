import {
    faTrash,
    faTimesCircle,
    faPersonDolly,
    faBoxUp,
    faMedkit,
    faTerminal,
} from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { generateDialogLink } from "~/components/dialogs/helpers";
import {
    Activity,
    ComponentIncludes,
    Container,
    Environment,
    Instance,
    Server,
} from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import { faArrowUpRightDots } from "@fortawesome/pro-duotone-svg-icons";
import { NavIcons } from "~/components/layout/NavIcons";
import {
    ContainerInstanceLink,
    ContainerLink,
    EnvironmentLink,
    ServerLink,
} from "~/components/common/resources/ResourceLinks";

export function formatInstanceMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const instanceLink = (
        <ContainerInstanceLink
            instanceId={item.component?.id}
            components={components}
        />
    );

    const containerLink = (
        <ContainerLink
            containerId={item.context.container_id}
            components={components}
        />
    );

    const envLink = (
        <EnvironmentLink
            environmentId={item.context.environment_id}
            components={components}
        />
    );

    switch (item.event) {
        // multiple instances
        case "container.instances.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Created instances for container {containerLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error creating instances for container {containerLink}{" "}
                        in environment {envLink}
                    </span>
                ),
                icon: faBoxUp,
            };
        case "container.instances.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Deleted instances for container {containerLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error deleting instances for container {containerLink}{" "}
                        in environment {envLink}
                    </span>
                ),
                icon: faTrash,
            };

        case "container.instance.volume.extend":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Extended volume for instance {instanceLink} in container{" "}
                        {containerLink}.
                    </span>
                ) : (
                    <span>
                        Error extending volume for instance {instanceLink} in
                        container {containerLink} in environment {envLink}
                    </span>
                ),
                icon: NavIcons["volumes"],
            };

        case "container.instance.task.volume.extend":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested to extend volume for {instanceLink} in
                        container {containerLink}.
                    </span>
                ) : (
                    <span>
                        Error requesting to extend volume for instance{" "}
                        {instanceLink} in container {containerLink}.
                    </span>
                ),
                icon: NavIcons["volumes"],
            };

        // single instance
        case "container.instance.healthcheck.restarted": {
            return {
                message: (
                    <span>
                        Restarted instance {instanceLink} in environment{" "}
                        {envLink} due to failed health check
                    </span>
                ),
                icon: faMedkit,
            };
        }

        case "container.instance.healthcheck.failed": {
            return {
                message: (
                    <span>
                        Health check failed for instance {instanceLink} in
                        container {containerLink}
                    </span>
                ),
                icon: faMedkit,
                iconClassName: "text-error",
            };
        }

        case "container.instance.error":
            return {
                message: (
                    <span>Instance {instanceLink} encountered an error</span>
                ),
                icon: faTimesCircle,
            };

        case "container.instance.ssh.login": {
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Connected via SSH to instance {instanceLink} of
                        container {containerLink}
                    </span>
                ) : (
                    <span>
                        Error connecting via SSH to instance {instanceLink} of
                        container {containerLink}
                    </span>
                ),
                icon: faTerminal,
            };
        }

        case "container.instance.task.migration.start": {
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested migration of instance {instanceLink} of
                        container {containerLink}
                    </span>
                ) : (
                    <span>
                        Error requesting migration of instance {instanceLink} of
                        container {containerLink}
                    </span>
                ),
                icon: faPersonDolly,
            };
        }

        case "container.instance.migration.start": {
            const prevServerLink = (
                <ServerLink
                    serverId={item.changes[0]?.before?.id}
                    components={components}
                />
            );
            const newServerLink = (
                <ServerLink
                    serverId={item.changes[0]?.after?.id}
                    components={components}
                />
            );

            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Started migration of instance {instanceLink} of
                        container {containerLink} from {prevServerLink} to{" "}
                        {newServerLink}
                    </span>
                ) : (
                    <span>
                        Error migrating instance {instanceLink} of container{" "}
                        {containerLink}
                    </span>
                ),
                icon: faPersonDolly,
            };
        }

        case "container.instance.task.migration.revert": {
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested reversal of migration of instance{" "}
                        {instanceLink} of container {containerLink}
                    </span>
                ) : (
                    <span>
                        Error requesting reversal of migration of instance{" "}
                        {instanceLink} of container {containerLink}
                    </span>
                ),
                icon: faPersonDolly,
            };
        }

        case "container.instance.migration.revert": {
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Reverted migration of instance {instanceLink} of
                        container {containerLink}
                    </span>
                ) : (
                    <span>
                        Error reverting migration of instance {instanceLink} of
                        container {containerLink}
                    </span>
                ),
                icon: faPersonDolly,
            };
        }
        case "container.instance.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted instance {instanceLink}</span>
                ) : (
                    <span>Error deleting instance {instanceLink}</span>
                ),
                icon: faTrash,
            };

        case "container.instances.autoscale.up": {
            const ni = item.annotations["new-instances"];
            const si = item.annotations["sibling-instance-id"];
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Automatically created {ni || ""} new instance
                        {ni !== 1 ? "s" : ""} for container {containerLink} due
                        to instance{" "}
                        <Link
                            to={generateDialogLink("container", {
                                "dialog-container-id":
                                    item.context.container_id || "",
                                "dialog-tab": "instances",
                                "dialog-instance-id": si || "",
                            })}
                        >
                            ...
                            {si.slice(-6)}
                        </Link>{" "}
                        hitting auto-scale criteria.
                    </span>
                ) : (
                    <span>Error up-scaling container {containerLink}.</span>
                ),
                icon: faArrowUpRightDots,
            };
        }

        case "container.instances.autoscale.down": {
            const pi = item.annotations["purged-instances"];
            const si = item.annotations["sibling-instance-id"];
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Automatically deleted {pi || ""} instance
                        {pi !== 1 ? "s" : ""} for container {containerLink} that
                        were caused by an auto-scale on instance
                        <Link
                            to={generateDialogLink("container", {
                                "dialog-container-id":
                                    item.context.container_id || "",
                                "dialog-tab": "instances",
                                "dialog-instance-id": si || "",
                            })}
                        >
                            ...
                            {si?.slice(-6)}
                        </Link>
                        .
                    </span>
                ) : (
                    <span>Error down-scaling container {containerLink}.</span>
                ),
                icon: faArrowUpRightDots,
                iconClassName: "rotate-90",
            };
        }

        default:
            return {
                // This will most likely be a custom activity.
                message: item.event,
                // icon: faQuestionCircle,
            };
    }
}
