/**
 * Labels for auto-scaling thresholds based on the threshold identifier.
 */
export const ThresholdLabel = {
    ram: "RAM",
    cpu: "CPU",
    custom: "Custom",
    "network-connections": "Network Connections",
    "network-requests": "Network Requests",
    "network-throughput": "Network Throughput",
} as const;
