import { ComponentIncludes, PipelineRunStep } from "~/services/cycle";
import classNames from "classnames";
import { PipelineRunStepMessage } from "./PipelineStepMessage";
import { PipelineStepDuration } from "./PipelineStepDuration";
import { PipelineStepError } from "./PipelineStepError";
import { PipelineStepErrorIcon } from "./PipelineStepErrorIcon";
import { Button } from "@cycleplatform/ui/components/buttons";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { StackBuildGenerateDetails } from "./advancedStepDetails/StackBuildGenerateDetails";
import { useState } from "react";
import { ImageImportDetails } from "./advancedStepDetails/ImageImportDetails";
import { StackBuildCreateDetails } from "./advancedStepDetails/StackBuildCreateDetails";
import { CycleErrorBoundary } from "~/components/common/errors";

export function Step({
    step,
    isRunning,
    components,
}: {
    step: PipelineRunStep;
    isRunning: boolean;
    components?: ComponentIncludes;
}) {
    const [isExpanded, setIsExpanded] = useState(false);
    const advancedComponent = getAdvancedStepComponent(step);

    const status = step.error
        ? "error"
        : isRunning
        ? "running"
        : step.success
        ? "complete"
        : "idle";

    return (
        <li className="flex">
            <div
                className={classNames(
                    "border-l-4",
                    status === "error" && "border-error",
                    status === "running" && "border-success animate-pulse",
                    status === "complete" && "border-success",
                    status === "idle" &&
                        "border-cycle-gray dark:border-cycle-gray-light/50"
                )}
            />
            <div className="w-full">
                <div
                    className={classNames(
                        "flex w-full items-center justify-between gap-4 p-4"
                    )}
                >
                    <div>
                        <PipelineRunStepMessage
                            step={step}
                            components={components}
                        />
                        <PipelineStepDuration step={step} />
                        <PipelineStepError step={step} />
                    </div>

                    <PipelineStepErrorIcon step={step} />

                    {advancedComponent !== null ? (
                        <Button
                            onClick={() => setIsExpanded(!isExpanded)}
                            icon={isExpanded ? faChevronUp : faChevronDown}
                        />
                    ) : null}
                </div>

                {isExpanded ? (
                    <div className="pl-4"> {advancedComponent}</div>
                ) : null}
            </div>
        </li>
    );
}

function getAdvancedStepComponent(
    step: PipelineRunStep
): React.ReactNode | null {
    if (!step.components) {
        return null;
    }

    if (step.action === "stack.build.generate") {
        return (
            <CycleErrorBoundary>
                <StackBuildGenerateDetails step={step} />
            </CycleErrorBoundary>
        );
    }

    if (step.action === "stack.build.create") {
        return (
            <CycleErrorBoundary>
                <StackBuildCreateDetails step={step} />
            </CycleErrorBoundary>
        );
    }

    if (step.action.startsWith("image") && step.action.endsWith("import")) {
        return (
            <CycleErrorBoundary>
                <ImageImportDetails step={step} />
            </CycleErrorBoundary>
        );
    }

    return null;
}
