import { $error } from "@cycleplatform/core/util/log";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Announcement, useGetAnnouncementsQuery } from "~/services/cycle";
import classNames from "classnames";
import { DetailsPopout } from "@cycleplatform/ui/components/tooltip";
import {
    AnnouncementItem,
    announcementPriorityInfo,
} from "@cycleplatform/ui/components/announcements";

export function Announcements() {
    const { data: a, error } = useGetAnnouncementsQuery({
        filter: {
            resolved: "false",
        },
        page: {
            number: 1,
            size: 10,
        },
    });

    const announcements = a?.data
        ? [...a.data].sort((a: Announcement, b: Announcement) => {
              const severityA =
                  announcementPriorityInfo[a.priority]?.severityIdx ?? 0;
              const severityB =
                  announcementPriorityInfo[b.priority]?.severityIdx ?? 0;
              return severityB - severityA;
          })
        : [];

    // we don't show errors here because if this fails, we don't really care right now.
    if (error) {
        $error("error fetching announcements", error);
    }

    if (!announcements || !announcements.length) {
        return null;
    }

    const latestAnnouncement = announcements[0];

    return (
        <DetailsPopout
            className={classNames(
                "flex w-full items-center gap-4 rounded-md border p-2",
                announcementPriorityInfo[latestAnnouncement.priority].border
            )}
            placement="right"
            popupClassName="w-[40rem] z-50"
            message={
                <ul className="flex flex-col gap-4">
                    {announcements?.map((a) => (
                        <AnnouncementItem announcement={a} />
                    ))}
                </ul>
            }
        >
            <FontAwesomeIcon
                icon={faTriangleExclamation}
                className={
                    announcementPriorityInfo[latestAnnouncement.priority].icon
                }
            />
            {announcements.length} Announcement
            {announcements.length > 1 ? "s" : ""}
        </DetailsPopout>
    );
}
