import {
    faTrash,
    faArrowCircleUp,
    faQuestionCircle,
    faPlus,
    faSync,
} from "@fortawesome/pro-solid-svg-icons";
import { Activity, ComponentIncludes } from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import { NetworkLink } from "~/components/common/resources/ResourceLinks";

export function formatSDNMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const networkLink = (
        <NetworkLink networkId={item.component?.id} components={components} />
    );

    switch (item.event) {
        case "sdn.network.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested deletion of network {networkLink}</span>
                ) : (
                    <span>
                        Error requesting deletion of network {networkLink}
                    </span>
                ),
                icon: faTrash,
            };
        case "sdn.network.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated network {networkLink}</span>
                ) : (
                    <span>Error updating network {networkLink}</span>
                ),
                icon: faArrowCircleUp,
            };
        case "sdn.network.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Created network {networkLink}</span>
                ) : (
                    <span>Error creating network</span>
                ),
                icon: faPlus,
            };
        case "sdn.network.task.reconfigure":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested reconfiguration of network {networkLink}
                    </span>
                ) : (
                    <span>
                        Error requesting reconfiguration of network{" "}
                        {networkLink}
                    </span>
                ),
                icon: faSync,
            };
        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}
