import {
    RhfFormProvider,
    SectionDisabledControl,
} from "@cycleplatform/ui/components/forms";
import { useForm, useWatch } from "react-hook-form";
import { getDefaultLbFormVals, LoadBalancerFormValues } from "../../../form";
import { V1LbConfig } from "~/services/cycle";
import classNames from "classnames";
import { getAllDialogSearchParams } from "~/components/dialogs/helpers";
import { V1GeneralSection } from "./sections/general/V1GeneralSection";
import { V1RouterSection } from "./sections/routers/V1RouterSection";
import { V1ExtensionSection } from "./sections/extension/V1ExtensionSection";
import { rhfConfig } from "~/components/forms/util";
import { useContext } from "react";
import { LoadBalancerDialogContext } from "../../../../context";
import { useKeepFormCurrent } from "~/components/common/forms";
import { V1ConfigFooter } from "./V1LbFormFooter";
import { Panel, PanelContent } from "@cycleplatform/ui/components/panels";
import { V1UsePlatformDefaultsButton } from "./sections/buttons/V1UsePlatformDefaultsButton";
import { V1CustomizeButton } from "./sections/buttons/V1CustomizeButton";

export function V1PortForm() {
    const { service } = useContext(LoadBalancerDialogContext);
    const params = getAllDialogSearchParams<"environment-lb-manage">();
    const activePortParam = params["dialog-port"];

    const form = useForm<LoadBalancerFormValues>({
        defaultValues: getDefaultLbFormVals(service),
        ...rhfConfig,
    });
    const { control } = form;

    useKeepFormCurrent(form, service, (lb) => getDefaultLbFormVals(lb));

    const currentConfig = useWatch({
        name: "config.details",
        control,
    }) as V1LbConfig;

    const activePortIdx = currentConfig.controllers.findIndex(
        (c) => `${c.port}` === activePortParam
    );

    const baseRoute =
        `config.details.controllers.${activePortIdx}` as `config.details.controllers.${number}`;

    const isTransportDefined =
        !!currentConfig.controllers[activePortIdx]?.transport;

    return (
        <RhfFormProvider {...form}>
            <div className={classNames("pb-24")}>
                <SectionDisabledControl
                    disabled={!currentConfig}
                    className="flex flex-col gap-4"
                >
                    <Panel>
                        <PanelContent>
                            <div className="flex w-full items-center justify-between">
                                <div>
                                    {isTransportDefined
                                        ? "Using Custom Config"
                                        : "Using Platform Defaults"}
                                </div>
                                {isTransportDefined ? (
                                    <V1UsePlatformDefaultsButton
                                        activePortIdx={activePortIdx}
                                    />
                                ) : (
                                    <V1CustomizeButton
                                        activePortIdx={activePortIdx}
                                        activePortParam={activePortParam}
                                        currentConfig={currentConfig}
                                    />
                                )}
                            </div>
                        </PanelContent>
                    </Panel>

                    {isTransportDefined ? (
                        <>
                            <V1GeneralSection baseRoute={baseRoute} />
                            <V1RouterSection baseRoute={baseRoute} />
                            <V1ExtensionSection baseRoute={baseRoute} />
                        </>
                    ) : null}
                </SectionDisabledControl>
            </div>
            {isTransportDefined ? <V1ConfigFooter /> : null}
        </RhfFormProvider>
    );
}
