import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import {
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Environment, useCreateVpnServiceJobMutation } from "~/services/cycle";
import {
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { handleSubmitError } from "~/components/forms/util";
import { AccessControlOverlay } from "~/components/common/buttons";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { ContainerDialogContext } from "~/components/dialogs/containers/container/context";

export function VpnDisableSection() {
    const { environment } = useContext(ContainerDialogContext);
    const form = useForm({
        defaultValues: {
            env_name: "",
        },
    });
    const {
        register,
        handleSubmit,
        setValue,
        formState: { isDirty, isSubmitting },
    } = form;

    const [reconfigureVpn] = useCreateVpnServiceJobMutation();
    const [formError, setFormError] = useState<string>();

    const onSubmit = (data: { env_name: string }) => {
        if (!isDirty) {
            return;
        }
        setFormError(undefined);

        if (data.env_name !== environment?.name) {
            setFormError("Environment Name does not match");
            return;
        }

        return reconfigureVpn({
            environmentId: environment?.id || "",
            vpnTask: {
                action: "reconfigure",
                contents: {
                    enable: false,
                },
            },
        })
            .unwrap()
            .then(() => {
                setValue("env_name", "");
                form.reset((formValues) => ({ ...formValues }));
            })
            .catch(handleSubmitError(form.setError));
    };

    return (
        <Panel>
            <RhfFormProvider {...form}>
                <PanelTitle title="VPN Disable" />
                <PanelContent>
                    <div className="pb-2">
                        <span>Enter</span>{" "}
                        <strong className="text-error">
                            {environment?.name}
                        </strong>{" "}
                        <span>
                            into the box below to disable this VPN Service.
                        </span>
                    </div>
                    <strong>
                        Any connected users will lose access if they are
                        connected via a VPN to this environment.
                    </strong>
                    <TextInput
                        className="my-4 w-full"
                        {...register("env_name")}
                    />

                    <PanelFooter>
                        <div>
                            {formError && (
                                <p className="text-error mt-4 text-sm">
                                    {formError}
                                </p>
                            )}
                            <RhfGlobalFormError />
                        </div>
                        <AccessControlOverlay
                            aclResource={environment}
                            verifyFn={modifyAccessFn(
                                "environments-services-manage"
                            )}
                        >
                            <PushAndHoldButton
                                onClick={handleSubmit(onSubmit)}
                                flavor="discard"
                                disabled={!isDirty}
                                isLoading={isSubmitting}
                                icon={faTimes}
                            >
                                Disable VPN Services
                            </PushAndHoldButton>
                        </AccessControlOverlay>
                    </PanelFooter>
                </PanelContent>
            </RhfFormProvider>
        </Panel>
    );
}
