import { Container, Environment } from "~/services/cycle";
import {
    DialogPageBody,
    DialogPageHeader,
} from "@cycleplatform/ui/components/dialog";
import { generateInstanceLabelMap } from "./helpers";
import { useFiltering } from "@cycleplatform/ui/components/forms/filters";
import { NavIcons } from "~/components/layout/NavIcons";
import { InstanceTelemetryFilters } from "~/components/instances/telemetry/charts/InstanceTelemetryFilters";
import {
    generateInstanceGranularity,
    useGenerateInstanceColorMap,
    useHandleInstanceLookups,
} from "~/components/instances/telemetry/helpers";
import { Panel, PanelTitle } from "@cycleplatform/ui/components/panels";
import { useMemo } from "react";
import { InstanceTelemtrySection } from "~/components/instances/telemetry/InstanceTelemetrySection";

type TelemetryTabProps = {
    container?: Container;
    environment?: Environment;
};

export function TelemetryTab({ container, environment }: TelemetryTabProps) {
    const { registerIds, components } = useHandleInstanceLookups(["servers"]);

    const instanceLabelMap = useMemo(
        () =>
            generateInstanceLabelMap({
                servers: components.servers,
                instances: components.instances,
            }),
        [Object.values(components.instances)]
    );
    const instanceColorMap = useGenerateInstanceColorMap(components.instances);

    const filtering = useFiltering();
    const { accessExclusiveValue, isReady } = filtering;

    const instanceLimit = accessExclusiveValue({
        key: "instanceLimit",
        isNumber: true,
    }) as number | undefined;

    const range = accessExclusiveValue({
        key: "range",
        isNumber: true,
    }) as number | undefined;

    const currentLimit = instanceLimit || 5;
    const currentRange = range || 24;

    return (
        <>
            <DialogPageHeader
                title="Telemetry"
                icon={NavIcons["telemetry"]}
                className="bg-cycle-white-accent  px-4 pt-4"
            />
            <DialogPageBody>
                <Panel>
                    <PanelTitle
                        title={`Instance Resource Usage - (top ${instanceLimit} instances) `}
                        className="flex justify-between"
                    >
                        <InstanceTelemetryFilters
                            filtering={filtering}
                            className="font-normal"
                        />
                    </PanelTitle>

                    <div className="relative h-full pb-24">
                        <InstanceTelemtrySection
                            instanceLabelMap={instanceLabelMap}
                            instanceColorMap={instanceColorMap}
                            filtering={{
                                granularity:
                                    generateInstanceGranularity(currentRange),
                                instanceLimit: currentLimit,
                                range: currentRange,
                                isBuffering: !isReady,
                            }}
                            registerIds={registerIds}
                            components={components}
                            criteria={
                                container?.id && environment?.cluster
                                    ? {
                                          "labels.container_id": container.id,
                                          "metadata.cluster":
                                              environment.cluster,
                                      }
                                    : undefined
                            }
                            tableInclude={["servers"]}
                        />
                    </div>
                </Panel>
            </DialogPageBody>
        </>
    );
}
