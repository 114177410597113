import { useContext } from "react";
import { useAppSelector } from "~/hooks";
import { getCurrentForm } from "~/modules/forms/slice";
import { MultiStepDialogContext } from "./context";

export function useGetMultiStepFormValues<T>() {
    const { formKey } = useContext(MultiStepDialogContext);

    const currentForm = useAppSelector((state) =>
        getCurrentForm(state, formKey)
    );
    return currentForm?.steps as T;
}
