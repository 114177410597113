import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { components } from "@cycleplatform/core/modules/api/__generated";
import { ResourceStateBadge } from "../resources/state";
import { NavLink, To } from "react-router-dom";
import { PageTitle } from "../page";
import { HeaderTab, HeaderTabList } from "../page/tabs";

export interface DialogPageHeaderTab {
    to: To;
    name: string;
    // exact?: boolean;
    beta?: boolean;
    isActive?: boolean;
}

type DialogPageHeaderProps = {
    tabs?: DialogPageHeaderTab[];
    className?: string;
    childrenClassName?: string;
    title?: string;
    children?: React.ReactNode;
    icon?: IconDefinition;
    /** null will render a placeholder. */
    state?: (components["schemas"]["State"] & { current: string }) | null;
    /** Badge is a prop that will allow the user to provide a custom badge rather than the default state badge */
    badge?: React.ReactNode;
};

export function DialogPageHeader({
    tabs,
    className,
    childrenClassName,
    children,
    title,
    state,
    badge = null,
    icon,
}: DialogPageHeaderProps) {
    return (
        <header
            className={classNames(
                className,
                "dark:border-cycle-gray  dark:bg-transparent",
                !tabs && "mb-2"
            )}
        >
            <div className="flex h-[45px] flex-1 items-center">
                <PageTitle
                    isLoading={title === undefined}
                    className="mr-4 flex min-w-[5rem] items-center overflow-hidden text-ellipsis whitespace-nowrap "
                >
                    {icon ? (
                        <FontAwesomeIcon
                            icon={icon}
                            className="text-cycle-blue pb-[0.1rem] pr-2 text-2xl"
                        />
                    ) : null}{" "}
                    {title}
                </PageTitle>

                {state !== undefined ? (
                    <ResourceStateBadge
                        state={state || undefined}
                        className="mr-4"
                    />
                ) : null}

                {badge}

                <div
                    className={classNames(
                        "flex flex-1 items-center justify-end",
                        childrenClassName
                    )}
                >
                    {children}
                </div>
            </div>

            {tabs && (
                <HeaderTabList>
                    {tabs.map((t) => (
                        <NavLink
                            key={t.name}
                            to={t.to}
                            className={classNames(
                                className,
                                "text-cycle-gray dark:text-cycle-white hover:text-cycle-blue inline-block py-3 px-5 font-semibold ",
                                t.isActive
                                    ? " border-cycle-blue !text-cycle-blue border-b-2"
                                    : ""
                            )}
                        >
                            {t.name}
                        </NavLink>
                    ))}
                </HeaderTabList>
            )}
        </header>
    );
}
