import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import { useNavigate } from "react-router-dom";
import { StackSpec, useExportStackMutation } from "~/services/cycle";
import {
    clearDialogParams,
    getAllDialogSearchParams,
    isDialogOpen,
} from "~/components/dialogs/helpers";
import { Controller, useForm } from "react-hook-form";
import { rhfConfig } from "~/components/forms/util";
import { DeploymentTagMultiSelect } from "~/components/environments/deployments";
import { CodeBox } from "~/components/common/code";
import { useCallback, useEffect, useState } from "react";
import { isCycleApiError } from "~/services/helpers";
import { CopyButton, LoaderButton } from "@cycleplatform/ui/components/buttons";
import { faRefresh } from "@fortawesome/pro-solid-svg-icons";
import { Checkbox, FormField } from "@cycleplatform/ui/components/forms";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";

export function ExportEnvironmentDialog() {
    const navigate = useNavigate();
    const [spec, setSpec] = useState<StackSpec>({} as StackSpec);
    const [error, setError] = useState<string>();

    const { ["dialog-env"]: envId } =
        getAllDialogSearchParams<"environment-export">();

    const form = useForm<{ tags: string[]; resolve_origins: boolean }>({
        ...rhfConfig,
    });

    const [exportAsStack, { isLoading }] = useExportStackMutation();

    const onClose = () => navigate(clearDialogParams());

    useEffect(() => {
        submit({ tags: [], resolve_origins: false });
    }, []);

    const submit = useCallback(
        (data: { tags: string[]; resolve_origins: boolean }) => {
            setError(undefined);
            exportAsStack({
                environmentId: envId,
                body: {
                    deployment_tags:
                        data.tags?.length > 0 ? data.tags : undefined,
                    resolve_origins: data.resolve_origins,
                },
            })
                .unwrap()
                .then((res) => {
                    setSpec(res.data);
                })
                .catch((e) => {
                    if (isCycleApiError(e)) {
                        setError(
                            `ERROR: ${e.data.error.title} - ${e.data.error.detail}`
                        );
                    } else {
                        setError(`ERROR: ${e}`);
                    }
                });
        },
        [exportAsStack]
    );

    return (
        <StyledFormDialog
            title={`Export Environment`}
            isOpen={isDialogOpen("environment-export")}
            onClose={onClose}
        >
            <DialogColumn className="w-full !pb-0">
                <form
                    className="flex flex-col gap-4"
                    onSubmit={form.handleSubmit(submit)}
                >
                    <div className="mt-4 flex grow items-center gap-4">
                        <FormField
                            help="When set, will inline image source definitions directly into the stack to make it portable between hubs."
                            className="!w-52"
                            label="Resolve Origins"
                        >
                            <Checkbox {...form.register("resolve_origins")} />
                        </FormField>

                        <FormField label="Include Deployments">
                            <Controller
                                render={({
                                    field: { ref: _ref, ...field },
                                }) => (
                                    <DeploymentTagMultiSelect
                                        {...field}
                                        className="w-full"
                                        placeholder="Include containers from deployment tags"
                                        environmentId={envId}
                                        value={field.value || undefined}
                                    />
                                )}
                                control={form.control}
                                name={`tags`}
                            />
                        </FormField>

                        <LoaderButton
                            isLoading={isLoading}
                            className="mt-4"
                            type="submit"
                            flavor="primary"
                            icon={faRefresh}
                        >
                            Regenerate
                        </LoaderButton>
                    </div>

                    <div className="relative">
                        <CodeBox
                            className="h-full overflow-y-auto"
                            value={
                                error ? error : JSON.stringify(spec, null, 2)
                            }
                        />
                        <CopyButton
                            className="absolute top-4 right-5 !rounded-md !px-2"
                            textToCopy={JSON.stringify(spec, null, 2)}
                        ></CopyButton>
                    </div>
                </form>
            </DialogColumn>
        </StyledFormDialog>
    );
}
