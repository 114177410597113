import { generateRandomString } from "@cycleplatform/core/util";
import {
    FormSection,
    FormSectionHeader,
    minLength,
    required,
    RhfFormField,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { useFormContext } from "react-hook-form";
import { GenerateIsoFormType } from "../types";

export function IsoInitializeFormSection() {
    const { register } = useFormContext<GenerateIsoFormType>();

    return (
        <>
            <FormSectionHeader header="Initialization" />
            <FormSection>
                <RhfFormField label="Name" required name="name">
                    <TextInput
                        {...register("name", {
                            ...required(),
                        })}
                    />
                </RhfFormField>

                <RhfFormField label="Token" required name="token">
                    <TextInput
                        defaultValue={generateRandomString(30)}
                        {...register("config.token", {
                            ...required(),
                            ...minLength(30),
                        })}
                    />
                </RhfFormField>
            </FormSection>
        </>
    );
}
