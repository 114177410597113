import { components } from "../api/__generated";

/** Returns the resolved URL this record points to */
export function getResolvedRecordUrl(
    record: components["schemas"]["DnsRecord"]
) {
    return `http${record.features?.certificate ? "s" : ""}://${
        record.resolved_domain
    }`;
}

/** Returns true if the record is a 'resolvable' type and isn't a wildcard domain. */
export function isResolvableRecordType(
    record: components["schemas"]["DnsRecord"]
): boolean {
    return (
        ["a", "aaaa", "cname", "linked", "caa"].includes(
            getRecordType(record)
        ) && !record.name.includes("*")
    );
}

/** Gets the string value of the record type */
export function getRecordType(
    record: components["schemas"]["DnsRecord"]
): keyof components["schemas"]["DnsRecordTypes"] {
    return Object.keys(record.type)[0] as "a";
}

/** A type that explicitly states the type of the record and defines the type field as not null. */
export type TypedRecord<
    T extends keyof components["schemas"]["DnsRecordTypes"]
> = Omit<components["schemas"]["DnsRecord"], "type"> & {
    type: Record<T, NonNullable<components["schemas"]["DnsRecordTypes"][T]>>;
};

/** If the record is an A type */
export function isATypeRecord(
    record: components["schemas"]["DnsRecord"]
): record is TypedRecord<"a"> {
    return "a" in record.type;
}

/** If the record is an AAAA type */
export function isAaaaTypeRecord(
    record: components["schemas"]["DnsRecord"]
): record is TypedRecord<"aaaa"> {
    return "aaaa" in record.type;
}

/** If the record is a CNAME type */
export function isCnameTypeRecord(
    record: components["schemas"]["DnsRecord"]
): record is TypedRecord<"cname"> {
    return "cname" in record.type;
}

/** If the record is an NS type */
export function isNsTypeRecord(
    record: components["schemas"]["DnsRecord"]
): record is TypedRecord<"ns"> {
    return "ns" in record.type;
}

/** If the record is an MX type */
export function isMxTypeRecord(
    record: components["schemas"]["DnsRecord"]
): record is TypedRecord<"mx"> {
    return "mx" in record.type;
}

/** If the record is a TXT type */
export function isTxtTypeRecord(
    record: components["schemas"]["DnsRecord"]
): record is TypedRecord<"txt"> {
    return "txt" in record.type;
}

/** If the record is an ALIAS type */
export function isAliasTypeRecord(
    record: components["schemas"]["DnsRecord"]
): record is TypedRecord<"alias"> {
    return "alias" in record.type;
}

/** If the record is an SRV type */
export function isSrvTypeRecord(
    record: components["schemas"]["DnsRecord"]
): record is TypedRecord<"srv"> {
    return "srv" in record.type;
}

/** If the record is an CAA type */
export function isCaaTypeRecord(
    record: components["schemas"]["DnsRecord"]
): record is TypedRecord<"caa"> {
    return "caa" in record.type;
}

/** If the record is a LINKED type */
export function isLinkedTypeRecord(
    record: components["schemas"]["DnsRecord"]
): record is TypedRecord<"linked"> {
    return "linked" in record.type;
}
