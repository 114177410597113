import { formatRFC3339, roundToNearestHours, subDays } from "date-fns";

export function buildServerUsageQuery() {
    const rangeStart = formatRFC3339(
        roundToNearestHours(subDays(new Date(), 7), {
            nearestTo: 1,
            roundingMethod: "floor",
        })
    );
    const rangeEnd = formatRFC3339(
        roundToNearestHours(new Date(), {
            nearestTo: 1,
            roundingMethod: "floor",
        })
    );

    return {
        filter: {
            "range-start": rangeStart,
            "range-end": rangeEnd,
        },
        body: {
            criteria: {
                "metadata.metric": {
                    $in: ["infrastructure.cluster.servers.num"],
                },
            },
            pipeline: [
                {
                    $sort: {
                        time: 1,
                    },
                },
                {
                    $project: {
                        metadata: "$metadata",
                        time: {
                            $dateTrunc: {
                                date: "$time",
                                unit: "day",
                                binSize: 1,
                            },
                        },
                        point: {
                            $arrayElemAt: [
                                {
                                    $last: "$points",
                                },
                                1,
                            ],
                        },
                    },
                },

                {
                    $group: {
                        _id: {
                            day: {
                                $dayOfYear: "$time",
                            },
                            hour: {
                                $hour: "$time",
                            },
                            minute: {
                                $minute: "$time",
                            },
                            cluster: "$metadata.cluster",
                        },
                        count: {
                            $max: "$point",
                        },
                        time: {
                            $last: "$time",
                        },
                    },
                },
                {
                    $sort: {
                        time: 1,
                    },
                },
                {
                    $group: {
                        _id: {
                            day: "$_id.day",
                            hour: "$_id.hour",
                        },
                        servers: {
                            $push: {
                                count: "$count",
                                cluster: "$_id.cluster",
                            },
                        },
                        time: {
                            $last: "$time",
                        },
                    },
                },
                {
                    $project: {
                        _id: 0,
                        time: 1,
                        servers: {
                            $arrayToObject: {
                                $map: {
                                    input: "$servers",
                                    as: "server",
                                    in: {
                                        k: "$$server.cluster",
                                        v: "$$server.count",
                                    },
                                },
                            },
                        },
                    },
                },

                {
                    $sort: {
                        time: 1,
                    },
                },
            ],
        },
    };
}
