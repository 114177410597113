import { FormattedOption } from "@cycleplatform/ui/components/forms/select";
import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import { ResourceComboBox } from "~/components/common/forms";
import { Ip } from "~/services/cycle";

type IpSelectProps = {
    value?: string | null;
    disabled?: boolean;
    onChange: (ipId: string | undefined | null, ip: Ip | undefined) => void;
    ips: Ip[];
    filterResult?: (ip: Ip[]) => Ip[];
};

export function IpSelect({
    value,
    disabled,
    onChange,
    ips,
    filterResult = (ips) => ips,
}: IpSelectProps) {
    return (
        <ResourceComboBox
            placeholder="Automatic"
            filterFields={["id"]}
            // isLoading={isLoading}
            value={value}
            isNullable={true}
            onChange={onChange}
            disabled={disabled}
            resources={filterResult(ips)}
            formatDisplayValue={(ip) => {
                if (!ip) {
                    return "Auto";
                }

                return ip.address;
            }}
            formatOption={(ip) => (
                <FormattedOption
                    label={ip.address}
                    // detail={`${s.provider.vendor}: ${s.provider.model}`}
                >
                    <ResourceStateBadge state={ip.state} />
                </FormattedOption>
            )}
        />
    );
}
