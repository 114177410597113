import { $info } from "@cycleplatform/core/util/log";
import { STARTER_TIER_PROMO_CODE } from "@cycleplatform/ui/components/resources";
import { useFormContext } from "react-hook-form";
import { handleSubmitError } from "~/components/forms/util";
import { useJobTracker } from "~/modules/jobs/hooks";
import {
    CreateOrderApiArg,
    TierPlan,
    useCreateOrderJobMutation,
    useCreateOrderMutation,
} from "~/services/cycle";

export function useHandlePlanUpdate({
    onSuccess,
    tiers,
}: {
    onSuccess: () => void;
    tiers?: TierPlan[];
}) {
    const form = useFormContext<CreateOrderApiArg["body"]>();

    const [createOrder] = useCreateOrderMutation();
    const [createOrderJob] = useCreateOrderJobMutation();
    const [trackJob] = useJobTracker();

    return (data: CreateOrderApiArg["body"]) => {
        let promo = data.promo_code;

        const t = tiers?.find((t) => t.id === data.tier_plan_id);

        if (t?.code === "starter") {
            if (!promo) {
                $info("applying free starter promo code");
                promo = STARTER_TIER_PROMO_CODE;
            }
        }

        return createOrder({
            body: { ...data, promo_code: promo },
        })
            .unwrap()
            .then((o) =>
                trackJob(
                    createOrderJob({
                        orderId: o?.data?.id || "",
                        body: {
                            action: "confirm",
                        },
                    }).unwrap()
                )
            )
            .then(onSuccess)
            .catch((e) => {
                handleSubmitError(form.setError)(e);
            });
    };
}
