import { ButtonLink } from "@cycleplatform/ui/components/buttons";
import {
    PillButtons,
    RhfFormProvider,
} from "@cycleplatform/ui/components/forms";
import {
    FormattedOption,
    SelectInput,
} from "@cycleplatform/ui/components/forms/select";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import {
    InfoPanel,
    Panel,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import classNames from "classnames";
import { Controller, useForm, useFormContext } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { InstanceRamChart } from "~/components/containers/charts/InstanceRamChart";
import { reportSelectOptions } from "~/components/containers/charts/telemetry";
import {
    Container,
    Instance,
    useGetInstanceTelemetryReportQuery,
    useGetServerQuery,
    useTransformedInstanceTelemetryStream,
} from "~/services/cycle";
import { PositionedMenu } from "@cycleplatform/ui/components/menus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/pro-solid-svg-icons";
import { ContainerDialogSearchParams } from "../searchparams";
import { InstanceNetworkChart } from "~/components/containers/charts/InstanceNetworkChart";
import { CycleErrorBoundary } from "~/components/common/errors";
import { faCircle } from "@fortawesome/pro-duotone-svg-icons";
import { useNavigatorOnline } from "@cycleplatform/core/hooks";
import { ResourceStateIcon } from "@cycleplatform/ui/components/resources/state";
import { PanelContentBoundary } from "~/components/layout/panels/PanelContentBoundary";
import { InstanceCpuChart } from "~/components/containers/charts/InstanceCpuChart";
import { isCycleApiError } from "~/services/helpers";
import { useMemo } from "react";

type InstanceChartsProps = {
    instance: Instance;
    container: Container;
    title?: string;
};

export type TelemetryOptionsType = {
    mode: "stream" | "report";
    option: {
        label: string;
        value: string;
        disabled: boolean;
    };
};

export function InstanceCharts({
    instance,
    container,
    title = "Instance Telemetry",
}: InstanceChartsProps) {
    const telemetryDisabled = container?.config?.deploy?.telemetry?.disable;

    const form = useForm<TelemetryOptionsType>({
        defaultValues: {
            mode: "stream",
            option: reportSelectOptions(undefined)[0],
        },
    });

    const option = form.watch("option");
    const mode = form.watch("mode");

    return (
        <Panel>
            <div className="flex w-full items-center justify-between">
                <PanelTitle title={title} className="!w-[12rem]" />
                <PositionedMenu
                    className={"w-[24rem]"}
                    render={() => (
                        <RhfFormProvider {...form}>
                            <InstanceTelemetryOptions container={container} />
                        </RhfFormProvider>
                    )}
                >
                    <button>
                        <FontAwesomeIcon icon={faGear} />
                    </button>
                </PositionedMenu>
            </div>

            <PanelContentBoundary className="w-full">
                {instance.state.current !== "new" ? (
                    <CycleErrorBoundary>
                        <Charts
                            telemetryDisabled={telemetryDisabled}
                            container={container}
                            instance={instance}
                            option={option}
                            mode={mode}
                        />
                    </CycleErrorBoundary>
                ) : (
                    <div className="flex items-center gap-4 px-4 py-2 ">
                        <ResourceStateIcon state={instance.state} />
                        <p>
                            Instance telemetry will be available once this
                            container has been started for the first time.
                        </p>
                    </div>
                )}
            </PanelContentBoundary>
        </Panel>
    );
}

type ChartsType = {
    instance: Instance;
    container: Container;
    option: TelemetryOptionsType["option"];
    mode: "stream" | "report";
    telemetryDisabled?: boolean;
};

function Charts({
    instance,
    container,
    option,
    mode,
    telemetryDisabled,
}: ChartsType) {
    const [searchParams] = useSearchParams();
    const isOnline = useNavigatorOnline();
    const { currentData: telemetryStream, error: streamError } =
        useTransformedInstanceTelemetryStream(
            {
                containerId: container.id,
                instanceId: instance.id,
            },
            {
                skip: mode !== "stream" || telemetryDisabled,
                refetchOnFocus: false,
                refetchOnReconnect: true,
                refetchOnMountOrArgChange: true,
            }
        );

    const { currentData: server, error: serverError } = useGetServerQuery({
        serverId: instance.server_id,
        meta: ["node"],
    });

    const { currentData: telemetry, error: telemetryError } =
        useGetInstanceTelemetryReportQuery(
            {
                instanceId: instance.id,
                containerId: instance.container_id,
                filter: {
                    // TODO: determine if range end is required here.
                    // ["range-end"]: formatRangeToNearestMinute(),
                    ["range-start"]: option.value,
                },
            },
            { skip: mode !== "report" || telemetryDisabled }
        );

    const getEnableTelemetryLink = () => {
        const nsp = new URLSearchParams(searchParams);
        nsp.set(ContainerDialogSearchParams.tab, "config");
        nsp.set(ContainerDialogSearchParams.config, "deployment");
        nsp.set(ContainerDialogSearchParams.advanced, "open");
        nsp.set(ContainerDialogSearchParams.focus, "telemetry");

        return nsp.toString();
    };

    const currentTelemetry = useMemo(() => {
        const val =
            telemetry?.data?.snapshots ||
            telemetryStream?.data?.telemetry ||
            [];

        return val.filter((t) => !!t);
    }, [telemetry?.data?.snapshots, telemetryStream?.data?.telemetry]);

    if (telemetryError) {
        if (
            isCycleApiError(telemetryError) &&
            telemetryError.data.error.status === 504
        ) {
            // This means the instance hasn't started/isn't running
            return (
                <div className="flex items-center gap-4 px-4 py-4">
                    <FontAwesomeIcon
                        icon={faCircle}
                        className="text-cycle-gray"
                    />
                    <p>{`Telemetry unavailable: ${telemetryError.data.error.title}`}</p>
                </div>
            );
        }
        throw telemetryError;
    }

    if (streamError) {
        throw streamError;
    }
    if (serverError) {
        throw serverError;
    }

    if (telemetryDisabled) {
        return (
            <div className="flex items-center justify-between px-4 py-2 ">
                <p>Telemetry retention is disabled. Click here to enable</p>
                <ButtonLink
                    to={{
                        search: getEnableTelemetryLink(),
                    }}
                >
                    Enable
                </ButtonLink>
            </div>
        );
    }

    if (!telemetryStream?.data && mode === "stream") {
        return (
            <div className="flex items-center gap-4 px-4 py-4">
                <FontAwesomeIcon
                    icon={faCircle}
                    className="text-success animate-pulse"
                />
                <p>{`Connecting to instance telemetry`}</p>
            </div>
        );
    }

    if (
        (telemetryStream?.data?.telemetry?.length || 0) < 2 &&
        mode === "stream"
    ) {
        return (
            <div className="flex items-center gap-4 px-4 py-4">
                <FontAwesomeIcon
                    icon={faCircle}
                    className="text-success animate-pulse"
                />
                <p>{`We're gathering live statistics for this instance. Once there is sufficient data, the telemetry data will be graphed.`}</p>
            </div>
        );
    }

    return (
        <>
            {!isOnline ? (
                <InfoPanel type="warning">
                    Your connection has been interrupted. Attempting to
                    reconnect.
                </InfoPanel>
            ) : null}
            <div className="flex w-full flex-wrap">
                <div className="w-1/2">
                    <PanelTitle title="CPU Usage" />
                    <PanelContentBoundary className="border-none">
                        <div
                            className={classNames(
                                "flex h-60 w-full",
                                currentTelemetry?.length === 0 || !server?.data
                                    ? skeletonStyles
                                    : ""
                            )}
                        >
                            <InstanceCpuChart
                                key={instance.id}
                                telemetry={currentTelemetry}
                                container={container}
                            />
                        </div>
                    </PanelContentBoundary>
                </div>

                <div className="w-1/2">
                    <PanelTitle title="RAM Usage" />
                    <PanelContentBoundary className="border-none">
                        <div
                            className={classNames(
                                "flex h-60 w-full",
                                currentTelemetry?.length === 0 && skeletonStyles
                            )}
                        >
                            <InstanceRamChart
                                key={instance.id}
                                telemetry={currentTelemetry}
                            />
                        </div>
                    </PanelContentBoundary>
                </div>
                {container.config.network.public !== "disable" && (
                    <div className="w-1/2">
                        <PanelTitle title="Network Traffic (Public)" />
                        <PanelContentBoundary className="border-none">
                            <div
                                className={classNames(
                                    "flex h-60 w-full",
                                    currentTelemetry?.length === 0 &&
                                        skeletonStyles
                                )}
                            >
                                <InstanceNetworkChart
                                    key={instance.id}
                                    telemetry={currentTelemetry}
                                    mode="public"
                                />
                            </div>
                        </PanelContentBoundary>
                    </div>
                )}

                <div className="w-1/2">
                    <PanelTitle title="Network Traffic (Private)" />
                    <PanelContentBoundary className="border-none">
                        <div
                            className={classNames(
                                "flex h-60 w-full",
                                currentTelemetry?.length === 0 && skeletonStyles
                            )}
                        >
                            <InstanceNetworkChart
                                key={instance.id}
                                telemetry={currentTelemetry}
                                mode="private"
                            />
                        </div>
                    </PanelContentBoundary>
                </div>
            </div>
        </>
    );
}

export function InstanceTelemetryOptions({
    container,
}: {
    container: Container;
}) {
    const { control, watch } = useFormContext<TelemetryOptionsType>();
    const telemetryConfig = container?.config.deploy.telemetry;
    const mode = watch("mode");

    return (
        <div className="flex flex-col gap-4">
            <Controller
                name={"mode"}
                control={control}
                render={({ field: { ref: _ref, ...field } }) => {
                    return (
                        <PillButtons
                            {...field}
                            options={[
                                {
                                    value: "stream",
                                    label: "Stream",
                                },
                                {
                                    value: "report",
                                    label: "Report",
                                },
                            ]}
                        />
                    );
                }}
            />

            <Controller
                name={"option"}
                control={control}
                render={({ field: { ref: _ref, ...field } }) => (
                    <SelectInput
                        {...field}
                        options={reportSelectOptions(
                            telemetryConfig?.retention
                        )}
                        disabled={mode !== "report"}
                        formatOption={(o) => (
                            <FormattedOption label={o.label} />
                        )}
                        formatDisplayValue={(o) => o.label}
                        isOptionValid={(o) => !o.disabled}
                    />
                )}
            />
        </div>
    );
}
