import { formatDateString } from "@cycleplatform/core/util";
import { faMedkit } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { Instance } from "~/services/cycle";
import classNames from "classnames";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";

type ResourceStateIconProps = {
    instance: Instance;
    className?: string;
};

export function InstanceHealthState({
    instance,
    className,
}: ResourceStateIconProps) {
    const { message, state } = useMemo(() => {
        if (instance.state.health?.healthy === false) {
            return {
                message: `unhealthy since ${formatDateString(
                    instance.state.health.updated,
                    "PPpp"
                )}`,
                state: "unhealthy",
            };
        } else if (instance.state.health?.healthy === true) {
            return {
                message: `healthy`,
                state: "healthy",
            };
        } else {
            return {
                message: `health check has not run`,
                state: "unknown",
            };
        }
    }, [instance]);

    return (
        <Tooltip message={message}>
            <FontAwesomeIcon
                icon={faMedkit}
                className={classNames(
                    className,
                    "text-xs",
                    state === "healthy" && "text-success",
                    state === "unhealthy" && "text-warning",
                    state === "healthy" &&
                        "dark:text-cycle-black-accent/50 text-[#8b8b8b]"
                )}
            />
        </Tooltip>
    );
}
