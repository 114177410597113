import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { getOptionButtonClassName } from "./styles";

type OptionButtonProps = {
    onClick: () => void;
    isActive: boolean;
    title: React.ReactNode;
    description?: React.ReactNode;
    icon?: IconDefinition;
    iconClassName?: string;
    disabled?: boolean;
};

// TODO: Will eventually be replaced in all cases by Option Tab.
export function OptionButton({
    onClick,
    isActive,
    title,
    description,
    icon,
    iconClassName,
    disabled,
}: OptionButtonProps) {
    return (
        <button
            disabled={disabled}
            type="button"
            onClick={() => onClick()}
            className={getOptionButtonClassName(isActive, disabled)}
        >
            <div className="flex ">
                {icon && (
                    <div
                        className={classNames(
                            "flex !w-14 items-center justify-center py-2",
                            iconClassName
                        )}
                    >
                        <FontAwesomeIcon icon={icon} />
                    </div>
                )}

                <div className="w-full pl-2">
                    <h4 className="font-semibold uppercase">{title}</h4>
                    <p className="text-sm">{description}</p>
                </div>
            </div>
        </button>
    );
}
