import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { NavIcons } from "~/components/layout/NavIcons";

export function FirewallDashboard() {
    return (
        <EmptyResource
            className="mt-4 flex h-60 items-center justify-center border-none"
            icon={NavIcons["firewall"]}
            title={`Load Balancer Firewall Telemetry Coming Soon`}
        />
    );
}
