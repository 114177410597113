import {
    ChangeHubPlanDialog,
    HubSelectDialog,
} from "~/components/dialogs/hubs";
import { ContainerDialog } from "~/components/dialogs/containers/container/ContainerDialog";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DialogParametersRecord } from "~/components/dialogs/helpers";
import { ContainerCreateDialog } from "~/components/dialogs/containers/create";
import { ImageSourceCreateDialog } from "~/components/dialogs/images/imageSource";
import { SdnNetworkCreateDialog } from "~/components/dialogs/networks/SdnNetworkCreateDialog";
import { CreateStackDialog } from "~/components/dialogs/stacks/CreateStackDialog";
import { EnvironmentCreateDialog } from "~/components/dialogs/environments/create/EnvironmentCreateDialog";
import { AddServerDialog } from "~/components/dialogs/infrastructure/addServer/AddServerDialog";
import { ViewImageDialog } from "~/components/dialogs/images/viewImage/ViewImageDialog";
import { ApiKeyCreateDialog } from "~/components/dialogs/settings/ApiKeyCreateDialog";
import { BillingMethodCreateDialog } from "~/components/dialogs/settings/BillingMethodCreateDialog";
import { TriggerKeyDialog } from "./pipelines/triggerKeys/TriggerKeyDialog";
import { DnsRecordDialog } from "./dns/record-create/DnsRecordDialog";
import { DnsZoneDialog } from "./dns/zone-create/DnsZoneDialog";
import { ZoneFileWizardDialog } from "./dns/ZoneFileWizardDialog";
import { InviteMemberDialog } from "./settings/InviteMemberDialog";
import { StackBuildDialog } from "./stackBuilds/StackBuildDialog";
import { AccountDialog } from "./account/AccountDialog";
import { HubCreateDialog } from "./hubs/HubCreateDialog";
import { AccountInfoDialog } from "./account/AccountInfoDialog";
import { PipelineCreateDialog } from "./pipelines/PipelineCreateDialog";
import { ManageLoadBalancerDialog } from "./environments/services/loadbalancer/ManageLoadBalancerDialog";
import { ManageDiscoveryDialog } from "./environments/services/discovery/ManageDiscoveryDialog";
import { ManageVpnDialog } from "./environments/services/vpn/ManageVpnDialog";
import { EditDeployableInfraDialog } from "./infrastructure/autoScale/EditDeployableInfraDialog";
import { AddDeployableInfraDialog } from "./infrastructure/autoScale/AddDeployableInfraDialog";
import { AutoScalingGroupCreateDialog } from "./infrastructure/autoScale/AutoScalingGroupCreateDialog";
import { PipelineRunDialog } from "./pipelines/pipelineRun/PipelineRunDialog";
import { ManageSchedulerDialog } from "./environments/services/scheduler/ManageSchedulerDialog";
import { CreateIntegrationDialog } from "./hubs/addIntegration/CreateIntegrationDialog";
import { RoleCreateDialog } from "./hubs/RoleCreate.tsx";
import { Capability } from "~/services/cycle";
import { ScopedVariableFormDialog } from "./environments/scopedVariables/ScopedVariableFormDialog";
import { dialogFallbackWrapper } from "../common/errors/FallbackWrappers";
import { CycleErrorBoundary } from "../common/errors";
import { CreateClusterDialog } from "./infrastructure/addCluster/CreateClusterDialog";
import { ExportEnvironmentDialog } from "./environments/export/ExportEnvironmentDialog";
import { EnvironmentMonitoringDialog } from "./environments/monitoring/EnvironmentMonitoringDialog";
import { TlsUserCertUploadDialog } from "./dns/tlsUserCertUpload/TlsUserCertUploadDialog";

import { VmCreateDialog } from "./vms/create";
import { VmDialog } from "./vms/vm";
import { SshKeyCreateDialog } from "./vms/ssh";
import { ManageGatewayDialog } from "./environments/services/gateway/ManageGatewayDialog";

import { VirtualProviderViewIsoDialog } from "./hubs/virtualProviders/virtualProviderViewIso/VirtualProviderViewIsoDialog";
import { VirtualProviderCreateIsoDialog } from "./hubs/virtualProviders/virtualProviderGenerateIso/VirtualProviderGenerateIsoDialog";

/**
 * Renders all the root level modals, so that we can use the `?modal={whatever}` search param
 * to open them up regardless of where the client is routed.
 *
 * Should be rendered in the top level menu in protected routes.
 */
export function Dialogs() {
    const [searchParams] = useSearchParams();
    const dialog = searchParams.get("dialog");

    switch (dialog as keyof DialogParametersRecord) {
        case "hub-create":
            return (
                <DialogErrorBoundary>
                    <HubCreateDialog />
                </DialogErrorBoundary>
            );

        case "container":
            return (
                <DialogErrorBoundary
                    resourceName="Containers"
                    capability={"containers-view"}
                >
                    <ContainerDialog />
                </DialogErrorBoundary>
            );
        case "environment-create":
            return (
                <DialogErrorBoundary
                    resourceName="Environments"
                    capability={"environments-manage"}
                >
                    <EnvironmentCreateDialog />
                </DialogErrorBoundary>
            );

        case "environment-monitoring":
            return (
                <DialogErrorBoundary
                    resourceName="Environments"
                    capability={"environments-manage"}
                >
                    <EnvironmentMonitoringDialog />
                </DialogErrorBoundary>
            );
        case "environment-lb-manage":
            return (
                <DialogErrorBoundary resourceName="Environment Services">
                    <ManageLoadBalancerDialog />
                </DialogErrorBoundary>
            );

        case "environment-discovery-manage":
            return (
                <DialogErrorBoundary resourceName="Environment Services">
                    <ManageDiscoveryDialog />
                </DialogErrorBoundary>
            );
        case "environment-gateway-manage":
            if (import.meta.env.VITE_FEAT_VIRTUAL_MACHINES === "false") {
                return null;
            }
            return (
                <DialogErrorBoundary resourceName="Environment Services">
                    <ManageGatewayDialog />
                </DialogErrorBoundary>
            );
        case "environment-scheduler-manage":
            return (
                <DialogErrorBoundary resourceName="Environment Services">
                    <ManageSchedulerDialog />
                </DialogErrorBoundary>
            );
        case "environment-vpn-manage":
            return (
                <DialogErrorBoundary resourceName="VPN">
                    <ManageVpnDialog />
                </DialogErrorBoundary>
            );
        case "server-create":
            return (
                <DialogErrorBoundary
                    capability={"servers-provision"}
                    resourceName="Servers"
                >
                    <AddServerDialog />
                </DialogErrorBoundary>
            );

        case "cluster-create":
            return (
                <DialogErrorBoundary
                    capability={"clusters-manage"}
                    resourceName="Clusters"
                >
                    <CreateClusterDialog />
                </DialogErrorBoundary>
            );
        case "container-create":
            return (
                <DialogErrorBoundary
                    capability={"containers-deploy"}
                    resourceName="Containers"
                >
                    <ContainerCreateDialog />
                </DialogErrorBoundary>
            );
        case "hub-select":
            return (
                <DialogErrorBoundary>
                    <HubSelectDialog />
                </DialogErrorBoundary>
            );
        case "image-source-create":
            return (
                <DialogErrorBoundary
                    capability={"images-sources-manage"}
                    resourceName="Image Sources"
                >
                    <ImageSourceCreateDialog />
                </DialogErrorBoundary>
            );
        case "api-key-create":
            return (
                <DialogErrorBoundary
                    capability={"api-keys-manage"}
                    resourceName="API Keys"
                >
                    <ApiKeyCreateDialog />
                </DialogErrorBoundary>
            );
        case "billing-method-create":
            return (
                <DialogErrorBoundary
                    capability={"billing-methods-manage"}
                    resourceName="Billing Methods"
                >
                    <BillingMethodCreateDialog />
                </DialogErrorBoundary>
            );
        case "sdn-network-create":
            return (
                <DialogErrorBoundary
                    capability={"sdn-networks-manage"}
                    resourceName="Networks"
                >
                    <SdnNetworkCreateDialog />
                </DialogErrorBoundary>
            );
        case "stack-create":
            return (
                <DialogErrorBoundary
                    capability={"stacks-manage"}
                    resourceName="Stacks"
                >
                    <CreateStackDialog />
                </DialogErrorBoundary>
            );
        case "stack-build":
            return (
                <DialogErrorBoundary capability={"stacks-view"}>
                    <StackBuildDialog />
                </DialogErrorBoundary>
            );
        case "image":
            return (
                <DialogErrorBoundary
                    resourceName="Images"
                    capability={"images-view"}
                >
                    <ViewImageDialog />
                </DialogErrorBoundary>
            );
        case "pipeline-trigger-key":
            return (
                <DialogErrorBoundary
                    resourceName="Pipelines"
                    capability={"pipelines-manage"}
                >
                    <TriggerKeyDialog />
                </DialogErrorBoundary>
            );
        case "account":
            return (
                <DialogErrorBoundary>
                    <AccountDialog />
                </DialogErrorBoundary>
            );
        case "dns-record":
            return (
                <DialogErrorBoundary
                    resourceName="Dns Record"
                    capability={"dns-view"}
                >
                    <DnsRecordDialog />
                </DialogErrorBoundary>
            );
        case "dns-zone-create":
            return (
                <DialogErrorBoundary
                    resourceName="Dns Zone"
                    capability={"dns-manage"}
                >
                    <DnsZoneDialog />
                </DialogErrorBoundary>
            );
        case "dns-zone-import":
            return (
                <DialogErrorBoundary
                    resourceName="Dns Zone"
                    capability={"dns-manage"}
                >
                    <ZoneFileWizardDialog />
                </DialogErrorBoundary>
            );
        case "tls-user-cert-upload":
            return (
                <DialogErrorBoundary
                    resourceName="TLS Certificate"
                    capability={"dns-manage"}
                >
                    <TlsUserCertUploadDialog />
                </DialogErrorBoundary>
            );
        case "hub-member-invite":
            return (
                <DialogErrorBoundary
                    resourceName="Invites"
                    capability={["hubs-invites-send", "hubs-roles-view"]}
                >
                    <InviteMemberDialog />
                </DialogErrorBoundary>
            );
        case "account-info":
            return (
                <DialogErrorBoundary>
                    <AccountInfoDialog />
                </DialogErrorBoundary>
            );
        case "pipeline-create":
            return (
                <DialogErrorBoundary
                    capability={"pipelines-manage"}
                    resourceName="Pipelines"
                >
                    <PipelineCreateDialog />
                </DialogErrorBoundary>
            );
        case "pipeline-run":
            return (
                <DialogErrorBoundary resourceName="Pipelines">
                    <PipelineRunDialog />
                </DialogErrorBoundary>
            );
        case "autoscaling-group-create":
            return (
                <DialogErrorBoundary
                    capability={"autoscale-groups-manage"}
                    resourceName="Autoscale Groups"
                >
                    <AutoScalingGroupCreateDialog />
                </DialogErrorBoundary>
            );
        case "autoscaling-group-add-infra":
            return (
                <DialogErrorBoundary
                    capability={"autoscale-groups-manage"}
                    resourceName="Autoscale Groups"
                >
                    <AddDeployableInfraDialog />
                </DialogErrorBoundary>
            );
        case "autoscaling-group-edit-infra":
            return (
                <DialogErrorBoundary
                    capability={"autoscale-groups-manage"}
                    resourceName="Autoscale Groups"
                >
                    <EditDeployableInfraDialog />
                </DialogErrorBoundary>
            );
        case "change-hub-plan":
            return (
                <DialogErrorBoundary
                    capability={"billing-services-manage"}
                    resourceName="Billing Services"
                >
                    <ChangeHubPlanDialog />
                </DialogErrorBoundary>
            );
        case "integration-create":
            return (
                <DialogErrorBoundary
                    capability={"hubs-integrations-manage"}
                    resourceName="Hub Integrations"
                >
                    <CreateIntegrationDialog />
                </DialogErrorBoundary>
            );

        case "virtual-provider-create-iso":
            return (
                <DialogErrorBoundary
                    capability={"servers-manage"}
                    resourceName="Virtual Provider Integration"
                >
                    <VirtualProviderCreateIsoDialog />
                </DialogErrorBoundary>
            );

        case "virtual-provider-iso":
            return (
                <DialogErrorBoundary
                    capability={"servers-manage"}
                    resourceName="Virtual Provider Integration"
                >
                    <VirtualProviderViewIsoDialog />
                </DialogErrorBoundary>
            );
        case "role-create":
            return (
                <DialogErrorBoundary
                    capability={"hubs-roles-manage"}
                    resourceName="Hub Roles"
                >
                    <RoleCreateDialog />
                </DialogErrorBoundary>
            );
        case "environment-scopedvar-edit":
            return (
                <DialogErrorBoundary
                    capability={"environments-scopedvariables-manage"}
                    resourceName="Scoped Variables"
                >
                    <ScopedVariableFormDialog />
                </DialogErrorBoundary>
            );
        case "environment-export":
            return (
                <DialogErrorBoundary
                    capability={"environments-manage"}
                    resourceName="Environment"
                >
                    <ExportEnvironmentDialog />
                </DialogErrorBoundary>
            );

        case "virtual-machine-create":
            if (import.meta.env.VITE_FEAT_VIRTUAL_MACHINES === "false") {
                return null;
            }
            return (
                <DialogErrorBoundary
                    capability={"virtual-machines-manage"}
                    resourceName="Virtual Machine"
                >
                    <VmCreateDialog />
                </DialogErrorBoundary>
            );
        case "virtual-machine":
            if (import.meta.env.VITE_FEAT_VIRTUAL_MACHINES === "false") {
                return null;
            }
            return (
                <DialogErrorBoundary
                    capability={"virtual-machines-view"}
                    resourceName="Virtual Machine"
                >
                    <VmDialog />
                </DialogErrorBoundary>
            );
        case "virtual-machines-ssh-key-create":
            if (import.meta.env.VITE_FEAT_VIRTUAL_MACHINES === "false") {
                return null;
            }
            return (
                <DialogErrorBoundary
                    capability={"virtual-machines-ssh-keys-manage"}
                    resourceName="Virtual Machine SSH Key"
                >
                    <SshKeyCreateDialog />
                </DialogErrorBoundary>
            );
        default:
            return null;
    }
}

type CycleFallbackProps = {
    capability?: Capability | Capability[];
    children: React.ReactNode;
    resourceName?: string;
};

export function DialogErrorBoundary({
    capability = [],
    children,
    resourceName = "resource",
}: CycleFallbackProps) {
    const nav = useNavigate();

    return (
        <CycleErrorBoundary
            capability={capability}
            resourceName={resourceName}
            fallbackWrapper={dialogFallbackWrapper(nav)}
        >
            {children}
        </CycleErrorBoundary>
    );
}
