import {
    faArrowRotateBack,
    faHouseLeave,
    IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { faHouseLeave as faHouseLeaveDuo } from "@fortawesome/pro-duotone-svg-icons";
import { Dialog } from "@headlessui/react";
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import {
    DialogContent,
    DialogHeader,
    DialogBody,
    DialogColumn,
    DialogFooter,
} from "@cycleplatform/ui/components/dialog/components";
import { useAppDispatch, useAppSelector } from "~/hooks";
import {
    clearError,
    getCurrentForm,
    initializeForm,
    terminateForm,
} from "~/modules/forms/slice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { clearDialogParams } from "../helpers";
import { MultiStepDialogContext } from "./context";
import { StepProgress } from "./components/StepProgress";
import { Button } from "@cycleplatform/ui/components/buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type MultiStepStyledFormDialogProps = {
    isOpen?: boolean;
    title?: React.ReactNode;
    className?: string;
    icon?: IconDefinition;
    children: React.ReactNode;
    onClose?: () => void;
    isCloseable?: boolean;
    formKey: string;
};

export function MultiStepFormDialog({
    isOpen,
    className,
    icon,
    children,
    title,
    isCloseable = true,
    onClose: customOnClose,
    formKey,
}: MultiStepStyledFormDialogProps) {
    const dispatch = useAppDispatch();
    const nav = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const [stepNames, setStepNames] = useState<string[]>([]);

    const [attemptClose, setAttemptClose] = useState(false);

    const currentForm = useAppSelector((state) =>
        getCurrentForm(state, formKey)
    );

    const currentIdx = parseInt(searchParams.get("dialog-step") || "0");

    const handleNext = () => {
        if (currentForm?.error && currentForm?.error.step === currentIdx) {
            dispatch(clearError({ key: formKey }));
        }

        const sp = new URLSearchParams(searchParams);
        sp.set("dialog-step", (currentIdx + 1).toString());
        setSearchParams(sp);
    };

    const handleBack = () => {
        const sp = new URLSearchParams(searchParams);
        sp.set("dialog-step", (currentIdx - 1).toString());
        setSearchParams(sp);
    };

    const navigateToStep = (idx: number) => {
        const sp = new URLSearchParams(searchParams);
        sp.set("dialog-step", idx.toString());
        setSearchParams(sp);
    };

    const onClose = () => {
        if (!isCloseable) {
            return;
        }
        if (customOnClose) {
            customOnClose();
            dispatch(terminateForm({ key: formKey }));
            return;
        }
        dispatch(terminateForm({ key: formKey }));
        nav(clearDialogParams());
    };

    useEffect(() => {
        if (!currentForm) {
            dispatch(initializeForm({ key: formKey }));
        }
    }, []);

    return (
        <MultiStepDialogContext.Provider
            value={{
                onClose,
                currentIdx,
                handleNext,
                handleBack,
                navigateToStep,
                stepNames,
                setStepNames,
                formKey,
            }}
        >
            <Dialog
                open={isOpen}
                onClose={() => {
                    if (!isCloseable) {
                        return;
                    }
                    setAttemptClose(true);
                }}
                className={classNames("relative z-50 ")}
            >
                <DialogBody
                    isCloseable={isCloseable}
                    onClose={onClose}
                    className={classNames("max-w-[85rem]", className)}
                >
                    <DialogHeader icon={icon} title={title}>
                        <StepProgress />
                    </DialogHeader>

                    <DialogContent
                        className={classNames(attemptClose && "hidden")}
                    >
                        {children}
                    </DialogContent>

                    {attemptClose ? (
                        <DialogContent>
                            <ConfirmClose setAttemptClose={setAttemptClose} />
                        </DialogContent>
                    ) : null}
                </DialogBody>
            </Dialog>
        </MultiStepDialogContext.Provider>
    );
}

function ConfirmClose({
    setAttemptClose,
}: {
    setAttemptClose: (close: boolean) => void;
}) {
    const { onClose } = useContext(MultiStepDialogContext);

    return (
        <DialogColumn className="flex items-center justify-center ">
            <p className="text-center text-xl">
                Are you sure you want to exit?
            </p>
            <div className="text-center">
                <FontAwesomeIcon
                    icon={faHouseLeaveDuo}
                    className="text-cycle-blue !h-[20rem] !w-[20rem]"
                />
            </div>

            <p className="text-center">
                All configuration will be lost when the modal closes.
            </p>

            <DialogFooter className="justify-between">
                <Button
                    onClick={() => {
                        setAttemptClose(false);
                    }}
                    icon={faArrowRotateBack}
                >
                    Return to Setup
                </Button>
                <Button onClick={onClose} flavor="discard" icon={faHouseLeave}>
                    Exit Setup
                </Button>
            </DialogFooter>
        </DialogColumn>
    );
}
