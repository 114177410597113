import { useEffect, useState } from "react";
import { ResourceWithId, ResourceWithIdOrIdentifier } from "~/modules/resource";
import {
    MultiSelectInput,
    SelectInput,
} from "@cycleplatform/ui/components/forms/select";
import { resourcesToResourceByHybridIdentifier } from "../resources/util";

type ResourceComboBoxProps<
    T extends ResourceWithIdOrIdentifier,
    MULTI extends boolean = false
> = {
    resources: T[];
    value: MULTI extends true ? Array<T["id"]> : T["id"] | undefined | null;
    onChange: MULTI extends true
        ? (ids: string[], resources: T[]) => void
        : (id: string | undefined | null, resource: T | undefined) => void;
    formatDisplayValue: (resource: T | undefined | null) => string;
    formatOption: (resource: T, disabled?: boolean) => React.ReactNode;
    groupOptions?: (options: T[]) => Record<string, T[]>;
    fetchResources?: (search: string) => Promise<T[]>;

    /** Additional indexes to search against */
    filterFields: Array<keyof T>;
    name?: string;
    isNullable?: boolean;
    disabled?: boolean;
    multiple?: MULTI;
    /** An override to show the loading spinner if fetching data manually. */
    isLoading?: boolean;
    placeholder?: string;
    onSearchQueryChanged?: (v: string) => void;

    /** If true, the user can add new values to options[] */
    isCreatable?: boolean;
    /** A function that takes the string value from the input, and converts it into the new option */
    formatCreatableValue?: (v: string) => T;
    /** If provided, the placeholder that will be rendered on the create option */
    creatablePlaceholder?: (v: string) => string;
    /** If provided, will validate the option, and disable if not valid. */
    isOptionValid?: (v: T) => boolean;
    /** If provided, will validate the intended create value on keypress, and disable if not valid. */
    isCreateValueValid?: (v: string) => boolean;
    className?: string;
    emptyMessage?: string;
};

/**
 * A generic search + select component for cycle resources with an ID and a state.
 */
export function ResourceComboBox<
    T extends ResourceWithId,
    MULTI extends boolean = false
>({ multiple, ...props }: ResourceComboBoxProps<T, MULTI>) {
    // Can't just use props.resources, because there may be an
    // async list of resources if fetchResources is provided.
    const [resourceById, setResourceById] = useState<Record<string, T>>(
        resourcesToResourceByHybridIdentifier(props.resources)
    );

    useEffect(() => {
        setResourceById(resourcesToResourceByHybridIdentifier(props.resources));
    }, [props.resources]);

    if (multiple) {
        const { value, onChange, ...otherProps } =
            props as ResourceComboBoxProps<T, true>;
        return (
            <MultiSelectInput
                {...otherProps}
                fetchOptions={props.fetchResources}
                // Preserve resource select api using ID, while multi select uses T
                value={value?.map((id) => {
                    return resourceById[id];
                })}
                onChange={(newValues) => {
                    const resourceMap = Object.values(
                        resourcesToResourceByHybridIdentifier(newValues || [])
                    ).reduce((acc, resource) => {
                        acc[resource.id] = resource;
                        return acc;
                    }, {} as Record<string, T>);

                    setResourceById(resourceMap);
                    onChange(
                        Object.keys(resourceMap),
                        Object.values(resourceMap)
                    );
                }}
                options={props.resources}
            />
        );
    }

    const { value, onChange, ...otherProps } = props as ResourceComboBoxProps<
        T,
        false
    >;

    return (
        <SelectInput
            {...otherProps}
            options={props.resources}
            value={value ? resourceById[value] : undefined}
            onChange={(newValue) => {
                if (!newValue) {
                    onChange(
                        otherProps.isNullable ? null : undefined,
                        undefined
                    );
                    return;
                }
                setResourceById({ ...resourceById, [newValue.id]: newValue });
                onChange(newValue.id, newValue);
            }}
        />
    );
}
