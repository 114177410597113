import {
    ComponentIncludes,
    Container,
    Instance,
    LogLine,
    LookupComponentsApiArg,
    useGetInstancesQuery,
    useLookupComponentsQuery,
} from "~/services/cycle";
import { PanelContent } from "@cycleplatform/ui/components/panels";
import { useEffect, useMemo, useState } from "react";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import {
    DateTimeFormats,
    formatDateString,
    formatIdAsName,
} from "@cycleplatform/core/util";
import { Link } from "react-router-dom";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { removeStylingMetadata } from "@cycleplatform/core/modules/logs";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { NavIcons } from "~/components/layout/NavIcons";
import { resourcesToResourceById } from "~/components/common/resources/util";

export function LogsTable({
    container,
    logs,
}: {
    container?: Container;
    logs?: LogLine[];
}) {
    return (
        <PanelContent stretch>
            <StyledDataTable>
                <StyledTableHead>
                    <StyledHeaderCell className="w-1/6 min-w-[16rem]">
                        Time
                    </StyledHeaderCell>
                    <StyledHeaderCell className="w-1/6 min-w-[16rem]">
                        Instance
                    </StyledHeaderCell>
                    <StyledHeaderCell className="w-2/3 px-8">
                        Message
                    </StyledHeaderCell>
                </StyledTableHead>
                <tbody>
                    <TableBody container={container} logs={logs} />
                </tbody>
            </StyledDataTable>
        </PanelContent>
    );
}

function TableBody({
    container,
    logs,
}: {
    container?: Container;
    logs?: LogLine[];
}) {
    // Instance components is a keyed object that can be isued for instance lookups in table
    // This is as a local cache and will be updated by both the instances call and the components call
    const [instanceComponents, setInstanceComponents] =
        useState<ComponentIncludes>({});

    // Ignore error since we'd rather have missing info than hit err boundary
    const { data: instances } = useGetInstancesQuery(
        {
            containerId: container?.id || "",
            include: ["servers"],
            page: {
                size: 100,
                number: 1,
            },
        },
        { skip: !container?.id }
    );

    // Determine if an instance ID is present in the logs but absent from the instanceComponents cache
    const missingInstances: LookupComponentsApiArg["body"]["components"] =
        useMemo(
            () =>
                logs
                    ?.map((l) => l.instance_id || "")
                    ?.filter(
                        (id) => !Object.keys(instanceComponents).includes(id)
                    )
                    ?.map((i) => ({ id: i, type: "container.instance" })) || [],
            [logs, instanceComponents]
        );

    // Ignore error since we'd rather have missing info than hit err boundary
    const { data: components } = useLookupComponentsQuery(
        {
            body: {
                components: missingInstances || [],
            },
        },
        { skip: !missingInstances || missingInstances.length === 0 }
    );

    // Use the instances array to prepopulate the instanceComponents cache
    // If there are missing instances, it will trigger the components api call for those IDS
    // When new components show up, add them to the instance components cache
    useEffect(() => {
        const instanceMap = resourcesToResourceById(instances?.data || []);

        setInstanceComponents({
            ...instanceComponents,
            ...instanceMap,
            ...components?.data,
        });
    }, [instances?.data, components?.data]);

    if (!logs) {
        return (
            <StyledTableRow>
                <StyledCell colSpan={4}>
                    <EmptyResource
                        title={"Search logs"}
                        icon={NavIcons["logs"]}
                        className="border-none"
                    >
                        Enter query parameters above to see applicable logs.
                    </EmptyResource>
                </StyledCell>
            </StyledTableRow>
        );
    }

    if (logs.length === 0) {
        return (
            <StyledTableRow>
                <StyledCell colSpan={4}>
                    <EmptyResource
                        title={"No logs"}
                        icon={NavIcons["logs"]}
                        className="border-none"
                    >
                        No logs matching your input parameters
                    </EmptyResource>
                </StyledCell>
            </StyledTableRow>
        );
    }
    return logs?.map((l) => {
        const i = instanceComponents[l.instance_id || ""] as
            | Instance
            | undefined;

        return (
            <StyledTableRow key={l.time}>
                {/* Remove the fractional time from the timestamp - improves readability */}
                <StyledCell>
                    {formatDateString(
                        l.time.split(".")[0] + "Z",
                        DateTimeFormats["standard"]
                    )}
                </StyledCell>
                <StyledCell>
                    <Link
                        to={generateDialogLink("container", {
                            "dialog-container-id": container?.id || "",
                            "dialog-instance-id": l.instance_id || "",
                            "dialog-tab": "instances",
                        })}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {i?.hostname} {formatIdAsName(l.instance_id || "")} (
                        {i?.provider.vendor} {i?.provider.location})
                    </Link>
                </StyledCell>
                <StyledCell className="break-all px-8">
                    {removeStylingMetadata(l.message)}
                </StyledCell>
            </StyledTableRow>
        );
    });
}
