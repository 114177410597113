import { PanelTitle } from "@cycleplatform/ui/components/panels";
import { useAppSelector } from "~/hooks";
import { selectActiveHub } from "~/modules/hubs/slice";
import { useGetBillingTiersQuery } from "~/services/cycle";
import { ServerUsageChart } from "./charts/serverUsageChart/ServerUsageChart";
import { CycleErrorBoundary } from "~/components/common/errors";
import { MembersUsageChart } from "./charts/membersUsageChart/MembersUsageChart";

export function HubUsageContent() {
    const hub = useAppSelector(selectActiveHub);
    const currentTierId = hub?.billing?.plans?.tier_id;

    const { data: tiers, error: tierError } = useGetBillingTiersQuery();
    const tier = tiers?.data?.find((t) => t.id === currentTierId);
    if (tierError) {
        throw tierError;
    }
    return (
        <>
            <PanelTitle title="servers" />
            <div className="mb-8 h-60 w-full">
                <CycleErrorBoundary>
                    <ServerUsageChart
                        allowedServers={tier ? tier.servers.included : 0}
                    />
                </CycleErrorBoundary>
            </div>

            <PanelTitle title="members" />
            <div className="!text-error h-60 w-full">
                <CycleErrorBoundary>
                    <MembersUsageChart
                        allowedMembers={tier ? tier.members.included : 0}
                    />
                </CycleErrorBoundary>
            </div>
        </>
    );
}
