import classNames from "classnames";
import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";

type RhfFormProviderProps<T extends FieldValues> = UseFormReturn<T> & {
    id?: string;
    children: React.ReactNode;
    onSubmit?: (e: React.FormEvent) => void;
    className?: string;
};

export function RhfFormProvider<T extends FieldValues>({
    // If no submit provided, prevent default
    // This will prevent window reload if you press "enter" in a text input that has a press and hold submit
    // We DO NOT want to allow people to bypass the press and hold by pressing enter
    onSubmit = (e) => e.preventDefault(),
    children,
    className,
    id,
    ...props
}: RhfFormProviderProps<T>) {
    return (
        <FormProvider {...props}>
            <form
                id={id}
                className={classNames("flex w-full flex-col ", className)}
                onSubmit={onSubmit}
            >
                {children}
            </form>
        </FormProvider>
    );
}
