import {
    Button,
    PushAndHoldButton,
} from "@cycleplatform/ui/components/buttons";
import { RhfGlobalFormError } from "@cycleplatform/ui/components/forms";
import {
    SkeletonHeader,
    SkeletonText,
} from "@cycleplatform/ui/components/loaders/skeleton";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import { PanelContent } from "@cycleplatform/ui/components/panels";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import {
    Integration,
    ProviderLocation,
    useGetProviderServersQuery,
} from "~/services/cycle";
import { CategoryFilter } from "../../ui/CategoryFilter";
import { HubUsageChart } from "./components/HubUsageChart";
import { OrderSummary } from "./components/OrderSummary";
import { ServerOptionTile } from "./components/ServerOptionTile";
import { PriceBreakdown } from "./components/PriceBreakdown";
import { getServerCategories } from "@cycleplatform/core/modules/infrastructure/servers";
import { CreateServerSchema } from "../../form";
import { useSetServerParams } from "../../helpers/hooks";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { NavIcons } from "~/components/layout/NavIcons";
import { getProviderName } from "@cycleplatform/core/modules/infrastructure/providers";
import {
    DialogColumn,
    DialogFooter,
} from "@cycleplatform/ui/components/dialog/components";

type SelectServersStepProps = {
    location: ProviderLocation;
    integration: Integration;
    onSubmit: (data: CreateServerSchema) => void;
};

export function SelectServersStep({
    location,
    integration,
    onSubmit,
}: SelectServersStepProps) {
    const setServerParams = useSetServerParams();
    const [category, setCategory] = useState<string | null>("all");

    const {
        watch,
        handleSubmit,
        setValue,
        formState: { isSubmitting },
    } = useFormContext<CreateServerSchema>();

    const selectedServers = watch("servers");

    const {
        data: providerServers,
        isLoading: isServersLoading,
        error,
    } = useGetProviderServersQuery({
        providerVendor: integration.id,
        filter: {
            location_ids: location.id || "",
        },
        sort: ["price.mills"],
    });

    const categories = getServerCategories(providerServers?.data || []);

    const filteredProviderServers = providerServers?.data?.filter((s) =>
        category === "all" ? true : s.provider.category === category
    );

    if (error) {
        throw error;
    }

    return (
        <>
            <div className="flex h-full">
                <DialogColumn className="w-2/3">
                    <CategoryFilter
                        active={category}
                        setCategory={setCategory}
                        categories={categories}
                        loading={!providerServers}
                    />
                    <ul className="flex h-full w-full flex-col gap-2 ">
                        {!isServersLoading ? (
                            providerServers?.data.length ? (
                                filteredProviderServers?.map((s, idx) => (
                                    <ServerOptionTile
                                        key={idx}
                                        providerServer={s}
                                        providerName={getProviderName(
                                            integration
                                        )}
                                    />
                                ))
                            ) : (
                                <EmptyResource
                                    icon={NavIcons["infrastructure"]}
                                    title="No servers available at this location"
                                />
                            )
                        ) : (
                            <>
                                {Array.from({
                                    length: 5,
                                }).map((_, idx) => (
                                    <ServerOptionTileSkeleton key={idx} />
                                ))}
                            </>
                        )}
                    </ul>
                </DialogColumn>
                <DialogColumn className="w-1/3">
                    <HubUsageChart />
                    <div className="h-full w-full">
                        <OrderSummary
                            location={location}
                            integration={integration}
                            providerServers={providerServers?.data}
                        />
                        <PriceBreakdown
                            providerServers={providerServers?.data}
                            integration={integration}
                        />
                    </div>
                </DialogColumn>
            </div>

            <DialogFooter className="!justify-between">
                <Button
                    type="button"
                    onClick={() => {
                        setServerParams({ step: "0" });
                        setValue("servers", {});
                    }}
                >
                    Back
                </Button>
                <div>
                    <RhfGlobalFormError />
                </div>
                <PushAndHoldButton
                    type="button"
                    flavor="primary"
                    icon={faPlus}
                    isLoading={isSubmitting}
                    onClick={handleSubmit(onSubmit)}
                    disabled={
                        selectedServers &&
                        Object.keys(selectedServers).length === 0
                    }
                >
                    Deploy
                </PushAndHoldButton>
            </DialogFooter>
        </>
    );
}

function ServerOptionTileSkeleton() {
    return (
        <li>
            <PanelContent>
                <div className="flex items-center justify-between gap-8">
                    <div className={classNames("h-20 w-20", skeletonStyles)} />
                    <SkeletonHeader size="lg" />
                    <div className="flex w-full flex-1 items-center gap-4 ">
                        <div>
                            <SkeletonText size="md" />
                            <SkeletonText size="sm" />
                            <SkeletonText size="md" />
                            <SkeletonText size="sm" />
                        </div>
                    </div>
                </div>
            </PanelContent>
        </li>
    );
}
