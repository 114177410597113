import { useNavigate } from "react-router-dom";
import { clearDialogParams, getAllDialogSearchParams } from "../../helpers";
import {
    useGetContainerQuery,
    useGetVirtualMachineQuery,
} from "~/services/cycle";
import { VirtualMachineDialogContex } from "./context";
import {
    ResourceDialogTab,
    StyledResourceDialog,
} from "@cycleplatform/ui/components/dialog";
import classNames from "classnames";
import { SkeletonHeader } from "@cycleplatform/ui/components/loaders/skeleton";
import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import { useVerifyAccess } from "~/modules/access";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { ReadOnlyBadge } from "@cycleplatform/ui/components/badges";
import { VmStateControls } from "~/components/vms/controls/VmStateControls";
import { NavIcons } from "~/components/layout/NavIcons";
import { CycleErrorBoundary } from "~/components/common/errors";
import { VmDashboardTab } from "./tabs/dashboard/VmDashboardTab";
import { VmSettingsTab } from "./tabs/settings";
import {
    faChartLineUpDown,
    faTowerControl,
} from "@fortawesome/pro-duotone-svg-icons";
import { VmConfigTab } from "./tabs/config";
import { VmTelemetryTab } from "./tabs/telemetry";
import { ResourceLockedBadge } from "~/components/common/resources/ResourceLockedBadge";
import { ResourceDeprecatedBadge } from "~/components/common/resources/ResourceDeprecatedBadge";
import { SerialOverSshPanel } from "./SerialOverSshPanel";
import { useEffect, useState } from "react";
import { VmHypervisorTab } from "./tabs/hypervisor/VmHypervisorTab";

type VmDialogProps = {
    className?: string;
};

export function VmDialog({ className }: VmDialogProps) {
    const params = getAllDialogSearchParams<"virtual-machine">();
    const vmId = params["dialog-id"];
    const nav = useNavigate();

    const vm = useHandlePollForVmPrivIps(vmId);

    const { currentData: container, error: containerError } =
        useGetContainerQuery(
            {
                containerId: vm?.data.container_id || "",
                meta: ["instances_count"],
            },
            { skip: !vm?.data.container_id }
        );

    // initially use the container fetched in includes, otherwise use the 'full' one
    // so we get the meta when loaded. will be faster for results this way.
    const ctxContainer =
        container?.data ||
        vm?.includes?.containers?.[vm?.data?.container_id || ""];
    const env = vm?.includes?.environments?.[vm?.data?.environment?.id || ""];

    const modifyRestrictedError = useVerifyAccess(
        env,
        modifyAccessFn("environments-manage")
    );

    if (containerError) {
        throw containerError;
    }

    return (
        <VirtualMachineDialogContex.Provider
            value={{
                environment: env,
                vm: vm?.data,
                container: ctxContainer,
            }}
        >
            <StyledResourceDialog
                open={!!vmId}
                onClose={() => nav(clearDialogParams())}
                className={classNames(className)}
                breadcrumb={[
                    {
                        label: "Environments",
                        link: "environments",
                    },
                    {
                        label: env ? env.name : "",
                        link: `environments/${env?.id}`,
                    },
                    {
                        label: "Virtual Machines",
                        link: `environments/${env?.id}/vms`,
                    },
                    {
                        label: vm?.data?.name || "",
                    },
                ]}
                header={
                    <div className="flex w-full items-center justify-between">
                        <div className="flex h-full items-center gap-4 text-base ">
                            {vm?.data?.name ? (
                                <h1 className="text-2xl uppercase">
                                    {vm?.data?.name}
                                </h1>
                            ) : (
                                <SkeletonHeader size="lg" />
                            )}

                            {vm?.data && (
                                <>
                                    <ResourceStateBadge
                                        className="!bg-cycle-gray !h-8"
                                        state={vm.data.state}
                                    />

                                    {modifyRestrictedError && (
                                        <ReadOnlyBadge
                                            className="!bg-cycle-gray !h-8"
                                            state={vm.data.state}
                                        />
                                    )}

                                    <ResourceLockedBadge
                                        resource={vm.data}
                                        resourceName="virtual machine"
                                    />
                                    <ResourceDeprecatedBadge
                                        resource={vm.data}
                                        resourceName="virtual machine"
                                    />
                                </>
                            )}
                        </div>

                        <div className="flex items-center gap-4">
                            {vm?.data && (
                                <SerialOverSshPanel
                                    vm={vm.data}
                                    environment={env}
                                />
                            )}

                            <VmStateControls vm={vm?.data} environment={env} />
                        </div>
                    </div>
                }
            >
                <ResourceDialogTab
                    to={null}
                    name="Dashboard"
                    icon={NavIcons["altDash"]}
                >
                    <CycleErrorBoundary>
                        <VmDashboardTab />
                    </CycleErrorBoundary>
                </ResourceDialogTab>
                {/* not supported yet. */}
                {/* <ResourceDialogTab to="vnc" name="VNC" icon={faScreencast}>
                    <CycleErrorBoundary>
                        <VmVncTab />
                    </CycleErrorBoundary>
                </ResourceDialogTab> */}
                <ResourceDialogTab
                    to="config"
                    name="Config"
                    icon={NavIcons["hubSettings"]}
                >
                    <CycleErrorBoundary>
                        <VmConfigTab />
                    </CycleErrorBoundary>
                </ResourceDialogTab>

                <ResourceDialogTab
                    to="telemetry"
                    name="Telemetry"
                    icon={faChartLineUpDown}
                >
                    <CycleErrorBoundary>
                        <VmTelemetryTab />
                    </CycleErrorBoundary>
                </ResourceDialogTab>
                <ResourceDialogTab
                    to="hypervisor"
                    name="Hypervisor"
                    icon={faTowerControl}
                >
                    <CycleErrorBoundary>
                        <VmHypervisorTab />
                    </CycleErrorBoundary>
                </ResourceDialogTab>
                <ResourceDialogTab
                    to="settings"
                    name="Settings"
                    icon={NavIcons["settings"]}
                >
                    <CycleErrorBoundary>
                        <VmSettingsTab focus={params["dialog-focus"]} />
                    </CycleErrorBoundary>
                </ResourceDialogTab>
            </StyledResourceDialog>
        </VirtualMachineDialogContex.Provider>
    );
}

const POLLING_TIME_LIMIT = 15_000;
const POLLING_FREQUENCY = 2_500;

function useHandlePollForVmPrivIps(vmId: string) {
    const [shouldPoll, setShouldPoll] = useState(true);

    const { currentData: vm, error } = useGetVirtualMachineQuery(
        {
            virtualMachineId: vmId || "",
            include: ["environments", "containers"],
            meta: ["domains", "ips", "server", "vm_priv_ips"],
        },
        {
            skip: !vmId,
            pollingInterval: shouldPoll ? POLLING_FREQUENCY : undefined,
        }
    );
    const hasPrivIps = !!vm?.data?.meta?.vm_priv_ips?.ipv6;

    // If Polling time limit reached, stop polling
    useEffect(() => {
        setTimeout(() => {
            setShouldPoll(false);
        }, POLLING_TIME_LIMIT);
    }, []);

    // If vm_priv_ips loaded in, stop polling
    useEffect(() => {
        if (!shouldPoll) {
            return;
        }
        if (hasPrivIps) {
            setShouldPoll(false);
        }
    }, [hasPrivIps, shouldPoll]);

    if (error) {
        throw error;
    }

    return vm;
}
