import classNames from "classnames";

type DialogResourceListProps = {
    children: React.ReactNode;
    className?: string;
};

export function DialogResourceList({
    children,
    className,
}: DialogResourceListProps) {
    return (
        <nav
            className={classNames(
                "flex h-full flex-col overflow-y-auto overflow-x-hidden pt-4 ",
                "3xl:w-[20rem] 3xl:min-w-[20rem]",
                "w-[16rem] min-w-[16rem]",
                className
            )}
        >
            <div className="">
                <ul className="flex flex-col gap-2"> {children}</ul>
            </div>
        </nav>
    );
}
