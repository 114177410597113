import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AccessControlOverlay } from "~/components/common/buttons";
import { MultiStepDialogContext } from "~/components/dialogs/MultiStepFormDialog/context";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { terminateForm } from "~/modules/forms/slice";
import { selectActiveHub, setActiveHub } from "~/modules/hubs/slice";
import { cycleApi, useDeleteHubMutation } from "~/services/cycle";

export function DeleteHubButton() {
    const { formKey } = useContext(MultiStepDialogContext);
    const nav = useNavigate();
    const dispatch = useAppDispatch();
    const [removeHub] = useDeleteHubMutation();

    const hub = useAppSelector(selectActiveHub);

    // Only "new" state hubs should be deletable via the Hub Setup process.
    if (hub?.state?.current !== "new") {
        return null;
    }

    return (
        <AccessControlOverlay capability={"hubs-delete"}>
            <PushAndHoldButton
                icon={faTrash}
                flavor="discard"
                onClick={async () => {
                    return removeHub({ body: {} })
                        .unwrap()
                        .then(() => {
                            nav("/");
                            dispatch(terminateForm({ key: formKey }));
                            // We set active hub to undefined to ensure that navigation occurs after job starts
                            // This prevents a delay when hub goes from deleting to deleted
                            dispatch(
                                setActiveHub({
                                    hubId: undefined,
                                })
                            );
                            dispatch(cycleApi.util.resetApiState());
                        });
                }}
            >
                Delete Hub
            </PushAndHoldButton>
        </AccessControlOverlay>
    );
}
