import { faTrash, faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { Activity, ComponentIncludes } from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import { IpsPoolLink } from "~/components/common/resources/ResourceLinks";

export function formatIPPoolMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const ipPoolLink = (
        <IpsPoolLink poolId={item.component?.id} components={components} />
    );

    switch (item.event) {
        case "infrastructure.ips.pool.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested deletion of IP pool {ipPoolLink}</span>
                ) : (
                    <span>
                        Error requesting deletion of IP pool {ipPoolLink}
                    </span>
                ),
                icon: faTrash,
            };
        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}
