import { components } from "@cycleplatform/core/modules/api/__generated";
import classNames from "classnames";
import { FormSection, FormSectionHeader } from "../forms";
import { DateFormats, formatDateString } from "@cycleplatform/core/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";

export function AnnouncementItem({
    announcement,
    className,
}: {
    announcement: components["schemas"]["Announcement"];
    className?: string;
}) {
    const created = new Date(announcement.events.created);
    return (
        <li
            className={classNames(
                className,
                " flex flex-col gap-2 rounded-md border p-4",
                announcementPriorityInfo[announcement.priority].border
            )}
        >
            <div className="flex flex-wrap justify-between">
                <h3 className="text-wrap text-lg font-bold">
                    <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className={classNames(
                            announcementPriorityInfo[announcement.priority]
                                .icon,
                            "pr-4"
                        )}
                    />
                    {announcement.title}
                </h3>
                <time className="font-bold" dateTime={created.toISOString()}>
                    {formatDateString(created, DateFormats["standardShort"])}
                </time>
            </div>
            {announcement.affected_integrations?.length ? (
                <div className=" flex gap-2 text-sm">
                    <span>Affected providers:</span>{" "}
                    <ul>
                        {announcement.affected_integrations.map((p) => (
                            <li key={p} className="mr-1 inline">
                                {p}
                            </li>
                        ))}
                    </ul>
                </div>
            ) : null}
            <p className="text-wrap ">{announcement.description}</p>

            {announcement.updates ? (
                <div>
                    <FormSectionHeader header="Updates" />
                    <FormSection>
                        <ul className="flex flex-col gap-2">
                            {announcement.updates.map((u) => {
                                const created = new Date(u.time);

                                return (
                                    <li key={u.id}>
                                        <time
                                            className="font-bold"
                                            dateTime={created.toISOString()}
                                        >
                                            [{formatDateString(created, "PPpp")}
                                            ]
                                        </time>{" "}
                                        <p className="text-wrap">{u.message}</p>
                                    </li>
                                );
                            })}
                        </ul>
                    </FormSection>
                </div>
            ) : null}
        </li>
    );
}

type AnnouncementPriorityInfoType = {
    border: string;
    icon: string;
    severityIdx: number;
};

export const announcementPriorityInfo: Record<
    components["schemas"]["Announcement"]["priority"],
    AnnouncementPriorityInfoType
> = {
    notice: {
        border: "border-cycle-blue",
        icon: "text-cycle-blue",
        severityIdx: 0,
    },
    low: {
        border: "border-cycle-blue-accent",
        icon: "text-cycle-blue-accent",
        severityIdx: 1,
    },
    medium: {
        border: "border-warning",
        icon: "text-warning",
        severityIdx: 2,
    },
    high: {
        border: "border-error",
        icon: "text-error",
        severityIdx: 3,
    },
    severe: {
        border: "border-error",
        icon: "text-error",
        severityIdx: 4,
    },
};
