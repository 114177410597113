import { LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    RhfFormField,
    RhfFormProvider,
    TextInput,
    RhfGlobalFormError,
    required,
    isUrl,
    FormSectionHeader,
    FormSection,
} from "@cycleplatform/ui/components/forms";
import { faArrowRight, faExternalLink } from "@fortawesome/pro-solid-svg-icons";
import { useForm } from "react-hook-form";
import { rhfConfig } from "~/components/forms/util";
import { ResourceAclCreate } from "~/components/common/acl";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { addStepData, getCurrentForm } from "~/modules/forms/slice";
import { OriginStepType } from "../../types";
import { useContext, useEffect } from "react";
import { MultiStepDialogContext } from "~/components/dialogs/MultiStepFormDialog/context";
import {
    DialogColumn,
    DialogFooter,
} from "@cycleplatform/ui/components/dialog/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function SetOrigin({ stepIdx = 0 }: { stepIdx?: number }) {
    const { handleNext, formKey } = useContext(MultiStepDialogContext);
    const currentForm = useAppSelector((state) =>
        getCurrentForm(state, formKey)
    );

    const form = useForm<OriginStepType>({
        defaultValues: currentForm?.steps?.[stepIdx] || { hosted: false },
        ...rhfConfig,
    });

    const {
        handleSubmit,
        register,
        setError,
        formState: { isSubmitting, isDirty },
    } = form;

    const dispatch = useAppDispatch();

    const onSubmit = async (data: OriginStepType) => {
        if (!isDirty && !currentForm?.steps?.[stepIdx]) {
            return;
        }
        dispatch(addStepData({ data, idx: stepIdx, key: formKey }));
        handleNext?.();
    };

    const error = currentForm?.error;

    useEffect(() => {
        if (error && error.step === stepIdx) {
            setError(error.source as never, { message: error.message });
        }
    }, [error]);

    return (
        <RhfFormProvider
            {...form}
            className="h-full"
            onSubmit={form.handleSubmit(onSubmit)}
        >
            <div className="flex">
                <DialogColumn className="w-2/3">
                    <RhfFormField label="Origin" name="origin" required>
                        <TextInput
                            {...register("origin", {
                                ...required(),
                                validate: { ...isUrl() },
                            })}
                        />
                    </RhfFormField>

                    <ResourceAclCreate resourceType="DNS zone" />
                </DialogColumn>

                <DialogColumn className="w-1/3 text-2xl">
                    <div className="mt-4 ">
                        <FormSectionHeader header="Learn More:" />
                        <FormSection>
                            <div className="mb-4 text-sm">
                                DNS (Domain Name System) , is a decentralized
                                naming system for computers, services, or other
                                resources connected to the internet or a private
                                network. It is what allows for the translation
                                of a URL, such as cycle.io, to an IP address.
                            </div>
                            <ul className="flex flex-col gap-2 pt-2 text-sm">
                                <li>
                                    <a
                                        className="flex items-center gap-2 "
                                        href="https://cycle.io/docs/platform/introduction-to-dns"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Platform Docs: Introduction to DNS{" "}
                                        <FontAwesomeIcon
                                            icon={faExternalLink}
                                            className="text-xs"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="flex items-center gap-2 "
                                        href="https://cycle.io/docs/platform/dns-zones"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Platform Docs: DNS Zones
                                        <FontAwesomeIcon
                                            icon={faExternalLink}
                                            className="text-xs"
                                        />
                                    </a>
                                </li>

                                <li>
                                    <a
                                        className="flex items-center gap-2 "
                                        href="https://cycle.io/docs/portal/creating-a-dns-zone"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Portal Docs: Creating a DNS Zone
                                        <FontAwesomeIcon
                                            icon={faExternalLink}
                                            className="text-xs"
                                        />
                                    </a>
                                </li>
                            </ul>
                        </FormSection>
                    </div>
                </DialogColumn>
            </div>

            <DialogFooter>
                <div>
                    <RhfGlobalFormError />
                </div>
                <LoaderButton
                    isLoading={isSubmitting}
                    onClick={handleSubmit(onSubmit)}
                    type="button"
                    flavor="primary"
                    icon={faArrowRight}
                    disabled={!isDirty && !currentForm?.steps?.[stepIdx]}
                >
                    Zone Type
                </LoaderButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}
