import { useNavigate } from "react-router-dom";
import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import {
    clearDialogParams,
    getAllDialogSearchParams,
    isDialogOpen,
} from "~/components/dialogs/helpers";
import { useGetIntegrationQuery } from "~/services/cycle";
import { SkeletonText } from "@cycleplatform/ui/components/loaders/skeleton";
import { GenerateIsoForm } from "./GenerateIsoForm";

export function VirtualProviderCreateIsoDialog() {
    const navigate = useNavigate();
    const params = getAllDialogSearchParams<"virtual-provider-create-iso">();

    const { data: integration } = useGetIntegrationQuery({
        integrationId: params["dialog-integration-id"],
    });

    return (
        <StyledFormDialog
            title={
                <div className="flex items-center gap-2">
                    {`Create Virtual Provider ISO`} -{" "}
                    {integration?.data.name ? (
                        <>{integration?.data.name} </>
                    ) : (
                        <SkeletonText className="inline-block !h-4" size="md" />
                    )}
                </div>
            }
            isOpen={isDialogOpen("virtual-provider-create-iso")}
            onClose={() => navigate(clearDialogParams())}
        >
            {integration?.data ? (
                <GenerateIsoForm integration={integration.data} />
            ) : null}
        </StyledFormDialog>
    );
}
