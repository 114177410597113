import { getCreditsTotal } from "@cycleplatform/core/modules/billing";
import { SkeletonHeader } from "@cycleplatform/ui/components/loaders/skeleton";
import { FormattedMoney } from "@cycleplatform/ui/components/money";
import { usePagination } from "@cycleplatform/ui/hooks";
import { useGetCreditsQuery } from "~/services/cycle";

export function CreditsContent() {
    const pagination = usePagination(5);
    const { data: credits, error: creditError } = useGetCreditsQuery({
        sort: ["-id"],
        page: pagination,
    });
    if (creditError) {
        throw creditError;
    }
    return (
        <div className="flex justify-center ">
            {credits?.data ? (
                <FormattedMoney
                    className="text-success py-4 text-3xl"
                    value={getCreditsTotal(credits?.data)}
                    mills
                />
            ) : (
                <SkeletonHeader size="sm" />
            )}
        </div>
    );
}
