import { ComponentPropsWithRef } from "react";
import { Button } from "./Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { PushAndHoldButton } from "./PushAndHoldButton";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { LoaderButton } from ".";
import { PositionedMenu } from "../menus";

type PositionedMenuButtonProps = ComponentPropsWithRef<typeof Button> & {
    /** Content of the popout menu activated when clicking the dropdown of the button */
    popout: React.ReactNode;
    panelClassName?: string;
    icon: IconProp;
    isLoading?: boolean;
    pushAndHold?: boolean;
    tooltip?: string;
    onClick?: () => void;
    onClose?: () => void;

    setIsOpenRef?: React.MutableRefObject<
        ((open: boolean) => void) | undefined
    >;
};

export function PositionedMenuButton({
    popout,
    children,
    pushAndHold,
    onClick,
    onClose,
    setIsOpenRef,
    tooltip,
    isLoading,
    panelClassName,
    ...buttonProps
}: PositionedMenuButtonProps) {
    return (
        <div className={classNames("flex")}>
            {pushAndHold ? (
                <PushAndHoldButton
                    {...buttonProps}
                    onClick={onClick ? onClick : () => null}
                    tooltip={tooltip}
                    isLoading={isLoading}
                    className={classNames(
                        buttonProps.className,
                        "rounded-r-none border-r-0"
                    )}
                >
                    {children}
                </PushAndHoldButton>
            ) : (
                <LoaderButton
                    {...buttonProps}
                    isLoading={isLoading}
                    onClick={onClick}
                    className={classNames(
                        buttonProps.className,
                        "rounded-r-none border-r-0"
                    )}
                >
                    {children}
                </LoaderButton>
            )}

            <PositionedMenu
                placement="bottom-end"
                className={classNames(panelClassName, "w-[30rem]")}
                onClose={onClose}
                render={(_, setIsOpen) => {
                    if (setIsOpenRef) {
                        setIsOpenRef.current = setIsOpen;
                    }
                    return <div>{popout}</div>;
                }}
            >
                <Button
                    disabled={buttonProps.disabled}
                    flavor={buttonProps.flavor}
                    className={classNames(
                        buttonProps.className,
                        "border-l-0.5 !border-l-cycle-gray/50 flex items-center rounded-lg rounded-l-none border-l !px-2"
                    )}
                >
                    <span className="">
                        <FontAwesomeIcon icon={faChevronDown} />
                    </span>
                </Button>
            </PositionedMenu>
        </div>
    );
}
