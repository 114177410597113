import { Controller, useFormContext } from "react-hook-form";
import { CreateBillingMethodFormType } from "../CreateBillingMethodForm";
import { formatCreditCard, getCreditCardType } from "@cycleplatform/core/util";
import { TextInput } from "@cycleplatform/ui/components/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCcAmex,
    faCcDinersClub,
    faCcDiscover,
    faCcJcb,
    faCcMastercard,
    faCcVisa,
} from "@fortawesome/free-brands-svg-icons";
import { faCreditCard } from "@fortawesome/pro-solid-svg-icons";

export function CreditCardTextInput() {
    const { control } = useFormContext<CreateBillingMethodFormType>();
    return (
        <Controller
            render={({ field: { ref: _ref, ...field } }) => {
                const ccType = getCreditCardType(field.value || "", " ");
                return (
                    <TextInput
                        onChange={(e) =>
                            field.onChange(
                                formatCreditCard(e.target.value).trimEnd()
                            )
                        }
                        value={field.value}
                        onBlur={field.onBlur}
                        name={field.name}
                        suffix={
                            <FontAwesomeIcon
                                icon={ccIcons[ccType] || faCreditCard}
                                className="dark:text-cycle-gray-light/70 text-cycle-gray text-3xl"
                            />
                        }
                    />
                );
            }}
            control={control}
            rules={{
                required: {
                    value: true,
                    message: "This field is required",
                },
            }}
            name="credit_card.number"
        />
    );
}

const ccIcons = {
    uatp: faCreditCard,
    amex: faCcAmex,
    diners: faCcDinersClub,
    discover: faCcDiscover,
    dankort: faCreditCard,
    instapayment: faCreditCard,
    mastercard: faCcMastercard,
    jcb15: faCcJcb,
    jcb: faCcJcb,
    maestro: faCreditCard,
    visa: faCcVisa,
    mir: faCreditCard,
    unionpay: faCreditCard,
    general: faCreditCard,
};
