import { useCreateHubMutation } from "~/services/cycle";
import { useForm, useFormContext } from "react-hook-form";
import { useAppDispatch } from "~/hooks";
import { cycleApi } from "~/services/cycle";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { setActiveHub } from "~/modules/hubs/slice";
import {
    InputRow,
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
    required,
} from "@cycleplatform/ui/components/forms";
import { LoaderButton } from "@cycleplatform/ui/components/buttons";
import { useNavigate } from "react-router-dom";
import { handleSubmitError } from "~/components/forms/util";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";
import { initializeForm } from "~/modules/forms/slice";
import { HUB_SETUP_KEY } from "~/components/dialogs/hubs/hubSetup";

type HubCreateFormType = {
    name: string;
};

export function HubCreateForm({
    inline,
}: {
    /**
     * Inline property = true will return the form on a single line
     * Hub Name input will be aligned with the submit button
     * (used on the "No-Hub" route)
     *
     * Default behavior is designed for a dialog like all other forms
     */
    inline?: boolean;
}) {
    const form = useForm<HubCreateFormType>({});
    const {
        formState: { isDirty },
    } = form;

    const [createHub] = useCreateHubMutation();
    const dispatch = useAppDispatch();
    const nav = useNavigate();

    const onSubmit = (data: HubCreateFormType) => {
        if (!isDirty) {
            return;
        }
        return createHub({
            body: {
                name: data.name,
            },
        })
            .unwrap()
            .then((hub) => {
                dispatch(setActiveHub({ hubId: hub?.data?.id }));
            })
            .then(() => {
                dispatch(initializeForm({ key: HUB_SETUP_KEY }));
            })
            .then(() => {
                dispatch(
                    cycleApi.util.invalidateTags([
                        "Hub",
                        "Hub.Resource",
                        "Hub.Membership",
                        "Hub.Integration",
                        "Hub.Provider",
                        "Hub.Invite",
                        "Hub.Role",
                        "Hub.Notifications",
                        "PersonalAccount",
                        "PublicAccount",
                        "SearchIndex",
                    ])
                );
                nav({ pathname: "/", search: "" });
            })

            .catch(handleSubmitError(form.setError));
    };

    return (
        <RhfFormProvider {...form}>
            <FormLayout onSubmit={onSubmit} inline={inline} />
        </RhfFormProvider>
    );
}

function FormLayout({
    onSubmit,
    inline,
}: {
    onSubmit: (data: HubCreateFormType) => Promise<void> | undefined;
    inline?: boolean;
}) {
    const {
        register,
        handleSubmit,
        formState: { isSubmitting, isDirty },
    } = useFormContext<HubCreateFormType>();

    if (inline) {
        return (
            <>
                <InputRow className="items-end">
                    <RhfFormField label="hub name" name="name" required>
                        <TextInput {...register("name", { ...required() })} />
                    </RhfFormField>
                    <div>
                        <LoaderButton
                            isLoading={isSubmitting}
                            onClick={handleSubmit(onSubmit)}
                            icon={faPlus}
                            flavor="primary"
                            disabled={!isDirty}
                        >
                            Create
                        </LoaderButton>
                    </div>
                </InputRow>

                <div>
                    <RhfGlobalFormError />
                </div>
            </>
        );
    }
    return (
        <>
            <RhfFormField label="hub name" name="name" required>
                <TextInput {...register("name", { ...required() })} />
            </RhfFormField>

            <DialogFooter>
                <div>
                    <RhfGlobalFormError />
                </div>
                <LoaderButton
                    isLoading={isSubmitting}
                    onClick={handleSubmit(onSubmit)}
                    icon={faPlus}
                    flavor="primary"
                    disabled={!isDirty}
                >
                    Create
                </LoaderButton>
            </DialogFooter>
        </>
    );
}
