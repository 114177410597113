import { Controller, useFormContext } from "react-hook-form";
import {
    RhfFormField,
    TextInput,
    maxLength,
    required,
    FormSectionHeader,
    FormSection,
    minLength,
} from "@cycleplatform/ui/components/forms";

import { BasicSelect } from "@cycleplatform/ui/components/forms/select";
import { capitalizeWord } from "@cycleplatform/core/util";
import { CreateBillingMethodFormType } from "../CreateBillingMethodForm";

export function UsBankFormSection() {
    const { register, control } = useFormContext<CreateBillingMethodFormType>();

    return (
        <>
            <FormSectionHeader header="Bank Account" />
            <FormSection>
                <div className="flex gap-2">
                    <RhfFormField
                        label="Account Holder"
                        name="us_bank_acct.account_holder_name"
                        required
                    >
                        <TextInput
                            {...register("us_bank_acct.account_holder_name", {
                                ...required(),
                            })}
                        />
                    </RhfFormField>
                    <RhfFormField
                        label="Account Type"
                        name="us_bank_acct.account_holder_type"
                        className="!w-[10rem]"
                        required
                    >
                        <Controller
                            defaultValue="company"
                            render={({ field: { ref: _ref, ...field } }) => (
                                <BasicSelect
                                    {...field}
                                    options={["company", "individual"].map(
                                        (c) => ({
                                            label: capitalizeWord(c),
                                            value: c,
                                        })
                                    )}
                                />
                            )}
                            rules={{ ...required() }}
                            control={control}
                            name="us_bank_acct.account_holder_type"
                        />
                    </RhfFormField>
                </div>

                <RhfFormField
                    label="Routing Number"
                    name="us_bank_acct.routing_number"
                    required
                >
                    <TextInput
                        {...register("us_bank_acct.routing_number", {
                            ...required(),
                            ...maxLength(9),
                            ...minLength(9),
                        })}
                    />
                </RhfFormField>

                <RhfFormField
                    label="Account Number"
                    name="us_bank_acct.account_number"
                    required
                >
                    <TextInput
                        {...register("us_bank_acct.account_number", {
                            ...required(),
                            ...maxLength(17),
                            ...minLength(4),
                        })}
                    />
                </RhfFormField>
            </FormSection>
        </>
    );
}
