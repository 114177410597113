import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import {
    GetVirtualMachineSshKeysApiArg,
    useGetEnvironmentsQuery,
    useGetVirtualMachineSshKeysQuery,
    VirtualMachineSshKey,
} from "~/services/cycle";
import { FormattedOption } from "@cycleplatform/ui/components/forms/select";
import { Badge } from "@cycleplatform/ui/components/badges";
import { ResourceComboBox } from "~/components/common/forms";

type VmSshKeySelectProps<
    MULTI extends boolean = false,
    NULLABLE extends boolean = false
> = {
    value: MULTI extends true ? Array<string> : string | undefined;
    isNullable?: NULLABLE;
    onChange: MULTI extends true
        ? (ids: string[], sshKeys: VirtualMachineSshKey[]) => void
        : (
              id: string | undefined | null,
              sshKey: VirtualMachineSshKey | undefined
          ) => void;
    skip?: boolean;
    placeholder?: string;
    multiple?: MULTI;
    disabled?: boolean;
    filter?: GetVirtualMachineSshKeysApiArg["filter"];
    required?: boolean;
    className?: string;
};

export function VmSshKeySelect<MULTI extends boolean = false>({
    value,
    onChange,
    skip,
    multiple,
    isNullable,
    filter,
    placeholder,
    disabled,
    className,
}: VmSshKeySelectProps<MULTI>) {
    const { currentData: sshKeys, error } = useGetVirtualMachineSshKeysQuery(
        {
            sort: ["-id"],
            page: {
                number: 1,
                size: 100,
            },
            filter,
        },
        { skip }
    );

    if (error) {
        throw error;
    }

    return (
        <ResourceComboBox
            className={className}
            filterFields={["id", "name"]}
            value={value}
            multiple={multiple}
            onChange={onChange}
            isNullable={isNullable}
            placeholder={placeholder || "No SSH Keys Selected"}
            disabled={disabled}
            resources={sshKeys?.data || []}
            formatDisplayValue={(e) => {
                if (!e) return "";

                return e.name;
            }}
            formatOption={(e) => {
                return (
                    <FormattedOption label={e.name}>
                        <ResourceStateBadge state={e.state} />
                    </FormattedOption>
                );
            }}
        />
    );
}
