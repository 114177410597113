import { useGenerateAggregatedMetricsQuery } from "~/services/cycle";
import { buildServerUsageQuery } from "./query";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import {
    Bar,
    CartesianGrid,
    ComposedChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import classNames from "classnames";
import {
    CycleAxisProps,
    CycleChartProps,
} from "@cycleplatform/ui/components/recharts/props";
import { FormattedYTick } from "@cycleplatform/ui/components/recharts/FormattedYTick";
import { brandColors } from "tailwindcss-config/colors";
import { FormattedTimeTick } from "@cycleplatform/ui/components/recharts/FormattedTimeTick";
import { CycleBarTooltip } from "@cycleplatform/ui/components/recharts/bar/CycleBarTooltip";
import {
    CycleReferenceLabelProps,
    CycleReferenceLineProps,
} from "@cycleplatform/ui/components/recharts/line/props";
import { useGetThemedChartColors } from "~/util/charts/hooks";
import { CycleGridProps } from "@cycleplatform/ui/components/recharts/grid/props";
import { useMemo } from "react";
import { AGGREGATE_POLLING_MS } from "~/util/charts/util";

type ServerUsageChartProps = {
    allowedServers: number;
};

type TelemetryType = {
    servers: {
        [key: string]: number;
    };
    [key: string]: unknown;
};

export function ServerUsageChart({ allowedServers }: ServerUsageChartProps) {
    const { colors } = useGetThemedChartColors();

    const { data: telemetry, isLoading } = useGenerateAggregatedMetricsQuery(
        buildServerUsageQuery(),
        { pollingInterval: AGGREGATE_POLLING_MS }
    );
    const data = telemetry?.data as TelemetryType[] | undefined;

    const dataMax = useMemo(
        () =>
            data?.reduce((acc, cur) => {
                const totalServers: number = Object.values(cur.servers).reduce(
                    (sumAcc, sumCur) => (sumAcc as number) + (sumCur as number),
                    0 as number
                );

                return totalServers > acc ? totalServers : acc;
            }, 0 as number),
        [data]
    );

    let bars: string[] = [];

    if (!telemetry?.data && !isLoading) {
        return null;
    }

    const formattedData = telemetry?.data?.map((p) => {
        const np: {
            time: string;
            [key: string]: string | number;
        } = {
            time: p.time,
        };

        if (!p.servers) {
            return np;
        }

        Object.entries(p.servers)?.forEach(([cluster, serverCount]) => {
            np[cluster] = serverCount as number;
            bars = [...new Set([...bars, cluster])];
        });
        return np;
    });

    const colorsArr = [
        colors["blue"].DEFAULT,
        colors["blue"].light,
        colors["gray"].dark,
        colors["gray"].DEFAULT,
    ];
    return (
        <div
            className={classNames(
                "group/usage h-full",
                isLoading && skeletonStyles
            )}
        >
            {telemetry?.data && (
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                        {...CycleChartProps}
                        data={formattedData}
                        syncId={"hub-usage"}
                    >
                        <CartesianGrid {...CycleGridProps} />
                        <Tooltip
                            content={(props) => <CycleBarTooltip {...props} />}
                        />

                        {bars?.map((b, idx) => {
                            const colorIdx = idx % colorsArr.length;
                            return (
                                <Bar
                                    key={b}
                                    stackId="priority"
                                    dataKey={b}
                                    fill={colorsArr[colorIdx]}
                                    opacity={0.85}
                                />
                            );
                        })}

                        <ReferenceLine
                            {...CycleReferenceLineProps}
                            y={allowedServers}
                            stroke={
                                dataMax && dataMax > allowedServers
                                    ? brandColors["warning"].DEFAULT
                                    : undefined
                            }
                            label={{
                                ...CycleReferenceLabelProps,
                                fill: colors["black"].DEFAULT,
                                value: "Total Allowed Servers",
                            }}
                        />

                        <XAxis
                            {...CycleAxisProps}
                            dataKey="time"
                            tick={(props) => <FormattedTimeTick {...props} />}
                        />
                        <YAxis
                            {...CycleAxisProps}
                            allowDecimals={false}
                            domain={[
                                0,
                                (dataMax: number) => {
                                    const max =
                                        dataMax > allowedServers
                                            ? dataMax
                                            : allowedServers;
                                    return Math.ceil(max * 1.2);
                                },
                            ]}
                            tick={(props) => <FormattedYTick {...props} />}
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            )}
        </div>
    );
}
