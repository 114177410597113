import {
    faTrash,
    faArrowCircleUp,
    faQuestionCircle,
    faPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { Activity, ComponentIncludes } from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import { RoleLink } from "~/components/common/resources/ResourceLinks";

export function formatRoleMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const roleLink = (
        <RoleLink roleId={item.component?.id} components={components} />
    );

    switch (item.event) {
        case "hub.role.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted Role {roleLink}</span>
                ) : (
                    <span>Error deleting Role {roleLink}</span>
                ),
                icon: faTrash,
            };
        case "hub.role.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested deletion of role {roleLink}</span>
                ) : (
                    <span>Error requesting deletion of role {roleLink}</span>
                ),
                icon: faTrash,
            };
        case "hub.role.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated Role {roleLink}</span>
                ) : (
                    <span>Error updating Role {roleLink}</span>
                ),
                icon: faArrowCircleUp,
            };
        case "hub.role.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Created Role {roleLink}</span>
                ) : (
                    <span>Error creating API key</span>
                ),
                icon: faPlus,
            };
        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}
