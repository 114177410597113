import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServer } from "@fortawesome/pro-duotone-svg-icons";
import { Tooltip } from "../../../tooltip";

export function ServerComponent({ message }: { message: string }) {
    return (
        <Tooltip disabled={!message} message={message}>
            <span className="fa-layers fa-fw mr-2">
                <FontAwesomeIcon
                    className={"text-cycle-blue text-3xl"}
                    icon={faServer}
                />
            </span>
        </Tooltip>
    );
}
