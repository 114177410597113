import { useNavigate } from "react-router-dom";
import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import {
    clearDialogParams,
    getAllDialogSearchParams,
    isDialogOpen,
} from "~/components/dialogs/helpers";
import { DnsRecordForm } from "~/components/dns/forms/DnsRecordForm";
import {
    useGetDnsZoneQuery,
    useGetDnsZoneRecordsQuery,
} from "~/services/cycle";
import {
    SkeletonFallback,
    SkeletonHeader,
    SkeletonText,
} from "@cycleplatform/ui/components/loaders/skeleton";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";

export function DnsRecordDialog() {
    const params = getAllDialogSearchParams<"dns-record">();
    const zoneId = params["dialog-zone-id"];
    const recordId = params["dialog-record-id"];

    const { data: dnsRecords, error } = useGetDnsZoneRecordsQuery(
        {
            zoneId: zoneId || "",
            sort: ["name"],
            page: {
                size: 100,
                number: 1,
            },
            include: ["containers"],
        },
        { skip: !zoneId }
    );

    const { data: zone } = useGetDnsZoneQuery(
        {
            zoneId: zoneId || "",
        },
        { skip: !zoneId }
    );

    const record = dnsRecords?.data?.find((r) => r.id === recordId);

    const nav = useNavigate();

    if (error) {
        throw error;
    }

    return (
        <StyledFormDialog
            title={
                recordId
                    ? `Edit DNS Record ${
                          record?.name ? `- ${record.name}` : ""
                      }`
                    : "Add DNS Record"
            }
            isOpen={isDialogOpen("dns-record")}
            onClose={() => nav(clearDialogParams())}
        >
            <DialogColumn className="w-full">
                <SkeletonFallback
                    shouldRenderFallback={!record && !!recordId}
                    fallback={
                        <>
                            <SkeletonHeader />
                            <SkeletonText />
                        </>
                    }
                >
                    <DnsRecordForm record={record} zone={zone?.data} />
                </SkeletonFallback>
            </DialogColumn>
        </StyledFormDialog>
    );
}
