import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import { RhfGlobalFormError } from "@cycleplatform/ui/components/forms";
import { useFormContext } from "react-hook-form";
import { AccessControlOverlay } from "~/components/common/buttons";
import { useContext, useState } from "react";
import {
    LoadBalancerConfig,
    useCreateLoadBalancerServiceJobMutation,
} from "~/services/cycle";
import { handleSubmitError } from "~/components/forms/util";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { useJobTracker } from "~/modules/jobs/hooks";
import { LoadBalancerDialogContext } from "../../../../context";
import { LoadBalancerFormValues } from "../../../form";
import { isStrictDirty } from "~/components/common/forms/util";

export function V1ConfigFooter() {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const {
        handleSubmit,
        setError,
        reset,
        formState: { dirtyFields },
    } = useFormContext<LoadBalancerFormValues>();

    const strictIsDirty = isStrictDirty(dirtyFields);

    const { environment } = useContext(LoadBalancerDialogContext);
    const [updateConfig] = useCreateLoadBalancerServiceJobMutation();
    const [trackJob] = useJobTracker();

    const onSubmit = (data: LoadBalancerFormValues) => {
        setIsSubmitting(true);

        if (!strictIsDirty) {
            return;
        }

        const formattedData = {
            config: {
                ...data.config,
                details: data.config?.details,
            } as LoadBalancerConfig,
        };

        return trackJob(
            updateConfig({
                environmentId: environment?.id || "",
                body: {
                    action: "reconfigure",
                    contents: formattedData,
                },
            }).unwrap()
        ).then(
            () => {
                reset((formValues) => ({ ...formValues }));
                setIsSubmitting(false);
            },
            (e) => {
                handleSubmitError(setError)(e);
                setIsSubmitting(false);
            }
        );
    };

    return (
        <div
            className={classNames(
                "absolute bottom-0 left-0 right-0 z-10 mt-auto  border-t",
                "bg-white px-2 py-4",
                "dark:border-cycle-black-accent dark:bg-cycle-black",
                "flex  w-full items-center justify-between gap-4"
            )}
        >
            <div>
                <RhfGlobalFormError />
            </div>
            <div className="flex gap-4">
                <AccessControlOverlay
                    aclResource={environment}
                    verifyFn={modifyAccessFn("environments-services-manage")}
                >
                    <PushAndHoldButton
                        flavor="primary"
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                        icon={faEdit}
                        isLoading={isSubmitting}
                        disabled={!strictIsDirty}
                    >
                        Update Controller Config
                    </PushAndHoldButton>
                </AccessControlOverlay>
            </div>
        </div>
    );
}
