import {
    faQuestionCircle,
    faObjectUnion,
    faArrowDownFromDottedLine,
} from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { Activity, ComponentIncludes, TlsCertificate } from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import { NavIcons } from "~/components/layout/NavIcons";

export function formatDnsCertificateMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const domains = extractDomain(item.component?.id || "", components);
    const link = <Link to={`/dns/tls/user-certificates`}>{domains}</Link>;

    switch (item.event) {
        case "dns.certificate.associate":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Associated user supplied TLS certificate for domain{" "}
                        {link} with DNS records
                    </span>
                ) : (
                    <span>
                        Error associating user supplied TLS certificate for
                        domain {link} with DNS records
                    </span>
                ),
                icon: faObjectUnion,
            };
        case "dns.certificate.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Created user supplied TLS certificate for domain {link}
                    </span>
                ) : (
                    <span>
                        Error creating user supplied TLS certificate for domain{" "}
                        {link}
                    </span>
                ),
                icon: NavIcons["dnsTls"],
            };
        case "dns.certificate.task.deprecate":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested deprecation of user supplied TLS certificate
                        for domain {link}
                    </span>
                ) : (
                    <span>
                        Error requesting deprecation of user supplied TLS
                        certificate for domain {link}
                    </span>
                ),
                icon: faArrowDownFromDottedLine,
            };
        case "dns.certificate.deprecate":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Deprecated user supplied TLS certificate for domain{" "}
                        {link}
                    </span>
                ) : (
                    <span>
                        Error deprecating user supplied TLS certificate for
                        domain {link}
                    </span>
                ),
                icon: faArrowDownFromDottedLine,
            };

        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}

function extractDomain(id: string, components: ComponentIncludes) {
    const c = components?.[id];
    if (!c) {
        return id;
    }

    return (c as TlsCertificate).domains?.join(", ");
}
