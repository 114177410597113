import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { Activity, ComponentIncludes } from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import { faGroupArrowsRotate } from "@fortawesome/pro-duotone-svg-icons";
import { AutoscaleGroupLink } from "~/components/common/resources/ResourceLinks";

export function formatInfrastructureAutoscaleMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const autoscaleGroupLink = (
        <AutoscaleGroupLink
            groupId={item.component?.id}
            components={components}
        />
    );
    switch (item.event) {
        case "infrastructure.autoscale.group.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Created new auto-scale group {autoscaleGroupLink}
                    </span>
                ) : (
                    <span>
                        Error creating new auto-scale group {autoscaleGroupLink}
                    </span>
                ),
                icon: faGroupArrowsRotate,
            };
        case "infrastructure.autoscale.group.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated auto-scale group {autoscaleGroupLink}</span>
                ) : (
                    <span>
                        Error updating auto-scale group {autoscaleGroupLink}
                    </span>
                ),
                icon: faGroupArrowsRotate,
            };
        case "infrastructure.autoscale.group.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted auto-scale group {autoscaleGroupLink}</span>
                ) : (
                    <span>
                        Error deleting auto-scale group {autoscaleGroupLink}
                    </span>
                ),
                icon: faTrash,
            };
        case "infrastructure.autoscale.group.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested deletion of auto-scale group{" "}
                        {autoscaleGroupLink}
                    </span>
                ) : (
                    <span>
                        Error requesting deletion of auto-scale group{" "}
                        {autoscaleGroupLink}
                    </span>
                ),
                icon: faTrash,
            };
        default:
            return {
                message: item.event,
            };
    }
}
