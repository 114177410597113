import {
    DialogPageBody,
    DialogPageContent,
    DialogPageHeader,
} from "@cycleplatform/ui/components/dialog";
import { NavIcons } from "~/components/layout/NavIcons";
import {
    generateDialogLink,
    getAllDialogSearchParams,
} from "~/components/dialogs/helpers";
import { useCapability } from "~/modules/hubs/permissions/useCapability";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { OverviewSection } from "./traffic/overview/OverviewSection";
import { UrlsSection } from "./traffic/urls/UrlsSection";
import { RoutersSection } from "./traffic/routers/RoutersSection";
import { V1PortForm } from "./form/v1PortForm/V1PortForm";

export function PortContent() {
    const params = getAllDialogSearchParams<"environment-lb-manage">();
    const activePortParam = params["dialog-port"];

    return (
        <div className="relative h-full w-full ">
            <DialogPageContent className="w-full min-w-0 !p-0 ">
                <div className="w-full overflow-y-auto pt-4">
                    <DialogPageHeader
                        key={activePortParam}
                        title={
                            activePortParam
                                ? `Port ${activePortParam}`
                                : undefined
                        }
                        icon={NavIcons["lbController"]}
                        tabs={[
                            {
                                name: "Overview",
                                to: generateDialogLink(
                                    "environment-lb-manage",
                                    {
                                        "dialog-tab": "controllers",
                                        "dialog-controllers-subsection":
                                            "overview",
                                    }
                                ),
                                isActive:
                                    params["dialog-controllers-subsection"] ===
                                    "overview",
                            },
                            {
                                name: "URLs",
                                to: generateDialogLink(
                                    "environment-lb-manage",
                                    {
                                        "dialog-tab": "controllers",
                                        "dialog-controllers-subsection": "urls",
                                    }
                                ),
                                isActive:
                                    params["dialog-controllers-subsection"] ===
                                    "urls",
                            },
                            {
                                name: "Routers",
                                to: generateDialogLink(
                                    "environment-lb-manage",
                                    {
                                        "dialog-tab": "controllers",
                                        "dialog-controllers-subsection":
                                            "routers",
                                    }
                                ),
                                isActive:
                                    params["dialog-controllers-subsection"] ===
                                    "routers",
                            },
                            {
                                name: "Config",
                                to: generateDialogLink(
                                    "environment-lb-manage",
                                    {
                                        "dialog-tab": "controllers",
                                        "dialog-controllers-subsection":
                                            "config",
                                    }
                                ),
                                isActive:
                                    params["dialog-controllers-subsection"] ===
                                    "config",
                            },
                        ]}
                    >
                        {/* <RemovePortButton environment={environment} /> */}
                    </DialogPageHeader>

                    {params["dialog-controllers-subsection"] === "overview" ? (
                        <OverviewSection
                            port={parseInt(activePortParam)}
                            key={`port-${activePortParam}`}
                        />
                    ) : null}
                    {params["dialog-controllers-subsection"] === "urls" ? (
                        <UrlsSection
                            port={parseInt(activePortParam)}
                            key={`port-${activePortParam}`}
                        />
                    ) : null}
                    {params["dialog-controllers-subsection"] === "routers" ? (
                        <RoutersSection
                            port={parseInt(activePortParam)}
                            key={`port-${activePortParam}`}
                        />
                    ) : null}

                    {params["dialog-controllers-subsection"] === "config" ? (
                        <ControllerConfigSection />
                    ) : null}
                </div>
            </DialogPageContent>
        </div>
    );
}

function ControllerConfigSection() {
    const hasCap = useCapability("environments-services-manage");

    const params = getAllDialogSearchParams<"environment-lb-manage">();
    const activePortParam = params["dialog-port"];

    if (hasCap instanceof Error) {
        return (
            <DialogPageBody className="w-full p-4">
                <EmptyResource
                    className="flex h-60 items-center justify-center border-none"
                    icon={NavIcons["lbController"]}
                    title={`Load Balancer Controllers Restricted`}
                >
                    <p className="text-center">
                        Managing load balancer controllers requires the
                        "environment-services-manage" capability.
                    </p>
                </EmptyResource>
            </DialogPageBody>
        );
    }

    return <V1PortForm key={`config-${activePortParam}`} />;
}
