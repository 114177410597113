// Utilities for networks of environment resources

import { components } from "../../api/__generated";

export function extractPrivateIPs(
    container: components["schemas"]["Container"]
) {
    const ips: { ipv6: string; ipv4: string | null } = {
        ipv6: "None Assigned",
        ipv4: null,
    };

    const envSummary = container.environment;

    if (envSummary?.ipv6) {
        ips.ipv6 = envSummary?.ipv6?.cidr;
    }

    if (container.environment?.legacy) {
        ips.ipv4 = container.environment.legacy.ipv4
            ? container.environment.legacy.ipv4.cidr
            : "None Assigned";
    }

    return ips;
}
