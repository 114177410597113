import { components } from "../../api/__generated";
import { getActualTransportConfig } from "../../environments/loadbalancer/v1";

type AnnotatedContainerPort = {
    lbIngress: number;
    containerIngress: number;
    mode: "tcp" | "http" | "udp";
    tls: boolean;
    disabled: boolean;
    autoRedirect: boolean;
};

/**
 * Retrieves an array of ports (with configurations) of any port that is open allowing traffic from LB <-> Container
 */
export function getAnnotatedContainerPorts(
    container: components["schemas"]["Container"],
    lbConfig: components["schemas"]["LoadBalancerConfig"] | undefined
): AnnotatedContainerPort[] {
    const containerPorts =
        container.config.network.ports
            ?.map((p) => {
                try {
                    const parts = p.split(":");
                    const lbIngress = parseInt(parts[0]);
                    const containerIngress = parts[1]
                        ? parseInt(parts[1])
                        : lbIngress;
                    return { lbIngress, containerIngress };
                } catch {
                    return null;
                }
            })
            .filter(
                (p): p is { lbIngress: number; containerIngress: number } => !!p
            ) || [];

    return containerPorts
        .map(({ lbIngress, containerIngress }) => {
            switch (lbConfig?.type) {
                case "v1": {
                    // it's important to note we're dealing with either a custom config
                    // OR a base config for the defaults, and it's expected that the caller
                    // passes in some valid config for this load balancer. therefore we can reuse
                    // it as the 'base' as it's expected that the absolute base config at a minimum
                    // will be present.
                    const transport = getActualTransportConfig(
                        lbIngress,
                        lbConfig.details,
                        lbConfig.details
                    );

                    return {
                        lbIngress,
                        containerIngress,
                        mode: transport.mode,
                        tls: transport.config.ingress.tls?.enable || false,
                        disabled: transport.disable,
                        autoRedirect: transport.routers.some((r) => {
                            if (r.config.extension?.type !== "http") {
                                return;
                            }
                            return r.config.extension?.details.redirect
                                ?.auto_https_redirect;
                        }),
                    };
                }
                case "haproxy": {
                    const match =
                        Object.entries(lbConfig.details?.ports || {}).find(
                            (p) => p[0] === `${lbIngress}`
                        )?.[1] || lbConfig.details?.default;

                    if (!match) {
                        return null;
                    }

                    return {
                        lbIngress,
                        containerIngress,
                        mode: match.frontend.mode as string,
                        tls: lbIngress === 443,
                        disabled: false,
                        autoRedirect: lbIngress === 443,
                    };
                }
            }
        })
        .filter((p): p is AnnotatedContainerPort => !!p);
}

export function getContainerLinkedRecords(
    container: components["schemas"]["Container"]
) {
    const domains = container.meta?.domains?.filter(
        (
            d
        ): d is typeof container.meta.domains[number] & {
            record: components["schemas"]["DnsRecord"];
        } => !!d.record
    );

    if (!domains || domains.length === 0) {
        return [];
    }

    return domains.map((d) => ({
        fqdn: d.fqdn,
        tls: !!d.record.features?.certificate?.id,
    }));
}
