import {
    generateDialogLink,
    getAllDialogSearchParams,
} from "~/components/dialogs/helpers";
import { useContext, useEffect } from "react";
import {
    DialogSubNavTab,
    SideNavTabList,
} from "@cycleplatform/ui/components/page/tabs";
import { getStandardPortName } from "~/util/loadbalancer";
import { useNavigate } from "react-router-dom";
import { LoadBalancerDialogContext } from "../../context";
import { getDefaultLbFormVals } from "../form";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";

export function PortList({
    search,
    controllers,
    telemControllers,
}: {
    search: string;
    controllers: number[];
    telemControllers: number[];
}) {
    const params = getAllDialogSearchParams<"environment-lb-manage">();
    const activePortParam = params["dialog-port"];
    const nav = useNavigate();

    useEffect(() => {
        if (!controllers || controllers.length === 0) {
            return;
        }

        if (!activePortParam || !parseInt(activePortParam)) {
            nav(
                generateDialogLink("environment-lb-manage", {
                    "dialog-tab": "controllers",
                    "dialog-port": controllers[0].toString(),
                    "dialog-controllers-subsection":
                        params["dialog-controllers-subsection"] || "overview",
                })
            );
        }
    }, [controllers, params]);

    const c = controllers.filter((c) =>
        search === "" ? true : c.toString().includes(search)
    );

    return (
        <SideNavTabList className="!px-0 pt-0">
            {c
                ?.sort((a, b) => a - b)
                ?.map((p, idx) => {
                    const status = getControllerStatus(p, telemControllers);
                    return (
                        <DialogSubNavTab
                            prefix={
                                status ? (
                                    <Tooltip message={status.message}>
                                        <FontAwesomeIcon
                                            icon={faCircle}
                                            className={classNames(
                                                "text-xs",
                                                status.color
                                            )}
                                        />
                                    </Tooltip>
                                ) : null
                            }
                            className="relative flex h-[50px] w-full items-center"
                            key={`controller-link-${idx}`}
                            title={`Port ${p} ${getStandardPortName(p) || ""}`}
                            to={generateDialogLink("environment-lb-manage", {
                                "dialog-tab": "controllers",
                                "dialog-port": `${p}`,
                            })}
                            isActive={params["dialog-port"] === `${p}`}
                        />
                    );
                })}
        </SideNavTabList>
    );
}

function getControllerStatus(
    port: number,
    telemControllers: number[]
): { color: string; message: string } | null {
    const { service } = useContext(LoadBalancerDialogContext);
    const config = getDefaultLbFormVals(service)?.config || undefined;

    if (config?.type !== "v1") {
        return null;
    }

    const activePortIdx = config.details.controllers.findIndex(
        (c) => c.port === port
    );

    const controller = config?.details?.controllers[activePortIdx];

    if (controller?.transport?.disable) {
        return {
            color: "text-cycle-gray",
            message: "Port disabled in transport config",
        };
    }

    if (!telemControllers?.includes(port)) {
        return {
            color: "text-cycle-blue",
            message:
                "Port is registered to load balancer config but no containers configured to use this port.",
        };
    }

    return {
        color: "text-success",
        message: "Port is live and in use.",
    };
}
