import { Controller, useFormContext } from "react-hook-form";
import { CreateBillingMethodFormType } from "../CreateBillingMethodForm";
import {
    FormSection,
    FormSectionHeader,
    required,
    RhfFormField,
    TextAreaInput,
} from "@cycleplatform/ui/components/forms";
import { BasicSelect } from "@cycleplatform/ui/components/forms/select";

export function DirectPaymentFormSection() {
    const { register, control } = useFormContext<CreateBillingMethodFormType>();

    return (
        <>
            <FormSectionHeader header="Direct Payment" />
            <FormSection>
                <RhfFormField
                    label="Preference"
                    name="direct_payment.preference"
                    required
                >
                    <Controller
                        defaultValue="check"
                        render={({ field: { ref: _ref, ...field } }) => (
                            <BasicSelect
                                {...field}
                                options={[
                                    { value: "check", label: "Check" },
                                    { value: "wire", label: "Wire" },
                                    { value: "ach", label: "ACH" },
                                ]}
                            />
                        )}
                        rules={{ ...required() }}
                        control={control}
                        name="direct_payment.preference"
                    />
                </RhfFormField>

                <RhfFormField
                    label="Payment Instructions"
                    name="direct_payment.instructions"
                    required
                >
                    <TextAreaInput
                        {...register("direct_payment.instructions")}
                        rows={5}
                    />
                </RhfFormField>
            </FormSection>
        </>
    );
}
