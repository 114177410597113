import {
    LoadBalancerConfig,
    useCreateLoadBalancerServiceJobMutation,
} from "~/services/cycle";
import { LoadBalancerFormValues } from "../../../form";
import { useJobTracker } from "~/modules/jobs/hooks";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { LoadBalancerDialogContext } from "../../../../context";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { handleSubmitError } from "~/components/forms/util";

export type AddControllerForm = LoadBalancerFormValues & {
    newController?: number | null;
};

type UseAddControllerProps<T extends FieldValues> = UseFormReturn<T> & {
    setIsOpen?: (open: boolean) => void;
};

export function useAddController({
    reset,
    setError,
    setIsOpen,
}: UseAddControllerProps<AddControllerForm>) {
    const { environment } = useContext(LoadBalancerDialogContext);
    const [trackJob] = useJobTracker();
    const [updateConfig] = useCreateLoadBalancerServiceJobMutation();
    const nav = useNavigate();

    return (data: AddControllerForm) => {
        if (data.config?.type !== "v1" || !data.newController) {
            return;
        }

        const formattedData = {
            config: {
                ...data.config,
                details: {
                    ...data.config?.details,
                    controllers: [
                        ...data.config.details.controllers,
                        {
                            port: data.newController,
                            identifier: `port-${data.newController}`,
                            transport: undefined,
                        },
                    ],
                },
            } as LoadBalancerConfig,
        };

        return trackJob(
            updateConfig({
                environmentId: environment?.id || "",
                body: {
                    action: "reconfigure",
                    contents: formattedData,
                },
            }).unwrap()
        ).then(() => {
            reset((formValues) => ({
                ...formValues,
                newController: null,
            }));

            setIsOpen?.(false);

            nav(
                generateDialogLink("environment-lb-manage", {
                    "dialog-tab": "controllers",
                    "dialog-port": `${data.newController}`,
                    "dialog-controllers-subsection": "config",
                })
            );
        }, handleSubmitError(setError));
    };
}
