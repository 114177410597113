import { ButtonLink, LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    RhfFormField,
    RhfFormProvider,
    TextInput,
    RhfGlobalFormError,
    required,
    isUrl,
    FormSectionHeader,
    FormSection,
} from "@cycleplatform/ui/components/forms";
import { faArrowRight, faExternalLink } from "@fortawesome/pro-solid-svg-icons";
import { useForm } from "react-hook-form";
import { rhfConfig } from "~/components/forms/util";
import { ResourceAclCreate } from "~/components/common/acl";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { addStepData, getCurrentForm } from "~/modules/forms/slice";
import { useContext, useEffect } from "react";
import { MultiStepDialogContext } from "~/components/dialogs/MultiStepFormDialog/context";
import {
    DialogColumn,
    DialogFooter,
} from "@cycleplatform/ui/components/dialog/components";
import { useGetTwoFactorAuthSetupMutation } from "~/services/cycle";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { NavIcons } from "~/components/layout/NavIcons";

export function EnableStep({ stepIdx = 0 }: { stepIdx?: number }) {
    const { handleNext, formKey } = useContext(MultiStepDialogContext);

    const [getTwoFaSetup, { data: twoFa }] = useGetTwoFactorAuthSetupMutation({
        fixedCacheKey: "twofa",
    });

    const dispatch = useAppDispatch();

    useEffect(() => {
        getTwoFaSetup();
    }, []);

    return (
        <>
            <div className="flex h-full w-full">
                <DialogColumn className="w-full">
                    <section className="h-full w-full">
                        <p>
                            The owner of this hub has enforced a security policy
                            that requires all members to enable two-factor auth.
                        </p>
                        <p>
                            To begin, scan the QR code below using your
                            two-factor authentication app.
                        </p>
                    </section>

                    <div className="mt-16 flex h-full w-full flex-col items-center">
                        {twoFa ? (
                            <>
                                <img
                                    className="w-64"
                                    src={`data:image/png;base64,${twoFa?.data?.qr}`}
                                />
                                <div className="text-xs">
                                    {twoFa?.data?.secret}
                                </div>
                            </>
                        ) : null}
                    </div>
                </DialogColumn>
            </div>

            <DialogFooter className="flex justify-between">
                <ButtonLink
                    to={generateDialogLink("hub-select", {})}
                    icon={NavIcons["hubs"]}
                >
                    Choose Another Hub
                </ButtonLink>
                <LoaderButton
                    onClick={() => {
                        dispatch(
                            // mark the step as completed so it knows
                            addStepData({
                                data: {},
                                idx: stepIdx,
                                key: formKey,
                            })
                        );
                        handleNext();
                    }}
                    type="button"
                    flavor="primary"
                    icon={faArrowRight}
                >
                    Next
                </LoaderButton>
            </DialogFooter>
        </>
    );
}
