import {
    faQuestionCircle,
    faPlus,
    faSync,
    faTrash,
    faHammer,
} from "@fortawesome/pro-solid-svg-icons";
import { Activity, ComponentIncludes, Stack } from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import {
    StackBuildLink,
    StackLink,
} from "~/components/common/resources/ResourceLinks";

export function formatStackBuildMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const build = (
        <StackBuildLink
            buildId={item.component?.id}
            components={components}
            text="build"
        />
    );

    const stack = (
        <StackLink stackId={item.context.stack_id} components={components} />
    );
    switch (item.event) {
        case "stack.build.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Created a {build} of stack {stack}
                    </span>
                ) : (
                    <span>Error creating build for stack {stack}</span>
                ),
                icon: faPlus,
            };
        case "stack.build.generate":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Generated this {build} for stack {stack}
                    </span>
                ) : (
                    <span>Error generating build for stack {stack}</span>
                ),
                icon: faHammer,
            };
        case "stack.build.deploy":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Deployed this {build} of stack {stack}
                    </span>
                ) : (
                    <span>
                        Error deploying this {build} of stack {stack}
                    </span>
                ),
                icon: faSync,
            };
        case "stack.build.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Deleted this {build} of stack {stack}
                    </span>
                ) : (
                    <span>
                        Error deleting this {build} of stack {stack}
                    </span>
                ),
                icon: faTrash,
            };
        case "stack.build.task.generate":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested generation of this {build} for stack {stack}
                    </span>
                ) : (
                    <span>
                        Error requesting generation of this {build} for stack{" "}
                        {stack}
                    </span>
                ),
                icon: faHammer,
            };

        case "stack.build.task.deploy":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested deployment of this {build} for stack {stack}
                    </span>
                ) : (
                    <span>
                        Error requesting deployment of this {build} for stack{" "}
                        {stack}
                    </span>
                ),
                icon: faHammer,
            };

        case "stack.build.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested to delete {build} of stack {stack}
                    </span>
                ) : (
                    <span>
                        Error requesting to delete {build} of stack {stack}
                    </span>
                ),
                icon: faTrash,
            };

        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}
