import { Button, LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    RhfFormField,
    RhfFormProvider,
    TextInput,
    RhfGlobalFormError,
    required,
    FormSectionHeader,
    FormSection,
    RHF_GLOBAL_ERROR,
} from "@cycleplatform/ui/components/forms";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { Controller, useForm, useFormContext, useWatch } from "react-hook-form";
import { rhfConfig } from "~/components/forms/util";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { addStepData, getCurrentForm } from "~/modules/forms/slice";
import { useContext, useEffect, useState } from "react";
import { MultiStepDialogContext } from "~/components/dialogs/MultiStepFormDialog/context";
import {
    DialogColumn,
    DialogFooter,
} from "@cycleplatform/ui/components/dialog/components";
import { VmNetworkStepValues } from "./types";
import { PublicNetworkSelect } from "~/components/containers/PublicNetworkSelect";
import slugify from "slugify";
import { LearnMore } from "./LearnMore";
import { ExternalLink } from "~/components/common/links";

export function VmConfigStep({ stepIdx = 0 }: { stepIdx?: number }) {
    const { handleNext, handleBack, formKey } = useContext(
        MultiStepDialogContext
    );
    const currentForm = useAppSelector((state) =>
        getCurrentForm(state, formKey)
    );

    const form = useForm<VmNetworkStepValues>({
        defaultValues: currentForm?.steps?.[stepIdx] || {
            hostname: slugify(
                (currentForm?.steps?.[0]?.name as string) || ""
            ).toLocaleLowerCase(),
            public: "egress-only",
        },
        ...rhfConfig,
    });

    const {
        handleSubmit,
        register,
        setError,
        formState: { isSubmitting },
        control,
    } = form;

    const dispatch = useAppDispatch();

    const onSubmit = async (data: VmNetworkStepValues) => {
        dispatch(addStepData({ data, idx: stepIdx, key: formKey }));
        handleNext?.();
    };
    const publicNetwork = useWatch({ name: "public", control });

    const error = currentForm?.error;

    useEffect(() => {
        if (error && error.step === stepIdx) {
            setError(RHF_GLOBAL_ERROR as never, { message: error.message });
        }
    }, [error]);

    return (
        <RhfFormProvider
            {...form}
            className="h-full"
            onSubmit={form.handleSubmit(onSubmit)}
        >
            <div className="flex">
                <DialogColumn className="w-2/3">
                    <FormSectionHeader header="Network" />
                    <FormSection>
                        <RhfFormField label="Hostname" name="hostname" required>
                            <TextInput
                                {...register("hostname", {
                                    ...required(),
                                })}
                            />
                        </RhfFormField>
                        <RhfFormField
                            label="public network"
                            name="public"
                            required
                        >
                            <Controller
                                render={({
                                    field: { ref: _ref, ...field },
                                }) => (
                                    <PublicNetworkSelect
                                        {...field}
                                        resourceName="Virtual machines"
                                        omitOptions={["disable"]}
                                    />
                                )}
                                rules={{ ...required() }}
                                control={control}
                                name="public"
                            />
                        </RhfFormField>
                    </FormSection>

                    {publicNetwork === "enable" ? (
                        <PublicNetworkingSection />
                    ) : null}
                </DialogColumn>

                <LearnMore title="Virtual Machine Networking">
                    This virtual machine will be able to communicate with other
                    containers and virtual machines within the same environment.
                    Configure whether the virtual machine has access to the
                    public internet, and what ports are exposed to the load
                    balancer here.
                </LearnMore>
            </div>

            <DialogFooter className="justify-between">
                <Button icon={faArrowLeft} onClick={() => handleBack?.()}>
                    Back
                </Button>

                <div>
                    <RhfGlobalFormError />
                </div>
                <LoaderButton
                    isLoading={isSubmitting}
                    onClick={handleSubmit(onSubmit)}
                    type="button"
                    flavor="primary"
                    icon={faArrowRight}
                >
                    Next
                </LoaderButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}

function PublicNetworkingSection() {
    return (
        <>
            <FormSectionHeader header="Public Networking" />
            <FormSection>
                <div className="flex items-center gap-8">
                    <div className="flex flex-col gap-2 text-sm">
                        <div>
                            For public networking for a virtual machine, there
                            are several options available:
                        </div>
                        <ul className="list-disc pl-5">
                            <li>
                                <strong>Assigning public IPs </strong>- This
                                option assigns a static IP to the virtual
                                machine, making it ideal for DMZ-setups that
                                require a fixed external entrypoint. To enable
                                this, allocate IPs from the virtual machine
                                dashboard, with traffic routed through the
                                gateway service. This simpler approach is
                                well-suited for services that need controlled
                                external access while maintaining network
                                isolation.
                            </li>

                            <li>
                                <strong>
                                    Use load balancer to route traffic{" "}
                                </strong>
                                - This option allows traffic to be run though
                                the load balancer, similar to how traffic is
                                routed to a container. Ports will be assigned in
                                the virtual machine config to facilitate
                                traffic. This is useful to facilitate high
                                availability or more advanced load handling
                                capabilities.
                            </li>
                        </ul>
                        {/* TODO */}
                        <ExternalLink to="https://cycle.io/docs/">
                            Check out the documentation for more information.
                        </ExternalLink>
                    </div>
                </div>
            </FormSection>
        </>
    );
}
