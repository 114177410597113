import { Image } from "~/services/cycle";
import {
    StyledTableRow,
    StyledDataTable,
} from "@cycleplatform/ui/components/tables";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive, faHdd } from "@fortawesome/pro-solid-svg-icons";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { useContext } from "react";
import { ImageDialogContext } from "../context";

export function VolumesSummary() {
    const { image, source } = useContext(ImageDialogContext);

    return (
        <Panel>
            <PanelTitle title="Volumes" />
            <PanelContent stretch>
                {image?.config?.volumes?.length ? (
                    <>
                        <StyledDataTable
                            className={classNames(
                                "h-4 w-full table-auto rounded-lg border-none  text-left"
                            )}
                        >
                            <thead className="border-b ">
                                <tr className="h-10 ">
                                    <th className="w-1/2 select-none pl-8">
                                        Path
                                    </th>
                                    <th className="w-1/2 select-none ">Mode</th>
                                </tr>
                            </thead>
                            <tbody>
                                {image.config.volumes.map((v) => (
                                    <StyledTableRow
                                        key={v.path}
                                        className="h-16 border-b p-0 last:border-none"
                                    >
                                        <td className="pl-8">
                                            <FontAwesomeIcon
                                                icon={faHdd}
                                                className="text-cycle-blue pr-4"
                                            />
                                            {v.path}
                                        </td>
                                        <td>
                                            <div>
                                                {v.mode === "ro"
                                                    ? "Read Only"
                                                    : "Read/Write"}
                                            </div>
                                        </td>
                                    </StyledTableRow>
                                ))}
                            </tbody>
                        </StyledDataTable>
                    </>
                ) : (
                    <div className="w-full py-4 text-center">
                        <FontAwesomeIcon
                            icon={faArchive}
                            className="text-cycle-blue h-10"
                        />
                        <div>No Volumes</div>
                        <div>This image has no volumes</div>
                    </div>
                )}
            </PanelContent>
        </Panel>
    );
}
