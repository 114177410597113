import { useContext } from "react";
import { MultiStepDialogContext } from "../context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faCircle,
    faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { useGetMultiStepFormValues } from "../hooks";
import { DialogFormStepTypes } from "../../dns/zone-create/types";
import { useAppSelector } from "~/hooks";
import { getCurrentForm } from "~/modules/forms/slice";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";

export function StepProgress() {
    const { stepNames } = useContext(MultiStepDialogContext);

    return (
        <div
            className={classNames(
                "grid auto-cols-fr grid-flow-col ",
                "pt-4 text-lg",
                "relative "
            )}
        >
            {stepNames.map((name, idx) => (
                <StepButton idx={idx} name={name} key={`${name}-${idx}`} />
            ))}
        </div>
    );
}

type Tense = "past" | "present" | "future";

function StepButton({ name, idx }: { name: string; idx: number }) {
    const { currentIdx } = useContext(MultiStepDialogContext);

    const tense =
        currentIdx > idx ? "past" : currentIdx === idx ? "present" : "future";

    return (
        <div className="relative w-full flex-1">
            <ProgressLine idx={idx} tense={tense} />
            <ProgressIconLink idx={idx} tense={tense} />
            <ProgressLabel label={name} />
        </div>
    );
}

function ProgressLine({ idx, tense }: { idx: number; tense: Tense }) {
    return (
        <div
            className={classNames(
                idx === 0 && "!bg-transparent",
                "z-0 h-[2px] w-full",
                tense === "future"
                    ? "dark:bg-cycle-gray-light/50 bg-cycle-gray/50"
                    : "bg-cycle-blue"
            )}
            style={{ transform: "translateX(-50%)" }}
        />
    );
}

function ProgressIconLink({ idx, tense }: { idx: number; tense: Tense }) {
    const { formKey } = useContext(MultiStepDialogContext);
    const error = useAppSelector((state) =>
        getCurrentForm(state, formKey)
    )?.error;
    const values = useGetMultiStepFormValues<DialogFormStepTypes>();
    const valuesDefined = !!values?.[idx];
    const { navigateToStep } = useContext(MultiStepDialogContext);

    return (
        <Tooltip
            message={error?.message}
            disabled={!error?.message}
            className={classNames(
                "flex w-full justify-center",
                "absolute left-1/2 bottom-6 z-30 -translate-x-1/2 transform"
            )}
        >
            <button
                onClick={() => navigateToStep(idx)}
                disabled={tense === "future"}
                className={classNames(
                    tense === "future" && "cursor-not-allowed",
                    "relative"
                )}
            >
                {/* Blank circle icon is the background to allow opacity and prevent line from showing through check icon */}
                <FontAwesomeIcon
                    icon={faCircle}
                    className="text-white dark:text-black"
                />
                <FontAwesomeIcon
                    icon={
                        error?.step === idx
                            ? faTimesCircle
                            : valuesDefined
                            ? faCheckCircle
                            : faCircle
                    }
                    className={classNames(
                        "absolute inset-0 top-1",
                        error?.step === idx && "!text-error",
                        tense === "past" && "!text-cycle-blue-accent",
                        tense === "present" && "!text-cycle-blue",
                        tense === "future" && "opacity-50",
                        tense === "future" &&
                            !valuesDefined &&
                            "dark:text-cycle-gray-light/50 text-cycle-gray/50"
                    )}
                />
            </button>
        </Tooltip>
    );
}

function ProgressLabel({ label }: { label: string }) {
    return (
        <div
            className={classNames(
                "flex w-full items-end justify-center px-4 pt-4 text-sm ",
                "capitalize"
            )}
        >
            <p className="whitespace-nowrap">{label}</p>
        </div>
    );
}
