import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    faArrowDownFromDottedLine,
    faArrowLeft,
    faArrowsRotate,
    faChartPieSimpleCircleDollar,
    faCheck,
    faCircle,
    faCircleCheck,
    faCloudArrowDown,
    faExclamationCircle,
    faFileBinary,
    faGiftCard,
    faHammer,
    faHourglass,
    faLockA,
    faMedkit,
    faMoneyBillTransfer,
    faSave,
    faSquare,
    faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { ResourceStateFull } from "./ResourceState";
import { serverHealthStateMap } from "../servers/serverHealthState";
import { faShieldXmark } from "@fortawesome/pro-duotone-svg-icons";

type ResourceStateIconProps = {
    state?: ResourceStateFull;
    className?: string;
    // Override state icons for specific current states - must provide full StateIconEntry
    stateIconOverride?: Record<string, StateIconEntry>;
};

export function ResourceStateIcon({
    state,
    className,
    stateIconOverride = {},
}: ResourceStateIconProps) {
    if (!state) {
        return null;
    }
    const stateIcon = getStateIcon(state, stateIconOverride);

    if (!stateIcon) {
        return null;
    }

    return (
        <FontAwesomeIcon
            icon={stateIcon.icon}
            className={classNames(
                className,
                "text-xs",
                state?.error && "!text-error",
                stateIcon.color === "blue" && "text-cycle-blue",
                stateIcon.color === "purple" && "text-cycle-purple",
                stateIcon.color === "green" && "text-success",
                stateIcon.color === "orange" && "text-warning",
                stateIcon.color === "gray" &&
                    "dark:text-cycle-black-accent/50 text-[#8b8b8b]",
                stateIcon.color === "red" && "text-error",
                stateIcon.animation === "pulse" && "animate-pulse",
                stateIcon.animation === "spin" && "animate-spin"
            )}
        />
    );
}

export type StateIconEntry = {
    icon: IconProp;
    color: "blue" | "green" | "red" | "orange" | "gray" | "purple";
    animation?: "spin" | "pulse";
};

function getStateIcon(
    state: NonNullable<ResourceStateIconProps["state"]>,
    stateIconOverride: Record<string, StateIconEntry>
): StateIconEntry | undefined {
    if (state.error) {
        return StateIconMap["error"];
    }

    return { ...StateIconMap, ...stateIconOverride }[state.current];
}

// For animation, transitory states (start[ing] stop[ing]) are pulsing
// Similarly, the resourceStatePieChart will be striped for these states
export const StateIconMap: Record<string, StateIconEntry> = {
    new: {
        icon: faCircle,
        color: "blue",
    },
    pending: {
        icon: faCircle,
        color: "blue",
    },
    acquiring: {
        icon: faLockA,
        color: "blue",
    },
    configuring: {
        icon: faCircle,
        color: "blue",
        animation: "pulse",
    },
    live: {
        icon: faCircle,
        color: "green",
    },
    healthy: {
        icon: faCircle,
        color: "green",
    },
    offline: {
        icon: faCircle,
        color: "red",
    },
    online: {
        icon: faCircle,
        color: "green",
    },
    decomissioned: {
        icon: faCircle,
        color: "gray",
    },
    deprecated: {
        icon: faArrowDownFromDottedLine,
        color: "gray",
    },
    disabled: {
        icon: faCircle,
        color: "gray",
    },
    deleting: {
        icon: faCircle,
        color: "red",
        animation: "pulse",
    },
    deleted: {
        icon: faCircle,
        color: "red",
    },
    error: {
        icon: faExclamationCircle,
        color: "red",
    },
    stopped: {
        icon: faSquare,
        color: "gray",
    },
    stopping: {
        icon: faCircle,
        color: "gray",
        animation: "pulse",
    },
    starting: {
        icon: faCircle,
        color: "green",
        animation: "pulse",
    },
    available: {
        icon: faCircle,
        color: "green",
    },
    running: {
        icon: faCircle,
        color: "green",
    },
    paid: {
        icon: faCheck,
        color: "green",
    },
    credited: {
        icon: faGiftCard,
        color: "green",
    },
    verifying: {
        icon: faCircle,
        color: "blue",
        animation: "pulse",
    },
    saving: {
        icon: faSave,
        color: "blue",
        animation: "pulse",
    },
    importing: {
        icon: faCircle,
        color: "blue",
        animation: "pulse",
    },
    downloading: {
        icon: faCloudArrowDown,
        color: "blue",
        animation: "pulse",
    },
    building: {
        icon: faHammer,
        color: "blue",
        animation: "pulse",
    },
    unhealthy: {
        icon: faMedkit,
        color: "orange",
    },
    provisioning: {
        icon: faArrowsRotate,
        color: "blue",
        animation: "spin",
    },
    releasing: {
        icon: faCircle,
        color: "gray",
        animation: "pulse",
    },
    released: {
        icon: faCircle,
        color: "gray",
    },
    failed: {
        icon: faCircle,
        color: "red",
    },
    migrating: {
        icon: faArrowsRotate,
        color: "orange",
        animation: "spin",
    },
    migrated: {
        icon: faCircle,
        color: "orange",
    },
    complete: {
        icon: faCircle,
        color: "green",
    },
    completed: {
        icon: faCircleCheck,
        color: "green",
    },
    accepted: {
        icon: faCircle,
        color: "green",
    },
    reimaging: {
        icon: faFileBinary,
        color: "orange",
        animation: "pulse",
    },
    processing: {
        icon: faCircle,
        color: "blue",
        animation: "pulse",
    },
    function: {
        icon: faCircle,
        color: "purple",
    },
    voided: {
        icon: faTimes,
        color: "gray",
    },
    ["partially-paid"]: {
        icon: faChartPieSimpleCircleDollar,
        color: "orange",
    },
    ["awaiting-funds"]: {
        icon: faMoneyBillTransfer,
        color: "purple",
    },
    ["pending-verification"]: {
        icon: faHourglass,
        color: "orange",
    },
    ["refunded"]: {
        icon: faArrowLeft,
        color: "orange",
    },
    [serverHealthStateMap["healthy"]]: {
        icon: faCircle,
        color: "green",
    },
    [serverHealthStateMap["unhealthy"]]: {
        icon: faExclamationCircle,
        color: "orange",
    },
    [serverHealthStateMap["unknown"]]: {
        icon: faCircle,
        color: "gray",
    },
    [serverHealthStateMap["offline"]]: {
        icon: faCircle,
        color: "red",
    },
    unhealthyInstance: {
        icon: faMedkit,
        color: "orange",
    },
    healthyInstance: {
        icon: faMedkit,
        color: "green",
    },
    unknownHealthInstance: {
        icon: faMedkit,
        color: "gray",
    },
    published: {
        icon: faCircle,
        color: "green",
    },
    approved: {
        icon: faCircle,
        color: "purple",
    },
    quarantined: {
        icon: faShieldXmark,
        color: "purple",
    },
};
