import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";
import { FormField } from "@cycleplatform/ui/components/forms";
import graphic from "@cycleplatform/ui/images/icons/gridless/infrastructure.svg";

export function VirtualProviderIsoAside() {
    return (
        <DialogColumn className="w-1/3">
            <img className="max-w-xs" src={graphic} />
            <div className="mt-4 text-lg">
                <h3>Create Provider ISO</h3>

                <FormField label="learn more:">
                    <ul>
                        <li>
                            <a
                                className="text-cycle-blue text-sm"
                                href="https://cycle.io/docs/platform/scaling-and-auto-scaling" //TODO
                                target="_blank"
                                rel="noreferrer"
                            >
                                <div>
                                    Documentation: Booting a Virtual Provider
                                    ISO
                                </div>
                            </a>
                        </li>
                    </ul>
                </FormField>
            </div>
        </DialogColumn>
    );
}
