import {
    Checkbox,
    FormSection,
    FormSectionHeader,
    InputRow,
    PillButtons,
    RhfFormField,
    RhfSliderInput,
} from "@cycleplatform/ui/components/forms";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { VmConfigFormData } from "../types";
import { CoresSelect } from "~/components/containers/CoresSelect";

export function VmResourcesSection() {
    return (
        <Panel>
            <PanelTitle title="Resources" className="flex justify-start px-4" />
            <PanelContent>
                <CpuResources />
                <RamResources />
            </PanelContent>
        </Panel>
    );
}

function RamResources() {
    const form = useFormContext<VmConfigFormData>();
    const { register, control } = form;

    const limit = useWatch({ name: "config.resources.ram.limit", control });

    if (!limit) {
        return null;
    }

    return (
        <>
            <FormSectionHeader header="RAM Resources" />
            <FormSection>
                <InputRow className="items-center gap-8">
                    <RhfFormField
                        label="limit (GB)"
                        name="config.resources.ram.limit"
                        required
                    >
                        <RhfSliderInput
                            min={1}
                            max={16}
                            numTicks={5}
                            {...register(`config.resources.ram.limit`, {
                                valueAsNumber: true,
                            })}
                        />
                    </RhfFormField>
                    <RhfFormField
                        label="Allocate"
                        name="config.resources.ram.allocate"
                        className="!w-[10rem]"
                    >
                        <Checkbox
                            {...register("config.resources.ram.allocate")}
                        />
                    </RhfFormField>
                </InputRow>
            </FormSection>
        </>
    );
}

function CpuResources() {
    const form = useFormContext<VmConfigFormData>();

    const {
        register,
        control,
        formState: { isSubmitting, isDirty },
    } = form;

    const active = useWatch({ name: "config.resources.cpu.active", control });

    return (
        <>
            <FormSectionHeader header="CPU Resources">
                <Controller
                    name="config.resources.cpu.active"
                    control={control}
                    render={({ field: { ref: _ref, ...field } }) => {
                        return (
                            <PillButtons
                                {...field}
                                options={[
                                    { label: "Cores", value: "cores" },
                                    {
                                        label: "Cpuset",
                                        value: "cpu",
                                    },
                                ]}
                            />
                        );
                    }}
                ></Controller>
            </FormSectionHeader>
            <FormSection>
                <InputRow className="items-center gap-8">
                    {active === "cores" ? (
                        <>
                            <RhfFormField
                                label="cores"
                                name="config.resources.cpu.cores"
                                required
                            >
                                <RhfSliderInput
                                    min={1}
                                    max={16}
                                    numTicks={5}
                                    {...register(
                                        `config.resources.cpu.cores.number`,
                                        {
                                            valueAsNumber: true,
                                        }
                                    )}
                                />
                            </RhfFormField>

                            <RhfFormField
                                label="Allocate"
                                name="config.resources.cpu.cores.allocate"
                                className="!w-[10rem]"
                            >
                                <Checkbox
                                    {...register(
                                        "config.resources.cpu.cores.allocate"
                                    )}
                                />
                            </RhfFormField>
                        </>
                    ) : (
                        <RhfFormField
                            label="CPUset"
                            name="config.resources.cpu.cpus"
                            className="w-full"
                        >
                            <Controller
                                render={({
                                    field: { ref: _ref, ...field },
                                }) => <CoresSelect {...field} />}
                                control={control}
                                name="config.resources.cpu.cpus"
                            />
                        </RhfFormField>
                    )}
                </InputRow>
            </FormSection>
        </>
    );
}
