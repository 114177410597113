import { Button, LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    RhfFormField,
    RhfFormProvider,
    TextInput,
    RhfGlobalFormError,
    required,
    FormSectionHeader,
    FormSection,
    PillButtons,
    TextAreaInput,
    RHF_GLOBAL_ERROR,
} from "@cycleplatform/ui/components/forms";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { Controller, useForm, useWatch } from "react-hook-form";
import { rhfConfig } from "~/components/forms/util";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { addStepData, getCurrentForm } from "~/modules/forms/slice";
import { useContext, useEffect } from "react";
import { MultiStepDialogContext } from "~/components/dialogs/MultiStepFormDialog/context";
import {
    DialogColumn,
    DialogFooter,
} from "@cycleplatform/ui/components/dialog/components";
import { VmImageStepValues } from "./types";
import { OptionButton } from "@cycleplatform/ui/components/options";
import { VmBaseImageSelect } from "~/components/vms/forms/VmBaseImageSelect";
import { LearnMore } from "./LearnMore";
import { BetaBadge } from "@cycleplatform/ui/components/badges";

export function VmImageStep({ stepIdx = 0 }: { stepIdx?: number }) {
    const { handleNext, handleBack, formKey } = useContext(
        MultiStepDialogContext
    );
    const currentForm = useAppSelector((state) =>
        getCurrentForm(state, formKey)
    );

    const form = useForm<VmImageStepValues>({
        defaultValues: currentForm?.steps?.[stepIdx] || {
            type: "base",
            details: {},
            ipxesrc: "url",
        },
        ...rhfConfig,
    });

    const {
        handleSubmit,
        register,
        setError,
        unregister,
        formState: { isSubmitting, isDirty },
        control,
    } = form;

    const dispatch = useAppDispatch();

    const onSubmit = async (data: VmImageStepValues) => {
        if (!isDirty && !currentForm?.steps?.[stepIdx]) {
            return;
        }
        dispatch(addStepData({ data, idx: stepIdx, key: formKey }));
        handleNext?.();
    };

    const error = currentForm?.error;

    useEffect(() => {
        if (error && error.step === stepIdx) {
            setError(RHF_GLOBAL_ERROR as never, { message: error.message });
        }
    }, [error]);

    const sourceType = useWatch({
        control,
        name: "type",
    });

    const ipxeType = useWatch({
        control,
        name: "ipxesrc",
    });

    return (
        <RhfFormProvider
            {...form}
            className="h-full"
            onSubmit={form.handleSubmit(onSubmit)}
        >
            <div className="flex">
                <DialogColumn className="w-2/3">
                    <div className="flex gap-8">
                        <RhfFormField name="type" className="!w-2/5">
                            <div className="flex flex-col gap-2">
                                <Controller
                                    name={"type"}
                                    control={control}
                                    rules={{
                                        ...required(),
                                    }}
                                    render={({
                                        field: { ref: _ref, ...field },
                                    }) => {
                                        return (
                                            <OptionButton
                                                onClick={() =>
                                                    field.onChange("base")
                                                }
                                                title="Cycle Base Image"
                                                description="Boot the VM using an image provided by Cycle."
                                                isActive={
                                                    field.value === "base"
                                                }
                                            />
                                        );
                                    }}
                                />

                                <Controller
                                    name={"type"}
                                    control={control}
                                    render={({
                                        field: { ref: _ref, ...field },
                                    }) => {
                                        return (
                                            <OptionButton
                                                onClick={() =>
                                                    field.onChange("url")
                                                }
                                                title="Image From URL"
                                                description="Boot the VM using an image hosted at a remote URL."
                                                isActive={field.value === "url"}
                                            />
                                        );
                                    }}
                                />

                                <Controller
                                    name={"type"}
                                    control={control}
                                    render={({
                                        field: { ref: _ref, ...field },
                                    }) => {
                                        return (
                                            <OptionButton
                                                disabled
                                                onClick={() =>
                                                    field.onChange("ipxe")
                                                }
                                                title={
                                                    <div className="flex items-center">
                                                        <div className="flex-1">
                                                            Boot From IPXE{" "}
                                                        </div>
                                                    </div>
                                                }
                                                description={
                                                    <div className="flex flex-col">
                                                        <div className="pb-1">
                                                            Boot the VM using an
                                                            IPXE script.
                                                        </div>
                                                        <div>
                                                            <BetaBadge
                                                                labelOverride="COMING SOON"
                                                                className="!ml-0"
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                isActive={
                                                    field.value === "ipxe"
                                                }
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </RhfFormField>
                        <div className="w-3/5">
                            {sourceType === "base" && (
                                <>
                                    <FormSectionHeader header="Cycle Base Image" />
                                    <FormSection>
                                        <RhfFormField
                                            label="Base Image"
                                            name="details.identifier"
                                            required
                                        >
                                            <Controller
                                                name={"details.identifier"}
                                                control={control}
                                                rules={{
                                                    ...required(),
                                                }}
                                                render={({
                                                    field: {
                                                        ref: _ref,
                                                        ...field
                                                    },
                                                }) => {
                                                    return (
                                                        <VmBaseImageSelect
                                                            {...field}
                                                            value={
                                                                field.value ||
                                                                ""
                                                            }
                                                        />
                                                    );
                                                }}
                                            />
                                        </RhfFormField>
                                    </FormSection>
                                </>
                            )}
                            {sourceType === "url" && (
                                <>
                                    <FormSectionHeader header="URL" />
                                    <FormSection>
                                        <RhfFormField
                                            label="URL"
                                            name="details.url"
                                            required
                                        >
                                            <TextInput
                                                {...register("details.url", {
                                                    ...required(),
                                                })}
                                            />
                                        </RhfFormField>
                                    </FormSection>
                                </>
                            )}

                            {sourceType === "ipxe" && (
                                <>
                                    <FormSectionHeader header="IPXE Script">
                                        <div>
                                            <Controller
                                                name={"ipxesrc"}
                                                control={control}
                                                render={({
                                                    field: {
                                                        ref: _ref,
                                                        ...field
                                                    },
                                                }) => {
                                                    return (
                                                        <PillButtons
                                                            {...field}
                                                            onChange={(v) => {
                                                                field.onChange(
                                                                    v
                                                                );
                                                                if (
                                                                    v === "url"
                                                                ) {
                                                                    unregister(
                                                                        "details.script"
                                                                    );
                                                                } else {
                                                                    unregister(
                                                                        "details.chain_url"
                                                                    );
                                                                }
                                                            }}
                                                            options={[
                                                                {
                                                                    value: "url",
                                                                    label: "From URL",
                                                                },
                                                                {
                                                                    value: "script",
                                                                    label: "Pasted",
                                                                },
                                                            ]}
                                                        />
                                                    );
                                                }}
                                            />
                                        </div>
                                    </FormSectionHeader>
                                    <FormSection>
                                        {ipxeType === "url" && (
                                            <RhfFormField
                                                label="URL"
                                                name="details.chain_url"
                                                required
                                            >
                                                <TextInput
                                                    {...register(
                                                        "details.chain_url",
                                                        {
                                                            ...required(),
                                                        }
                                                    )}
                                                />
                                            </RhfFormField>
                                        )}

                                        {ipxeType === "script" && (
                                            <RhfFormField
                                                label="Script"
                                                name="details.script"
                                                required
                                            >
                                                <TextAreaInput
                                                    rows={10}
                                                    {...register(
                                                        "details.script",
                                                        {
                                                            ...required(),
                                                        }
                                                    )}
                                                />
                                            </RhfFormField>
                                        )}
                                    </FormSection>
                                </>
                            )}
                        </div>
                    </div>
                </DialogColumn>

                <LearnMore title="Selecting an Image">
                    <p>
                        Choose what bootable image the virtual machine will use
                        to start.
                    </p>
                    <ul className="mt-1 [&>li]:mb-1">
                        <li>
                            <strong>Cycle Base Image</strong> - An image
                            provided by Cycle
                        </li>
                        <li>
                            <strong>Image From URL</strong> - Enter a URL for a
                            remote image Cycle will pull in when the virtual
                            machine is created.
                        </li>
                        <li>
                            <strong>Boot From IPXE</strong> - Enter a URL that
                            points to an IPXE script, or enter one manually.
                            Cycle will use this script when booting the virtual
                            machine.
                        </li>
                    </ul>
                </LearnMore>
            </div>

            <DialogFooter className="justify-between">
                <Button icon={faArrowLeft} onClick={() => handleBack?.()}>
                    Back
                </Button>

                <div>
                    <RhfGlobalFormError />
                </div>
                <LoaderButton
                    isLoading={isSubmitting}
                    onClick={handleSubmit(onSubmit)}
                    type="button"
                    flavor="primary"
                    icon={faArrowRight}
                    disabled={!isDirty && !currentForm?.steps?.[stepIdx]}
                >
                    Next
                </LoaderButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}
