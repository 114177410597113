import {
    TextInput,
    RhfFormField,
    required,
    Checkbox,
    isDurationString,
} from "@cycleplatform/ui/components/forms";
import { Controller, useFormContext } from "react-hook-form";
import { BasicSelect } from "@cycleplatform/ui/components/forms/select";
import {
    faArrowUpRightFromSquare,
    faInfoCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useState } from "react";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { RhfIdentifierInput } from "~/components/forms/RhfIdentifierInput";
import { LoadBalancerFormValues } from "../../../../../form";

export function V1GeneralSection({
    baseRoute,
}: {
    baseRoute: `config.details.controllers.${number}`;
}) {
    const { control, register } = useFormContext<LoadBalancerFormValues>();

    return (
        <Panel>
            <PanelTitle
                title={
                    <div className="flex items-center gap-2">
                        <p>General</p>
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="text-cycle-blue text-xs"
                        />
                        <Link
                            className="text-sm"
                            to={"https://cycle.io/docs/portal/configuring-v1"}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Config Details{" "}
                            <FontAwesomeIcon
                                icon={faArrowUpRightFromSquare}
                                className="text-cycle-blue text-xs"
                            />
                        </Link>
                    </div>
                }
            />
            <PanelContent>
                <div className="flex gap-4">
                    <RhfIdentifierInput
                        name={`${baseRoute}.identifier`}
                        label="Identifier"
                        required
                    />

                    <RhfFormField
                        label="Ingress Port"
                        name={`${baseRoute}.port`}
                        required
                    >
                        <TextInput
                            type="number"
                            disabled
                            {...register(`${baseRoute}.port`, {
                                valueAsNumber: true,
                                ...required(),
                            })}
                        />
                    </RhfFormField>
                </div>
                <div className="flex gap-4">
                    <ModeSelect baseRoute={baseRoute} />
                    <RhfFormField
                        label="Logging Verbosity"
                        name={`${baseRoute}.transport.config.verbosity`}
                        required
                    >
                        <Controller
                            render={({ field: { ref: _ref, ...field } }) => (
                                <BasicSelect
                                    {...field}
                                    options={[
                                        {
                                            label: "Low",
                                            value: "low",
                                        },
                                        {
                                            label: "Normal",
                                            value: "normal",
                                        },
                                        {
                                            label: "High",
                                            value: "high",
                                        },
                                        {
                                            label: "Debug",
                                            value: "debug",
                                        },
                                    ]}
                                />
                            )}
                            defaultValue="normal"
                            control={control}
                            name={`${baseRoute}.transport.config.verbosity`}
                        />
                    </RhfFormField>
                </div>
                <div className="flex gap-4">
                    <RhfFormField
                        label="Idle Timeout"
                        name={`${baseRoute}.transport.config.timeouts.idle`}
                        required
                    >
                        <TextInput
                            {...register(
                                `${baseRoute}.transport.config.timeouts.idle`,
                                {
                                    ...required(),
                                    validate: {
                                        ...isDurationString(),
                                    },
                                }
                            )}
                        />
                    </RhfFormField>
                    <div className="flex w-full gap-4">
                        <RhfFormField
                            label="Performance"
                            name={`${baseRoute}.transport.config.performance`}
                            help="If performance mode is enabled, some telemetry is disabled to dedicate all processing to routing"
                        >
                            <Checkbox
                                {...register(
                                    `${baseRoute}.transport.config.performance`
                                )}
                            />
                        </RhfFormField>

                        <RhfFormField
                            label="TLS Enable"
                            name={`${baseRoute}.transport.config.ingress.tls.enable`}
                        >
                            <Checkbox
                                {...register(
                                    `${baseRoute}.transport.config.ingress.tls.enable`
                                )}
                            />
                        </RhfFormField>

                        <RhfFormField
                            label="disable port"
                            name={`${baseRoute}.transport.disable`}
                        >
                            <Checkbox
                                {...register(`${baseRoute}.transport.disable`)}
                            />
                        </RhfFormField>
                    </div>
                </div>
            </PanelContent>
        </Panel>
    );
}

function ModeSelect({
    baseRoute,
}: {
    baseRoute: `config.details.controllers.${number}`;
}) {
    const { control } = useFormContext<LoadBalancerFormValues>();

    const [isDisabled, setIsDisabled] = useState(true);

    return (
        <RhfFormField
            label="Mode"
            name={`${baseRoute}.transport.mode`}
            className="w-full"
            required
        >
            <div className="flex items-center gap-4">
                <Tooltip
                    message={
                        "Changing mode will reset all extensions for this controller"
                    }
                >
                    <Checkbox
                        checked={!isDisabled}
                        onChange={(v) => setIsDisabled(!v.target.checked)}
                    />
                </Tooltip>
                <Controller
                    render={({ field: { ref: _ref, ...field } }) => (
                        <BasicSelect
                            {...field}
                            disabled={isDisabled}
                            options={[
                                {
                                    label: "HTTP",
                                    value: "http",
                                },
                                {
                                    label: "TCP",
                                    value: "tcp",
                                },
                                {
                                    label: "UDP",
                                    value: "udp",
                                },
                            ]}
                        />
                    )}
                    defaultValue="http"
                    rules={{ ...required() }}
                    control={control}
                    name={`${baseRoute}.transport.mode`}
                />
            </div>
        </RhfFormField>
    );
}
