import {
    ResourceDialogTab,
    StyledResourceDialog,
} from "@cycleplatform/ui/components/dialog";
import { SkeletonHeader } from "@cycleplatform/ui/components/loaders/skeleton";
import { faDashboard, faUserLock } from "@fortawesome/pro-duotone-svg-icons";
import { useNavigate } from "react-router-dom";
import { CycleErrorBoundary } from "~/components/common/errors";
import {
    clearDialogParams,
    getAllDialogSearchParams,
    isDialogOpen,
} from "~/components/dialogs/helpers";
import { useGetContainerQuery, useGetEnvironmentQuery } from "~/services/cycle";
import { DashboardTab } from "./dashboard/DashboardTab";
import { AuthTab } from "./auth/AuthTab";
import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import { preprocessCountsData } from "@cycleplatform/ui/components/charts";
import { ContainerStateControls } from "~/components/containers/controls";
import { ServiceContainerBadge } from "~/components/containers/ServiceContainerBadge";
import { InstancesTab } from "./instances/InstancesTab";
import { NavIcons } from "~/components/layout/NavIcons";
import { ContainerDialogContext } from "~/components/dialogs/containers/container/context";
import { useVerifyAccess } from "~/modules/access";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { ResourceStatePieChart } from "~/components/common/charts/ResourceStatePieChart";

export function ManageVpnDialog() {
    const params = getAllDialogSearchParams<"environment-vpn-manage">();
    const isOpen = isDialogOpen("environment-vpn-manage");
    const navigate = useNavigate();

    const { currentData: environment, error } = useGetEnvironmentQuery(
        {
            environmentId: params["dialog-env"],
        },
        { skip: !params["dialog-env"] }
    );

    const { data: vpnContainer, error: vpnError } = useGetContainerQuery(
        {
            containerId: environment?.data?.services?.vpn?.container_id || "",
            meta: ["ips", "instances_count"],
            include: ["environments"],
        },
        {
            skip: !environment?.data?.services?.vpn?.container_id,
        }
    );
    const isVpnEnabled = environment?.data?.services.vpn?.enable || false;

    const accessErr = useVerifyAccess(
        environment?.data,
        modifyAccessFn("environments-services-manage")
    );

    if (error) {
        throw error;
    }

    if (vpnError) {
        throw vpnError;
    }

    return (
        <ContainerDialogContext.Provider
            value={{ environment: environment?.data, image: undefined }}
        >
            <StyledResourceDialog
                open={isOpen}
                onClose={() => navigate(clearDialogParams())}
                header={
                    <div className="flex w-full items-center justify-between">
                        <div className="flex items-center gap-4">
                            <h1>
                                {environment?.data?.name ? (
                                    <>{environment?.data?.name}: VPN</>
                                ) : (
                                    <SkeletonHeader size="lg" />
                                )}
                            </h1>
                            <ResourceStateBadge
                                className="!bg-cycle-gray !h-8"
                                state={vpnContainer?.data?.state}
                            />
                            <ServiceContainerBadge />
                            <div className="h-10 w-10">
                                <ResourceStatePieChart
                                    data={preprocessCountsData(
                                        vpnContainer?.data?.meta
                                            ?.instances_count
                                    )}
                                    centerTextClassName="text-cycle-white"
                                />
                            </div>
                        </div>

                        <ContainerStateControls
                            container={vpnContainer?.data}
                            hide={["delete"]}
                            environment={environment?.data}
                        />
                    </div>
                }
                breadcrumb={[
                    {
                        label: "Environments",
                        link: "environments",
                    },
                    {
                        label: environment?.data ? environment.data.name : "",
                        link: `environments/${environment?.data?.id}`,
                    },
                    {
                        label: "Services",
                    },
                    {
                        label: "Manage VPN Service",
                    },
                ]}
            >
                <ResourceDialogTab
                    to={null}
                    name="Dashboard"
                    icon={faDashboard}
                >
                    <DashboardTab />
                </ResourceDialogTab>
                <ResourceDialogTab to="auth" name="Auth" icon={faUserLock}>
                    <CycleErrorBoundary>
                        <AuthTab />
                    </CycleErrorBoundary>
                </ResourceDialogTab>
                <ResourceDialogTab
                    to="instances"
                    name="Instances"
                    icon={NavIcons["instances"]}
                >
                    <CycleErrorBoundary>
                        <InstancesTab
                            container={vpnContainer?.data}
                            containerId={
                                environment?.data?.services.vpn?.container_id ||
                                ""
                            }
                            instanceId={params["dialog-instance-id"]}
                        />
                    </CycleErrorBoundary>
                </ResourceDialogTab>
            </StyledResourceDialog>
        </ContainerDialogContext.Provider>
    );
}
