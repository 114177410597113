import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";
import { FormField } from "@cycleplatform/ui/components/forms";
import bareMetalServersGraphic from "@cycleplatform/ui/images/icons/gridless/virtual-machines.svg";
import { ExternalLink } from "~/components/common/links";

type LearnMoreProps = {
    title: string;
    children: React.ReactNode;
};

export function LearnMore({ title, children }: LearnMoreProps) {
    return (
        <DialogColumn className="w-1/3 text-2xl">
            <img className="max-w-xs " src={bareMetalServersGraphic} />
            <div className="mt-4 text-lg">
                <h3>{title}</h3>
                <div className="mb-4 text-sm">{children}</div>
                <FormField label="learn more:">
                    <ul>
                        <li>
                            <ExternalLink
                                className="text-sm"
                                to="https://cycle.io/docs/platform/introduction-to-virtual-machines"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Virtual Machines Overview
                            </ExternalLink>
                        </li>
                        <li>
                            <ExternalLink
                                className="text-sm"
                                to="https://cycle.io/docs/portal/virtual-machine-configuration-overview"
                            >
                                Configuring a Virtual Machine
                            </ExternalLink>
                        </li>
                    </ul>
                </FormField>
            </div>
        </DialogColumn>
    );
}
