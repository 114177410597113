import { Button, LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    RhfFormProvider,
    RhfGlobalFormError,
    RHF_GLOBAL_ERROR,
    FormSection,
    FormSectionHeader,
} from "@cycleplatform/ui/components/forms";
import {
    faArrowLeft,
    faExternalLink,
    faPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { useForm } from "react-hook-form";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import {
    CreateDnsZoneApiArg,
    useCreateDnsZoneJobMutation,
    useCreateDnsZoneMutation,
} from "~/services/cycle";
import { createAclSubmitBody } from "~/components/common/acl";
import { useContext } from "react";
import { MultiStepDialogContext } from "~/components/dialogs/MultiStepFormDialog/context";
import { useGetMultiStepFormValues } from "~/components/dialogs/MultiStepFormDialog/hooks";
import { useNavigate } from "react-router-dom";
import { isCycleApiError } from "~/services/helpers";
import { useAppDispatch } from "~/hooks";
import { setError } from "~/modules/forms/slice";
import { DialogFormStepTypes } from "../../types";
import { ConfigurationRecords } from "../../ConfigurationRecords";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getSubdomains } from "../step3/components/hosted/manageHostedDomain/helpers";
import {
    DialogColumn,
    DialogFooter,
} from "@cycleplatform/ui/components/dialog/components";

type FormType = CreateDnsZoneApiArg["body"];

export function Review() {
    const nav = useNavigate();
    const dispatch = useAppDispatch();

    const { handleBack, onClose, formKey } = useContext(MultiStepDialogContext);
    const values = useGetMultiStepFormValues<DialogFormStepTypes>();

    const isHosted = values[1].hosted;

    const form = useForm<FormType>({
        defaultValues: {
            origin: values[0].origin,
            hosted: values[1].hosted,
            ...createAclSubmitBody(values[0]?.acl, "create"),
        },
        ...rhfConfig,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = form;

    const [createDnsZone] = useCreateDnsZoneMutation();
    const [dnsZoneVerify] = useCreateDnsZoneJobMutation();

    const onSubmit = async (data: FormType) => {
        // For hosted, create the DNS zone and then verify the DNS zone
        if (data.hosted) {
            return createDnsZone({
                body: data,
            })
                .unwrap()
                .then((z) => {
                    dnsZoneVerify({
                        zoneId: z.data.id,
                        body: {
                            action: "verify",
                        },
                    }).unwrap();

                    return z.data.id;
                })
                .then((zoneId) => {
                    onClose?.();
                    nav(`/dns/zones/${zoneId}`);
                })
                .catch((err) => {
                    handleSubmitError(form.setError, {
                        sourceOverride: { origin: RHF_GLOBAL_ERROR },
                    })(err);

                    if (isCycleApiError(err)) {
                        dispatch(setError({ data: err, key: formKey }));
                    }
                });
        }

        // For non hosted, just create the DNS zone
        return createDnsZone({
            body: data,
        })
            .unwrap()
            .then((z) => {
                onClose?.();
                nav(`/dns/zones/${z.data.id}`);
            })
            .catch((err) => {
                handleSubmitError(form.setError, {
                    sourceOverride: { origin: RHF_GLOBAL_ERROR },
                })(err);

                if (isCycleApiError(err)) {
                    dispatch(setError({ data: err, key: formKey }));
                }
            });
    };

    return (
        <RhfFormProvider
            {...form}
            className="h-full"
            onSubmit={form.handleSubmit(onSubmit)}
        >
            <div className="flex ">
                <DialogColumn className="w-2/3">
                    <div className="pb-2">Please confirm the following:</div>
                    <FormSection className="pl-8">
                        <ul className="flex list-disc flex-col gap-2">
                            <Origin values={values} />
                            <Hosted values={values} />
                            <Authoritative values={values} />
                        </ul>
                    </FormSection>
                    <Configuration values={values} />
                </DialogColumn>
                <DialogColumn className="w-1/3 text-lg">
                    <FormSectionHeader header="Learn More:" />
                    <FormSection>
                        <ul className="flex flex-col gap-2 text-sm">
                            <li>
                                <a
                                    href={
                                        "https://cycle.io/docs/platform/dns-zones"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex items-center gap-2 "
                                >
                                    Platform Docs: DNS Zones
                                    <FontAwesomeIcon
                                        icon={faExternalLink}
                                        className="text-xs"
                                    />
                                </a>
                            </li>

                            <li>
                                <a
                                    href={
                                        "https://cycle.io/docs/platform/dns-records"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex items-center gap-2 "
                                >
                                    Platform Docs: DNS Records
                                    <FontAwesomeIcon
                                        icon={faExternalLink}
                                        className="text-xs"
                                    />
                                </a>
                            </li>

                            <li>
                                <a
                                    href={
                                        "https://cycle.io/docs/portal/creating-a-dns-record"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex items-center gap-2 "
                                >
                                    Portal Docs: Managing a DNS Zone
                                    <FontAwesomeIcon
                                        icon={faExternalLink}
                                        className="text-xs"
                                    />
                                </a>
                            </li>

                            <li>
                                <a
                                    href={
                                        "https://cycle.io/docs/portal/creating-a-dns-record"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex items-center gap-2 "
                                >
                                    Portal Docs: Creating a DNS Record
                                    <FontAwesomeIcon
                                        icon={faExternalLink}
                                        className="text-xs"
                                    />
                                </a>
                            </li>
                        </ul>
                    </FormSection>
                </DialogColumn>
            </div>

            <DialogFooter className="justify-between ">
                <Button icon={faArrowLeft} onClick={() => handleBack?.()}>
                    Back
                </Button>
                <div>
                    <RhfGlobalFormError />
                </div>
                <LoaderButton
                    isLoading={isSubmitting}
                    onClick={handleSubmit(onSubmit)}
                    type="button"
                    flavor="primary"
                    icon={faPlus}
                >
                    {isHosted ? <>Create and Verify</> : <>Create Zone</>}
                </LoaderButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}

function Origin({ values }: { values: DialogFormStepTypes }) {
    return (
        <li>
            A zone will be created for <strong>{values[0].origin}</strong>.
        </li>
    );
}

function Hosted({ values }: { values: DialogFormStepTypes }) {
    return (
        <li>
            The zone is{" "}
            {values[1].hosted ? (
                <>
                    <strong>hosted</strong> (Cycle will manage this domain).
                </>
            ) : (
                <>
                    <strong>non-hosted</strong> (DNS managed outside of Cycle).
                </>
            )}
        </li>
    );
}

function Authoritative({ values }: { values: DialogFormStepTypes }) {
    if (!values[1].hosted) {
        return null;
    }

    if (values[2].isAuthoritative) {
        return (
            <li>
                The zone is given <strong>authoritative access</strong> and
                records can be created directly.
            </li>
        );
    }
    return (
        <li>
            The zone is <strong>not granted</strong> authoritative access, which
            means it can only create records for subdomains of the origin.
        </li>
    );
}

function Configuration({ values }: { values: DialogFormStepTypes }) {
    if (!values[1].hosted) {
        return null;
    }

    const originSubdomain = getSubdomains(values[0].origin).subdomains;

    const isAuthoritativeOrigin =
        !originSubdomain && !!values[2].isAuthoritative;

    return (
        <div className="pt-4">
            <div className="pb-2">
                {isAuthoritativeOrigin
                    ? "You have configured the following nameservers for this domain:"
                    : "  You have configured the following records in your DNS provider:"}
            </div>
            <FormSection className="[&_h3]:hidden">
                <ConfigurationRecords
                    isHosted={values[1].hosted}
                    isAuthoritative={values[2].isAuthoritative}
                    origin={values[0].origin}
                />
            </FormSection>
        </div>
    );
}
