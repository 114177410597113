import {
    minLength,
    required,
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { useForm } from "react-hook-form";
import { useCreateVirtualMachineJobMutation } from "~/services/cycle";
import {
    InfoPanel,
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { SkeletonButton } from "@cycleplatform/ui/components/loaders/skeleton";
import { useContext } from "react";
import { AccessControlOverlay } from "~/components/common/buttons";
import { AccessControlledSection } from "~/components/layout/AccessControlledSection";
import { VirtualMachineDialogContex } from "../../../context";
import { vmModifyAccessFn } from "@cycleplatform/core/modules/vms/acls";
import { useJobTracker } from "~/modules/jobs/hooks";

type UpdatePasswordForm = {
    password: string;
};

export function VmUpdateRootPasswordPanel() {
    const { vm, environment } = useContext(VirtualMachineDialogContex);

    const form = useForm<UpdatePasswordForm>({
        defaultValues: {
            password: "",
        },
        ...rhfConfig,
    });

    const {
        register,
        handleSubmit,
        formState: { isDirty, isSubmitting },
    } = form;

    const [createVirtualMachineJob] = useCreateVirtualMachineJobMutation();
    const [trackJob, { isTrackingJob }] = useJobTracker();

    const onSubmit = async (data: UpdatePasswordForm) => {
        if (!vm) {
            return;
        }

        return (
            trackJob(
                createVirtualMachineJob({
                    virtualMachineId: vm.id,
                    virtualMachineTask: {
                        action: "rootpw.change",
                        contents: {
                            password: data.password,
                        },
                    },
                }).unwrap()
            ).then(() => form.reset()),
            handleSubmitError(form.setError, { pathPrefix: "config" })
        );
    };

    return (
        <Panel>
            <RhfFormProvider {...form}>
                <PanelTitle title="Update Root Password" />
                <PanelContent>
                    <InfoPanel>
                        Password change takes effect on next restart.
                    </InfoPanel>
                    <AccessControlledSection
                        aclResource={environment}
                        verifyFn={vm ? vmModifyAccessFn(vm) : () => undefined}
                    >
                        <RhfFormField
                            label="New Password"
                            name="password"
                            required
                        >
                            {vm ? (
                                <TextInput
                                    type="password"
                                    className="my-2 w-full"
                                    {...register("password", {
                                        ...minLength(
                                            10,
                                            "Root password must be at least 10 characters"
                                        ),
                                    })}
                                />
                            ) : (
                                <SkeletonButton />
                            )}
                        </RhfFormField>
                    </AccessControlledSection>

                    <PanelFooter>
                        <div>
                            <RhfGlobalFormError />
                        </div>
                        <AccessControlOverlay
                            aclResource={environment}
                            verifyFn={
                                vm ? vmModifyAccessFn(vm) : () => undefined
                            }
                        >
                            <PushAndHoldButton
                                isLoading={isSubmitting || isTrackingJob}
                                icon={faEdit}
                                onClick={handleSubmit(onSubmit)}
                                type="button"
                                tooltip={"hold to update password"}
                                flavor="primary"
                                disabled={!isDirty}
                            >
                                Update Root Password
                            </PushAndHoldButton>
                        </AccessControlOverlay>
                    </PanelFooter>
                </PanelContent>
            </RhfFormProvider>
        </Panel>
    );
}
