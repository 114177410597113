import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import {
    useGetInstancesQuery,
    useGetInstanceVolumesQuery,
} from "~/services/cycle";
import { useContext } from "react";
import { VirtualMachineDialogContex } from "../../../context";
import { VolumeTableRow } from "~/components/dialogs/containers/container/instances/VolumeTableRow";
import { SkeletonHeader } from "@cycleplatform/ui/components/loaders/skeleton";
import { StyledDataTable } from "@cycleplatform/ui/components/tables";

export function VmVolumesPanel() {
    const { vm, environment } = useContext(VirtualMachineDialogContex);
    const { data: instances, error: instancesError } = useGetInstancesQuery(
        {
            containerId: vm?.container_id || "",
            include: ["servers"],
            page: {
                size: 100,
                number: 1,
            },
        },
        { skip: !vm?.container_id }
    );

    const instanceId = instances?.data?.[0]?.id;
    const { data: volumes, error: volumesError } = useGetInstanceVolumesQuery(
        {
            instanceId: instanceId || "",
            containerId: vm?.container_id || "",
        },
        { skip: !vm?.container_id || !instanceId }
    );

    if (volumesError) {
        throw volumesError;
    }
    if (instancesError) {
        throw instancesError;
    }

    return (
        <Panel>
            <PanelTitle title="Volumes" />
            <PanelContent>
                <ul className="grid gap-4">
                    {volumes?.data ? (
                        <StyledDataTable>
                            {volumes?.data?.map((v) => (
                                <VolumeTableRow
                                    key={v.id}
                                    volume={v}
                                    destinationName={v.config.destination
                                        .split("/")
                                        .at(-1)}
                                    environment={environment}
                                    containerId={vm?.container_id}
                                    instanceId={instanceId}
                                />
                            ))}
                        </StyledDataTable>
                    ) : (
                        <>
                            <SkeletonHeader size="lg" className="w-full" />
                            <SkeletonHeader size="lg" className="w-full" />
                        </>
                    )}
                </ul>
            </PanelContent>
        </Panel>
    );
}
