import { z } from "zod";

export const portType = z.custom<string>((val) => {
    if (typeof val !== "string") {
        return false;
    }
    if (val.slice(-1) === ":") {
        return false;
    }
    const parts = val.split(":");
    const external = Number(parts[0]);
    if (Number.isNaN(external)) {
        return false;
    }

    if (parts[1]) {
        const internal = Number(parts[1]);
        if (Number.isNaN(internal)) {
            return false;
        }
    }
    return true;
});

export const DeployStrategy = z.enum([
    "resource-density",
    "first-available",
    "manual",
    "high-availability",
    "node",
    "edge",
    "function",
]);

export const ShutdownSignal = z.enum([
    "SIGTERM",
    "SIGINT",
    "SIGUSR1",
    "SIGUSR2",
    "SIGHUP",
    "SIGQUIT",
]);

export const RestartCondition = z.enum(["always", "never", "failure"]);

export const ContainerRuntimeNamespaces = z.enum([
    "ipc",
    "pid",
    "uts",
    "network",
    "mount",
    "user",
    "cgroup",
]);

export const BaseKernelCapabilities = z.enum([
    "CAP_CHOWN",
    "CAP_FSETID",
    "CAP_DAC_OVERRIDE",
    "CAP_FOWNER",
    "CAP_SETFCAP",
    "CAP_SETGID",
    "CAP_SETUID",
    "CAP_KILL",
    "CAP_MKNOD",
    "CAP_NET_BIND_SERVICE",
    "CAP_NET_RAW",
    "CAP_AUDIT_WRITE",
    "CAP_SYS_CHROOT",
]);

export const PrivilegedKernelCapabilities = z.enum([
    "CAP_SETPCAP",
    "CAP_DAC_READ_SEARCH",
    "CAP_NET_ADMIN",
    "CAP_NET_BROADCAST",
    "CAP_SYS_ADMIN",
    "CAP_SYS_MODULE",
    "CAP_SYS_NICE",
    "CAP_SYS_PACCT",
    "CAP_SYS_PTRACE",
    "CAP_SYS_RAWIO",
    "CAP_SYS_RESOURCE",
    "CAP_SYS_BOOT",
    "CAP_SYS_TIME",
    "CAP_SYS_TTY_CONFIG",
    "CAP_SYSLOG",
    "CAP_AUDIT_CONTROL",
    "CAP_AUDIT_READ",
    "CAP_IPC_LOCK",
    "CAP_IPC_OWNER",
    "CAP_LINUX_IMMUTABLE",
    "CAP_MAC_ADMIN",
    "CAP_MAC_OVERRIDE",
    "CAP_BLOCK_SUSPEND",
    "CAP_LEASE",
    "CAP_WAKE_ALARM",
    "CAP_BPF",
]);
