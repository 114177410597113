import { useFormContext } from "react-hook-form";
import classNames from "classnames";
import { getOptionButtonClassName } from "@cycleplatform/ui/components/options/styles";
import { useAppSelector } from "~/hooks";
import { IntegrationLogo } from "@cycleplatform/ui/components/resources";
import { DeployableInfraFormType } from "../form";
import { Integration } from "~/services/cycle";
import { selectAppliedTheme } from "~/modules/settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavIcons } from "~/components/layout/NavIcons";

export function ProviderSection({
    integration,
    integrations,
}: {
    integration?: Integration;
    integrations?: Integration[];
}) {
    const { setValue } = useFormContext<DeployableInfraFormType>();
    const theme = useAppSelector(selectAppliedTheme);

    return (
        <div className="grid grid-cols-4 gap-4 pb-8">
            {integrations?.map((i) => {
                const isSelected = integration && integration.id === i.id;
                return (
                    <button
                        key={i.id}
                        type="button"
                        onClick={() => setValue("provider", i)}
                        className={classNames(
                            getOptionButtonClassName(isSelected)
                        )}
                    >
                        <div className="flex items-center justify-center p-2 text-center ">
                            {i.vendor !== "abstraction" ? (
                                <div>
                                    <div className="h-10">
                                        <IntegrationLogo
                                            vendor={i.vendor}
                                            theme={theme}
                                        />
                                    </div>
                                    <span className="text-sm">{i.name}</span>
                                </div>
                            ) : (
                                <div className="flex h-10 items-center">
                                    <FontAwesomeIcon
                                        icon={
                                            NavIcons["infrastructureProviders"]
                                        }
                                        className="pr-2 "
                                    />
                                    {i.name}
                                </div>
                            )}
                        </div>
                    </button>
                );
            })}
        </div>
    );
}
