import { Checkbox } from "@cycleplatform/ui/components/forms";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import { IdSelectType } from "./hooks";
import { useEffect } from "react";

type RhfIdCheckboxProps = {
    id: string;
    disabled?: boolean;
    className?: string;
};

export function RhfIdCheckbox({ id, disabled, className }: RhfIdCheckboxProps) {
    const { register, getValues, setValue } = useFormContext<IdSelectType>();

    useEffect(() => {
        return () => {
            const ids = getValues("ids");
            setValue(
                "ids",
                ids?.filter((i) => i !== id)
            );
        };
    }, [id, getValues, setValue]);

    return (
        <Checkbox
            {...register("ids")}
            value={id}
            disabled={disabled}
            className={classNames(className)}
        />
    );
}
