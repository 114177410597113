import { Container, VirtualMachine } from "~/services/cycle";
import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import { preprocessCountsData } from "@cycleplatform/ui/components/charts";
import { Link } from "react-router-dom";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import { ResourceStatePieChart } from "~/components/common/charts/ResourceStatePieChart";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { Panel, PanelTitle } from "@cycleplatform/ui/components/panels";
import { PanelContentBoundary } from "~/components/layout/panels/PanelContentBoundary";
import { useContext } from "react";
import { VirtualMachineDialogContex } from "../../../context";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";

type VmContainerPanelProps = {
    vm: VirtualMachine | undefined;
    container: Container | undefined;
};

export function HypervisorContainerPanel() {
    const { vm, container } = useContext(VirtualMachineDialogContex);

    return (
        <Panel>
            <PanelTitle
                help="Every virtual machine on Cycle is backed by an automatically managed container behind the scenes. General virtual machine use does not require interacting with the underlying container."
                title="Hypervisor Container"
            />
            <PanelContentBoundary>
                {vm?.container_id ? (
                    <HypervisorContainerPanelContent
                        vm={vm}
                        container={container}
                    />
                ) : (
                    <EmptyResource
                        className="flex items-center justify-center border-none"
                        icon={faPlay}
                        title="Start virtual machine to create container"
                    >
                        <p className="text-center">
                            When started for the first time, the hypervisor
                            container will be generated.
                        </p>
                    </EmptyResource>
                )}
            </PanelContentBoundary>
        </Panel>
    );
}

export function HypervisorContainerPanelContent({
    vm,
    container,
}: VmContainerPanelProps) {
    return (
        <div className="flex flex-col gap-2">
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-8">
                    <Link
                        className={!vm ? skeletonStyles : ""}
                        to={generateDialogLink("container", {
                            "dialog-container-id": vm?.container_id,
                        })}
                    >
                        {vm?.container_id}
                    </Link>

                    <ResourceStateBadge state={container?.state} />
                </div>

                <div className="h-10 w-10">
                    <ResourceStatePieChart
                        data={preprocessCountsData(
                            container?.meta?.instances_count
                        )}
                    />
                </div>
            </div>
        </div>
    );
}
