import {
    BasicSelect,
    FormattedOption,
} from "@cycleplatform/ui/components/forms/select";
import { useCallback, useEffect, useState } from "react";
import { ResourceComboBox } from "~/components/common/forms";
import {
    GetVirtualMachineBaseImagesApiResponse,
    useGetVirtualMachineBaseImagesQuery,
} from "~/services/cycle";

type VmBaseImageSelectProps = {
    value: string;
    disabled?: boolean;
    onChange: (
        identifier: string | undefined | null,
        baseImage: ReturnType<typeof parseVersionedImages>[0] | undefined
    ) => void;
};

export function VmBaseImageSelect({
    value,
    disabled,
    onChange,
}: VmBaseImageSelectProps) {
    const [os, setOs] = useState<string>(value?.split("-")?.[0] || "");

    const {
        data: images,
        error,
        isLoading,
    } = useGetVirtualMachineBaseImagesQuery();

    const imagesArray = parseVersionedImages(images, os);

    /**
     * Handles setting the version syncronously on OS change and initialization
     */
    const handleDefaultVersion = useCallback(
        (
            os: string | undefined | null,
            i: GetVirtualMachineBaseImagesApiResponse | undefined
        ) => {
            if (!os) {
                return;
            }

            const latest = parseVersionedImages(i, os)?.[0];

            if (latest) {
                onChange(latest.id, latest);
            }
        },
        [onChange]
    );

    /**
     * Allow value to dictate OS.
     * That way we don't have to update the OS directly onChange,
     * but rather, setting the value will set the appropriate OS inherently
     */
    useEffect(() => {
        setOs(value?.split("-")?.[0] || "");
    }, [value]);

    useEffect(() => {
        if (!images?.data) {
            return;
        }
        // if no value => autoselect the first OS verison from list
        if (!value) {
            const firstOs = Object.keys(images.data)[0];
            // Internal delay creates an issue with react hook form
            // 1ms wait allows proper rendering on init
            setTimeout(() => handleDefaultVersion(firstOs, images), 1);
        }
    }, [images?.data, value]);

    if (error) {
        throw error;
    }

    return (
        <div className="flex flex-col gap-2">
            <div>
                <BasicSelect
                    optionsClassName="!min-h-[35rem]"
                    value={os}
                    onChange={(v) => handleDefaultVersion(v, images)}
                    placeholder="Select an OS"
                    options={
                        images
                            ? Object.entries(images.data).map(
                                  ([ident, img]) => ({
                                      value: ident,
                                      label: `${img.title}`,
                                      detail: `${img.description}`,
                                  })
                              )
                            : []
                    }
                />
            </div>

            <div>
                <ResourceComboBox
                    isLoading={isLoading}
                    value={value}
                    filterFields={["id", "title", "version"]}
                    onChange={onChange}
                    disabled={disabled}
                    resources={imagesArray}
                    formatDisplayValue={(i) => {
                        if (!i) return "";

                        return `${i.title}`;
                    }}
                    placeholder={os ? "Select a Version" : "No OS Selected"}
                    formatOption={(i) => (
                        <FormattedOption label={`${i.title}`}></FormattedOption>
                    )}
                />
            </div>
        </div>
    );
}

function parseVersionedImages(
    resp: GetVirtualMachineBaseImagesApiResponse | undefined,
    os: string = ""
) {
    if (!resp) {
        return [];
    }
    const imgs = resp.data[os]?.versions || [];

    return imgs
        .filter((vi) => vi.supported)
        .map((vi) => ({
            id: vi.identifier,
            os: os,
            title: `${vi.version}`,
            version: `${vi.version}`,
            url: vi.disk_image_url,
        }));
}
