import {
    faFileBinary,
    faHouse,
    faLayerGroup,
    faNetworkWired,
    faObjectGroup,
    faScrewdriverWrench,
    faServer,
    faSquareDollar,
    faGlobeAmericas,
    faFileInvoice,
    faHammer,
    faWavePulse,
    faCircleDollar,
    faPeopleGroup,
    faMessages,
    faCode,
    faKeySkeleton,
    faGear,
    faMapPin,
    faFileLock,
    faAddressBook,
    faCloudBinary,
    faChartNetwork,
    faHdd,
    faBox,
    faVault,
    faCreditCardAlt,
    faRocket,
    faBinaryLock,
    faFolderGear,
    faContainerStorage,
    faGaugeLow,
    faGaugeHigh,
    faGearComplexCode,
    faArrowUpArrowDown,
    faChartLineUpDown,
    faPuzzle,
    faCalendar,
    faTimelineArrow,
    faListCheck,
    faCircleNodes,
    faBlockBrickFire,
    faFileCode,
    faComputerClassic,
    faKey,
    faRoute,
} from "@fortawesome/pro-duotone-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

export const NavIcons = {
    hubs: faFolderGear,
    dashboard: faHouse,
    altDash: faGaugeHigh,
    jobs: faHammer,
    backups: faVault,
    logs: faFileCode,
    containers: faContainerStorage,
    containerConfig: faGearComplexCode,
    telemetry: faChartLineUpDown,
    environments: faObjectGroup,
    environmentLoadBalancer: faNetworkWired,
    environmentVpn: faBinaryLock,
    environmentScheduler: faCalendar,
    environmentDiscovery: faMessages,
    environmentGateway: faRoute,
    lbController: faServer,
    firewall: faBlockBrickFire,
    stacks: faLayerGroup,
    images: faFileBinary,
    imageSources: faCloudBinary,
    pipelines: faTimelineArrow,
    infrastructure: faServer,
    infrastructureDashboard: faGaugeLow,
    infrastructureClusters: faCircleNodes,
    infrastructureServers: faServer,
    infrastructureProviders: faChartNetwork,
    infrastructureScaling: faArrowUpArrowDown,
    infrastructureIps: faAddressBook,
    dns: faGlobeAmericas,
    deploy: faRocket,
    dnsZone: faMapPin,
    dnsTls: faFileLock,
    volumes: faHdd,
    networks: faNetworkWired,
    instances: faBox,
    billing: faSquareDollar,
    billingInvoices: faFileInvoice,
    billingMethods: faCreditCardAlt,
    billingActiveServices: faWavePulse,
    billingCredits: faCircleDollar,
    settings: faScrewdriverWrench,
    hubSettings: faGear,
    hubMembers: faPeopleGroup,
    hubRoles: faListCheck,
    hubInvites: faMessages,
    hubIntegrations: faPuzzle,
    hubPlans: faLayerGroup,
    hubApiKeys: faKeySkeleton,
    hubAuditLog: faCode,
    vm: faComputerClassic,
    sshKey: faKey,
} as const;

export function getNavIcons(key: keyof typeof NavIcons): IconDefinition {
    return NavIcons[key];
}
