import {
    Button,
    PushAndHoldButton,
} from "@cycleplatform/ui/components/buttons";
import {
    DialogColumn,
    DialogFooter,
} from "@cycleplatform/ui/components/dialog/components";
import {
    faArrowLeft,
    faCreditCard,
    faEdit,
} from "@fortawesome/pro-solid-svg-icons";
import { useContext, useMemo, useState } from "react";
import { MultiStepDialogContext } from "~/components/dialogs/MultiStepFormDialog/context";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { addStepData, getCurrentForm } from "~/modules/forms/slice";
import { ChooseAnotherHubButton } from "../../buttons/ChooseAnotherHubButton";
import { DeleteHubButton } from "../../buttons/DeleteHubButton";
import {
    CreateOrderApiArg,
    useGetBillingMethodsQuery,
    useGetBillingTiersQuery,
} from "~/services/cycle";
import { HubSetupTypes } from "../../types";
import {
    FormSection,
    FormSectionHeader,
    RhfFormProvider,
    SectionDisabledControl,
} from "@cycleplatform/ui/components/forms";
import { useForm } from "react-hook-form";
import { rhfConfig } from "~/components/forms/util";
import {
    PLAN_TILE_CLASSNAME,
    PlanTier,
} from "@cycleplatform/ui/components/resources";
import classNames from "classnames";
import { PromoInfo } from "../../../changePlan/form/steps/orderConfirmation/OrderConfirmationStep";
import { AddPromoCode } from "../../../changePlan/form/steps/orderConfirmation/AddPromoCode";
import { ActivePaymentSection } from "../setupPayment/ActivePayment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavIcons } from "~/components/layout/NavIcons";
import { useHandlePlanUpdate } from "../../../changePlan/form/util";
import { getAllDialogSearchParams } from "~/components/dialogs/helpers";
import { HandleNextButton } from "../../buttons/HandleNextButton";

export function HubSetupReviewStep({ stepIdx = 0 }: { stepIdx?: number }) {
    const { handleBack, formKey, handleNext, navigateToStep } = useContext(
        MultiStepDialogContext
    );

    const promoParam =
        getAllDialogSearchParams<"change-hub-plan">()["dialog-promo-code"];

    const dispatch = useAppDispatch();

    const { data: tiers } = useGetBillingTiersQuery();
    const { data: methods } = useGetBillingMethodsQuery({});

    const currentForm = useAppSelector((state) =>
        getCurrentForm(state, formKey)
    );

    const isSetup = !!currentForm?.steps?.[stepIdx];

    const cachedTier = currentForm?.steps?.[0] as HubSetupTypes[0] | undefined;

    const form = useForm<CreateOrderApiArg["body"]>({
        defaultValues: { ...cachedTier, promo_code: promoParam || undefined },
        ...rhfConfig,
    });

    const {
        formState: { isSubmitting },
    } = form;

    const [promoInfo, setPromoInfo] = useState<PromoInfo>();

    const currentTier = useMemo(
        () => tiers?.data?.find((t) => t.id === cachedTier?.tier_plan_id),
        [tiers, cachedTier?.tier_plan_id]
    );

    const onSubmit = useHandlePlanUpdate({
        onSuccess: () => {
            handleNext();
            dispatch(
                addStepData({
                    data: { complete: true },
                    idx: stepIdx,
                    key: formKey,
                })
            );
        },
        tiers: tiers?.data,
    });

    if (!cachedTier || !methods?.data?.length) {
        return null;
    }

    return (
        <RhfFormProvider {...form} className="!h-full">
            <SectionDisabledControl
                className="flex h-full gap-8"
                disabled={isSetup}
            >
                <DialogColumn className="w-1/2">
                    <div
                        className={classNames(
                            "h-full flex-1",
                            PLAN_TILE_CLASSNAME
                        )}
                    >
                        <FormSectionHeader
                            header={
                                <div className="flex items-center gap-2">
                                    <FontAwesomeIcon
                                        icon={NavIcons["hubPlans"]}
                                        className="text-cycle-blue"
                                    />
                                    {!isSetup ? `Selected` : `Active`} Plan
                                    {!isSetup ? (
                                        <button
                                            onClick={() => navigateToStep(0)}
                                        >
                                            <FontAwesomeIcon
                                                size="sm"
                                                icon={faEdit}
                                                className="text-cycle-blue hover:text-cycle-blue-accent"
                                            />
                                        </button>
                                    ) : null}
                                </div>
                            }
                        />
                        <FormSection>
                            <PlanTier
                                tier={currentTier}
                                priceOverride={
                                    promoInfo?.order?.total_price !== undefined
                                        ? {
                                              price: promoInfo.order
                                                  .total_price,
                                              reason: "discount",
                                          }
                                        : undefined
                                }
                            />
                        </FormSection>
                    </div>
                    <AddPromoCode
                        setPromoInfo={setPromoInfo}
                        promoInfo={promoInfo}
                    />
                </DialogColumn>
                <DialogColumn className="w-1/2">
                    <ActivePaymentSection method={methods.data[0]} />
                </DialogColumn>
            </SectionDisabledControl>

            <DialogFooter className="flex justify-between">
                <div className="flex gap-4">
                    <Button icon={faArrowLeft} onClick={() => handleBack?.()}>
                        Back
                    </Button>
                    <ChooseAnotherHubButton />
                    <DeleteHubButton />
                </div>

                {!isSetup ? (
                    <PushAndHoldButton
                        flavor="primary"
                        icon={faCreditCard}
                        isLoading={isSubmitting}
                        onClick={form.handleSubmit(onSubmit)}
                    >
                        Place Order
                    </PushAndHoldButton>
                ) : (
                    <HandleNextButton />
                )}
            </DialogFooter>
        </RhfFormProvider>
    );
}
