import classNames from "classnames";
import { Link, To } from "react-router-dom";
import { HelpInfoCircle } from "../../help";

type DialogSubNavTabProps = {
    title: React.ReactNode;
    to: To;
    isActive: boolean;
    isError?: boolean;
    className?: string;
    badge?: React.ReactNode;
    help?: string;
    prefix?: React.ReactNode;
};

export function DialogSubNavTab({
    title,
    to,
    isActive,
    isError,
    className,
    badge,
    help,
    prefix,
}: DialogSubNavTabProps) {
    return (
        <Link
            to={to}
            className={classNames(
                "dark:text-cycle-white overflow-hidden text-ellipsis whitespace-nowrap rounded-lg rounded-r-none p-3 text-inherit",
                "hover:bg-cycle-gray-light dark:hover:bg-cycle-black-accent hover:text-inherit ",
                className,
                isActive &&
                    "bg-cycle-white-accent dark:bg-cycle-black-accent !text-cycle-blue",
                isError && "!text-error"
            )}
        >
            <div className="flex w-full justify-between">
                <div className="flex items-center gap-4">
                    {prefix}
                    {title}
                    {help ? <HelpInfoCircle message={help} /> : null}
                </div>

                {badge}
            </div>
        </Link>
    );
}
