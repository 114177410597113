import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { useContext } from "react";
import { useGetInstancesQuery } from "~/services/cycle";
import { VirtualMachineDialogContex } from "../../../context";
import { getActiveVmInstance } from "@cycleplatform/core/modules/vms/instance";
import { CopyInput } from "@cycleplatform/ui/components/forms";
import { Link } from "react-router-dom";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPersonDolly, faServer } from "@fortawesome/pro-solid-svg-icons";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { ButtonLink } from "@cycleplatform/ui/components/buttons";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { NavIcons } from "~/components/layout/NavIcons";

export function VmHostServerPanel() {
    const { vm } = useContext(VirtualMachineDialogContex);

    const { data: instances, error: instancesError } = useGetInstancesQuery(
        {
            containerId: vm?.container_id || "",
            include: ["servers"],
            page: {
                size: 100,
                number: 1,
            },
        },
        { skip: !vm?.container_id }
    );

    if (instancesError) {
        throw instancesError;
    }

    const instance = getActiveVmInstance(instances?.data || []);
    const server = instance
        ? instances?.includes?.servers?.[instance.server_id]
        : undefined;

    return (
        <Panel>
            <PanelTitle className="flex justify-between" title="Host Server" />
            <PanelContent>
                {server ? (
                    <div className="flex w-full items-center gap-4">
                        <div className="w-full">
                            <CopyInput value={server?.hostname} />
                        </div>
                        <Link to={`/infrastructure/servers/${server?.id}`}>
                            <Tooltip message="Go to server dashboard">
                                <FontAwesomeIcon
                                    icon={faServer}
                                    className="text-cycle-blue"
                                />
                            </Tooltip>
                        </Link>
                        <ButtonLink
                            icon={faPersonDolly}
                            to={generateDialogLink("virtual-machine", {
                                "dialog-tab": "settings",
                                "dialog-id": vm?.id,
                                "dialog-focus": "migrate",
                            })}
                        >
                            Migrate
                        </ButtonLink>
                    </div>
                ) : (
                    <EmptyResource
                        className="border-none"
                        icon={NavIcons["infrastructureServers"]}
                        title="Not Started"
                    >
                        <p className="text-center">
                            This virtual machine has never been started. An
                            instance will be created and a server assigned on
                            start.
                        </p>
                    </EmptyResource>
                )}
            </PanelContent>
        </Panel>
    );
}
