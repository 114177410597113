import { useGetInstancesQuery } from "~/services/cycle";
import {
    CopyInput,
    FormField,
    SectionDisabledControl,
} from "@cycleplatform/ui/components/forms";
import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import classNames from "classnames";
import { useContext } from "react";
import { VirtualMachineDialogContex } from "../../context";
import { InstanceConsole } from "~/components/dialogs/containers/container/instances/InstanceConsole";
import { HypervisorContainerPanel } from "../settings/panels/VmHypervisorContainerPanel";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { PageAside, PageContent } from "@cycleplatform/ui/components/page";

export function VmHypervisorTab() {
    const { vm, container } = useContext(VirtualMachineDialogContex);

    const { data: instances, error: instanceError } = useGetInstancesQuery(
        {
            containerId: container?.id || "",
            include: ["servers"],
        },
        { skip: !container?.id }
    );

    if (instanceError) {
        throw instanceError;
    }

    const instance = instances?.data?.[0];

    return (
        <DialogPageBody className="relative">
            <SectionDisabledControl
                className={classNames("flex flex-1 items-start rounded-lg")}
                heightFull
                disabled={vm?.state.current === "deleted"}
            >
                <PageContent>
                    {container && instance ? (
                        <InstanceConsole instance={instance} />
                    ) : null}
                </PageContent>
                <PageAside>
                    <HypervisorContainerPanel />
                    <Panel>
                        <PanelTitle title="Network" />
                        <PanelContent>
                            <FormField className="w-full" label="IPv6">
                                <CopyInput
                                    value={instance?.environment.ipv6?.ip}
                                />
                            </FormField>
                            <FormField className="w-full" label="IPv4">
                                <CopyInput
                                    value={
                                        instance?.environment.legacy?.ipv4
                                            ?.ip || "Legacy Mode Disabled"
                                    }
                                />
                            </FormField>
                        </PanelContent>
                    </Panel>
                </PageAside>
            </SectionDisabledControl>
        </DialogPageBody>
    );
}
