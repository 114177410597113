import { operations } from "@cycleplatform/core/modules/api/__generated";
import { analyzeContainerNetworkStatus } from "@cycleplatform/core/modules/networks/analysis";
import { getLoadBalancerConfiguration } from "@cycleplatform/core/modules/environments/loadbalancer";
import { DateTimeFormats } from "@cycleplatform/core/util";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import {
    faArrowsLeftRight,
    faExternalLink,
} from "@fortawesome/pro-solid-svg-icons";
import {
    faLockKeyhole,
    faPlus,
    faUnlock,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, isBefore, sub } from "date-fns";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import {
    Container,
    DnsRecord,
    LoadBalancerConfig,
    useGetLoadBalancerServiceQuery,
} from "~/services/cycle";
import { Badge } from "@cycleplatform/ui/components/badges";
import { NavIcons } from "~/components/layout/NavIcons";

type PublicDomainsTableProps = {
    domains:
        | {
              fqdn: string;
              record?: DnsRecord | null;
          }[]
        | null
        | undefined;
    container: Container;
};

export function PublicDomainsTable({
    domains,
    container,
}: PublicDomainsTableProps) {
    const { data: lbInfo, error } = useGetLoadBalancerServiceQuery({
        environmentId: container.environment?.id || "",
    });

    const filteredDomains = useMemo(
        () =>
            domains?.filter(
                (d): d is { fqdn: string; record: DnsRecord } => !!d.record
            ) || [],
        [domains]
    );

    const config = lbInfo?.data
        ? getLoadBalancerConfiguration(
              lbInfo.data as unknown as operations["getLoadBalancerService"]["responses"][200]["content"]["application/json"]["data"]
          )
        : undefined;

    const analysis = useMemo(
        () =>
            container
                ? analyzeContainerNetworkStatus(
                      container,
                      // we don't need lb to determine linked records
                      undefined,
                      // needed for type discrepancy until RTKQuery updates their codegen.
                      config as LoadBalancerConfig
                  )
                : null,
        [container, config]
    );

    if (!analysis) {
        // loading
        return null;
    }

    if (
        analysis.errors.some((e) => e.code === "lb-ingress.dns.missing-record")
    ) {
        return (
            <div className="p-4">
                <p>
                    No LINKED Records. Containers are only resolvable via HTTP/S
                    over the public internet when they have a LINKED record.
                </p>
                <div className="mt-2 flex gap-2">
                    <Link to="/dns/zones">
                        <FontAwesomeIcon icon={faPlus} /> Add LINKED Record
                    </Link>
                    <a
                        target="_blank"
                        href="https://cycle.io/docs/portal/creating-a-dns-record#type-linked"
                    >
                        <FontAwesomeIcon icon={faExternalLink} /> Learn More
                    </a>
                </div>
            </div>
        );
    }

    return (
        <StyledDataTable>
            <StyledTableHead>
                <StyledHeaderCell>Domain</StyledHeaderCell>
                <StyledHeaderCell>TLS</StyledHeaderCell>
                <StyledHeaderCell></StyledHeaderCell>
            </StyledTableHead>
            <tbody>
                {filteredDomains.map((d) => {
                    const hasCertificate = !!d.record.features?.certificate?.id;
                    const certificate = d.record.features?.certificate;
                    const certificateExpired =
                        certificate &&
                        isBefore(
                            new Date(certificate.generated),
                            sub(new Date(), { days: 90 })
                        );

                    const isDmz =
                        d.record.type.linked &&
                        "virtual_machine" in d.record.type.linked &&
                        d.record.type.linked.virtual_machine.dmz;

                    return (
                        <StyledTableRow key={d.fqdn}>
                            <StyledCell className="">
                                <div className="flex items-center gap-4">
                                    <Link
                                        to={`/dns/zones/${d.record.zone_id}/records`}
                                    >
                                        {d.fqdn}
                                    </Link>
                                    {isDmz ? (
                                        <Badge className="flex gap-2">
                                            <FontAwesomeIcon
                                                icon={
                                                    NavIcons[
                                                        "environmentGateway"
                                                    ]
                                                }
                                            />
                                            DMZ
                                        </Badge>
                                    ) : null}
                                </div>
                            </StyledCell>
                            <StyledCell>
                                <Tooltip
                                    as="span"
                                    message={
                                        !d.record.type.linked?.features.tls
                                            .enable
                                            ? "No TLS"
                                            : certificate && !certificateExpired
                                            ? `TLS Certificate Generated On ${format(
                                                  new Date(
                                                      certificate.generated
                                                  ),
                                                  DateTimeFormats["standard"]
                                              )}`
                                            : certificateExpired
                                            ? "TLS Certificate is Expired"
                                            : "Generating Certificate"
                                    }
                                >
                                    {d.record &&
                                    d.record.type.linked?.features.tls
                                        .enable ? (
                                        <FontAwesomeIcon
                                            icon={faLockKeyhole}
                                            className={
                                                certificate &&
                                                !certificateExpired
                                                    ? "text-success"
                                                    : certificateExpired
                                                    ? "text-error"
                                                    : "text-cycle-blue"
                                            }
                                        />
                                    ) : (
                                        <FontAwesomeIcon icon={faUnlock} />
                                    )}
                                </Tooltip>
                            </StyledCell>
                            <StyledCell>
                                <a
                                    href={`${
                                        hasCertificate ? "https://" : "http://"
                                    }${d.fqdn}`}
                                    target="_blank"
                                >
                                    <FontAwesomeIcon icon={faExternalLink} />
                                </a>
                            </StyledCell>
                        </StyledTableRow>
                    );
                })}
            </tbody>
        </StyledDataTable>
    );
}
