import { useGenerateAggregatedMetricsQuery } from "~/services/cycle";
import { buildMemberUsageQuery } from "./query";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import {
    Bar,
    CartesianGrid,
    ComposedChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import classNames from "classnames";
import {
    CycleAxisProps,
    CycleChartProps,
} from "@cycleplatform/ui/components/recharts/props";
import { FormattedYTick } from "@cycleplatform/ui/components/recharts/FormattedYTick";
import { FormattedTimeTick } from "@cycleplatform/ui/components/recharts/FormattedTimeTick";
import { CycleBarTooltip } from "@cycleplatform/ui/components/recharts/bar/CycleBarTooltip";
import {
    CycleReferenceLabelProps,
    CycleReferenceLineProps,
} from "@cycleplatform/ui/components/recharts/line/props";
import { useGetThemedChartColors } from "~/util/charts/hooks";
import { CycleGridProps } from "@cycleplatform/ui/components/recharts/grid/props";
import { CycleBarProps } from "@cycleplatform/ui/components/recharts/bar/props";
import { brandColors } from "tailwindcss-config/colors";
import { useMemo } from "react";
import { AGGREGATE_POLLING_MS } from "~/util/charts/util";

type MembersUsageChartProps = {
    allowedMembers: number;
};

export function MembersUsageChart({ allowedMembers }: MembersUsageChartProps) {
    const { colors } = useGetThemedChartColors();

    const { data: telemetry, isLoading } = useGenerateAggregatedMetricsQuery(
        buildMemberUsageQuery(),
        { pollingInterval: AGGREGATE_POLLING_MS }
    );

    const data = telemetry?.data as {
        active: number;
        pending: number;
        time: string;
    }[];

    const dataMax = useMemo(
        () =>
            data?.reduce((acc, cur) => {
                const totalMembers = cur.active + cur.pending;
                return totalMembers > acc ? totalMembers : acc;
            }, 0 as number),
        [data]
    );

    if (!telemetry?.data && !isLoading) {
        return null;
    }

    return (
        <div
            className={classNames(
                "group/usage h-full",
                isLoading && skeletonStyles
            )}
        >
            {telemetry?.data && (
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                        {...CycleChartProps}
                        data={telemetry?.data}
                        syncId={"hub-usage"}
                    >
                        <CartesianGrid {...CycleGridProps} />
                        <Tooltip
                            content={(props) => <CycleBarTooltip {...props} />}
                        />
                        <Bar
                            {...CycleBarProps}
                            stackId="priority"
                            dataKey={"active"}
                            fill={colors["blue"].DEFAULT}
                        />
                        <Bar
                            {...CycleBarProps}
                            stackId="priority"
                            dataKey={"pending"}
                            fill={colors["gray"].light}
                        />
                        <ReferenceLine
                            {...CycleReferenceLineProps}
                            y={allowedMembers}
                            stroke={
                                dataMax && dataMax > allowedMembers
                                    ? brandColors["warning"].DEFAULT
                                    : undefined
                            }
                            label={{
                                ...CycleReferenceLabelProps,
                                fill: colors["black"].DEFAULT,
                                value: "Total Allowed Members",
                            }}
                        />

                        <XAxis
                            {...CycleAxisProps}
                            dataKey="time"
                            tick={(props) => <FormattedTimeTick {...props} />}
                        />
                        <YAxis
                            {...CycleAxisProps}
                            allowDecimals={false}
                            domain={[
                                0,
                                (dataMax: number) => {
                                    const max =
                                        dataMax > allowedMembers
                                            ? dataMax
                                            : allowedMembers;
                                    return Math.ceil(max * 1.2);
                                },
                            ]}
                            tick={(props) => <FormattedYTick {...props} />}
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            )}
        </div>
    );
}
