import {
    useDeleteContainerMutation,
    useDeleteVirtualMachineMutation,
    useGetInstancesQuery,
    VirtualMachine,
} from "~/services/cycle";
import {
    CopyInput,
    SectionDisabledControl,
} from "@cycleplatform/ui/components/forms";
import { PageAside, PageContent } from "@cycleplatform/ui/components/page";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { SkeletonButton } from "@cycleplatform/ui/components/loaders/skeleton";
import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import classNames from "classnames";
import { DeleteResource } from "~/components/common/resources/DeleteResource";
import { useContext } from "react";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { VirtualMachineDialogContex } from "../../context";
import { InstanceCharts } from "~/components/dialogs/containers/container/instances/InstanceCharts";

export function VmTelemetryTab() {
    const { vm, container } = useContext(VirtualMachineDialogContex);

    const { data: instances, error: instanceError } = useGetInstancesQuery(
        {
            containerId: container?.id || "",
            include: ["servers"],
        },
        { skip: !container?.id }
    );

    if (instanceError) {
        throw instanceError;
    }
    return (
        <DialogPageBody className="relative">
            <SectionDisabledControl
                className={classNames("flex  flex-1 items-start rounded-lg")}
                heightFull
                disabled={vm?.state.current === "deleted"}
            >
                {container && instances?.data?.[0] ? (
                    <InstanceCharts
                        instance={instances?.data?.[0]}
                        container={container}
                        title="Virtual Machine Telemetry"
                    />
                ) : null}
            </SectionDisabledControl>
        </DialogPageBody>
    );
}
