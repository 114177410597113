import { AccessError, VerifyFn, doesRoleHaveCapabilities } from "../acls/util";
import { components } from "../api/__generated";

export const vmModifyAccessFn =
    (
        vm: components["schemas"]["VirtualMachine"]
    ): VerifyFn<components["schemas"]["Environment"]> =>
    (_, acl, role) => {
        if (!doesRoleHaveCapabilities(role, ["virtual-machines-manage"])) {
            return new AccessError().setCapability("virtual-machines-manage");
        }

        if (!acl.modify) {
            return new AccessError().setAclResource("environment");
        }

        if (vm.state.current === "deleted") {
            return new Error("virtual machine is deleted");
        }

        return undefined;
    };

export const vmConsoleAccessFn =
    (
        vm: components["schemas"]["VirtualMachine"]
    ): VerifyFn<components["schemas"]["Environment"]> =>
    (_, acl, role) => {
        if (!doesRoleHaveCapabilities(role, ["virtual-machines-console"])) {
            return new AccessError().setCapability("virtual-machines-console");
        }

        if (!acl.modify) {
            return new AccessError().setAclResource("environment");
        }

        if (vm.state.current === "deleted") {
            return new Error("virtual machine is deleted");
        }

        return undefined;
    };

export const vmSshKeyAccessFn =
    (
        vm: components["schemas"]["VirtualMachine"]
    ): VerifyFn<components["schemas"]["Environment"]> =>
    (_, acl, role) => {
        if (
            !doesRoleHaveCapabilities(role, [
                "virtual-machines-ssh-keys-manage",
            ])
        ) {
            return new AccessError().setCapability(
                "virtual-machines-ssh-keys-manage"
            );
        }

        if (!acl.modify) {
            return new AccessError().setAclResource("environment");
        }

        if (vm.state.current === "deleted") {
            return new Error("virtual machine is deleted");
        }

        return undefined;
    };
