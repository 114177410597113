import {
    faSync,
    faHdd,
    faQuestionCircle,
    faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import {
    Activity,
    ComponentIncludes,
    Container,
    Environment,
} from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import {
    ContainerLink,
    EnvironmentLink,
} from "~/components/common/resources/ResourceLinks";

export function formatContainerBackupMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const containerLink = (
        <ContainerLink
            containerId={item.context.container_id}
            components={components}
        />
    );
    const envLink = (
        <EnvironmentLink
            environmentId={item.context.environment_id}
            components={components}
        />
    );

    switch (item.event) {
        case "container.backup.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Created backup for container {containerLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error creating backing for container {containerLink} in
                        environment {envLink}
                    </span>
                ),
                icon: faHdd,
            };
        case "container.backup.restore":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Restored container {containerLink}in environment{" "}
                        {envLink} from backup
                    </span>
                ) : (
                    <span>
                        Error restoring container {containerLink} in environment{" "}
                        {envLink} from backup
                    </span>
                ),
                icon: faSync,
            };
        case "container.backup.task.restore":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested restore of container {containerLink} in
                        environment {envLink} from backup
                    </span>
                ) : (
                    <span>
                        Error requesting restore of container {containerLink} in
                        environment {envLink} from backup
                    </span>
                ),
                icon: faSync,
            };
        case "container.backup.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Deleted backup for container {containerLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error deleting backup for container {containerLink} in
                        environment {envLink}
                    </span>
                ),
                icon: faTimesCircle,
            };
        case "container.backup.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested deletion of backup for container{" "}
                        {containerLink} in environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting deletion of backup for container{" "}
                        {containerLink} in environment {envLink}
                    </span>
                ),
                icon: faTimesCircle,
            };

        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}
