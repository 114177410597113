import { useContext, useEffect, useState } from "react";
import {
    FilterContext,
    FilterGroupContext,
    FilterValueType,
} from "./FilterContext";
import { faCircle, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";

export type FilterGroupProperties = {
    name: string;
    identifier: string;
    children: React.ReactNode;
    /**
     * Indicates that only one FilterValue can be selected at a time
     */
    isExclusive?: boolean;
    /**
     * If required = true, you MUST set a default Filter Value
     */
    isRequired?: boolean;
    /**
     * The icon to be displayed for the group
     */
    icon?: IconDefinition;
    /**
     * Filters provided by the ActiveFilter component to only show one section at a time
     * The string is the identifier of the group to display
     */
    filter?: string;
    onClose?: (open: boolean) => void;
};

export function FilterGroup({
    identifier: groupIdentifier,
    name: groupName,
    children,
    isExclusive = false,
    isRequired = false,
    icon = faCircle,
    filter,
    onClose,
}: FilterGroupProperties) {
    const [expanded, setExpanded] = useState(false);

    const { groupDetails, setGroupDetails } = useContext(FilterContext);

    useEffect(() => {
        if (!groupDetails[groupIdentifier]) {
            setGroupDetails({
                ...groupDetails,
                [groupIdentifier]: { icon, isRequired, isExclusive },
            });
        }
    }, [groupDetails, icon]);

    if (filter && filter !== groupIdentifier) {
        return null;
    }

    return (
        <FilterGroupContext.Provider
            value={{
                group: groupIdentifier,
                groupName: groupName,
                exclusive: isExclusive,
                required: isRequired,
                onClose,
            }}
        >
            <div>
                <button
                    onClick={() => setExpanded(!expanded)}
                    className="w-full"
                >
                    <div className="flex w-full justify-between gap-2 pb-2 text-sm text-base font-semibold uppercase">
                        {groupName}
                    </div>
                </button>

                <div className={classNames(" flex gap-2")}>{children}</div>
            </div>
        </FilterGroupContext.Provider>
    );
}
