import { useContext } from "react";
import { VirtualMachineDialogContex } from "../../../context";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { AccessControlOverlay } from "~/components/common/buttons";
import { Instance, useGetInstancesQuery } from "~/services/cycle";
import { $error } from "@cycleplatform/core/util/log";
import { containerMigrateAccessFn } from "@cycleplatform/core/modules/containers/acls";
import { MigrateInstanceForm } from "~/components/dialogs/containers/container/instances/MigratePanelButton";
import { MigratingPanel } from "~/components/dialogs/containers/container/instances/MigratingPanel";
import classNames from "classnames";
import { getActiveVmInstance } from "@cycleplatform/core/modules/vms/instance";
import { SkeletonChart } from "@cycleplatform/ui/components/loaders/skeleton";

type VmMigratePanelProps = {
    shouldFocus?: boolean;
};

export function VmMigratePanel({ shouldFocus }: VmMigratePanelProps) {
    const { container, environment } = useContext(VirtualMachineDialogContex);

    const { data: instances, error } = useGetInstancesQuery(
        {
            containerId: container?.id || "",
        },
        { skip: !container?.id }
    );

    if (error) {
        $error("unable to load instance for virtual machine container");
        return null;
    }

    // should only ever be one instance of a vm container
    const instance = getActiveVmInstance(instances?.data || []) as
        | Instance
        | undefined;

    if (instance?.state?.current === "migrating") {
        return (
            <MigratingPanel
                title="Migrate Virtual Machine"
                instance={instance}
            />
        );
    }

    return (
        <Panel>
            <PanelTitle title="Migrate Virtual Machine" />
            <PanelContent
                className={classNames(shouldFocus && "ring-cycle-blue ring")}
            >
                <AccessControlOverlay
                    aclResource={environment}
                    verifyFn={
                        container && instance
                            ? containerMigrateAccessFn(container, instance)
                            : () => undefined
                    }
                >
                    {instance ? (
                        <MigrateInstanceForm
                            container={container}
                            instance={instance}
                        />
                    ) : (
                        <SkeletonChart className="!h-48" />
                    )}
                </AccessControlOverlay>
            </PanelContent>
        </Panel>
    );
}
