import { useAppSelector } from "~/hooks";
import { selectActiveHub } from "~/modules/hubs/slice";
import { useGetBillingTiersQuery } from "~/services/cycle";

export function useGetCurrentPlanTier() {
    const { data: tiers, error } = useGetBillingTiersQuery();
    const hub = useAppSelector(selectActiveHub);
    const currentTierId = hub?.billing?.plans?.tier_id || null;

    if (error) {
        throw error;
    }

    return tiers?.data?.find((t) => t.id === currentTierId);
}
