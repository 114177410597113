import { formatLoadBalancerInstanceName } from "@cycleplatform/core/modules/environments/loadbalancer/format";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import {
    StyledCell,
    StyledDataTable,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { useContext, useMemo } from "react";
import { NavIcons } from "~/components/layout/NavIcons";
import {
    Instance,
    useGenerateAggregatedMetricsQuery,
    useGetInstancesQuery,
} from "~/services/cycle";
import { LoadBalancerDialogContext } from "../../../../context";
import {
    LoadBalancerDestinationMetric,
    buildRouterMetricsQuery,
} from "./query";
import { CycleErrorBoundary } from "~/components/common/errors";
import { LatencyChartMetric, buildLatencyQuery } from "./latency/query";
import { SkeletonTable } from "@cycleplatform/ui/components/loaders/skeleton";
import { AGGREGATE_POLLING_MS } from "~/util/charts/util";
import { RouterTableHeader } from "./RouterTableHeader";
import { RouterRow } from "./RouterRow";
import { RouterEmptyPanel } from "./RouterEmptyPanel";
import { resourcesToResourceById } from "~/components/common/resources/util";

type RoutersSectionProps = {
    port: number;
};

export function RoutersSection({ port }: RoutersSectionProps) {
    const { environment, lbContainer } = useContext(LoadBalancerDialogContext);
    const {
        data: routerMetricsResp,
        isLoading: isRouterMetricsLoading,
        error: routerMetricsErr,
    } = useGenerateAggregatedMetricsQuery(
        buildRouterMetricsQuery(environment?.id, port),
        {
            skip: !environment?.id || !port,
            pollingInterval: AGGREGATE_POLLING_MS,
        }
    );
    const {
        data: latencyResp,
        isLoading: isLatencyLoading,
        error: latencyErr,
    } = useGenerateAggregatedMetricsQuery(
        buildLatencyQuery(environment?.id, port),
        { refetchOnFocus: false, pollingInterval: AGGREGATE_POLLING_MS }
    );

    const { data: instances, error: instancesErr } = useGetInstancesQuery(
        {
            containerId: lbContainer?.id || "",
            sort: ["id"],
            include: ["locations"],
        },
        { skip: !lbContainer?.id, pollingInterval: 1000 * 60 * 1 }
    );

    const routerMetrics = routerMetricsResp?.data as
        | LoadBalancerDestinationMetric[]
        | undefined;

    // Reduce lookups for instance pairing with telemetry
    const instancesByInstanceId = useMemo(
        () => resourcesToResourceById(instances?.data || []),
        [instances]
    );

    const metricsByLoadBalancerInstanceId = useMemo(
        () =>
            routerMetrics?.reduce((acc, cur) => {
                if (!acc[cur.instance_id]) {
                    acc[cur.instance_id] = [];
                }
                acc[cur.instance_id]!.push(cur);
                return acc;
            }, {} as Record<string, LoadBalancerDestinationMetric[]>) || {},
        [routerMetrics]
    );

    const latencyMetrics = latencyResp?.data as
        | LatencyChartMetric[]
        | undefined;

    if (instancesErr) {
        throw instancesErr;
    } else if (latencyErr) {
        throw latencyErr;
    } else if (routerMetricsErr) {
        throw routerMetricsErr;
    }

    if (isRouterMetricsLoading || isLatencyLoading) {
        return (
            <Panel>
                <PanelContent stretch>
                    <PanelTitle title={undefined} />
                    <SkeletonTable />
                </PanelContent>
            </Panel>
        );
    }

    if (Object.keys(metricsByLoadBalancerInstanceId).length === 0) {
        return <RouterEmptyPanel />;
    }
    return (
        <>
            {port ? (
                <>
                    {Object.entries(metricsByLoadBalancerInstanceId).map(
                        ([lbInstanceId, routerMetrics], idx) => {
                            const lbInstance: Instance | undefined =
                                instancesByInstanceId[lbInstanceId];
                            const location =
                                instances?.includes?.locations?.[
                                    lbInstance?.location_id || ""
                                ];

                            if (!lbInstance) {
                                return null;
                            }

                            return (
                                <Panel
                                    className="w-full pb-4"
                                    key={lbInstance?.id || ""}
                                >
                                    <PanelTitle
                                        className="flex items-center justify-between"
                                        title={formatLoadBalancerInstanceName(
                                            lbInstance?.id || "",
                                            lbInstance?.provider?.vendor || "",
                                            location?.name || ""
                                        )}
                                    >
                                        {idx === 0 && (
                                            <div className="text-sm">
                                                Showing Last 24 Hours
                                            </div>
                                        )}
                                    </PanelTitle>

                                    <PanelContent stretch>
                                        <StyledDataTable>
                                            <RouterTableHeader />
                                            <tbody>
                                                <CycleErrorBoundary>
                                                    {routerMetrics &&
                                                        routerMetrics.map(
                                                            (m) => (
                                                                <RouterRow
                                                                    key={`${m.instance_id}${m.destination_instance_id}`}
                                                                    metric={m}
                                                                    environmentId={
                                                                        environment?.id ||
                                                                        ""
                                                                    }
                                                                    latency={latencyMetrics?.find(
                                                                        (l) =>
                                                                            l.destination_instance_id ===
                                                                                m.destination_instance_id &&
                                                                            l.instance_id ===
                                                                                m.instance_id
                                                                    )}
                                                                />
                                                            )
                                                        )}
                                                </CycleErrorBoundary>
                                            </tbody>
                                        </StyledDataTable>
                                    </PanelContent>
                                </Panel>
                            );
                        }
                    )}
                </>
            ) : (
                <Panel>
                    <PanelTitle title={undefined} />
                    <PanelContent stretch>
                        <StyledDataTable>
                            <RouterTableHeader />
                            <tbody>
                                <StyledTableRow>
                                    <StyledCell colSpan={5}>
                                        <EmptyResource
                                            icon={NavIcons["telemetry"]}
                                            title="No Metrics"
                                            className="w-full border-none"
                                        />
                                    </StyledCell>
                                </StyledTableRow>
                            </tbody>
                        </StyledDataTable>
                    </PanelContent>
                </Panel>
            )}
        </>
    );
}
