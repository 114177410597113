import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { useGenerateAggregatedMetricsQuery } from "~/services/cycle";
import { useContext, useEffect } from "react";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import classNames from "classnames";
import { SkeletonTable } from "@cycleplatform/ui/components/loaders/skeleton";
import { RamUsageByInstanceChart } from "~/components/instances/telemetry/charts/ram/RamUsageByInstanceChart";
import { RamUsageByInstanceTable } from "~/components/instances/telemetry/charts/ram/RamUsageByInstanceTable";
import {
    buildFindTopRamUsageInstancesQuery,
    buildRamUsageByInstanceQuery,
    RamUsageByInstanceDataPoint,
} from "~/components/instances/telemetry/charts/ram/query";
import { getInstanceIdsFromAggregateData } from "~/components/instances/telemetry/helpers";
import { InstanceChartTelemetryContext } from "../context";

export function RamSection() {
    const {
        filtering: { granularity, instanceLimit, range, isBuffering },
        instanceColorMap,
        instanceLabelMap,
        components,
        registerIds,
        criteria,
        tableInclude,
    } = useContext(InstanceChartTelemetryContext);

    const {
        data: tId,
        isLoading: tIsLoading,
        error: tError,
    } = useGenerateAggregatedMetricsQuery(
        buildFindTopRamUsageInstancesQuery({
            criteria: criteria || {},
            granularity,
            limit: instanceLimit,
            range: range,
        }),
        { skip: !criteria || isBuffering, pollingInterval: 1 * 60 * 1000 }
    );

    const topIds = tId?.data?.[0]?.instanceIds as string[] | undefined;

    const {
        data: ramUsage,
        error,
        isLoading,
    } = useGenerateAggregatedMetricsQuery(
        buildRamUsageByInstanceQuery({
            criteria: criteria || {},
            granularity: granularity,
            instanceIds: topIds,
            range,
        }),
        {
            skip: !criteria || !topIds || isBuffering,
            pollingInterval: 1 * 60 * 1000,
        }
    );

    const data = ramUsage?.data as RamUsageByInstanceDataPoint[] | undefined;
    const { instanceIds } = getInstanceIdsFromAggregateData(data);

    useEffect(() => {
        if (!topIds) {
            return;
        }
        registerIds(topIds || []);
    }, [topIds]);

    if (error) {
        throw error;
    }
    if (tError) {
        throw tError;
    }

    const renderLoading = isLoading || tIsLoading || isBuffering;

    return (
        <Panel>
            <div className="h-full w-full">
                <PanelTitle title={`RAM Usage`} />
                <PanelContent stretch className="border-none">
                    <div
                        className={classNames(
                            "m-4 h-60",
                            renderLoading && skeletonStyles
                        )}
                    >
                        {data && (
                            <RamUsageByInstanceChart
                                data={data}
                                instanceColorMap={instanceColorMap}
                                instanceLabelMap={instanceLabelMap}
                                instanceIds={instanceIds}
                            />
                        )}
                    </div>
                    {!renderLoading && data ? (
                        <RamUsageByInstanceTable
                            data={data}
                            instanceColorMap={instanceColorMap}
                            instanceLabelMap={instanceLabelMap}
                            components={components}
                            include={tableInclude}
                            instanceIds={instanceIds}
                        />
                    ) : (
                        <SkeletonTable />
                    )}
                </PanelContent>
            </div>
        </Panel>
    );
}
