import {
    Button,
    LoaderButton,
    PositionedMenuButton,
    PopoverButtonContext,
} from "@cycleplatform/ui/components/buttons";
import {
    RHF_GLOBAL_ERROR,
    RhfFormField,
    RhfGlobalFormError,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { handleSubmitError } from "~/components/forms/util";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { faFileArrowDown, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useKeepFormCurrent } from "~/components/common/forms";
import { useJobTracker } from "~/modules/jobs/hooks";
import {
    CreateImageApiArg,
    ImageSource,
    useCreateImageJobMutation,
    useCreateImageMutation,
} from "~/services/cycle";
import { useAppDispatch } from "~/hooks";
import { AccessControlOverlay } from "~/components/common/buttons";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";

type ImportImageButtonProps = {
    source?: ImageSource;
};

export function ImportImageButton({ source }: ImportImageButtonProps) {
    const setIsOpen = useRef<(open: boolean) => void>();

    const [createImage, { isLoading: isCreating }] = useCreateImageMutation();
    const [importImage, { isLoading: isImporting }] =
        useCreateImageJobMutation();
    const form = useForm<CreateImageApiArg["body"]>();
    const [trackJob] = useJobTracker();
    useKeepFormCurrent(form, source, (s) => ({
        source_id: s.id,
    }));

    const onImportImage = (values: CreateImageApiArg["body"]) => {
        if (!values.override?.target && !values.override?.targz_url) {
            delete values.override;
        }
        return createImage({
            body: { ...values, source_id: source?.id || "" },
        })
            .unwrap()
            .then((i) =>
                trackJob(
                    importImage({
                        imageId: i?.data?.id || "",
                        body: {
                            action: "import",
                        },
                    }).unwrap()
                )
            )
            .then(() => setIsOpen.current?.(false))
            .catch((err) => {
                setIsOpen.current?.(true);
                handleSubmitError(form.setError, {
                    sourceOverride: {
                        override: RHF_GLOBAL_ERROR,
                        source_id: RHF_GLOBAL_ERROR,
                    },
                })(err);
            });
    };

    return (
        <form onSubmit={form.handleSubmit(onImportImage)}>
            <FormProvider {...form}>
                <AccessControlOverlay
                    aclResource={source}
                    verifyFn={modifyAccessFn("images-sources-manage")}
                >
                    <PositionedMenuButton
                        flavor="primary"
                        icon={faFileArrowDown}
                        tooltip="Hold to import with default settings"
                        popout={
                            <ImageImportOptionsForm
                                isLoading={isCreating || isImporting}
                                source={source}
                            />
                        }
                        pushAndHold
                        isLoading={isCreating || isImporting}
                        onClick={form.handleSubmit(onImportImage)}
                        onClose={() => form.reset()}
                        setIsOpenRef={setIsOpen}
                    >
                        Import Image
                    </PositionedMenuButton>
                </AccessControlOverlay>
            </FormProvider>
        </form>
    );
}

export function ImageImportOptionsForm({
    source,
    isLoading,
}: {
    source?: ImageSource;
    isLoading: boolean;
}) {
    const { register, setValue, getValues, formState } =
        useFormContext<CreateImageApiArg["body"]>();
    const [newBuildArg, setNewBuildArg] = useState({
        variable: "",
        value: "",
    });

    const { close } = useContext(PopoverButtonContext);
    useEffect(() => {
        if (formState.isSubmitSuccessful) {
            close();
        }
    }, [formState.isSubmitSuccessful]);

    let override: React.ReactNode;

    switch (source?.origin.type) {
        case "docker-hub":
        case "docker-registry":
        case "oci-registry":
            override = (
                <RhfFormField name="override.target" label="target override">
                    <TextInput
                        {...register("override.target", {
                            setValueAs: (v) => (v === "" ? null : v),
                        })}
                        placeholder={source.origin.details?.target}
                    />
                </RhfFormField>
            );
            break;
        case "docker-file":
            override = (
                <RhfFormField
                    help="Instead of a repo url, you can provide the URL to a .tar.gz file that contains the dockerfile + repo for Cycle to build"
                    name="override.targz_url"
                    label=".tar.gz url"
                >
                    <TextInput
                        {...register("override.targz_url", {
                            setValueAs: (v) => (v === "" ? null : v),
                        })}
                    />
                </RhfFormField>
            );
    }

    return (
        <div>
            <h3 className="pb-4">Import Options</h3>
            <RhfFormField name="name" label="custom name">
                <TextInput {...register("name")} />
            </RhfFormField>

            {override}

            <StyledDataTable>
                <StyledTableHead>
                    <StyledHeaderCell>Build Args</StyledHeaderCell>
                    <StyledHeaderCell></StyledHeaderCell>
                    <StyledHeaderCell></StyledHeaderCell>
                </StyledTableHead>
                <tbody>
                    {Object.entries(getValues().build?.args || {}).map(
                        ([key, value]) => (
                            <StyledTableRow key={key}>
                                <StyledCell className="pr-2">{key}</StyledCell>
                                <StyledCell className="pr-4">
                                    {value}
                                </StyledCell>
                            </StyledTableRow>
                        )
                    )}
                    <StyledTableRow>
                        <StyledCell className="pr-2 !pl-0">
                            <TextInput
                                placeholder="Build Arg"
                                value={newBuildArg.variable}
                                onChange={(ev) =>
                                    setNewBuildArg({
                                        ...newBuildArg,
                                        variable: ev.target.value,
                                    })
                                }
                            />
                        </StyledCell>
                        <StyledCell className="pr-4">
                            <TextInput
                                placeholder="Value"
                                value={newBuildArg.value}
                                onChange={(ev) =>
                                    setNewBuildArg({
                                        ...newBuildArg,
                                        value: ev.target.value,
                                    })
                                }
                            />
                        </StyledCell>
                        <StyledCell className="text-end">
                            <Button
                                type="button"
                                className="whitespace-nowrap"
                                disabled={
                                    !newBuildArg.variable || !newBuildArg.value
                                }
                                onClick={() => {
                                    setValue(
                                        `build.args.${newBuildArg.variable}`,
                                        newBuildArg.value
                                    );
                                    setNewBuildArg({
                                        variable: "",
                                        value: "",
                                    });
                                }}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        </StyledCell>
                    </StyledTableRow>
                </tbody>
            </StyledDataTable>
            <PanelFooter className="mt-4 flex items-center justify-between">
                <div>
                    <RhfGlobalFormError />
                </div>
                <LoaderButton
                    isLoading={isLoading}
                    type="submit"
                    flavor="primary"
                    icon={faFileArrowDown}
                >
                    Import Image
                </LoaderButton>
            </PanelFooter>
        </div>
    );
}
