import { LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    DialogColumn,
    DialogFooter,
} from "@cycleplatform/ui/components/dialog/components";
import {
    RhfFormProvider,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { clearDialogParams } from "~/components/dialogs/helpers";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import {
    CreateVirtualProviderIsoApiArg,
    Integration,
    useCreateVirtualProviderIsoMutation,
} from "~/services/cycle";
import { IsoInitializeFormSection } from "../sections/IsoInitializeFormSection";
import { IsoIpxeFormSection } from "../sections/IsoIpxeFormSection";
import { IsoServerFormSection } from "../sections/IsoServerFormSection";
import { VirtualProviderIsoAside } from "./VirtualProviderIsoAside";
import { GenerateIsoFormType } from "../types";

export function GenerateIsoForm({ integration }: { integration: Integration }) {
    const form = useForm<GenerateIsoFormType>({
        ...rhfConfig,
    });
    const {
        formState: { isSubmitting },
    } = form;

    const [generateIso] = useCreateVirtualProviderIsoMutation();

    const nav = useNavigate();
    const onCreate = (data: GenerateIsoFormType) => {
        return generateIso({
            integrationId: integration.id,
            body: data,
        })
            .unwrap()
            .then(() => nav(clearDialogParams()))
            .catch(handleSubmitError(form.setError));
    };

    return (
        <div className="flex w-full">
            <DialogColumn className="w-2/3">
                <RhfFormProvider
                    {...form}
                    onSubmit={form.handleSubmit(onCreate)}
                >
                    <IsoInitializeFormSection />

                    <IsoIpxeFormSection />

                    <IsoServerFormSection />

                    <DialogFooter className="items-center justify-between">
                        <div>
                            <RhfGlobalFormError />
                        </div>
                        <LoaderButton
                            flavor="primary"
                            icon={faPlus}
                            onClick={form.handleSubmit(onCreate)}
                            isLoading={isSubmitting}
                        >
                            Create
                        </LoaderButton>
                    </DialogFooter>
                </RhfFormProvider>
            </DialogColumn>

            <VirtualProviderIsoAside />
        </div>
    );
}
