import { formatRFC3339, roundToNearestMinutes, subDays } from "date-fns";

export type LookupDataPoint = {
    time: string;
    domain: string;
    question: string;
    cached: number;
    not_found: number;
    throttled_hits: number;
    total_lookups: number;
    forwarded: number;
};

export function buildRecentLookupsQuery(environmentId: string | undefined) {
    const intervalMinutes = 15;
    const start = formatRFC3339(
        roundToNearestMinutes(subDays(new Date(), 1), {
            nearestTo: intervalMinutes,
            roundingMethod: "floor",
        })
    );
    return {
        filter: {
            "range-start": start,
        },
        body: {
            criteria: {
                "metadata.metric": {
                    $in: [
                        "discovery.resolutions.lookups",
                        "discovery.resolutions.cache_hits",
                        "discovery.resolutions.not_founds",
                        "discovery.resolutions.throttled_hits",
                    ],
                },
                "labels.environment_id": environmentId,
            },
            pipeline: [
                {
                    $sort: {
                        time: 1,
                    },
                },
                {
                    $project: {
                        metadata: "$metadata",
                        labels: "$labels",
                        time: "$time",
                        point: {
                            $arrayElemAt: [
                                {
                                    $last: "$points",
                                },
                                1,
                            ],
                        },
                    },
                },
                {
                    $group: {
                        _id: {
                            domain: "$labels.domain",
                            question: "$labels.question",
                        },
                        time: {
                            $last: "$time",
                        },
                        domain: {
                            $first: "$labels.domain",
                        },
                        question: {
                            $first: "$labels.question",
                        },
                        cached: {
                            $sum: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "discovery.resolutions.cache_hits",
                                        ],
                                    },
                                    then: "$point",
                                    else: 0,
                                },
                            },
                        },
                        not_found: {
                            $sum: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "discovery.resolutions.not_founds",
                                        ],
                                    },
                                    then: "$point",
                                    else: 0,
                                },
                            },
                        },
                        total_lookups: {
                            $sum: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "discovery.resolutions.lookups",
                                        ],
                                    },
                                    then: "$point",
                                    else: 0,
                                },
                            },
                        },
                        throttled_hits: {
                            $sum: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "discovery.resolutions.throttled_hits",
                                        ],
                                    },
                                    then: "$point",
                                    else: 0,
                                },
                            },
                        },
                    },
                },
                {
                    $addFields: {
                        forwarded: {
                            $subtract: [
                                "$total_lookups",
                                {
                                    $add: [
                                        "$not_found",
                                        "$cached",
                                        "$throttled_hits",
                                    ],
                                },
                            ],
                        },
                    },
                },
                {
                    $sort: {
                        domain: 1,
                        question: 1,
                    },
                },
                {
                    $project: {
                        _id: 0,
                    },
                },
            ],
        },
    };
}
