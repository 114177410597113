import {
    faPlay,
    faPlus,
    faTrash,
    faSync,
    faArrowCircleUp,
    faStop,
    faQuestionCircle,
    faTerminal,
    faLocationDot,
    faLocationDotSlash,
} from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import {
    Activity,
    ComponentIncludes,
    Environment,
    VirtualMachine,
    VirtualMachineSshKey,
} from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import {
    EnvironmentLink,
    VirtualMachineLink,
} from "~/components/common/resources/ResourceLinks";

export function formatVmMessage(item: Activity, components: ComponentIncludes) {
    const vmLink = (
        <VirtualMachineLink
            vmId={item.context.virtual_machine_id}
            components={components}
        />
    );

    const envLink = (
        <EnvironmentLink
            environmentId={item.context.environment_id}
            components={components}
        />
    );

    // link to env settings for SSH key
    const sshKeyLink = (
        <Link to={`/environments/${item.context.environment_id}/settings`}>
            {extractSshKeyName(item.component?.id || "", components)}
        </Link>
    );

    switch (item.event) {
        case "virtual-machine.initialize":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Initialized virtual machine {vmLink} in environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>Error creating virtual machine</span>
                ),
                icon: faPlus,
            };

        case "virtual-machine.task.start":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested start of virtual machine {vmLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting start of virtual machine {vmLink} in
                        environment {envLink}
                    </span>
                ),
                icon: faPlay,
            };
        case "virtual-machine.start":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Started virtual machine {vmLink} in environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>
                        Error starting virtual machine {vmLink} in environment{" "}
                        {envLink}
                    </span>
                ),
                icon: faPlay,
            };

        case "virtual-machine.task.stop":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested stop of virtual machine {vmLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting stop of virtual machine {vmLink} in
                        environment {envLink}
                    </span>
                ),
                icon: faStop,
            };

        case "virtual-machine.stop":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Stopped virtual machine {vmLink} in environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>
                        Error stopping virtual machine {vmLink} in environment{" "}
                        {envLink}
                    </span>
                ),
                icon: faStop,
            };

        case "virtual-machine.reconfigure":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Reconfigured virtual machine {vmLink} in environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>
                        Error reconfiguring virtual machine {vmLink} in
                        environment {envLink}
                    </span>
                ),
                icon: faSync,
            };

        case "virtual-machine.task.reconfigure":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested reconfigure of virtual machine {vmLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>Error requesting reconfigure of virtual machine</span>
                ),
                icon: faSync,
            };

        case "virtual-machine.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Created virtual machine {vmLink} in environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>Error creating virtual machine</span>
                ),
                icon: faPlus,
            };

        case "virtual-machine.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Updated virtual machine {vmLink} in environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>
                        Error updating virtual machine {vmLink} in environment{" "}
                        {envLink}
                    </span>
                ),
                icon: faArrowCircleUp,
            };

        case "virtual-machine.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested deletion of virtual machine {vmLink} from
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting deletion of virtual machine {vmLink}{" "}
                        from environment {envLink}
                    </span>
                ),
                icon: faTrash,
            };

        case "virtual-machine.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Deleted virtual machine {vmLink} from environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>Error deleting virtual machine {vmLink}</span>
                ),
                icon: faTrash,
            };

        case "virtual-machine.sos.login": {
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Connected via serial-over-SSH to virtual machine{" "}
                        {vmLink}
                    </span>
                ) : (
                    <span>
                        Error connecting via serial-over-SSH to virtual machine{" "}
                        {vmLink}
                    </span>
                ),
                icon: faTerminal,
            };
        }
        // SSH Keys
        case "virtual-machine.ssh-key.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Created virtual machine SSH key {sshKeyLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error creating virtual machine SSH key in environment{" "}
                        {envLink}
                    </span>
                ),
                icon: faPlus,
            };
        case "virtual-machine.ssh-key.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Updated virtual machine SSH key {sshKeyLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error updating virtual machine SSH key {sshKeyLink} in
                        environment {envLink}
                    </span>
                ),
                icon: faArrowCircleUp,
            };

        case "virtual-machine.ssh-key.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested deletion of virtual machine SSH key{" "}
                        {sshKeyLink} in environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting deletion of virtual machine SSH key{" "}
                        {sshKeyLink} in environment {envLink}
                    </span>
                ),
                icon: faTrash,
            };

        case "virtual-machine.ssh-key.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Deleted virtual machine SSH key {sshKeyLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error deleting virtual machine SSH key {sshKeyLink}
                    </span>
                ),
                icon: faTrash,
            };

        // IP allocations
        case "virtual-machine.task.ip.allocate":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested allocation of public IP address for virtual
                        machine {vmLink} in environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting allocation of public IP address for
                        virtual machine {vmLink} in environment {envLink}
                    </span>
                ),
                icon: faLocationDot,
            };
        case "virtual-machine.ip.allocate": {
            const ipAddressLink = item.annotations.ip_addr ? (
                <Link to={`/infrastructure/ips/${item.annotations.ip_pool_id}`}>
                    {item.annotations.ip_addr}
                </Link>
            ) : null;
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Allocated public IP address{" "}
                        {ipAddressLink ? ipAddressLink : ""} to virtual machine{" "}
                        {vmLink} in environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting allocation of public IP address{" "}
                        {ipAddressLink ? ipAddressLink : ""} for virtual machine{" "}
                        {vmLink} in environment {envLink}
                    </span>
                ),
                icon: faLocationDot,
            };
        }
        case "virtual-machine.task.ip.unallocate":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested removal of public IP address from virtual
                        machine {vmLink} in environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting removal of public IP address from
                        virtual machine {vmLink} in environment {envLink}
                    </span>
                ),
                icon: faLocationDotSlash,
            };
        case "virtual-machine.ip.unallocate": {
            const ipAddressLink = item.annotations.ip_addr ? (
                <Link to={`/infrastructure/ips/${item.annotations.ip_pool_id}`}>
                    {item.annotations.ip_addr}
                </Link>
            ) : null;
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Unallocated public IP address{" "}
                        {ipAddressLink ? ipAddressLink : ""} from virtual
                        machine {vmLink} in environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error unallocating public IP address{" "}
                        {ipAddressLink ? ipAddressLink : ""} from virtual
                        machine {sshKeyLink} in environment {envLink}
                    </span>
                ),
                icon: faLocationDot,
            };
        }
        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}

function extractSshKeyName(id: string, components: ComponentIncludes) {
    const c = components?.[id];
    if (!c) {
        return id;
    }

    return (c as VirtualMachineSshKey).name;
}
