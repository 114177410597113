import {
    BillingMethod,
    CreateBillingMethodApiArg,
    UpdateBillingMethodApiArg,
    useCreateBillingMethodMutation,
    useGetHubQuery,
    useUpdateBillingMethodMutation,
} from "~/services/cycle";
import { Controller, useForm, useFormContext, useWatch } from "react-hook-form";
import {
    RhfFormField,
    TextInput,
    maxLength,
    required,
    FormSectionHeader,
    FormSection,
} from "@cycleplatform/ui/components/forms";
import { CreateBillingMethodFormType } from "../CreateBillingMethodForm";
import { useState } from "react";
import { CreditCardTextInput } from "./CreditCardTextInput";
import { ExpirationInput } from "./ExpirationInput";

export function CreditCardFormSection() {
    const { register } = useFormContext<CreateBillingMethodFormType>();

    return (
        <>
            <FormSectionHeader header="Credit Card" />
            <FormSection>
                <RhfFormField
                    label="Card Holder Name"
                    name="credit_card.name"
                    className="flex-1"
                    required
                >
                    <TextInput
                        {...register("credit_card.name", {
                            ...required(),
                        })}
                    />
                </RhfFormField>

                <div className="flex gap-2">
                    <RhfFormField
                        label="card number"
                        name="credit_card.number"
                        required
                    >
                        <CreditCardTextInput />
                    </RhfFormField>

                    <RhfFormField
                        label="expiration date"
                        name="credit_card._tmpexpires"
                        className="!w-[15rem]"
                        required
                    >
                        <ExpirationInput />
                    </RhfFormField>
                    {/* Security Code */}
                    <RhfFormField
                        label="security code"
                        name="credit_card.cvv"
                        className="!w-[10rem]"
                        required
                    >
                        <TextInput
                            {...register("credit_card.cvv", {
                                ...required(),
                                ...maxLength(5),
                            })}
                        />
                    </RhfFormField>
                </div>
            </FormSection>
        </>
    );
}
