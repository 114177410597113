import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Tooltip } from "../tooltip";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

type HelpInfoCircleProps = {
    message: React.ReactNode;
    className?: string;
    popupClassName?: string;
    size?: SizeProp;
};

export function HelpInfoCircle({
    message,
    className,
    popupClassName,
    size,
}: HelpInfoCircleProps) {
    return (
        <Tooltip
            as="span"
            message={message}
            popupClassName={classNames(
                popupClassName,
                "normal-case font-normal"
            )}
            className={classNames(
                className,
                "text-cycle-blue flex items-center text-xs"
            )}
        >
            <FontAwesomeIcon size={size} icon={faInfoCircle} />
        </Tooltip>
    );
}
