import {
    faTrash,
    faArrowCircleUp,
    faQuestionCircle,
    faFileCertificate,
} from "@fortawesome/pro-solid-svg-icons";
import { Activity, ComponentIncludes, DnsRecord } from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import { getRecordType } from "@cycleplatform/core/modules/dns/records";
import { DnsRecordLink } from "~/components/common/resources/ResourceLinks";

export function formatDnsRecordMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const type = extractRecordType(item.component?.id || "", components);

    const recordLink = (
        <DnsRecordLink recordId={item.component?.id} components={components} />
    );

    switch (item.event) {
        case "dns.zone.record.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested deletion of DNS {type} record for {recordLink}
                    </span>
                ) : (
                    <span>
                        Error requesting deletion of DNS {type} record for{" "}
                        {recordLink}
                    </span>
                ),
                icon: faTrash,
            };
        case "dns.zone.record.task.cert.generate": {
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requesting TLS certificate creation for DNS {type}
                        record {recordLink}
                    </span>
                ) : (
                    <span>
                        Error requesting TLS certificate creation for DNS
                        {type} record
                        {recordLink}
                    </span>
                ),
                icon: faFileCertificate,
            };
        }
        case "dns.zone.record.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Deleted DNS {type} record for {recordLink}
                    </span>
                ) : (
                    <span>
                        Error deleting DNS {type} record for {recordLink}
                    </span>
                ),
                icon: faTrash,
            };
        case "dns.zone.record.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Updated DNS {type} record for {recordLink}
                    </span>
                ) : (
                    <span>
                        Error updating DNS {type} record for {recordLink}
                    </span>
                ),
                icon: faArrowCircleUp,
            };
        case "dns.zone.record.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Created DNS {type} record for {recordLink}
                    </span>
                ) : (
                    <span>Error creating DNS record</span>
                ),
                icon: faFileCertificate,
            };
        case "dns.zone.record.cert.generate":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Generated TLS certificate for DNS {type} record{" "}
                        {recordLink}
                    </span>
                ) : (
                    <span>
                        Error generating TLS certificate for DNS {type}
                        record {recordLink}
                    </span>
                ),
                icon: faFileCertificate,
            };
        case "dns.zone.record.cert.generate.auto":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Automatically renewed TLS certificate for DNS {type}{" "}
                        record {recordLink}
                    </span>
                ) : (
                    <span>
                        Error automatically renewing TLS certificate for DNS{" "}
                        {type} record {recordLink}
                    </span>
                ),
                icon: faFileCertificate,
            };
        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}

function extractRecordType(id: string, components: ComponentIncludes) {
    const c = components?.[id];
    if (!c) {
        return "";
    }

    return getRecordType(c as DnsRecord);
}
