import { tierCategories } from "@cycleplatform/core/modules/tiers/util";
import { CategorySection } from "./CategorySection";
import { PlanTierButton } from "./PlanTierButton";
import { TierPlan } from "~/services/cycle";
import classNames from "classnames";
import { PLAN_TILE_CLASSNAME } from "@cycleplatform/ui/components/resources";
import { getOptionButtonClassName } from "@cycleplatform/ui/components/options/styles";
import { EnterpriseContent } from "./EnterpriseContent";

type TierCategorySectionsProps = {
    tiersByCategory: {
        nonCommercial: TierPlan[];
        teams: TierPlan[];
        custom: TierPlan[];
    };
    selectedTierId: string | undefined;
    currentTierId: string | null;
    activeTierPrice: number;
};

export function TierCategorySections({
    tiersByCategory,
    selectedTierId,
    currentTierId,
    activeTierPrice,
}: TierCategorySectionsProps) {
    return (
        <div className="flex w-full flex-col gap-4 p-4">
            {/* Non Commercial */}
            <CategorySection tab={tierCategories.NON_COMMERCIAL}>
                {tiersByCategory?.nonCommercial?.map((t) => (
                    <PlanTierButton
                        tier={t}
                        selectedTierId={selectedTierId}
                        currentTierId={currentTierId}
                        activeTierPrice={activeTierPrice}
                    />
                ))}
            </CategorySection>

            {/* Teams */}
            <CategorySection tab={tierCategories.TEAMS} isDefault>
                {tiersByCategory?.teams?.map((t) => (
                    <PlanTierButton
                        tier={t}
                        selectedTierId={selectedTierId}
                        currentTierId={currentTierId}
                        activeTierPrice={activeTierPrice}
                    />
                ))}
            </CategorySection>

            {/* Enterprise */}
            <CategorySection tab={tierCategories.ENTERPRISE}>
                <div
                    className={classNames(
                        "pointer-events-none p-4",
                        PLAN_TILE_CLASSNAME,
                        getOptionButtonClassName(false)
                    )}
                >
                    <EnterpriseContent />
                </div>
            </CategorySection>

            {/* Custom */}
            <CategorySection tab={tierCategories.CUSTOM}>
                {tiersByCategory?.custom?.map((t) => (
                    <PlanTierButton
                        tier={t}
                        selectedTierId={selectedTierId}
                        currentTierId={currentTierId}
                        activeTierPrice={activeTierPrice}
                    />
                ))}
            </CategorySection>
        </div>
    );
}
