import { roundToNearestMinutes } from "date-fns";
import { formatRFC3339, subDays } from "date-fns";

export type LbTrafficQueryTelem = {
    time: string;
    instances: {
        instance_id: string;
        metrics: LbInstanceTrafficMetric[];
    }[];
}[];

export type LbInstanceTrafficMetric = { metric: string; value: number };

export function getLbTrafficQuery(
    envId: string | undefined,
    port: number | undefined
) {
    return {
        filter: {
            "range-start": formatRFC3339(
                roundToNearestMinutes(subDays(new Date(), 1), {
                    roundingMethod: "floor",
                })
            ),
        },
        body: {
            criteria: {
                "metadata.metric": {
                    $in: [
                        "lb.controller.received_kb",
                        "lb.controller.transmitted_kb",
                        "lb.controller.connections",
                        "lb.controller.requests",
                        "lb.controller.disconnects.destination_unavailable",
                        "lb.controller.disconnects.no_error",
                        "lb.controller.disconnects.timeout_idle",
                        "lb.controller.disconnects.timeout_init",
                        "lb.controller.disconnects.total",
                        "lb.controller.disconnects.unknown_error",
                        "lb.controller.disconnects.connection_invalid",
                        "lb.controller.disconnects.router_none",
                        "lb.controller.disconnects.router_nomatch",
                        "lb.controller.disconnects.waf_blocked",
                    ],
                },
                "labels.port": `${port}`,
                "labels.environment_id": envId,
            },
            pipeline: [
                {
                    $sort: {
                        time: -1,
                    },
                },
                {
                    $group: {
                        _id: {
                            metric: "$metadata.metric",
                            instance: "$labels.instance_id",
                        },
                        time: {
                            $first: "$time",
                        },
                        point_value: {
                            $first: {
                                $arrayElemAt: [
                                    {
                                        $arrayElemAt: ["$points", 0],
                                    },
                                    1,
                                ],
                            },
                        },
                    },
                },
                {
                    $group: {
                        _id: {
                            instance: "$_id.instance",
                        },
                        time: {
                            $first: "$time",
                        },
                        metrics: {
                            $push: {
                                metric: "$_id.metric",
                                value: "$point_value",
                            },
                        },
                    },
                },
                {
                    $group: {
                        _id: null,
                        time: {
                            $first: "$time",
                        },
                        instances: {
                            $push: {
                                instance_id: "$_id.instance",
                                metrics: "$metrics",
                            },
                        },
                    },
                },
                {
                    $sort: {
                        time: -1,
                    },
                },
                {
                    $limit: 1,
                },
                {
                    $project: {
                        _id: 0,
                        time: 1,
                        instances: 1,
                    },
                },
            ],
        },
    };
}
