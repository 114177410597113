import {
    StyledHeaderCell,
    StyledTableHead,
} from "@cycleplatform/ui/components/tables";

export function RouterTableHeader() {
    return (
        <StyledTableHead>
            <StyledHeaderCell className="w-1/8 min-w-[20rem] ">
                Destination
            </StyledHeaderCell>
            <StyledHeaderCell className="w-1/5 text-center">
                Latency
            </StyledHeaderCell>

            <StyledHeaderCell className="w-1/6 text-center">
                Requests
            </StyledHeaderCell>

            <StyledHeaderCell className="min-w-[8rem]">
                Connections
            </StyledHeaderCell>
            <StyledHeaderCell className="w-1/8 min-w-[8rem] max-w-[12rem]">
                Success Rate
            </StyledHeaderCell>
        </StyledTableHead>
    );
}
