import { RhfFormProvider } from "@cycleplatform/ui/components/forms";
import { PageContent } from "@cycleplatform/ui/components/page";
import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import classNames from "classnames";
import { useCallback, useContext, useRef } from "react";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { VirtualMachineDialogContex } from "../../context";
import { useForm } from "react-hook-form";
import { VmConfigFormData } from "./types";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { AccessControlledSection } from "~/components/layout/AccessControlledSection";
import { VmNetworkSection } from "./network/NetworkSection";
import { VmDeploymentSection } from "./deploy/DeploymentSection";
import { useJobTracker } from "~/modules/jobs/hooks";
import { useCreateVirtualMachineJobMutation } from "~/services/cycle";
import { useKeepFormCurrent } from "~/components/common/forms";
import { VmResourcesSection } from "./resources/VmResourcesSection";
import { getVmConfigBody, getVmConfigDefaults } from "./helpers";
import { $info } from "@cycleplatform/core/util/log";
import { VmConfigFooter } from "./VmConfigFooter";
import { Link } from "react-router-dom";
import {
    generateDialogLink,
    getAllDialogSearchParams,
} from "~/components/dialogs/helpers";
import { BetaBadge } from "@cycleplatform/ui/components/badges";
import { useHandleConfigSmoothScroll } from "~/components/dialogs/containers/container/config/helpers";

export function VmConfigTab() {
    const params = getAllDialogSearchParams<"virtual-machine">();

    const configParam = params["dialog-config"];

    const { vm, environment } = useContext(VirtualMachineDialogContex);
    const ref = useRef<HTMLDivElement>(null);

    const [trackJob, { isTrackingJob }] = useJobTracker();
    const [createVirtualMachineJob] = useCreateVirtualMachineJobMutation();

    const form = useForm<VmConfigFormData>({
        defaultValues: getVmConfigDefaults(vm),
        ...rhfConfig,
    });

    useKeepFormCurrent(form, vm, (vm) => getVmConfigDefaults(vm));

    const onSubmit = useCallback(
        (values: VmConfigFormData) => {
            if (!vm) {
                return;
            }
            const submitBody = getVmConfigBody(values);

            if (!submitBody) {
                return;
            }

            return trackJob(
                createVirtualMachineJob({
                    virtualMachineId: vm.id,
                    virtualMachineTask: {
                        action: "reconfigure",
                        contents: submitBody,
                    },
                }).unwrap()
            ).then(
                (f) => $info("complete", f),
                handleSubmitError(form.setError, { pathPrefix: "config" })
            );
        },
        [vm]
    );

    const {
        formState: { isSubmitting },
    } = form;

    // Borrow functionality from container config since the section names are the same
    const { network, deployment, resources } = useHandleConfigSmoothScroll(
        configParam,
        undefined
    );

    if (!vm) {
        return null;
    }

    return (
        <RhfFormProvider
            {...form}
            onSubmit={form.handleSubmit(onSubmit)}
            className="h-full"
        >
            <div className="relative h-full w-full overflow-hidden">
                <DialogPageBody
                    className={classNames(
                        "absolute inset-0 overflow-hidden pr-0"
                    )}
                >
                    <div
                        className={classNames(
                            "fixed flex h-full w-[16rem] flex-col gap-4 overflow-hidden pr-4"
                        )}
                    >
                        <ConfigNavigation
                            title="Network"
                            value="network"
                            hasError={!!form.formState.errors?.config?.network}
                            onClick={network.scroll}
                            config={configParam}
                        />
                        <ConfigNavigation
                            title="Deployment"
                            value="deployment"
                            hasError={!!form.formState.errors?.config?.deploy}
                            onClick={deployment.scroll}
                            config={configParam}
                        />
                        <ConfigNavigation
                            title="Resources"
                            value="resources"
                            hasError={
                                !!form.formState.errors?.config?.resources
                            }
                            onClick={resources.scroll}
                            config={configParam}
                        />
                    </div>
                    <div
                        ref={ref}
                        className="ml-[16rem] h-full w-full overflow-y-auto pr-4"
                    >
                        <AccessControlledSection
                            aclResource={environment}
                            verifyFn={modifyAccessFn("containers-manage")}
                            className="h-full w-full "
                        >
                            {vm && (
                                <div className="flex flex-col gap-4 pb-24">
                                    <div ref={network.ref}>
                                        <VmNetworkSection />
                                    </div>

                                    <div ref={deployment.ref}>
                                        <VmDeploymentSection />
                                    </div>

                                    <div ref={resources.ref}>
                                        <VmResourcesSection />
                                    </div>
                                </div>
                            )}
                        </AccessControlledSection>
                    </div>
                    <PageContent className="h-full"></PageContent>
                </DialogPageBody>

                <VmConfigFooter
                    isSubmitting={isTrackingJob || isSubmitting}
                    onSubmit={onSubmit}
                />
            </div>
        </RhfFormProvider>
    );
}

type ConfigNavigationProps = {
    title: string;
    value: string;
    hasError: boolean;
    onClick?: () => void;
    config: string | undefined;
    isBeta?: boolean;
};

function ConfigNavigation({
    title,
    value,
    hasError,
    onClick,
    isBeta,
    config,
}: ConfigNavigationProps) {
    const isActive = config === value || (!config && value === "network");
    return (
        <Link
            key={title}
            to={generateDialogLink("virtual-machine", {
                "dialog-config": value,
                "dialog-tab": "config",
            })}
            className={classNames(
                "dark:border-cycle-gray dark:bg-cycle-gray-accent  border-t-2 border-l-2 border-b-0 bg-white p-4 text-inherit",
                "!dark:hover:text-cycle-blue-accent ",
                hasError && "!border-error !text-error ",
                isActive && "!text-cycle-blue"
            )}
            onClick={() => {
                if (isActive) {
                    onClick?.();
                }
            }}
        >
            {title} {isBeta && <BetaBadge />}
        </Link>
    );
}
