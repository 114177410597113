import {
    faTrash,
    faArrowCircleUp,
    faQuestionCircle,
    faPlus,
    faSync,
    faShield,
    faFileLock,
    faArrowRotateBack,
    faArrowRightToLine,
} from "@fortawesome/pro-solid-svg-icons";
import { Activity, ComponentIncludes, Server } from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import { ServerLink } from "~/components/common/resources/ResourceLinks";

export function formatServerMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const serverLink = (
        <ServerLink components={components} serverId={item.component?.id} />
    );

    switch (item.event) {
        case "infrastructure.server.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested deletion of server {serverLink}</span>
                ) : (
                    <span>
                        Error requesting deletion of server {serverLink}
                    </span>
                ),
                icon: faTrash,
            };
        case "infrastructure.server.task.restart":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested restart of server {serverLink}</span>
                ) : (
                    <span>Error requesting restart of server {serverLink}</span>
                ),
                icon: faSync,
            };

        case "infrastructure.server.services.sftp.auth":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Authenticated via SFTP to server {serverLink}</span>
                ) : (
                    <span>
                        Detected failed authentication via SFTP to server{" "}
                        {serverLink}
                    </span>
                ),
                icon: faFileLock,
            };

        case "infrastructure.server.task.provision":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested provision of server {serverLink}</span>
                ) : (
                    <span>
                        Error requesting provision of server {serverLink}
                    </span>
                ),
                icon: faPlus,
            };
        case "infrastructure.server.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated server {serverLink}</span>
                ) : (
                    <span>Error updating server {serverLink}</span>
                ),
                icon: faArrowCircleUp,
            };
        case "infrastructure.server.unquarantine":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Removed server {serverLink} from quarantine</span>
                ) : (
                    <span>
                        Error removing server {serverLink} from quarantine
                    </span>
                ),
                icon: faShield,
            };
        case "infrastructure.server.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted server {serverLink}</span>
                ) : (
                    <span>Error deleting server {serverLink}</span>
                ),
                icon: faTrash,
            };
        case "infrastructure.server.restart":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Restarted server {serverLink}</span>
                ) : (
                    <span>Error restarting server {serverLink}</span>
                ),
                icon: faSync,
            };
        case "infrastructure.server.compute.restart":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested restart of the compute service on server{" "}
                        {serverLink}
                    </span>
                ) : (
                    <span>
                        Error requesting restart of the compute service on
                        server {serverLink}
                    </span>
                ),
                icon: faSync,
            };

        case "infrastructure.server.compute.spawner.restart":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested restart of the compute spawner on server{" "}
                        {serverLink}
                    </span>
                ) : (
                    <span>
                        Error requesting restart of the compute spawner on
                        server {serverLink}
                    </span>
                ),
                icon: faSync,
            };
        case "infrastructure.server.provision":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Provisioned server {serverLink}</span>
                ) : (
                    <span>Error provisioning server {serverLink}</span>
                ),
                icon: faPlus,
            };
        case "infrastructure.server.live":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Server {serverLink} has come online</span>
                ) : (
                    <span>Error bringing server {serverLink} online</span>
                ),
                icon: faPlus,
            };
        case "infrastructure.server.services.sftp.lockdown":
            return {
                message: (
                    <span>
                        SFTP service on server {serverLink} has automatically
                        entered lockdown mode due to large volume of connections
                    </span>
                ),
                icon: faShield,
            };
        case "infrastructure.server.console":
            return {
                message: (
                    <span>
                        Detected unauthorized console access to server{" "}
                        {serverLink}.
                    </span>
                ),
                icon: faShield,
            };
        case "infrastructure.server.ssh.token":
            return {
                message: (
                    <span>
                        Detected unauthorized attempt to generate SSH token for
                        access to server {serverLink}.
                    </span>
                ),
                icon: faShield,
            };
        case "infrastructure.server.task.features.reconfigure":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested reconfiguration of features for server{" "}
                        {serverLink}
                    </span>
                ) : (
                    <span>
                        Error requesting reconfiguration of features for server{" "}
                        {serverLink}
                    </span>
                ),
                icon: faPlus,
            };
        case "infrastructure.server.features.reconfigure":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Reconfigured features for server {serverLink}</span>
                ) : (
                    <span>
                        Error reconfiguring features for server {serverLink}
                    </span>
                ),
                icon: faShield,
            };

        case "infrastructure.server.sharedfs.reconfigure":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Reconfigured shared file system for server {serverLink}
                    </span>
                ) : (
                    <span>
                        Error reconfiguring shared file system for server{" "}
                        {serverLink}
                    </span>
                ),
                icon: faSync,
            };

        case "infrastructure.server.task.sharedfs.reconfigure":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested reconfiguration of shared file system for
                        server {serverLink}
                    </span>
                ) : (
                    <span>
                        Error requesting reconfiguration of shared file system
                        for server {serverLink}
                    </span>
                ),
                icon: faSync,
            };

        case "infrastructure.server.services.internal-api.throttle":
            return {
                message: (
                    <span>
                        Throttled the internal API on server {serverLink} due to
                        excessive requests
                    </span>
                ),
                icon: faShield,
            };
        case "infrastructure.server.task.evacuation.start":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested evacuation of server {serverLink}</span>
                ) : (
                    <span>
                        Error requesting evacuation of server {serverLink}
                    </span>
                ),
                icon: faArrowRightToLine,
            };
        case "infrastructure.server.evacuation.start":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Evacuated server {serverLink}</span>
                ) : (
                    <span>Error evacuating server {serverLink}</span>
                ),
                icon: faArrowRightToLine,
            };
        case "infrastructure.server.task.evacuation.reset":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested an evacuation reset on server {serverLink}
                    </span>
                ) : (
                    <span>
                        Error requesting evacuation reset on server {serverLink}
                    </span>
                ),
                icon: faArrowRotateBack,
            };
        case "infrastructure.server.evacuation.reset":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Reset evacuation on server {serverLink}</span>
                ) : (
                    <span>
                        Error resetting evacuating on server {serverLink}
                    </span>
                ),
                icon: faArrowRotateBack,
            };

        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}
