import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "../../../tooltip";
import classNames from "classnames";
import { faGlobeAmericas } from "@fortawesome/pro-duotone-svg-icons";
import { NetworkDiagramStatus } from "../util";

export function PublicInternetComponent({
    status,
}: {
    status: NetworkDiagramStatus;
}) {
    return (
        <Tooltip message="Public Internet">
            <FontAwesomeIcon
                icon={faGlobeAmericas}
                className={classNames(
                    {
                        "text-cycle-blue": status.internet.status === "ok",
                        "text-warning": status.internet.status === "warning",
                        "text-cycle-gray":
                            status.internet.status === "disabled",
                    },
                    "text-3xl"
                )}
            />
        </Tooltip>
    );
}
