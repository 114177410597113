import {
    TextInput,
    RhfFormField,
    required,
    SectionDisabledControl,
} from "@cycleplatform/ui/components/forms";
import { Controller, useFormContext } from "react-hook-form";
import {
    BasicSelect,
    FormattedOption,
    SelectInput,
} from "@cycleplatform/ui/components/forms/select";
import { LoadBalancerFormValues } from "../form";
import classNames from "classnames";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";

type PortFormProps = {
    baseRoute: "config.details.default" | `config.details.ports.${string}`;
    activePortParam: string;
    port: string;
    disabled?: boolean;
};

export function PortForm({
    baseRoute,
    activePortParam,
    port,
    disabled,
}: PortFormProps) {
    const { register, watch, control } =
        useFormContext<LoadBalancerFormValues>();

    const details = watch("config.details");

    return (
        <div
            className={classNames(
                `${port === activePortParam ? "visible" : "hidden"}`,
                "w-full pb-24"
            )}
        >
            <SectionDisabledControl disabled={!details || disabled}>
                <div className="flex flex-col gap-4">
                    <div className="w-full">
                        <Panel>
                            <PanelTitle title="Frontend" />
                            <PanelContent>
                                <div className="grid grid-cols-2 gap-x-4">
                                    <RhfFormField
                                        label="Mode"
                                        name={`${baseRoute}.frontend.mode`}
                                        required
                                    >
                                        <Controller
                                            render={({
                                                field: { ref: _ref, ...field },
                                            }) => (
                                                <SelectInput
                                                    {...field}
                                                    options={["tcp", "http"]}
                                                    formatDisplayValue={(v) =>
                                                        v?.toUpperCase()
                                                    }
                                                    formatOption={(v) => (
                                                        <FormattedOption
                                                            label={v?.toUpperCase()}
                                                        />
                                                    )}
                                                    disabled={
                                                        port === "default"
                                                    }
                                                />
                                            )}
                                            rules={{ ...required() }}
                                            control={control}
                                            name={`${baseRoute}.frontend.mode`}
                                        />
                                    </RhfFormField>
                                    <RhfFormField
                                        label="Max Connections"
                                        name={`${baseRoute}.frontend.max_connections`}
                                    >
                                        <TextInput
                                            className="w-full border-gray-200"
                                            type="number"
                                            placeholder="No Limit"
                                            {...register(
                                                `${baseRoute}.frontend.max_connections`,
                                                {
                                                    setValueAs: (v) =>
                                                        !v ? null : parseInt(v),
                                                }
                                            )}
                                        />
                                    </RhfFormField>
                                    <RhfFormField
                                        label="Client Timeout [seconds]"
                                        name={`${baseRoute}.frontend.timeouts.client_secs`}
                                    >
                                        <TextInput
                                            className="w-full border-gray-200"
                                            type="number"
                                            placeholder="No Timeout"
                                            {...register(
                                                `${baseRoute}.frontend.timeouts.client_secs`,
                                                {
                                                    setValueAs: (v) =>
                                                        !v ? null : parseInt(v),
                                                }
                                            )}
                                        />
                                    </RhfFormField>
                                    <RhfFormField
                                        label="Client Finish Timeout [1ms]"
                                        name={`${baseRoute}.frontend.timeouts.client_fin_ms`}
                                    >
                                        <TextInput
                                            className="w-full border-gray-200"
                                            type="number"
                                            placeholder="No Timeout"
                                            {...register(
                                                `${baseRoute}.frontend.timeouts.client_fin_ms`,
                                                {
                                                    setValueAs: (v) =>
                                                        !v ? null : parseInt(v),
                                                }
                                            )}
                                        />
                                    </RhfFormField>
                                </div>
                            </PanelContent>
                        </Panel>
                    </div>
                    <div className="w-full">
                        <Panel>
                            <PanelTitle title="Backend" />
                            <PanelContent>
                                <div className="grid grid-cols-2 gap-x-4">
                                    <RhfFormField
                                        label="Balance"
                                        name={`${baseRoute}.backend.balance`}
                                        required
                                    >
                                        <Controller
                                            render={({
                                                field: { ref: _ref, ...field },
                                            }) => (
                                                <BasicSelect
                                                    {...field}
                                                    options={[
                                                        {
                                                            label: "Round Robin",
                                                            value: "roundrobin",
                                                        },
                                                        {
                                                            label: "Static Round Robin",
                                                            value: "static-rr",
                                                        },
                                                        {
                                                            label: "Least Connection",
                                                            value: "leastconn",
                                                        },
                                                        {
                                                            label: "First",
                                                            value: "first",
                                                        },
                                                        {
                                                            label: "Source",
                                                            value: "source",
                                                        },
                                                    ]}
                                                />
                                            )}
                                            rules={{ ...required() }}
                                            control={control}
                                            name={`${baseRoute}.backend.balance`}
                                        />
                                    </RhfFormField>
                                    <RhfFormField
                                        label="Server Request Timeout [Seconds]"
                                        name={`${baseRoute}.backend.timeouts.server_secs`}
                                    >
                                        <TextInput
                                            className="w-full border-gray-200"
                                            type="number"
                                            placeholder="No Timeout"
                                            {...register(
                                                `${baseRoute}.backend.timeouts.server_secs`,
                                                {
                                                    setValueAs: (v) =>
                                                        !v ? null : parseInt(v),
                                                }
                                            )}
                                        />
                                    </RhfFormField>
                                    <RhfFormField
                                        label="Server Finish Timeout [ms]"
                                        name={`${baseRoute}.backend.timeouts.server_fin_ms`}
                                    >
                                        <TextInput
                                            className="w-full border-gray-200"
                                            type="number"
                                            placeholder="No Timeout"
                                            {...register(
                                                `${baseRoute}.backend.timeouts.server_fin_ms`,
                                                {
                                                    setValueAs: (v) =>
                                                        !v ? null : parseInt(v),
                                                }
                                            )}
                                        />
                                    </RhfFormField>
                                    <RhfFormField
                                        label="Connection Timeout [ms]"
                                        name={`${baseRoute}.backend.timeouts.connect_ms`}
                                    >
                                        <TextInput
                                            className="w-full border-gray-200"
                                            type="number"
                                            placeholder="No Timeout"
                                            {...register(
                                                `${baseRoute}.backend.timeouts.connect_ms`,
                                                {
                                                    setValueAs: (v) =>
                                                        !v ? null : parseInt(v),
                                                }
                                            )}
                                        />
                                    </RhfFormField>
                                    <RhfFormField
                                        label="Queue Timeout [ms]"
                                        name={`${baseRoute}.backend.timeouts.queue_ms`}
                                    >
                                        <TextInput
                                            className="w-full border-gray-200"
                                            type="number"
                                            placeholder="No Timeout"
                                            {...register(
                                                `${baseRoute}.backend.timeouts.queue_ms`,
                                                {
                                                    setValueAs: (v) =>
                                                        !v ? null : parseInt(v),
                                                }
                                            )}
                                        />
                                    </RhfFormField>
                                    <RhfFormField
                                        label="Tunnel Timeout [seconds]"
                                        name={`${baseRoute}.backend.timeouts.tunnel_secs`}
                                    >
                                        <TextInput
                                            className="w-full border-gray-200"
                                            type="number"
                                            placeholder="No Timeout"
                                            {...register(
                                                `${baseRoute}.backend.timeouts.tunnel_secs`,
                                                {
                                                    setValueAs: (v) =>
                                                        !v ? null : parseInt(v),
                                                }
                                            )}
                                        />
                                    </RhfFormField>
                                </div>
                            </PanelContent>
                        </Panel>
                    </div>
                </div>
            </SectionDisabledControl>
        </div>
    );
}
