import { LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    RhfFormField,
    RhfFormProvider,
    TextInput,
    RhfGlobalFormError,
    required,
    FormSectionHeader,
    FormSection,
    RHF_GLOBAL_ERROR,
    SectionDisabledControl,
} from "@cycleplatform/ui/components/forms";
import { faArrowRight, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { Controller, useForm, useWatch } from "react-hook-form";
import { rhfConfig } from "~/components/forms/util";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { addStepData, getCurrentForm } from "~/modules/forms/slice";
import { useContext, useEffect, useMemo } from "react";
import { MultiStepDialogContext } from "~/components/dialogs/MultiStepFormDialog/context";
import {
    DialogColumn,
    DialogFooter,
} from "@cycleplatform/ui/components/dialog/components";
import { VmNameStepValues } from "./types";
import { Link } from "react-router-dom";
import { EnvironmentSelect } from "~/components/environments/EnvironmentSelect";
import { LearnMore } from "./LearnMore";
import { VmSshKeySelect } from "~/components/vms/forms";
import {
    generateDialogLink,
    getAllDialogSearchParams,
} from "~/components/dialogs/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SshKeyAddPopupForm } from "~/components/vms/forms/SshKeyAddPopupForm";
import { InfoPanel } from "@cycleplatform/ui/components/panels";
import { useGetServersQuery } from "~/services/cycle";

export function VmBasicsStep({ stepIdx = 0 }: { stepIdx?: number }) {
    const params = getAllDialogSearchParams<"virtual-machine-create">();

    const envIdParam = params["dialog-environment-id"] || "";
    const clusterIdParam = params["dialog-cluster-id"];

    const { data: servers } = useGetServersQuery(
        {
            filter: {
                cluster: clusterIdParam || "",
            },
            include: ["models"],
        },
        { skip: !clusterIdParam }
    );

    // Check to ensure that at least one hypervisor server is deployed
    // Default to "true" until servers load because it prevents flashing while servers load in
    const isClusterVmEligible = useMemo(
        () =>
            servers?.data
                ? servers?.data?.some(
                      (s) =>
                          !!servers?.includes?.models?.[s.model_id]?.specs
                              ?.features?.hypervisor
                  )
                : true,
        [servers?.data]
    );

    const { handleNext, formKey } = useContext(MultiStepDialogContext);
    const currentForm = useAppSelector((state) =>
        getCurrentForm(state, formKey)
    );

    const form = useForm<VmNameStepValues>({
        defaultValues: currentForm?.steps?.[stepIdx] || {
            name: "",
            environment_id: envIdParam,
            identifier: "",
        },
        ...rhfConfig,
    });

    const {
        handleSubmit,
        register,
        setError,
        formState: { isSubmitting, isDirty },
        control,
    } = form;

    const dispatch = useAppDispatch();

    const onSubmit = async (data: VmNameStepValues) => {
        if (!isDirty && !currentForm?.steps?.[stepIdx]) {
            return;
        }
        dispatch(addStepData({ data, idx: stepIdx, key: formKey }));
        handleNext?.();
    };

    const error = currentForm?.error;

    useEffect(() => {
        if (error && error.step === stepIdx) {
            setError(RHF_GLOBAL_ERROR as never, { message: error.message });
        }
    }, [error]);

    const envId = useWatch({ control, name: "environment_id" });

    return (
        <SectionDisabledControl disabled={!isClusterVmEligible}>
            <RhfFormProvider
                {...form}
                className="h-full"
                onSubmit={form.handleSubmit(onSubmit)}
            >
                <div className="flex">
                    <DialogColumn className="w-2/3">
                        {!isClusterVmEligible ? (
                            <InfoPanel className="mb-8" type="warning">
                                There are no servers available on this cluster
                                that support hypervisors.{" "}
                                <Link
                                    to={generateDialogLink("server-create", {})}
                                >
                                    Deploy a server{" "}
                                </Link>
                                to this cluster that supports hypervisors to
                                deploy a virtual machine.
                            </InfoPanel>
                        ) : null}
                        <RhfFormField label="Name" name="name" required>
                            <TextInput
                                {...register("name", {
                                    ...required(),
                                })}
                            />
                        </RhfFormField>
                        {!envIdParam && (
                            <RhfFormField
                                label="environment"
                                name="environment_id"
                            >
                                <Controller
                                    name={`environment_id`}
                                    control={control}
                                    rules={{ ...required() }}
                                    render={({
                                        field: { ref: _ref, ...field },
                                    }) => (
                                        <EnvironmentSelect
                                            {...field}
                                            onChange={(id) => {
                                                if (!id) {
                                                    return;
                                                }
                                                field.onChange(id);
                                            }}
                                        />
                                    )}
                                />
                            </RhfFormField>
                        )}
                        <FormSectionHeader header="Authorization"></FormSectionHeader>
                        <FormSection>
                            <RhfFormField
                                label="SSH Keys"
                                name="sshKeys"
                                tooltip={
                                    <div className="text-base font-normal">
                                        <SshKeyAddPopupForm
                                            environmentId={envId}
                                        >
                                            <span className="text-cycle-blue text-sm font-semibold">
                                                <FontAwesomeIcon
                                                    icon={faPlus}
                                                />{" "}
                                                Add
                                            </span>
                                        </SshKeyAddPopupForm>
                                    </div>
                                }
                                labelClassName="flex w-full justify-between"
                            >
                                <Controller
                                    name={`sshKeys`}
                                    control={control}
                                    render={({
                                        field: { ref: _ref, ...field },
                                    }) => (
                                        <VmSshKeySelect
                                            {...field}
                                            value={field.value?.map(
                                                (v) => v.id
                                            )}
                                            multiple
                                            filter={{
                                                environment: envId,
                                            }}
                                            onChange={(id, keys) => {
                                                if (!id) {
                                                    return;
                                                }
                                                field.onChange(
                                                    keys.map((k) => ({
                                                        id: k.id,
                                                        name: k.name,
                                                    }))
                                                );
                                            }}
                                        />
                                    )}
                                />
                            </RhfFormField>
                        </FormSection>
                    </DialogColumn>

                    <LearnMore title="Virtual Machines">
                        Virtual machines can be deployed and run alongside
                        containers in the same environment. Virtual machines
                        provide more security and the ability to use custom
                        kernels, at the cost of higher resource usage.
                    </LearnMore>
                </div>

                <DialogFooter>
                    <div>
                        <RhfGlobalFormError />
                    </div>
                    <LoaderButton
                        isLoading={isSubmitting}
                        onClick={handleSubmit(onSubmit)}
                        type="button"
                        flavor="primary"
                        icon={faArrowRight}
                        // Disable until we have servers?.data so that we can prove they have a hypervisor container
                        // This allows us to fetch the servers "silently" and only interrupt if there is an error
                        // While not allowing user to continue until we can confirm the Cluster is eligible
                        disabled={
                            !servers?.data ||
                            (!isDirty && !currentForm?.steps?.[stepIdx])
                        }
                    >
                        Next
                    </LoaderButton>
                </DialogFooter>
            </RhfFormProvider>
        </SectionDisabledControl>
    );
}
