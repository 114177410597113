import classNames from "classnames";
import { ButtonLink } from "@cycleplatform/ui/components/buttons";
import { useEffect, useMemo, useState } from "react";
import { To, useNavigate } from "react-router-dom";
import { getAllDialogSearchParams } from "~/components/dialogs/helpers";
import { TierPlan } from "~/services/cycle";
import {
    getTiersByCategory,
    nonCommercialTiers,
    TierCategory,
    tierCategories,
} from "@cycleplatform/core/modules/tiers/util";

export function TierCategoryNavigation({
    currentTierId,
    hasCustomTiers,
    tiers,
}: {
    currentTierId?: string | null;
    hasCustomTiers: boolean;
    tiers: TierPlan[] | undefined;
}) {
    const nav = useNavigate();

    const tiersByCategory = useMemo(() => {
        if (!currentTierId) {
            return;
        }
        return getTiersByCategory(tiers, currentTierId);
    }, [currentTierId, tiers]);

    const currentTier = useMemo(
        () => tiers?.find((t) => t.id === currentTierId),
        [tiers, currentTierId]
    );

    // On load, ensure the navigation shows their current tier
    useEffect(() => {
        if (!currentTierId) {
            nav(getTabLink(tierCategories.TEAMS));
            return;
        }
        if (
            tiersByCategory?.nonCommercial.some((t) => t.id === currentTierId)
        ) {
            nav(getTabLink(tierCategories.NON_COMMERCIAL));
        } else if (tiersByCategory?.teams.some((t) => t.id === currentTierId)) {
            nav(getTabLink(tierCategories.TEAMS));
        } else {
            nav(getTabLink(tierCategories.CUSTOM));
        }
    }, [tiersByCategory, currentTierId]);

    // If user is on a commercial plan, we will NOT show non-commercial tiers
    const isCommercialClient =
        currentTier?.code && !nonCommercialTiers.includes(currentTier?.code);

    return (
        <div
            className={classNames(
                "flex justify-center p-1",
                "[&>:not(:first-child):not(:last-child)_a]:rounded-none [&>:not(:first-child):not(:last-child)_a]:border-r-0",
                "[&>:last-child_a]:rounded-l-none  [&>:first-child_a]:rounded-r-none [&>:first-child_a]:border-r-0"
            )}
        >
            {!isCommercialClient ? (
                <NavTab
                    label="For Non-Commercial"
                    tab={tierCategories.NON_COMMERCIAL}
                />
            ) : null}

            <NavTab label="For Teams" tab={tierCategories.TEAMS} isDefault />
            <NavTab label="Enterprise" tab={tierCategories.ENTERPRISE} />

            {hasCustomTiers ? (
                <NavTab label="Custom" tab={tierCategories.CUSTOM} />
            ) : null}
        </div>
    );
}

function NavTab({
    label,
    tab,
    isDefault,
}: {
    label: string;
    tab: TierCategory;
    isDefault?: boolean;
}) {
    // Only show focus ring if user presses tab
    const [tabIndex, setTabIndex] = useState(-1);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Tab") {
                setTabIndex(0);
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const params = getAllDialogSearchParams<"change-hub-plan">();

    const tabParam = params["dialog-tier-tab"];
    const isRendered = tabParam === tab || (isDefault && !tabParam);

    return (
        <ButtonLink
            to={getTabLink(tab)}
            tabIndex={tabIndex || undefined}
            className={classNames(isRendered && "!bg-cycle-blue text-white")}
        >
            {label}
        </ButtonLink>
    );
}

function getTabLink(tab: TierCategory): To {
    const sp = new URLSearchParams(
        new URL(window.location.toString()).searchParams
    );
    sp.append("dialog-tier-tab", tab);
    return {
        search: sp.toString(),
    };
}
