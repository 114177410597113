import { Image, ImageSource } from "~/services/cycle";
import {
    Panel,
    PanelContent,
    PanelDetail,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { formatBytesString } from "@cycleplatform/core/util/bytes";
import { Link } from "react-router-dom";
import { getSourceDetails } from "@cycleplatform/core/modules/images";
import { components } from "@cycleplatform/core/modules/api/__generated";
import { getHumanizedTime } from "@cycleplatform/core/util";
import { useContext } from "react";
import { ImageDialogContext } from "../context";

export function ImageSourceSummary() {
    const { image, source } = useContext(ImageDialogContext);

    return (
        <Panel>
            <PanelTitle title="Image Source" />
            <PanelContent>
                <PanelDetail label="Source Name">
                    <Link to={`/images/sources/${source?.id}`}>
                        {source?.name}
                    </Link>
                </PanelDetail>

                <PanelDetail label="Source">
                    {source?.origin &&
                        getSourceDetails(
                            source.origin as components["schemas"]["ImageOrigin"],
                            image?.source?.override
                        )}
                </PanelDetail>

                <PanelDetail label="target">{source?.origin?.type}</PanelDetail>

                <PanelDetail label="Created">
                    {source?.events.created
                        ? getHumanizedTime(source?.events.created)
                        : null}
                </PanelDetail>

                <PanelDetail label="Storage">
                    <>{formatBytesString(image?.size || 0)}</>
                </PanelDetail>
            </PanelContent>
        </Panel>
    );
}
