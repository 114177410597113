import classNames from "classnames";
import { HelpInfoCircle } from "../help";

type DataLabelProps = {
    label: string;
    className?: string;
    help?: string;
};

export function DataLabel({ label, className, help }: DataLabelProps) {
    return (
        <span
            className={classNames(
                className,
                "flex items-center gap-2 font-bold uppercase"
            )}
        >
            {label} {help ? <HelpInfoCircle message={help} /> : null}
        </span>
    );
}
