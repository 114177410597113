import { useContext } from "react";
import { VirtualMachineDialogContex } from "../../context";
import { SectionDisabledControl } from "@cycleplatform/ui/components/forms";
import { PageAside, PageContent } from "@cycleplatform/ui/components/page";
import { Panel, PanelTitle } from "@cycleplatform/ui/components/panels";
import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import { PanelContentBoundary } from "~/components/layout/panels/PanelContentBoundary";
import { RecentActivityLog } from "~/components/logs/activity";
import { VmImagePanel } from "./panels/VmImagePanel";
import { usePagination } from "@cycleplatform/ui/hooks";
import { PageControl } from "@cycleplatform/ui/components/pagination";
import { VmVolumesPanel } from "./panels/VmVolumesPanel";
import { VmHostServerPanel } from "./panels/VmHostServerPanel";
import { VmPrivateNetworkPanel } from "./panels/VmPrivateNetworkPanel";
import { VmDomainsPanel } from "./panels/VmDomainsPanel";
import { VmStaticPublicIpsPanel } from "./panels/VmStaticPublicIpsPanel";
import { VmEventsPanel } from "./panels/VmEventsPanel";
import { VmRootPasswordPanel } from "./panels/VmRootPasswordPanel";

import { VmPublicNetworkChart } from "./panels/VmPublicNetworkChart";

type VmDashboardTabProps = {};

export function VmDashboardTab({}: VmDashboardTabProps) {
    const pagination = usePagination(10, "dialog-page");
    const { vm, environment, container } = useContext(
        VirtualMachineDialogContex
    );

    return (
        <SectionDisabledControl
            className="flex"
            disabled={vm?.state.current === "deleted"}
            heightFull
        >
            <DialogPageBody>
                <div className="w-2/3 overflow-y-auto">
                    <PageContent className="flex grow">
                        <VmPrivateNetworkPanel />
                        <VmPublicNetworkChart
                            vm={vm}
                            environment={environment}
                            container={container}
                        />
                        <VmDomainsPanel />
                        <VmStaticPublicIpsPanel />
                        <VmHostServerPanel />
                        <VmVolumesPanel />
                        <VmImagePanel />
                    </PageContent>
                </div>

                <PageAside className="w-1/2">
                    <VmRootPasswordPanel />
                    <VmEventsPanel />

                    <Panel>
                        <PanelTitle
                            className="bg-cycle-white-accent dark:bg-cycle-black-accent sticky -top-5 z-10 flex justify-between"
                            title="Recent Activity"
                        >
                            <PageControl page={pagination} />
                        </PanelTitle>

                        <PanelContentBoundary className="flex max-h-full px-0 pt-0">
                            <RecentActivityLog
                                pagination={pagination}
                                vmId={vm?.id}
                            />
                        </PanelContentBoundary>
                    </Panel>
                </PageAside>
            </DialogPageBody>
        </SectionDisabledControl>
    );
}
