import { formatLoadBalancerInstanceName } from "@cycleplatform/core/modules/environments/loadbalancer/format";
import { formatBytesString } from "@cycleplatform/core/util";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { Meter } from "@cycleplatform/ui/components/progress";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { NavIcons } from "~/components/layout/NavIcons";
import { useAppSelector } from "~/hooks";
import { selectAppliedTheme } from "~/modules/settings";
import {
    Instance,
    useGenerateAggregatedMetricsQuery,
    useGetInstanceQuery,
    useGetInstancesQuery,
} from "~/services/cycle";
import { LatencyChart } from "./latency/LatencyChart";
import { LoadBalancerDialogContext } from "../../../../context";
import {
    LoadBalancerDestinationMetric,
    buildRouterMetricsQuery,
} from "./query";
import { CycleErrorBoundary } from "~/components/common/errors";
import { isFunctionContainer } from "@cycleplatform/core/modules/containers/config";
import { LatencyChartMetric, buildLatencyQuery } from "./latency/query";
import { SkeletonTable } from "@cycleplatform/ui/components/loaders/skeleton";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faInfoCircle,
    faTimesCircle,
} from "@fortawesome/pro-duotone-svg-icons";
import { AGGREGATE_POLLING_MS } from "~/util/charts/util";
import { formatNumber, round } from "@cycleplatform/core/util/number";

export function RouterRow({
    metric,
    environmentId,
    latency,
}: {
    environmentId: string;
    metric: LoadBalancerDestinationMetric;
    latency: LatencyChartMetric | undefined;
}) {
    const {
        data: instance,
        isLoading,
        error,
    } = useGetInstanceQuery({
        containerId: metric.destination_container_id,
        instanceId: metric.destination_instance_id,
        include: ["integrations", "locations", "containers"],
    });

    if (error) {
        throw error;
    }

    const location =
        instance?.includes?.locations?.[instance?.data?.location_id || ""];

    const container =
        instance?.includes?.containers?.[metric.destination_container_id];

    return (
        <StyledTableRow>
            <StyledCell className="pr-2">
                <Link
                    to={generateDialogLink("container", {
                        "dialog-container-id": metric.destination_container_id,
                        "dialog-instance-id": metric.destination_instance_id,
                        "dialog-tab": "instances",
                    })}
                >
                    {instance?.data?.hostname}
                </Link>
                <div className="text-sm">
                    {formatLoadBalancerInstanceName(
                        metric.destination_instance_id,
                        instance?.data?.provider?.vendor || "",
                        location?.name || ""
                    )}
                </div>
            </StyledCell>
            <StyledCell>
                <div className="h-[4rem] w-full">
                    {container && !isFunctionContainer(container) ? (
                        <>
                            {latency ? (
                                <LatencyChart metric={latency} />
                            ) : (
                                <div className="flex h-full items-center justify-center">
                                    <Tooltip message="no requests in the last hour">
                                        <FontAwesomeIcon
                                            className="text-cycle-blue"
                                            icon={faInfoCircle}
                                        />
                                    </Tooltip>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="flex h-full items-center justify-center">
                            <Tooltip message="latency tracking unsupported on function containers">
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </Tooltip>
                        </div>
                    )}
                </div>
            </StyledCell>
            <StyledCell className="text-center">{metric.requests}</StyledCell>

            <StyledCell>
                <div className="text-sm">
                    {`${formatBytesString(
                        metric.transmitted_kb * 1024
                    )} Transmitted`}
                </div>
                <div className="text-sm">
                    {`${formatBytesString(
                        metric.received_kb * 1024
                    )}  Received`}{" "}
                </div>
            </StyledCell>

            <StyledCell>
                <Meter
                    text={`${formatNumber(metric.connections_success_percent, {
                        decimals: 1,
                    })}%`}
                    percent={metric.connections_success_percent}
                />
            </StyledCell>
        </StyledTableRow>
    );
}
