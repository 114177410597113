import { useNavigate } from "react-router-dom";
import {
    DialogPageBody,
    ResourceDialogTab,
    StyledResourceDialog,
} from "@cycleplatform/ui/components/dialog";
import {
    clearDialogParams,
    getAllDialogSearchParams,
    isDialogOpen,
} from "~/components/dialogs/helpers";
import {
    useDeleteVirtualProviderIsoMutation,
    useGetVirtualProviderIsoQuery,
} from "~/services/cycle";
import { faDashboard } from "@fortawesome/pro-duotone-svg-icons";
import { UpdateIsoPanel } from "./UpdateIsoPanel";
import { IsoDetailsPanel } from "./IsoDetailsPanel";
import { DeleteResource } from "~/components/common/resources/DeleteResource";
import { formatDateString } from "@cycleplatform/core/util";
import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import { IsoDownloadButton } from "~/routes/hub/integrations/$integrationVendor/virtualProvider/IsoDownloadButton";
import { IsoGenerateButton } from "~/routes/hub/integrations/$integrationVendor/virtualProvider/IsoGenerateButton";
import { PageContent } from "@cycleplatform/ui/components/page";

export function VirtualProviderViewIsoDialog() {
    const navigate = useNavigate();
    const params = getAllDialogSearchParams<"virtual-provider-iso">();

    const { data: iso } = useGetVirtualProviderIsoQuery({
        integrationId: params["dialog-integration-id"],
        isoId: params["dialog-iso-id"],
        include: ["integrations"],
    });

    const integration =
        iso?.includes?.integrations?.[iso?.data?.integration_id];

    const [deleteIso] = useDeleteVirtualProviderIsoMutation();
    const nav = useNavigate();

    return (
        <StyledResourceDialog
            header={
                <div className="flex w-full items-center justify-between gap-4">
                    <div className="flex items-center gap-4">
                        <h1>{iso?.data?.name}</h1>
                        {iso?.data && (
                            <>
                                <ResourceStateBadge
                                    className="!bg-cycle-gray !h-8"
                                    state={iso.data.state}
                                />
                            </>
                        )}
                    </div>
                    <div className="flex !h-[32px] items-center text-base ">
                        {iso?.data ? (
                            iso?.data.state.current === "live" ? (
                                <IsoDownloadButton
                                    integrationId={iso?.data?.integration_id}
                                    isoId={iso.data.id}
                                    flavor="primary"
                                />
                            ) : (
                                <IsoGenerateButton
                                    integrationId={iso?.data?.integration_id}
                                    isoId={iso.data.id}
                                    flavor="primary"
                                />
                            )
                        ) : null}
                    </div>
                </div>
            }
            breadcrumb={[
                {
                    label: "Virtual Providers",
                    link: "/hub/integrations/virtual-provider",
                },
                {
                    label: integration ? integration.name : "",
                    link: `/hub/integrations/virtual-provider?integration-expanded=${iso?.data?.integration_id}`,
                },
                {
                    label: "ISO",
                },
                {
                    label: iso?.data?.name || "",
                },
            ]}
            open={isDialogOpen("virtual-provider-iso")}
            onClose={() => navigate(clearDialogParams())}
        >
            <ResourceDialogTab to={null} name="Dashboard" icon={faDashboard}>
                <DialogPageBody className="gap-4">
                    <div className="w-2/3 overflow-y-auto">
                        <PageContent className="flex grow">
                            <UpdateIsoPanel iso={iso?.data} />
                        </PageContent>
                    </div>

                    <div className="w-1/3 overflow-y-auto">
                        <PageContent className="flex grow">
                            <IsoDetailsPanel iso={iso?.data} />

                            <DeleteResource
                                resourceType="iso"
                                resourceName={iso?.data?.id}
                                accessControl={{ capability: "servers-manage" }}
                                onSubmit={() => {
                                    return deleteIso({
                                        integrationId:
                                            iso?.data?.integration_id || "",
                                        isoId: iso?.data?.id || "",
                                    })
                                        .unwrap()
                                        .then(() => nav(clearDialogParams()));
                                }}
                            />
                        </PageContent>
                    </div>
                </DialogPageBody>
            </ResourceDialogTab>
        </StyledResourceDialog>
    );
}
