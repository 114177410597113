import { Button } from "@cycleplatform/ui/components/buttons";
import { faDownload } from "@fortawesome/pro-solid-svg-icons";
import { cycleApi } from "~/services/cycle";
import { useAppDispatch } from "~/hooks";
import { store } from "~/store";
import { pushNotification } from "~/modules/notifications/slice";
import classNames from "classnames";

export function IsoDownloadButton({
    integrationId,
    isoId,
    className,
    flavor,
}: {
    integrationId: string;
    isoId: string;
    className?: string;
    flavor?: "default" | "primary" | "discard";
}) {
    const dispatch = useAppDispatch();

    const onSubmit = async () => {
        return fetchDownloadUrl(integrationId, isoId)
            .then((url) => {
                if (url && typeof url === "string") {
                    window.open(url, "_blank", "noopener,noreferrer");
                }
            })
            .catch((error) => {
                dispatch(
                    pushNotification({
                        title: error,
                        type: "error",
                    })
                );
            });
    };
    return (
        <Button
            icon={faDownload}
            className={classNames(className, "text-sm")}
            onClick={onSubmit}
            flavor={flavor}
        >
            Download
        </Button>
    );
}

function fetchDownloadUrl(integrationId: string, isoId: string) {
    const promise = store.dispatch(
        cycleApi.endpoints.getVirtualProviderIsoDownloadUrl.initiate({
            integrationId,
            isoId,
        })
    );

    return promise
        .then(({ data: download, error }) => {
            if (error) {
                return new Error(`Error fetching ISO download URL: ${error}`);
            }
            return download?.data?.url;
        })
        .finally(() => {
            promise.unsubscribe();
        });
}
