import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import { faSliders } from "@fortawesome/pro-solid-svg-icons";
import { useFormContext } from "react-hook-form";
import { LoadBalancerFormValues } from "../../../../../form";
import { useCreateLoadBalancerServiceJobMutation } from "~/services/cycle";
import { useContext, useState } from "react";
import { LoadBalancerDialogContext } from "~/components/dialogs/environments/services/loadbalancer/context";
import { useJobTracker } from "~/modules/jobs/hooks";
import { handleSubmitError } from "~/components/forms/util";

export function V1UsePlatformDefaultsButton({
    activePortIdx,
}: {
    activePortIdx: number;
}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { handleSubmit, reset, setError } =
        useFormContext<LoadBalancerFormValues>();

    const { environment } = useContext(LoadBalancerDialogContext);
    const [updateConfig] = useCreateLoadBalancerServiceJobMutation();
    const [trackJob] = useJobTracker();

    const onSubmit = (data: LoadBalancerFormValues) => {
        setIsSubmitting(true);
        let config = { ...data.config } as LoadBalancerFormValues["config"];

        if (activePortIdx < 0 || config?.type !== "v1") {
            return;
        }

        const controllers = config.details.controllers;

        if (!controllers[activePortIdx]) {
            return;
        }

        const isWafDefined = !!controllers[activePortIdx].waf;

        if (isWafDefined) {
            // If WAF exists, null the transport but retail the controller entry
            controllers[activePortIdx].transport = null;
        } else {
            // Else, remove the controller entry entirely
            config.details.controllers = [
                ...controllers.slice(0, activePortIdx),
                ...controllers.slice(activePortIdx + 1),
            ];
        }

        return trackJob(
            updateConfig({
                environmentId: environment?.id || "",
                body: {
                    action: "reconfigure",
                    contents: { config },
                },
            }).unwrap()
        ).then(
            () => {
                reset((formValues) => ({ ...formValues }));
                setIsSubmitting(false);
            },
            (e) => {
                handleSubmitError(setError)(e);
                setIsSubmitting(false);
            }
        );
    };
    return (
        <PushAndHoldButton
            flavor="primary"
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
            icon={faSliders}
        >
            Use Platform Defaults
        </PushAndHoldButton>
    );
}
