import { ComponentType, ElementType, useEffect, useRef, useState } from "react";
import {
    useFloating,
    autoUpdate,
    offset,
    flip,
    shift,
    useHover,
    Placement,
    useFocus,
    useDismiss,
    useRole,
    useInteractions,
    arrow,
    FloatingArrow,
} from "@floating-ui/react";
import classNames from "classnames";

export type DetailsPopoutProps = {
    className?: string;
    popupClassName?: string;
    children: React.ReactNode;
    message: React.ReactNode;
    disabled?: boolean;
    placement?: Placement;
    as?: ComponentType | ElementType;
};

export function DetailsPopout({
    children,
    message,
    disabled,
    className,
    popupClassName,
    placement = "top",
    as: Component = "div",
}: DetailsPopoutProps) {
    const [isOpen, setIsOpen] = useDebounceIsOpen(500);

    const arrowRef = useRef(null);

    const { x, y, strategy, refs, context, update } = useFloating({
        open: isOpen,
        strategy: "absolute",
        placement: placement,
        onOpenChange: setIsOpen,
        middleware: [
            flip(),
            shift(),
            arrow({
                element: arrowRef,
            }),
            offset(25),
        ],
        whileElementsMounted: autoUpdate,
    });

    const handleMouseMove: React.MouseEventHandler = (event) => {
        const nativeEvent = event.nativeEvent;

        refs.setReference({
            getBoundingClientRect: () => ({
                width: 0,
                height: 0,
                x: nativeEvent.clientX,
                y: nativeEvent.clientY,
                top: nativeEvent.clientY,
                left: nativeEvent.clientX,
                right: nativeEvent.clientX,
                bottom: nativeEvent.clientY,
            }),
        });
        update();
    };

    const handleFocus = () => {
        setIsOpen(true);
    };

    const handleBlur = () => {
        setIsOpen(false);
    };

    const hover = useHover(context, { move: false });
    const focus = useFocus(context);
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: "tooltip" });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        hover,
        focus,
        dismiss,
        role,
    ]);

    return (
        <>
            <Component
                as={"div"}
                ref={refs.setReference}
                {...getReferenceProps({
                    onMouseMove: handleMouseMove,
                    onFocus: handleFocus,
                    onBlur: handleBlur,
                })}
                className={classNames(
                    className,
                    "w-full",
                    disabled ? "cursor-auto " : ""
                )}
                tabIndex={0}
            >
                {children}
            </Component>
            {isOpen && !disabled && (
                <div
                    ref={refs.setFloating}
                    style={{
                        position: strategy,
                        top: y ?? 0,
                        left: x ?? 0,
                    }}
                    className={classNames(
                        popupClassName,
                        "animate-fade-in animate-fade-out z-10 whitespace-nowrap rounded-lg bg-white stroke-1 shadow-lg "
                    )}
                    {...getFloatingProps()}
                >
                    <div className="m-4">{message}</div>
                    <FloatingArrow
                        className="fill-white"
                        ref={arrowRef}
                        context={context}
                    />
                </div>
            )}
        </>
    );
}
/**
 *
 * @param delay the ms of delay before the "open" state propagates
 * @returns [isOpen, setIsOpen] similar to a usestate, but the "true" state waits for the delay time before
 * actually returning true.  Updates to "false" occur immediately.
 */
function useDebounceIsOpen(delay: number) {
    const [isOpen, setIsOpen] = useState(false);
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const setDebouncedIsOpen = (newState: boolean) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        if (newState) {
            timeoutRef.current = setTimeout(() => {
                setIsOpen(newState);
            }, delay);
        } else {
            setIsOpen(newState);
        }
    };

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return [isOpen, setDebouncedIsOpen] as const;
}
