import { Container, InstanceTelemetryResourceSnapshot } from "~/services/cycle";
import { snapshotToCpuData } from "./telemetry";
import { useMemo } from "react";
import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { CycleLineProps } from "@cycleplatform/ui/components/recharts/line/props";
import {
    CycleAxisProps,
    CycleChartProps,
} from "@cycleplatform/ui/components/recharts/props";
import { FormattedTimeTick } from "@cycleplatform/ui/components/recharts/FormattedTimeTick";
import { FormattedYTick } from "@cycleplatform/ui/components/recharts/FormattedYTick";
import { CycleLineTooltip } from "@cycleplatform/ui/components/recharts/line/CycleLineTooltip";
import classNames from "classnames";
import { useGetThemedChartColors } from "~/util/charts/hooks";
import { CycleGridProps } from "@cycleplatform/ui/components/recharts/grid/props";

type InstanceCpuChartProps = {
    telemetry: InstanceTelemetryResourceSnapshot[];
    container: Container;
};
export function InstanceCpuChart({
    telemetry,
    container,
}: InstanceCpuChartProps) {
    const { colors } = useGetThemedChartColors();

    const cpuUsageData = useMemo(
        () =>
            telemetry
                .map((t, idx) =>
                    snapshotToCpuData(
                        t,
                        idx > 0 ? telemetry[idx - 1] : null,
                        container.config.resources?.cpu,
                        container.config.deploy.strategy
                    )
                )
                .slice(1),
        [telemetry]
    );
    return (
        <div className={classNames("h-full w-full")}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart {...CycleChartProps} data={cpuUsageData}>
                    <CartesianGrid {...CycleGridProps} />
                    <XAxis
                        {...CycleAxisProps}
                        dataKey="time"
                        tick={(props) => <FormattedTimeTick {...props} />}
                    />
                    <YAxis
                        {...CycleAxisProps}
                        domain={([_, dataMax]) =>
                            dataMax < 100 ? [0, 100] : [0, Math.ceil(dataMax)]
                        }
                        tick={(props) => (
                            <FormattedYTick
                                {...props}
                                formatValue={(v: number) => `${v}%`}
                            />
                        )}
                    />
                    <Tooltip
                        content={(props) => (
                            <CycleLineTooltip
                                {...props}
                                formatValue={(v: number) => `${Math.round(v)}%`}
                            />
                        )}
                    />
                    <Line
                        {...CycleLineProps}
                        dataKey="usage"
                        stroke={colors["blue"].DEFAULT}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}
