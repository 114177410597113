import {
    LoaderButton,
    PopoverButtonContext,
} from "@cycleplatform/ui/components/buttons";
import {
    required,
    RhfFormField,
    RhfFormProvider,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { Popover } from "@headlessui/react";
import classNames from "classnames";
import { PublicKeyInput } from "./PublicKeyInput";
import {
    CreateVirtualMachineSshKeyApiArg,
    useCreateVirtualMachineSshKeyMutation,
} from "~/services/cycle";
import { useForm } from "react-hook-form";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { pushNotification } from "~/modules/notifications/slice";
import { useAppDispatch } from "~/hooks";
import {
    autoUpdate,
    flip,
    offset,
    shift,
    useFloating,
} from "@floating-ui/react";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { createPortal } from "react-dom";

type SshKeyAddPopupFormProps = {
    environmentId: string;
    children: React.ReactNode;
};

/** Small form for inline adding VM SSH keys */
export function SshKeyAddPopupForm({
    children,
    environmentId,
}: SshKeyAddPopupFormProps) {
    const form = useForm<CreateVirtualMachineSshKeyApiArg["body"]>({
        defaultValues: {
            environment_id: environmentId,
        },
        ...rhfConfig,
    });

    const {
        handleSubmit,
        register,
        reset,
        setError,
        formState: { isSubmitting, isDirty },
    } = form;

    const dispatch = useAppDispatch();

    const [createSshKey] = useCreateVirtualMachineSshKeyMutation();
    const onSubmit = async (data: CreateVirtualMachineSshKeyApiArg["body"]) => {
        return createSshKey({
            body: data,
        })
            .unwrap()
            .then(() => {
                reset();
                dispatch(
                    pushNotification({
                        title: `Created SSH Key '${data.name}'`,
                        type: "success",
                        icon: "sshKey",
                    })
                );
            }, handleSubmitError(setError));
    };

    const { refs, floatingStyles } = useFloating({
        placement: "bottom-end",
        middleware: [offset(10), flip(), shift()],
        whileElementsMounted: autoUpdate,
    });

    return (
        <Popover className="relative">
            {({ open, close }) => (
                <>
                    <Popover.Button ref={refs.setReference} className="h-full">
                        {children}
                    </Popover.Button>
                    <PopoverButtonContext.Provider
                        value={{
                            isOpen: open,
                            close,
                        }}
                    >
                        {createPortal(
                            <Popover.Panel
                                ref={refs.setFloating}
                                style={floatingStyles}
                                className={classNames(
                                    "dark:bg-cycle-gray dark:text-cycle-white  animate-fade-in-fast a bg-cycle-white z-50 min-w-[25rem] rounded-lg stroke-1 p-4 shadow-lg"
                                )}
                            >
                                <RhfFormProvider
                                    {...form}
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <RhfFormField
                                        label="Name"
                                        name="name"
                                        required
                                    >
                                        <TextInput
                                            {...register("name", {
                                                ...required(),
                                                setValueAs(v) {
                                                    return v.trim();
                                                },
                                            })}
                                        />
                                    </RhfFormField>
                                    <RhfFormField
                                        label="Public Key"
                                        name="public_key"
                                        required
                                    >
                                        <PublicKeyInput
                                            {...register("public_key", {
                                                ...required(),
                                                setValueAs(v) {
                                                    return v.trim();
                                                },
                                            })}
                                        />
                                    </RhfFormField>
                                    <PanelFooter>
                                        <LoaderButton
                                            isLoading={isSubmitting}
                                            onClick={handleSubmit((data) =>
                                                onSubmit(data).then(() =>
                                                    close()
                                                )
                                            )}
                                            icon={faPlus}
                                            flavor="primary"
                                            type="submit"
                                        >
                                            Add
                                        </LoaderButton>
                                    </PanelFooter>
                                </RhfFormProvider>
                            </Popover.Panel>,
                            document.body
                        )}
                    </PopoverButtonContext.Provider>
                </>
            )}
        </Popover>
    );
}
