import {
    faPlay,
    faTrash,
    faSync,
    faArrowCircleUp,
    faStop,
    faQuestionCircle,
    faCheckCircle,
    faPlus,
    faRocketLaunch,
    faClock,
} from "@fortawesome/pro-solid-svg-icons";
import { Activity, ComponentIncludes } from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import { EnvironmentLink } from "~/components/common/resources/ResourceLinks";

export function formatEnvironmentMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const envLink = (
        <EnvironmentLink
            environmentId={item.component?.id}
            components={components}
        />
    );

    switch (item.event) {
        case "environment.initialize":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Initialized environment {envLink}</span>
                ) : (
                    <span>Error initializing environment</span>
                ),
                icon: faCheckCircle,
            };
        case "environment.task.initialize":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested initialization of environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting initialization of environment {envLink}
                    </span>
                ),
                icon: faCheckCircle,
            };
        case "environment.start":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Started environment {envLink}</span>
                ) : (
                    <span>Error starting environment {envLink}</span>
                ),
                icon: faPlay,
            };
        case "environment.stop":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Stopped environment {envLink}</span>
                ) : (
                    <span>Error stopping environment {envLink}</span>
                ),
                icon: faStop,
            };
        case "environment.task.start":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested start of environment {envLink}</span>
                ) : (
                    <span>Error requesting start of environment {envLink}</span>
                ),
                icon: faPlay,
            };
        case "environment.task.stop":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested stop of environment {envLink}</span>
                ) : (
                    <span>Error requesting stop of environment {envLink} </span>
                ),
                icon: faStop,
            };

        case "environment.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted environment {envLink}</span>
                ) : (
                    <span>Error deleting environment {envLink}</span>
                ),
                icon: faTrash,
            };

        case "environment.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested environment {envLink} be deleted</span>
                ) : (
                    <span>
                        Error requesting deletion of environment {envLink}{" "}
                    </span>
                ),
                icon: faTrash,
            };
        case "environment.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated environment {envLink}</span>
                ) : (
                    <span>Error updating environment {envLink}</span>
                ),
                icon: faArrowCircleUp,
            };
        case "environment.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Created environment {envLink}</span>
                ) : (
                    <span>Error creating environment</span>
                ),
                icon: faPlus,
            };
        case "environment.services.vpn.reset":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Reset the VPN Service for environment {envLink}</span>
                ) : (
                    <span>
                        Error resetting the VPN Service for environment{" "}
                        {envLink}
                    </span>
                ),
                icon: faSync,
            };

        case "environment.services.vpn.task.reset":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested reset of the VPN Service for environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>
                        Error resetting the VPN Service for environment{" "}
                        {envLink}
                    </span>
                ),
                icon: faSync,
            };
        case "environment.services.discovery.reconfigure":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Reconfigured the Discovery Service for environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>
                        Error reconfiguring the Discovery Service for
                        environment {envLink}
                    </span>
                ),
                icon: faSync,
            };
        case "environment.services.discovery.task.reconfigure":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested reconfigure of the Discovery Service for
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error reconfiguring the Discovery Service for
                        environment {envLink}
                    </span>
                ),
                icon: faSync,
            };
        case "environment.services.lb.reconfigure":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Reconfigured the Load Balancer Service for environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>
                        Error reconfiguring the Load Balancer Service for
                        environment {envLink}
                    </span>
                ),
                icon: faSync,
            };
        case "environment.services.lb.task.reconfigure":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested reconfigure of the Load Balancer Service for
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting reconfigure of the Load Balancer
                        Service for environment {envLink}
                    </span>
                ),
                icon: faSync,
            };
        case "environment.services.vpn.reconfigure":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Reconfigured the VPN Service for environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error reconfiguring the VPN Service for environment{" "}
                        {envLink}
                    </span>
                ),
                icon: faSync,
            };
        case "environment.services.vpn.task.reconfigure":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested reconfigure of the VPN Service for environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting reconfigure of the VPN Service for
                        environment {envLink}
                    </span>
                ),
                icon: faSync,
            };
        case "environment.services.vpn.user.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Created new VPN user for environment {envLink}</span>
                ) : (
                    <span>
                        Error creating new VPN user for environment {envLink}
                    </span>
                ),
                icon: faPlus,
            };
        case "environment.services.vpn.login": {
            const user = item.annotations["username"] as string;
            const ip = item.annotations["ip"] as string;

            const ipMsg = ip ? (
                <span>
                    from IP <strong>{ip}</strong>
                </span>
            ) : (
                ""
            );

            const loginMsg = isErroredActivity(item) ? (
                <span className="text-error font-bold">failed to log in</span>
            ) : (
                "logged in"
            );

            return {
                message: (
                    <span>
                        User <strong>{user}</strong> {loginMsg} to {envLink} VPN{" "}
                        {ipMsg}{" "}
                    </span>
                ),
                icon: faPlus,
            };
        }
        case "environment.services.scheduler.reconfigure":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Reconfigured the Scheduler Service for environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>
                        Error reconfiguring the Scheduler Service for
                        environment {envLink}
                    </span>
                ),
                icon: faClock,
            };
        case "environment.services.scheduler.task.reconfigure":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested reconfigure of the Scheduler Service for
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error reconfiguring the Scheduler Service for
                        environment {envLink}
                    </span>
                ),
                icon: faClock,
            };
        case "environment.deployments.reconfigure": {
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Reconfigured deployments for environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error reconfiguring deployments for environment{" "}
                        {envLink}
                    </span>
                ),
                icon: faRocketLaunch,
            };
        }
        case "environment.deployments.prune": {
            return {
                message: !isErroredActivity(item) ? (
                    <span>Pruned deployments for environment {envLink}</span>
                ) : (
                    <span>
                        Error pruning deployments for environment {envLink}
                    </span>
                ),
                icon: faRocketLaunch,
            };
        }
        case "environment.deployment.start": {
            return {
                message: !isErroredActivity(item) ? (
                    <span>Started deployment in environment {envLink}</span>
                ) : (
                    <span>
                        Error starting deployment in environment {envLink}
                    </span>
                ),
                icon: faPlay,
            };
        }
        case "environment.deployment.stop": {
            return {
                message: !isErroredActivity(item) ? (
                    <span>Stopped deployment in environment {envLink}</span>
                ) : (
                    <span>
                        Error stopping deployment in environment {envLink}
                    </span>
                ),
                icon: faStop,
            };
        }
        case "environment.task.deployments.reconfigure":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested reconfigure of deployments for environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting reconfigure of deployments for
                        environment {envLink}
                    </span>
                ),
                icon: faRocketLaunch,
            };
        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}
