import {
    Button,
    PushAndHoldButton,
} from "@cycleplatform/ui/components/buttons";
import {
    hasMaximumValue,
    RhfFormField,
    RhfFormProvider,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useContext, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { AccessControlOverlay } from "~/components/common/buttons";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { LoadBalancerDialogContext } from "../../../../context";
import { getDefaultLbFormVals } from "../../../form";
import { rhfConfig } from "~/components/forms/util";
import { useKeepFormCurrent } from "~/components/common/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PositionedMenu } from "@cycleplatform/ui/components/menus";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { useCapability } from "~/modules/hubs/permissions/useCapability";
import { AddControllerForm, useAddController } from "./util";

export function V1AddControllerMenu() {
    const hasCap = useCapability("environments-services-manage");
    if (hasCap instanceof Error) {
        return null;
    }
    return (
        <PositionedMenu
            placement="bottom-start"
            className={"w-[20rem]"}
            render={(_, setIsOpen) => (
                <V1AddControllerForm setIsOpen={setIsOpen} />
            )}
        >
            <Tooltip message="Add Port" className="relative">
                <Button
                    icon={faPlus}
                    className="h-[40px] w-[40px] !px-2 text-xs"
                />
            </Tooltip>
        </PositionedMenu>
    );
}
function V1AddControllerForm({
    setIsOpen,
}: {
    setIsOpen: (open: boolean) => void;
}) {
    const { environment, service } = useContext(LoadBalancerDialogContext);

    const form = useForm<AddControllerForm>({
        defaultValues: getDefaultLbFormVals(service),
        ...rhfConfig,
    });
    const {
        handleSubmit,
        control,
        register,
        formState: { isSubmitting, isDirty },
    } = form;

    useKeepFormCurrent(form, service, (lb) => getDefaultLbFormVals(lb));

    const addController = useAddController({ ...form, setIsOpen });

    const onSubmit = (data: AddControllerForm) => {
        if (!isDirty) {
            return;
        }
        return addController(data);
    };

    const controllers = useWatch({
        name: `config.details.controllers`,
        control,
    });

    const existingPorts = useMemo(
        () => controllers.map((c) => c.port.toString()),
        [controllers]
    );

    return (
        <>
            <div className="pb-4 text-lg">
                <FontAwesomeIcon icon={faPlus} /> Add LB Port
            </div>
            <RhfFormProvider {...form}>
                <div className="flex items-center gap-4">
                    <AccessControlOverlay
                        aclResource={environment}
                        verifyFn={modifyAccessFn(
                            "environments-services-manage"
                        )}
                    >
                        <RhfFormField name="newController">
                            <TextInput
                                className="dark:disabled:bg-cycle-gray/50"
                                placeholder="Port #"
                                type="number"
                                {...register("newController", {
                                    valueAsNumber: true,
                                    validate: {
                                        isValidPort: (value) =>
                                            !existingPorts.includes(
                                                value?.toString() || ""
                                            ) || "Port already exists",
                                        ...hasMaximumValue(65535),
                                    },
                                })}
                            />
                        </RhfFormField>
                    </AccessControlOverlay>
                    <AccessControlOverlay
                        aclResource={environment}
                        verifyFn={modifyAccessFn(
                            "environments-services-manage"
                        )}
                    >
                        <PushAndHoldButton
                            type="button"
                            icon={faPlus}
                            disabled={!isDirty}
                            onClick={handleSubmit(onSubmit)}
                            isLoading={isSubmitting}
                        >
                            Add
                        </PushAndHoldButton>
                    </AccessControlOverlay>
                </div>
            </RhfFormProvider>
        </>
    );
}
