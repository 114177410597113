import { Button, LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    RhfFormProvider,
    RhfGlobalFormError,
    RhfFormField,
    RHF_GLOBAL_ERROR,
} from "@cycleplatform/ui/components/forms";
import { faArrowLeft, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { Controller, useForm, useFormContext } from "react-hook-form";
import { rhfConfig } from "~/components/forms/util";
import { useContext, useEffect } from "react";
import { MultiStepDialogContext } from "~/components/dialogs/MultiStepFormDialog/context";
import { VmCreateFormSteps, VmDeploymentStepValues } from "./types";
import {
    DialogColumn,
    DialogFooter,
} from "@cycleplatform/ui/components/dialog/components";
import { InfoPanel } from "@cycleplatform/ui/components/panels";
import { TagSelectInput } from "~/components/common/forms/TagSelectInput";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { addStepData, getCurrentForm } from "~/modules/forms/slice";
import { useGetEnvironmentQuery } from "~/services/cycle";
import { LearnMore } from "./LearnMore";

export function VmDeploymentStep({ stepIdx = 0 }: { stepIdx?: number }) {
    const { handleBack, handleNext, formKey } = useContext(
        MultiStepDialogContext
    );
    const currentForm = useAppSelector((state) =>
        getCurrentForm(state, formKey)
    );

    const form = useForm<VmDeploymentStepValues>({
        defaultValues: currentForm?.steps?.[stepIdx] || {},
        ...rhfConfig,
    });

    const {
        handleSubmit,
        setError,
        formState: { isSubmitting },
    } = form;

    const dispatch = useAppDispatch();

    const onSubmit = async (data: VmDeploymentStepValues) => {
        dispatch(addStepData({ data, idx: stepIdx, key: formKey }));
        handleNext?.();
    };
    const error = currentForm?.error;

    useEffect(() => {
        if (error && error.step === stepIdx) {
            setError(RHF_GLOBAL_ERROR as never, { message: error.message });
        }
    }, [error]);

    return (
        <RhfFormProvider
            {...form}
            onSubmit={handleSubmit(onSubmit)}
            className="h-full"
        >
            <div className="flex ">
                <DialogColumn className="w-2/3">
                    <DeployContraints />
                </DialogColumn>
                <LearnMore title="Virtual Machine Deployments">
                    A server <strong>MUST</strong> have support for the
                    hypervisor feature for Cycle to deploy virtual machines to
                    it. Configure any additional constraints here.
                </LearnMore>
            </div>

            <DialogFooter className="justify-between ">
                <Button icon={faArrowLeft} onClick={() => handleBack?.()}>
                    Back
                </Button>
                <div>
                    <RhfGlobalFormError />
                </div>
                <LoaderButton
                    isLoading={isSubmitting}
                    type="submit"
                    flavor="primary"
                    icon={faPlus}
                    onClick={handleSubmit(onSubmit)}
                >
                    Next
                </LoaderButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}

export function DeployContraints() {
    const form = useFormContext<VmDeploymentStepValues>();
    const { formKey } = useContext(MultiStepDialogContext);
    const currentForm = useAppSelector((state) =>
        getCurrentForm(state, formKey)
    );

    const step0 = currentForm?.steps[0] as VmCreateFormSteps[0];

    const envId = step0.environment_id;

    const { data: environment } = useGetEnvironmentQuery({
        environmentId: envId,
    });

    const { control } = form;

    return (
        <>
            <InfoPanel type="info">
                <p>
                    Constraints provide the ability to set restrictions on which
                    nodes instances of this virtual machine are able to be
                    deployed to (i.e. be sure to apply to a server that supports
                    hypervisor).
                </p>
            </InfoPanel>

            <div className="flex gap-4">
                <div className="w-1/2">
                    <RhfFormField
                        label="Node Tags (Match One)"
                        name="constraints.node.tags.any"
                    >
                        <Controller
                            name="constraints.node.tags.any"
                            control={control}
                            render={({ field: { ref: _ref, ...field } }) => (
                                <TagSelectInput
                                    {...field}
                                    value={field.value || []}
                                    cluster={environment?.data?.cluster}
                                />
                            )}
                        />
                    </RhfFormField>
                </div>
                <div className="w-1/2">
                    <RhfFormField
                        label="Node Tags (Match All)"
                        name="constraints.node.tags.all"
                    >
                        <Controller
                            name="constraints.node.tags.all"
                            control={control}
                            render={({ field: { ref: _ref, ...field } }) => (
                                <TagSelectInput
                                    {...field}
                                    value={field.value || []}
                                    cluster={environment?.data?.cluster}
                                />
                            )}
                        />
                    </RhfFormField>
                </div>
            </div>
        </>
    );
}
