import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faGearCode } from "@fortawesome/pro-duotone-svg-icons";
import { FilterContext } from "./FilterContext";
import { PositionedMenu } from "../../menus";
import { ActiveFilter } from "./ActiveFilter";
import { FilterGroupProperties } from "./FilterGroup";
import classNames from "classnames";
import { faFilterList } from "@fortawesome/pro-solid-svg-icons";

type FilterMenuProps = {
    filtering: FilterContext;
    children: React.ReactElement<FilterGroupProperties>[];
    className?: string;
};

export function FilterMenu({
    filtering,
    children,
    className,
}: FilterMenuProps) {
    return (
        <div className={classNames("flex items-center gap-2", className)}>
            <FilterContext.Provider value={filtering}>
                {Object.entries(filtering.filters).map(
                    ([groupIdentifier, { name, value }], idx) => {
                        return (
                            <ActiveFilter
                                groupName={name}
                                groupIdentifier={groupIdentifier}
                                filterValues={value}
                                key={`active-${name}-${idx}`}
                            >
                                {children}
                            </ActiveFilter>
                        );
                    }
                )}
                <PositionedMenu
                    className={"w-[20rem]"}
                    placement="bottom-end"
                    render={() => (
                        <div className="flex flex-col gap-4 ">{children}</div>
                    )}
                >
                    <button
                        className={classNames(
                            "border-cycle-gray/30 dark:border-cycle-white/10 rounded-md border p-1 px-2 text-xs",
                            "flex items-center gap-2",
                            "text-cycle-black dark:bg-cycle-black bg-white dark:text-white ",
                            "h-[24px] items-center "
                        )}
                    >
                        <FontAwesomeIcon icon={faFilterList} />
                        <p>Filter</p>
                    </button>
                </PositionedMenu>
            </FilterContext.Provider>
        </div>
    );
}
