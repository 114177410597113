import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { DeployContraints } from "./sections/DeployConstraints";
import { DeployStartupPolicy } from "./sections/DeployStartupPolicy";
import { DeployShutdownPolicy } from "./sections/DeployShutdownPolicy";
import { DeployTelemetry } from "./sections/DeployTelemetry";
import { ToggleFormSection } from "@cycleplatform/react-hook-form-components/components";
import { VmConfigFormData } from "../types";

export function VmDeploymentSection() {
    return (
        <>
            <Panel>
                <PanelTitle
                    title="Deployment"
                    className="flex justify-start px-4"
                />
                <PanelContent>
                    <ToggleFormSection<VmConfigFormData>
                        header="Constraints"
                        field="config.deploy.constraints"
                    >
                        {() => <DeployContraints />}
                    </ToggleFormSection>

                    <ToggleFormSection<VmConfigFormData>
                        header="Startup Policy"
                        field="config.deploy.startup"
                    >
                        {() => <DeployStartupPolicy />}
                    </ToggleFormSection>

                    <ToggleFormSection<VmConfigFormData>
                        header="Shutdown Policy"
                        field="config.deploy.shutdown"
                    >
                        {() => <DeployShutdownPolicy />}
                    </ToggleFormSection>

                    <ToggleFormSection<VmConfigFormData>
                        header="Telemetry"
                        field="config.deploy.telemetry"
                    >
                        {() => <DeployTelemetry />}
                    </ToggleFormSection>

                    {/*  */}
                </PanelContent>
            </Panel>
        </>
    );
}
