import {
    ResourceDialogTab,
    StyledResourceDialog,
} from "@cycleplatform/ui/components/dialog";
import { SkeletonHeader } from "@cycleplatform/ui/components/loaders/skeleton";
import {
    faDashboard,
    faGearComplexCode,
} from "@fortawesome/pro-duotone-svg-icons";
import { useNavigate } from "react-router-dom";
import { CycleErrorBoundary } from "~/components/common/errors";
import {
    clearDialogParams,
    getAllDialogSearchParams,
    isDialogOpen,
} from "~/components/dialogs/helpers";
import {
    useCreateContainerJobMutation,
    useGetContainerQuery,
    useGetEnvironmentQuery,
    useGetLoadBalancerServiceQuery,
} from "~/services/cycle";
import { DashboardTab } from "./dashboard";
import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import { preprocessCountsData } from "@cycleplatform/ui/components/charts";
import { ContainerStateControls } from "~/components/containers/controls";
import { InstancesTab } from "./instances/InstancesTab";
import { NavIcons } from "~/components/layout/NavIcons";
import { ServiceContainerBadge } from "~/components/containers/ServiceContainerBadge";
import { EmptyActionPanel } from "@cycleplatform/ui/components/panels";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import { useJobTracker } from "~/modules/jobs/hooks";
import { LoadBalancerDialogContext } from "./context";
import { ResourceStatePieChart } from "~/components/common/charts/ResourceStatePieChart";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { FirewallTab } from "./firewall/FirewallTab";
import { ControllersTab } from "./controllers";

export function ManageLoadBalancerDialog() {
    const params = getAllDialogSearchParams<"environment-lb-manage">();
    const isOpen = isDialogOpen("environment-lb-manage");
    const navigate = useNavigate();

    const { currentData: environment, error } = useGetEnvironmentQuery(
        {
            environmentId: params["dialog-env"],
        },
        { skip: !params["dialog-env"] }
    );

    const { currentData: loadbalancer, error: lbContainerError } =
        useGetContainerQuery(
            {
                containerId:
                    environment?.data?.services.loadbalancer?.container_id ||
                    "",
                meta: ["ips", "instances_count"],
                include: ["environments"],
            },
            { skip: !environment?.data?.services.loadbalancer }
        );

    const loadBalancerDisabled =
        environment?.data?.services?.loadbalancer?.enable === false;

    const { data: lbInfo, error: lbInfoError } = useGetLoadBalancerServiceQuery(
        {
            environmentId: environment?.data?.id || "",
        },
        { skip: !environment?.data?.id || loadBalancerDisabled }
    );

    if (error) {
        throw error;
    }
    if (lbContainerError) {
        throw lbContainerError;
    }
    if (lbInfoError) {
        throw lbInfoError;
    }

    return (
        <LoadBalancerDialogContext.Provider
            value={{
                environment: environment?.data,
                lbContainer: loadbalancer?.data,
                service: lbInfo?.data,
            }}
        >
            <StyledResourceDialog
                open={isOpen}
                onClose={() => navigate(clearDialogParams())}
                header={
                    <div className="flex w-full items-center justify-between">
                        <div className="flex h-full items-center gap-4">
                            <h1 className="uppercase">
                                {environment?.data?.name ? (
                                    <>
                                        {environment?.data?.name}: Load Balancer
                                    </>
                                ) : (
                                    <SkeletonHeader size="lg" />
                                )}
                            </h1>
                            <ResourceStateBadge
                                className="!bg-cycle-gray !h-8"
                                state={loadbalancer?.data?.state}
                            />
                            <ServiceContainerBadge />
                            <div className="h-10 w-10">
                                <ResourceStatePieChart
                                    data={preprocessCountsData(
                                        loadbalancer?.data?.meta
                                            ?.instances_count
                                    )}
                                    centerTextClassName="text-cycle-white"
                                />
                            </div>
                        </div>

                        <ContainerStateControls
                            container={loadbalancer?.data}
                            hide={["delete"]}
                            environment={environment?.data}
                        />
                    </div>
                }
                breadcrumb={[
                    {
                        label: "Environments",
                        link: "environments",
                    },
                    {
                        label: environment?.data ? environment.data.name : "",
                        link: `environments/${environment?.data?.id}`,
                    },
                    {
                        label: "Services",
                    },
                    {
                        label: "Load Balancer",
                    },
                ]}
            >
                <ResourceDialogTab
                    to={null}
                    name="Dashboard"
                    icon={faDashboard}
                >
                    <CycleErrorBoundary>
                        <DashboardTab
                            container={loadbalancer?.data}
                            lbInfo={lbInfo?.data}
                        />
                    </CycleErrorBoundary>
                </ResourceDialogTab>
                <ResourceDialogTab
                    to="controllers"
                    name="Controllers"
                    icon={NavIcons["lbController"]}
                >
                    <CycleErrorBoundary>
                        {!loadBalancerDisabled ? (
                            <ControllersTab />
                        ) : (
                            <LoadBalancerDisabledPanel
                                containerId={
                                    environment?.data?.services.loadbalancer
                                        ?.container_id
                                }
                                icon={NavIcons["containerConfig"]}
                            />
                        )}
                    </CycleErrorBoundary>
                </ResourceDialogTab>
                <ResourceDialogTab
                    to="firewall"
                    name="Firewall"
                    icon={NavIcons["firewall"]}
                >
                    <CycleErrorBoundary>
                        {!loadBalancerDisabled ? (
                            <FirewallTab />
                        ) : (
                            <LoadBalancerDisabledPanel
                                containerId={
                                    environment?.data?.services.loadbalancer
                                        ?.container_id
                                }
                                icon={NavIcons["containerConfig"]}
                            />
                        )}
                    </CycleErrorBoundary>
                </ResourceDialogTab>
                <ResourceDialogTab
                    to="instances"
                    name="Instances"
                    icon={NavIcons["instances"]}
                >
                    <CycleErrorBoundary>
                        <InstancesTab
                            container={loadbalancer?.data}
                            containerId={
                                environment?.data?.services.loadbalancer
                                    ?.container_id || ""
                            }
                            instanceId={params["dialog-instance-id"]}
                        />
                    </CycleErrorBoundary>
                </ResourceDialogTab>
            </StyledResourceDialog>
        </LoadBalancerDialogContext.Provider>
    );
}

function LoadBalancerDisabledPanel({
    containerId,
    icon,
}: {
    containerId?: string | null;
    icon: IconDefinition;
}) {
    const [createContainerJob] = useCreateContainerJobMutation();
    const [trackStartJob, { isTrackingJob: isTrackingStartJob }] =
        useJobTracker();
    return (
        <EmptyActionPanel
            title="Load Balancer Disabled"
            details="Start the Load Balancer below to enable the Load Balancer and complete the initial setup."
            icon={icon}
        >
            <PushAndHoldButton
                icon={faPlay}
                isLoading={isTrackingStartJob}
                aria-label="Start Container"
                flavor="primary"
                className={"border-cycle-blue-accent border-r"}
                onClick={() =>
                    trackStartJob(
                        createContainerJob({
                            containerId: containerId || "",
                            containerTask: {
                                action: "start",
                            },
                        }).unwrap()
                    )
                }
            >
                Start Load Balancer
            </PushAndHoldButton>
        </EmptyActionPanel>
    );
}
