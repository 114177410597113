import {
    Checkbox,
    InputRow,
    RhfFormField,
    TextInput,
    isDurationString,
    required,
} from "@cycleplatform/ui/components/forms";
import { useFormContext } from "react-hook-form";

import { VmConfigFormData } from "../../types";

export function DeployTelemetry() {
    const { register, watch, unregister } = useFormContext<VmConfigFormData>();

    return (
        <InputRow>
            <RhfFormField
                className="flex-grow"
                label="retention"
                name="config.deploy.telemetry.retention"
                required
            >
                <TextInput
                    defaultValue={"21600s"}
                    {...register("config.deploy.telemetry.retention", {
                        ...required(),
                        validate: {
                            ...isDurationString(),
                        },
                    })}
                />
            </RhfFormField>
            <RhfFormField
                label="interval"
                name="config.deploy.telemetry.interval"
                required
            >
                <TextInput
                    defaultValue={"15s"}
                    {...register("config.deploy.telemetry.interval", {
                        ...required(),
                        validate: {
                            ...isDurationString(),
                        },
                    })}
                />
            </RhfFormField>

            <RhfFormField
                label="disable telemetry"
                name="config.deploy.telemetry.disable"
                help="Will disable telemetry for this
                                    container and its instances"
            >
                <Checkbox
                    defaultChecked={false}
                    {...register("config.deploy.telemetry.disable")}
                />
            </RhfFormField>
        </InputRow>
    );
}
