import { useFormContext } from "react-hook-form";
import {
    Checkbox,
    RhfFormField,
    TextInput,
    required,
    FormSectionHeader,
    FormSection,
} from "@cycleplatform/ui/components/forms";
import { CreateBillingMethodFormType } from "../CreateBillingMethodForm";

export function MethodGeneralSection({
    isEdit,
    canBePrimary,
}: {
    isEdit?: boolean;
    canBePrimary?: boolean;
}) {
    const { register } = useFormContext<CreateBillingMethodFormType>();

    return (
        <>
            <FormSectionHeader header="General" />
            <FormSection>
                <div className="flex w-full gap-4">
                    <RhfFormField
                        label="Payment Method Name"
                        name="name"
                        className="w-full"
                        required
                    >
                        <TextInput {...register("name", { ...required() })} />
                    </RhfFormField>

                    {isEdit ? (
                        <RhfFormField
                            label="make primary"
                            name="primary"
                            className="!w-40"
                        >
                            <Checkbox
                                {...register("primary")}
                                disabled={!canBePrimary}
                            />
                        </RhfFormField>
                    ) : null}
                </div>
            </FormSection>
        </>
    );
}
