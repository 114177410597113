import {
    Button,
    LoaderButton,
    PopoverButtonContext,
    PositionedMenuButton,
} from "@cycleplatform/ui/components/buttons";
import {
    FormProvider,
    useFieldArray,
    useForm,
    useFormContext,
} from "react-hook-form";
import { AccessControlOverlay } from "~/components/common/buttons";
import { Container, Environment } from "~/services/cycle";
import { rhfConfig } from "~/components/forms/util";
import { faFunction, faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    FormSection,
    FormSectionHeader,
    RhfFormField,
    TextInput,
    minLength,
    required,
} from "@cycleplatform/ui/components/forms";
import { useJobTracker } from "~/modules/jobs/hooks";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { useCreateFunctionJobMutation } from "~/services/cycle";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import { isCycleApiError } from "~/services/helpers";
import { useAppDispatch } from "~/hooks";
import { pushNotification } from "~/modules/notifications/slice";
import { useContext, useEffect } from "react";
import classNames from "classnames";
import { containerTriggerFunctionAccessFn } from "@cycleplatform/core/modules/containers/acls";
import { generateRandomString } from "@cycleplatform/core/util";

type FunctionControlsProps = {
    container?: Container;
    disabled?: boolean;
    environment?: Environment;
    className?: string;
};

type FunctionTriggerForm = {
    token: string;
    runtime_variables?: { var: string; val: string }[];
};

export function FunctionControls({
    container,
    environment,
    disabled,
}: FunctionControlsProps) {
    const form = useForm<FunctionTriggerForm>({
        defaultValues: {
            token: generateRandomString(5),
            runtime_variables: [],
        },
        ...rhfConfig,
    });

    const [trackJob, { isTrackingJob }] = useJobTracker();
    const [createFunctionJob, { isLoading, error }] =
        useCreateFunctionJobMutation({});

    const dispatch = useAppDispatch();

    const onSubmit = (data: FunctionTriggerForm) => {
        return trackJob(
            createFunctionJob({
                containerId: container?.id || "",
                functionTask: {
                    action: "trigger",
                    contents: {
                        token: data.token,
                        runtime_variables: {
                            environment_variables:
                                data?.runtime_variables?.reduce((acc, cur) => {
                                    acc[cur.var] = cur.val;
                                    return acc;
                                }, {} as Record<string, string>),
                        },
                    },
                },
            }).unwrap()
        )
            .then(() => {
                form.reset({
                    token: generateRandomString(5),
                    runtime_variables: [],
                });
            })
            .catch((err) =>
                dispatch(
                    pushNotification({
                        title: `Error triggering function container ${container?.name}`,
                        message: `${err?.data?.error?.title}`,
                        type: "error",
                        icon: "containers",
                    })
                )
            );
    };

    return (
        <AccessControlOverlay
            aclResource={environment}
            verifyFn={
                !container
                    ? () => undefined
                    : containerTriggerFunctionAccessFn(container)
            }
        >
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <FormProvider {...form}>
                    <div>
                        <PositionedMenuButton
                            disabled={disabled}
                            flavor="primary"
                            pushAndHold
                            icon={faFunction}
                            tooltip={"Hold to trigger this function"}
                            className={classNames(
                                "border-cycle-blue-accent flex h-[32px] border !text-base",
                                disabled && "!text-cycle-white !bg-cycle-gray"
                            )}
                            popout={
                                <TriggerFunctionForm
                                    isLoading={isLoading || isTrackingJob}
                                    error={error}
                                />
                            }
                            onClick={form.handleSubmit(onSubmit)}
                            onClose={() => form.reset()}
                            isLoading={isLoading || isTrackingJob}
                            panelClassName={"!w-[40rem]  "}
                        />
                    </div>
                </FormProvider>
            </form>
        </AccessControlOverlay>
    );
}

function TriggerFunctionForm({
    isLoading,
    error,
}: {
    isLoading: boolean;
    error: FetchBaseQueryError | SerializedError | undefined;
}) {
    const { register, control, formState } =
        useFormContext<FunctionTriggerForm>();
    const { close } = useContext(PopoverButtonContext);

    useEffect(() => {
        if (formState.isSubmitSuccessful) {
            close();
        }
    }, [formState.isSubmitSuccessful]);

    const { fields, append, remove } = useFieldArray({
        name: "runtime_variables",
        control,
    });
    return (
        <div className="text-base">
            <h3 className="pb-4 text-lg">Trigger Function</h3>
            <RhfFormField
                name="token"
                label="token"
                help="A custom token used for identifying and managing a claim."
                required
            >
                <TextInput
                    placeholder="Use platform generated token"
                    {...register("token", {
                        ...required(),
                        ...minLength(5),
                    })}
                />
            </RhfFormField>

            <FormSectionHeader header="Runtime Variables" />
            <FormSection>
                <StyledDataTable>
                    <StyledTableHead>
                        <StyledHeaderCell className="w-[15rem] !pl-0">
                            Variable
                        </StyledHeaderCell>
                        <StyledHeaderCell className="w-[15rem]">
                            Value
                        </StyledHeaderCell>
                        <StyledHeaderCell className="!w-[5rem]"></StyledHeaderCell>
                    </StyledTableHead>
                    <tbody>
                        {fields.map((v, idx) => {
                            return (
                                <StyledTableRow key={v.id}>
                                    <StyledCell className="!pl-0 pr-2">
                                        <RhfFormField
                                            name={`runtime_variables.${idx}.var`}
                                        >
                                            <TextInput
                                                {...register(
                                                    `runtime_variables.${idx}.var`,
                                                    { ...required() }
                                                )}
                                            />
                                        </RhfFormField>
                                    </StyledCell>
                                    <StyledCell className="pr-4">
                                        <RhfFormField
                                            name={`runtime_variables.${idx}.val`}
                                        >
                                            <TextInput
                                                {...register(
                                                    `runtime_variables.${idx}.val`,
                                                    { ...required() }
                                                )}
                                            />
                                        </RhfFormField>
                                    </StyledCell>
                                    <StyledCell className="pr-4">
                                        <Button
                                            flavor="discard"
                                            onClick={() => remove(idx)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                    </StyledCell>
                                </StyledTableRow>
                            );
                        })}
                        <StyledTableRow>
                            <StyledCell colSpan={3} className="!pl-0">
                                <Button
                                    type="button"
                                    icon={faPlus}
                                    className="w-full"
                                    onClick={() =>
                                        append({
                                            var: "",
                                            val: "",
                                        })
                                    }
                                >
                                    Add
                                </Button>
                            </StyledCell>
                        </StyledTableRow>
                    </tbody>
                </StyledDataTable>
            </FormSection>

            <PanelFooter className="mt-4 flex items-center justify-between">
                <div className="text-error text-sm">
                    {error && isCycleApiError(error) ? (
                        <p>{`Error: ${error.data.error.title}`}</p>
                    ) : null}
                </div>
                <LoaderButton
                    isLoading={isLoading}
                    type="submit"
                    flavor="primary"
                    icon={faFunction}
                >
                    Trigger
                </LoaderButton>
            </PanelFooter>
        </div>
    );
}
