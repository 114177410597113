import { ButtonLink } from "@cycleplatform/ui/components/buttons";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { NavIcons } from "~/components/layout/NavIcons";
import { useGetHubsQuery } from "~/services/cycle";

export function ChooseAnotherHubButton() {
    const { data: hubs } = useGetHubsQuery({});

    if (hubs?.data && hubs?.data?.length === 1) {
        return null;
    }

    return (
        <ButtonLink
            to={generateDialogLink("hub-select", {})}
            icon={NavIcons["hubs"]}
        >
            Choose Another Hub
        </ButtonLink>
    );
}
