import {
    faPlay,
    faPlus,
    faTrash,
    faSync,
    faHdd,
    faFileCheck,
    faArrowCircleUp,
    faStop,
    faQuestionCircle,
    faArrowCircleDown,
    faSortCircle,
    faRefresh,
    faFunction,
} from "@fortawesome/pro-solid-svg-icons";
import { Activity, ComponentIncludes } from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import {
    ContainerLink,
    EnvironmentLink,
    ImageLink,
} from "~/components/common/resources/ResourceLinks";

export function formatContainerMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const containerLink = (
        <ContainerLink
            containerId={item.context.container_id}
            components={components}
        />
    );

    const envLink = (
        <EnvironmentLink
            environmentId={item.context.environment_id}
            components={components}
        />
    );

    switch (item.event) {
        case "container.initialize":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Initialized container {containerLink} in environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>Error creating container</span>
                ),
                icon: faPlus,
            };

        case "container.task.start":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested start of container {containerLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting start of container {containerLink} in
                        environment {envLink}
                    </span>
                ),
                icon: faPlay,
            };
        case "container.function.trigger":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Triggered function container {containerLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error triggering function container {containerLink} in
                        environment {envLink}
                    </span>
                ),
                icon: faFunction,
            };
        case "container.function.task.trigger":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested trigger of function container {containerLink}{" "}
                        in environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting trigger of function container{" "}
                        {containerLink} in environment {envLink}
                    </span>
                ),
                icon: faFunction,
            };

        case "container.start":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Started container {containerLink} in environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>
                        Error starting container {containerLink} in environment{" "}
                        {envLink}
                    </span>
                ),
                icon: faPlay,
            };

        case "container.task.stop":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested stop of container {containerLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting stop of container {containerLink} in
                        environment {envLink}
                    </span>
                ),
                icon: faStop,
            };

        case "container.stop":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Stopped container {containerLink} in environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>
                        Error stopping container {containerLink} in environment{" "}
                        {envLink}
                    </span>
                ),
                icon: faStop,
            };

        case "container.task.reconfigure":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested reconfiguration of container {containerLink}{" "}
                        in environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting reconfiguration of container{" "}
                        {containerLink} in environment {envLink}
                    </span>
                ),
                icon: faSync,
            };

        case "container.reconfigure":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Reconfigured container {containerLink} in environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>
                        Error reconfiguring container {containerLink} in
                        environment {envLink}
                    </span>
                ),
                icon: faSync,
            };

        case "container.task.volumes.reconfigure":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested volume reconfiguration of container{" "}
                        {containerLink} in environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting volume reconfiguration of container{" "}
                        {containerLink} in environment {envLink}
                    </span>
                ),
                icon: faHdd,
            };

        case "container.volumes.reconfigure":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Reconfigured volumes for container {containerLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error reconfiguring volumes for container{" "}
                        {containerLink} in environment {envLink}
                    </span>
                ),
                icon: faHdd,
            };

        case "container.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Created container {containerLink} in environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>Error creating container</span>
                ),
                icon: faPlus,
            };

        case "container.restart":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Restarted container {containerLink} in environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>Error restarting container</span>
                ),
                icon: faRefresh,
            };

        case "container.task.reimage":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested reimaging of container {containerLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting reimaging of container {containerLink}{" "}
                        in environment {envLink}
                    </span>
                ),
                icon: faSortCircle,
            };

        case "container.reimage": {
            const prevId =
                (item.changes.length && item.changes[0].before?.id) || "";
            const curId =
                (item.changes.length && item.changes[0].after?.id) || "";

            const prevLink = (
                <ImageLink imageId={prevId} components={components} />
            );

            const curLink = (
                <ImageLink imageId={curId} components={components} />
            );

            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Reimaged container {containerLink}{" "}
                        <span>
                            from {prevLink} to {curLink}
                        </span>
                    </span>
                ) : (
                    <span>
                        Error reimaging container {containerLink} in environment{" "}
                        {envLink}
                    </span>
                ),
                icon: faFileCheck,
            };
        }

        case "container.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Updated container {containerLink} in environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>
                        Error updating container {containerLink} in environment{" "}
                        {envLink}
                    </span>
                ),
                icon: faArrowCircleUp,
            };

        case "container.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested deletion of container {containerLink} from
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting deletion of container {containerLink}{" "}
                        from environment {envLink}
                    </span>
                ),
                icon: faTrash,
            };

        case "container.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Deleted container {containerLink} from environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>Error deleting container {containerLink}</span>
                ),
                icon: faTrash,
            };

        case "container.task.scale":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested scaling of container {containerLink} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting scaling of container {containerLink} in
                        environment {envLink}
                    </span>
                ),
                icon: faSortCircle,
            };

        case "container.scale": {
            const [change] = item.changes;
            const increased =
                ((change && change.before?.number) || 0) <
                ((change && change.after?.number) || 0);
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Scaled {increased ? "up" : "down"} container{" "}
                        {containerLink} in environment {envLink} from{" "}
                        <strong>
                            {(item.changes.length &&
                                item.changes[0].before?.number) ??
                                "?"}
                        </strong>{" "}
                        to{" "}
                        <strong>
                            {(item.changes.length &&
                                item.changes[0].after?.number) ??
                                "?"}
                        </strong>{" "}
                        instances
                    </span>
                ) : (
                    <span>
                        Error scaling container {containerLink} in environment{" "}
                        {envLink}
                    </span>
                ),
                icon: increased ? faArrowCircleUp : faArrowCircleDown,
            };
        }
        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}
