import { Transform } from "@fortawesome/fontawesome-svg-core";
import { faTriangleExclamation } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties } from "react";

export function StatusWarningIcon({ transform }: { transform?: Transform }) {
    return (
        <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="text-cycle-white text-xl"
            transform={transform}
            style={
                {
                    "--fa-secondary-opacity": "1",
                    "--fa-secondary-color": "rgb(255 130 42)",
                } as CSSProperties
            }
        />
    );
}
