import { components, operations } from "../../api/__generated";

/**
 * Determines based on the IPs of the load balancer container if it is misconfigured.
 *
 * No IPs indicates that the loadbalancer has an invalid setup (someone unchecked ipv4 & ipv6 in settings)
 */
export function doesLbHaveIps(lb?: components["schemas"]["Container"]) {
    if (!lb?.meta?.ips) {
        return false;
    }
    return Array.isArray(lb.meta.ips) && lb.meta.ips.length > 0;
}

/**
 * Returns true if the given environment is using the Cycle-native load balancer
 */
export function isUsingCycleNativeLb(
    service: operations["getLoadBalancerService"]["responses"][200]["content"]["application/json"]["data"]
): boolean {
    return service.current_type === "v1";
}

export function isV1LbConfig(
    lb:
        | components["schemas"]["HaProxyConfig"]
        | components["schemas"]["V1LbConfig"]
        | null
): lb is components["schemas"]["V1LbConfig"] {
    if (!lb) {
        return false;
    }

    return "controllers" in lb;
}

/**
 *  Determines the actual used load balancer configuration.
 *  Handles merging in the base config if no configuration is specified.
 *  Also merges in controller template and waf if not specified for native lb.
 *
 */
export function getLoadBalancerConfiguration(
    lbInfo: operations["getLoadBalancerService"]["responses"][200]["content"]["application/json"]["data"]
): components["schemas"]["V1LbType"] | components["schemas"]["HaProxyLbType"] {
    if (lbInfo.service?.config) {
        const fullConfig = structuredClone(lbInfo.service.config);

        if (fullConfig.details) {
            // merge in default controller template and/or WAF if missing
            if (fullConfig.type === "v1") {
                if (!fullConfig.details.controller_template) {
                    // merge in controller template from base config
                    fullConfig.details.controller_template =
                        lbInfo.base_configs?.["v1"].controller_template;
                }

                if (!fullConfig.details.waf) {
                    // merge in waf from base config
                    fullConfig.details.waf = lbInfo.base_configs?.["v1"].waf;
                }
            }

            return fullConfig as
                | components["schemas"]["V1LbType"]
                | components["schemas"]["HaProxyLbType"];
        }

        return {
            ...fullConfig,
            type: lbInfo.current_type,
            details: lbInfo.base_configs?.[lbInfo.current_type] || null,
            // need to override due to issue in types
        } as
            | components["schemas"]["V1LbType"]
            | components["schemas"]["HaProxyLbType"];
    }

    return {
        ...lbInfo.default_config,
        type: lbInfo.default_type,
        details: lbInfo.base_configs?.[lbInfo.default_type] || null,
        // need to override due to issue in types
    } as
        | components["schemas"]["V1LbType"]
        | components["schemas"]["HaProxyLbType"];
}
