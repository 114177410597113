import {
    Checkbox,
    FormField,
    RhfFormField,
    TextInput,
    required,
} from "@cycleplatform/ui/components/forms";
import { FieldPath, useFormContext } from "react-hook-form";
import { useState } from "react";
import { GenerateIsoFormType } from "../types";

export function IsoCustomizeTextInput({
    field,
    label,
}: {
    field: FieldPath<GenerateIsoFormType>;
    label: string;
}) {
    const [checked, setChecked] = useState(false);
    const { register, setValue } = useFormContext<GenerateIsoFormType>();

    return (
        <div className="flex gap-4">
            <FormField label="Customize" className="!w-80">
                <Checkbox
                    checked={checked}
                    onChange={(ev) => {
                        if (!ev.target.checked) {
                            setValue(field, null, { shouldDirty: true });
                        }
                        setChecked(ev.target.checked);
                    }}
                />
            </FormField>

            <RhfFormField label={label} name={field} required={checked}>
                {checked ? (
                    <TextInput
                        type="number"
                        {...register(field, {
                            setValueAs: (v) => (v !== "" ? parseInt(v) : null),
                            ...required(),
                        })}
                    />
                ) : (
                    <TextInput disabled />
                )}
            </RhfFormField>
        </div>
    );
}
