import {
    faTrash,
    faArrowCircleUp,
    faQuestionCircle,
    faPlus,
    faBadgeCheck,
    faCheckCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { Activity, ComponentIncludes } from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import { DnsZoneLink } from "~/components/common/resources/ResourceLinks";

export function formatDnsZoneMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const zoneLink = (
        <DnsZoneLink dnsZoneId={item.component?.id} components={components} />
    );

    switch (item.event) {
        case "dns.zone.task.verify":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested verification of DNS zone {zoneLink}</span>
                ) : (
                    <span>
                        Error requesting verification of DNS zone {zoneLink}{" "}
                    </span>
                ),
                icon: faBadgeCheck,
            };
        case "dns.zone.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested deletion of DNS zone {zoneLink}</span>
                ) : (
                    <span>
                        Error requesting deletion of DNS zone {zoneLink}
                    </span>
                ),
                icon: faTrash,
            };
        case "dns.zone.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated DNS zone {zoneLink}</span>
                ) : (
                    <span>Error updating DNS zone {zoneLink}</span>
                ),
                icon: faArrowCircleUp,
            };
        case "dns.zone.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Created DNS zone {zoneLink}</span>
                ) : (
                    <span>Error creating DNS zone</span>
                ),
                icon: faPlus,
            };
        case "dns.zone.verify":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Verified DNS zone {zoneLink}</span>
                ) : (
                    <span>Error verifying DNS zone {zoneLink} </span>
                ),
                icon: faCheckCircle,
            };

        case "dns.zone.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted DNS zone {zoneLink}</span>
                ) : (
                    <span>Error deleting DNS zone {zoneLink} </span>
                ),
                icon: faTrash,
            };

        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}
