import {
    Checkbox,
    required,
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { useForm } from "react-hook-form";
import {
    UpdateContainerApiArg,
    UpdateVirtualMachineApiArg,
    useUpdateVirtualMachineMutation,
    VirtualMachine,
} from "~/services/cycle";
import {
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { SkeletonButton } from "@cycleplatform/ui/components/loaders/skeleton";
import { useKeepFormCurrent } from "~/components/common/forms";
import { RhfIdentifierInput } from "~/components/forms/RhfIdentifierInput";
import { useCapability } from "~/modules/hubs/permissions/useCapability";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { useContext } from "react";
import { AccessControlOverlay } from "~/components/common/buttons";
import { AccessControlledSection } from "~/components/layout/AccessControlledSection";
import { VirtualMachineDialogContex } from "../../../context";
import { vmModifyAccessFn } from "@cycleplatform/core/modules/vms/acls";

function getDefaultValues(vm?: VirtualMachine) {
    return {
        name: vm?.name,
        identifier: vm?.identifier,
        deprecate: vm?.deprecate,
        lock: vm?.lock,
    };
}

export function VmBasicSettingsPanel() {
    const { vm, environment } = useContext(VirtualMachineDialogContex);

    const form = useForm<UpdateVirtualMachineApiArg["body"]>({
        defaultValues: getDefaultValues(vm),
        ...rhfConfig,
    });

    const {
        register,
        handleSubmit,
        formState: { isDirty, isSubmitting },
    } = form;

    useKeepFormCurrent(form, vm, (vm) => getDefaultValues(vm));

    const [updateVm] = useUpdateVirtualMachineMutation();

    const onSubmit = async (data: UpdateContainerApiArg["body"]) => {
        if (!vm || !isDirty) {
            return;
        }
        return updateVm({
            virtualMachineId: vm.id,
            body: data,
        })
            .unwrap()
            .catch(handleSubmitError(form.setError));
    };

    const vmLockCapabilityError = useCapability("virtual-machines-lock");

    return (
        <Panel>
            <RhfFormProvider {...form}>
                <PanelTitle title="Basic Settings" />
                <PanelContent>
                    <AccessControlledSection
                        aclResource={environment}
                        verifyFn={vm ? vmModifyAccessFn(vm) : () => undefined}
                    >
                        <RhfFormField label="Name" name="name" required>
                            {vm ? (
                                <TextInput
                                    className="my-2 w-full"
                                    {...register("name", { ...required() })}
                                />
                            ) : (
                                <SkeletonButton />
                            )}
                        </RhfFormField>

                        <RhfIdentifierInput
                            name={`identifier`}
                            label="Identifier"
                            required
                        />

                        <div className="flex gap-4">
                            <RhfFormField
                                label="Deprecate"
                                name="deprecate"
                                help="Add a deprecated tag to this virtual machine"
                            >
                                <div className="flex items-center gap-4">
                                    {vm ? (
                                        <Checkbox {...register("deprecate")} />
                                    ) : (
                                        <SkeletonButton className="!h-5 !w-5 !rounded-sm" />
                                    )}
                                </div>
                            </RhfFormField>

                            <RhfFormField
                                label="Lock Virtual Machine"
                                name="lock"
                                help="Locking the virtual machine prevents it from being deleted."
                            >
                                <div className="flex items-center gap-4">
                                    {vm ? (
                                        <Tooltip
                                            disabled={!vmLockCapabilityError}
                                            message="Access Denied - Missing 'vms-lock' capability."
                                        >
                                            <Checkbox
                                                disabled={
                                                    !!vmLockCapabilityError
                                                }
                                                {...register("lock")}
                                            />
                                        </Tooltip>
                                    ) : (
                                        <SkeletonButton className="!h-5 !w-5 !rounded-sm" />
                                    )}
                                </div>
                            </RhfFormField>
                        </div>
                    </AccessControlledSection>

                    <PanelFooter>
                        <div>
                            <RhfGlobalFormError />
                        </div>
                        <AccessControlOverlay
                            aclResource={environment}
                            verifyFn={
                                vm ? vmModifyAccessFn(vm) : () => undefined
                            }
                        >
                            <PushAndHoldButton
                                isLoading={isSubmitting}
                                icon={faEdit}
                                onClick={handleSubmit(onSubmit)}
                                type="button"
                                tooltip={"hold to update container"}
                                flavor="primary"
                                disabled={!isDirty}
                            >
                                Update
                            </PushAndHoldButton>
                        </AccessControlOverlay>
                    </PanelFooter>
                </PanelContent>
            </RhfFormProvider>
        </Panel>
    );
}
