import { Button, LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    RhfFormProvider,
    RhfGlobalFormError,
    RHF_GLOBAL_ERROR,
    FormSection,
    FormSectionHeader,
    TextAreaInput,
} from "@cycleplatform/ui/components/forms";
import {
    faArrowLeft,
    faEdit,
    faHardDrive,
    faPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { useForm } from "react-hook-form";
import { PanelDetail } from "@cycleplatform/ui/components/panels";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import {
    CreateVirtualMachineApiArg,
    useCreateVirtualMachineJobMutation,
    useCreateVirtualMachineMutation,
    useGetEnvironmentQuery,
    VirtualMachineImageSourceBase,
} from "~/services/cycle";
import { useContext } from "react";
import { MultiStepDialogContext } from "~/components/dialogs/MultiStepFormDialog/context";
import { useGetMultiStepFormValues } from "~/components/dialogs/MultiStepFormDialog/hooks";
import { useNavigate } from "react-router-dom";
import { isCycleApiError } from "~/services/helpers";
import { useAppDispatch } from "~/hooks";
import { setError } from "~/modules/forms/slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VmCreateFormSteps } from "./types";
import {
    StyledCell,
    StyledDataTable,
    StyledHeaderCell,
    StyledTableHead,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { VolumeStoragePoolSelect } from "~/components/containers";
import { generateDialogLink } from "~/components/dialogs/helpers";
import {
    DialogColumn,
    DialogFooter,
} from "@cycleplatform/ui/components/dialog/components";
import { LearnMore } from "./LearnMore";

type FormType = CreateVirtualMachineApiArg["body"];

export function VmReviewStep() {
    const nav = useNavigate();
    const dispatch = useAppDispatch();

    const { handleBack, onClose, formKey } = useContext(MultiStepDialogContext);
    const values = useGetMultiStepFormValues<VmCreateFormSteps>();

    const form = useForm<FormType>({
        defaultValues: {
            name: values[0].name,
            environment_id: values[0].environment_id,
            ssh_keys: values[0].sshKeys?.map((k) => k.id),
            image: {
                type: values[1].type,
                details: values[1].details,
            } as VirtualMachineImageSourceBase,
            volumes: values[3].volumes,
            base_disk_size: `${values[3].base_disk}G`,
            config: {
                network: values[4],
                deploy: values[5],
                resources: {
                    ram: {
                        allocate: values[2].ram.allocate,
                        limit: `${values[2].ram.limit}G`,
                    },
                    cpu:
                        values[2].cpu.active === "cores"
                            ? { cores: values[2].cpu.cores }
                            : { cpus: values[2].cpu.cpus },
                },
            },
        },
        ...rhfConfig,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = form;

    const [createVm] = useCreateVirtualMachineMutation();

    const { data: environment } = useGetEnvironmentQuery({
        environmentId: values[0].environment_id,
    });

    const onSubmit = async (data: FormType) => {
        return createVm({
            body: data,
        })
            .unwrap()
            .then((vm) => {
                onClose?.();
                nav(
                    generateDialogLink("virtual-machine", {
                        "dialog-id": vm.data.id,
                    })
                );
            })
            .catch((err) => {
                handleSubmitError(form.setError, {
                    sourceOverride: {
                        origin: RHF_GLOBAL_ERROR,
                        volumes: RHF_GLOBAL_ERROR,
                    },
                    global: true,
                })(err);

                if (isCycleApiError(err)) {
                    dispatch(setError({ data: err, key: formKey }));
                }
            });
    };

    return (
        <RhfFormProvider
            {...form}
            onSubmit={handleSubmit(onSubmit)}
            className="h-full"
        >
            <div className="flex h-full">
                <DialogColumn className="w-2/3">
                    <div className="pb-4">
                        Deploying the following virtual machine to environment{" "}
                        <strong>
                            {environment?.data.name || values[0].environment_id}
                        </strong>
                        :
                    </div>
                    <BasicsSection values={values} />
                    <ImageSummary values={values} />
                    <ResourcesSummary values={values} />
                    <StorageSummary values={values} />
                    <NetworkSummary values={values} />
                    <DeploymentSummary values={values} />
                </DialogColumn>
                <LearnMore title="Confirm Changes">
                    Verify the parameters of this virtual machine before
                    creating it.
                </LearnMore>{" "}
            </div>

            <DialogFooter className="justify-between ">
                <Button icon={faArrowLeft} onClick={() => handleBack?.()}>
                    Back
                </Button>
                <div>
                    <RhfGlobalFormError />
                </div>
                <LoaderButton
                    isLoading={isSubmitting}
                    type="submit"
                    flavor="primary"
                    icon={faPlus}
                >
                    Create Virtual Machine
                </LoaderButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}
function BasicsSection({ values }: { values: VmCreateFormSteps }) {
    const stepIdx = 0;
    return (
        <>
            <FormSectionHeader
                header="Basics"
                tooltip={<SectionEditLink step={stepIdx} />}
            />
            <FormSection>
                <PanelDetail label="Name">
                    <div>{values[stepIdx].name}</div>
                </PanelDetail>
                <PanelDetail label="SSH Keys">
                    <div>
                        {values[stepIdx].sshKeys?.length
                            ? values[stepIdx].sshKeys
                                  .map((k) => k.name)
                                  .join(", ")
                            : "None"}
                    </div>
                </PanelDetail>
            </FormSection>
        </>
    );
}
function ImageSummary({ values }: { values: VmCreateFormSteps }) {
    const stepIdx = 1;
    const image = values[stepIdx];

    switch (image.type) {
        case "base":
            return (
                <>
                    <FormSectionHeader
                        header="Image"
                        tooltip={<SectionEditLink step={stepIdx} />}
                    />
                    <FormSection>
                        <PanelDetail label="Type">
                            <div>Cycle Base Image</div>
                        </PanelDetail>
                        <PanelDetail label="OS">
                            <div>{image.details.identifier}</div>
                        </PanelDetail>
                    </FormSection>
                </>
            );
        case "url":
            return (
                <>
                    <FormSectionHeader
                        header="Image"
                        tooltip={<SectionEditLink step={stepIdx} />}
                    />
                    <FormSection>
                        <PanelDetail label="Type">
                            <div>URL</div>
                        </PanelDetail>
                        <PanelDetail label="URL">
                            <div>{image.details.url}</div>
                        </PanelDetail>
                    </FormSection>
                </>
            );
        case "ipxe":
            return (
                <>
                    <FormSectionHeader
                        header="Image"
                        tooltip={<SectionEditLink step={stepIdx} />}
                    />
                    <FormSection>
                        <PanelDetail label="Type">
                            <div>IPXE</div>
                        </PanelDetail>
                        {image.details.chain_url ? (
                            <PanelDetail label="Chain URL">
                                <div>{image.details.chain_url}</div>
                            </PanelDetail>
                        ) : (
                            <PanelDetail label="Script">
                                <TextAreaInput
                                    readOnly
                                    value={image.details.script || ""}
                                />
                            </PanelDetail>
                        )}
                    </FormSection>
                </>
            );
    }
}

function ResourcesSummary({ values }: { values: VmCreateFormSteps }) {
    const stepIdx = 2;
    const resources = values[stepIdx];

    return (
        <>
            <FormSectionHeader
                header="Resources"
                tooltip={<SectionEditLink step={stepIdx} />}
            />
            <FormSection>
                {resources.cpu.active === "cores" ? (
                    <>
                        <PanelDetail label="Number of Cores">
                            {resources.cpu?.cores?.number}
                        </PanelDetail>

                        {resources.cpu.cores?.allocate ? (
                            <PanelDetail label="CPU Allocate">true</PanelDetail>
                        ) : null}
                    </>
                ) : (
                    <>
                        <PanelDetail label="Cpuset">
                            {resources.cpu.cpus}
                        </PanelDetail>
                    </>
                )}
                <PanelDetail label="RAM limit">
                    {resources.ram.limit}GB
                </PanelDetail>
                {resources.ram.allocate ? (
                    <PanelDetail label="RAM Allocate">true</PanelDetail>
                ) : null}
            </FormSection>
        </>
    );
}

function StorageSummary({ values }: { values: VmCreateFormSteps }) {
    const stepIdx = 3;
    const storage = values[stepIdx];

    return (
        <>
            <FormSectionHeader
                header="Storage"
                tooltip={<SectionEditLink step={stepIdx} />}
            />
            <FormSection>
                <PanelDetail label="base disk">
                    {storage.base_disk}GB
                </PanelDetail>
            </FormSection>
            {storage.volumes.length ? (
                <>
                    <FormSectionHeader header="Volumes" />
                    <FormSection>
                        <StyledDataTable>
                            <StyledTableHead>
                                <StyledHeaderCell>Identifier</StyledHeaderCell>
                                <StyledHeaderCell>
                                    Pool Options
                                </StyledHeaderCell>
                                <StyledHeaderCell>Size</StyledHeaderCell>
                            </StyledTableHead>
                            <tbody>
                                {storage.volumes.map((v, idx) => (
                                    <StyledTableRow key={idx}>
                                        <StyledCell>
                                            <div className="flex items-center gap-2">
                                                <FontAwesomeIcon
                                                    icon={faHardDrive}
                                                    className="text-cycle-blue pt-1"
                                                />
                                                {v.identifier}
                                            </div>
                                        </StyledCell>
                                        <StyledCell>
                                            <div className="pr-8">
                                                <VolumeStoragePoolSelect
                                                    disabled
                                                    name=""
                                                    onChange={() => null}
                                                    value={
                                                        v.local?.storage_pool ||
                                                        undefined
                                                    }
                                                />
                                            </div>
                                        </StyledCell>
                                        <StyledCell>
                                            <div className="mr-8">
                                                {v.local?.max_size}
                                            </div>
                                        </StyledCell>
                                    </StyledTableRow>
                                ))}
                            </tbody>
                        </StyledDataTable>
                    </FormSection>
                </>
            ) : null}
        </>
    );
}

function NetworkSummary({ values }: { values: VmCreateFormSteps }) {
    const stepIdx = 4;
    const network = values[stepIdx];
    return (
        <>
            <FormSectionHeader
                header="Network"
                tooltip={<SectionEditLink step={stepIdx} />}
            />
            <FormSection>
                <div className="mt-4">
                    <PanelDetail label="Hostname">
                        <div>{network.hostname}</div>
                    </PanelDetail>
                    <PanelDetail label="Public Network" className="capitalize">
                        <div>{network.public}</div>
                    </PanelDetail>
                    {network.ports && network.ports.length > 0 ? (
                        <PanelDetail label="Ports">
                            <div>{network.ports.join(", ")}</div>
                        </PanelDetail>
                    ) : null}
                </div>
            </FormSection>
        </>
    );
}

function DeploymentSummary({ values }: { values: VmCreateFormSteps }) {
    const stepIdx = 5;
    const deployment = values[stepIdx]?.constraints?.node?.tags;

    if (!deployment?.any && !deployment?.all) {
        return null;
    }

    return (
        <>
            <FormSectionHeader
                header="Deployment"
                tooltip={<SectionEditLink step={stepIdx} />}
            />
            <FormSection>
                {deployment.all ? (
                    <PanelDetail label="All">
                        {deployment.all.join(", ")}
                    </PanelDetail>
                ) : null}
                {deployment.any ? (
                    <PanelDetail label="Any">
                        {deployment.any.join(", ")}
                    </PanelDetail>
                ) : null}
            </FormSection>
        </>
    );
}

function SectionEditLink({ step }: { step: number }) {
    const { navigateToStep } = useContext(MultiStepDialogContext);

    return (
        <FontAwesomeIcon
            icon={faEdit}
            className="text-cycle-blue hover:text-cycle-blue-accent hover:cursor-pointer"
            onClick={() => navigateToStep(step)}
        />
    );
}
