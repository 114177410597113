import { useNavigate } from "react-router-dom";
import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import {
    clearDialogParams,
    getAllDialogSearchParams,
    isDialogOpen,
    shouldClose,
} from "~/components/dialogs/helpers";
import { CreateBillingMethodForm } from "~/components/settings/forms/billingMethod/CreateBillingMethodForm";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";
import { useGetBillingStatusQuery } from "~/services/cycle";
import { $error } from "@cycleplatform/core/util/log";
import { CycleErrorBoundary } from "~/components/common/errors";
import { InvalidState } from "@cycleplatform/ui/components/resources/panels";

export function BillingMethodCreateDialog() {
    const nav = useNavigate();
    const params = getAllDialogSearchParams<"billing-method-create">();
    const { data: billingStatus, error: statusError } =
        useGetBillingStatusQuery();

    if (statusError) {
        $error("unable to check billing status", statusError);
    }

    return (
        <StyledFormDialog
            title="New Payment Method"
            isOpen={isDialogOpen("billing-method-create")}
            onClose={() => nav(clearDialogParams())}
        >
            <DialogColumn className="w-full">
                {!!billingStatus && !billingStatus.data.enabled ? (
                    <div className="w-full p-4">
                        <InvalidState detail="Billing is disabled for this Cycle core." />
                    </div>
                ) : (
                    <CreateBillingMethodForm
                        onSuccess={(m) =>
                            nav(
                                shouldClose(params["dialog-nav"]) || {
                                    pathname: `billing/methods/${m?.data?.id}`,
                                    search: "",
                                }
                            )
                        }
                    />
                )}
            </DialogColumn>
        </StyledFormDialog>
    );
}
