import { DialogPageBody } from "@cycleplatform/ui/components/dialog";
import { PageAside, PageContent } from "@cycleplatform/ui/components/page";
import { useGetContainerQuery } from "~/services/cycle";
import { ServiceContainerPanel } from "../../ServiceContainerPanel";
import { GatewayConfigPanel } from "./GatewayConfigPanel";
import { useContext } from "react";
import { ContainerDialogContext } from "~/components/dialogs/containers/container/context";
import { AccessControlledSection } from "~/components/layout/AccessControlledSection";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { NavIcons } from "~/components/layout/NavIcons";

export function DashboardTab() {
    const { environment } = useContext(ContainerDialogContext);
    const { data: gatewayContainer, error } = useGetContainerQuery(
        {
            containerId: environment?.services?.gateway?.container_id || "",
            meta: ["ips", "instances_count"],
        },
        {
            skip: !environment?.services?.gateway?.container_id,
        }
    );

    if (error) {
        throw error;
    }

    return (
        <DialogPageBody>
            <PageContent>
                <EmptyResource
                    className="flex h-60 items-center justify-center border-none"
                    icon={NavIcons["environmentGateway"]}
                    title={`Gateway Telemetry Coming Soon`}
                />
            </PageContent>
            <PageAside>
                <AccessControlledSection
                    aclResource={environment}
                    verifyFn={modifyAccessFn("environments-services-manage")}
                >
                    <GatewayConfigPanel
                        info={environment?.services?.gateway}
                        environment={environment}
                    />
                </AccessControlledSection>
                <AccessControlledSection
                    aclResource={environment}
                    verifyFn={modifyAccessFn("environments-services-manage")}
                >
                    <ServiceContainerPanel
                        container={gatewayContainer?.data}
                        environment={environment}
                    />
                </AccessControlledSection>
            </PageAside>
        </DialogPageBody>
    );
}
