export const ChartColorBank = {
    red: {
        "1": "#ff9f9f",
        "2": "#fb9a9a",
        "3": "#f79494",
        "4": "#f38f8f",
        "5": "#ef8989",
        "6": "#eb8484",
        "7": "#e77e7e",
        "8": "#e37979",
        "9": "#e07373",
        "10": "#dc6e6e",
        "11": "#d86868",
        "12": "#d46363",
        "13": "#d05d5d",
        "14": "#cc5858",
        "15": "#c85252",
        "16": "#c44d4d",
        "17": "#c04747",
        "18": "#bc4242",
        "19": "#b83c3c",
        "20": "#b43737",
        "21": "#b03131",
        "22": "#ac2c2c",
        "23": "#a92626",
        "24": "#a52121",
        "25": "#a11b1b",
        "26": "#9d1616",
        "27": "#991010",
        "28": "#950b0b",
        "29": "#910505",
        "30": "#8d0000",
    },
    orange: {
        "1": "#ff8c32",
        "2": "#fb8830",
        "3": "#f6842f",
        "4": "#f2812d",
        "5": "#ee7d2b",
        "6": "#e97929",
        "7": "#e57528",
        "8": "#e17126",
        "9": "#dd6e24",
        "10": "#d86a22",
        "11": "#d46621",
        "12": "#d0621f",
        "13": "#cb5e1d",
        "14": "#c75b1c",
        "15": "#c3571a",
        "16": "#be5318",
        "17": "#ba4f16",
        "18": "#b64c15",
        "19": "#b14813",
        "20": "#ad4411",
        "21": "#a94010",
        "22": "#a43c0e",
        "23": "#a0390c",
        "24": "#9c350a",
        "25": "#983109",
        "26": "#932d07",
        "27": "#8f2905",
        "28": "#8b2603",
        "29": "#862202",
        "30": "#821e00",
    },
    yellow: {
        "1": "#ffe664",
        "2": "#fce261",
        "3": "#f8dd5d",
        "4": "#f5d95a",
        "5": "#f2d456",
        "6": "#efd053",
        "7": "#ebcb4f",
        "8": "#e8c74c",
        "9": "#e5c248",
        "10": "#e2be45",
        "11": "#deb942",
        "12": "#dbb53e",
        "13": "#d8b03b",
        "14": "#d4ac37",
        "15": "#d1a734",
        "16": "#cea330",
        "17": "#cb9e2d",
        "18": "#c79a29",
        "19": "#c49526",
        "20": "#c19122",
        "21": "#bd8c1f",
        "22": "#ba881c",
        "23": "#b78318",
        "24": "#b47f15",
        "25": "#b07a11",
        "26": "#ad760e",
        "27": "#aa710a",
        "28": "#a76d07",
        "29": "#a36803",
        "30": "#a06400",
    },
    green: {
        "1": "#e0ff9f",
        "2": "#d9fb9a",
        "3": "#d2f794",
        "4": "#ccf38f",
        "5": "#c5ef89",
        "6": "#beeb84",
        "7": "#b7e77e",
        "8": "#b1e379",
        "9": "#aae073",
        "10": "#a3dc6e",
        "11": "#9cd868",
        "12": "#96d463",
        "13": "#8fd05d",
        "14": "#88cc58",
        "15": "#81c852",
        "16": "#7bc44d",
        "17": "#74c047",
        "18": "#6dbc42",
        "19": "#66b83c",
        "20": "#60b437",
        "21": "#59b031",
        "22": "#52ac2c",
        "23": "#4ba926",
        "24": "#45a521",
        "25": "#3ea11b",
        "26": "#379d16",
        "27": "#309910",
        "28": "#2a950b",
        "29": "#239105",
        "30": "#1c8d00",
    },
    blue: {
        "1": "#c8f0ff",
        "2": "#c2ebfd",
        "3": "#bce6fb",
        "4": "#b6e0f9",
        "5": "#b1dbf7",
        "6": "#abd6f6",
        "7": "#a5d1f4",
        "8": "#9fccf2",
        "9": "#99c7f0",
        "10": "#93c1ee",
        "11": "#8dbcec",
        "12": "#88b7ea",
        "13": "#82b2e8",
        "14": "#7cade6",
        "15": "#76a8e4",
        "16": "#70a2e3",
        "17": "#6a9de1",
        "18": "#6498df",
        "19": "#5e93dd",
        "20": "#598edb",
        "21": "#5389d9",
        "22": "#4d83d7",
        "23": "#477ed5",
        "24": "#4179d3",
        "25": "#3b74d1",
        "26": "#356fd0",
        "27": "#306ace",
        "28": "#2a64cc",
        "29": "#245fca",
        "30": "#1e5ac8",
    },
    purple: {
        "1": "#e1c8ff",
        "2": "#dbc1f9",
        "3": "#d6baf4",
        "4": "#d0b4ee",
        "5": "#caade8",
        "6": "#c5a6e3",
        "7": "#bf9fdd",
        "8": "#b998d7",
        "9": "#b491d1",
        "10": "#ae8bcc",
        "11": "#a884c6",
        "12": "#a37dc0",
        "13": "#9d76bb",
        "14": "#976fb5",
        "15": "#9268af",
        "16": "#8c62aa",
        "17": "#875ba4",
        "18": "#81549e",
        "19": "#7b4d99",
        "20": "#764693",
        "21": "#703f8d",
        "22": "#6a3988",
        "23": "#653282",
        "24": "#5f2b7c",
        "25": "#592476",
        "26": "#541d71",
        "27": "#4e166b",
        "28": "#481065",
        "29": "#430960",
        "30": "#3d025a",
    },
    gray: {
        "1": "#f5f5f5",
        "2": "#ededed",
        "3": "#e6e6e6",
        "4": "#dedede",
        "5": "#d7d7d7",
        "6": "#cfcfcf",
        "7": "#c8c8c8",
        "8": "#c0c0c0",
        "9": "#b9b9b9",
        "10": "#b1b1b1",
        "11": "#a9a9a9",
        "12": "#a2a2a2",
        "13": "#9a9a9a",
        "14": "#939393",
        "15": "#8b8b8b",
        "16": "#848484",
        "17": "#7c7c7c",
        "18": "#757575",
        "19": "#6d6d6d",
        "20": "#666666",
        "21": "#5e5e5e",
        "22": "#565656",
        "23": "#4f4f4f",
        "24": "#474747",
        "25": "#404040",
        "26": "#383838",
        "27": "#313131",
        "28": "#292929",
        "29": "#222222",
        "30": "#1a1a1a",
    },
};

export const ApiResponseColorMap: Record<string, string> = {
    "100": ChartColorBank.gray[20],
    "101": ChartColorBank.gray[15],
    "102": ChartColorBank.gray[10],

    "200": "rgb(34, 211, 104)",

    "201": ChartColorBank.green[5],
    "202": ChartColorBank.green[7],
    "203": ChartColorBank.green[9],
    "204": ChartColorBank.green[11],
    "205": ChartColorBank.green[13],
    "206": ChartColorBank.green[15],
    "207": ChartColorBank.green[17],
    "208": ChartColorBank.green[19],
    "226": ChartColorBank.green[21],

    "300": "rgb(42, 167, 255)",
    "301": ChartColorBank.blue[13],
    "302": ChartColorBank.blue[16],
    "303": ChartColorBank.blue[19],
    "304": ChartColorBank.blue[22],
    "305": ChartColorBank.blue[25],
    "306": ChartColorBank.blue[27],
    "307": ChartColorBank.blue[29],
    "308": ChartColorBank.blue[30],

    "400": "rgb(255, 198, 41)",
    "401": ChartColorBank.orange[2],
    "402": ChartColorBank.orange[3],
    "403": ChartColorBank.orange[4],
    "404": ChartColorBank.orange[5],
    "405": ChartColorBank.orange[6],
    "406": ChartColorBank.orange[7],
    "407": ChartColorBank.orange[8],
    "408": ChartColorBank.orange[9],
    "409": ChartColorBank.orange[10],
    "410": ChartColorBank.orange[11],
    "411": ChartColorBank.orange[12],
    "412": ChartColorBank.orange[13],
    "413": ChartColorBank.orange[14],
    "414": ChartColorBank.orange[15],
    "415": ChartColorBank.orange[16],
    "416": ChartColorBank.orange[17],
    "417": ChartColorBank.orange[18],
    "418": ChartColorBank.orange[19],
    "421": ChartColorBank.orange[20],
    "422": ChartColorBank.orange[21],
    "423": ChartColorBank.orange[22],
    "424": ChartColorBank.orange[23],
    "426": ChartColorBank.orange[24],
    "428": ChartColorBank.orange[25],
    "429": ChartColorBank.orange[26],
    "431": ChartColorBank.orange[27],
    "451": ChartColorBank.orange[28],
    "499": ChartColorBank.orange[29],

    "500": "rgb(211, 34, 53)",
    "501": ChartColorBank.red[12],
    "502": ChartColorBank.red[14],
    "503": ChartColorBank.red[16],
    "504": ChartColorBank.red[18],
    "505": ChartColorBank.red[20],
    "506": ChartColorBank.red[22],
    "507": ChartColorBank.red[24],
    "508": ChartColorBank.red[26],
    "510": ChartColorBank.red[28],
    "511": ChartColorBank.red[30],

    empty: "#BABA",
};

export const DisconnectReasonsColorMap: Record<string, string> = {
    no_error: "#22d368",
    request_invalid: "#E57373",
    connection_invalid: "2aa7ff",
    timeout_idle: "#FF5252",
    timeout_init: "#FF8800",
    router_none: "#EC407A",
    router_nomatch: "#880E4F",
    destination_unavailable: "#F50057",
    unknown_error: "#d774ff",
    empty: "#BABA",
};
