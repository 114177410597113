import { Button } from "@cycleplatform/ui/components/buttons";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { useContext } from "react";
import { MultiStepDialogContext } from "~/components/dialogs/MultiStepFormDialog/context";

export function HandleBackButton() {
    const { handleBack } = useContext(MultiStepDialogContext);
    return (
        <Button onClick={() => handleBack()} icon={faArrowLeft}>
            Back
        </Button>
    );
}
