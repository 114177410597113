import { useCallback, useEffect, useState } from "react";
import { BasicSelect, Option } from "../select";
import { TextInput } from "../TextInput";
import classNames from "classnames";

type RhfDataSizeInput = {
    name: string;
    value?: string;
    disabled?: boolean;
    onBlur?: () => void;
    onChange: (value: string | undefined) => void;
    filterUnitOptions?: (o: Option[]) => Option[];
    suffix?: React.ReactNode;
    suffixClassName?: string;
};

function splitDataString(input: string | undefined): {
    size: string;
    unit: string;
} {
    if (!input) {
        return { size: "", unit: "G" };
    }
    const match = input.match(/^(\d*)([a-zA-Z]*)$/);
    if (match) {
        const size = match[1] || "";
        const unit = match[2] || "";
        return { size, unit };
    }
    return { size: "", unit: "" };
}

export function RhfDataSizeInput({
    onChange,
    value,
    disabled,
    suffix,
    suffixClassName,
    filterUnitOptions = (opt) => opt,
    ...props
}: RhfDataSizeInput) {
    const [unit, setUnit] = useState(splitDataString(value).unit);

    useEffect(() => {
        setUnit(splitDataString(value).unit);
    }, [value]);

    const getUnitOptions = useCallback(() => {
        if (["M", "G", "T"].includes(unit)) {
            return filterUnitOptions([
                { value: "M", label: "MB" },
                { value: "G", label: "GB" },
                { value: "T", label: "TB" },
            ]);
        } else if (["Mb", "Gb", "Tb"].includes(unit)) {
            return filterUnitOptions([
                { value: "Mb", label: "MB" },
                { value: "Gb", label: "GB" },
                { value: "Tb", label: "TB" },
            ]);
        } else {
            return filterUnitOptions([
                { value: "MB", label: "MB" },
                { value: "GB", label: "GB" },
                { value: "TB", label: "TB" },
            ]);
        }
    }, [filterUnitOptions, unit]);

    return (
        <div className="group ">
            <div className="group-focus-within:ring-cycle-blue flex rounded-md group-focus-within:border-transparent group-focus-within:ring-2">
                <TextInput
                    {...props}
                    className="rounded-r-none border-r-0"
                    type="number"
                    onChange={(v) => onChange(v.target.value.toString() + unit)}
                    disabled={disabled}
                    value={splitDataString(value).size}
                />
                <BasicSelect
                    className={classNames(
                        "group-focus-within:ring-cycle-blue rounded-l-none group-focus-within:border-transparent group-focus-within:ring-2 ",
                        suffix && "rounded-r-none"
                    )}
                    name="unit"
                    value={unit}
                    onChange={(s) => {
                        setUnit(s || "G");
                        if (value) {
                            onChange(splitDataString(value).size + s);
                        }
                    }}
                    disabled={disabled}
                    options={getUnitOptions()}
                />

                {suffix && (
                    <div
                        className={classNames(
                            suffixClassName,
                            "min-h-[40px] text-sm",
                            "bg-cycle-gray-light dark:bg-cycle-gray dark:text-cycle-gray-light group-focus-within:ring-cycle-blue text-cycle-gray-accent flex h-[42px] min-w-[8rem]  grow  items-center  justify-center rounded-r-md px-2 group-focus-within:ring-2"
                        )}
                    >
                        {suffix}
                    </div>
                )}
            </div>
        </div>
    );
}
