import {
    RhfFormField,
    TextInput,
    required,
    FormSection,
    FormSectionHeader,
    hasNoSpaces,
    isTaggedImage,
    isUrl,
    isNotHttpHttps,
} from "@cycleplatform/ui/components/forms";
import { useFormContext } from "react-hook-form";
import { ImageSourceCreateEditSubmitType } from "../../util";
import { OciAuthSection } from "./OciAuthSection";

type RefType = "hash" | "tag" | "none";

export function OciRegistryForm() {
    const { register } = useFormContext<ImageSourceCreateEditSubmitType>();

    return (
        <>
            <FormSectionHeader header="Target" />
            <FormSection>
                <div className="flex gap-4">
                    <RhfFormField
                        label="image name"
                        name="origin.details.target"
                        required
                    >
                        <TextInput
                            {...register("origin.details.target", {
                                ...required("Image name cannot be empty"),
                                validate: {
                                    ...isTaggedImage(),
                                },
                            })}
                            placeholder="image:tag"
                        />
                    </RhfFormField>
                    <RhfFormField
                        label="registry url"
                        name="origin.details.url"
                        required
                    >
                        <TextInput
                            {...register("origin.details.url", {
                                ...required(),
                                validate: {
                                    ...isUrl(),
                                    ...isNotHttpHttps(
                                        "Registry URL must not include http:// or https://"
                                    ),
                                    ...hasNoSpaces(),
                                },
                            })}
                            placeholder="registry.com"
                        />
                    </RhfFormField>
                </div>
                <OciAuthSection />
            </FormSection>
        </>
    );
}
