import { PipelineRunStep, useGetStackBuildQuery } from "~/services/cycle";
import { StackBuildLog } from "~/components/stacks/StackBuildLog";
import { Link } from "react-router-dom";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import { formatDateString } from "@cycleplatform/core/util";
import classNames from "classnames";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";

export function StackBuildCreateDetails({ step }: { step: PipelineRunStep }) {
    const buildId = step?.components?.find((c) => c.type === "stack.build")?.id;
    const stackId = step?.components?.find((c) => c.type === "stack")?.id;

    const { data: stackBuild } = useGetStackBuildQuery(
        {
            stackId: stackId || "",
            buildId: buildId || "",
        },
        { skip: !stackId || !buildId }
    );

    if (!stackBuild?.data) {
        return (
            <div
                className={classNames("h-[25rem] w-[55rem]", skeletonStyles)}
            />
        );
    }

    return (
        <StackBuildLog
            buildId={buildId}
            stackId={stackId}
            codeDimensionsClassName="max-h-[25rem] max-w-[55rem]"
            title={
                <div className="flex items-center gap-2 !normal-case">
                    <Link
                        to={generateDialogLink("stack-build", {
                            "dialog-stack-id": stackId || "",
                            "dialog-build-id": buildId || "",
                        })}
                    >
                        {stackBuild ? (
                            <>
                                {formatDateString(
                                    stackBuild?.data?.events.created
                                )}
                            </>
                        ) : null}
                    </Link>
                    <ResourceStateBadge state={stackBuild?.data?.state} />
                </div>
            }
        />
    );
}
