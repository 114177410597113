import { useContext, useMemo } from "react";
import { FormattedOption, MultiSelectInput } from "../select";
import { FilterContext, FilterGroupContext } from "./FilterContext";

type FilterMultiSelectProps = {
    options: string[];
};

export function FilterMultiSelect({ options }: FilterMultiSelectProps) {
    const { filters, setFilter, deleteFilter } = useContext(FilterContext);
    const { group, groupName } = useContext(FilterGroupContext);
    const formattedOptions = useMemo(
        () => options.map((o) => ({ name: o, value: o })),
        [options]
    );

    return (
        <div className="w-full normal-case">
            <MultiSelectInput
                value={filters[group || ""]?.value || null}
                options={formattedOptions}
                filterFields={["name"]}
                formatDisplayValue={(v) => `${v?.name || ""}`}
                formatOption={(v) => <FormattedOption label={v?.name} />}
                onChange={(v) => {
                    if (!v || v.length === 0) {
                        deleteFilter(group || "");
                        return;
                    }

                    setFilter(group || "", {
                        name: groupName,
                        value: v,
                    });
                }}
            />
        </div>
    );
}
