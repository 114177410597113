import { Link, useNavigate, useParams } from "react-router-dom";
import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import asideImg from "@cycleplatform/ui/images/icons/gridless/private-cloud.svg";
import {
    clearDialogParams,
    getAllDialogSearchParams,
    isDialogOpen,
} from "~/components/dialogs/helpers";
import {
    FormField,
    required,
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    TextAreaInput,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import {
    DialogColumn,
    DialogFooter,
} from "@cycleplatform/ui/components/dialog/components";
import { Controller, useForm } from "react-hook-form";
import {
    CreateVirtualMachineSshKeyApiArg,
    useCreateVirtualMachineSshKeyMutation,
} from "~/services/cycle";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { EnvironmentSelect } from "~/components/environments/EnvironmentSelect";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { LoaderButton } from "@cycleplatform/ui/components/buttons";
import { useAppDispatch } from "~/hooks";
import { pushNotification } from "~/modules/notifications/slice";
import { PublicKeyInput } from "~/components/vms/forms/PublicKeyInput";

type RouteParams = {
    environmentId: string;
};

export function SshKeyCreateDialog() {
    const nav = useNavigate();
    const params = useParams<RouteParams>();
    const dialogParams =
        getAllDialogSearchParams<"virtual-machines-ssh-key-create">();

    const envId = params.environmentId || "";
    const [createSshKey] = useCreateVirtualMachineSshKeyMutation();

    const form = useForm<CreateVirtualMachineSshKeyApiArg["body"]>({
        defaultValues: {
            environment_id: envId,
        },
        ...rhfConfig,
    });

    const dispatch = useAppDispatch();

    const {
        handleSubmit,
        register,
        setError,
        formState: { isSubmitting, isDirty },
        control,
    } = form;

    const onSubmit = async (data: CreateVirtualMachineSshKeyApiArg["body"]) => {
        return createSshKey({
            body: data,
        })
            .unwrap()
            .then(() => {
                const p = dialogParams["dialog-nav"];
                if (p && p != "x") {
                    nav(p);
                    return;
                }
                nav(clearDialogParams());
                dispatch(
                    pushNotification({
                        title: `Created SSH Key '${data.name}'`,
                        type: "success",
                        icon: "sshKey",
                    })
                );
            }, handleSubmitError(setError));
    };

    return (
        <RhfFormProvider
            {...form}
            className="h-full"
            onSubmit={form.handleSubmit(onSubmit)}
        >
            <StyledFormDialog
                title="New Virtual Machine SSH Key"
                isOpen={isDialogOpen("virtual-machines-ssh-key-create")}
                onClose={() => nav(clearDialogParams())}
            >
                <DialogColumn className="w-2/3">
                    <div className="flex">
                        <RhfFormField label="Name" name="name" required>
                            <TextInput
                                {...register("name", {
                                    ...required(),
                                    setValueAs(v) {
                                        return v.trim();
                                    },
                                })}
                            />
                        </RhfFormField>
                        {!envId && (
                            <RhfFormField
                                label="environment"
                                name="environment_id"
                            >
                                <Controller
                                    name={`environment_id`}
                                    control={control}
                                    rules={{ ...required() }}
                                    render={({
                                        field: { ref: _ref, ...field },
                                    }) => (
                                        <EnvironmentSelect
                                            {...field}
                                            onChange={(id) => {
                                                if (!id) {
                                                    return;
                                                }
                                                field.onChange(id);
                                            }}
                                        />
                                    )}
                                />
                            </RhfFormField>
                        )}
                    </div>
                    <RhfFormField label="Public Key" name="public_key" required>
                        <PublicKeyInput
                            {...register("public_key", {
                                ...required(),
                                setValueAs(v) {
                                    return v.trim();
                                },
                            })}
                        />
                    </RhfFormField>
                </DialogColumn>
                <DialogColumn className="w-1/3">
                    <Aside />
                </DialogColumn>
                <DialogFooter>
                    <div>
                        <RhfGlobalFormError />
                    </div>
                    <LoaderButton
                        isLoading={isSubmitting}
                        onClick={handleSubmit(onSubmit)}
                        type="submit"
                        flavor="primary"
                        icon={faPlus}
                        disabled={!isDirty}
                    >
                        Add SSH Key
                    </LoaderButton>
                </DialogFooter>
            </StyledFormDialog>
        </RhfFormProvider>
    );
}

function Aside() {
    return (
        <>
            <img className="max-w-xs " src={asideImg} />
            <div className="mt-4 text-lg">
                <h3>Virtual Machine SSH Keys</h3>
                <div className="mb-4 text-sm">
                    Virtual machine SSH keys can be created and installed into
                    virtual machines to gain access via SSH. These keys are
                    compatible with official Cycle base images, or any image
                    that utilizes cloud-init.
                </div>
                <FormField label="learn more:">
                    <ul>
                        <li>
                            <Link
                                className="text-sm"
                                to="https://cycle.io/docs/platform/virtual-machine-ssh-keys"
                            >
                                Virtual Machine SSH Keys
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="text-sm"
                                to="https://cycle.io/docs/portal/creating-a-virtual-machine-ssh-key"
                            >
                                Creating an SSH Key
                            </Link>
                        </li>
                    </ul>
                </FormField>
            </div>
        </>
    );
}
