import { useEffect, useState } from "react";
import { useGetImageBuildLogQuery } from "~/services/cycle";
import { Panel, PanelTitle } from "@cycleplatform/ui/components/panels";
import { isCycleApiError } from "~/services/helpers";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import classNames from "classnames";

type ImageBuildLogProps = {
    imageId?: string;
    title?: React.ReactNode;
    codeDimensionsClassName?: string;
};

export function ImageBuildLog({
    imageId,
    title,
    codeDimensionsClassName,
}: ImageBuildLogProps) {
    const {
        currentData: log,
        error: logError,
        isLoading,
    } = useGetImageBuildLogQuery(
        {
            imageId: imageId || "",
        },
        {
            skip: !imageId,
        }
    );

    const notFoundError =
        logError &&
        isCycleApiError(logError) &&
        logError.data.error.code !== "404.image.build-log";

    if (logError && !notFoundError) {
        throw logError;
    }

    const value = isLoading || !imageId ? "" : log?.data?.log || "No build log";
    return (
        <div className="h-full w-full pb-8">
            <PanelTitle title={title || "Build Log"} />
            <div className="py-2">
                <pre
                    className={classNames(
                        "language-bash  !m-0 rounded-lg p-4 !text-sm",
                        codeDimensionsClassName || "h-full max-w-[80rem]"
                    )}
                >
                    <code>{value}</code>
                </pre>
            </div>
        </div>
    );
}
