import classNames from "classnames";
import { NetworkAnalysis } from "@cycleplatform/core/modules/networks/analysis";
import { skeletonStyles } from "../../loaders/skeleton/skeletonStyle";
import { components } from "@cycleplatform/core/modules/api/__generated";
import { useMemo } from "react";
import { Line } from "./Line";
import { adaptNetworkAnalysisToDiagram } from "./util";
import { PublicInternetComponent } from "./icons/PublicInternetComponent";
import { LoadBalancerComponent } from "./icons/LoadBalancerComponent";
import { ContainerComponent } from "./icons/ContainerComponent";

export function ContainerPublicNetworkChart({
    container,
    analysis,
    formatTooltip = (m) => m,
}: {
    container: components["schemas"]["Container"];
    analysis: NetworkAnalysis | null;
    formatTooltip?: (message: string) => string;
}) {
    const status = useMemo(
        () =>
            analysis
                ? adaptNetworkAnalysisToDiagram(analysis, "container")
                : null,
        [analysis]
    );

    if (!status || !analysis) {
        return <div className={classNames(skeletonStyles, "h-12")} />;
    }
    return (
        <div className="mx-2 mt-2 flex items-center justify-between">
            {/* Internet */}
            <PublicInternetComponent status={status} />

            {status.lb.hidden === false && (
                <>
                    {/* lb traffic */}
                    <Line
                        left={true}
                        right={true}
                        status={status.lbIngress.status}
                        message={formatTooltip(status.lbIngress.message)}
                    />
                    {/* lb */}
                    <LoadBalancerComponent
                        status={status}
                        formatTooltip={formatTooltip}
                    />
                </>
            )}

            {/* container traffic */}
            {container.image.service !== "loadbalancer" && (
                <>
                    <Line
                        left={analysis.mode !== "disabled"}
                        right={analysis.mode === "public"}
                        status={status.lbEgress.status}
                        message={formatTooltip(status.lbEgress.message)}
                    />
                    {/* container */}
                    <ContainerComponent
                        status={status}
                        formatTooltip={formatTooltip}
                    />
                </>
            )}
        </div>
    );
}
