import { RhfFormField } from "@cycleplatform/ui/components/forms";
import { InfoPanel } from "@cycleplatform/ui/components/panels";
import { Controller, useFormContext } from "react-hook-form";
import { SkeletonButton } from "@cycleplatform/ui/components/loaders/skeleton";
import { TagSelectInput } from "~/components/common/forms/TagSelectInput";
import { VmConfigFormData } from "../../types";
import { useContext } from "react";
import { VirtualMachineDialogContex } from "../../../../context";

export function DeployContraints() {
    const { vm } = useContext(VirtualMachineDialogContex);
    const { control } = useFormContext<VmConfigFormData>();

    return (
        <>
            <InfoPanel type="info">
                <p>
                    Constraints provide the ability to set restrictions on which
                    nodes instances of this virtual machine are able to be
                    deployed to (i.e. be sure to apply to a server that supports
                    hypervisor).
                </p>
            </InfoPanel>

            <div className="flex gap-4">
                <div className="w-1/2">
                    <RhfFormField
                        label="Node Tags (Match One)"
                        name="config.deploy.constraints.node.tags.any"
                    >
                        {vm ? (
                            <Controller
                                name="config.deploy.constraints.node.tags.any"
                                control={control}
                                render={({
                                    field: { ref: _ref, ...field },
                                }) => (
                                    <TagSelectInput
                                        {...field}
                                        value={field.value || []}
                                        additionalTags={
                                            vm?.config?.deploy?.constraints
                                                ?.node?.tags.any || []
                                        }
                                        cluster={vm?.environment?.cluster}
                                    />
                                )}
                            />
                        ) : (
                            <SkeletonButton />
                        )}
                    </RhfFormField>
                </div>
                <div className="w-1/2">
                    <RhfFormField
                        label="Node Tags (Match All)"
                        name="config.deploy.constraints.node.tags.all"
                    >
                        {vm ? (
                            <Controller
                                name="config.deploy.constraints.node.tags.all"
                                control={control}
                                render={({
                                    field: { ref: _ref, ...field },
                                }) => (
                                    <TagSelectInput
                                        {...field}
                                        additionalTags={
                                            vm?.config?.deploy?.constraints
                                                ?.node?.tags.all || []
                                        }
                                        value={field.value || []}
                                        cluster={vm?.environment?.cluster}
                                    />
                                )}
                            />
                        ) : (
                            <SkeletonButton />
                        )}
                    </RhfFormField>
                </div>
            </div>
        </>
    );
}
