import { components } from "@cycleplatform/core/modules/api/__generated";
import { getSourceDetails } from "@cycleplatform/core/modules/images";
import { FormattedOption } from "@cycleplatform/ui/components/forms/select";
import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import { ResourceComboBox } from "~/components/common/forms";
import { ImageSource, useGetImageSourcesQuery } from "~/services/cycle";

type ImageSourceSelectProps<MULTI extends boolean = false> = {
    value: MULTI extends true ? Array<string> : string | undefined;
    multiple?: MULTI;
    onChange: MULTI extends true
        ? (ids: string[], sources: ImageSource[]) => void
        : (
              id: string | undefined | null,
              source: ImageSource | undefined
          ) => void;
};

export function ImageSourceSelect<MULTI extends boolean = false>({
    value,
    onChange,
    multiple,
}: ImageSourceSelectProps<MULTI>) {
    // TODO - there is a risk that the image source set as 'value' is not on the list
    // returned by the query. May need an additional query for the specific image source
    // if not initially returned and then merge them.
    const {
        data: sources,
        error,
        isLoading,
    } = useGetImageSourcesQuery({
        sort: ["-id"],
        // meta: ["images_count"],
        page: {
            size: 100,
            number: 1,
        },
    });
    if (error) {
        throw error;
    }

    return (
        <ResourceComboBox
            placeholder="No Source Selected"
            filterFields={["id", "name"]}
            isLoading={isLoading}
            value={value}
            onChange={onChange}
            multiple={multiple}
            resources={sources?.data || []}
            formatDisplayValue={(s) => s?.name || ""}
            formatOption={(s) => (
                <FormattedOption
                    label={s.name}
                    detail={getSourceDetails(
                        s.origin as components["schemas"]["ImageOrigin"]
                    )}
                >
                    <ResourceStateBadge state={s.state} />
                </FormattedOption>
            )}
        />
    );
}
