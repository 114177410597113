import classNames from "classnames";
import { VmNetworkAnalysis } from "@cycleplatform/core/modules/networks/analysis";
import { skeletonStyles } from "../../loaders/skeleton/skeletonStyle";
import { useMemo } from "react";
import { Line } from "./Line";
import { adaptNetworkAnalysisToDiagram, NetworkDiagramStatus } from "./util";
import { PublicInternetComponent } from "./icons/PublicInternetComponent";
import { LoadBalancerComponent } from "./icons/LoadBalancerComponent";
import { VmComponent } from "./icons/VmComponent";
import { GatewayComponent } from "./icons/GatewayComponent";
import { ServerComponent } from "./icons/ServerComponent";

export function VirtualMachinePublicNetworkChart({
    analysis,
    formatTooltip = (m) => m,
}: {
    analysis: VmNetworkAnalysis | null;
    formatTooltip?: (message: string) => string;
}) {
    const status = useMemo(
        () => (analysis ? adaptNetworkAnalysisToDiagram(analysis, "vm") : null),
        [analysis]
    );

    if (!status || !analysis) {
        return <div className={classNames(skeletonStyles, "h-12")} />;
    }
    return (
        <div className="flex flex-col gap-4 ">
            {/* Split logic into egress and standard - 
            will display different versions of the chart 
            with portions hidden on egress */}
            {analysis.mode === "egress" ? (
                <EgressChart
                    status={status}
                    analysis={analysis}
                    formatTooltip={formatTooltip}
                />
            ) : (
                <NetworkChart
                    status={status}
                    analysis={analysis}
                    formatTooltip={formatTooltip}
                />
            )}
        </div>
    );
}

function NetworkChart({
    status,
    analysis,
    formatTooltip,
}: {
    status: NetworkDiagramStatus;
    analysis: VmNetworkAnalysis;
    formatTooltip: (message: string) => string;
}) {
    return (
        <div className="flex !w-full">
            <div className="flex items-center">
                <PublicInternetComponent status={status} />
            </div>
            <div className="flex w-full flex-col gap-4">
                <div className="mx-2 mt-2 flex items-center justify-between">
                    <Line
                        left={true}
                        right={true}
                        status={status.lbIngress.status}
                        message={formatTooltip(status.lbIngress.message)}
                    />
                    <LoadBalancerComponent
                        status={status}
                        formatTooltip={formatTooltip}
                    />

                    <Line
                        left={analysis.mode !== "disabled"}
                        right={analysis.mode === "public"}
                        status={status.lbEgress.status}
                        message={formatTooltip(status.lbEgress.message)}
                    />
                </div>

                <div className="mx-2 mt-2 flex items-center justify-between">
                    <Line
                        left={true}
                        right={true}
                        status={status.gatewayIngress.status}
                        message={formatTooltip(status.gatewayIngress.message)}
                    />

                    <GatewayComponent
                        status={status}
                        formatTooltip={formatTooltip}
                    />

                    <Line
                        left={analysis.mode !== "disabled"}
                        right={analysis.mode === "public"}
                        status={status.gatewayEgress.status}
                        message={formatTooltip(status.gatewayEgress.message)}
                    />
                </div>
            </div>
            <div className="flex items-center">
                <VmComponent status={status} formatTooltip={formatTooltip} />
            </div>
        </div>
    );
}

function EgressChart({
    status,
    analysis,
    formatTooltip,
}: {
    status: NetworkDiagramStatus;
    analysis: VmNetworkAnalysis;
    formatTooltip: (message: string) => string;
}) {
    return (
        <>
            <div className="mx-2 mt-2 flex items-center justify-between">
                <PublicInternetComponent status={status} />

                <Line left={true} right={false} status={"ok"} message={null} />

                {analysis.hasStaticIps ? (
                    <GatewayComponent
                        status={status}
                        formatTooltip={formatTooltip}
                    />
                ) : (
                    <ServerComponent message="Egress traffic routing though the server." />
                )}

                <Line
                    left={true}
                    right={analysis.hasStaticIps}
                    status={"ok"}
                    message={null}
                />

                <VmComponent status={status} formatTooltip={formatTooltip} />
            </div>
        </>
    );
}
