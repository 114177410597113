import { $error } from "@cycleplatform/core/util/log";
import { LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    FormSection,
    FormSectionHeader,
} from "@cycleplatform/ui/components/forms";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { faCheckCircle, faTimes } from "@fortawesome/pro-duotone-svg-icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "~/hooks";
import { setActiveHub } from "~/modules/hubs/slice";
import {
    HubMembership,
    HubMembershipsIncludes,
    cycleApi,
    useGetAccountInvitesQuery,
    useUpdateAccountInviteMutation,
} from "~/services/cycle";

export function PendingInvitesList() {
    const { data: invites, error } = useGetAccountInvitesQuery({
        include: ["senders", "hubs", "roles"],
    });

    // we don't show errors here because if this fails, we don't really care right now.
    if (error) {
        $error("error fetching invites", error);
    }

    if (!invites?.data?.length) {
        return null;
    }

    return (
        <Panel>
            <PanelTitle title="invites" />
            <PanelContent stretch>
                <ul>
                    {invites?.data?.map((i) => (
                        <AccountInvite
                            key={i.id}
                            invite={i}
                            hubMembershipIncludes={invites.includes}
                        />
                    ))}
                </ul>
            </PanelContent>
        </Panel>
    );
}

export function AccountInvite({
    invite,
    hubMembershipIncludes,
}: {
    invite: HubMembership;
    hubMembershipIncludes?: HubMembershipsIncludes;
}) {
    const hub = hubMembershipIncludes?.hubs?.[invite.hub_id];
    const sender =
        hubMembershipIncludes?.senders?.accounts?.[
            invite.invitation.sender.id as string
        ];

    const role = hubMembershipIncludes?.roles?.[invite?.role_id || ""]?.name;

    const [updateAccountInvite, { isLoading }] = useUpdateAccountInviteMutation(
        {}
    );

    const [activeButton, setActiveButton] = useState<"decline" | "accept">();
    const dispatch = useAppDispatch();
    const nav = useNavigate();

    const onSubmit = (action: "accept" | "decline") => {
        return updateAccountInvite({
            inviteId: invite?.id || "",
            body: {
                [action]: true,
            },
        })
            .unwrap()
            .then((i) => {
                dispatch(setActiveHub({ hubId: i.data.hub_id || "" }));
                dispatch(cycleApi.util.resetApiState());
                setActiveButton(undefined);
                nav("/");
            });
    };

    return (
        <li
            key={invite.id}
            className={
                "dark:border-cycle-gray items-center gap-4 border-b !border-opacity-40 p-4 last:border-none"
            }
        >
            <div className="flex justify-between">
                <div>
                    <h2 className="pb-2 ">{`Invitation to join "${hub?.name}"`}</h2>
                    <p className="text-sm">
                        {sender?.name?.first} {sender?.name?.last} invited you
                        to join {'"'}
                        <strong>{hub?.name}</strong>
                        {'"'} as {role}
                    </p>
                </div>
                <div className="flex items-center gap-4">
                    <div>
                        <LoaderButton
                            isLoading={isLoading && activeButton === "decline"}
                            flavor="discard"
                            icon={faTimes}
                            onClick={() => {
                                setActiveButton("decline");
                                onSubmit("decline");
                            }}
                        >
                            Decline
                        </LoaderButton>
                    </div>
                    <div>
                        <LoaderButton
                            isLoading={isLoading && activeButton === "accept"}
                            flavor="primary"
                            icon={faCheckCircle}
                            onClick={() => {
                                setActiveButton("accept");
                                onSubmit("accept");
                            }}
                        >
                            Accept
                        </LoaderButton>
                    </div>
                </div>
            </div>
        </li>
    );
}
