import { Button, LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    FormSectionHeader,
    FormSection,
    InputRow,
    Checkbox,
    RhfSliderInput,
    PillButtons,
    RHF_GLOBAL_ERROR,
} from "@cycleplatform/ui/components/forms";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { Controller, useForm, useFormContext, useWatch } from "react-hook-form";
import { rhfConfig } from "~/components/forms/util";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { addStepData, getCurrentForm } from "~/modules/forms/slice";
import { useContext, useEffect } from "react";
import { MultiStepDialogContext } from "~/components/dialogs/MultiStepFormDialog/context";
import {
    DialogColumn,
    DialogFooter,
} from "@cycleplatform/ui/components/dialog/components";
import { VmResourcesStepValues } from "./types";
import { CoresSelect } from "~/components/containers/CoresSelect";
import { LearnMore } from "./LearnMore";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";

export function VmResourcesStep({ stepIdx = 0 }: { stepIdx?: number }) {
    const { handleNext, handleBack, formKey } = useContext(
        MultiStepDialogContext
    );
    const currentForm = useAppSelector((state) =>
        getCurrentForm(state, formKey)
    );

    const form = useForm<VmResourcesStepValues>({
        defaultValues: currentForm?.steps?.[stepIdx] || {
            cpu: {
                active: "cores",
                cores: {
                    number: 2,
                },
            },
            ram: {
                limit: 1,
            },
        },
        ...rhfConfig,
    });

    const {
        handleSubmit,
        setError,
        formState: { isSubmitting, isDirty },
    } = form;

    const dispatch = useAppDispatch();

    const onSubmit = async (data: VmResourcesStepValues) => {
        dispatch(addStepData({ data, idx: stepIdx, key: formKey }));
        handleNext?.();
    };

    const error = currentForm?.error;

    useEffect(() => {
        if (error && error.step === stepIdx) {
            setError(RHF_GLOBAL_ERROR as never, { message: error.message });
        }
    }, [error]);

    return (
        <RhfFormProvider
            {...form}
            className="h-full"
            onSubmit={form.handleSubmit(onSubmit)}
        >
            <div className="flex">
                <DialogColumn className="w-2/3">
                    <CpuResources />
                    <RamResources />
                </DialogColumn>
                <LearnMore title="Virtual Machine Resources">
                    Set CPU and RAM limits for this virtual machine, or
                    preallocate them so those resources are reserved ahead of
                    time.
                </LearnMore>
            </div>

            <DialogFooter className="justify-between">
                <Button icon={faArrowLeft} onClick={() => handleBack?.()}>
                    Back
                </Button>
                <div>
                    <RhfGlobalFormError />
                </div>
                <LoaderButton
                    isLoading={isSubmitting}
                    onClick={handleSubmit(onSubmit)}
                    type="button"
                    flavor="primary"
                    icon={faArrowRight}
                >
                    Next
                </LoaderButton>
            </DialogFooter>
        </RhfFormProvider>
    );
}

function RamResources() {
    const form = useFormContext<VmResourcesStepValues>();

    const { register, control } = form;

    return (
        <>
            <FormSectionHeader header="RAM" />
            <FormSection>
                <InputRow className="items-center gap-8">
                    <RhfFormField label="limit (GB)" name="ram.limit" required>
                        <RhfSliderInput
                            min={1}
                            max={16}
                            numTicks={5}
                            {...register(`ram.limit`, { valueAsNumber: true })}
                        />
                    </RhfFormField>
                    <RhfFormField
                        label="Allocate"
                        name="ram.allocate"
                        className="!w-[10rem]"
                    >
                        <Checkbox {...register("ram.allocate")} />
                    </RhfFormField>
                </InputRow>
            </FormSection>
        </>
    );
}

function CpuResources() {
    const form = useFormContext<VmResourcesStepValues>();

    const { register, control } = form;

    const active = useWatch({ name: "cpu.active", control });

    return (
        <>
            <FormSectionHeader header="CPU">
                <Controller
                    name="cpu.active"
                    control={control}
                    render={({ field: { ref: _ref, ...field } }) => {
                        return (
                            <PillButtons
                                {...field}
                                options={[
                                    { label: "Cores", value: "cores" },
                                    {
                                        label: "Cpuset",
                                        value: "cpu",
                                    },
                                ]}
                            />
                        );
                    }}
                ></Controller>
            </FormSectionHeader>
            <FormSection>
                <InputRow className="items-center gap-8">
                    {active === "cores" ? (
                        <>
                            <RhfFormField
                                label="cores"
                                name="cpu.cores"
                                required
                            >
                                <RhfSliderInput
                                    min={1}
                                    max={16}
                                    numTicks={5}
                                    {...register(`cpu.cores.number`, {
                                        valueAsNumber: true,
                                    })}
                                />
                            </RhfFormField>

                            {/* TODO Enable once supported by platform */}

                            <RhfFormField
                                label="Allocate"
                                name="cpu.cores.allocate"
                                className="!w-[10rem]"
                                help="CPU allocation will be available upon full production release."
                            >
                                <Checkbox
                                    {...register("cpu.cores.allocate")}
                                    disabled
                                />
                            </RhfFormField>
                        </>
                    ) : (
                        <RhfFormField
                            label="CPUset"
                            name="cpu.cpus"
                            className="w-full"
                        >
                            <Controller
                                render={({
                                    field: { ref: _ref, ...field },
                                }) => <CoresSelect {...field} />}
                                control={control}
                                name="cpu.cpus"
                            />
                        </RhfFormField>
                    )}
                </InputRow>
            </FormSection>
        </>
    );
}
