import {
    FormField,
    FormSection,
    FormSectionHeader,
    InputRow,
    PillButtons,
    RhfFormField,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { IsoCustomizeTextInput } from "../inputs/IsoCustomizeTextInput";
import { IpxeNetworkInterfaceInput } from "../inputs/IpxeNetworkInterfaceInput";
import { GenerateIsoFormType } from "../types";

export function IsoIpxeFormSection() {
    return (
        <>
            <FormSectionHeader header="IPXE" />
            <FormSection>
                <IsoCustomizeTextInput
                    field="config.ipxe.vlan_id"
                    label="VLAN ID"
                />
                <IpxeNetworkInterfaceInput
                    field="config.ipxe.network_interface"
                    label="Network Interface"
                />

                <DHCPInput />
            </FormSection>
        </>
    );
}

function DHCPInput() {
    const [pillValue, setPillValue] = useState("dhcp");

    const { register, setValue } = useFormContext<GenerateIsoFormType>();

    return (
        <div className="flex gap-4">
            <FormField className="!w-80" label={`IPXE IP`}>
                <PillButtons
                    className="pt-2"
                    value={pillValue}
                    options={[
                        { label: "DHCP", value: "dhcp" },
                        { label: "Static", value: "static" },
                    ]}
                    onChange={(v) => {
                        if (v === "dhcp") {
                            setValue("config.ipxe.static_ip", null, {
                                shouldDirty: true,
                            });
                            setValue("config.ipxe.netmask", null, {
                                shouldDirty: true,
                            });
                            setValue("config.ipxe.gateway_ip", null, {
                                shouldDirty: true,
                            });
                            setValue("config.ipxe.dns_ip", null, {
                                shouldDirty: true,
                            });
                        }
                        setPillValue(v);
                    }}
                />
            </FormField>

            {pillValue === "static" ? (
                <div className="w-full">
                    <InputRow>
                        <RhfFormField
                            label={"Static Ip"}
                            name={`config.ipxe.static_ip`}
                        >
                            <TextInput {...register(`config.ipxe.static_ip`)} />
                        </RhfFormField>

                        <RhfFormField
                            label={"Netmask"}
                            name={`config.ipxe.netmask`}
                        >
                            <TextInput {...register(`config.ipxe.netmask`)} />
                        </RhfFormField>
                    </InputRow>

                    <RhfFormField
                        label={"Gateway Ip"}
                        name={`config.ipxe.gateway_ip`}
                    >
                        <TextInput {...register(`config.ipxe.gateway_ip`)} />
                    </RhfFormField>

                    <RhfFormField label={"DNS Ip"} name={`config.ipxe.dns_ip`}>
                        <TextInput {...register(`config.ipxe.dns_ip`)} />
                    </RhfFormField>
                </div>
            ) : (
                <div className="w-full">
                    <InputRow>
                        <FormField label={"Static Ip"}>
                            <TextInput disabled />
                        </FormField>
                        <FormField label={"Netmask"}>
                            <TextInput disabled />
                        </FormField>
                    </InputRow>
                    <FormField label={"Gateway Ip"}>
                        <TextInput disabled />
                    </FormField>
                    <FormField label={"DNS Ip"}>
                        <TextInput disabled />
                    </FormField>
                </div>
            )}
        </div>
    );
}
