import { ScaleThresholdMetric } from "~/services/cycle";

export const DefaultThresholdValues: Record<
    ScaleThresholdMetric["type"],
    ScaleThresholdMetric
> = {
    ram: { type: "ram", details: { usage: "50M" } },
    cpu: { type: "cpu", details: { utilization: 50 } },
    custom: { type: "custom", details: { webhook: "" } },
    ["network-connections"]: {
        type: "network-connections",
        details: { connections: 10 },
    },
    ["network-requests"]: {
        type: "network-requests",
        details: { requests: 100 },
    },
    ["network-throughput"]: {
        type: "network-throughput",
        details: { private: true, bandwidth: "50M" },
    },
};
