import {
    formatRFC3339,
    roundToNearestMinutes,
    subDays,
    subMinutes,
} from "date-fns";

export function getLbControllersQuery(environmentId: string | undefined) {
    return {
        filter: {
            "range-start": formatRFC3339(
                roundToNearestMinutes(subMinutes(new Date(), 10), {
                    roundingMethod: "floor",
                })
            ),
            "range-end": formatRFC3339(
                roundToNearestMinutes(new Date(), {
                    roundingMethod: "floor",
                })
            ),
        },

        // Random assortment of controller metrics to narrow down the query time
        body: {
            criteria: {
                "metadata.metric": "lb.controllers.active",
                "labels.environment_id": environmentId,
            },
            pipeline: [
                {
                    $sort: {
                        time: -1,
                    },
                },

                {
                    $project: {
                        _id: 0,
                        telemControllers: {
                            $map: {
                                input: {
                                    $split: ["$labels.ports", ","],
                                },
                                as: "port",
                                in: { $toInt: "$$port" },
                            },
                        },
                    },
                },
            ],
        },
    };
}
