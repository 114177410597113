import { Button } from "@cycleplatform/ui/components/buttons";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { useContext } from "react";
import { MultiStepDialogContext } from "~/components/dialogs/MultiStepFormDialog/context";

export function HandleNextButton() {
    const { handleNext } = useContext(MultiStepDialogContext);
    return (
        <Button
            onClick={() => handleNext()}
            icon={faArrowRight}
            flavor="primary"
        >
            Next
        </Button>
    );
}
