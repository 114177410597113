import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@headlessui/react";
import classNames from "classnames";
import React from "react";

export function DialogBody({
    children,
    onClose,
    className,
    isCloseable = true,
}: {
    children: React.ReactNode;
    onClose: () => void;
    className?: string;
    isCloseable?: boolean;
}) {
    return (
        <>
            <BackgroundBlur />

            <div className="fixed inset-0 flex flex-col items-center ">
                <Dialog.Panel
                    className={classNames(
                        className,
                        "relative max-h-[90%] min-h-[70%] w-[70%] min-w-[75rem]  rounded-b-lg  p-16"
                    )}
                >
                    {isCloseable ? (
                        <CloseDialogButton onClose={onClose} />
                    ) : null}
                    {children}
                </Dialog.Panel>
            </div>
        </>
    );
}

function CloseDialogButton({ onClose }: { onClose: () => void }) {
    return (
        <button
            onClick={onClose}
            className={classNames(
                "bg-cycle-gray text-cycle-white absolute top-3 right-16 flex h-10 w-10 items-center justify-center rounded-full opacity-90 transition-colors",
                "hover:text-cycle-blue hover:bg-cycle-black"
            )}
        >
            <FontAwesomeIcon icon={faTimes} />
        </button>
    );
}

function BackgroundBlur() {
    return (
        <div
            className="fixed inset-0  bg-black/30 backdrop-blur-[2px]"
            aria-hidden="true"
        />
    );
}
