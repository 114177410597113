import { InfoPanel } from "@cycleplatform/ui/components/panels";
import { TierPlan } from "~/services/cycle";
import { RhfGlobalFormError } from "@cycleplatform/ui/components/forms";
import { useAppSelector } from "~/hooks";
import { selectActiveHub } from "~/modules/hubs/slice";
import { Button } from "@cycleplatform/ui/components/buttons";
import { useSetHubPlanParams } from "../../../hooks";
import { TierCategoryNavigation } from "./components/CategoryNavigation";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";
import { useSelectTierFunctionality } from "./helpers";
import { TierCategorySections } from "./components/TierCategorySections";

type SelectTierStepProps = {
    tiers: TierPlan[] | undefined;
};

export function SelectTierStep({ tiers }: SelectTierStepProps) {
    const hub = useAppSelector(selectActiveHub);

    const {
        currentTier,
        currentTierId,
        tiersByCategory,
        selectedTierId,
        activeTierPrice,
    } = useSelectTierFunctionality();

    const hasHubBillingTier = !!hub?.billing?.plans.tier_id;
    const setHubPlanParams = useSetHubPlanParams();

    return (
        <div className="h-full pb-16">
            <TierCategoryNavigation
                tiers={tiers}
                currentTierId={
                    currentTier?.code !== "free" ? currentTierId : null
                }
                hasCustomTiers={tiersByCategory.custom.length !== 0}
            />

            {tiers && !hasHubBillingTier ? (
                <InfoPanel>
                    Cycle is configuring the billing for this hub. This form
                    will be available shortly.
                </InfoPanel>
            ) : null}

            <TierCategorySections
                currentTierId={currentTierId}
                tiersByCategory={tiersByCategory}
                selectedTierId={selectedTierId}
                activeTierPrice={activeTierPrice}
            />

            <DialogFooter className="items-center ">
                <RhfGlobalFormError />
                <Button
                    disabled={
                        !selectedTierId || currentTierId === selectedTierId
                    }
                    onClick={() =>
                        setHubPlanParams({
                            step: "1",
                            tier: selectedTierId,
                        })
                    }
                >
                    Next
                </Button>
            </DialogFooter>
        </div>
    );
}
