import { roundToNearestMinutes } from "date-fns";
import { formatRFC3339, subDays } from "date-fns";

export type LoadBalancerDestinationMetric = {
    destination_container_id: string;
    destination_instance_id: string;
    instance_id: string;
    time: string;
    connections_success: number;
    connections_unavailable: number;
    connections_invalid: number;
    requests: number;
    transmitted_kb: number;
    received_kb: number;
    connections_total: number;
    connections_success_percent: number;
};

export function buildRouterMetricsQuery(
    envId: string | undefined,
    port: number | undefined
) {
    return {
        filter: {
            "range-start": formatRFC3339(
                roundToNearestMinutes(subDays(new Date(), 1), {
                    roundingMethod: "floor",
                })
            ),
        },
        body: {
            criteria: {
                "metadata.metric": {
                    $in: [
                        "lb.controller.router.destinations.connections.transmitted_kb",
                        "lb.controller.router.destinations.connections.received_kb",
                        "lb.controller.router.destinations.requests.total",
                        "lb.controller.router.destinations.connections.success",
                        "lb.controller.router.destinations.connections.unavailable",
                        "lb.controller.router.destinations.connections.invalid",
                    ],
                },
                "labels.port": `${port}`,
                "labels.environment_id": envId,
            },
            pipeline: [
                {
                    $sort: {
                        time: -1,
                    },
                },
                {
                    $project: {
                        destination_instance_id:
                            "$labels.destination:instance_id",
                        destination_container_id:
                            "$labels.destination:container_id",
                        instance_id: "$labels.instance_id",
                        time: "$time",
                        metadata: "$metadata",
                        point_value: {
                            $arrayElemAt: [
                                {
                                    $arrayElemAt: ["$points", 0],
                                },
                                1,
                            ],
                        },
                    },
                },
                {
                    $group: {
                        _id: {
                            destination_instance_id: "$destination_instance_id",
                            instance_id: "$instance_id",
                        },
                        destination_instance_id: {
                            $first: "$destination_instance_id",
                        },
                        destination_container_id: {
                            $first: "$destination_container_id",
                        },
                        instance_id: {
                            $first: "$instance_id",
                        },
                        time: {
                            $last: "$time",
                        },
                        connections_success: {
                            $sum: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "lb.controller.router.destinations.connections.success",
                                        ],
                                    },
                                    then: "$point_value",
                                    else: 0,
                                },
                            },
                        },
                        connections_unavailable: {
                            $sum: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "lb.controller.router.destinations.connections.unavailable",
                                        ],
                                    },
                                    then: "$point_value",
                                    else: 0,
                                },
                            },
                        },
                        connections_invalid: {
                            $sum: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "lb.controller.router.destinations.connections.invalid",
                                        ],
                                    },
                                    then: "$point_value",
                                    else: 0,
                                },
                            },
                        },
                        requests: {
                            $sum: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "lb.controller.router.destinations.requests.total",
                                        ],
                                    },
                                    then: "$point_value",
                                    else: 0,
                                },
                            },
                        },
                        transmitted_kb: {
                            $sum: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "lb.controller.router.destinations.connections.transmitted_kb",
                                        ],
                                    },
                                    then: "$point_value",
                                    else: 0,
                                },
                            },
                        },
                        received_kb: {
                            $sum: {
                                $cond: {
                                    if: {
                                        $eq: [
                                            "$metadata.metric",
                                            "lb.controller.router.destinations.connections.received_kb",
                                        ],
                                    },
                                    then: "$point_value",
                                    else: 0,
                                },
                            },
                        },
                    },
                },
                {
                    $addFields: {
                        connections_total: {
                            $add: [
                                "$connections_success",
                                "$connections_unavailable",
                                "$connections_invalid",
                            ],
                        },
                    },
                },
                {
                    $addFields: {
                        connections_success_percent: {
                            $cond: {
                                if: {
                                    $gt: ["$connections_total", 0],
                                },
                                then: {
                                    $multiply: [
                                        {
                                            $divide: [
                                                "$connections_success",
                                                "$connections_total",
                                            ],
                                        },
                                        100,
                                    ],
                                },
                                else: null,
                            },
                        },
                    },
                },
                {
                    $project: {
                        _id: 0,
                    },
                },
                {
                    $sort: {
                        instance_id: 1,
                        destination_instance_id: 1,
                    },
                },
            ],
        },
    };
}
