import {
    StyledHeaderCell,
    StyledTableHead,
} from "@cycleplatform/ui/components/tables";

export function UrlsTableHeader() {
    return (
        <StyledTableHead>
            <StyledHeaderCell className="max-w-[20rem]">Host</StyledHeaderCell>
            <StyledHeaderCell className="min-w-[6rem] text-center">
                Method
            </StyledHeaderCell>

            <StyledHeaderCell className="w-[8rem] text-center">
                Responses
            </StyledHeaderCell>
            <StyledHeaderCell className="w-[8rem] text-center">
                Handler
            </StyledHeaderCell>
            <StyledHeaderCell className="w-[14rem] text-center">
                Average Time
            </StyledHeaderCell>
        </StyledTableHead>
    );
}
