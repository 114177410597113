import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import {
    StyledCell,
    StyledDataTable,
    StyledTableRow,
} from "@cycleplatform/ui/components/tables";
import { RouterTableHeader } from "./RouterTableHeader";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { NavIcons } from "~/components/layout/NavIcons";

export function RouterEmptyPanel() {
    return (
        <Panel>
            <PanelTitle title="Routers" />
            <PanelContent stretch>
                <StyledDataTable>
                    <RouterTableHeader />
                    <tbody>
                        <StyledTableRow>
                            <StyledCell colSpan={5}>
                                <EmptyResource
                                    icon={NavIcons["telemetry"]}
                                    title="No Metrics"
                                    className="w-full border-none"
                                />
                            </StyledCell>
                        </StyledTableRow>
                    </tbody>
                </StyledDataTable>
            </PanelContent>
        </Panel>
    );
}
