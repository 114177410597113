import {
    Account,
    useGetAccountInvitesQuery,
    useGetAccountQuery,
} from "~/services/cycle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEnvelope,
    faGear,
    faPowerOff,
} from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Avatar } from "@cycleplatform/ui/components/avatar";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { PositionedMenu } from "@cycleplatform/ui/components/menus";
import { Placement } from "@floating-ui/react";

export function AccountMenu({
    placement = "right-start",
}: {
    placement?: Placement;
}) {
    const { data: account, error: accountError } = useGetAccountQuery();

    if (accountError) {
        throw accountError;
    }

    return (
        <PositionedMenu
            render={() => <AccountPanel account={account?.data} />}
            placement={placement}
            className="w-[14rem]"
            stretch
        >
            <button className="flex cursor-pointer items-center gap-2 px-2 text-lg transition-colors">
                {account?.data?.email.address && (
                    <Avatar
                        className="ring-cycle-blue-accent hover:ring-cycle-blue ring-2"
                        email={account.data.email.address}
                    />
                )}
            </button>
        </PositionedMenu>
    );
}

function AccountPanel({ account }: { account: Account | undefined }) {
    const { data: invites, error: invitesError } = useGetAccountInvitesQuery({
        include: ["senders", "hubs"],
    });

    if (invitesError) {
        throw invitesError;
    }

    return (
        <>
            <div className="dark:bg-cycle-black-accent rounded-t-lg border-b bg-white p-4 text-left transition">
                <Link
                    to={generateDialogLink("account", {})}
                    className={classNames("text-cycle-blue")}
                >
                    <p className="text-cycle-gray dark:text-cycle-white mb-2 break-all text-lg">
                        {account?.name.first} {account?.name.last}
                    </p>
                    <div className="flex justify-between ">
                        <p className="text-xs">{account?.email.address}</p>
                    </div>
                </Link>
            </div>

            <div className="dark:bg-cycle-black-accent flex flex-col rounded-b-lg">
                <Link
                    to={generateDialogLink("account", {
                        "dialog-tab": "hubs",
                    })}
                    className="border-b p-2"
                >
                    <div className="flex items-center justify-between">
                        <div>
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                className="pr-2"
                            />
                            Invites
                        </div>
                        {invites?.data?.length ? (
                            <div className="bg-cycle-blue relative h-6 w-6 rounded-full">
                                <span className="text-cycle-white absolute inset-0 text-center font-bold">
                                    {invites?.data?.length}
                                </span>
                            </div>
                        ) : null}
                    </div>
                </Link>

                <Link
                    to={generateDialogLink("account", {})}
                    className="border-b p-2"
                >
                    <FontAwesomeIcon icon={faGear} className="pr-2" />
                    Settings
                </Link>

                <Link to="/logout" className="border-b p-2">
                    <FontAwesomeIcon icon={faPowerOff} className="pr-2" />
                    Log Out
                </Link>
            </div>
        </>
    );
}
