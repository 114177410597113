import { NetworkAnalysis } from "@cycleplatform/core/modules/networks/analysis";
import {
    NetworkAnalysisError,
    PublicNetworkAnalysisStatus,
} from "@cycleplatform/core/modules/networks/analysis/error";

export type NetworkDiagramStatus = {
    internet: {
        status: string;
    };
    lbIngress: {
        hidden: boolean;
        status: PublicNetworkAnalysisStatus;
        message: string;
    };
    lb: {
        hidden: boolean;
        status: PublicNetworkAnalysisStatus;
        message: string;
    };
    lbEgress: {
        status: PublicNetworkAnalysisStatus;
        message: string;
    };
    container: {
        status: PublicNetworkAnalysisStatus;
        message: string;
    };
    vm: {
        status: PublicNetworkAnalysisStatus;
        message: string;
    };
    gateway: {
        hidden: boolean;
        status: PublicNetworkAnalysisStatus;
        message: string;
    };
    gatewayIngress: {
        hidden: boolean;
        status: PublicNetworkAnalysisStatus;
        message: string;
    };
    gatewayEgress: {
        status: PublicNetworkAnalysisStatus;
        message: string;
    };
};

export function adaptNetworkAnalysisToDiagram(
    analysis: NetworkAnalysis,
    type: "container" | "vm"
): NetworkDiagramStatus {
    const errors = getErrorsByType(analysis.errors);

    const isDisabled = analysis.mode === "disabled";

    const overPortsString = analysis.exposedPorts
        .map((p) => `${p.lbIngress}:${p.containerIngress}`)
        .join(", ");

    return {
        internet: {
            status: "ok",
        },
        lbIngress: {
            hidden: analysis.mode === "egress",
            status: getHighestLevelStatus(errors["lb-ingress"]),
            message:
                errors["lb-ingress"].map((e) => e.details).join("\n") ||
                "Load balancer ingress configured correctly.",
        },
        lb: {
            hidden: analysis.mode === "egress", // hide load balancer if egress (traffic doesn't go through it.)
            status: getHighestLevelStatus(errors["lb"]),
            message:
                errors["lb"].map((e) => e.details).join("\n") ||
                "Load balancer configured correctly.",
        },
        lbEgress: {
            status: isDisabled
                ? "disabled"
                : getHighestLevelStatus(errors["lb-egress"]),
            message: isDisabled
                ? `${
                      type === "container" ? "Container" : "Virtual machine"
                  } public network is set to disabled.`
                : errors["lb-egress"].map((e) => e.details).join("\n") ||
                  `${
                      type === "container" ? "Container" : "Virtual machine"
                  } <-> ${
                      analysis.mode === "egress" ? "internet" : "load balancer"
                  } connection configured for ${
                      analysis.mode === "egress"
                          ? "outbound (egress)"
                          : "bi-directional"
                  } traffic${
                      overPortsString ? ` over ports ${overPortsString}` : ""
                  }.`,
        },
        container: {
            status: isDisabled
                ? "disabled"
                : getHighestLevelStatus(errors["container"]),
            message: isDisabled
                ? "Container public network is set to disabled."
                : errors["container"].map((e) => e.details).join("\n") ||
                  "Container configured correctly.",
        },
        gatewayEgress: {
            status: isDisabled
                ? "disabled"
                : getHighestLevelStatus(errors["gateway-egress"]),
            message: isDisabled
                ? "Gateway egress is set to disabled."
                : errors["gateway-egress"].map((e) => e.details).join("\n") ||
                  "Gateway egress configured correctly.",
        },

        gatewayIngress: {
            hidden: analysis.mode === "egress",
            status: getHighestLevelStatus(errors["gateway-ingress"]),
            message:
                errors["gateway-ingress"].map((e) => e.details).join("\n") ||
                "Gateway ingress configured correctly.",
        },
        vm: {
            status: isDisabled
                ? "disabled"
                : getHighestLevelStatus(errors["vm"]),
            message: isDisabled
                ? "Virtual machine public network is set to disabled."
                : errors["vm"].map((e) => e.details).join("\n") ||
                  "Virtual machine configured correctly.",
        },
        gateway: {
            hidden: analysis.mode === "egress",

            status: getHighestLevelStatus(errors["gateway"]),
            message:
                errors["gateway"]?.map((e) => e.details).join("\n") ||
                "Gateway configured correctly.",
        },
    };
}

export function getHighestLevelStatus(errors: NetworkAnalysis["errors"]) {
    const ord: PublicNetworkAnalysisStatus[] = [
        "error",
        "warning",
        "disabled",
        "ok",
    ];
    return (
        errors?.reduce((acc, cur) => {
            if (ord.indexOf(acc) > ord.indexOf(cur.type)) {
                acc = cur.type;
            }
            return acc;
        }, "ok" as PublicNetworkAnalysisStatus) || "ok"
    );
}

export function getErrorsByType(
    errors: NetworkAnalysis["errors"]
): Record<string, NetworkAnalysisError[]> {
    return errors.reduce(
        (acc, cur) => {
            const prefix = cur.code.split(".")[0];
            if (!acc[prefix]) {
                acc[prefix] = [];
            }

            acc[prefix].push(cur);

            return acc;
        },
        {
            lb: [],
            container: [],
            vm: [],
            gateway: [],
            "lb-ingress": [],
            "lb-egress": [],
            "vm-ingress": [],
            "gateway-ingress": [],
            "gateway-egress": [],
        } as Record<string, NetworkAnalysisError[]>
    );
}
