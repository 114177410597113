import { getAllDialogSearchParams } from "~/components/dialogs/helpers";
import classNames from "classnames";
import { TierCategory } from "@cycleplatform/core/modules/tiers/util";

export function CategorySection({
    children,
    tab,
    isDefault,
}: {
    children: React.ReactNode;
    tab: TierCategory;
    isDefault?: boolean;
}) {
    const params = getAllDialogSearchParams<"change-hub-plan">();
    const tabParam = params["dialog-tier-tab"];
    const isRendered = tabParam === tab || (isDefault && !tabParam);
    return (
        <div
            className={classNames(
                "gap-4",
                isRendered ? "flex" : "hidden",
                "flex-col items-stretch justify-center md:flex-row"
            )}
        >
            {children}
        </div>
    );
}
