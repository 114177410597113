import {
    faTrash,
    faQuestionCircle,
    faEdit,
    faPlus,
    faHammer,
} from "@fortawesome/pro-solid-svg-icons";
import { Activity, ComponentIncludes } from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import { VirtualProviderIsoLink } from "~/components/common/resources/ResourceLinks";

export function formatVirtualProviderIsoMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const isoLink = (
        <VirtualProviderIsoLink
            isoId={item.component?.id}
            components={components}
        />
    );

    switch (item.event) {
        case "infrastructure.virtual-providers.iso.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Created virtual provider ISO {isoLink}</span>
                ) : (
                    <span>Error creating virtual provider ISO {isoLink}</span>
                ),
                icon: faPlus,
            };

        case "infrastructure.virtual-providers.iso.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted virtual provider ISO {isoLink}</span>
                ) : (
                    <span>Error deleting virtual provider ISO {isoLink}</span>
                ),
                icon: faTrash,
            };
        case "infrastructure.virtual-providers.iso.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested deletion of virtual provider ISO {isoLink}
                    </span>
                ) : (
                    <span>
                        Requested deletion of virtual provider ISO {isoLink}
                    </span>
                ),
                icon: faTrash,
            };

        case "infrastructure.virtual-providers.iso.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated virtual provider ISO {isoLink}</span>
                ) : (
                    <span>Error updating virtual provider ISO {isoLink}</span>
                ),
                icon: faEdit,
            };
        case "infrastructure.virtual-providers.iso.generate":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Generated virtual provider ISO {isoLink}</span>
                ) : (
                    <span>Error generating virtual provider ISO {isoLink}</span>
                ),
                icon: faHammer,
            };

        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}
