import { Panel, PanelTitle } from "@cycleplatform/ui/components/panels";
import { useContext } from "react";
import { VirtualMachineDialogContex } from "../../../context";
import { PanelContentBoundary } from "~/components/layout/panels/PanelContentBoundary";
import { PublicDomainsTable } from "~/components/dialogs/containers/container/dashboard/PublicDomainsTable";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { NavIcons } from "~/components/layout/NavIcons";
import { SkeletonTable } from "@cycleplatform/ui/components/loaders/skeleton";
import { Container } from "~/services/cycle";
import { injectHypervisorContainerMeta } from "@cycleplatform/core/modules/networks/analysis";

export function VmDomainsPanel() {
    const { vm, container } = useContext(VirtualMachineDialogContex);

    return (
        <Panel>
            <PanelTitle title="Domains" />
            <PanelContentBoundary stretch>
                {vm ? (
                    <>
                        <div>
                            {container?.config?.network?.public === "enable" &&
                            vm?.meta?.domains?.length ? (
                                <div>
                                    {/* TODO - We inject domain meta into container meta - this will be improved */}
                                    <PublicDomainsTable
                                        domains={vm?.meta?.domains}
                                        container={
                                            injectHypervisorContainerMeta(
                                                container,
                                                vm
                                            ) as Container
                                        }
                                    />
                                </div>
                            ) : vm.config.network.public !== "enable" ? (
                                <EmptyResource
                                    className="border-none"
                                    icon={NavIcons["dns"]}
                                    title="Public Networking Disabled"
                                >
                                    <p className="text-center">
                                        The public network is disabled for this
                                        virtual machine. Enable it to access
                                        this virtual machine over the public
                                        internet.
                                    </p>
                                </EmptyResource>
                            ) : (
                                <EmptyResource
                                    className="border-none"
                                    icon={NavIcons["dns"]}
                                    title="No Domains Assigned"
                                >
                                    <p className="text-center">
                                        To access this virtual machine using a
                                        domain name, create a LINKED record and
                                        point it here.
                                    </p>
                                </EmptyResource>
                            )}
                        </div>
                    </>
                ) : (
                    <SkeletonTable />
                )}
            </PanelContentBoundary>
        </Panel>
    );
}
