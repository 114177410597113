import { useContext } from "react";
import { VirtualMachineDialogContex } from "../../../context";
import {
    InfoPanel,
    Panel,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { PanelContentBoundary } from "~/components/layout/panels/PanelContentBoundary";
import { CopyInput } from "@cycleplatform/ui/components/forms";
import { FormattedDate } from "@cycleplatform/ui/components/time";

export function VmRootPasswordPanel() {
    const { vm } = useContext(VirtualMachineDialogContex);

    return (
        <Panel>
            <PanelTitle
                title="Root Password"
                className="flex justify-between"
                help="The root password is set for Cycle base images and images with cloud-init installed."
            />
            <PanelContentBoundary>
                {vm && vm.root_password?.current ? (
                    <CopyInput
                        value={vm.root_password.current}
                        type="password"
                    />
                ) : (
                    <InfoPanel>
                        The root password is only visible for the first 10
                        minutes after it has been generated. Contact the hub
                        owner if you need this password.
                    </InfoPanel>
                )}
                {vm?.root_password?.changed && (
                    <div className="mx-1 mt-4 text-sm">
                        Last Generated:{" "}
                        <FormattedDate date={vm?.root_password.changed} />
                    </div>
                )}
            </PanelContentBoundary>
        </Panel>
    );
}
