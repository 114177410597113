import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";
import { useGetBillingTiersQuery } from "~/services/cycle";
import { SkeletonTable } from "@cycleplatform/ui/components/loaders/skeleton";
import { HubSetupTierForm } from "./form";
import { ActiveTierSection } from "./ActiveTier";
import { useAppSelector } from "~/hooks";
import { selectActiveHub } from "~/modules/hubs/slice";

export function HubSetupTierStep({ stepIdx = 0 }: { stepIdx?: number }) {
    const { data: tiers, isLoading } = useGetBillingTiersQuery();
    const hub = useAppSelector(selectActiveHub);
    const currentTierId = hub?.billing?.plans?.tier_id || null;
    const tier = tiers?.data?.find((t) => t.id === currentTierId);

    if (tier?.code && tier?.code !== "free") {
        return <ActiveTierSection />;
    }

    return (
        <DialogColumn>
            <HubSetupTierForm tiers={tiers?.data} stepIdx={stepIdx} />
        </DialogColumn>
    );
}
