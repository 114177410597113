import {
    clearDialogParams,
    getAllDialogSearchParams,
    isDialogOpen,
} from "~/components/dialogs/helpers";
import { useNavigate } from "react-router-dom";
import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import { CreateIntegrationForm } from "./CreateIntegrationsForm";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";
import { useMemo } from "react";
import { findAvailableIntegration } from "@cycleplatform/core/modules/hub/integrations/helpers";
import { useGetAvailableIntegrationsQuery } from "~/services/cycle";

export function CreateIntegrationDialog() {
    const nav = useNavigate();

    const params = getAllDialogSearchParams<"integration-create">();
    const integrationVendor = params["dialog-integration-vendor"];

    const { data: availableIntegrations, error } =
        useGetAvailableIntegrationsQuery();

    const availableIntegration = useMemo(
        () =>
            findAvailableIntegration(
                availableIntegrations?.data,
                integrationVendor
            ),
        [availableIntegrations, integrationVendor]
    );

    const isCreatableIntegration = useMemo(
        () =>
            !!availableIntegration &&
            availableIntegration.usable &&
            !availableIntegration.deprecated,
        [availableIntegration]
    );

    if (error) {
        throw error;
    }

    return (
        <StyledFormDialog
            title={`Add Integration - ${availableIntegration?.name}`}
            isOpen={
                isDialogOpen("integration-create") && isCreatableIntegration
            }
            onClose={() => nav(clearDialogParams())}
        >
            <DialogColumn className="w-full">
                <CreateIntegrationForm />
            </DialogColumn>
        </StyledFormDialog>
    );
}
