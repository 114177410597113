import {
    faTrash,
    faArrowCircleUp,
    faQuestionCircle,
    faPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { Activity, ComponentIncludes } from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import { ApiKeyLink } from "~/components/common/resources/ResourceLinks";

export function formatApiKeyMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const networkLink = (
        <ApiKeyLink apiKeyId={item.component?.id} components={components} />
    );

    switch (item.event) {
        case "hub.apikey.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted API key {networkLink}</span>
                ) : (
                    <span>Error deleting API key {networkLink}</span>
                ),
                icon: faTrash,
            };
        case "hub.apikey.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated API key {networkLink}</span>
                ) : (
                    <span>Error updating API key {networkLink}</span>
                ),
                icon: faArrowCircleUp,
            };
        case "hub.apikey.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Created API key {networkLink}</span>
                ) : (
                    <span>Error creating API key</span>
                ),
                icon: faPlus,
            };
        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}
