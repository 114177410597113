import { Button } from "@cycleplatform/ui/components/buttons";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { useContext } from "react";
import { MultiStepDialogContext } from "~/components/dialogs/MultiStepFormDialog/context";
import { useAppSelector } from "~/hooks";
import { CreateOrderApiArg, TierPlan } from "~/services/cycle";
import { ChooseAnotherHubButton } from "../../buttons/ChooseAnotherHubButton";
import { addStepData, getCurrentForm } from "~/modules/forms/slice";
import { useForm, useWatch } from "react-hook-form";
import {
    RhfFormProvider,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import { dispatch } from "~/dispatch";
import { DeleteHubButton } from "../../buttons/DeleteHubButton";
import { AccessControlOverlay } from "~/components/common/buttons";
import { useSelectTierFunctionality } from "../../../changePlan/form/steps/selectTier/helpers";
import { TierCategoryNavigation } from "../../../changePlan/form/steps/selectTier/components/CategoryNavigation";
import { TierCategorySections } from "../../../changePlan/form/steps/selectTier/components/TierCategorySections";

export function HubSetupTierForm({
    tiers,
    stepIdx,
}: {
    tiers: TierPlan[] | undefined;
    stepIdx: number;
}) {
    const { formKey, handleNext } = useContext(MultiStepDialogContext);
    const currentForm = useAppSelector((state) =>
        getCurrentForm(state, formKey)
    );

    const cachedTier = currentForm?.steps?.[stepIdx];

    const form = useForm<CreateOrderApiArg["body"]>({
        defaultValues: cachedTier,
    });

    const {
        formState: { isDirty },
    } = form;

    const onSubmit = (data: CreateOrderApiArg["body"]) => {
        dispatch(addStepData({ data, idx: stepIdx, key: formKey }));
        handleNext();
    };
    return (
        <RhfFormProvider {...form} className="!h-full">
            <Form tiers={tiers} />
            <DialogFooter className="items-center justify-between ">
                <div className="flex gap-4">
                    <ChooseAnotherHubButton />
                    <DeleteHubButton />
                </div>

                <RhfGlobalFormError />
                <AccessControlOverlay capability={"billing-services-manage"}>
                    <Button
                        flavor="primary"
                        icon={faArrowRight}
                        disabled={!isDirty && !cachedTier}
                        onClick={form.handleSubmit(onSubmit)}
                    >
                        Payment Method
                    </Button>
                </AccessControlOverlay>
            </DialogFooter>
        </RhfFormProvider>
    );
}

export function Form({ tiers }: { tiers: TierPlan[] | undefined }) {
    const {
        currentTier,
        currentTierId,
        tiersByCategory,
        selectedTierId,
        activeTierPrice,
    } = useSelectTierFunctionality();

    return (
        <>
            <TierCategoryNavigation
                tiers={tiers}
                currentTierId={
                    currentTier?.code !== "free" ? currentTierId : null
                }
                hasCustomTiers={tiersByCategory.custom.length !== 0}
            />

            <TierCategorySections
                currentTierId={currentTierId}
                tiersByCategory={tiersByCategory}
                selectedTierId={selectedTierId}
                activeTierPrice={activeTierPrice}
            />
        </>
    );
}
