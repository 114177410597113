import { Container, StackBuildDeployAction, StackSpec } from "~/services/cycle";
import { useFormContext } from "react-hook-form";
import {
    FormSectionHeader,
    FormSection,
} from "@cycleplatform/ui/components/forms";
import { useEffect, useMemo } from "react";
import { ScopedVariablesSection } from "./ScopedVariablesSection";
import { sortContainers } from "./helpers";
import { ExistingContainersList } from "./ExistingContainersList";
import { NewContainersList } from "./NewContainersList";

export function DefaultFormSection({
    envContainers,
    stackContainers,
}: {
    envContainers?: Container[];
    stackContainers?: StackSpec["containers"];
}) {
    const sc = typeof stackContainers === "object" ? stackContainers : {};
    const { setValue, register } =
        useFormContext<StackBuildDeployAction["contents"]>();

    const sortedContainers = useMemo(
        () => sortContainers(envContainers, sc),
        [envContainers, sc]
    );

    useEffect(() => {
        if (sortedContainers?.existing) {
            const defaultValues = Object.entries(
                sortedContainers?.existing
            )?.reduce((acc, cur) => {
                return {
                    ...acc,
                    [cur[0]]: {
                        reimage: false,
                        reconfigure: false,
                    },
                };
            }, {} as Record<string, { reimage: boolean; reconfigure: boolean }>);
            setValue("update.containers", defaultValues);
        }
    }, [sortedContainers?.existing]);

    return (
        <>
            {Object.values(sortedContainers?.new || {}).length ? (
                <>
                    <FormSectionHeader header="New Containers" />
                    <FormSection>
                        <NewContainersList
                            newContainers={sortedContainers?.new}
                        />
                    </FormSection>
                </>
            ) : null}

            {Object.values(sortedContainers?.existing || {}).length ? (
                <>
                    <FormSectionHeader
                        header="Existing Containers"
                        help="Existing containers are detected if a container in the environment has the same identifier as the container in the stack spec."
                    />
                    <FormSection>
                        <ExistingContainersList
                            existingContainers={sortedContainers?.existing}
                        />
                    </FormSection>
                </>
            ) : null}

            <ScopedVariablesSection />
        </>
    );
}
