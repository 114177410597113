import { Controller, useFormContext } from "react-hook-form";
import { CreateBillingMethodFormType } from "../CreateBillingMethodForm";
import { PillButtons, RhfFormField } from "@cycleplatform/ui/components/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLockAlt } from "@fortawesome/pro-solid-svg-icons";
import { faStripe } from "@fortawesome/free-brands-svg-icons";

export function ToggleMethodType({ allowsDirect }: { allowsDirect?: boolean }) {
    const { control, unregister, setValue } =
        useFormContext<CreateBillingMethodFormType>();

    return (
        <RhfFormField name="method_type">
            <>
                <Controller
                    name={"method_type"}
                    control={control}
                    defaultValue="cc"
                    render={({ field: { ref: _ref, ...field } }) => {
                        return (
                            <PillButtons
                                {...field}
                                onChange={(v) => {
                                    field.onChange(v);
                                    if (v === "us_bank_acct") {
                                        unregister("credit_card");
                                        setValue("address.country", "US");
                                    } else {
                                        unregister("us_bank_acct");
                                    }
                                }}
                                options={[
                                    {
                                        value: "cc",
                                        label: "Credit Card",
                                    },
                                    {
                                        value: "us_bank_acct",
                                        label: "US Bank Account",
                                    },
                                    ...(allowsDirect
                                        ? [
                                              {
                                                  value: "direct_payment",
                                                  label: "Direct Payment",
                                              },
                                          ]
                                        : []),
                                ]}
                            />
                        );
                    }}
                />
                <div className="flex items-center gap-1 text-xs">
                    <FontAwesomeIcon icon={faLockAlt} />
                    <span>Powered by</span>
                    <a
                        tabIndex={-1}
                        href="https://stripe.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-2xl text-black dark:text-white"
                    >
                        <FontAwesomeIcon icon={faStripe} className="pt-2" />
                    </a>
                </div>
            </>
        </RhfFormField>
    );
}
