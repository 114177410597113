import { components } from "@cycleplatform/core/modules/api/__generated";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import {
    DnsRecord,
    useGetContainerQuery,
    useLookupComponentsQuery,
} from "~/services/cycle";
import classNames from "classnames";
import { useMemo } from "react";
import { HelpInfoCircle } from "@cycleplatform/ui/components/help";

export function HumanizedRouterDescription({
    router,
}: {
    router: components["schemas"]["V1LbConfigRouter"];
}) {
    const ports = router.match.internal_ports;
    const domains = router.match.domains;
    const path = router.match.path;

    const containersInclude = router.match.containers?.include || [];
    const containersExclude = router.match.containers?.exclude || [];

    const PortsString = () => {
        if (!ports || !ports.length) {
            return null;
        }

        return (
            <>
                listening on port
                {ports.length > 1 ? "s" : ""}{" "}
                <strong>{ports.join(", ")}</strong>
            </>
        );
    };

    const DomainsString = () => {
        if (!domains || !domains.length) {
            return <></>;
        }

        return (
            <>
                {" "}
                for <strong>{domains.join(", ")}</strong>
            </>
        );
    };

    const PathString = () => {
        if (!path || path === "") {
            return <></>;
        }
        return (
            <>
                , matching path <strong>{path}</strong>
            </>
        );
    };

    const ContainersString = () => {
        if (!containersInclude?.length && !containersExclude?.length) {
            return <>All ingress traffic </>;
        }

        const includedContainers = containersInclude.map((c, idx) => (
            <>
                {idx !== 0 ? ", " : " "} <ContainerItem containerId={c} />
            </>
        ));

        const excludedContainers = containersExclude.map((c, idx) => (
            <>
                {idx !== 0 ? ", " : " "} <ContainerItem containerId={c} />
            </>
        ));

        const IncludeString = () => {
            return <>{includedContainers}</>;
        };

        const ExcludeString = () => {
            return <>{excludedContainers}</>;
        };

        return (
            <>
                All ingress traffic
                {!!includedContainers?.length && (
                    <>
                        {` to container${
                            includedContainers.length > 1 ? "s" : ""
                        }`}{" "}
                        <IncludeString />
                    </>
                )}
                {!!excludedContainers?.length && (
                    <>
                        {`, excluding container${
                            excludedContainers.length > 1 ? "s" : ""
                        }`}{" "}
                        <ExcludeString />
                    </>
                )}
            </>
        );
    };

    return (
        <span>
            <ContainersString />
            <PortsString />
            <DomainsString />
            <PathString />
        </span>
    );
}

function ContainerItem({ containerId }: { containerId: string }) {
    const { data: container } = useGetContainerQuery({
        containerId,
        meta: ["domains"],
    });

    const domains = container?.data?.meta?.domains;

    const filteredDomains = useMemo(
        () =>
            domains?.filter(
                (d): d is { fqdn: string; record: DnsRecord } => !!d.record
            ) || [],
        [domains]
    );

    return (
        <div className="inline-block">
            {container?.data?.name ? (
                <strong>{container?.data?.name}</strong>
            ) : (
                <div
                    className={classNames(
                        skeletonStyles,
                        "mr-1 mt-1 inline-block h-[1rem] w-[8rem]"
                    )}
                />
            )}{" "}
            <HelpInfoCircle
                message={
                    <>
                        <div className="flex items-center gap-4 pb-1 text-sm">
                            LINKED Domains
                        </div>

                        {!filteredDomains || filteredDomains.length === 0 ? (
                            <>No domains associated with this container</>
                        ) : (
                            <>
                                {filteredDomains.map((d) => (
                                    <div>{d.fqdn}</div>
                                ))}
                            </>
                        )}
                    </>
                }
                className="!inline  "
            />{" "}
        </div>
    );
}
