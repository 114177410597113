import {
    faPlus,
    faQuestionCircle,
    faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { Activity, ComponentIncludes } from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import { PipelineKeyLink } from "~/components/common/resources/ResourceLinks";

export const formatPipelineKeyMessage = (
    item: Activity,
    components: ComponentIncludes
) => {
    const key = (
        <PipelineKeyLink components={components} keyId={item.component?.id} />
    );

    switch (item.event) {
        case "pipeline.key.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Created pipeline trigger key {key}</span>
                ) : (
                    <span>Error creating pipeline trigger key {key}</span>
                ),
                icon: faPlus,
            };
        case "pipeline.key.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted pipeline trigger key {key}</span>
                ) : (
                    <span>Error deleting pipeline trigger key {key}</span>
                ),
                icon: faTrash,
            };
        case "pipeline.key.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated pipeline trigger key {key}</span>
                ) : (
                    <span>Error updating pipeline trigger key {key}</span>
                ),
                icon: faTrash,
            };
        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
};
