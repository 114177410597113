import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import {
    AutoScaleGroup,
    GetAutoScaleGroupsApiArg,
    useGetAutoScaleGroupsQuery,
} from "~/services/cycle";
import { FormattedOption } from "@cycleplatform/ui/components/forms/select";
import { getAutoscaleGroupUniqueProviders } from "@cycleplatform/core/modules/infrastructure/autoscaling";
import { ResourceComboBox } from "~/components/common/forms";

type AutoScaleGroupSelectProps = {
    value?: string | null;
    disabled?: boolean;
    placeholder?: string;
    onChange: (
        groupId: string | undefined | null,
        autoScaleGroup: AutoScaleGroup | undefined
    ) => void;
    skip?: boolean;
    filter?: GetAutoScaleGroupsApiArg["filter"];
    filterFields: Array<keyof AutoScaleGroup>;
};

export function AutoScaleGroupSelect({
    value,
    disabled,
    onChange,
    placeholder = "None (Instance Scaling Only)",
    filter,
    skip,
    filterFields,
}: AutoScaleGroupSelectProps) {
    // TODO - there is a risk that the image set as 'value' is not on the list
    // returned by the query. May need an additional query for the specific image
    // if not initially returned and then merge them.
    const {
        data: asGroups,
        error,
        isLoading,
    } = useGetAutoScaleGroupsQuery(
        {
            // sort: ["-id"],
            filter,
            page: {
                // TODO - infinite scroll
                size: 100,
                number: 1,
            },
        },
        { skip }
    );

    if (error) {
        throw error;
    }

    return (
        <ResourceComboBox
            isLoading={isLoading}
            value={value || null}
            isNullable
            onChange={onChange}
            disabled={disabled}
            resources={
                // autoscale groups are selected by identifier
                asGroups?.data?.map((g) => ({ ...g, id: g.identifier })) || []
            }
            formatDisplayValue={(i) => {
                if (!i) return "";

                return i.name;
            }}
            filterFields={filterFields}
            placeholder={placeholder}
            formatOption={(a) => (
                <FormattedOption
                    label={`${a.name}`}
                    detail={
                        <>
                            <div>
                                Cluster: <strong>{a.cluster}</strong>
                            </div>
                            <div className="flex gap-2">
                                <div>
                                    <strong>
                                        {a.infrastructure.models.length}
                                    </strong>{" "}
                                    model
                                    {a.infrastructure.models.length !== 1
                                        ? "s"
                                        : ""}{" "}
                                    across{" "}
                                    <strong>
                                        {
                                            getAutoscaleGroupUniqueProviders(a)
                                                .length
                                        }
                                    </strong>{" "}
                                    providers
                                </div>{" "}
                                {a.scale?.up?.maximum !== undefined
                                    ? "/"
                                    : null}
                                {a.scale?.up?.maximum !== undefined && (
                                    <div>
                                        max:{" "}
                                        <strong>{a.scale?.up.maximum}</strong>
                                    </div>
                                )}
                            </div>
                        </>
                    }
                >
                    <ResourceStateBadge state={a.state} />
                </FormattedOption>
            )}
        />
    );
}
