import { RhfGlobalFormError } from "@cycleplatform/ui/components/forms";
import { faEdit, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { VirtualMachineDialogContex } from "../../context";
import { useFormContext } from "react-hook-form";
import { VmConfigFormData } from "./types";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import { AccessControlOverlay } from "~/components/common/buttons";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { SkeletonButton } from "@cycleplatform/ui/components/loaders/skeleton";

export function VmConfigFooter({
    isSubmitting,
    onSubmit,
}: {
    isSubmitting: boolean;
    onSubmit: (values: VmConfigFormData) => Promise<void> | undefined;
}) {
    const { vm, environment } = useContext(VirtualMachineDialogContex);

    const form = useFormContext<VmConfigFormData>();

    const {
        formState: { isDirty },
    } = form;

    return (
        <div className="dark:border-cycle-black-accent dark:bg-cycle-black absolute bottom-0 z-10 flex w-full items-center justify-between border-t bg-white px-2 py-4">
            <div className="flex w-full justify-between">
                <div className="flex items-center gap-2">
                    <FontAwesomeIcon
                        className="text-cycle-blue"
                        icon={faInfoCircle}
                    />
                    Updating the config will restart this virtual machine.
                </div>
                <div className="flex flex-1 items-center justify-end pr-4">
                    <RhfGlobalFormError />
                </div>
                {vm ? (
                    <>
                        <AccessControlOverlay
                            aclResource={environment}
                            verifyFn={modifyAccessFn("virtual-machines-manage")}
                        >
                            <PushAndHoldButton
                                disabled={!isDirty}
                                onClick={form.handleSubmit(onSubmit)}
                                type="button"
                                isLoading={isSubmitting}
                                flavor="primary"
                                icon={faEdit}
                            >
                                Save Virtual Machine
                            </PushAndHoldButton>
                        </AccessControlOverlay>
                    </>
                ) : (
                    <SkeletonButton size="lg" />
                )}
            </div>
        </div>
    );
}
