import {
    CreateIntegrationApiArg,
    IntegrationDefinitionField,
} from "~/services/cycle";
import {
    RhfFormField,
    TextInput,
    TextAreaInput,
    required,
    isRegExMatch,
} from "@cycleplatform/ui/components/forms";
import { Controller, useFormContext } from "react-hook-form";
import { AuthKey } from "@cycleplatform/core/modules/hub/integrations";
import { BasicSelect } from "@cycleplatform/ui/components/forms/select";
import { generateRandomString } from "@cycleplatform/core/util";

export function IntegrationDynamicFieldInput({
    k,
    section,
    field: f,
}: {
    k: AuthKey;
    section: "extra" | "auth";
    field?: IntegrationDefinitionField;
}) {
    const { register, control } =
        useFormContext<CreateIntegrationApiArg["body"]>();

    return (
        <RhfFormField
            label={f?.description}
            name={`${section}.${k}`}
            required={f?.required}
            key={`${section}.${k}`}
        >
            {f?.type === "select" ? (
                <Controller
                    control={control}
                    name={`${section}.${k}`}
                    shouldUnregister
                    render={({ field: { ref: _ref, ...field } }) => {
                        return (
                            <BasicSelect
                                {...field}
                                placeholder="Select an option"
                                options={
                                    f?.options?.map((o) => ({
                                        label: o,
                                        value: o,
                                    })) || []
                                }
                            />
                        );
                    }}
                />
            ) : f?.type === "int" ? (
                <TextInput
                    type="number"
                    {...register(`${section}.${k}`, {
                        ...(f?.required ? required() : {}),
                        validate: {
                            ...isRegExMatch(f?.regex),
                        },
                        setValueAs: (v) => (v === "" ? null : parseInt(v)),
                    })}
                />
            ) : f?.type === "blob" ? (
                <TextAreaInput
                    rows={10}
                    {...register(`auth.${k as AuthKey}`, {
                        ...(f?.required ? required() : {}),
                        validate: {
                            ...isRegExMatch(f?.regex),
                        },
                    })}
                />
            ) : (
                <TextInput
                    defaultValue={
                        f?.description === "Initialization Token"
                            ? generateRandomString(10)
                            : undefined
                    }
                    {...register(`${section}.${k}`, {
                        ...(f?.required ? required() : {}),
                        validate: {
                            ...isRegExMatch(f?.regex),
                        },
                    })}
                />
            )}
        </RhfFormField>
    );
}
