import { InfoPanel } from "@cycleplatform/ui/components/panels";
import { useMemo } from "react";
import {
    useGetBillingMethodsQuery,
    useGetBillingServicesQuery,
} from "~/services/cycle";

export const CREDIT_CARD_FEE_LIMIT_MILLS = 3_000_000;
export const CREDIT_CARD_FEE_PERCENTAGE = 2.8;

export function CreditCardFeeWarning({
    message,
}: {
    message: React.ReactNode;
}) {
    const { data: activeServices, error: servicesError } =
        useGetBillingServicesQuery({});

    const { data: methods, error: methodsError } = useGetBillingMethodsQuery(
        {}
    );

    const activeTierPrice = useMemo(
        () =>
            activeServices?.data?.reduce(
                (acc, cur) => acc + cur.price.mills,
                0
            ),
        [activeServices?.data]
    );

    const primaryPaymentMethod = useMemo(
        () => methods?.data?.find((m) => m.primary),
        [methods?.data]
    );

    if (servicesError) {
        throw servicesError;
    }

    if (methodsError) {
        throw methodsError;
    }

    if (!activeTierPrice || activeTierPrice <= CREDIT_CARD_FEE_LIMIT_MILLS) {
        return null;
    }

    if (primaryPaymentMethod?.source?.type !== "stripe-credit-card") {
        return null;
    }

    return <InfoPanel type="info">{message}</InfoPanel>;
}
