import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import { ResourceListItem } from "./ResourceListItem";
import classNames from "classnames";
import { useNavigationExpandedState } from "../hooks";

type ResourceListProps = {
    isLoading?: boolean;
    children?: React.ReactNode;
};

/**
 * A list of resource 'tiles' that go into the seconday navigation.
 */
export function ResourceList({ children, isLoading }: ResourceListProps) {
    const [isExpanded] = useNavigationExpandedState();

    return (
        <div
            className={classNames(
                "pl-4 pt-4",
                "h-full overflow-y-auto overflow-x-hidden bg-white pb-[60px] dark:bg-black",
                !isExpanded && "hidden 2xl:block",
                "shadow-3xl 2xl:shadow-none"
            )}
        >
            <ul className="flex w-full flex-wrap gap-2">
                {isLoading
                    ? Array.from({
                          length: 5,
                      }).map((_, idx) => (
                          <ResourceListItem
                              isLoading={isLoading}
                              to={""}
                              key={idx}
                              isActive={false}
                          >
                              <h3
                                  className={classNames(
                                      skeletonStyles,
                                      "h-4 w-full"
                                  )}
                              />
                          </ResourceListItem>
                      ))
                    : children}
            </ul>
        </div>
    );
}

/**
 *  We require a separation of concernts when handling grouped resource lists
 *  For grouped lists, wrap the whole list with ResourceListWithGrouping
 *  Handles the scroll and loading behavior
 */
export function ResourceListWithGrouping({
    children,
    isLoading,
}: ResourceListProps) {
    const [isExpanded] = useNavigationExpandedState();

    return (
        <div
            className={classNames(
                "pl-4 pt-4",
                "h-full overflow-y-auto overflow-x-hidden bg-white pb-[60px] dark:bg-black",
                !isExpanded && "hidden 2xl:block",
                "shadow-3xl 2xl:shadow-none"
            )}
        >
            {isLoading ? (
                <ul className="flex w-full flex-wrap gap-2">
                    {Array.from({
                        length: 5,
                    }).map((_, idx) => (
                        <ResourceListItem
                            isLoading={isLoading}
                            to={""}
                            key={idx}
                            isActive={false}
                        >
                            <h3
                                className={classNames(
                                    skeletonStyles,
                                    "h-4 w-full"
                                )}
                            />
                        </ResourceListItem>
                    ))}
                </ul>
            ) : (
                children
            )}
        </div>
    );
}

/**
 * ResourceListSubList is for grouped ResourceLists.  In this case, we just want to provide the <ul/> with no scrolling behavior baked in
 * The ResourceListWithGrouping will handle the scroll behavior (and will wrap multiple sublists)
 */
export function ResourceListSubList({ children }: ResourceListProps) {
    return <ul className="flex w-full flex-wrap gap-2">{children}</ul>;
}
