import { components } from "../api/__generated";

/**
 * Look up the base image from the GET API endpoint from a base image version identifier
 * @param identifier identifier of the VERSION of the base image to reverse lookup the base image for
 * @param baseImages the list of base images from Cycle
 * @returns a base image if one found, or undefined
 */
export function getBaseImageFromIdentifier(
    identifier: string,
    baseImages: {
        [key: string]: components["schemas"]["VirtualMachineBaseImage"];
    }
): components["schemas"]["VirtualMachineBaseImage"] | undefined {
    const baseImage = Object.values(baseImages).find((i) =>
        i.versions.find((vi) => vi.identifier === identifier)
    );

    return baseImage;
}
