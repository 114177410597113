import classNames from "classnames";
import { LabeledDataText } from "../text/LabeledDataText";

type PanelDetailProps = {
    label: string;
    children?: React.ReactNode;
    className?: string;
    isLoading?: boolean;
    block?: boolean;
    help?: string;
};

/**
 * Use this for listing details with a label on left and content on right.
 * Common for summary sections.
 */
export function PanelDetail({
    label,
    children,
    isLoading,
    className,
    block,
    help,
}: PanelDetailProps) {
    return (
        <LabeledDataText
            label={label}
            labelClassName="w-48"
            className={classNames(className, "mb-4")}
            isLoading={isLoading}
            block={block}
            help={help}
        >
            <div className="text-wrap break-words">{children}</div>
        </LabeledDataText>
    );
}
