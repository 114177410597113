import { FormattedMoney } from "@cycleplatform/ui/components/money";
import { useParams } from "react-router-dom";
import { BillingInvoice } from "~/services/cycle";
import { ResourceList, ResourceListItem } from "~/components/layout/resources";
import { DateFormats, formatDateString } from "@cycleplatform/core/util/time";
import { formatIdAsName } from "@cycleplatform/core/util";

type InvoicesListProps = {
    invoices?: BillingInvoice[];
};

export function InvoicesList({ invoices }: InvoicesListProps) {
    const { invoiceId } = useParams<{ invoiceId: string }>();

    return (
        <ResourceList isLoading={!invoices}>
            {invoices?.map((i) => (
                <ResourceListItem
                    to={`/billing/invoices/${i.id}`}
                    isActive={i.id === invoiceId}
                    key={i.id}
                    state={i.state}
                >
                    <div className="flex w-full justify-between">
                        <div>
                            {formatDateString(
                                i.events.created,
                                DateFormats["standardShort"]
                            )}{" "}
                            {formatIdAsName(i.id)}
                        </div>

                        <FormattedMoney value={i.charges} mills />
                    </div>
                </ResourceListItem>
            ))}
        </ResourceList>
    );
}
