import {
    RhfFormField,
    TextInput,
    FormSectionHeader,
    FormSection,
    InputRow,
} from "@cycleplatform/ui/components/forms";
import { Button, ButtonLink } from "@cycleplatform/ui/components/buttons";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useMemo, useState } from "react";
import { ContainerConfigFormData } from "../types";
import {
    Container,
    Environment,
    ScaleThresholdMetric,
    useGetEnvironmentMonitoringTiersQuery,
} from "~/services/cycle";
import { faPlus, faUp } from "@fortawesome/pro-solid-svg-icons";
import { BasicSelect } from "@cycleplatform/ui/components/forms/select";
import { ScalingTile } from "./ScalingTile";
import { ThresholdForm } from "./threshold/ThresholdForm";
import { DefaultThresholdValues } from "./util";
import { AutoScaleGroupSelect } from "~/components/infrastructure/autoscale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-duotone-svg-icons";
import infrastructureIcon from "@cycleplatform/ui/images/icons/gridless/infrastructure.svg";
import { TogglePanelContent } from "@cycleplatform/react-hook-form-components/components";
import { FeatureLimited } from "~/components/sales";
import { useTierFeature } from "~/modules/hubs/permissions/useTierFeature";
import { InfoPanel } from "@cycleplatform/ui/components/panels";
import { Link } from "react-router-dom";
import { useGetEffectiveMonitoringTier } from "~/components/environments/monitoring/hooks/useGetEffectiveMonitoringTier";
import { ThresholdLabel } from "@cycleplatform/core/modules/infrastructure/autoscaling";

export function ScalingSection({
    container,
    environment,
}: {
    container: Container;
    environment: Environment | undefined;
}) {
    const { register, control } = useFormContext<ContainerConfigFormData>();
    const canAutoScale = useTierFeature("autoscale");

    const tier = useGetEffectiveMonitoringTier(environment);

    return (
        <TogglePanelContent
            title="Auto-Scaling"
            field="config.scale"
            toggleDisabled={!canAutoScale}
            toggleTooltip={
                !canAutoScale
                    ? "Auto-Scaling is only available in the Standard Tier or higher"
                    : undefined
            }
            disabledFallback={(toggle) => (
                <div className="flex justify-center ">
                    <div className="ext-center flex max-w-xl flex-col items-center gap-4">
                        <h4>Auto-Scaling Disabled</h4>
                        <p>
                            Enable auto-scaling settings to automatically scale
                            instances of this container.
                        </p>
                        <Button flavor="primary" onClick={() => toggle()}>
                            Enable
                        </Button>
                    </div>
                </div>
            )}
        >
            {() => (
                <FeatureLimited
                    feature="autoscale"
                    render={<AutoScaleUnavailable />}
                >
                    <FormSectionHeader header="General" />
                    <FormSection>
                        <InfoPanel className="mb-4">
                            The platform will use the specified window interval
                            for auto-scaling unless it is less than the
                            environment’s monitoring tier granularity (
                            {tier?.metrics.container_telemetry_granularity ||
                                ""}
                            ), in which case the minimum will apply. Update
                            monitoring tier{" "}
                            <Link
                                to={`/environments/${environment?.id}/settings`}
                            >
                                here
                            </Link>
                            .
                        </InfoPanel>

                        <InputRow>
                            <RhfFormField
                                className="w-full"
                                label="Window"
                                name="config.scale.window"
                            >
                                <TextInput
                                    defaultValue="10m"
                                    {...register("config.scale.window")}
                                />
                            </RhfFormField>

                            <div className="relative w-full">
                                <RhfFormField
                                    className="w-full "
                                    label="Autoscale Group"
                                    name="config.scale.autoscale_group"
                                >
                                    <Controller
                                        name="config.scale.autoscale_group"
                                        control={control}
                                        render={({
                                            field: { ref: _ref, ...field },
                                        }) => (
                                            <AutoScaleGroupSelect
                                                {...field}
                                                filter={{
                                                    cluster:
                                                        container.environment
                                                            ?.cluster || "",
                                                }}
                                                filterFields={[
                                                    "name",
                                                    "cluster",
                                                ]}
                                            />
                                        )}
                                    />
                                </RhfFormField>
                                <a
                                    target="_blank"
                                    href="/infrastructure/autoscaling?dialog=autoscaling-group-create"
                                    className="absolute top-0 right-0 inline"
                                >
                                    Create Autoscale Group{" "}
                                    <FontAwesomeIcon icon={faExternalLink} />
                                </a>
                            </div>
                        </InputRow>
                    </FormSection>

                    <FormSectionHeader header="Instances" />
                    <FormSection>
                        <div className="flex gap-4">
                            <RhfFormField
                                className="w-full"
                                label="max"
                                name="config.scale.instances.max"
                                help="Maximum additional instances auto-scaler can generate"
                            >
                                <TextInput
                                    defaultValue={20}
                                    type="number"
                                    {...register("config.scale.instances.max", {
                                        valueAsNumber: true,
                                    })}
                                />
                            </RhfFormField>
                            <RhfFormField
                                className="w-full"
                                label="max instances per server"
                                name="config.scale.instances.max_server"
                            >
                                <TextInput
                                    defaultValue={5}
                                    type="number"
                                    {...register(
                                        "config.scale.instances.max_server",
                                        { valueAsNumber: true }
                                    )}
                                />
                            </RhfFormField>
                            <RhfFormField
                                className="w-full"
                                label="minimum time to live"
                                name="config.scale.instances.min_ttl"
                            >
                                <TextInput
                                    defaultValue={"10m"}
                                    {...register(
                                        "config.scale.instances.min_ttl"
                                    )}
                                />
                            </RhfFormField>
                        </div>
                    </FormSection>
                    <ScalingThresholds />
                </FeatureLimited>
            )}
        </TogglePanelContent>
    );
}

function ScalingThresholds() {
    const { control } = useFormContext<ContainerConfigFormData>();

    const {
        fields: thresholds,
        append,
        remove,
    } = useFieldArray({
        control,
        name: `config.scale.thresholds`,
    });

    const [newType, setNewType] = useState<string>();

    return (
        <>
            <FormSectionHeader header="Scaling Thresholds" />
            <FormSection>
                <div className="grid grid-cols-2 gap-4">
                    {thresholds.map((t, tIdx) => (
                        <ThresholdForm
                            t={t}
                            remove={(idx) => remove(idx)}
                            idx={tIdx}
                            thresholdIdx={tIdx}
                        />
                    ))}

                    <ScalingTile className="bg-cycle-white">
                        <RhfFormField label="Threshold Type" name="action">
                            <BasicSelect
                                options={[
                                    "ram",
                                    "cpu",
                                    "network-connections",
                                    "network-requests",
                                    "network-throughput",
                                ].map((v) => ({
                                    value: v,
                                    label: ThresholdLabel[
                                        v as ScaleThresholdMetric["type"]
                                    ],
                                    disabled: thresholds.some(
                                        (th) => th.type === v
                                    ),
                                }))}
                                onChange={(v) => (v ? setNewType(v) : null)}
                                value={newType}
                            />
                        </RhfFormField>
                        <div className="pt-2 text-end">
                            <Button
                                icon={faPlus}
                                disabled={!newType}
                                onClick={() => {
                                    const defaultScaleMetric =
                                        DefaultThresholdValues[
                                            newType as ScaleThresholdMetric["type"]
                                        ];
                                    append(defaultScaleMetric);
                                    setNewType("");
                                }}
                            >
                                Add
                            </Button>
                        </div>
                    </ScalingTile>
                </div>
            </FormSection>
        </>
    );
}

function AutoScaleUnavailable() {
    return (
        <div className="flex justify-center ">
            <div className="flex max-w-xl flex-col items-center gap-4 text-center">
                <h4 className="text-xl">
                    Upgrade your hub to access Auto-Scaling
                </h4>
                <p>
                    Auto-Scaling is only available in the{" "}
                    <strong>Startup Tier</strong> or higher.
                </p>
                <img className="max-w-sm" src={infrastructureIcon} />
                <ButtonLink flavor="primary" icon={faUp} to="/hub/plans">
                    Upgrade Hub Now
                </ButtonLink>
            </div>
        </div>
    );
}
