import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import {
    Environment,
    GetEnvironmentsApiArg,
    LookupComponentsApiArg,
    useGetEnvironmentsQuery,
    useLookupComponentsQuery,
} from "~/services/cycle";
import { ResourceComboBox } from "../common/forms";
import { FormattedOption } from "@cycleplatform/ui/components/forms/select";
import { Badge } from "@cycleplatform/ui/components/badges";
import { resourcesToResourceById } from "../common/resources/util";
import { useMemo } from "react";

type EnvironmentSelectProps<MULTI extends boolean = false> = {
    value: MULTI extends true ? Array<string> : string | undefined | null;
    isNullable?: boolean;
    onChange: MULTI extends true
        ? (ids: string[], environments: Environment[]) => void
        : (
              id: string | undefined | null,
              environment: Environment | undefined
          ) => void;
    skip?: boolean;
    placeholder?: string;
    multiple?: MULTI;
    disabled?: boolean;
    filter?: GetEnvironmentsApiArg["filter"];
    filterResult?: (es: Environment[]) => Environment[];
    required?: boolean;
    className?: string;
};

export function EnvironmentSelect<MULTI extends boolean = false>({
    value,
    onChange,
    skip,
    multiple,
    isNullable,
    placeholder,
    disabled,
    filter,
    filterResult = (envs) => envs,
    className,
}: EnvironmentSelectProps<MULTI>) {
    const { currentData: environments, error } = useGetEnvironmentsQuery(
        {
            sort: ["-about.favorite", "-id"],
            page: {
                number: 1,
                // TODO - infinite scroll
                size: 100,
            },
            filter,
        },
        { skip }
    );

    const envLookupInput = getEnvironmentComponentsInput(value);

    const { data: envComponents } = useLookupComponentsQuery(
        {
            body: {
                components: envLookupInput || [],
            },
        },
        { skip: !envLookupInput }
    );

    const mergedEnvs = useMemo(
        () =>
            Object.values({
                ...resourcesToResourceById(environments?.data || []),
                ...(envComponents?.data || {}),
            }),
        [environments?.data, envComponents?.data]
    );

    if (error) {
        throw error;
    }

    return (
        <ResourceComboBox
            className={className}
            filterFields={["id", "name"]}
            value={value}
            multiple={multiple}
            onChange={onChange}
            isNullable={isNullable}
            placeholder={placeholder || "No Environment Selected"}
            disabled={disabled}
            resources={mergedEnvs as Environment[]}
            formatDisplayValue={(e) => {
                if (!e) return "";

                return e.name;
            }}
            formatOption={(e) => {
                return (
                    <FormattedOption label={e.name}>
                        <div className="flex gap-2">
                            <Badge>{e.cluster}</Badge>
                            <ResourceStateBadge state={e.state} />
                        </div>
                    </FormattedOption>
                );
            }}
        />
    );
}

function getEnvironmentComponentsInput(
    value: Array<string> | string | undefined | null
): LookupComponentsApiArg["body"]["components"] | null {
    if (!value) {
        return null;
    }

    if (typeof value === "string") {
        return [{ type: "environment", id: value }];
    }

    return value.map((v) => ({ type: "environment", id: v }));
}
