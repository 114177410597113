import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transform } from "@fortawesome/fontawesome-svg-core";
import { CSSProperties } from "react";
import { faCircleExclamation } from "@fortawesome/pro-duotone-svg-icons";

export function StatusErrorIcon({ transform }: { transform?: Transform }) {
    return (
        <FontAwesomeIcon
            icon={faCircleExclamation}
            className="text-cycle-white text-xl"
            transform={transform}
            style={
                {
                    "--fa-secondary-opacity": "1",
                    "--fa-secondary-color": "rgb(211 34 53)",
                } as CSSProperties
            }
            fade
        />
    );
}
