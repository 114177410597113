import {
    CreateVirtualMachineApiArg,
    VirtualMachine,
    VirtualMachineConfig,
} from "~/services/cycle";
import { VmConfigFormData } from "./types";
import { useSmoothScroll } from "@cycleplatform/core/hooks";
import { useEffect } from "react";

export function getVmConfigDefaults(
    vm: VirtualMachine | undefined
): VmConfigFormData {
    if (!vm) {
        return {};
    }
    return {
        config: {
            network: vm.config.network,
            deploy: vm.config.deploy,
            resources: {
                ram: {
                    limit: parseInt(vm.config.resources.ram.limit),
                    allocate: vm.config.resources.ram.allocate,
                },
                cpu: {
                    ...vm.config.resources.cpu,
                    active: vm.config.resources.cpu.cores ? "cores" : "cpus",
                },
            },
        },
    };
}

export function getVmConfigBody(
    data: VmConfigFormData
): VirtualMachineConfig | null {
    if (!data.config) {
        return null;
    }

    return {
        network: data.config.network,
        deploy: data.config.deploy,
        resources: {
            ram: {
                allocate: data.config.resources.ram.allocate,
                limit: `${data.config.resources.ram.limit}G`,
            },
            cpu:
                data.config.resources.cpu.active === "cores"
                    ? { cores: data.config.resources.cpu.cores }
                    : { cpus: data.config.resources.cpu.cpus },
        },
    };
}
