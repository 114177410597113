import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { MeterDetail } from "@cycleplatform/ui/components/progress";
import { useFormContext, useWatch } from "react-hook-form";
import { useGetServerUsage } from "../../../helpers/hooks";
import { CreateServerSchema } from "../../../form";

export function HubUsageChart() {
    const { control } = useFormContext<CreateServerSchema>();
    const servers = useWatch({ name: "servers", control });
    const { included, current, hardCap, additional, isLoading } =
        useGetServerUsage(servers);

    return (
        <Panel>
            <PanelTitle title="Hub Usage" />
            <PanelContent>
                <h3 className="pb-2 text-center font-bold">Server Licences</h3>
                {!isLoading ? (
                    <MeterDetail
                        current={current + additional}
                        total={included}
                        unlimited={!hardCap}
                        resourceLabel="Servers"
                        totalLabel="Included"
                        overageLabel="Additional"
                    />
                ) : null}
            </PanelContent>
        </Panel>
    );
}
