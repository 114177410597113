import { FormField, TextAreaInput } from "@cycleplatform/ui/components/forms";
import { SkeletonText } from "@cycleplatform/ui/components/loaders/skeleton";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { useGetVirtualMachineBaseImagesQuery } from "~/services/cycle";
import { getBaseImageFromIdentifier } from "@cycleplatform/core/modules/vms/images";
import { Badge } from "@cycleplatform/ui/components/badges";
import { useContext } from "react";
import { VirtualMachineDialogContex } from "../../../context";

export function VmImagePanel() {
    const { vm } = useContext(VirtualMachineDialogContex);

    const {
        data: baseImages,
        error,
        isLoading,
    } = useGetVirtualMachineBaseImagesQuery(undefined, {
        skip: !vm || vm.image.type !== "base",
    });

    if (!vm || isLoading) {
        return (
            <Panel>
                <PanelTitle title="Image" />
                <PanelContent>
                    <FormField label="Source">
                        <SkeletonText size="lg" />
                    </FormField>
                    <FormField label="Details">
                        <SkeletonText size="lg" />
                    </FormField>
                </PanelContent>
            </Panel>
        );
    }

    switch (vm.image.type) {
        case "base":
            const bi = getBaseImageFromIdentifier(
                vm.image.details.identifier,
                baseImages?.data || {}
            );
            const version = bi?.versions.find(
                (v) =>
                    vm.image.type === "base" &&
                    v.identifier === vm.image.details.identifier
            )?.version;
            return (
                <Panel>
                    <PanelTitle title="Image" />
                    <PanelContent>
                        <FormField label="Source">Cycle Base Image</FormField>
                        <FormField label="OS">
                            {bi?.title} {version}{" "}
                            <Badge>{vm.image.details.identifier}</Badge>{" "}
                        </FormField>
                    </PanelContent>
                </Panel>
            );
        case "url":
            return (
                <Panel>
                    <PanelTitle title="Image" />
                    <PanelContent>
                        <FormField label="Source">Remote URL</FormField>
                        <FormField label="URL">
                            {vm.image.details.url}
                        </FormField>{" "}
                    </PanelContent>
                </Panel>
            );
        case "ipxe":
            return (
                <Panel>
                    <PanelTitle title="Image" />
                    <PanelContent>
                        <FormField label="Source">IPXE Script</FormField>
                        {vm.image.details.chain_url && (
                            <FormField label="Chain URL">
                                {vm.image.details.chain_url}
                            </FormField>
                        )}
                        {vm.image.details.script && (
                            <FormField label="Script">
                                <TextAreaInput
                                    rows={20}
                                    className="resize-none"
                                    readOnly
                                    value={vm.image.details.script}
                                />
                            </FormField>
                        )}
                    </PanelContent>
                </Panel>
            );
    }
}
