import { useFormContext, useWatch } from "react-hook-form";
import { V1LbRouterConfig } from "~/services/cycle";
import classNames from "classnames";
import { Badge } from "@cycleplatform/ui/components/badges";
import { Disclosure } from "@headlessui/react";
import { useSearchParams } from "react-router-dom";
import { getAllDialogSearchParams } from "~/components/dialogs/helpers";
import {
    routerExpandedParam,
    getExpandedIdentifier,
} from "../../../expandedParamHelpers";
import { HumanizedRouterDescription } from "~/components/environments/services/lb/HumanizedRouterDescription";
import { LoadBalancerFormValues } from "../../../../../form";
import { V1RouterMatch } from "./sections/V1RouterMatch";
import { V1RouterConfig } from "./sections/V1RouterConfig";
import { V1RouterAdvanced } from "./sections/V1RouterAdvanced";
import { V1RouterMode } from "./sections/V1RouterMode";
import { ExpandButton } from "./buttons/ExpandButton";
import { RemoveButton } from "./buttons/RemoveButton";

export function V1RouterForm({
    router,
    baseRoute,
    idx,
    mode,
    remove,
}: {
    router: V1LbRouterConfig;
    baseRoute: `config.details.controllers.${number}.transport.routers`;
    idx: number;
    mode: "tcp" | "udp" | "http";
    remove: (idx: number) => void;
}) {
    const { control } = useFormContext<LoadBalancerFormValues>();
    const [searchParams] = useSearchParams();
    const params = getAllDialogSearchParams<"environment-lb-manage">();
    const activePortParam = params["dialog-port"];

    const expandedIdentifier = getExpandedIdentifier(activePortParam, idx);

    const routerExpandedArr =
        searchParams.get(routerExpandedParam)?.split(",") || [];

    const formRouter = useWatch({ name: `${baseRoute}.${idx}`, control });

    return (
        <div className="w-full">
            <Disclosure
                defaultOpen={routerExpandedArr.includes(
                    getExpandedIdentifier(activePortParam, idx)
                )}
            >
                {({ open }) => (
                    <>
                        <div
                            className={classNames(
                                "dark:border-cycle-gray/50 dark:bg-cycle-black-accent rounded-t-lg border-x border-t bg-white text-left",
                                !open && "rounded-b-lg border-b "
                            )}
                        >
                            <div className="relative">
                                <div
                                    className={classNames(
                                        "flex w-full justify-between  px-4 py-6 text-left",
                                        !open ? "rounded-b-lg  " : "border-b"
                                    )}
                                >
                                    <div className="flex w-full items-center justify-between">
                                        <div className="flex max-w-[60%] items-center gap-16 break-words ">
                                            <HumanizedRouterDescription
                                                router={formRouter || router}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Absolute position because cannot have buttons nested in other buttons */}
                                <div className="absolute right-4 top-4 flex items-center gap-4 text-sm">
                                    <Badge className="bg-cycle-blue">
                                        {router.mode}
                                    </Badge>

                                    <ExpandButton
                                        open={open}
                                        expandedIdentifier={expandedIdentifier}
                                        routerExpandedArr={routerExpandedArr}
                                    />
                                    <RemoveButton
                                        idx={idx}
                                        remove={remove}
                                        expandedArr={routerExpandedArr}
                                        expandedIdentifier={expandedIdentifier}
                                    />
                                </div>
                            </div>
                            <Disclosure.Panel
                                className={classNames(
                                    "rounded-b-lg border-b p-4"
                                )}
                            >
                                <V1RouterMode baseRoute={baseRoute} idx={idx} />

                                <V1RouterMatch
                                    baseRoute={baseRoute}
                                    idx={idx}
                                />

                                <V1RouterConfig
                                    baseRoute={baseRoute}
                                    idx={idx}
                                    mode={mode}
                                />

                                <V1RouterAdvanced
                                    baseRoute={baseRoute}
                                    idx={idx}
                                    activePortParam={activePortParam}
                                />
                            </Disclosure.Panel>
                        </div>
                    </>
                )}
            </Disclosure>
        </div>
    );
}
