import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import { faDownload, faHammer } from "@fortawesome/pro-solid-svg-icons";
import { useCreateVirtualProviderIsoJobMutation } from "~/services/cycle";
import { useAppDispatch } from "~/hooks";
import { pushNotification } from "~/modules/notifications/slice";
import { useJobTracker } from "~/modules/jobs/hooks";
import classNames from "classnames";

export function IsoGenerateButton({
    integrationId,
    isoId,
    className,
    flavor,
}: {
    integrationId: string;
    isoId: string;
    className?: string;
    flavor?: "default" | "primary" | "discard";
}) {
    const [trackJob, { isTrackingJob }] = useJobTracker();
    const [generateIso] = useCreateVirtualProviderIsoJobMutation();
    const dispatch = useAppDispatch();

    return (
        <PushAndHoldButton
            isLoading={isTrackingJob}
            icon={faHammer}
            className={classNames(className, "text-sm")}
            flavor={flavor}
            onClick={async () => {
                return trackJob(
                    generateIso({
                        integrationId,
                        isoId: isoId,
                        virtualProviderIsoTask: {
                            action: "generate",
                        },
                    }).unwrap()
                ).catch((err) =>
                    dispatch(
                        pushNotification({
                            title: "Error generating ISO",
                            message: `${err?.data?.error?.title}`,
                            type: "error",
                        })
                    )
                );
            }}
        >
            Generate
        </PushAndHoldButton>
    );
}
