import { Badge } from "@cycleplatform/ui/components/badges";
import classNames from "classnames";

type BetaBadgeProps = {
    labelOverride?: string;
    className?: string;
};

export function BetaBadge({ className, labelOverride }: BetaBadgeProps) {
    return (
        <Badge
            className={classNames("!bg-cycle-blue ml-4 font-bold", className)}
        >
            {labelOverride || "BETA"}
        </Badge>
    );
}
