import {
    FormField,
    FormSection,
    FormSectionHeader,
    InputRow,
    PillButtons,
    RhfFormField,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { Button } from "@cycleplatform/ui/components/buttons";
import { faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { useFieldArray, useFormContext } from "react-hook-form";
import { IsoCustomizeTextInput } from "../inputs/IsoCustomizeTextInput";
import { useState } from "react";
import { GenerateIsoFormType } from "../types";

export function IsoServerFormSection() {
    const { control } = useFormContext<GenerateIsoFormType>();

    const {
        fields: nics,
        remove,
        append,
    } = useFieldArray({
        control,
        name: `config.server.nics`,
    });

    return (
        <>
            <FormSectionHeader header="Server" />
            <FormSection>
                <div className=" flex flex-col gap-4 pb-4">
                    {nics?.map((_, idx) => {
                        return (
                            <div className="bg-cycle-white rounded-md border p-4">
                                <div className="flex w-full items-center justify-between pb-4">
                                    <h3
                                        className={" text-lg"}
                                    >{`Network Interface ${idx + 1}`}</h3>

                                    <Button
                                        className="h-[37px]"
                                        icon={faTrash}
                                        flavor="discard"
                                        onClick={() => remove(idx)}
                                    />
                                </div>

                                <MatchSection
                                    baseRoute={`config.server.nics.${idx}.match`}
                                />
                                <IsoCustomizeTextInput
                                    field={`config.server.nics.${idx}.vlan_id`}
                                    label="VLAN ID"
                                />

                                <DHCPInput
                                    baseRoute={`config.server.nics.${idx}`}
                                />
                            </div>
                        );
                    })}
                </div>
                <div className="flex w-full">
                    <Button
                        className="w-full"
                        icon={faPlus}
                        onClick={() => append({ match: {} })}
                    >
                        Add Network Interface
                    </Button>
                </div>
            </FormSection>
        </>
    );
}

function DHCPInput({
    baseRoute,
}: {
    baseRoute: `config.server.nics.${number}`;
}) {
    const [pillValue, setPillValue] = useState("dhcp");

    const { register, setValue } = useFormContext<GenerateIsoFormType>();

    return (
        <div className="flex gap-4">
            <FormField className="!w-80" label={`IPXE IP`}>
                <PillButtons
                    className="pt-2"
                    value={pillValue}
                    options={[
                        { label: "DHCP", value: "dhcp" },
                        { label: "Static", value: "static" },
                    ]}
                    onChange={(v) => {
                        if (v === "dhcp") {
                            setValue(`${baseRoute}.static_ip`, null, {
                                shouldDirty: true,
                            });
                            setValue(`${baseRoute}.netmask`, null, {
                                shouldDirty: true,
                            });
                            setValue(`${baseRoute}.gateway_ip`, null, {
                                shouldDirty: true,
                            });
                        }
                        setPillValue(v);
                    }}
                />
            </FormField>

            {pillValue === "static" ? (
                <div className="w-full">
                    <InputRow>
                        <RhfFormField
                            label={"Static Ip"}
                            name={`${baseRoute}.static_ip`}
                        >
                            <TextInput
                                {...register(`${baseRoute}.static_ip`)}
                            />
                        </RhfFormField>

                        <RhfFormField
                            label={"Netmask"}
                            name={`${baseRoute}.netmask`}
                        >
                            <TextInput {...register(`${baseRoute}.netmask`)} />
                        </RhfFormField>
                    </InputRow>

                    <RhfFormField
                        label={"Gateway Ip"}
                        name={`${baseRoute}.gateway_ip`}
                    >
                        <TextInput {...register(`${baseRoute}.gateway_ip`)} />
                    </RhfFormField>
                </div>
            ) : (
                <div className="w-full">
                    <InputRow>
                        <FormField label={"Static Ip"}>
                            <TextInput disabled />
                        </FormField>
                        <FormField label={"Netmask"}>
                            <TextInput disabled />
                        </FormField>
                    </InputRow>
                    <FormField label={"Gateway Ip"}>
                        <TextInput disabled />
                    </FormField>
                </div>
            )}
        </div>
    );
}

function MatchSection({
    baseRoute,
}: {
    baseRoute: `config.server.nics.${number}.match`;
}) {
    const [pillValue, setPillValue] = useState("name");

    const { register, setValue } = useFormContext<GenerateIsoFormType>();

    return (
        <div className="flex gap-4">
            <FormField className="!w-80" label={`Match`}>
                <PillButtons
                    className="pt-2"
                    value={pillValue}
                    options={[
                        { label: "Name", value: "name" },
                        { label: "Address", value: "address" },
                    ]}
                    onChange={(v) => {
                        if (v === "address") {
                            setValue(`${baseRoute}.interface_name`, null, {
                                shouldDirty: true,
                            });
                        } else {
                            setValue(`${baseRoute}.mac_address`, null, {
                                shouldDirty: true,
                            });
                        }
                        setPillValue(v);
                    }}
                />
            </FormField>

            {pillValue === "name" ? (
                <RhfFormField
                    label={"Interface Name"}
                    name={`${baseRoute}.interface_name`}
                    required
                >
                    <TextInput {...register(`${baseRoute}.interface_name`)} />
                </RhfFormField>
            ) : (
                <RhfFormField
                    label={"Mac Address"}
                    name={`${baseRoute}.mac_address`}
                    required
                >
                    <TextInput {...register(`${baseRoute}.mac_address`)} />
                </RhfFormField>
            )}
        </div>
    );
}
