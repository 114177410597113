import { Cluster, Role, useGetClusterQuery } from "~/services/cycle";
import {
    AclResource,
    VerifyFn,
    verifyAccess,
} from "@cycleplatform/core/modules/acls/util";
import { useGetMemberRole } from "./hubs/permissions/useGetMemberRole";

export function useVerifyAccess<T extends AclResource>(
    aclResource: T | undefined,
    verifyFn: VerifyFn<T>
): Error | undefined {
    const { role, error: roleError } = useGetMemberRole();
    const { data: cluster, isLoading: clusterIsLoading } = useGetClusterQuery(
        { clusterId: aclResource?.cluster || "" },
        { skip: !aclResource?.cluster }
    );

    if (roleError) {
        throw roleError;
    }

    if (!role || clusterIsLoading || !aclResource) {
        return undefined;
    }

    return verifyAccess({
        aclResource,
        verifyFn,
        role,
        cluster: cluster?.data,
    });
}
