import { DateTimeFormats, formatDateString } from "@cycleplatform/core/util";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TooltipProps } from "recharts";
import {
    NameType,
    ValueType,
} from "recharts/types/component/DefaultTooltipContent";

type CycleBarTooltipProps = {
    formatValue?: (v: number) => string;
    labelMap?: Record<string, string>;
    /** if not set, values equal to zero will be hidden */
    showZeroValues?: boolean;
};

export const CycleBarTooltip = ({
    active,
    payload,
    label,
    formatValue = (v: number) => `${v}`,
    labelMap,
    showZeroValues,
}: TooltipProps<ValueType, NameType> & CycleBarTooltipProps) => {
    if (active && payload && Array.isArray(payload) && payload.length) {
        return (
            <div className="dark:bg-cycle-black-accent dark:text-cycle-white rounded-md border bg-white ">
                <p className="label bg-cycle-blue rounded-t-md p-2 text-sm text-white">
                    {formatDateString(
                        new Date(label as string),
                        DateTimeFormats["standard"]
                    )}
                </p>
                <div className="flex flex-col px-2 py-1 ">
                    {payload?.map((p) => {
                        const dk = p.dataKey as string;
                        // dont show values if they're hidden
                        if (!showZeroValues && p.value === 0) {
                            return null;
                        }
                        return (
                            <div
                                className="flex items-center gap-2 text-sm"
                                key={dk}
                            >
                                <FontAwesomeIcon
                                    key={p.dataKey as string}
                                    icon={faCircle}
                                    color={
                                        // (p.fill as string) ||
                                        p.color as string
                                    }
                                    className="w-3"
                                />
                                {labelMap?.[dk] || dk.replace("_", " ")} [
                                {formatValue(p.value as number)}]
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    return null;
};
