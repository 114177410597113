import { useContext } from "react";
import { VirtualMachineDialogContex } from "../../../context";
import {
    InfoPanel,
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { Controller, useForm } from "react-hook-form";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { useKeepFormCurrent } from "~/components/common/forms";
import { useUpdateVirtualMachineMutation } from "~/services/cycle";
import {
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import { vmSshKeyAccessFn } from "@cycleplatform/core/modules/vms/acls";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { generateDialogLink } from "~/components/dialogs/helpers";
import { faKey, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { VmSshKeySelect } from "~/components/vms/forms";
import { AccessControlOverlay } from "~/components/common/buttons";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";

export function VmUpdateSshKeyPanel() {
    const { vm, environment } = useContext(VirtualMachineDialogContex);
    const form = useForm<{ sshKeys: string[] }>({
        defaultValues: {
            sshKeys: vm?.ssh_keys || [],
        },
        ...rhfConfig,
    });

    useKeepFormCurrent(form, vm, (vm) => ({
        sshKeys: vm.ssh_keys || [],
    }));

    const {
        control,
        handleSubmit,
        formState: { isDirty, isSubmitting },
    } = form;

    const [updateVirtualMachine] = useUpdateVirtualMachineMutation();

    const onSubmit = async (data: { sshKeys: string[] }) => {
        if (!vm) {
            return;
        }

        return updateVirtualMachine({
            virtualMachineId: vm.id,
            body: {
                ssh_keys: data.sshKeys,
            },
        })
            .unwrap()
            .then(() => form.reset(), handleSubmitError(form.setError));
    };

    return (
        <Panel>
            <RhfFormProvider {...form}>
                <PanelTitle title="SSH Keys" />
                <PanelContent>
                    <InfoPanel>
                        SSH key changes takes effect on next restart.
                    </InfoPanel>
                    <RhfFormField
                        label="Applied SSH Keys"
                        name="sshKeys"
                        tooltip={
                            <Link
                                to={generateDialogLink(
                                    "virtual-machines-ssh-key-create",
                                    {
                                        "dialog-nav": `?${generateDialogLink(
                                            "virtual-machine",
                                            {
                                                "dialog-id": vm?.id,
                                                "dialog-tab": "settings",
                                            }
                                        ).search?.toString()}`,
                                    }
                                )}
                            >
                                <FontAwesomeIcon icon={faPlus} /> Add
                            </Link>
                        }
                        labelClassName="flex w-full justify-between"
                    >
                        <Controller
                            name={`sshKeys`}
                            control={control}
                            render={({ field: { ref: _ref, ...field } }) => (
                                <VmSshKeySelect
                                    {...field}
                                    multiple
                                    filter={{
                                        environment: vm?.environment.id,
                                    }}
                                    onChange={(id) => {
                                        if (!id) {
                                            return;
                                        }
                                        field.onChange(id);
                                    }}
                                />
                            )}
                        />
                    </RhfFormField>

                    <PanelFooter>
                        <div>
                            <RhfGlobalFormError />
                        </div>
                        <AccessControlOverlay
                            aclResource={environment}
                            verifyFn={
                                vm ? vmSshKeyAccessFn(vm) : () => undefined
                            }
                        >
                            <PushAndHoldButton
                                isLoading={isSubmitting}
                                icon={faKey}
                                onClick={handleSubmit(onSubmit)}
                                type="button"
                                tooltip={"hold to update SSH keys"}
                                flavor="primary"
                                disabled={!isDirty}
                            >
                                Update SSH Keys
                            </PushAndHoldButton>
                        </AccessControlOverlay>
                    </PanelFooter>
                </PanelContent>
            </RhfFormProvider>
        </Panel>
    );
}
