import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transform } from "@fortawesome/fontawesome-svg-core";
import { faBan } from "@fortawesome/pro-duotone-svg-icons";

export function StatusDisabledIcon({ transform }: { transform?: Transform }) {
    return (
        <FontAwesomeIcon
            icon={faBan}
            className="text-cycle-gray-accent dark:text-cycle-gray-light/60 text-xl"
            transform={transform}
        />
    );
}
