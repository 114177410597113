import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transform } from "@fortawesome/fontawesome-svg-core";
import { faCircleCheck } from "@fortawesome/pro-duotone-svg-icons";
import { CSSProperties } from "react";

export function StatusOkIcon({ transform }: { transform?: Transform }) {
    return (
        <FontAwesomeIcon
            icon={faCircleCheck}
            className="text-cycle-white dark:text-cycle-black text-xl"
            transform={transform}
            style={
                {
                    "--fa-secondary-opacity": "1",
                    "--fa-secondary-color": "rgb(34 211 104)",
                } as CSSProperties
            }
        />
    );
}
