// Common XTerm styles are set in styles/xterm.css
import { Panel, PanelTitle } from "@cycleplatform/ui/components/panels";
import { useCallback, useEffect, useRef } from "react";
import { useTerminal } from "~/components/common/terminal/useTerminal";
import {
    Instance,
    GetInstanceConsoleStreamAuthApiResponse,
    InstanceState,
    useGetInstanceConsoleStreamAuthQuery,
} from "~/services/cycle";
import {
    AccessControlOverlay,
    ConsoleButton,
} from "~/components/common/buttons";
import {
    faCopy,
    faRepeat,
    faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { ContainerDialogSearchParams } from "../searchparams";
import { useSearchParams } from "react-router-dom";
import { Terminal } from "xterm";
import { colorTerminalLine } from "~/util/term";
import { isBefore, addMinutes } from "date-fns";
import { $error, $info, $trace, $warn } from "@cycleplatform/core/util/log";
import ansiEscapes from "ansi-escapes";
import { isCycleApiError } from "~/services/helpers";
import {
    PLATFORM_SOCKET_HEARTBEAT_MESSAGE,
    PLATFORM_SOCKET_HEARTBEAT_TIMEOUT,
} from "@cycleplatform/core/modules/websocket";
import { PanelContentBoundary } from "~/components/layout/panels/PanelContentBoundary";

type InstanceConsoleProps = {
    instance: Instance;
};

export function InstanceConsole({ instance }: InstanceConsoleProps) {
    const [searchParams] = useSearchParams();
    const instanceIdParam = searchParams.get(
        ContainerDialogSearchParams.instanceId
    );

    const { containerRef, terminal } = useTerminal();
    const refetch = useConnectInstanceStreamToConsole(instance, terminal);

    useEffect(() => {
        terminal.reset();
    }, [instanceIdParam]);

    const copyToClipboard = useCallback(() => {
        if (!terminal.hasSelection()) {
            terminal.selectAll();
        }
        const data = terminal.getSelection();
        terminal.clearSelection();
        navigator.clipboard.writeText(data);
    }, [terminal]);

    return (
        <Panel>
            <PanelTitle
                title="Instance Console"
                className="flex items-center justify-between"
            >
                <div className="flex gap-2 font-normal lowercase ">
                    <AccessControlOverlay capability={"containers-console"}>
                        <ConsoleButton
                            onClick={() => {
                                terminal.reset();
                                refetch();
                            }}
                            icon={faRepeat}
                        >
                            Reset Connection
                        </ConsoleButton>
                    </AccessControlOverlay>
                    <AccessControlOverlay capability={"containers-console"}>
                        <ConsoleButton
                            onClick={() => terminal.clear()}
                            icon={faTimesCircle}
                        >
                            Clear
                        </ConsoleButton>
                    </AccessControlOverlay>
                    <AccessControlOverlay capability={"containers-console"}>
                        <ConsoleButton onClick={copyToClipboard} icon={faCopy}>
                            Copy
                        </ConsoleButton>
                    </AccessControlOverlay>
                </div>
            </PanelTitle>
            <PanelContentBoundary
                stretch
                capability="containers-console"
                className="!bg-black"
            >
                <div
                    onClick={(e) => e.stopPropagation()}
                    className="h-full w-full p-2"
                    ref={containerRef}
                />
            </PanelContentBoundary>
        </Panel>
    );
}

/**
 * Connects to the instance stream and plugs it into the terminal,
 * handling writing to output, errors, etc.
 * This is not piped through redux (like instance telemetry) for
 * performance reasons.
 * @param creds
 * @param terminal
 * @returns
 */
function useConnectInstanceStreamToConsole(
    instance: Instance,
    terminal: Terminal
) {
    const socket = useRef<WebSocket>();
    const {
        currentData: creds,
        error,
        isLoading,
        refetch,
    } = useGetInstanceConsoleStreamAuthQuery(
        {
            containerId: instance.container_id,
            instanceId: instance.id,
        },
        {
            refetchOnFocus: false,
            refetchOnReconnect: true,
            skip: !window.navigator.onLine || !shouldReconnect(instance),
        }
    );

    const refetchInterval = useRef<NodeJS.Timer | undefined>(undefined);

    const refetchCountdown = () => {
        let countdown = 5;
        clearInterval(refetchInterval.current);
        // terminal.write(saveCursorPosition());
        refetchInterval.current = setInterval(() => {
            terminal.write(ansiEscapes.eraseLine);
            terminal.write(ansiEscapes.cursorLeft);
            terminal.write(`Reconnecting in ${countdown}...`);
            countdown--;
            if (countdown < 0) {
                clearInterval(refetchInterval.current);
                terminal.write(ansiEscapes.cursorLeft);
                terminal.write(ansiEscapes.eraseLine);
                refetch();
            }
        }, 1000);
    };

    useEffect(() => {
        if (error) {
            if (isCycleApiError(error)) {
                $trace(
                    "[instance console]: received error from Cycle while attempting to fetch credentials"
                );
                terminal.writeln(
                    colorTerminalLine(
                        `⚠ Error fetching console credentials: \n${JSON.stringify(
                            error.data.error,
                            null,
                            2
                        )}`,
                        "red",
                        { bold: true }
                    )
                );
                terminal.writeln(
                    colorTerminalLine(
                        "\nTo try again, click 'Reset Connection' above. If the error persists, please contact support.",
                        "gray"
                    )
                );
            } else {
                $trace(
                    "[instance console]: received unknown error while attempting to fetch credentials"
                );
                terminal.writeln(
                    colorTerminalLine(
                        `⚠ An unknown error occurred while fetching console credentials`,
                        "red",
                        { bold: true }
                    )
                );
                terminal.writeln(
                    colorTerminalLine(
                        "\nTo try again, click 'Reset Connection' above. If the error persists, please contact support.",
                        "gray"
                    )
                );
            }
            return;
        }

        if (!creds?.data) {
            if (!window.navigator.onLine) {
                $trace(
                    "[instance console]: detected offline status while fetching credentials"
                );
                writeOfflineMessage(terminal);
            } else {
                if (!shouldReconnect(instance)) {
                    $trace("[instance console]: should not reconnect");

                    const message =
                        instance.state.current === "new"
                            ? "Instance has never been started."
                            : "Instance has been in an offline state for too long.";

                    terminal.writeln(
                        colorTerminalLine(
                            `Console currently unavailable: ${message}`,
                            "darkgray",
                            {
                                bold: true,
                            }
                        )
                    );
                }
            }

            if (isLoading) {
                $trace("[instance console]: fetching credentials");
                terminal.writeln(
                    colorTerminalLine("⏳ Fetching credentials...", "blue", {
                        bold: true,
                    })
                );
            }
            return;
        }

        $trace(
            "[instance console]: credentials received, connecting to console"
        );

        let heartbeatTimeout: NodeJS.Timeout;
        const makeHbTimeout = () => {
            return setTimeout(() => {
                $warn(
                    `[instance console]: heartbeat timeout on console socket for instance ${instance.id} - closing socket`
                );

                socket.current?.close();
            }, PLATFORM_SOCKET_HEARTBEAT_TIMEOUT);
        };

        onPreconnect(terminal);
        socket.current = new WebSocket(getInstanceStreamUrl(creds.data));
        socket.current.onopen = (ev) => {
            heartbeatTimeout = makeHbTimeout();
            return onConnect(terminal)(ev);
        };
        socket.current.onerror = onError(terminal, instance.state.current);
        socket.current.onclose = (ev) => {
            clearTimeout(heartbeatTimeout);
            return onClose(
                terminal,
                instance.state.current,
                refetchCountdown
            )(ev);
        };
        socket.current.onmessage = (ev) => {
            if (
                typeof ev.data === "string" &&
                ev.data === PLATFORM_SOCKET_HEARTBEAT_MESSAGE
            ) {
                clearTimeout(heartbeatTimeout);
                heartbeatTimeout = makeHbTimeout();
                return;
            }
            onMessage(terminal)(ev);
        };

        const heartbeat = setInterval(() => {
            try {
                if (socket.current?.readyState === 1) {
                    socket.current?.send(PLATFORM_SOCKET_HEARTBEAT_MESSAGE);
                }
            } catch (e) {
                $trace("unable to send heartbeat to instance console socket");
                socket.current?.close();
            }
        }, 15_000);

        return () => {
            $trace("[instance console]: disconnecting from instance console");
            clearInterval(heartbeat);
            clearTimeout(heartbeatTimeout);
            clearInterval(refetchInterval.current);
            if (!socket.current) {
                return;
            }
            if (socket.current.readyState === 1) {
                socket.current.close();
            }
            socket.current.onerror = null;
            socket.current.onclose = null;
            socket.current.onmessage = null;
            socket.current = undefined;
        };
    }, [creds, error]);

    return refetch;
}

function getInstanceStreamUrl(
    creds: GetInstanceConsoleStreamAuthApiResponse["data"]
): URL {
    const url = new URL(creds.address);
    url.searchParams.set("token", creds.token);

    return url;
}

function shouldReconnect(instance: Instance) {
    // New will have NO data.
    if (instance.state.current === "new") {
        return false;
    }

    const failedStates: readonly InstanceState["current"][] = [
        "deleting",
        "deleted",
        "stopped",
        "failed",
    ] as const;

    const isFailedState = failedStates.includes(instance.state.current);
    if (!isFailedState) {
        return true;
    }

    $trace(
        `[instance console]: instance in failed state - ${instance.state.current}`
    );

    // instance console buffer is only kept for 15 minutes, so after that if it's in one of these
    // states there is no point trying to connect.
    const lastStateChange = new Date(instance.state.changed);
    const bufferExpiration = addMinutes(lastStateChange, 15);
    const isExpired = isBefore(bufferExpiration, new Date());

    $trace(
        `[instance console]: instance state last change: ${lastStateChange}`
    );
    $trace(`[instance console]: buffer would expire at ${bufferExpiration}}`);
    $trace(`[instance console]: is expired - ${isExpired}`);

    return !isExpired;
}

function onPreconnect(terminal: Terminal) {
    terminal.clear();
    terminal.write(ansiEscapes.cursorSavePosition);
    terminal.write(
        colorTerminalLine(`⏳ Connecting to instance console...\n`, "blue", {
            bold: true,
        })
    );
}

const onConnect = (terminal: Terminal) => (_: Event) => {
    $info("connected to instance console");
    terminal.reset();
    try {
        terminal.writeln(
            colorTerminalLine("✅  Connected to instance console\n", "green", {
                bold: true,
            })
        );
    } catch (e) {
        $warn(`error writing to terminal`, e);
    }
};

const onError =
    (terminal: Terminal, currentState: InstanceState["current"]) =>
    (_: Event) => {
        $error("instance console connection failure");
        try {
            if (currentState === "starting") {
                return;
            }
            terminal.writeln(
                colorTerminalLine("⚠ Connection to console failed", "red", {
                    bold: true,
                })
            );
        } catch (e) {
            $warn(`error writing to terminal`, e);
        }
    };

const onClose =
    (
        terminal: Terminal,
        currentState: InstanceState["current"],
        refetch: () => void
    ) =>
    (_: CloseEvent) => {
        $trace("connection to instance console closed");
        const isStarting = currentState === "starting";
        try {
            if (isStarting) {
                terminal.writeln(
                    colorTerminalLine("\nInstance is starting", "green", {
                        bold: true,
                    })
                );
            } else {
                terminal.writeln(
                    colorTerminalLine(
                        "\n ⚠ Console connection was closed",
                        "red",
                        {
                            bold: true,
                        }
                    )
                );
            }
        } catch (e) {
            $warn(`error writing to terminal`, e);
        }

        if (!window.navigator.onLine) {
            writeOfflineMessage(terminal);
        } else {
            try {
                if (!isStarting) {
                    terminal.writeln(
                        colorTerminalLine("\nPossible Reasons", "blue", {
                            bold: true,
                        })
                    );

                    terminal.writeln(
                        " - Instance is currently in a error state"
                    );
                    terminal.writeln(
                        " - Instance has not been started recently"
                    );
                    terminal.writeln(
                        " - Instance is outputting binary to console\n"
                    );
                }
            } catch (e) {
                $warn(`error writing to terminal`, e);
            }
        }

        try {
            terminal.write(ansiEscapes.cursorRestorePosition);
        } catch (e) {
            $warn(`error writing to terminal`, e);
        }

        refetch();
    };

const onMessage = (terminal: Terminal) => (ev: MessageEvent) => {
    // eslint-disable-next-line no-control-regex
    const json = ev.data.replace(/\u0000/g, "");

    for (const line of json.split("\n")) {
        try {
            terminal.writeln(line);
        } catch (e) {
            $warn(`error writing to terminal`, e);
        }
    }

    try {
        terminal.write(ansiEscapes.cursorSavePosition);
    } catch (e) {
        $warn(`error writing to terminal`, e);
    }
};

function writeOfflineMessage(terminal: Terminal) {
    terminal.writeln(
        colorTerminalLine(
            "\nIt appears you are offline. Once an internet connection is detected, the console will attempt to reconnect to the instance.",
            "red"
        )
    );
}
