import { usePagination } from "@cycleplatform/ui/hooks";
import { useAppSelector } from "~/hooks";
import { selectAppliedTheme } from "~/modules/settings";
import { useGetInvoicesQuery } from "~/services/cycle";
import { CycleErrorBoundary } from "~/components/common/errors";
import { InvoiceHistoryChart } from "./charts/InvoiceHistoryChart";

export function InvoiceHistoryContent() {
    const pagination = usePagination(5);

    const {
        data: invoices,
        error: invoicesError,
        isLoading,
    } = useGetInvoicesQuery({
        sort: ["-id"],
        meta: ["due"],
        page: pagination,
    });
    const theme = useAppSelector(selectAppliedTheme);

    if (invoicesError) {
        throw invoicesError;
    }
    return (
        <CycleErrorBoundary>
            <div className="h-60">
                <InvoiceHistoryChart
                    invoices={invoices?.data}
                    theme={theme}
                    isLoading={isLoading}
                />
            </div>
        </CycleErrorBoundary>
    );
}
