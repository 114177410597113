import { LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
    TextInput,
    required,
    FormSectionHeader,
    FormSection,
    hasNoSpaces,
} from "@cycleplatform/ui/components/forms";
import { handleSubmitError } from "~/components/forms/util";
import { BasicSelect } from "@cycleplatform/ui/components/forms/select";
import { faEdit, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { clearDialogParams } from "~/components/dialogs/helpers";
import {
    DnsRecord,
    DnsRecordTypes,
    DnsZone,
    useCreateDnsZoneRecordMutation,
    useGetDnsZoneQuery,
    useUpdateDnsZoneRecordMutation,
} from "~/services/cycle";
import { useKeepFormCurrent } from "~/components/common/forms";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";
import { LinkedRecordDetails } from "./records";
import { InfoPanel } from "@cycleplatform/ui/components/panels";

type DnsRecordFormProps = {
    record?: DnsRecord;
    zone?: DnsZone;
};

type FormValues = {
    zoneId?: string;
    recordId?: string;
    body: {
        name?: string;
        type: DnsRecordTypes;
    };
};
function getDefaultValues(
    record: DnsRecord | undefined,
    zoneId?: string | null
) {
    return structuredClone({
        zoneId: zoneId || "",
        recordId: record?.id || "",
        body: {
            name: record?.name || "",
            type: record?.type || { linked: undefined },
        },
    });
}
export function DnsRecordForm({ record, zone }: DnsRecordFormProps) {
    const isEdit = !!record;
    const [typeStr, setTypeStr] = useState(
        record?.type ? Object.keys(record?.type)[0] : ""
    );

    const dv = useMemo(
        () => getDefaultValues(record, zone?.id),
        [record, zone?.id]
    );

    const form = useForm<FormValues>({
        defaultValues: dv,
    });

    useKeepFormCurrent(form, record, (r) => getDefaultValues(r, zone?.id));

    const {
        watch,
        register,
        setValue,
        control,
        formState: { isSubmitting, isDirty },
    } = form;

    const type = watch("body.type");

    const [updateDnsRecord] = useUpdateDnsZoneRecordMutation();
    const [createDnsRecord] = useCreateDnsZoneRecordMutation();
    const nav = useNavigate();

    const currentName = useWatch({ control, name: "body.name" });

    const onSubmit = (data: FormValues) => {
        if (!isDirty) {
            return;
        }

        if (isEdit) {
            return updateDnsRecord({
                zoneId: data.zoneId || "",
                recordId: data.recordId || "",
                body: {
                    type: data.body.type,
                },
            })
                .unwrap()
                .then(
                    () => nav(clearDialogParams()),
                    handleSubmitError(form.setError)
                );
        }

        if ("name" in data.body) {
            return createDnsRecord({
                zoneId: data.zoneId || "",
                body: {
                    name: data.body.name || "",
                    type: data.body.type,
                },
            })
                .unwrap()
                .then(
                    () => nav(clearDialogParams()),
                    handleSubmitError(form.setError)
                );
        }
    };

    useEffect(() => {
        // initialize default values for create
        if (isEdit) {
            return;
        }
        switch (typeStr) {
            case "a":
                setValue(
                    "body.type",
                    {
                        a: {
                            ip: "",
                        },
                    },
                    { shouldDirty: true }
                );
                return;
            case "aaaa":
                return setValue(
                    "body.type",
                    {
                        aaaa: {
                            ip: "",
                        },
                    },
                    { shouldDirty: true }
                );
            case "cname":
                return setValue(
                    "body.type",
                    {
                        cname: {
                            domain: "",
                        },
                    },
                    { shouldDirty: true }
                );
            case "ns":
                return setValue(
                    "body.type",
                    {
                        ns: {
                            domain: "",
                        },
                    },
                    { shouldDirty: true }
                );
            case "mx":
                return setValue(
                    "body.type",
                    {
                        mx: {
                            domain: "",
                            priority: 0,
                        },
                    },
                    { shouldDirty: true }
                );
            case "txt":
                return setValue(
                    "body.type",
                    {
                        txt: {
                            value: "",
                        },
                    },
                    { shouldDirty: true }
                );
            case "alias":
                return setValue(
                    "body.type",
                    {
                        alias: {
                            domain: "",
                        },
                    },
                    { shouldDirty: true }
                );
            case "srv":
                return setValue(
                    "body.type",
                    {
                        srv: {
                            weight: 0,
                            priority: 0,
                            port: 0,
                            domain: "",
                        },
                    },
                    { shouldDirty: true }
                );
            case "caa":
                return setValue(
                    "body.type",
                    {
                        caa: {
                            tag: "",
                            value: "",
                        },
                    },
                    { shouldDirty: true }
                );
            case "linked":
                return setValue(
                    "body.type",
                    {
                        linked: {
                            features: {
                                tls: {
                                    enable: false,
                                },
                                geodns: {
                                    enable: false,
                                },
                            },
                        },
                    },
                    { shouldDirty: true }
                );
        }
    }, [typeStr]);

    return (
        <RhfFormProvider {...form} onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex h-full flex-col justify-between">
                <div>
                    <FormSectionHeader header="General" />
                    <FormSection>
                        <RhfFormField label="record type" name="body.type">
                            <BasicSelect
                                disabled={isEdit}
                                onChange={(v) => {
                                    if (v) {
                                        return setTypeStr(v);
                                    }
                                }}
                                value={typeStr}
                                options={[
                                    {
                                        label: "LINKED",
                                        value: "linked",
                                        detail: "Cycle DNS Record type that facilitates the association of domains with containers",
                                    },
                                    {
                                        label: "A",
                                        value: "a",
                                        detail: "Holds the IP address of a domain.",
                                    },
                                    {
                                        label: "AAAA",
                                        value: "aaaa",
                                        detail: "Contains the IPv6 address for a domain (as opposed to A records, which list the IPv4 address)",
                                    },
                                    {
                                        label: "CNAME",
                                        value: "cname",
                                        detail: "Forwards one domain or subdomain to another domain, does NOT provide an IP address",
                                    },
                                    {
                                        label: "NS",
                                        value: "ns",
                                        detail: "Stores the name server for a DNS entry",
                                    },
                                    {
                                        label: "MX",
                                        value: "mx",
                                        detail: "Directs mail to an email server",
                                    },
                                    {
                                        label: "TXT",
                                        value: "txt",
                                        detail: "Allows an admin to store text notes in the record. These records are often used for email security",
                                    },
                                    {
                                        label: "ALIAS",
                                        value: "alias",
                                        detail: "Points your domain name to a hostname instead of an IP address",
                                    },
                                    {
                                        label: "SRV",
                                        value: "srv",
                                        detail: "Specifies a port for specific services",
                                    },
                                    {
                                        label: "CAA",
                                        value: "caa",
                                        detail: "Allows domain owners state which certificate authorities can issue certificates for that domain",
                                    },
                                ]}
                            />
                        </RhfFormField>
                    </FormSection>
                    {type && (
                        <>
                            <FormSectionHeader header="Details" />

                            <FormSection>
                                {!isEdit ? (
                                    <>
                                        <RhfFormField
                                            name="body.name"
                                            label="name"
                                        >
                                            <TextInput
                                                {...register("body.name", {
                                                    setValueAs: (v) =>
                                                        v.toLowerCase()
                                                            ? v.toLowerCase()
                                                            : "",
                                                    ...required(),
                                                    validate: {
                                                        ...hasNoSpaces(),
                                                    },
                                                })}
                                            />
                                        </RhfFormField>

                                        {zone?.origin &&
                                            currentName?.endsWith(
                                                zone?.origin
                                            ) && (
                                                <InfoPanel type="warning">
                                                    The record name should only
                                                    contain the subdomain. This
                                                    will resolve to{" "}
                                                    <strong>
                                                        {currentName}.
                                                        {zone.origin}
                                                    </strong>
                                                </InfoPanel>
                                            )}
                                    </>
                                ) : null}

                                {"linked" in type ? (
                                    <LinkedRecordDetails />
                                ) : null}

                                {"a" in type ? (
                                    <>
                                        <RhfFormField
                                            label="ip"
                                            name="body.type.a.ip"
                                            required
                                        >
                                            <TextInput
                                                {...register("body.type.a.ip", {
                                                    ...required(),
                                                })}
                                            />
                                        </RhfFormField>
                                    </>
                                ) : null}
                                {"aaaa" in type ? (
                                    <>
                                        <RhfFormField
                                            label="ip"
                                            name="body.type.aaaa.ip"
                                            required
                                        >
                                            <TextInput
                                                {...register(
                                                    "body.type.aaaa.ip",
                                                    {
                                                        ...required(),
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                    </>
                                ) : null}
                                {"cname" in type ? (
                                    <>
                                        <RhfFormField
                                            label="domain"
                                            name="body.type.cname.domain"
                                            required
                                        >
                                            <TextInput
                                                {...register(
                                                    "body.type.cname.domain",
                                                    {
                                                        ...required(),
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                    </>
                                ) : null}
                                {"ns" in type ? (
                                    <>
                                        <RhfFormField
                                            label="domain"
                                            name="body.type.ns.domain"
                                            required
                                        >
                                            <TextInput
                                                {...register(
                                                    "body.type.ns.domain",
                                                    {
                                                        ...required(),
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                    </>
                                ) : null}
                                {"mx" in type ? (
                                    <>
                                        <RhfFormField
                                            label="domain"
                                            name="body.type.mx.domain"
                                            required
                                        >
                                            <TextInput
                                                {...register(
                                                    "body.type.mx.domain",
                                                    {
                                                        ...required(),
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                        <RhfFormField
                                            label="priority"
                                            name="body.type.mx.priority"
                                            required
                                        >
                                            <TextInput
                                                type="number"
                                                {...register(
                                                    "body.type.mx.priority",
                                                    {
                                                        ...required(),
                                                        valueAsNumber: true,
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                    </>
                                ) : null}
                                {"txt" in type ? (
                                    <>
                                        <RhfFormField
                                            label="value"
                                            name="body.type.txt.value"
                                            required
                                        >
                                            <TextInput
                                                {...register(
                                                    "body.type.txt.value",
                                                    {
                                                        ...required(),
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                    </>
                                ) : null}
                                {"alias" in type ? (
                                    <>
                                        <RhfFormField
                                            label="domain"
                                            name="body.type.alias.domain"
                                            required
                                        >
                                            <TextInput
                                                {...register(
                                                    "body.type.alias.domain",
                                                    {
                                                        ...required(),
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                    </>
                                ) : null}
                                {"srv" in type ? (
                                    <>
                                        <RhfFormField
                                            label="domain"
                                            name="body.type.srv.domain"
                                            required
                                        >
                                            <TextInput
                                                {...register(
                                                    "body.type.srv.domain",
                                                    {
                                                        ...required(),
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                        <RhfFormField
                                            label="ip"
                                            name="body.type.srv.weight"
                                            required
                                        >
                                            <TextInput
                                                {...register(
                                                    "body.type.srv.weight",
                                                    {
                                                        ...required(),
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                        <RhfFormField
                                            label="priority"
                                            name="body.type.srv.priority"
                                            required
                                        >
                                            <TextInput
                                                type="number"
                                                {...register(
                                                    "body.type.srv.priority",
                                                    {
                                                        ...required(),
                                                        valueAsNumber: true,
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                        <RhfFormField
                                            label="port"
                                            name="body.type.srv.port"
                                            required
                                        >
                                            <TextInput
                                                {...register(
                                                    "body.type.srv.port",
                                                    {
                                                        ...required(),
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                    </>
                                ) : null}
                                {"caa" in type ? (
                                    <>
                                        <RhfFormField
                                            label="tag"
                                            name="body.type.caa.tag"
                                            required
                                        >
                                            <TextInput
                                                {...register(
                                                    "body.type.caa.tag",
                                                    {
                                                        ...required(),
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                        <RhfFormField
                                            label="value"
                                            name="body.type.caa.value"
                                            required
                                        >
                                            <TextInput
                                                {...register(
                                                    "body.type.caa.value",
                                                    {
                                                        ...required(),
                                                    }
                                                )}
                                            />
                                        </RhfFormField>
                                    </>
                                ) : null}
                            </FormSection>
                        </>
                    )}
                </div>

                <DialogFooter className="items-center">
                    <div>
                        <RhfGlobalFormError />
                    </div>
                    <LoaderButton
                        icon={isEdit ? faEdit : faPlus}
                        onClick={form.handleSubmit(onSubmit)}
                        flavor="primary"
                        isLoading={isSubmitting}
                        disabled={!isDirty}
                    >
                        {isEdit ? "Update" : "Create"}
                    </LoaderButton>
                </DialogFooter>
            </div>
        </RhfFormProvider>
    );
}
