import { Image } from "~/services/cycle";
import {
    StyledTableRow,
    StyledDataTable,
    StyledTableHead,
    StyledCell,
    StyledHeaderCell,
} from "@cycleplatform/ui/components/tables";
import { CopyInput } from "@cycleplatform/ui/components/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from "@fortawesome/pro-solid-svg-icons";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { useContext } from "react";
import { ImageDialogContext } from "../context";

export function EnvironmentVariables() {
    const { image } = useContext(ImageDialogContext);

    return (
        <Panel>
            <PanelTitle title="Environment Variables" />
            <PanelContent>
                {image?.config?.env && Object.keys(image.config.env).length ? (
                    <>
                        <StyledDataTable>
                            <StyledTableHead>
                                <StyledHeaderCell>Variable</StyledHeaderCell>
                                <StyledHeaderCell className="select-none ">
                                    Value
                                </StyledHeaderCell>
                            </StyledTableHead>

                            <tbody>
                                {Object.keys(image.config.env)?.map((v) => (
                                    <StyledTableRow key={v}>
                                        <StyledCell>{v}</StyledCell>
                                        <StyledCell>
                                            <div>
                                                <CopyInput
                                                    value={image.config.env[v]}
                                                />
                                            </div>
                                        </StyledCell>
                                    </StyledTableRow>
                                ))}
                            </tbody>
                        </StyledDataTable>
                    </>
                ) : (
                    <div className="flex w-full flex-col items-center justify-center">
                        <FontAwesomeIcon
                            icon={faCode}
                            className="text-cycle-blue h-10"
                        />
                        <div>No Environment Variables</div>
                        <div>This environment has no environment variables</div>
                    </div>
                )}
            </PanelContent>
        </Panel>
    );
}
