import {
    Button,
    PushAndHoldButton,
} from "@cycleplatform/ui/components/buttons";
import {
    RhfDataSizeInput,
    RhfFormField,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import { PositionedMenu } from "@cycleplatform/ui/components/menus";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { AccessControlOverlay } from "~/components/common/buttons";
import { getAllDialogSearchParams } from "~/components/dialogs/helpers";
import { handleSubmitError } from "~/components/forms/util";
import { NavIcons } from "~/components/layout/NavIcons";
import { useJobTracker } from "~/modules/jobs/hooks";
import {
    Container,
    Environment,
    InstanceExtendVolumeAction,
    InstanceVolume,
    useCreateInstanceJobMutation,
    useCreateServerJobMutation,
} from "~/services/cycle";
import { formatBytesString } from "@cycleplatform/core/util";
import { modifyAccessFn } from "@cycleplatform/core/modules/acls/util";
import { faArrowsMaximize } from "@fortawesome/pro-solid-svg-icons";

export function UpdateVolumeSizePanel({
    volume,
    environment,
    containerId,
    instanceId,
}: {
    volume: InstanceVolume;
    environment: Environment | undefined;
    containerId: string | undefined;
    instanceId: string | undefined;
}) {
    return (
        <AccessControlOverlay
            aclResource={environment}
            verifyFn={modifyAccessFn("containers-volumes-manage")}
        >
            <PositionedMenu
                className={"w-[40rem]"}
                placement="left-start"
                render={(_, setIsOpen) => {
                    return (
                        <>
                            <div className="flex items-center gap-2 pb-4 text-lg">
                                <FontAwesomeIcon
                                    icon={faArrowsMaximize}
                                    className="text-cycle-blue"
                                />
                                Extend Volume Size
                            </div>
                            <UpdateVolumeSizeForm
                                volume={volume}
                                setIsOpen={setIsOpen}
                                containerId={containerId}
                                instanceId={instanceId}
                            />
                        </>
                    );
                }}
            >
                <Tooltip message="Extend Volume Size" className="relative">
                    <Button
                        icon={faArrowsMaximize}
                        className="w-10 !px-2 text-xs"
                    />
                </Tooltip>
            </PositionedMenu>
        </AccessControlOverlay>
    );
}

function UpdateVolumeSizeForm({
    volume,
    setIsOpen,
    containerId,
    instanceId,
}: {
    volume: InstanceVolume;
    setIsOpen: (open: boolean) => void;
    containerId: string | undefined;
    instanceId: string | undefined;
}) {
    const form = useForm<InstanceExtendVolumeAction["contents"]>({
        defaultValues: { volume_id: volume.id || "" },
    });

    const [trackJob, { isTrackingJob }] = useJobTracker();
    const [createInstanceJob] = useCreateInstanceJobMutation();

    const {
        handleSubmit,
        setError,
        control,
        formState: { isDirty },
    } = form;

    const onSubmit = (data: InstanceExtendVolumeAction["contents"]) => {
        return trackJob(
            createInstanceJob({
                containerId: containerId || "",
                instanceId: instanceId || "",
                instanceTask: {
                    action: "volume.extend",
                    contents: data,
                },
            }).unwrap()
        )
            .then(() => {
                form.reset();
                setIsOpen(false);
            })
            .catch((err) => {
                handleSubmitError(setError)(err);
            });
    };

    return (
        <FormProvider {...form}>
            <RhfFormField
                label="Extend Size"
                name="extend_size"
                help="Indicates how much the volume size will be increased"
                required
            >
                <Controller
                    render={({ field: { ref: _ref, ...field } }) => (
                        <RhfDataSizeInput
                            {...field}
                            suffixClassName="!w-[40rem]"
                            suffix={
                                <div className="text-sm ">
                                    Current Usable Size:{" "}
                                    {formatBytesString(
                                        volume.deployed.storage.total * 1000000,
                                        1
                                    )}
                                </div>
                            }
                            filterUnitOptions={(opt) =>
                                opt.filter((o) => o.label !== "MB")
                            }
                        />
                    )}
                    control={control}
                    name={"extend_size"}
                />
            </RhfFormField>

            <PanelFooter>
                <div>
                    <RhfGlobalFormError />
                </div>
                <PushAndHoldButton
                    type="button"
                    flavor="primary"
                    icon={faArrowsMaximize}
                    onClick={handleSubmit(onSubmit)}
                    disabled={!isDirty}
                    isLoading={isTrackingJob}
                >
                    Extend
                </PushAndHoldButton>
            </PanelFooter>
        </FormProvider>
    );
}
