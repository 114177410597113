import { components } from "@cycleplatform/core/modules/api/__generated";
import { Area, AreaChart, ResponsiveContainer } from "recharts";
import { BillingInvoice } from "~/services/cycle";
import classNames from "classnames";
import { useMemo } from "react";
import { XAxis, YAxis, Tooltip, CartesianGrid } from "recharts";
import { CycleLineProps } from "@cycleplatform/ui/components/recharts/line/props";
import { CycleLineTooltip } from "@cycleplatform/ui/components/recharts/line/CycleLineTooltip";
import {
    CycleAxisProps,
    CycleChartProps,
} from "@cycleplatform/ui/components/recharts/props";
import { FormattedTimeTick } from "@cycleplatform/ui/components/recharts/FormattedTimeTick";
import { FormattedYTick } from "@cycleplatform/ui/components/recharts/FormattedYTick";
import { formatRFC3339 } from "date-fns";
import { formatMoney } from "@cycleplatform/core/util/money";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import { useGetThemedChartColors } from "~/util/charts/hooks";
import { CycleGridProps } from "@cycleplatform/ui/components/recharts/grid/props";

export function InvoiceHistoryChart({
    invoices,
    theme,
    isLoading,
}: {
    invoices?: BillingInvoice[];
    isLoading: boolean;
    theme?: "light" | "dark";
}) {
    const { colors } = useGetThemedChartColors();
    const data = useMemo(
        () => (invoices ? formatInvoiceData(invoices) : undefined),
        [invoices]
    );

    return (
        <div className={classNames("h-full", isLoading && skeletonStyles)}>
            {invoices && data && (
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                        {...CycleChartProps}
                        margin={{ top: 5, bottom: 5, right: 0, left: 10 }}
                        data={data}
                    >
                        <XAxis
                            {...CycleAxisProps}
                            dataKey="time"
                            domain={["auto", "auto"]}
                            tick={(props) => <FormattedTimeTick {...props} />}
                        />
                        <YAxis
                            {...CycleAxisProps}
                            tickMargin={30}
                            tick={(props) => (
                                <FormattedYTick
                                    {...props}
                                    formatValue={(v: number) =>
                                        formatMoney({
                                            value: v as number,
                                            formatOpts: {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                            },
                                        })
                                    }
                                />
                            )}
                        />

                        <CartesianGrid {...CycleGridProps} />
                        <Tooltip
                            content={(props) => (
                                <CycleLineTooltip
                                    {...props}
                                    formatValue={(v) =>
                                        formatMoney({
                                            value: v as number,
                                            formatOpts: {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                            },
                                        })
                                    }
                                />
                            )}
                        />
                        <Area
                            {...CycleLineProps}
                            dataKey="charged"
                            stroke={colors["blue"].DEFAULT}
                            fill={colors["blue"].DEFAULT}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            )}
        </div>
    );
}

function formatInvoiceData(is: components["schemas"]["Invoice"][]) {
    const keyedNumericDates = is.reduce<Record<string, number>>((acc, cur) => {
        const key = new Date(cur.events.created || "").setHours(0, 0, 0, 0);
        acc[key] =
            acc[key] !== undefined ? acc[key] + cur.charges : cur.charges;
        return acc;
    }, {});

    return Object.keys(keyedNumericDates)
        .sort()
        .map((date) => ({
            time: formatRFC3339(new Date(parseInt(date))),
            charged: keyedNumericDates[date] / 1000,
        }));
}
