import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { useContext, useState } from "react";
import {
    LoadBalancerConnectionsChart,
    LoadBalancerTrafficReportChart,
} from "~/components/environments/services/lb/charts";
import { LoadBalancerDisconnectsChart } from "~/components/environments/services/lb/charts/disconnects/LoadBalancerDisconnectsChart";
import { LoadBalancerDialogContext } from "../../context";
import classNames from "classnames";
import {
    getLoadBalancerConfiguration,
    isUsingCycleNativeLb,
    isV1LbConfig,
} from "@cycleplatform/core/modules/environments/loadbalancer";
import { faCheck, faLineChart } from "@fortawesome/pro-solid-svg-icons";
import { GearOptions } from "@cycleplatform/ui/components/controls";
import {
    useGenerateAggregatedMetricsQuery,
    V1LbConfig,
    V1LbType,
} from "~/services/cycle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadBalancerDestinationsAvailabilityChart } from "~/components/environments/services/lb/charts/destinationsAvailability/LoadBalancerDestinationsAvailabilityChart";
import { LoadBalancerDestinationsChart } from "~/components/environments/services/lb/charts/destinations copy/LoadBalancerDestinationsChart";
import { getLbControllersQuery } from "../../util/getLbControllersQuery";
import { LoadBalancerRouterResponseChart } from "~/components/environments/services/lb/charts/routerResponse/LoadBalancerRouterResponseChart";
import { AGGREGATE_POLLING_MS } from "~/util/charts/util";
import { getActualTransportConfig } from "@cycleplatform/core/modules/environments/loadbalancer/v1";

export function LoadBalancerTelemetrySection() {
    const [port, setPort] = useState<number | undefined>();
    const { environment } = useContext(LoadBalancerDialogContext);

    const { data: telemetry } = useGenerateAggregatedMetricsQuery(
        getLbControllersQuery(environment?.id),
        { skip: !environment?.id, pollingInterval: AGGREGATE_POLLING_MS }
    );

    const telemControllers = (telemetry?.data?.[0]?.telemControllers ||
        []) as number[];

    return (
        <Panel>
            <PanelTitle
                title="Load Balancer Telemetry"
                className="flex justify-between"
            >
                <GearOptions
                    className="text-sm lowercase"
                    placement="bottom-end"
                >
                    <ul>
                        <li>
                            <button
                                className={classNames(
                                    port === undefined && "text-cycle-blue",
                                    "flex items-center gap-2"
                                )}
                                onClick={() => setPort(undefined)}
                            >
                                <div className="w-4">
                                    {port === undefined && (
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className="text-cycle-blue-accent"
                                        />
                                    )}
                                </div>
                                All Ports
                            </button>
                        </li>

                        {telemControllers?.map((p) => {
                            const isActive = p === port;
                            return (
                                <li key={p}>
                                    <button
                                        className={classNames(
                                            isActive && "text-cycle-blue",
                                            "flex items-center gap-2"
                                        )}
                                        onClick={() => setPort(p)}
                                    >
                                        <div className="w-4">
                                            {isActive && (
                                                <FontAwesomeIcon
                                                    icon={faCheck}
                                                    className="text-cycle-blue-accent"
                                                />
                                            )}
                                        </div>
                                        Port {p}
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                </GearOptions>
            </PanelTitle>
            <PanelContent className={classNames("w-full")}>
                <TelemetryContent port={port ? `${port}` : undefined} />
            </PanelContent>
        </Panel>
    );
}

function TelemetryContent({ port }: { port: string | undefined }) {
    const { environment, service } = useContext(LoadBalancerDialogContext);

    const isCycleNativeLb = service ? isUsingCycleNativeLb(service) : false;

    const isLbDisabled = environment?.services?.loadbalancer?.enable === false;

    if (!isCycleNativeLb) {
        return (
            <EmptyResource
                className="flex h-60 items-center justify-center border-none"
                icon={faLineChart}
                title="Not Using Native Load Balancer"
            >
                <p className="text-center">
                    Metrics are only available when using the Cycle Native Load
                    Balancer (v1). The type of load balancer for this
                    environment can be selected in the panel to the right.
                </p>
            </EmptyResource>
        );
    }

    if (isLbDisabled) {
        return (
            <EmptyResource
                className="flex h-60 items-center justify-center border-none"
                icon={faLineChart}
                title="Load Balancer Disabled"
            >
                <p className="text-center">
                    The load balancer is disabled for this environment. Once it
                    is enabled, Cycle will begin gathering metrics that will be
                    displayed here.
                </p>
            </EmptyResource>
        );
    }

    const nativelb = service
        ? (getLoadBalancerConfiguration(service) as V1LbType)
        : undefined;
    const transport =
        service && nativelb && port
            ? (getActualTransportConfig(
                  parseInt(port),
                  service.base_configs?.v1 || nativelb.details,
                  nativelb.details
              ) as V1LbConfig["controllers"][number]["transport"])
            : undefined;

    return (
        <>
            <PanelTitle title="Traffic" />
            <div className="h-60 p-4">
                <LoadBalancerTrafficReportChart
                    environment={environment}
                    service={service}
                    port={port}
                />
            </div>

            <PanelTitle title="Inbound Connections" />
            <div className="h-60 p-4">
                <LoadBalancerConnectionsChart
                    environmentId={environment?.id}
                    port={port}
                />
            </div>

            <PanelTitle title="Disconnects" />
            <div className="h-60 p-4">
                <LoadBalancerDisconnectsChart
                    environmentId={environment?.id}
                    port={port}
                />
            </div>

            <PanelTitle title="Destination Connections" />
            <div className="h-60 p-4">
                <LoadBalancerDestinationsChart
                    environment={environment}
                    port={port}
                />
            </div>

            <PanelTitle title="Destination Availability" />
            <div className="h-60 p-4">
                <LoadBalancerDestinationsAvailabilityChart
                    environment={environment}
                    port={port}
                />
            </div>

            <PanelTitle title="Router Response" />
            <div className="h-60 p-4">
                <LoadBalancerRouterResponseChart
                    environment={environment}
                    transport={transport}
                    port={port}
                />
            </div>
        </>
    );
}
