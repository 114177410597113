import { LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    InputRow,
    required,
    RhfFormField,
    RhfFormProvider,
    isEmail,
    TextInput,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import { faMessage } from "@fortawesome/pro-solid-svg-icons";
import { Controller, useForm } from "react-hook-form";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { RolesSelect } from "~/components/roles/RolesSelect";
import {
    CreateHubInviteApiArg,
    useCreateHubInviteMutation,
} from "~/services/cycle";

export function HubSetupInviteMemberForm() {
    const form = useForm<CreateHubInviteApiArg["body"]>({
        ...rhfConfig,
    });

    const {
        register,
        control,
        formState: { isDirty, isSubmitting, errors },
    } = form;

    const [inviteMemberForm] = useCreateHubInviteMutation();

    const onSubmit = (data: CreateHubInviteApiArg["body"]) => {
        if (!isDirty) {
            return;
        }

        return inviteMemberForm({
            body: data,
        })
            .unwrap()
            .then(() => form.reset(), handleSubmitError(form.setError));
    };

    return (
        <RhfFormProvider {...form} onSubmit={form.handleSubmit(onSubmit)}>
            <InputRow className="items-end">
                <RhfFormField label="email" name="recipient" required>
                    <TextInput
                        autoComplete="off"
                        {...register("recipient", {
                            validate: {
                                ...isEmail(),
                            },
                        })}
                    />
                </RhfFormField>

                <RhfFormField label="role" name="role_id" required>
                    <Controller
                        render={({ field: { ref: _ref, ...field } }) => (
                            <RolesSelect {...field} />
                        )}
                        rules={{ ...required() }}
                        control={control}
                        name="role_id"
                    />
                </RhfFormField>
                <LoaderButton
                    flavor="primary"
                    icon={faMessage}
                    onClick={form.handleSubmit(onSubmit)}
                    isLoading={isSubmitting}
                    disabled={!isDirty || Object.keys(errors).length > 0}
                >
                    Send Invite
                </LoaderButton>
            </InputRow>
            <RhfGlobalFormError />
        </RhfFormProvider>
    );
}
