import { usePagination } from "@cycleplatform/ui/hooks";
import { InvoicesList } from "~/components/billing/lists/InvoicesList";
import { useGetInvoicesQuery } from "~/services/cycle";

export function InvoicesListContent() {
    const pagination = usePagination(5);

    const { data: invoices, error: invoicesError } = useGetInvoicesQuery({
        sort: ["-id"],
        meta: ["due"],
        page: pagination,
    });
    if (invoicesError) {
        throw invoicesError;
    }

    return <InvoicesList invoices={invoices?.data} />;
}
