import {
    Button,
    PushAndHoldButton,
} from "@cycleplatform/ui/components/buttons";
import {
    FormSectionHeader,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import { useFormContext, useWatch } from "react-hook-form";
import {
    PLAN_TILE_CLASSNAME,
    PlanTier,
} from "@cycleplatform/ui/components/resources/plans/PlanTier";
import {
    BillingOrder,
    CreateOrderApiArg,
    Hub,
    PromoCode,
    TierPlan,
} from "~/services/cycle";
import { useSetHubPlanParams } from "../../../hooks";
import { PageBody, PageContent } from "@cycleplatform/ui/components/page";
import { faCreditCard } from "@fortawesome/pro-solid-svg-icons";
import { useMemo, useState } from "react";
import classNames from "classnames";
import { AddPromoCode } from "./AddPromoCode";
import { clearDialogParams } from "~/components/dialogs/helpers";
import { useNavigate } from "react-router-dom";
import { getOptionButtonClassName } from "@cycleplatform/ui/components/options/styles";
import { DialogFooter } from "@cycleplatform/ui/components/dialog/components";
import { useHandlePlanUpdate } from "../../util";

export type PromoInfo = {
    order: BillingOrder;
    promo?: PromoCode;
};

export function OrderConfirmationStep({
    tiers,
    hub,
}: {
    tiers: TierPlan[] | undefined;
    hub: Hub | undefined;
}) {
    const setHubPlanParams = useSetHubPlanParams();

    const [promoInfo, setPromoInfo] = useState<PromoInfo>();

    const form = useFormContext<CreateOrderApiArg["body"]>();
    const {
        unregister,
        control,
        formState: { isSubmitting },
    } = form;

    const selectedTierId = useWatch({ name: "tier_plan_id", control });
    const currentTierId = hub?.billing?.plans?.tier_id || null;

    const usableTiers = useMemo(
        () =>
            new Map(
                tiers
                    ?.filter((t) => !t.hidden || t.id === selectedTierId)
                    .sort((a, b) => a.price.mills - b.price.mills)
                    .map((t) => [t.id, t])
            ),
        [tiers]
    );

    const currentTier = useMemo(
        () => tiers?.find((t) => t.id === currentTierId),
        [tiers, currentTierId]
    );

    const selectedTier = usableTiers.get(selectedTierId || "");

    const canUsePromo =
        (selectedTier?.price.mills || 0) > (currentTier?.price.mills || 0);

    const nav = useNavigate();

    const onSubmit = useHandlePlanUpdate({
        onSuccess: () => nav(clearDialogParams()),
        tiers,
    });

    return (
        <div className="pb-16">
            <PageBody className="!mb-0 flex h-full rounded-lg">
                <PageContent className="h-full justify-center">
                    <div
                        className={classNames(
                            "h-full flex-1",
                            PLAN_TILE_CLASSNAME
                        )}
                    >
                        <FormSectionHeader
                            header="Current Plan"
                            className="!text-xl"
                        />

                        <div
                            className={classNames(
                                "pointer-events-none rounded-md border p-4",
                                getOptionButtonClassName(false)
                            )}
                        >
                            <PlanTier
                                tier={currentTier}
                                priceOverride={
                                    promoInfo?.order?.total_price !== undefined
                                        ? {
                                              price: promoInfo.order
                                                  .total_price,
                                              reason: "discount",
                                          }
                                        : undefined
                                }
                            />
                        </div>
                    </div>
                    <div
                        className={classNames(
                            "h-full w-full flex-1",
                            PLAN_TILE_CLASSNAME
                        )}
                    >
                        <FormSectionHeader
                            header="New Plan"
                            className="!text-xl"
                        />

                        <div
                            className={classNames(
                                "pointer-events-none mb-4 rounded-md border p-4",
                                getOptionButtonClassName(false)
                            )}
                        >
                            <PlanTier
                                tier={selectedTier}
                                priceOverride={
                                    promoInfo?.order?.total_price !== undefined
                                        ? {
                                              price: promoInfo.order
                                                  .total_price,
                                              reason: "discount",
                                          }
                                        : undefined
                                }
                            />
                        </div>

                        {canUsePromo && (
                            <AddPromoCode
                                setPromoInfo={setPromoInfo}
                                promoInfo={promoInfo}
                            />
                        )}
                    </div>
                </PageContent>
            </PageBody>

            <DialogFooter className="items-center justify-between ">
                <Button
                    onClick={() => {
                        setHubPlanParams({ step: "0", promo: null });
                        unregister("promo_code");
                    }}
                >
                    Back
                </Button>
                <RhfGlobalFormError />

                <PushAndHoldButton
                    flavor="primary"
                    icon={faCreditCard}
                    isLoading={isSubmitting}
                    onClick={form.handleSubmit(onSubmit)}
                >
                    Confirm Plan Change
                </PushAndHoldButton>
            </DialogFooter>
        </div>
    );
}
