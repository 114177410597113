import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CycleApiErrorResponse } from "~/services/helpers";
import { RootState } from "~/store";

type Form = {
    steps: Record<string, unknown>[];
    error?: { step: number; message?: string; source?: string };
};

type State = Record<string, Form>;

const initialState: State = {};

const formSlice = createSlice({
    name: "form",
    initialState,
    reducers: {
        initializeForm: (
            state,
            action: PayloadAction<{
                key: string;
                steps?: Form["steps"];
            }>
        ) => {
            const key = action.payload.key;
            if (key === "") {
                return;
            }
            if (!state[key]) {
                state[key] = { steps: action.payload.steps || [] };
            }
        },

        terminateForm: (
            state,
            action: PayloadAction<{
                key: string;
            }>
        ) => {
            delete state[action.payload.key];
        },

        terminateAllForms: () => {
            return {};
        },

        addStepData: (
            state,
            action: PayloadAction<{
                data: Record<string, unknown>;
                key: string;
                idx: number;
            }>
        ) => {
            const { data, key, idx } = action.payload;

            if (!state[key]) {
                return;
            }

            if (idx >= 0) {
                state[key].steps[idx] = data; // Directly replace the item at the index
            }
        },

        setError: (
            state,
            action: PayloadAction<{
                data: CycleApiErrorResponse;
                key: string;
            }>
        ) => {
            const { data, key } = action.payload;
            if (!state[key]) {
                return;
            }

            const steps = [...state[key].steps];

            const errSource =
                data.data.error.source?.split("/")?.reverse()[0] || "";

            const stepIdx = steps.findIndex((step) => errSource in step);

            if (stepIdx !== -1) {
                state[key].error = {
                    step: stepIdx,
                    message: data.data.error.title,
                    source: errSource,
                };
            } else {
                state[key].error = undefined;
            }
        },

        clearError: (
            state,
            action: PayloadAction<{
                key: string;
            }>
        ) => {
            const { key } = action.payload;

            if (!state[key]) {
                return;
            }
            state[key].error = undefined;
        },
    },
});

export const getCurrentForm = (state: RootState, key: string) => {
    return state.form[key];
};
// Action creators are generated for each case reducer function
export const {
    actions: {
        initializeForm,
        terminateForm,
        addStepData,
        terminateAllForms,
        setError,
        clearError,
    },
    reducer: formSliceReducer,
} = formSlice;
