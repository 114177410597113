import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";
import { Link } from "react-router-dom";
import { ImageBuildLog } from "~/components/images/ImageBuildLog";
import { PipelineRunStep, useGetImageQuery } from "~/services/cycle";
import { generateDialogLink } from "~/components/dialogs/helpers";
import classNames from "classnames";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";

export function ImageImportDetails({ step }: { step: PipelineRunStep }) {
    const imageId = step?.components?.find((c) => c.type === "image")?.id;

    const { data: image } = useGetImageQuery(
        {
            imageId: imageId || "",
        },
        { skip: !imageId }
    );

    if (!image?.data) {
        return (
            <div
                className={classNames("h-[25rem] w-[55rem]", skeletonStyles)}
            />
        );
    }

    return (
        <ImageBuildLog
            imageId={imageId}
            codeDimensionsClassName="max-h-[25rem] max-w-[55rem]"
            title={
                <div className="flex items-center gap-2 !normal-case">
                    <Link
                        to={generateDialogLink("image", {
                            "dialog-image-id": imageId,
                        })}
                    >
                        {image?.data?.name}
                    </Link>
                    <ResourceStateBadge state={image?.data?.state} />
                </div>
            }
        />
    );
}
