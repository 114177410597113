import { Controller, useFormContext } from "react-hook-form";
import { CreateBillingMethodFormType } from "../CreateBillingMethodForm";
import { useState } from "react";
import { TextInput } from "@cycleplatform/ui/components/forms";

export function ExpirationInput() {
    const { control, setValue } = useFormContext<CreateBillingMethodFormType>();
    const [rawValue, setRawValue] = useState(""); // Store the raw input value

    const handleExpirationChange = (value: string) => {
        // Remove all non-numeric characters except "/"
        let cleanValue = value.replace(/[^\d\/]/g, "");

        // If user enters "1/" or "2/", it automatically becomes "01/" or "02/"
        if (cleanValue.length === 2 && value.includes("/")) {
            cleanValue = `0${cleanValue.slice(0, 1)}/`;
        }

        // If the user types a single digit, pad it with a 0 if necessary
        if (cleanValue.length === 1 && cleanValue > "1") {
            cleanValue = `0${cleanValue}`;
        }

        // Automatically add "/" after the month part if not present
        if (cleanValue.length > 2 && !cleanValue.includes("/")) {
            cleanValue = `${cleanValue.slice(0, 2)}/${cleanValue.slice(2)}`;
        }

        // Cap months to "12" if the entered value exceeds valid month range
        const month = cleanValue.slice(0, 2);
        if (parseInt(month, 10) > 12) {
            cleanValue = `12${cleanValue.slice(2)}`;
        }

        // Sync month and year in the form state when both are present (MM/YY)
        if (cleanValue.length === 7) {
            const [formattedMonth, formattedYear] = cleanValue.split("/");
            setValue("credit_card.expiration.month", parseInt(formattedMonth)); // Store month as MM
            setValue("credit_card.expiration.year", parseInt(formattedYear)); // Store year as YY
        }

        // Update the raw value for display and return the formatted value
        setRawValue(cleanValue);
    };

    return (
        <Controller
            render={({ field: { ref: _ref, ...field } }) => (
                <TextInput
                    type="text"
                    onChange={(e) => {
                        handleExpirationChange(e.target.value);
                        field.onChange(rawValue); // Sync the raw value to the form state
                    }}
                    onBlur={field.onBlur}
                    name={field.name}
                    value={rawValue}
                    placeholder="MM/YYYY"
                    maxLength={7}
                />
            )}
            control={control}
            rules={{
                required: {
                    value: true,
                    message: "This field is required",
                },
            }}
            name={"credit_card._tmpexpires" as "credit_card.expiration.month"} // mock a field that doesn't exist in our definition to store temp val
        />
    );
}
