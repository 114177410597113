import {
    Checkbox,
    ExpandableFormSection,
    SectionDisabledControl,
    FormField,
    FormSection,
    FormSectionHeader,
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import { faCodeMerge } from "@fortawesome/pro-solid-svg-icons";
import { useForm, useFormContext } from "react-hook-form";
import { Container, useCreateContainerJobMutation } from "~/services/cycle";
import { ImageSelect } from "./ImageSelect";
import {
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { handleSubmitError, rhfConfig } from "~/components/forms/util";
import { SkeletonButton } from "@cycleplatform/ui/components/loaders/skeleton";
import { $info } from "@cycleplatform/core/util/log";
import { useJobTracker } from "~/modules/jobs/hooks";
import { CycleErrorBoundary } from "~/components/common/errors";
import { SelectInput } from "@cycleplatform/ui/components/forms/select";
import { useKeepFormCurrent } from "~/components/common/forms";
import { useContext } from "react";
import { ContainerDialogContext } from "../../context";
import { AccessControlOverlay } from "~/components/common/buttons";
import { containerModifyAccessFn } from "@cycleplatform/core/modules/containers/acls";
import { AccessControlledSection } from "~/components/layout/AccessControlledSection";
import {
    isServiceContainer,
    isHypervisorContainer,
} from "@cycleplatform/core/modules/containers/util";

type ReimageContainerProps = {
    container?: Container;
};

export type ReimageContainerSubmitType = {
    image_id: string;
    allow_incompatible: boolean;
    overwrite_runtime_config: boolean;
};

function getDefaultValues(container?: Container) {
    return {
        image_id: container?.image?.id || "",
        allow_incompatible: false,
        overwrite_runtime_config: false,
    };
}

export function ReimageContainer({ container }: ReimageContainerProps) {
    const { environment } = useContext(ContainerDialogContext);

    const form = useForm<ReimageContainerSubmitType>({
        defaultValues: getDefaultValues(container),
        ...rhfConfig,
    });

    useKeepFormCurrent(form, container, (c) => getDefaultValues(c));

    const {
        handleSubmit,
        watch,
        reset,
        formState: { isDirty },
    } = form;
    const [trackImageJob, { isTrackingJob }] = useJobTracker();

    const [reimageContainer] = useCreateContainerJobMutation();
    const onSubmit = async (data: ReimageContainerSubmitType) => {
        if (!container || !isDirty) {
            return;
        }
        trackImageJob(
            reimageContainer({
                containerId: container.id,
                containerTask: {
                    action: "reimage",
                    contents: data,
                },
            }).unwrap()
        ).then(() => {
            reset({
                image_id: data?.image_id || "",
                allow_incompatible: false,
                overwrite_runtime_config: false,
            });
            $info("reimaged container");
        }, handleSubmitError(form.setError));
    };

    const isService = isServiceContainer(container);
    const isHypervisor = isHypervisorContainer(container);

    return (
        <Panel>
            <PanelTitle title="Re-Image" />
            <PanelContent>
                {!isService && !isHypervisor ? (
                    <RhfFormProvider {...form}>
                        <FormSectionHeader header="General" />

                        <AccessControlledSection
                            aclResource={environment}
                            verifyFn={
                                container
                                    ? containerModifyAccessFn(container)
                                    : () => undefined
                            }
                        >
                            <FormSection>
                                <RhfFormField
                                    label="image"
                                    name="image_id"
                                    required
                                >
                                    <CycleErrorBoundary>
                                        {container ? (
                                            <ImageSelect
                                                container={container}
                                                currentImageId={
                                                    container.image.id ||
                                                    undefined
                                                }
                                                allowIncompatible={watch(
                                                    "allow_incompatible"
                                                )}
                                            />
                                        ) : (
                                            <SkeletonButton />
                                        )}
                                    </CycleErrorBoundary>
                                </RhfFormField>
                            </FormSection>
                        </AccessControlledSection>
                        <Advanced container={container} />
                        <PanelFooter>
                            <div>
                                <RhfGlobalFormError />
                            </div>
                            <AccessControlOverlay
                                aclResource={environment}
                                verifyFn={
                                    container
                                        ? containerModifyAccessFn(container)
                                        : () => undefined
                                }
                            >
                                <PushAndHoldButton
                                    flavor="primary"
                                    isLoading={isTrackingJob}
                                    tooltip={
                                        isService
                                            ? "cannot reimage service container"
                                            : "hold to reimage container"
                                    }
                                    type="button"
                                    icon={faCodeMerge}
                                    onClick={handleSubmit(onSubmit)}
                                    disabled={!isDirty}
                                >
                                    Reimage Container
                                </PushAndHoldButton>
                            </AccessControlOverlay>
                        </PanelFooter>
                    </RhfFormProvider>
                ) : (
                    <FormField label="image">
                        <SelectInput placeholder="Select Image" disabled />
                    </FormField>
                )}
            </PanelContent>
        </Panel>
    );
}

function Advanced({ container }: { container: Container | undefined }) {
    const { register } = useFormContext<ReimageContainerSubmitType>();
    const { environment } = useContext(ContainerDialogContext);

    return (
        <ExpandableFormSection>
            {() => (
                <AccessControlledSection
                    aclResource={environment}
                    verifyFn={
                        container
                            ? containerModifyAccessFn(container)
                            : () => undefined
                    }
                >
                    <RhfFormField
                        label="allow incompatible images"
                        name="allow_incompatible"
                        help="Allow images not determined to be
                    compatible with this container to be
                    selected for reimaging."
                    >
                        <Checkbox
                            className="mt-1"
                            {...register("allow_incompatible")}
                        />
                    </RhfFormField>

                    <RhfFormField
                        label="overwrite runtime config"
                        name="overwrite_runtime_config"
                        help={`If selected, your existing container's
                    runtime config will be overwritten by
                    the default runtime config for this
                    image.`}
                    >
                        <Checkbox
                            className="mt-1"
                            {...register("overwrite_runtime_config")}
                        />
                    </RhfFormField>
                </AccessControlledSection>
            )}
        </ExpandableFormSection>
    );
}
