import {
    Checkbox,
    FormField,
    RhfFormField,
    TextInput,
} from "@cycleplatform/ui/components/forms";
import { FieldPath, useFormContext, useWatch } from "react-hook-form";
import { ContainerConfigFormData } from "../../types";

export function LetsEncryptPathInput({
    field,
    label,
    placeholder,
}: {
    field: FieldPath<ContainerConfigFormData>;
    label: string;
    placeholder: string;
}) {
    const { register, control, setValue } =
        useFormContext<ContainerConfigFormData>();

    const customValue = useWatch({
        name: field,
        control,
    });

    return (
        <div className="mt-4 flex gap-8">
            <FormField label="Customize">
                <Checkbox
                    checked={typeof customValue === "string"}
                    onChange={(ev) =>
                        setValue(field, ev.target.checked ? "" : null, {
                            shouldDirty: true,
                        })
                    }
                />
            </FormField>
            {typeof customValue === "string" ? (
                <RhfFormField label={label} name={field} required>
                    <TextInput
                        placeholder={placeholder}
                        {...register(field, {
                            validate: {
                                startsWithSlash: (value) => {
                                    const v = value as string | undefined;
                                    return (
                                        (v && v[0] === "/") ||
                                        `${label} must begin with a "/"`
                                    );
                                },
                            },
                        })}
                    />
                </RhfFormField>
            ) : (
                <FormField label={label}>
                    <TextInput
                        placeholder={placeholder}
                        disabled
                        {...(register(field),
                        {
                            setValueAs: (v: string | null) =>
                                v === "" ? null : v,
                        })}
                    />
                </FormField>
            )}
        </div>
    );
}
