import { faGear, faPlus } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { StyledFormDialog } from "@cycleplatform/ui/components/dialog";
import { ButtonLink } from "@cycleplatform/ui/components/buttons";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { selectActiveHub, setActiveHub } from "~/modules/hubs/slice";
import { cycleApi, Hub, useGetHubsQuery } from "~/services/cycle";
import {
    clearDialogParams,
    generateDialogLink,
    isDialogOpen,
} from "~/components/dialogs/helpers";
import { ResourceState } from "@cycleplatform/ui/components/resources/state";
import { PendingInvitesList } from "~/components/hubs/PendingInvitesList";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { NavIcons } from "~/components/layout/NavIcons";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";
import { terminateAllForms } from "~/modules/forms/slice";

export function HubSelectDialog() {
    const navigate = useNavigate();
    const { data: hubsList, error } = useGetHubsQuery({});
    const hub = useAppSelector(selectActiveHub);

    if (error) {
        throw error;
    }

    return (
        <StyledFormDialog
            title={
                <div className="flex w-full justify-between ">
                    <div>Select a Hub</div>
                    <div className="flex gap-4">
                        <ButtonLink className="text-sm" to="/hub" icon={faGear}>
                            Hub Settings
                        </ButtonLink>
                        <ButtonLink
                            to={generateDialogLink("hub-create", null)}
                            className="text-sm"
                            icon={faPlus}
                            flavor="primary"
                            type="button"
                        >
                            Create Hub
                        </ButtonLink>
                    </div>
                </div>
            }
            isOpen={isDialogOpen("hub-select")}
            onClose={() => navigate(clearDialogParams())}
        >
            <DialogColumn className="w-full">
                <div className="pb-4">
                    <PendingInvitesList />
                </div>
                {!hub && hubsList?.data && hubsList.data.length === 0 ? (
                    <EmptyResource
                        title="No Hubs"
                        icon={NavIcons["hubs"]}
                        details="You are not a member of any Cycle Hubs.  Click below to create a hub."
                        stretch
                    >
                        <div className="pt-2">
                            <ButtonLink
                                to={generateDialogLink("hub-create", null)}
                                className="text-sm"
                                icon={faPlus}
                                flavor="primary"
                                type="button"
                            >
                                Create Hub
                            </ButtonLink>
                        </div>
                    </EmptyResource>
                ) : null}

                <div
                    className={classNames(
                        "grid  w-full grid-cols-4 gap-4 pb-4"
                    )}
                >
                    {hub ? <HubTile hub={hub} active /> : null}
                    {hubsList?.data
                        ?.filter((h) => h.id !== hub?.id)
                        .map((h) => (
                            <HubTile key={h.id} hub={h} />
                        ))}
                </div>
            </DialogColumn>
        </StyledFormDialog>
    );
}
type HubTileProps = {
    hub?: Hub;
    active?: boolean;
};
function HubTile({ hub, active }: HubTileProps) {
    const dispatch = useAppDispatch();
    const nav = useNavigate();

    const onClick = () => {
        dispatch(setActiveHub({ hubId: hub?.id || "" }));
        dispatch(cycleApi.util.resetApiState());
        dispatch(terminateAllForms());
        nav("/");
    };

    return (
        <button
            onClick={onClick}
            className={classNames(
                "hover:border-cycle-blue-accent h-40 rounded-md border",
                {
                    "border-cycle-blue": active,
                }
            )}
            disabled={active}
        >
            <div className="flex flex-col items-center justify-center">
                <p className="text-md truncate pb-2 font-semibold">
                    {hub?.name}
                </p>
                {hub?.state ? (
                    <div>
                        <ResourceState state={hub.state} />
                    </div>
                ) : null}
            </div>
        </button>
    );
}
