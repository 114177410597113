import {
    CopyInput,
    FormField,
    InputRow,
} from "@cycleplatform/ui/components/forms";
import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { useContext } from "react";
import { VirtualMachineDialogContex } from "../../../context";
import { extractPrivateIPs } from "@cycleplatform/core/modules/environments/resources/networks";
import { SkeletonButton } from "@cycleplatform/ui/components/loaders/skeleton";

export function VmPrivateNetworkPanel() {
    const { vm, container } = useContext(VirtualMachineDialogContex);
    const ips = container ? extractPrivateIPs(container) : null;

    return (
        <Panel>
            <PanelTitle title="Private Network" />
            <PanelContent>
                <InputRow>
                    <FormField label="cidr">
                        {vm ? (
                            <div className="flex flex-col gap-2">
                                <CopyInput
                                    value={ips?.ipv6 || "Loading..."}
                                    prefix="IPv6"
                                />
                                {ips?.ipv4 && (
                                    <CopyInput value={ips.ipv4} prefix="IPv4" />
                                )}
                            </div>
                        ) : (
                            <SkeletonButton />
                        )}
                    </FormField>

                    <PrivateIpsSection isLegacy={!!ips?.ipv4} />
                </InputRow>
                <InputRow>
                    <FormField label="hostname">
                        {vm ? (
                            <CopyInput value={vm.config.network.hostname} />
                        ) : (
                            <SkeletonButton />
                        )}
                    </FormField>
                </InputRow>
            </PanelContent>
        </Panel>
    );
}

function PrivateIpsSection({ isLegacy }: { isLegacy: boolean }) {
    const { vm } = useContext(VirtualMachineDialogContex);

    const privIps = vm?.meta?.vm_priv_ips;
    return (
        <FormField label="IPs">
            {privIps ? (
                <div className="flex flex-col gap-2">
                    <CopyInput
                        value={privIps?.ipv6 || "Loading..."}
                        prefix="IPv6"
                    />
                    {privIps?.ipv4 ? (
                        <CopyInput value={privIps?.ipv4} prefix="IPv4" />
                    ) : isLegacy ? (
                        <CopyInput value="Loading..." prefix="IPv4" />
                    ) : null}
                </div>
            ) : (
                <SkeletonButton />
            )}
        </FormField>
    );
}
