import {
    faTrash,
    faArrowCircleUp,
    faQuestionCircle,
    faPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { Activity, ComponentIncludes } from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import { ClusterLink } from "~/components/common/resources/ResourceLinks";

export function formatClusterMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const clusterLink = (
        <ClusterLink clusterId={item.component?.id} components={components} />
    );

    switch (item.event) {
        case "infrastructure.cluster.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted cluster {clusterLink}</span>
                ) : (
                    <span>Error deleting cluster {clusterLink}</span>
                ),
                icon: faTrash,
            };

        case "infrastructure.cluster.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated cluster {clusterLink}</span>
                ) : (
                    <span>Error updating cluster {clusterLink}</span>
                ),
                icon: faArrowCircleUp,
            };

        case "infrastructure.cluster.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Created cluster {clusterLink}</span>
                ) : (
                    <span>Error creating cluster {clusterLink}</span>
                ),
                icon: faPlus,
            };

        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}
