import { Button, LoaderButton } from "@cycleplatform/ui/components/buttons";
import {
    RhfFormField,
    RhfFormProvider,
    RhfGlobalFormError,
} from "@cycleplatform/ui/components/forms";
import { faArrowLeft, faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { useForm } from "react-hook-form";
import { handleSubmitError } from "~/components/forms/util";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { addStepData, getCurrentForm } from "~/modules/forms/slice";
import { useContext } from "react";
import { MultiStepDialogContext } from "~/components/dialogs/MultiStepFormDialog/context";
import {
    DialogColumn,
    DialogFooter,
} from "@cycleplatform/ui/components/dialog/components";
import {
    EnableTwoFactorAuthApiArg,
    useEnableTwoFactorAuthMutation,
} from "~/services/cycle";
import { VerificationCodeInput } from "~/components/auth/VerificationCodeInput";

export function VerifyStep({ stepIdx = 0 }: { stepIdx?: number }) {
    const { handleNext, handleBack, formKey } = useContext(
        MultiStepDialogContext
    );
    const currentForm = useAppSelector((state) =>
        getCurrentForm(state, formKey)
    );

    const form = useForm<{ token: string }>();

    const [setup2Fa] = useEnableTwoFactorAuthMutation();

    const {
        setValue,
        handleSubmit,
        formState: { isDirty, isSubmitting },
    } = form;

    const dispatch = useAppDispatch();

    const onSubmit = (data: EnableTwoFactorAuthApiArg["body"]) => {
        if (!isDirty) {
            return;
        }
        return setup2Fa({
            body: { token: data.token },
        })
            .unwrap()
            .then((v) => {
                dispatch(
                    addStepData({
                        data: { recoveryCodes: v.data?.recovery_codes || [] },
                        idx: stepIdx,
                        key: formKey,
                    })
                );

                handleNext();
            }, handleSubmitError(form.setError));
    };

    return (
        <DialogColumn className="w-full">
            <RhfFormProvider {...form}>
                <div className="flex items-center gap-4 pb-4">
                    <p>
                        Enter the six-digit numeric token displayed on your app
                        into the box below to enable two-factor authentication.
                    </p>
                </div>
                <div className="flex w-1/2 items-center gap-8 self-center pb-4">
                    <RhfFormField label="token" name="token">
                        <VerificationCodeInput
                            onChange={(v) =>
                                setValue("token", v, { shouldDirty: true })
                            }
                        />
                    </RhfFormField>
                </div>

                <DialogFooter>
                    <Button icon={faArrowLeft} onClick={() => handleBack()}>
                        Go Back
                    </Button>
                    <div>
                        <RhfGlobalFormError />
                    </div>
                    <LoaderButton
                        isLoading={isSubmitting}
                        onClick={handleSubmit(onSubmit)}
                        type="button"
                        flavor="primary"
                        icon={faCheckCircle}
                        disabled={!isDirty && !currentForm?.steps?.[stepIdx]}
                    >
                        Verify
                    </LoaderButton>
                </DialogFooter>
            </RhfFormProvider>
        </DialogColumn>
    );
}
