import {
    CreateOrderApiArg,
    Hub,
    useGetBillingTiersQuery,
} from "~/services/cycle";
import { RhfFormProvider } from "@cycleplatform/ui/components/forms";
import { useForm } from "react-hook-form";
import { useAppSelector } from "~/hooks";
import { selectActiveHub } from "~/modules/hubs/slice";
import { rhfConfig } from "~/components/forms/util";
import { useKeepFormCurrent } from "~/components/common/forms";
import { getAllDialogSearchParams } from "~/components/dialogs/helpers";
import { SelectTierStep } from "./steps/selectTier/SelectTierStep";
import { OrderConfirmationStep } from "./steps/orderConfirmation/OrderConfirmationStep";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";

const getFormDefaults = (
    params: Record<
        "dialog-step" | "dialog-tier-id" | "dialog-promo-code",
        string
    >,
    hub?: Hub
) => {
    const tierParam = params["dialog-tier-id"];
    const promoParam = params["dialog-promo-code"];

    return {
        tier_plan_id: tierParam || hub?.billing?.plans?.tier_id || undefined,
        promo_code: promoParam || undefined,
    };
};

export function ChangeHubPlanForm() {
    const params = getAllDialogSearchParams<"change-hub-plan">();
    const step = params["dialog-step"];

    const { data: tiers, error } = useGetBillingTiersQuery();

    const hub = useAppSelector(selectActiveHub);
    const form = useForm<CreateOrderApiArg["body"]>({
        defaultValues: getFormDefaults(params, hub),
        ...rhfConfig,
    });

    useKeepFormCurrent(form, hub, (h) => getFormDefaults(params, h));

    if (error) {
        throw error;
    }

    return (
        <RhfFormProvider {...form} className="!h-full">
            {!step || step === "0" ? (
                <DialogColumn>
                    <SelectTierStep tiers={tiers?.data} />
                </DialogColumn>
            ) : (
                <DialogColumn>
                    <OrderConfirmationStep tiers={tiers?.data} hub={hub} />
                </DialogColumn>
            )}
        </RhfFormProvider>
    );
}
