import {
    DialogColumn,
    DialogFooter,
} from "@cycleplatform/ui/components/dialog/components";
import {
    FormSection,
    FormSectionHeader,
} from "@cycleplatform/ui/components/forms";
import { PLAN_TILE_CLASSNAME } from "@cycleplatform/ui/components/resources";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { MultiStepDialogContext } from "~/components/dialogs/MultiStepFormDialog/context";
import { NavIcons } from "~/components/layout/NavIcons";
import { CurrentPlan } from "~/routes/hub/plans/route";
import { ChooseAnotherHubButton } from "../../buttons/ChooseAnotherHubButton";
import { DeleteHubButton } from "../../buttons/DeleteHubButton";
import { HandleNextButton } from "../../buttons/HandleNextButton";

export function ActiveTierSection() {
    return (
        <>
            <DialogColumn>
                <FormSectionHeader
                    header={
                        <div className="flex items-center gap-2">
                            <FontAwesomeIcon
                                icon={NavIcons["hubPlans"]}
                                className="text-cycle-blue"
                            />
                            Plan Tier
                        </div>
                    }
                />
                <FormSection>
                    <div className={PLAN_TILE_CLASSNAME}>
                        <CurrentPlan />
                    </div>
                </FormSection>
            </DialogColumn>

            <DialogFooter className="flex justify-between">
                <div className="flex gap-4">
                    <ChooseAnotherHubButton />
                    <DeleteHubButton />
                </div>
                <HandleNextButton />
            </DialogFooter>
        </>
    );
}
