import {
    Button,
    LoaderButton,
    PushAndHoldButton,
} from "@cycleplatform/ui/components/buttons";
import { useEffect } from "react";
import {
    Environment,
    useExpireVirtualMachineSosCredentialsMutation,
    useGenerateVirtualMachineSosCredentialsMutation,
    VirtualMachine,
} from "~/services/cycle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircle,
    faClock,
    faTerminal,
} from "@fortawesome/pro-solid-svg-icons";
import { useCountdown } from "@cycleplatform/ui/hooks";
import { isAfter } from "date-fns";
import { brandColors } from "tailwindcss-config/colors";
import { $info } from "@cycleplatform/core/util/log";
import { PositionedMenu } from "@cycleplatform/ui/components/menus";
import { PanelFooter } from "@cycleplatform/ui/components/panels";
import { CopyInput, FormField } from "@cycleplatform/ui/components/forms";
import { AccessControlOverlay } from "~/components/common/buttons";
import { Tooltip } from "@cycleplatform/ui/components/tooltip";
import {
    vmConsoleAccessFn,
    vmModifyAccessFn,
} from "@cycleplatform/core/modules/vms/acls";

type SerialOverSshPanelProps = {
    vm: VirtualMachine;
    environment: Environment | undefined;
};

export function SerialOverSshPanel({
    vm,
    environment,
}: SerialOverSshPanelProps) {
    const [getSosCreds, { data: sosCreds, isLoading, reset }] =
        useGenerateVirtualMachineSosCredentialsMutation({
            fixedCacheKey: `sos-creds-${vm.id}`,
        });
    const [expireSosCreds, { isLoading: isExpiring }] =
        useExpireVirtualMachineSosCredentialsMutation();

    // Handle initial fetch
    useEffect(() => {
        if (
            sosCreds?.data &&
            isAfter(new Date(), new Date(sosCreds.data.token.events.expires))
        ) {
            reset();
        }
    }, [vm.id]);

    return (
        <PositionedMenu
            className={"text-base"}
            render={() => {
                return (
                    <div>
                        <div className="pb-4 text-lg">
                            <FontAwesomeIcon icon={faTerminal} />{" "}
                            Serial-Over-SSH Access
                        </div>
                        <p>
                            Gain serial console access to this virtual machine
                            via Cycle&apos;s console proxy service. No SSH
                            processes are installed inside the virtual machine
                            and no ports are opened.
                        </p>
                        {sosCreds?.data ? (
                            <>
                                <div className="mt-4">
                                    <FormField label="command">
                                        <CopyInput
                                            value={formatSosCmd(
                                                sosCreds.data.address
                                            )}
                                        />
                                    </FormField>
                                    <FormField label="password">
                                        <CopyInput
                                            value={sosCreds.data.secret}
                                        />
                                    </FormField>
                                    <Countdown
                                        onExpired={() => reset()}
                                        expires={
                                            new Date(
                                                sosCreds.data.token.events.expires
                                            )
                                        }
                                    />
                                </div>
                                <PanelFooter>
                                    <PushAndHoldButton
                                        flavor="discard"
                                        icon={faClock}
                                        loaderSvgProps={{
                                            fill: brandColors["error"].DEFAULT,
                                        }}
                                        isLoading={isExpiring}
                                        onClick={() =>
                                            expireSosCreds({
                                                virtualMachineId: vm.id,
                                            }).then(
                                                () => {
                                                    reset();
                                                },
                                                (err) => {
                                                    $info(
                                                        "Error expiring serial-over-SSH credentials",
                                                        err
                                                    );
                                                }
                                            )
                                        }
                                    >
                                        Expire All Tokens
                                    </PushAndHoldButton>
                                </PanelFooter>
                            </>
                        ) : (
                            <div className="flex min-h-[10rem]  items-center justify-center">
                                <AccessControlOverlay
                                    aclResource={environment}
                                    verifyFn={vmModifyAccessFn(vm)}
                                >
                                    <LoaderButton
                                        icon={faTerminal}
                                        isLoading={isLoading}
                                        flavor="primary"
                                        onClick={() =>
                                            getSosCreds({
                                                virtualMachineId: vm.id,
                                            })
                                        }
                                    >
                                        Get Serial Console Credentials
                                    </LoaderButton>
                                </AccessControlOverlay>
                            </div>
                        )}
                    </div>
                );
            }}
        >
            <AccessControlOverlay
                aclResource={environment}
                verifyFn={vmConsoleAccessFn(vm)}
            >
                <Tooltip
                    message="Gain Serial Console Access"
                    className="relative flex items-center"
                >
                    <Button
                        icon={faTerminal}
                        className="h-[32px] w-10 !px-2 text-xs"
                        flavor="primary"
                    />
                    {sosCreds?.data && (
                        <FontAwesomeIcon
                            icon={faCircle}
                            className="text-warning absolute -top-1 right-1 w-2 animate-pulse"
                        />
                    )}
                </Tooltip>
            </AccessControlOverlay>
        </PositionedMenu>
    );
}

function formatSosCmd(address: string) {
    // the address we get from the API doesn't have a protocol,
    // so slapping ssh on allows us to parse it like a URL. We
    // could just split on a colon for the port, but this is cleaner imo.
    const url = new URL(`ssh://${address}`);
    return `ssh ${url.username}@${url.hostname} -p ${url.port}`;
}

function Countdown({
    expires,
    onExpired,
}: {
    expires: Date;
    onExpired: () => void;
}) {
    const { minutes: m, seconds: s } = useCountdown(expires);

    const minutes = m || 0;
    const seconds = s || 0;

    useEffect(() => {
        if (minutes <= 0 && seconds <= 0) {
            onExpired();
        }
    }, [minutes, seconds]);

    return (
        <span className="text-error dark:text-cycle-white">
            This token expires in{" "}
            <strong>{`${formatTimeSubstring(minutes)}:${formatTimeSubstring(
                seconds
            )}`}</strong>
        </span>
    );
}

function formatTimeSubstring(number: number) {
    return `${number < 10 ? `0${number.toString()}` : number.toString()}`;
}
