import {
    Checkbox,
    FormField,
    RhfFormField,
    TextInput,
    required,
    InputRow,
} from "@cycleplatform/ui/components/forms";
import {
    FormattedOption,
    SelectInput,
} from "@cycleplatform/ui/components/forms/select";
import { InfoPanel } from "@cycleplatform/ui/components/panels";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Link } from "react-router-dom";
import {
    ContainerSelect,
    ContainerSelectProps,
} from "~/components/containers/ContainerSelect";
import { EnvironmentSelect } from "~/components/environments/EnvironmentSelect";
import {
    Container,
    DnsRecordTypes,
    useGetContainerQuery,
    useGetContainersQuery,
    useGetDnsZoneQuery,
    useGetEnvironmentSummaryQuery,
    useGetUserSuppliedCertificatesQuery,
    useGetVirtualMachineQuery,
    useGetVirtualMachinesQuery,
} from "~/services/cycle";
import { Tab } from "@headlessui/react";
import { OptionTab } from "@cycleplatform/ui/components/options";
import { NavIcons } from "~/components/layout/NavIcons";
import { DeploymentTagSelect } from "~/components/environments/deployments";
import { filterDuplicates } from "@cycleplatform/core/util";
import { FeatureLimited } from "~/components/sales";
import { useTierFeature } from "~/modules/hubs/permissions/useTierFeature";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { $error } from "@cycleplatform/core/util/log";
import { useGetCurrentTier } from "~/modules/settings";
import LoadingIcons from "react-loading-icons";
import { brandColors } from "tailwindcss-config/colors";
import { VmSelect } from "~/components/vms/forms";

type FormValues = {
    zoneId?: string;
    recordId?: string;
    body: {
        name?: string;
        type: DnsRecordTypes;
    };
};

export function LinkedRecordDetails() {
    const { tier } = useGetCurrentTier();
    const isGeoDnsSupported = tier?.advanced_features.geodns;

    const { register, control, unregister, setValue } =
        useFormContext<FormValues>();

    const hasDeploymentsFeat = useTierFeature("deployments");

    const selectedContainerId = useWatch({
        name: "body.type.linked.container_id",
        control,
    });

    const deploymentEnvId = useWatch({
        name: "body.type.linked.deployment.environment_id",
        control,
    });

    const selectedVmId = useWatch({
        name: "body.type.linked.virtual_machine.id",
        control,
    });

    const [environment, setEnvironment] = useState<string | undefined | null>(
        deploymentEnvId || undefined
    );

    const { data: container } = useGetContainerQuery(
        {
            containerId: selectedContainerId || "",
        },
        { skip: !selectedContainerId }
    );
    const { data: vm } = useGetVirtualMachineQuery(
        {
            virtualMachineId: selectedVmId || "",
        },
        { skip: !selectedVmId }
    );

    useEffect(() => {
        if (environment) {
            return;
        }
        if (vm) {
            setEnvironment(vm?.data.environment.id);
            return;
        }
        if (container?.data?.environment?.id) {
            setEnvironment(container?.data?.environment?.id);
        }
    }, [container?.data?.environment?.id, vm]);

    const { currentData: vms, isFetching: isVmsLoading } =
        useGetVirtualMachinesQuery(
            {
                filter: {
                    environment: environment || "",
                },
            },
            { skip: !environment }
        );

    const [selectedIndex, setSelectedIndex] = useState(
        selectedVmId ? 2 : deploymentEnvId ? 1 : 0
    );

    // If environment is changed, and it doesn't have VMs, we need to move back to container
    // select.
    useEffect(() => {
        // vms tab
        if (selectedIndex !== 2) {
            return;
        }

        if (vms && vms.data.length === 0) {
            setSelectedIndex(0);
        }
    }, [environment, vms]);

    return (
        <>
            <InputRow>
                <FormField label="Environment">
                    <EnvironmentSelect
                        onChange={(v) => {
                            setEnvironment(v);
                            if (selectedContainerId) {
                                setValue(
                                    "body.type.linked.container_id",
                                    undefined
                                );
                            }
                            if (selectedVmId) {
                                setValue("body.type.linked.virtual_machine", {
                                    id: "",
                                    dmz: false,
                                });
                            }
                            if (deploymentEnvId) {
                                setValue("body.type.linked.deployment", {
                                    environment_id: v || "",
                                    match: {
                                        container: "",
                                        tag: null,
                                    },
                                });
                            }
                        }}
                        isNullable
                        value={environment}
                    />
                </FormField>
                <RhfFormField
                    label="TLS Enabled"
                    name="body.type.linked.features.tls.enable"
                    help="Cycle will generate or use user-supplied TLS certificates and install them into the load balancer."
                    className="!w-[10rem]"
                >
                    <Checkbox
                        {...register("body.type.linked.features.tls.enable")}
                    />
                </RhfFormField>

                <RhfFormField
                    label="Geo DNS"
                    name="body.type.linked.features.geodns.enable"
                    help={
                        isGeoDnsSupported
                            ? "Cycle will attempt to match inbound requests to the closest load balancer geographically."
                            : `Geo DNS is not supported on the ${tier?.name} tier`
                    }
                    className="!w-[10rem]"
                >
                    <Checkbox
                        disabled={!isGeoDnsSupported}
                        {...register("body.type.linked.features.geodns.enable")}
                    />
                </RhfFormField>
            </InputRow>

            <div className="my-4">
                <TlsNotification />
            </div>

            {environment ? (
                <>
                    <div className="mb-4">
                        <Tab.Group
                            selectedIndex={selectedIndex}
                            onChange={setSelectedIndex}
                        >
                            <Tab.List className="mb-4 flex gap-2">
                                <OptionTab
                                    title="Link to Container"
                                    description="Associate with a specific container."
                                    icon={NavIcons["containers"]}
                                    onClick={() => {
                                        setSelectedIndex(0);
                                        unregister(
                                            "body.type.linked.virtual_machine"
                                        );
                                        unregister(
                                            "body.type.linked.deployment"
                                        );
                                    }}
                                />
                                <OptionTab
                                    title="Link to Deployment"
                                    disabled={
                                        !hasDeploymentsFeat && !deploymentEnvId
                                    }
                                    description={
                                        hasDeploymentsFeat ? (
                                            "Associate with a container identifier that can change with deployment state."
                                        ) : (
                                            <>
                                                <FontAwesomeIcon
                                                    className="text-cycle-blue mr-2"
                                                    icon={faCircleInfo}
                                                />
                                                <span>
                                                    <Link to="/hub/plans">
                                                        Upgrade your tier
                                                    </Link>{" "}
                                                    to enable zero-downtime
                                                    updates
                                                </span>
                                            </>
                                        )
                                    }
                                    icon={NavIcons["deploy"]}
                                    onClick={() => {
                                        setSelectedIndex(1);
                                        unregister(
                                            "body.type.linked.container_id"
                                        );
                                        unregister(
                                            "body.type.linked.virtual_machine"
                                        );
                                    }}
                                />
                                <OptionTab
                                    title="Link to Virtual Machine"
                                    disabled={vms && vms.data.length === 0}
                                    description={(() => {
                                        if (isVmsLoading) {
                                            return (
                                                <div className="flex w-full items-center gap-2">
                                                    <LoadingIcons.ThreeDots
                                                        fill={
                                                            brandColors[
                                                                "cycle-blue"
                                                            ]["DEFAULT"]
                                                        }
                                                        width={30}
                                                        fillOpacity={1}
                                                        strokeOpacity={0}
                                                        height={"0.5rem"}
                                                    />{" "}
                                                    Checking for Virtual
                                                    Machines
                                                </div>
                                            );
                                        }
                                        if (!vms || vms.data.length === 0) {
                                            return "No virtual machines in selected environment";
                                        }

                                        return "Associate with a virtual machine.";
                                    })()}
                                    icon={NavIcons["vm"]}
                                    onClick={() => {
                                        setSelectedIndex(2);
                                        unregister(
                                            "body.type.linked.container_id"
                                        );
                                        unregister(
                                            "body.type.linked.deployment"
                                        );
                                    }}
                                />
                            </Tab.List>
                            <Tab.Panel>
                                {selectedIndex === 0 ? (
                                    <LinkToContainer
                                        environmentId={environment}
                                        container={
                                            selectedContainerId
                                                ? container?.data
                                                : undefined
                                        }
                                    />
                                ) : null}
                            </Tab.Panel>
                            <Tab.Panel>
                                {selectedIndex === 1 ? (
                                    <LinkToDeployment
                                        environmentId={environment}
                                    />
                                ) : null}
                            </Tab.Panel>
                            <Tab.Panel>
                                {selectedIndex === 2 ? (
                                    <LinkToVirtualMachine
                                        environmentId={environment}
                                        vmId={selectedVmId}
                                    />
                                ) : null}
                            </Tab.Panel>
                        </Tab.Group>
                    </div>
                </>
            ) : null}
        </>
    );
}

function LinkToContainer({
    container,
    environmentId,
}: {
    container: Container | undefined;
    environmentId: string;
}) {
    const { control, unregister } = useFormContext<FormValues>();

    // On render, ensure that deployment is unregistered.
    // This is required due to the way env change is handled, it must clear out the fields
    // and you cannot clear fields and unregister the section at the same time
    useEffect(() => {
        setTimeout(() => {
            unregister("body.type.linked.deployment");
            unregister("body.type.linked.virtual_machine");
        }, 200);
    }, []);

    return (
        <div className="flex flex-col">
            <RhfFormField
                label="container"
                name="body.type.linked.container_id"
                required
            >
                <Controller
                    render={({ field: { ref: _ref, ...field } }) => (
                        <ContainerSelectInjectScheduler
                            {...field}
                            value={field.value ? field.value : undefined}
                            environmentId={environmentId}
                        />
                    )}
                    rules={{ ...required() }}
                    shouldUnregister
                    control={control}
                    name="body.type.linked.container_id"
                />
            </RhfFormField>

            {container?.config?.network?.public === "disable" ? (
                <InfoPanel type="warning" className="mb-4">
                    The selected container's public network is currently set to
                    disabled. In order to reach this container using this
                    domain, it will need to first be enabled.
                </InfoPanel>
            ) : null}
        </div>
    );
}

function LinkToDeployment({ environmentId }: { environmentId: string }) {
    return (
        <>
            <FeatureLimited
                feature="deployments"
                render={
                    <InfoPanel type="warning">
                        You no longer have access to deployments. This record
                        will still function, but you will be unable to change it
                        until you upgrade your tier.
                    </InfoPanel>
                }
            />
            <LinkedDeploymentForm environmentId={environmentId} />
        </>
    );
}

function LinkToVirtualMachine({
    vmId,
    environmentId,
}: {
    vmId: string | undefined;
    environmentId: string;
}) {
    const { control, unregister, register } = useFormContext<FormValues>();

    // This is required due to the way env change is handled, it must clear out the fields
    // and you cannot clear fields and unregister the section at the same time
    useEffect(() => {
        setTimeout(() => {
            unregister("body.type.linked.virtual_machine");
            unregister("body.type.linked.deployment");
            unregister("body.type.linked.container_id");
        }, 200);
    }, []);

    const { data: vm, error } = useGetVirtualMachineQuery(
        {
            virtualMachineId: vmId || "",
        },
        { skip: !vmId }
    );

    if (error) {
        $error("unable to fetch selected vm", error);
    }

    return (
        <div className="flex flex-col">
            <RhfFormField
                label="virtual machine"
                name="body.type.linked.virtual_machine.id"
                required
            >
                <Controller
                    render={({ field: { ref: _ref, ...field } }) => (
                        <VmSelect
                            {...field}
                            filter={{ environment: environmentId }}
                        />
                    )}
                    rules={{ ...required() }}
                    shouldUnregister
                    control={control}
                    name="body.type.linked.virtual_machine.id"
                />
            </RhfFormField>

            <RhfFormField
                label="DMZ"
                name="body.type.linked.virtual_machine.dmz"
            >
                <Checkbox
                    {...register("body.type.linked.virtual_machine.dmz")}
                    info="Bypass the load balancer and send all traffic to the virtual machine via the gateway"
                />
            </RhfFormField>

            {vm?.data.config?.network?.public === "disable" ? (
                <InfoPanel type="warning" className="mb-4">
                    The selected virtual machine's public network is currently
                    set to disabled. In order to reach this container using this
                    domain, it will need to first be enabled.
                </InfoPanel>
            ) : null}
        </div>
    );
}

function ContainerSelectInjectScheduler({
    environmentId,
    ...props
}: ContainerSelectProps & { environmentId: string }) {
    const { data: envSummary, error: summaryError } =
        useGetEnvironmentSummaryQuery({
            environmentId: environmentId,
        });

    const { data: schedulerContainer, error: schedulerError } =
        useGetContainerQuery(
            {
                containerId:
                    envSummary?.data?.services?.scheduler?.container_id || "",
            },
            { skip: !envSummary?.data?.services?.scheduler?.container_id }
        );

    if (schedulerError) {
        $error("error fetching environment scheduler", schedulerError);
    }
    if (summaryError) {
        $error("error fetching environment summary", summaryError);
    }

    return (
        <ContainerSelect
            {...props}
            filter={{
                environment: environmentId || "",
            }}
            additionalContainers={
                schedulerContainer?.data &&
                schedulerContainer?.data?.config.network.public === "enable"
                    ? [schedulerContainer?.data]
                    : []
            }
        />
    );
}
function LinkedDeploymentForm({ environmentId }: { environmentId: string }) {
    const { register, control, setValue, unregister } =
        useFormContext<FormValues>();

    useEffect(() => {
        setTimeout(() => {
            unregister("body.type.linked.virtual_machine");
            unregister("body.type.linked.container_id");
        }, 200);
    }, []);

    const { data: containers } = useGetContainersQuery({
        filter: {
            environment: environmentId,
        },
        page: {
            number: 1,
            size: 100,
        },
    });

    const containerIdentifiers = filterDuplicates(
        containers?.data?.map((c) => c.identifier) || []
    );

    useEffect(() => {
        setValue("body.type.linked.deployment.environment_id", environmentId);
    }, []);

    return (
        <div className="flex gap-4">
            <TextInput
                hidden
                {...register("body.type.linked.deployment.environment_id")}
            />
            <RhfFormField
                label="Container Identifier"
                name="body.type.linked.deployment.match.container"
                required
            >
                <Controller
                    render={({ field: { ref: _ref, ...field } }) => (
                        <SelectInput
                            {...field}
                            placeholder="Select a container identifier"
                            isCreatable
                            options={containerIdentifiers}
                            formatOption={(o) => <FormattedOption label={o} />}
                        />
                    )}
                    rules={{ ...required() }}
                    control={control}
                    name="body.type.linked.deployment.match.container"
                />
            </RhfFormField>

            <RhfFormField
                label="Deployment Tag"
                name="body.type.linked.deployment.match.tag"
                required
            >
                <Controller
                    render={({ field: { ref: _ref, ...field } }) => (
                        <DeploymentTagSelect
                            {...field}
                            isCreatable
                            value={field.value || undefined}
                            environmentId={environmentId}
                        />
                    )}
                    rules={{ ...required() }}
                    control={control}
                    name="body.type.linked.deployment.match.tag"
                />
            </RhfFormField>
        </div>
    );
}

function TlsNotification() {
    const { control, getValues } = useFormContext<FormValues>();
    const enabled = useWatch({
        control,
        name: "body.type.linked.features.tls.enable",
    });
    const domain = useWatch({ control, name: "body.name" });
    const { data: zone, error: zoneError } = useGetDnsZoneQuery({
        zoneId: getValues("zoneId") || "",
    });

    const [fqdn, setFqdn] = useState("");

    const {
        data: certs,
        error: certsError,
        isLoading,
    } = useGetUserSuppliedCertificatesQuery(
        {
            filter: {
                domain: fqdn,
            },
        },
        { skip: !zone || !enabled || !fqdn }
    );
    useEffect(() => {
        if (!zone) {
            return;
        }
        if (domain === "@") {
            setFqdn(zone.data.origin);
        } else {
            setFqdn(`${domain}.${zone.data.origin}`);
        }
    }, [domain, zone?.data.origin]);

    if (zoneError) {
        $error("error with tls notification", zoneError);
        return null;
    }
    if (certsError) {
        $error("error with tls notification", certsError);
        return null;
    }

    if (!enabled || isLoading) {
        return null;
    }

    if (certs?.data.filter((c) => c.state.current === "live").length) {
        return (
            <InfoPanel>
                This domain will use an existing user supplied TLS certificate.
            </InfoPanel>
        );
    }

    return (
        <InfoPanel>
            This domain will use a Cycle generated TLS certificate that will
            automatically renew every 60 days.
        </InfoPanel>
    );
}
