import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "../../tooltip";
import classNames from "classnames";
import { PublicNetworkAnalysisStatus } from "@cycleplatform/core/modules/networks/analysis/error";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { StatusOkIcon } from "./icons/StatusOkIcon";
import { StatusErrorIcon } from "./icons/StatusErrorIcon";
import { StatusWarningIcon } from "./icons/StatusWarningIcon";
import { StatusDisabledIcon } from "./icons/StatusDisabledIcon";

export function Line({
    left,
    right,
    status,
    message,
}: {
    left: boolean;
    right: boolean;
    status: PublicNetworkAnalysisStatus;
    message: string | null;
}) {
    return (
        <div className="relative mx-4 flex w-full items-center">
            {left ? (
                <FontAwesomeIcon
                    className={classNames(
                        {
                            "text-cycle-black dark:text-cycle-gray-light/80":
                                status === "ok",
                            "text-error/50": status === "error",
                            "text-warning/50": status === "warning",
                            "text-cycle-gray/50 dark:text-cycle-gray-light/40":
                                status === "disabled",
                        },
                        "text-3xl"
                    )}
                    icon={faArrowLeft}
                />
            ) : null}

            <Tooltip
                disabled={!message}
                message={
                    <>
                        {message?.split("\n").map((line, idx) => (
                            <div key={`${line}-${idx}`}>{line}</div>
                        ))}
                    </>
                }
                className="absolute left-0 right-0 flex w-full justify-center"
            >
                {status === "ok" ? <StatusOkIcon /> : null}
                {status === "error" ? <StatusErrorIcon /> : null}
                {status === "warning" ? <StatusWarningIcon /> : null}
                {status === "disabled" ? <StatusDisabledIcon /> : null}
            </Tooltip>

            <div
                className={classNames(
                    {
                        "border-t-cycle-black dark:border-t-cycle-gray-light/80":
                            status === "ok",
                        "border-t-error/50": status === "error",
                        "border-t-warning/50": status === "warning",
                        "border-t-cycle-gray/50 dark:border-t-cycle-gray-light/40":
                            status === "disabled",
                    },
                    " mx-4 inline-block w-full border-t-4 border-dashed"
                )}
            />

            {right ? (
                <FontAwesomeIcon
                    className={classNames(
                        {
                            "text-cycle-black dark:text-cycle-gray-light/80":
                                status === "ok",
                            "text-error/50": status === "error",
                            "text-warning/50": status === "warning",
                            "text-cycle-gray/50 dark:text-cycle-gray-light/40":
                                status === "disabled",
                        },
                        "text-3xl"
                    )}
                    icon={faArrowRight}
                />
            ) : null}
        </div>
    );
}
