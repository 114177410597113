import { PanelContent, PanelDetail } from "@cycleplatform/ui/components/panels";
import { FormattedDate } from "@cycleplatform/ui/components/time";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BillingMethod } from "~/services/cycle";

type BillingMethodInfoProps = {
    method: BillingMethod | undefined;
};

export function BillingMethodInfo({ method }: BillingMethodInfoProps) {
    return (
        <>
            {method?.source.type === "stripe-credit-card" && (
                <>
                    <PanelDetail label="Payment Type">Credit Card</PanelDetail>
                    <PanelDetail label="card number">
                        <div className="flex items-center gap-[2px]">
                            {Array.from({
                                length: 12,
                            }).map((_, idx) => (
                                <FontAwesomeIcon
                                    className="text-[6px]"
                                    icon={faCircle}
                                    key={idx}
                                />
                            ))}
                            {method.source.details.last_4}
                        </div>
                    </PanelDetail>
                    <PanelDetail label="brand">
                        {method.source.details.brand}
                    </PanelDetail>
                    <PanelDetail label="expiration">
                        {method.source.details.expiration.month}/
                        {method.source.details.expiration.year}
                    </PanelDetail>
                </>
            )}
            {method?.source.type === "stripe-us-bank-acct" && (
                <>
                    <PanelDetail label="Payment Type">Bank Account</PanelDetail>
                    <PanelDetail label="account number">
                        <div className="flex items-center gap-[2px]">
                            {Array.from({
                                length: 12,
                            }).map((_, idx) => (
                                <FontAwesomeIcon
                                    className="text-[6px]"
                                    icon={faCircle}
                                    key={idx}
                                />
                            ))}
                            {method.source.details.last_4}
                        </div>
                    </PanelDetail>

                    {method.mandate?.accepted ? (
                        <PanelDetail label="approved by">
                            {method.mandate.email}
                        </PanelDetail>
                    ) : null}
                    <PanelDetail label="approved on">
                        {method.mandate?.accepted ? (
                            <FormattedDate
                                date={new Date(method.mandate.accepted)}
                            />
                        ) : (
                            "Not Approved"
                        )}
                    </PanelDetail>
                </>
            )}

            {method?.source.type === "direct-payment" && (
                <>
                    <PanelDetail label="Payment Type">
                        Direct Payment
                    </PanelDetail>
                    <PanelDetail label="Preference">
                        {method.source.details.preference}
                    </PanelDetail>

                    <PanelDetail label="instructions">
                        {method.source.details.instructions
                            ?.split("\n")
                            .map((l) => (
                                <div>{l}</div>
                            ))}
                    </PanelDetail>

                    {method.mandate?.accepted ? (
                        <PanelDetail label="approved by">
                            {method.mandate.email}
                        </PanelDetail>
                    ) : null}
                    <PanelDetail label="approved on">
                        {method.mandate?.accepted ? (
                            <FormattedDate
                                date={new Date(method.mandate.accepted)}
                            />
                        ) : (
                            "Not Approved"
                        )}
                    </PanelDetail>
                </>
            )}
        </>
    );
}
