import {
    faQuestionCircle,
    faPlus,
    faEdit,
} from "@fortawesome/pro-solid-svg-icons";
import { Activity, ComponentIncludes } from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import {
    EnvironmentLink,
    EnvScopedVariableLink,
} from "~/components/common/resources/ResourceLinks";

export function formatScopedVariableMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const envLink = (
        <EnvironmentLink
            environmentId={item.context.environment_id}
            components={components}
        />
    );

    const scopedVar = (
        <EnvScopedVariableLink
            scopedVarId={item.component?.id}
            components={components}
        />
    );

    switch (item.event) {
        case "environment.scoped-variable.create": {
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Created new scoped variable {scopedVar} for environment
                        {envLink}
                    </span>
                ) : (
                    <span>
                        Error creating new scoped variable {scopedVar} for
                        environment
                        {envLink}
                    </span>
                ),
                icon: faPlus,
            };
        }

        case "environment.scoped-variable.update": {
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Updated scoped variable {scopedVar} in environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>
                        Error updating scoped variable {scopedVar} in
                        environment
                        {envLink}
                    </span>
                ),
                icon: faEdit,
            };
        }

        case "environment.scoped-variable.delete": {
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Deleted scoped variable {scopedVar} for environment{" "}
                        {envLink}
                    </span>
                ) : (
                    <span>
                        Error deleting scoped variable {scopedVar} for
                        environment
                        {envLink}
                    </span>
                ),
                icon: faPlus,
            };
        }
        case "environment.scoped-variable.task.delete": {
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested deletion of scoped variable {scopedVar} in
                        environment {envLink}
                    </span>
                ) : (
                    <span>
                        Error requesting deletion of scoped variable {scopedVar}{" "}
                        in environment {envLink}
                    </span>
                ),
                icon: faPlus,
            };
        }
        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}
