import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { PageTitle } from "./PageTitle";
import { HeaderTab, HeaderTabList } from "./tabs";
import { components } from "@cycleplatform/core/modules/api/__generated";
import { ResourceStateBadge } from "../resources/state";
import { ReadOnlyBadge } from "../badges";

export interface PageHeaderTab {
    to: string;
    name: string;
    // exact?: boolean;
    beta?: boolean;
}

type PageHeaderProps = {
    tabs?: PageHeaderTab[];
    className?: string;
    childrenClassName?: string;
    title?: string;
    icon?: IconDefinition;
    children?: React.ReactNode;
    /** null will render a placeholder. */
    state?: (components["schemas"]["State"] & { current: string }) | null;
    /** Badge is a prop that will allow the user to provide a custom badge rather than the default state badge */
    badge?: React.ReactNode;
    /** Read only is a boolean that will add a badge to the top when a user does not have write access */
    readOnly?: boolean;
};

export function PageHeader({
    tabs,
    className,
    childrenClassName,
    children,
    title,
    icon,
    state,
    readOnly,
    badge = null,
}: PageHeaderProps) {
    return (
        <header
            className={classNames(
                className,
                "dark:border-cycle-gray pb-2 dark:bg-transparent "
            )}
        >
            <div className="flex flex-1 items-center">
                <PageTitle
                    isLoading={title === undefined}
                    className="mr-4 flex min-w-[10rem] items-center overflow-hidden text-ellipsis whitespace-nowrap"
                >
                    {icon ? (
                        <FontAwesomeIcon
                            icon={icon}
                            className="text-cycle-blue pb-[0.1rem] pr-2 text-2xl"
                        />
                    ) : null}{" "}
                    {title}
                </PageTitle>

                {state !== undefined ? (
                    <ResourceStateBadge
                        state={state || undefined}
                        className="mr-4"
                    />
                ) : null}

                {badge}

                {readOnly ? (
                    <ReadOnlyBadge state={state} className="mr-4" />
                ) : null}

                <div
                    className={classNames(
                        "flex flex-1 items-center justify-end",
                        childrenClassName
                    )}
                >
                    {children}
                </div>
            </div>

            {tabs && (
                <HeaderTabList>
                    {tabs.map((t) => (
                        <HeaderTab
                            key={t.name}
                            title={t.name}
                            beta={t.beta}
                            to={t.to}
                            end={t.to === "/" || t.to === ""}
                        />
                    ))}
                </HeaderTabList>
            )}
        </header>
    );
}
