import { InstanceTelemetryResourceSnapshot } from "~/services/cycle";
import { useMemo } from "react";
import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { CycleLineProps } from "@cycleplatform/ui/components/recharts/line/props";
import {
    CycleAxisProps,
    CycleChartProps,
} from "@cycleplatform/ui/components/recharts/props";
import { FormattedTimeTick } from "@cycleplatform/ui/components/recharts/FormattedTimeTick";
import { FormattedYTick } from "@cycleplatform/ui/components/recharts/FormattedYTick";
import { CycleLineTooltip } from "@cycleplatform/ui/components/recharts/line/CycleLineTooltip";
import classNames from "classnames";
import { formatBytes } from "@cycleplatform/core/util";
import { useGetThemedChartColors } from "~/util/charts/hooks";
import { CycleGridProps } from "@cycleplatform/ui/components/recharts/grid/props";

type InstanceRamChartProps = {
    telemetry?: InstanceTelemetryResourceSnapshot[];
};

export function InstanceRamChart({ telemetry = [] }: InstanceRamChartProps) {
    const { colors } = useGetThemedChartColors();
    const data = useMemo(
        () =>
            telemetry?.map((t) => ({
                time: t.time,
                usage: t.memory.usage?.usage ?? null,
                kernel: t.memory.kernel_usage?.usage ?? null,
                kernel_tcp: t.memory.kernel_tcp_usage?.usage ?? null,
            })),
        [telemetry]
    );

    return (
        <div className={classNames("h-full w-full")}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart {...CycleChartProps} data={data}>
                    <CartesianGrid {...CycleGridProps} />
                    <XAxis
                        {...CycleAxisProps}
                        dataKey="time"
                        tick={(props) => <FormattedTimeTick {...props} />}
                    />
                    <YAxis
                        {...CycleAxisProps}
                        domain={[
                            0,
                            (dataMax: number) => Math.ceil(dataMax * 1.2),
                        ]}
                        tick={(props) => (
                            <FormattedYTick
                                {...props}
                                formatValue={(v: number) => {
                                    const bytes = formatBytes(v);

                                    return `${bytes.value}${bytes.suffix}`;
                                }}
                            />
                        )}
                    />
                    <Tooltip
                        content={(props) => (
                            <CycleLineTooltip
                                {...props}
                                formatValue={(v: number) => {
                                    const bytes = formatBytes(v, 1);

                                    return `${bytes.value}${bytes.suffix}`;
                                }}
                            />
                        )}
                    />
                    <Line
                        {...CycleLineProps}
                        dataKey="usage"
                        stroke={colors["blue"].DEFAULT}
                    />

                    <Line
                        {...CycleLineProps}
                        dataKey="kernel"
                        stroke={colors["blue"].dark}
                    />
                    <Line
                        {...CycleLineProps}
                        dataKey="kernel_tcp"
                        stroke={colors["gray"].DEFAULT}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}
