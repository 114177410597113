import { useMemo } from "react";
import classNames from "classnames";
import { FormattedOption } from "./FormattedOption";
import { Listbox } from "./Listbox";

export type BooleanOption = {
    value: boolean;
    label: string;
    detail?: string;
    disabled?: boolean;
};

type BooleanSelectProps = {
    name: string;
    value?: boolean | null;
    options: BooleanOption[];
    disabled?: boolean;
    onBlur?: () => void;
    onChange?: (value: boolean | null) => void;
    className?: string;
    placeholder?: string;
};

/**
 * Basic select is used when you want to present a list of options of type Option
 * The label is the display string for the select
 * The value is the string that is submitted
 */
export function BooleanSelect({
    value,
    options,
    className,
    placeholder,
    ...props
}: BooleanSelectProps) {
    const labelsByValue = useMemo(() => {
        return options.reduce((acc, cur) => {
            return { ...acc, [cur.value.toString()]: cur.label };
        }, {} as Record<string, string>);
    }, [options]);

    const detailsByValue = useMemo(() => {
        return options.reduce((acc, cur) => {
            return { ...acc, [cur.value.toString()]: cur.detail };
        }, {} as Record<string, string | undefined>);
    }, [options]);

    const disabledByValue = useMemo(() => {
        return options.reduce((acc, cur) => {
            return { ...acc, [cur.value.toString()]: cur.disabled };
        }, {} as Record<string, boolean | undefined>);
    }, [options]);

    return (
        <Listbox
            {...props}
            defaultValue={false}
            value={value}
            placeholder={placeholder}
            className={classNames(className)}
            options={options?.map((o) => o.value)}
            formatDisplayValue={(o) => {
                return labelsByValue[o ? o.toString() : "false"] || "";
            }}
            isOptionValid={(o) => {
                return !disabledByValue[o ? o.toString() : "false"] || true;
            }}
            formatOption={(o) => {
                if (o === null) {
                    return "";
                }
                return (
                    <FormattedOption
                        label={labelsByValue[o.toString()]}
                        detail={detailsByValue[o.toString()]}
                    />
                );
            }}
        />
    );
}
