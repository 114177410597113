import {
    FilterContext,
    FilterGroup,
    FilterMenu,
    FilterMultiSelect,
    FilterSelect,
    FilterValue,
} from "@cycleplatform/ui/components/forms/filters";
import {
    faAddressBook,
    faClock,
    faCode,
} from "@fortawesome/pro-solid-svg-icons";

export function UrlFilters({
    filtering,
    availableResponses,
    availableHostnames,
    className,
}: {
    filtering: FilterContext;
    availableResponses: string[] | undefined;
    availableHostnames: string[] | undefined;
    className?: string;
}) {
    return (
        <FilterMenu filtering={filtering} className={className}>
            <FilterGroup
                identifier="range"
                name="Time Range"
                isExclusive
                isRequired
                icon={faClock}
            >
                <FilterValue name="1h" value="1">
                    1h
                </FilterValue>
                <FilterValue name="6h" value="6">
                    6h
                </FilterValue>
                <FilterValue name="1d" value="24" isDefault>
                    1d
                </FilterValue>
            </FilterGroup>

            <FilterGroup
                identifier="hostname"
                name="Domain"
                isExclusive
                icon={faAddressBook}
            >
                <FilterSelect options={availableHostnames || []} />
            </FilterGroup>

            <FilterGroup
                identifier="responses"
                name="HTTP Code"
                isExclusive
                icon={faCode}
            >
                <FilterMultiSelect options={availableResponses || []} />
            </FilterGroup>
        </FilterMenu>
    );
}
