import { getTiersByCategory } from "@cycleplatform/core/modules/tiers/util";
import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useAppSelector } from "~/hooks";
import { selectActiveHub } from "~/modules/hubs/slice";
import {
    CreateOrderApiArg,
    useGetBillingServicesQuery,
    useGetBillingTiersQuery,
} from "~/services/cycle";

/**
 * Designed to work instde of CreateOrderApiArg form
 * Ensures that everything is kept in sync and handles the interplay between the
 * Tier sections and the tiers themselves
 *
 * Used in the HubPlanUpdate dialog and HubSetup dialog
 *
 * @returns An object with all the information neccessary for the TierCategory components
 */
export function useSelectTierFunctionality() {
    const form = useFormContext<CreateOrderApiArg["body"]>();
    const { control } = form;

    const { data: activeServices } = useGetBillingServicesQuery({});

    const { data: tiers } = useGetBillingTiersQuery();
    const hub = useAppSelector(selectActiveHub);

    let currentTierId = hub?.billing?.plans?.tier_id || null;
    const currentTier = tiers?.data?.find((t) => t.id === currentTierId);

    // If current tier is free - don't show it
    // We now force the user to upgrade from the "free" tier during hub setup
    if (currentTier?.code === "free") {
        currentTierId = null;
    }

    const tiersByCategory = getTiersByCategory(tiers?.data, currentTierId);

    const selectedTierId = useWatch({ name: "tier_plan_id", control });

    const activeTierPrice = useMemo(
        () =>
            activeServices?.data?.find((as) => !!as.item.tier)?.item.tier?.price
                .mills || 0,
        [activeServices?.data]
    );

    return {
        currentTier,
        currentTierId,
        tiersByCategory,
        selectedTierId,
        activeTierPrice,
    };
}
