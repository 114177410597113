import {
    InputRow,
    RhfEnableDisableFormSection,
    RhfFormField,
    TextInput,
    isDurationString,
    required,
} from "@cycleplatform/ui/components/forms";
import { useFormContext } from "react-hook-form";
import { VmConfigFormData } from "../../types";

export function DeployStartupPolicy() {
    const { register, control, watch, unregister } =
        useFormContext<VmConfigFormData>();

    return (
        <InputRow>
            <RhfFormField
                label="delay"
                name="config.deploy.startup.delay"
                required
            >
                <TextInput
                    defaultValue={"0s"}
                    {...register("config.deploy.startup.delay", {
                        ...required("Delay cannot be empty"),
                        validate: {
                            ...isDurationString(),
                        },
                    })}
                />
            </RhfFormField>
        </InputRow>
    );
}
