export function getStandardPortName(port: number | "default") {
    switch (port) {
        case 80:
            return "[HTTP]";
        case 443:
            return "[HTTPS]";
        case 53:
            return "[DNS]";
        case 1194:
            return "[VPN]";
        case 8812:
            return "[VPN File Download]";
        default:
            return null;
    }
}
