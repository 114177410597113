import classNames from "classnames";

type SkeletonChartProps = {
    className?: string;
};

export function SkeletonChart({ className }: SkeletonChartProps) {
    return (
        <div className="h-full w-full p-8">
            <div
                className={classNames(
                    className,
                    " from-cycle-gray-light via-cycle-gray/60 to-cycle-gray-light animate-shimmer h-full w-full self-center rounded-lg bg-gradient-to-r bg-[length:1000px_100%] opacity-50"
                )}
            />
        </div>
    );
}
