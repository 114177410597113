import { Server, useGetCompatibleServersQuery } from "~/services/cycle";
import { ResourceComboBox } from "../common/forms";
import { FormattedOption } from "@cycleplatform/ui/components/forms/select";
import { ResourceStateBadge } from "@cycleplatform/ui/components/resources/state";

type UsableServerSelectProps = {
    containerId: string;
    value?: string;
    disabled?: boolean;
    onChange: (
        serverId: string | undefined | null,
        server: Server | undefined
    ) => void;
    skip?: boolean;
    filterFields: Array<keyof Server>;
    filterServers?: (s: Server) => boolean;
};

export function UsableServerSelect({
    containerId,
    value,
    disabled,
    onChange,
    skip,
    filterFields,
    filterServers = (s) => true,
}: UsableServerSelectProps) {
    // TODO - there is a risk that the image set as 'value' is not on the list
    // returned by the query. May need an additional query for the specific image
    // if not initially returned and then merge them.
    const {
        data: servers,
        error,
        isLoading,
    } = useGetCompatibleServersQuery(
        {
            containerId,
            include: ["integrations"],
            sort: ["provider.vendor"],
        },
        { skip }
    );

    if (error) {
        throw error;
    }

    const integrations = servers?.includes?.integrations || {};

    return (
        <ResourceComboBox
            placeholder="Select a server..."
            value={value}
            isLoading={isLoading}
            onChange={onChange}
            disabled={disabled}
            resources={servers?.data?.filter(filterServers) || []}
            formatDisplayValue={(s) => {
                if (!s) return "";
                return s.hostname;
            }}
            filterFields={filterFields}
            formatOption={(s) => {
                const integration =
                    integrations[s.provider.integration_id]?.name ||
                    s.provider.vendor;
                return (
                    <FormattedOption
                        label={s.hostname}
                        detail={`${integration}: ${s.provider.model} ${
                            s.provider.zone ? `[${s.provider.zone}]` : ""
                        }`}
                    >
                        <ResourceStateBadge state={s.state} />
                    </FormattedOption>
                );
            }}
        />
    );
}
