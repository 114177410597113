import { useContext } from "react";
import { VirtualMachineDialogContex } from "../../../context";
import { EventChartList } from "~/components/security/events/EventChartList";
import { Panel, PanelTitle } from "@cycleplatform/ui/components/panels";
import { PanelContentBoundary } from "~/components/layout/panels/PanelContentBoundary";

export function VmEventsPanel() {
    return (
        <Panel className="">
            <PanelTitle title="Events" />
            <PanelContentBoundary>
                <VmEventsChart />
            </PanelContentBoundary>
        </Panel>
    );
}

function VmEventsChart() {
    const { vm } = useContext(VirtualMachineDialogContex);

    if (!vm?.container_id) {
        return (
            <EventChartList
                criteria={{
                    "metadata.type": {
                        $ne: "audit",
                    },
                    $or: [
                        {
                            "labels.vm_id": vm?.id,
                        },
                    ],
                }}
                skip={!vm?.id}
            />
        );
    }
    return (
        <EventChartList
            criteria={{
                "metadata.type": {
                    $ne: "audit",
                },
                $or: [
                    {
                        "labels.vm_id": vm?.id,
                    },
                    {
                        "labels.container_id": vm.container_id,
                    },
                ],
            }}
            skip={!vm?.id}
        />
    );
}
