import { DialogPageHeader } from "@cycleplatform/ui/components/dialog";
import {
    generateDialogLink,
    getAllDialogSearchParams,
} from "~/components/dialogs/helpers";
import { NavIcons } from "~/components/layout/NavIcons";
import { FirewallDashboard } from "./components/FirewallDashboard";
import { FirewallConfig } from "./components/FirewallConfig";
import { EmptyResource } from "@cycleplatform/ui/components/resources/panels";
import { useContext, useEffect } from "react";
import { LoadBalancerDialogContext } from "../context";
import { isUsingCycleNativeLb } from "@cycleplatform/core/modules/environments/loadbalancer";
import { useNavigate } from "react-router-dom";
import { DialogColumn } from "@cycleplatform/ui/components/dialog/components";

export function FirewallTab() {
    const params = getAllDialogSearchParams<"environment-lb-manage">();

    const { service } = useContext(LoadBalancerDialogContext);
    const nav = useNavigate();

    useEffect(() => {
        nav(
            generateDialogLink("environment-lb-manage", {
                "dialog-tab": "firewall",
                "dialog-firewall-subsection":
                    params["dialog-firewall-subsection"] || "overview",
            }),
            { replace: true }
        );
    }, [params["dialog-port-idx"]]);

    if (service && !isUsingCycleNativeLb(service)) {
        return (
            <div className="p-4">
                <EmptyResource
                    title="Firewall Not Available"
                    icon={NavIcons["firewall"]}
                >
                    <span>
                        Load balancer firewall not available for HAProxy. For
                        access to the web application firewall, switch to the V1
                        Load Balancer. To learn more,{" "}
                        <a
                            href="https://cycle.io/docs/portal/configuring-web-application-firewall"
                            target="_blank"
                            rel="noreferrer"
                        >
                            check out the docs
                        </a>
                    </span>
                </EmptyResource>
            </div>
        );
    }

    return (
        <DialogColumn className="pt-4">
            <DialogPageHeader
                title={"Load Balancer Firewall"}
                icon={NavIcons["firewall"]}
                tabs={[
                    {
                        name: "Overview",
                        to: generateDialogLink("environment-lb-manage", {
                            "dialog-tab": "firewall",
                            "dialog-firewall-subsection": "overview",
                        }),
                        isActive:
                            params["dialog-firewall-subsection"] === "overview",
                    },
                    {
                        name: "Config",
                        to: generateDialogLink("environment-lb-manage", {
                            "dialog-tab": "firewall",
                            "dialog-firewall-subsection": "config",
                        }),
                        isActive:
                            params["dialog-firewall-subsection"] === "config",
                    },
                ]}
            />

            {params["dialog-firewall-subsection"] === "overview" ? (
                <FirewallDashboard />
            ) : null}
            {params["dialog-firewall-subsection"] === "config" ? (
                <FirewallConfig />
            ) : null}
        </DialogColumn>
    );
}
