import {
    faTrash,
    faArrowCircleUp,
    faQuestionCircle,
    faPlus,
    faBroom,
} from "@fortawesome/pro-solid-svg-icons";
import { Activity, ComponentIncludes, Stack } from "~/services/cycle";
import { isErroredActivity } from "./common/util";
import { StackLink } from "~/components/common/resources/ResourceLinks";

export function formatStackMessage(
    item: Activity,
    components: ComponentIncludes
) {
    const stackLink = (
        <StackLink stackId={item.component?.id} components={components} />
    );
    switch (item.event) {
        case "stack.task.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Requested deletion of stack {stackLink}</span>
                ) : (
                    <span>Error requesting deletion of stack {stackLink} </span>
                ),
                icon: faTrash,
            };
        case "stack.delete":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Deleted stack {stackLink}</span>
                ) : (
                    <span>Error deleting stack {stackLink} </span>
                ),
                icon: faTrash,
            };
        case "stack.update":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Updated stack {stackLink}</span>
                ) : (
                    <span>Error updating stack {stackLink}</span>
                ),
                icon: faArrowCircleUp,
            };
        case "stack.create":
            return {
                message: !isErroredActivity(item) ? (
                    <span>Created stack {stackLink}</span>
                ) : (
                    <span>Error creating stack</span>
                ),
                icon: faPlus,
            };
        case "stack.prune":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested prune of all builds of stack {stackLink}
                    </span>
                ) : (
                    <span>
                        Error requesting prune of all builds builds of stack{" "}
                        {stackLink}
                    </span>
                ),
                icon: faBroom,
            };
        case "stack.task.prune":
            return {
                message: !isErroredActivity(item) ? (
                    <span>
                        Requested prune of all builds of stack {stackLink}
                    </span>
                ) : (
                    <span>
                        Error requesting prune of all builds builds of stack{" "}
                        {stackLink}
                    </span>
                ),
                icon: faBroom,
            };

        default:
            return {
                message: item.event,
                icon: faQuestionCircle,
            };
    }
}
