import { useGetHubMembershipQuery, useGetHubsQuery } from "~/services/cycle";
import { selectActiveHubId } from "../slice";
import { useAppSelector } from "~/hooks";

/**
 * Get the role for the current member in this hub
 */
export function useGetMemberRole(props: { skip?: boolean } = { skip: false }) {
    const hubId = useAppSelector(selectActiveHubId);

    const { data: hubs } = useGetHubsQuery({});

    const {
        data: membership,
        isLoading,
        error,
    } = useGetHubMembershipQuery(
        {
            include: ["roles"],
        },
        { skip: props.skip || hubs?.data.length === 0 || !hubId }
    );

    return {
        role: membership?.includes?.roles?.[membership.data.role_id],
        isLoading,
        error,
    };
}
