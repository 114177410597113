import {
    faArrowRight,
    faBackward,
    faServer,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PushAndHoldButton } from "@cycleplatform/ui/components/buttons";
import {
    Instance,
    useCreateInstanceJobMutation,
    useGetCompatibleServersQuery,
} from "~/services/cycle";
import { useJobTracker } from "~/modules/jobs/hooks";
import { $info } from "@cycleplatform/core/util/log";
import { sentryLog } from "~/components/logging";
import {
    InfoPanel,
    Panel,
    PanelContent,
    PanelFooter,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { FormattedPurgeTimer } from "@cycleplatform/ui/components/resources";
import {
    getServerPurgeTime,
    isMigrationComplete,
} from "@cycleplatform/core/modules/containers/instances";
import { components } from "@cycleplatform/core/modules/api/__generated";
import { useAppDispatch } from "~/hooks";
import { pushNotification } from "~/modules/notifications/slice";
import { useMemo } from "react";

type MigratingPanelProps = {
    title?: string;
    instance: Instance;
};

export function MigratingPanel({ title, instance }: MigratingPanelProps) {
    const [createInstanceJob] = useCreateInstanceJobMutation({
        fixedCacheKey: `migrate-instance-${instance.id}`,
    });
    const { data: servers, error } = useGetCompatibleServersQuery({
        containerId: instance.container_id,
    });
    const [trackJob, { isTrackingJob }] = useJobTracker();
    const migrationComplete = isMigrationComplete(
        instance as components["schemas"]["Instance"]
    );
    const dispatch = useAppDispatch();

    if (error) {
        throw error;
    }
    const purgeTime = getServerPurgeTime(instance);

    const currentServer = useMemo(
        () => servers?.data?.find((s) => s.id === instance.server_id),
        [servers?.data, instance.server_id]
    );

    const toServer = useMemo(
        () =>
            servers?.data?.find(
                (s) => s.id === instance.migration?.to?.server_id
            ),
        [servers?.data, instance.migration?.to?.server_id]
    );

    const fromServer = useMemo(
        () =>
            servers?.data?.find(
                (s) => s.id === instance.migration?.from?.server_id
            ),
        [servers?.data, instance.migration?.from?.server_id]
    );

    const isEligibleToRevert = "to" in (instance?.migration || {});

    return (
        <Panel>
            {title && <PanelTitle title={title} />}
            <PanelContent>
                <div>
                    <div className="text-cycle-blue flex items-center justify-center gap-20 pt-4 pb-8 text-7xl">
                        <div className="relative flex flex-col items-center justify-center">
                            <FontAwesomeIcon icon={faServer} />
                            <p className="absolute -bottom-6 w-40 overflow-hidden text-ellipsis whitespace-nowrap text-xs">
                                {fromServer?.hostname ||
                                    currentServer?.hostname}
                            </p>
                        </div>
                        <FontAwesomeIcon
                            className="animate-pulse"
                            icon={faArrowRight}
                        />
                        <div className="relative flex flex-col items-center justify-center">
                            <FontAwesomeIcon icon={faServer} />
                            <p className="absolute -bottom-6 w-40 overflow-hidden text-ellipsis whitespace-nowrap text-xs">
                                {toServer?.hostname || currentServer?.hostname}
                            </p>
                        </div>
                    </div>
                    <p className="mb-8 text-center font-bold">
                        {migrationComplete ? (
                            <>Migration Complete</>
                        ) : (
                            <> Migration in progress...</>
                        )}
                    </p>
                    {isEligibleToRevert ? (
                        <>
                            <InfoPanel
                                type={migrationComplete ? "success" : "info"}
                            >
                                {migrationComplete ? (
                                    <div className="w-full">
                                        <span>
                                            This instance has been migrated
                                        </span>

                                        {purgeTime ? (
                                            <>
                                                {" "}
                                                and will be purged in{" "}
                                                <span>
                                                    <FormattedPurgeTimer
                                                        purgeTime={purgeTime}
                                                        onlyTime={true}
                                                    />
                                                </span>
                                            </>
                                        ) : null}

                                        <span>
                                            . You can revert this migration by
                                            clicking the button below.
                                        </span>
                                    </div>
                                ) : (
                                    <>
                                        This instance is currently being
                                        migrated to another server. To revert
                                        this migration, click the button below.
                                        This will not stop the migration, but
                                        revert back once the instance has
                                        finished migrating.
                                    </>
                                )}
                            </InfoPanel>
                            <PanelFooter>
                                <PushAndHoldButton
                                    type="button"
                                    flavor="primary"
                                    icon={faBackward}
                                    isLoading={isTrackingJob}
                                    onClick={() =>
                                        trackJob(
                                            createInstanceJob({
                                                containerId:
                                                    instance.container_id,
                                                instanceId: instance.id,
                                                instanceTask: {
                                                    action: "migration.revert",
                                                },
                                            }).unwrap()
                                        ).then(
                                            (_) => $info("Migration reverted"),
                                            (err) => {
                                                sentryLog(err);
                                                dispatch(
                                                    pushNotification({
                                                        title: err?.data?.error
                                                            ?.code,
                                                        type: "error",
                                                        message:
                                                            err?.data?.error
                                                                ?.title,
                                                    })
                                                );
                                            }
                                        )
                                    }
                                >
                                    Revert Migration
                                </PushAndHoldButton>
                            </PanelFooter>
                        </>
                    ) : null}
                </div>
            </PanelContent>
        </Panel>
    );
}
