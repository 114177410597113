import {
    Panel,
    PanelContent,
    PanelTitle,
} from "@cycleplatform/ui/components/panels";
import { useGenerateAggregatedMetricsQuery } from "~/services/cycle";
import { useContext, useEffect } from "react";
import { skeletonStyles } from "@cycleplatform/ui/components/loaders/skeleton/skeletonStyle";
import classNames from "classnames";
import { SkeletonTable } from "@cycleplatform/ui/components/loaders/skeleton";
import {
    buildFindTopTrafficInstancesQuery,
    buildTrafficByInstanceQuery,
} from "~/components/instances/telemetry/charts/traffic/query";
import { TrafficByInstanceChart } from "~/components/instances/telemetry/charts/traffic/TrafficByInstanceChart";
import { TrafficByInstanceTable } from "~/components/instances/telemetry/charts/traffic/TrafficByInstanceTable";
import {
    InstanceChartDataPoint,
    getInstanceIdsFromAggregateData,
} from "~/components/instances/telemetry/helpers";
import { InstanceChartTelemetryContext } from "../context";
import { AGGREGATE_POLLING_MS } from "~/util/charts/util";

export function TrafficSection({
    packets,
    scope,
}: {
    packets: "tx" | "rx";
    scope?: "public" | "private";
}) {
    const {
        filtering: { granularity, instanceLimit, range, isBuffering },
        instanceColorMap,
        instanceLabelMap,
        components,
        registerIds,
        criteria,
        tableInclude,
    } = useContext(InstanceChartTelemetryContext);

    const {
        data: tId,
        isLoading: tIsLoading,
        error: tError,
    } = useGenerateAggregatedMetricsQuery(
        buildFindTopTrafficInstancesQuery({
            criteria: criteria || {},
            granularity,
            limit: instanceLimit,
            range: range,
            packets: packets,
            scope,
        }),
        {
            skip: !criteria || isBuffering,
            pollingInterval: AGGREGATE_POLLING_MS,
        }
    );

    const topIds = tId?.data?.[0]?.instanceIds as string[] | undefined;

    const {
        data: traffic,
        error,
        isLoading,
    } = useGenerateAggregatedMetricsQuery(
        buildTrafficByInstanceQuery({
            criteria: criteria || {},
            granularity: granularity,
            instanceIds: topIds,
            range,
            packets: packets,
            scope,
        }),
        {
            skip: !criteria || !topIds || isBuffering,
            pollingInterval: AGGREGATE_POLLING_MS,
        }
    );

    const data = traffic?.data as InstanceChartDataPoint[] | undefined;

    const { instanceIds } = getInstanceIdsFromAggregateData(data);

    useEffect(() => {
        if (!topIds) {
            return;
        }
        registerIds(topIds);
    }, [topIds]);

    if (error) {
        throw error;
    }
    if (tError) {
        throw tError;
    }

    const renderLoading = isLoading || tIsLoading || isBuffering;

    return (
        <Panel>
            <div className="h-full w-full">
                <PanelTitle
                    title={`Data ${
                        packets === "tx" ? "transmitted" : "received"
                    } ${scope ? `[${scope}]` : ""}`}
                />
                <PanelContent stretch className="border-none">
                    <div
                        className={classNames(
                            "m-4 h-60",
                            renderLoading && skeletonStyles
                        )}
                    >
                        {data && (
                            <TrafficByInstanceChart
                                data={data}
                                instanceColorMap={instanceColorMap}
                                instanceLabelMap={instanceLabelMap}
                                instanceIds={instanceIds}
                            />
                        )}
                    </div>
                    {!renderLoading && data ? (
                        <TrafficByInstanceTable
                            data={data}
                            instanceColorMap={instanceColorMap}
                            instanceLabelMap={instanceLabelMap}
                            instanceIds={instanceIds}
                            components={components}
                            include={tableInclude}
                        />
                    ) : (
                        <SkeletonTable />
                    )}
                </PanelContent>
            </div>
        </Panel>
    );
}
