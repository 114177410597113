import { StackBuild, useGetStackBuildLogQuery } from "~/services/cycle";
import { Panel, PanelTitle } from "@cycleplatform/ui/components/panels";
import { isCycleApiError } from "~/services/helpers";
import classNames from "classnames";
import AnsiConverter from "ansi-to-html";

type ImageBuildLogProps = {
    stackId?: string;
    buildId?: string;
    title?: React.ReactNode;
    codeDimensionsClassName?: string;
};

export function StackBuildLog({
    stackId,
    buildId,
    title,
    codeDimensionsClassName,
}: ImageBuildLogProps) {
    const convert = new AnsiConverter();

    const {
        currentData: log,
        error: logError,
        isLoading,
    } = useGetStackBuildLogQuery(
        {
            stackId: stackId || "",
            buildId: buildId || "",
        },
        {
            skip: !stackId || !buildId,
        }
    );

    const notFoundError =
        logError &&
        isCycleApiError(logError) &&
        logError.data.error.code !== "404.stack.build-log";

    if (logError && !notFoundError) {
        throw logError;
    }

    const value = isLoading ? "" : log?.data?.log || "No build log";

    return (
        <div className=" h-full w-full pb-8">
            <PanelTitle title={title || "Build Log"} />
            <div className="h-full py-2">
                <pre
                    className={classNames(
                        "language-bash !m-0 rounded-lg p-4 !text-sm",
                        codeDimensionsClassName || "h-full max-w-[80rem]"
                    )}
                >
                    <code
                        className={`py-2 ${classNames(
                            codeDimensionsClassName || "h-full"
                        )}`}
                        dangerouslySetInnerHTML={{
                            __html: convert.toHtml(value),
                        }}
                    ></code>
                </pre>
            </div>
        </div>
    );
}
